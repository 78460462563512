/**
 * Slider con dos puntos deslizables.
 * Docs para el desarrollo del componente:
 * 
 * https://retool.com/blog/building-a-react-slider/
 * https://github.com/zillow/react-slider
 * 
 */

import { HexStandardPinkStylingB2C } from '@src/helpers/stringHelpers';
import React from 'react';
import ReactSlider from 'react-slider';

const Thumb = props => (
  <div
    {...props}
    className={`bg-[${HexStandardPinkStylingB2C}] relative top-[-4px] h-4 w-4 rounded-full`}
  />
);

const Track = (props, state) => {
  const isOutsideTrack = state.index !== 1;
  
  return (
    <div {...props} key={state.index} className={`h-2 ${!isOutsideTrack ? "bg-red-100" : "bg-gray-200"}`} />
  );
};

const MinMaxSlider = ({ className, limits, currentValues, onChange, valueFormatter }) => (
  <div className={`grid grid-cols-1 ${className ?? ""}`}>
    <div className="flex justify-between mb-2">
      <p>{valueFormatter ? valueFormatter(currentValues[0]) : currentValues[0]}</p>
      <p>{valueFormatter ? valueFormatter(currentValues[1]) : currentValues[1]}</p>
    </div>
    <ReactSlider
      className="w-full"
      withTracks={true}
      min={limits ? limits[0] : undefined}
      max={limits ? limits[1] : undefined}
      defaultValue={limits ?? undefined}
      renderTrack={(props, state) => Track(props, state, valueFormatter)}
      renderThumb={Thumb}
      onChange={onChange}
      minDistance={1}
    />
  </div>
);
 
export default MinMaxSlider;