import React, { useEffect, useState } from "react";
import { Dialog } from "@material-tailwind/react";
import HistoryBackButton from "@src/components/ui/buttons/HistoryBackButton";
import PaginateData from "@src/components/ui/PaginateData";
import Spinner from "@src/components/ui/Spinner";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { useTranslation } from "react-i18next";
import SupplementService from "@src/services/supplementService";
import ListSupplementsTable from "@src/components/supplements/ListSupplementsTable";
import { useToggle } from "@src/helpers/hooks/useToggle";
import ProductService from "@src/services/products/productService";
import { useParams } from "react-router-dom";
import NewButton from "@src/components/ui/buttons/NewButton";
import CreateSupplementDialog from "@src/components/supplements/CreateSupplementDialog";
import { useHistory } from "react-router-dom";
import { CommonRoutesStrings } from "@src/helpers/stringHelpers";


const SupplementSelectorDialog = ({ open, selectedSupplementIds, handler, finalizeSupplementSelection }) => {
  const [supplements, setSupplements] = useState();
  const [newSupplement, setNewSupplement] = useState();
  const [w18ApiResponse, toggleW18ApiResponse] = useToggle();
  const { t, ready: translationsReady } = useTranslation("pages/supplements/list");
  const { id: productId } = useParams();
  const SUPPLEMENTS_PER_PAGE = 5;
  const [showCreateSupplementDialog, setShowCreateSupplementDialog] = useState(false);
  const history = useHistory();
 

  const fetchSupplements = async () => {
    try {
      setSupplements(await SupplementService.indexDependingOnCurrentUserTypeExcludingIDs(selectedSupplementIds));
    } catch (_) {
      createNotification(NotificationTypes.error, t("messages.genericRequestError"));
    }
  };

  const fetchNewSupplements = async () => {
    try {
      setNewSupplement(await SupplementService.index());
    } catch (error) {
      createNotification(NotificationTypes.error, t("messages.genericRequestError"));
      history.push(CommonRoutesStrings.homePath);
    }
  };

  useEffect(() => {
    if (open && translationsReady) {
      fetchSupplements();
    }
    // eslint-disable-next-line
  }, [translationsReady, open, showCreateSupplementDialog]);

  const handleSupplementCreation = async () => {
    await fetchNewSupplements();
    setShowCreateSupplementDialog(false);
  };

  const handleSupplementSelection = async supplement => {
    toggleW18ApiResponse();
    try {
      
      await ProductService.assignSupplementToProduct(productId, supplement.id);
      createNotification(NotificationTypes.success, t("selectAction.ok"));
      finalizeSupplementSelection(supplement);
    } catch (err) {
      createNotification(NotificationTypes.error, t("selectAction.ko"));
    } finally {
      toggleW18ApiResponse();
    }
  };

  return (
    <Dialog open={open} >
      {!(translationsReady && supplements) ? (
        <Spinner />
      ) : (
        <div className="grid grid-cols-1 place-items-center max-w-xl mt-2 mx-auto">
          <PaginateData
            data={supplements}
            emptyDataArrayMsg={t("messages.noSupplementsToList")}
            perPage={SUPPLEMENTS_PER_PAGE}
          >
            <ListSupplementsTable hideProviderColumn={true} selectSupplementCallback={handleSupplementSelection} />
          </PaginateData>
        </div>
      )}
      <div className="flex justify-around my-4">
        <NewButton onClick={() => setShowCreateSupplementDialog(true)} className="text-sm" />
        <HistoryBackButton onClick={handler} />
      </div>
   
      <CreateSupplementDialog
        open={showCreateSupplementDialog}
        handler={() => setShowCreateSupplementDialog(false)}
        handleSupplementCreation={handleSupplementCreation}
      />
      {w18ApiResponse && <Spinner />}
    </Dialog>
  );
};

export default SupplementSelectorDialog;
