import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import FormikTextInput from "@src/components//forms/FormikTextInput";
import Spinner from "@src/components//ui/Spinner";
import FormikSelect from "@src/components/forms/FormikSelect";
import { OrderStatusStrings } from "@src/helpers/stringHelpers";


const FormInitialValues = {
  client: "",
  date: "",
  status: "",
};

const FilterOrderForm = ({
  className,
  color,
  cbSetClient,
  cbSetDateMin,
  cbSetDateMax,
  cbSetStatus,
}) => {
  const { t, ready: translationsReady } = useTranslation([
    "common/filterForm",
    "common/form",
    "components/orders/filterForm",
  ]);
  const DEFAULT_COLOR = "light-blue";

  function getOptionsForOrderStatus() {
    const orderStatus = [
      OrderStatusStrings.cancelled,
      OrderStatusStrings.completed,
      OrderStatusStrings.pendingRefund,
      OrderStatusStrings.paymentReceived,
    ];
    let options = [{
        value: "",
        label: t("any", { ns: "common/common" }),
    }];
  
    orderStatus.forEach(value => options.push({
      value,
      label: t(value, { ns: "common/orderStatuses"}),
    }));
  
    return options;
  };

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <Card className={className}>
          <CardHeader
            color={color ?? DEFAULT_COLOR}
            variant="gradient"
            className="p-5"
          >
            <Typography variant="h5" color="white">
              {t("description", { ns: "common/filterForm" })}
            </Typography>
          </CardHeader>
          <CardBody>
            <Formik initialValues={FormInitialValues}>
              {({ handleChange }) => (
                <Form className="grid sm:grid-flow-row xl:grid-flow-col  xl:space-x-6 sm:items-start xl:items-center w-full">
                  <FormikTextInput
                    className={"mt-3"}
                    name="client"
                    type="client"
                    color={color ?? DEFAULT_COLOR}
                    label={t("labels.client", { ns: "components/orders/filterForm" })}
                    onChange={(e) => {
                      handleChange(e);
                      cbSetClient(e.target.value);
                    }}
                  />
                  <FormikTextInput
                    className={"mt-3"}
                    label={t("labels.minimalDate", { ns: "components/orders/filterForm" })}
                    name="date-min"
                    type="date"
                    onChange={(e) => {
                      handleChange(e);
                      cbSetDateMin(e.target.value);
                    }}
                  />
                  <FormikTextInput
                    className={"mt-3"}
                    label={t("labels.maximalDate", { ns: "components/orders/filterForm" })}
                    name="date-max"
                    type="date"
                    onChange={(e) => {
                      handleChange(e);
                      cbSetDateMax(e.target.value);
                    }}
                  />
                  <FormikSelect
                    className={"mt-3"}
                    label={t("labels.status", { ns: "components/orders/filterForm" })}
                    name="status"
                    options={getOptionsForOrderStatus()}
                    onChange={optionValue => cbSetStatus(optionValue)}
                  />
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      )}
    </>
  );
};
export default FilterOrderForm;
