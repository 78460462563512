import React, {useState} from "react";
import {useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {getCurrentUserData} from "@src/helpers/localStorageHelpers";
import {createNotification, NotificationTypes} from "@src/helpers/notificationHelpers";
import {getProductRequest} from "@src/helpers/productHelpers";
import {CommonRoutesStrings} from "@src/helpers/stringHelpers";
import {isProvider} from "@src/helpers/userHelpers";
import EditProductProperties from "@src/components/products/edit_product_data/EditProductProperties";
import ListDates from "@src/components/products/edit_product_data/list_dates/ListDates";
import ListTimeslices from "@src/components/products/edit_product_data/list_timeslices/ListTimeslices";
import ListPrices from "@src/components/products/edit_product_data/list_prices/ListPrices";
import EditProductMainScreen from "@src/components/products/edit_product_data/EditProductMainScreen";
import Spinner from "@src/components/ui/Spinner";
import { Trans, useTranslation } from "react-i18next";
import { CardHeader } from "@material-tailwind/react";
import EditProductTranslations from "@src/components/products/edit_product_data/edit_product_translations/EditProductTranslations";
import EditProductImages from "@src/components/products/edit_product_data/EditProductImages";
import EditProductSupplements from "@src/components/products/edit_product_data/supplements/EditProductSupplements";
import { useQuery } from "@src/helpers/hooks/useQuery";

export const DEFINED_COMPONENTS = {
  MAIN_COMPONENT: "MAIN_COMPONENT",
  SHOW_PRODUCT_TRANSLATIONS: "SHOW_PRODUCT_TRANSLATIONS",
  SHOW_PRODUCT_PROPERTIES: "SHOW_PRODUCT_PROPERTIES",
  SHOW_PRODUCT_IMAGES: "SHOW_PRODUCT_IMAGES",
  SHOW_PRODUCT_SUPPLEMENTS: "SHOW_PRODUCT_SUPPLEMENTS",
  LIST_DATES: "LIST_DATES",
  LIST_TIMESLICES_OF_DATE: "LIST_TIMESLICES_OF_DATE",
  LIST_PRICES_OF_TIMESLICE: "LIST_PRICES_OF_TIMESLICE",
};

const ShowProductPage = ({ productType }) => {
  const [product, setProduct] = useState();
  const {id} = useParams();
  const { t, ready: translationsReady } = useTranslation("pages/products/edit");
  const history = useHistory();
  const query = useQuery();
  const show = query.get("show");
  const [currentComponent, setCurrentComponent] = useState(show ? DEFINED_COMPONENTS.SHOW_PRODUCT_PROPERTIES:DEFINED_COMPONENTS.MAIN_COMPONENT);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTimeslice, setSelectedTimeslice] = useState();
  

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const getProductResponse = await getProductRequest(id, productType);
        const productData = getProductResponse.data;
        if (isProvider() && productData.provider_id !== getCurrentUserData().id) {
          createNotification(NotificationTypes.error, t("validations.noPermissions.show"));
          history.push(CommonRoutesStrings.homePath);
        } else {
          setProduct(productData);
        }
      } catch (err) {
        const errorMsg = err?.response?.status === 404 ?
          t("validations.productNotFound") : t("validations.genericRequestError");

        createNotification(NotificationTypes.error, errorMsg);
        history.push(CommonRoutesStrings.homePath);
      }
    };
    
    translationsReady && fetchProduct();
    // eslint-disable-next-line
  }, [translationsReady]);

  const renderCurrentComponent = () => {

    switch (currentComponent) {
      case DEFINED_COMPONENTS.MAIN_COMPONENT:
        return <EditProductMainScreen
          editProductTranslationsCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.SHOW_PRODUCT_TRANSLATIONS)}
          editProductPropertiesCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.SHOW_PRODUCT_PROPERTIES)}
          editProductImagesCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.SHOW_PRODUCT_IMAGES)}
          editProductSupplementsCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.SHOW_PRODUCT_SUPPLEMENTS)}
          editConfigurationsCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.LIST_DATES)}
        />;
      case DEFINED_COMPONENTS.SHOW_PRODUCT_TRANSLATIONS:
        return <EditProductTranslations
                  productType={product.type}
                  backCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.MAIN_COMPONENT)}
                  readOnly={true}
                />;
      case DEFINED_COMPONENTS.SHOW_PRODUCT_PROPERTIES:
        return <EditProductProperties
          productData={product}
          readOnly={true}
          backAction={() => changeSelectedComponent(DEFINED_COMPONENTS.MAIN_COMPONENT)}
        />;
      case DEFINED_COMPONENTS.SHOW_PRODUCT_IMAGES:
        return <EditProductImages
                  productData={product}
                  readOnly={true}
                  backCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.MAIN_COMPONENT)}
                />;
      case DEFINED_COMPONENTS.SHOW_PRODUCT_SUPPLEMENTS:
        return <EditProductSupplements
                  backCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.MAIN_COMPONENT)}
                  productData={product}
                  readOnly={true}
                />;
      case DEFINED_COMPONENTS.LIST_DATES:
        return <ListDates
          dates={product.dates}
          readOnly={true}
          backCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.MAIN_COMPONENT)}
          addDateCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.ADD_DATE_TO_CONFIGURATION)}
          showTimeslicesOfDateCallback={date => changeSelectedComponent(DEFINED_COMPONENTS.LIST_TIMESLICES_OF_DATE, date)}
        />;
      case DEFINED_COMPONENTS.LIST_TIMESLICES_OF_DATE:
        return <ListTimeslices
          productType={product.type}
          date={selectedDate}
          readOnly={true}
          timeslices={selectedDate.time_slices}
          backCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.LIST_DATES)}
          addTimesliceCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.ADD_TIMESLICE_TO_DATE)}
          editTimesliceCallback={timeslice => changeSelectedComponent(DEFINED_COMPONENTS.EDIT_INFO_TIMESLICE, timeslice)}

          showPricesOfTimesliceCallback={timeslice => changeSelectedComponent(DEFINED_COMPONENTS.LIST_PRICES_OF_TIMESLICE, timeslice)}
        />;
      case DEFINED_COMPONENTS.LIST_PRICES_OF_TIMESLICE:
        return <ListPrices
          date={selectedDate}
          readOnly={true}
          timeslice={selectedTimeslice}
          prices={selectedTimeslice.prices}
          backCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.LIST_TIMESLICES_OF_DATE)}
          addPriceCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.ADD_PRICE_TO_TIMESLICE)}
          editPriceCallback={price => changeSelectedComponent(DEFINED_COMPONENTS.EDIT_INFO_PRICE, price)}

        />;
      default:
        return <div>Not found :(</div>;
    }
  }

  const changeSelectedComponent = (selectedComponent, requiredData) => {
    // eslint-disable-next-line
    switch (selectedComponent) {
      case DEFINED_COMPONENTS.MAIN_COMPONENT:
        setCurrentComponent(DEFINED_COMPONENTS.MAIN_COMPONENT);
        break;
      case DEFINED_COMPONENTS.SHOW_PRODUCT_TRANSLATIONS:
        setCurrentComponent(DEFINED_COMPONENTS.SHOW_PRODUCT_TRANSLATIONS);
        break;
      case DEFINED_COMPONENTS.SHOW_PRODUCT_PROPERTIES:
        setCurrentComponent(DEFINED_COMPONENTS.SHOW_PRODUCT_PROPERTIES);
        break;
      case DEFINED_COMPONENTS.SHOW_PRODUCT_IMAGES:
        setCurrentComponent(DEFINED_COMPONENTS.SHOW_PRODUCT_IMAGES);
        break;
      case DEFINED_COMPONENTS.SHOW_PRODUCT_SUPPLEMENTS:
        setCurrentComponent(DEFINED_COMPONENTS.SHOW_PRODUCT_SUPPLEMENTS);
        break;
      case DEFINED_COMPONENTS.LIST_DATES:
        setCurrentComponent(DEFINED_COMPONENTS.LIST_DATES);
        break;
      case DEFINED_COMPONENTS.LIST_TIMESLICES_OF_DATE:
        setCurrentComponent(DEFINED_COMPONENTS.LIST_TIMESLICES_OF_DATE);
        if (requiredData) setSelectedDate(requiredData);
        break;
      case DEFINED_COMPONENTS.LIST_PRICES_OF_TIMESLICE:
        setCurrentComponent(DEFINED_COMPONENTS.LIST_PRICES_OF_TIMESLICE);
        if (requiredData) setSelectedTimeslice(requiredData);
        break;
    }
  }

  return (
    <>
      {!(translationsReady && product) ? <Spinner /> : (
        <div>
          <div className="mt-10 mb-4 flex place-content-center w-full">
            <CardHeader color="purple" className="p-4 space-y-2">
              <h2 className="text-white text-center text-2xl">
                {t("titles.show")}
              </h2>
              <h6 className="uppercase text-gray-200 text-xs font-medium">
                <Trans t={t} i18nKey="productName" values={{ name: product.name }} components={{ italic: <i /> }} />  
              </h6>
            </CardHeader>
          </div>
          {renderCurrentComponent()}
        </div>
      )}
    </>
  );
};

export default ShowProductPage;