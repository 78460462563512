import { Dialog } from "@material-tailwind/react";
import { useToggle } from "@src/helpers/hooks/useToggle";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import * as Yup from "yup";
import ImageBankService from "@src/services/imageBankService";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import ImageBankForm from "@src/components/image_bank/ImageBankForm";

const EditImageValidationSchema = Yup.object().shape({
  description: Yup.string()
    .required("components/imageBank/imageBankForm:validationErrors.description.empty")
    .min(5, "components/imageBank/imageBankForm:validationErrors.description.min5characters")
    .max(255, "components/imageBank/imageBankForm:validationErrors.description.max255characters"),
});

const EditImageDialog = ({ image, backCallback, finalizeImageEditionCallback }) => {
  const [selectedFile, setSelectedFile] = useState();
  const [w18ApiResponse, setW18ApiResponse] = useToggle();
  const { t, ready: translationsReady } = useTranslation([
    "components/imageBank/editImageDialog",
    "components/imageBank/imageBankForm",
  ]);

  const performImageModification = async (values) => {
    setW18ApiResponse();
    try {
      await ImageBankService.update(image.id, values, selectedFile);
      createNotification(NotificationTypes.success, t("editImageAction.ok"));
      await finalizeImageEditionCallback();
    } catch (error) {
      createNotification(NotificationTypes.error, t("editImageAction.ko"));
    } finally {
      setW18ApiResponse();
    }
  };

  return !translationsReady ? (
    <Spinner />
  ) : (
    <Dialog open={!!image} handler={backCallback}>
      <ImageBankForm
        initialValues={image}
        validationSchema={EditImageValidationSchema}
        showLoadingSpinner={w18ApiResponse}
        title={t("title")}
        editing={true}
        setSelectedFileCallback={setSelectedFile}
        submitCallback={performImageModification}
        backCallback={backCallback}
      />
    </Dialog>
  );
};

export default EditImageDialog;
