import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "@src/components/ui/Button";
import Spinner from "@src/components/ui/Spinner";
import { useTranslation } from "react-i18next";
import TrashButton from "@src/components/ui/buttons/TrashButton";
import { isPastDate, stringDateFormat } from "@src/helpers/dateHelpers";
import { faBoxes } from "@fortawesome/free-solid-svg-icons";
import TableCard from "@src/components/ui/TableCard";

const ListDatesTable = ({ currentPageData : dateList, currentPage, deleteDateCallback, showResourcesOfDateCallback, readOnly }) => {
  const { t, ready: translationsReady } = useTranslation("components/services/edit/dates/list");

  const getHeadersArray = () => Object.values(t("labels.listDatesTableHeaders"));

  const formatDataMatrix = () =>
    dateList.map(date => [
      stringDateFormat(date.date),
      <>
        <Button
          className="bg-gray-300"
          onClick={() => showResourcesOfDateCallback(date)}
          title={t("labels.viewDateResourcesTitle")}
        ><FontAwesomeIcon icon={faBoxes} /></Button>
        {!readOnly &&
          <TrashButton
            onClick={() => deleteDateCallback(date)}
            disabled={date.canceled_at || isPastDate(new Date(date.date)) || (currentPage === 0 && dateList.length < 2)}
          />
        }
      </>
    ]);

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
          title={t("labels.title")}
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

export default ListDatesTable;