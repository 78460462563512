import BasicFormTemplate, { FooterType } from '@src/components/forms/BasicFormTemplate';
import FormikTextArea from '@src/components/forms/FormikTextArea';
import FormikTextInput from '@src/components/forms/FormikTextInput';
import Spinner from '@src/components/ui/Spinner';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormikSelect from '@src/components/forms/FormikSelect';
import { SupportedLocalesArray } from '@src/helpers/localeHelpers';
import CountryFlag from '@src/components/ui/CountryFlag';

const ProductDetailsIntialValues = {
  language: "",
  name: "",
  description: "",
  conditions: "",
};

const ProductTranslationForm = ({ title, showLoadingSpinner, initialValues, backCallback, submitCallback, readOnly, languagesAlreadyInUse }) => {
  const { t, ready: translationsReady } = useTranslation(["components/products/edit/editProductTranslations", "common/form", "pages/products/create"]);

  const validateForm = ({ language, name, description, conditions }) => {
    const errors = {};

    if (!language) errors.language = "components/products/edit/editProductTranslations:form.validations.noLanguage";
    if (language && languagesAlreadyInUse && languagesAlreadyInUse.some(l => l === language)) {
      errors.language = "components/products/edit/editProductTranslations:form.validations.languageAlreadyInUse";
    }
    if (!name) errors.name = "common/form:validationErrors.empty.name";
    if (!description) errors.description = "components/products/edit/editProductTranslations:form.validations.noDescription";
    if (!conditions) errors.conditions = "components/products/edit/editProductTranslations:form.validations.noConditions";

    return errors;
  }

  const getOptionsForLanguageSelect = () =>
    SupportedLocalesArray.map(locale => ({
      value: locale,
      label: (
        <div className="flex">
          <CountryFlag
            className="w-[25px]"
            languageCode={locale}
          />
          <p className="ml-2">{locale}</p>
        </div>
      ),        
    }));

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="mt-10">
          <Formik
            initialValues={initialValues ?? ProductDetailsIntialValues}
            validate={validateForm}
            onSubmit={submitCallback}
          >
            <Form>
              <BasicFormTemplate
                formTitle={title}
                footerType={readOnly ? FooterType.BACK_BUTTON : FooterType.BACK_SUBMIT_BUTTONS}
                headerColor="green"
                backCallback={backCallback}
                showLoadingSpinner={showLoadingSpinner}
              >
                <div className="space-y-4">
                  {!initialValues &&
                    <FormikSelect
                      label={t("languageTableHeader")}
                      name="language"
                      options={getOptionsForLanguageSelect()}
                      shouldNotTranslateLabel={true}
                    />
                  }
                  <FormikTextInput
                    label={t("form.labels.name")}
                    name="name"
                    disabled={readOnly}
                  />
                  <FormikTextArea
                    label={t("form.labels.description")}
                    name="description"
                    disabled={readOnly}
                  />
                  <FormikTextArea
                    label={t("form.labels.conditions")}
                    name="conditions"
                    disabled={readOnly}
                  />
                </div>
              </BasicFormTemplate>
            </Form>      
          </Formik>
        </div>
      )}
    </>
  );
};

export default ProductTranslationForm;