import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { emptyArray } from "@src/helpers/arrayHelpers";
import ErrorText from "@src/components/ui/ErrorText";
import { Dialog} from "@material-tailwind/react";
import PaginateData from "@src/components/ui/PaginateData";
import Spinner from "@src/components/ui/Spinner";
import AddDeleteSelectAllButtons from "@src/components/products/product_wizard/AddDeleteSelectAllButtons";
import BackNextSubmitButtons from "@src/components/products/product_wizard/BackNextSubmitButtons";
import DateRangesForResourcesTable from "@src/components/products/services/create/DateRangesForResourcesTable";
import ResourcesSelectedDetails from "@src/components/products/services/create/ResourcesSelectedDetails";
import ResourcesSelector from "@src/components/products/services/create/ResourcesSelector";
import { Card } from "@material-tailwind/react";
import BasicFormTemplate, {
  FooterType,
} from "@src/components/forms/BasicFormTemplate";

const ResourcesConfiguration = ({
  editingProductConfigurations,
  currentStep,
  numberSteps,
  initialValues,
  callbackBackClicked,
  callbackNextClicked,
}) => {
  const [showResourcesSelectorModal, setShowResourcesSelectorModal] =
    useState(false);
  const [showResourcesTableModal, setShowResourcesTableModal] = useState(false);
  const [selectedRowForDetails, setSelectedRowForDetails] = useState();
  const [
    selectedRowsForResourceSelection,
    setSelectedRowsForResourcesSelection,
  ] = useState([]);
  const [indexDateRangeToEdit, setIndexDateRangeToEdit] = useState();
  const [dateRanges, setDateRanges] = useState(initialValues);
  const [error, setError] = useState();
  const { t, ready: translationsReady } = useTranslation([
    "components/services/create/resources",
    "pages/services/create",
    "common/navigation",
  ]);
  const actions = {
    add: "ADD",
    delete: "DELETE",
    submit: "SUBMIT",
  };

  const toggleSelectionAllRows = () => {
    if (selectedRowsForResourceSelection.length === dateRanges.length) {
      setSelectedRowsForResourcesSelection([]);
    } else {
      setSelectedRowsForResourcesSelection([
        ...Array(dateRanges.length).keys(),
      ]);
    }
  };

  const areResourcesSetForDateRange = (dateRange) =>
    dateRange.resources != null;

  const checkErrors = (action) => {
    if (
      (action === actions.add || action === actions.delete) &&
      emptyArray(selectedRowsForResourceSelection)
    ) {
      setError("mainScreen.validations.noRowSelected");
      return true;
    }

    if (
      action === actions.delete &&
      selectedRowsForResourceSelection.some(
        (index) => !areResourcesSetForDateRange(dateRanges.at(index))
      )
    ) {
      setError("mainScreen.validations.noResourcesToDelete");
      return true;
    }

    if (
      action === actions.submit &&
      dateRanges.some((event) => !areResourcesSetForDateRange(event))
    ) {
      setError("mainScreen.validations.allRowsWithResources");
      return true;
    }

    setError(null);
    return false;
  };

  const openAddResourcesModal = () => {
    if (!checkErrors(actions.add)) setShowResourcesSelectorModal(true);
  };

  const openEditResourcesModal = (dateRangeIndex) => {
    setIndexDateRangeToEdit(dateRangeIndex);
    setShowResourcesSelectorModal(true);
  };

  const closeResourcesSelector = () => {
    setIndexDateRangeToEdit(null);
    setShowResourcesSelectorModal(false);
  };

  const openDeleteResourcesModal = () => {
    if (!checkErrors(actions.delete))
      if (window.confirm(t("mainScreen.deleteResourcesMessage"))) {
        setDateRanges(
          dateRanges.map((dateRange, index) => {
            if (selectedRowsForResourceSelection.some((i) => i === index)) {
              return {
                ...dateRange,
                resources: null,
              };
            } else return dateRange;
          })
        );

        setSelectedRowsForResourcesSelection([]);
      }
  };

  const handleNextStep = () => {
    if (!checkErrors(actions.submit)) {
      callbackNextClicked(dateRanges);
    }
  };

  const showResourceTable = (index) => {
    setSelectedRowForDetails(index);
    setShowResourcesTableModal(true);
  };

  const handleResourcesSelection = (data) => {
    setDateRanges(
      dateRanges.map((dateRange, index) => {
        if (selectedRowsForResourceSelection.some((i) => i === index)) {
          return {
            ...dateRange,
            resources: data,
          };
        } else if (index === indexDateRangeToEdit) {
          return {
            ...dateRanges.at(indexDateRangeToEdit),
            resources: data,
          };
        } else return dateRange;
      })
    );

    setShowResourcesSelectorModal(false);
    setIndexDateRangeToEdit(null);
    setSelectedRowsForResourcesSelection([]);
  };

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <>
          <div className="grid grid-cols-1 max-w-full ">
            <Card className="place-self-center px-4 py-2">
              <AddDeleteSelectAllButtons
                addBtnDisabled={emptyArray(selectedRowsForResourceSelection)}
                deleteBtnDisabled={
                  emptyArray(selectedRowsForResourceSelection) ||
                  selectedRowsForResourceSelection.some(
                    (index) => !dateRanges.at(index).regularityType
                  )
                }
                cbAdd={openAddResourcesModal}
                cbDelete={openDeleteResourcesModal}
                cbSelectAll={toggleSelectionAllRows}
              />
            </Card>
            <ErrorText>{t(error)}</ErrorText>
            <div className="grid grid-cols-1 place-items-center">
              {dateRanges && (
                <PaginateData data={dateRanges}>
                  <DateRangesForResourcesTable
                    numElems={dateRanges.length}
                    selectedIndexes={selectedRowsForResourceSelection}
                    cbSetSelectedIndexes={setSelectedRowsForResourcesSelection}
                    areResourcesSetForDateRange={areResourcesSetForDateRange}
                    cbShowResourceDetails={showResourceTable}
                    cbOpenEditResourcesModal={openEditResourcesModal}
                  />
                </PaginateData>
              )}
            </div>
            <ResourcesSelector
              open={showResourcesSelectorModal}
              handler={() => setShowResourcesSelectorModal(false)}
              disableWidthRestriction={true}
              cbSubmitResourcesSelection={handleResourcesSelection}
              cbCancelResourceSelector={closeResourcesSelector}
              resourcesToEdit={
                indexDateRangeToEdit != null
                  ? dateRanges.at(indexDateRangeToEdit).resources
                  : null
              }
            />
            <Dialog
              open={showResourcesTableModal}
              handler={setShowResourcesTableModal}
            >
              <div className="m-2 space-y-2">
                <BasicFormTemplate
                  className="mt-8"
                  formTitle={t("mainScreen.resourceDetailsDescription")}
                  backCallback={() => setShowResourcesTableModal(false)}
                  footerType={FooterType.BACK_BUTTON}
                  headerColor="teal"
                >
                  <ResourcesSelectedDetails
                    resources={dateRanges.at(selectedRowForDetails).resources}
                  />
                </BasicFormTemplate>
              </div>
            </Dialog>
            <BackNextSubmitButtons
              editingProductConfigurations={editingProductConfigurations}
              currentStep={currentStep}
              numberSteps={numberSteps}
              cbBackButton={callbackBackClicked}
              cbNextButton={handleNextStep}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ResourcesConfiguration;
