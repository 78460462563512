import React from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from '@src/components/ui/Spinner';
import { Form, Formik } from 'formik';
import FormikTextInput from '@src/components/forms/b2c/FormikTextInput';
import HistoryBackButton from '@src/components/ui/b2c/buttons/HistoryBackButton';
import SubmitButton from '@src/components/ui/b2c/buttons/SubmitButton';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import OptionalSubmitButton from '@src/components/ui/b2c/buttons/OptionalSubmitButton';

const AddCompanyDataInitialValues = {
  cif: "",
  fiscalName: "",
  province: "",
  commercialName: "",
  zipCode: "",
  address: "",
  country: "",
  managerName: "",
  managerPosition: "",
  managerLandlineNumber: "",
  managerPhoneNumber: "",
};

const AddCompanyDataRegistrationForm = ({ title, showLoadingSpinnerCompanyDataSubmission, showLoadingSpinnerSkipCompanyDataSubmission, backCallback, skipCompanySubmissionCallback, submitCompanyDataCallback }) => {
  const { t, ready: translationsReady } = useTranslation(["components/users/companyDataForm", "pages/users/create", "common/form"]);
  const submitDisabled = values =>
    !(values.cif && values.fiscalName && values.province && values.commercialName && values.address && values.managerName && values.managerLandlineNumber);

  return !translationsReady ? <Spinner /> : (
    <Formik
      initialValues={AddCompanyDataInitialValues}
      onSubmit={submitCompanyDataCallback}
    >
      {({ values }) => (
        <Form>
          <div className="flex flex-col items-center my-24 p-10 rounded-[28px] bg-white shadow-2xl">
            <div className="flex flex-col items-center w-full">
              <h1 className="leading-7 -mt-px tracking-normal whitespace-no-wrap montserrat-bold-shark-36px">{title}</h1>
              <p className="mt-4 text-red-400 font-bold">{t("requiredFieldDisclaimer", { ns: "common/form" })}</p>
              <div className="grid grid-cols-2 items-center w-full mt-8 space-y-8">
                <FormikTextInput
                  name="cif"
                  placeholder={t("labels.cif") + " *"}
                />
                <div />
                <FormikTextInput
                  name="fiscalName"
                  placeholder={t("labels.fiscalName") + " *"}
                />
                <FormikTextInput
                  name="province"
                  placeholder={t("labels.province") + " *"}
                />
                <FormikTextInput
                  name="commercialName"
                  placeholder={t("labels.commercialName") + " *"}
                />
                <FormikTextInput
                  name="zipCode"
                  placeholder={t("labels.zipCode")}
                />
                <FormikTextInput
                  name="address"
                  placeholder={t("labels.address") + " *"}
                />
                <FormikTextInput
                  name="country"
                  placeholder={t("labels.country")}
                />
                <FormikTextInput
                  name="managerName"
                  placeholder={t("labels.managerName") + " *"}
                />
                <FormikTextInput
                  name="managerPosition"
                  placeholder={t("labels.managerPosition")}
                />
                <FormikTextInput
                  name="managerLandlineNumber"
                  placeholder={t("labels.managerLandlineNumber") + " *"}
                />
                <FormikTextInput
                  name="managerPhoneNumber"
                  placeholder={t("labels.managerPhoneNumber")}
                />
              </div>
            </div>
            <div className="flex items-start mt-6 w-full justify-between">
              <HistoryBackButton onClick={backCallback} />
              <OptionalSubmitButton
                onClick={skipCompanySubmissionCallback}
                showLoadingSpinner={showLoadingSpinnerSkipCompanyDataSubmission}
              >{t("skipCompanyDataFullfillmentBtn", { ns: "pages/users/create" })}</OptionalSubmitButton>
              <SubmitButton
                disabled={submitDisabled(values)}
                icon={faPaperPlane}
                showLoadingSpinner={showLoadingSpinnerCompanyDataSubmission}
              />
            </div>
            <p className="mt-6">{t("messages.noCompanyDataDisclaimer", { ns: "pages/users/create" })}</p>
          </div>
        </Form>
      )}
    </Formik>
  );
}
 
export default AddCompanyDataRegistrationForm;