import React, { useState } from 'react';
import DefinedRoutes from "@src/components/DefinedRoutes";
import Footer from "@src/components/ui/Footer";
import Sidebar from "@src/components/ui/Sidebar";
import { useEffect } from 'react';
import UserService from '@src/services/users/userService';
import InfoDialog from '@src/components/ui/dialogs/InfoDialog';
import { useTranslation } from 'react-i18next';
import Spinner from '@src/components/ui/Spinner';
import { isProfessional } from '@src/helpers/userHelpers';
import { LocalStorageKeys } from '@src/helpers/localStorageHelpers';
import { isCurrentSessionForImpersonatingUser } from '@src/helpers/sessionHelpers';
import ImpersonateUserAlert from '@src/components/ui/ImpersonateUserAlert';
import { ProfessionalValidationStatusStrings } from '@src/helpers/stringHelpers';
import ProfessionalValidationStatusAlert from '@src/components/ui/alerts/ProfessionalValidationStatusAlert';

const B2BApp = () => {
  const [showDisabledProfessionalDialog, setShowDisabledProfessionalDialog] = useState(false);
  const [showProfessionalValidationStatusDialog, setShowProfessionalValidationStatusDialog] = useState(false);
  const [professionalValidationStatus, setProfessionalValidationStatus] = useState();
  const { t, ready: translationsReady } = useTranslation(["common/common", "components/users/professionalValidationStatus"]);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const currentUser = await UserService.showCurrentUser();
        let professionalValidationStatus;

        if (isProfessional()) {
          if (!currentUser.company) {
            professionalValidationStatus = ProfessionalValidationStatusStrings.awaitingCompanyData;
          } else if (!currentUser.validatedAt && !currentUser.revokedAt) {
            professionalValidationStatus = ProfessionalValidationStatusStrings.reviewPending;
          } else if (currentUser.revokedAt) {
            professionalValidationStatus = ProfessionalValidationStatusStrings.accountRevoked;
          } else if (currentUser.disabledAt) {
            setShowDisabledProfessionalDialog(true);
          }

          setProfessionalValidationStatus(professionalValidationStatus);
          setShowProfessionalValidationStatusDialog(!!professionalValidationStatus);
        }

        // Needed for userHelpers.js -> currentUserHasWritePermissions()
        localStorage.setItem(LocalStorageKeys.userDisabled, currentUser.disabledAt?.toISOString());

        // Needed for userHelpers.js -> currentProfessionalIsValidated()
        localStorage.setItem(LocalStorageKeys.professionalValidated, currentUser.validatedAt?.toISOString());
      } catch (err) { console.log(err) }
    };

    translationsReady && fetchCurrentUser();
  }, [translationsReady]);

  return !translationsReady ? <Spinner /> : (
    <>
      <Sidebar />
      <div className="mx-4">
        <div className="pb-[50px] mt-4 md:ml-64">
          {isCurrentSessionForImpersonatingUser() &&
            <ImpersonateUserAlert />
          }
          {professionalValidationStatus &&
            <ProfessionalValidationStatusAlert validationStatus={professionalValidationStatus} />
          }
          <DefinedRoutes />
        </div>
        <Footer />
      </div>
      <InfoDialog
        open={showDisabledProfessionalDialog}
        unavoidable={true}
        okCallback={() => setShowDisabledProfessionalDialog(false)}
      >{t("disabledProfessionalMessage", { ns: "common/common" })}</InfoDialog>
      {professionalValidationStatus &&
        <InfoDialog
          open={showProfessionalValidationStatusDialog}
          unavoidable={true}
          okCallback={() => setShowProfessionalValidationStatusDialog(false)}
        >{t(`${professionalValidationStatus}.long`, { ns: "components/users/professionalValidationStatus" })}</InfoDialog>
      }
    </>
  );
}
 
export default B2BApp;