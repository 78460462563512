import { useFormikContext } from "formik";
import React from "react";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { getProductByName} from "@src/helpers/productHelpers"
import FormSearchInput from "@src/components/forms/FormSearchInput";

const ProductFormSearch = ({
  onChange,
  label,
  placeholder,
  name,
  loadOptionsErrorMsg,
  noOptionsMessage,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();

  const loadOptions = (inputValue) =>
    getProductByName(
      inputValue
    )
      .then((res) => res.data)
      .catch((err) =>
        createNotification(NotificationTypes.error, loadOptionsErrorMsg)
      );

  const getOptionValue = (option) => option;
  const getOptionLabel = (option) => option.name;

  const handleChange = (option) => {
    if (onChange) {
      onChange(option);
    } else {
      setFieldValue(name, option ? option : null);
    }
  };

  return (
    <FormSearchInput
      label={label}
      placeholder={placeholder}
      name={name}
      isClearable={true}
      noOptionsMessage={noOptionsMessage}
      cbLoadOptions={loadOptions}
      cbGetOptionLabel={getOptionLabel}
      cbGetOptionValue={getOptionValue}
      cbHandleChange={handleChange}
      {...props}
    />
  );
};

export default ProductFormSearch;
