import { useFormikContext } from "formik";
import React from "react";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import FormSearchInput from "@src/components/forms/FormSearchInput";
import UserService from "@src/services/users/userService";

const FormUserSearch = ({
  userTypesArray,
  label,
  secondLabel,
  placeholder,
  name,
  loadOptionsErrorMsg,
  defaultUser,
  noOptionsMessage,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();

  const loadOptions = async inputValue => {
    let users = [];
    try {
      users = await UserService.indexUsersGivenPartialEmailAndUserTypes(inputValue, userTypesArray);
    } catch (err) {
      createNotification(NotificationTypes.error, loadOptionsErrorMsg);
    }
    return users;
  };

  const getOptionValue = option => option.id;
  const getOptionLabel = option => option.email;

  const handleChange = option => setFieldValue(name, option ? option.id : null);

  return (
    <FormSearchInput
      label={label}
      secondLabel={secondLabel}
      placeholder={placeholder}
      name={name}
      noOptionsMessage={noOptionsMessage}
      defaultValue={defaultUser}
      isClearable={true}
      cbLoadOptions={loadOptions}
      cbGetOptionLabel={getOptionLabel}
      cbGetOptionValue={getOptionValue}
      cbHandleChange={handleChange}
      {...props}
    />
  );
};

export default FormUserSearch;
