import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import FormikTextInput from "@src/components//forms/FormikTextInput";
import Spinner from "@src/components//ui/Spinner";
import { isServicesProvider } from "@src/helpers/userHelpers";


const FormInitialValues = {
  number: "",
  title: "",
  body: "",
};


const FilterNewsletterForm = ({
  className,
  color,
  cbSetNumber,
  cbSetTitle,
  cbSetBody,
}) => {
  const { t, ready: translationsReady } = useTranslation([
    "components/services/create/basicInfoForm",
    "common/filterForm",
    "common/form",
    "components/newsletters/filterForm"
  ]);
  const DEFAULT_COLOR = "light-blue";


  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <Card className={className}>
          <CardHeader
            color={color ?? DEFAULT_COLOR}
            variant="gradient"
            className="p-5"
          >
            <Typography variant="h5" color="white">
              {t("description", { ns: "common/filterForm" })}
            </Typography>
          </CardHeader>
          <CardBody>
            <Formik initialValues={FormInitialValues}>
              {({ handleChange }) => (
                <Form className="grid  sm:grid-flow-row lg:grid-flow-col  lg:space-x-6 sm:items-start lg:items-center w-full">
                  <FormikTextInput
                      name="number"
                      type="number"
                      color={color ?? DEFAULT_COLOR}
                      label={t("labels.number", { ns: "components/newsletters/filterForm" })}
                      onChange={(e) => {
                        handleChange(e);
                        cbSetNumber(e.target.value);
                      }}
                  />
                  <FormikTextInput
                    name="title"
                    type="title"
                    color={color ?? DEFAULT_COLOR}
                    label={t("labels.title", { ns: "components/newsletters/filterForm" })}
                    onChange={(e) => {
                      handleChange(e);
                      cbSetTitle(e.target.value);
                    }}
                  />
                  {!isServicesProvider() && (
                    <FormikTextInput
                      name="body"
                      type="body"
                      color={color ?? DEFAULT_COLOR}
                      label={t("labels.body", { ns: "components/newsletters/filterForm" })}
                      onChange={(e) => {
                        handleChange(e);
                        cbSetBody(e.target.value);
                      }}
                    />
                  )}
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      )}
    </>
  );
};
export default FilterNewsletterForm;
