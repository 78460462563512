import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "@src/components/ui/Button";
import Spinner from "@src/components/ui/Spinner";
import { useTranslation } from "react-i18next";
import TrashButton from "@src/components/ui/buttons/TrashButton";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { formatPrice } from "@src/helpers/localeHelpers";
import TableCard from "@src/components/ui/TableCard";

const ListResourcesTable = ({ currentPageData : resourceList, currentPage, deleteResourceCallback, showResourceUnitsCallback, readOnly, title }) => {
  const { t, ready: translationsReady } = useTranslation("components/services/edit/resources/list");

  const getHeadersArray = () => Object.values(t("labels.listResourcesTableHeaders"));

  const formatDataMatrix = () => resourceList.map(resource => [
    resource.name,
    resource.quantity,
    formatPrice(resource.priceHour),
    <>
      <Button
        className="bg-gray-300"
        onClick={() => showResourceUnitsCallback(resource)}
        title={t("labels.viewResourceUnitsTitle")}
      ><FontAwesomeIcon icon={faFolderOpen} /></Button>
      {!readOnly &&
        <TrashButton
          onClick={() => deleteResourceCallback(resource)}
          disabled={currentPage === 0 && resourceList.length < 2}
        />
      }
    </>
  ]);

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
          title={title}
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

export default ListResourcesTable;