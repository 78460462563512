import KoImg from '@src/assets/images/error.svg';
import Spinner from "@src/components/ui/Spinner";
import { useTranslation } from "react-i18next";

import "@src/assets/styles/b2c/pages/list_orders/globals.css";
import "@src/assets/styles/b2c/pages/list_orders/list-orders.css";
import "@src/assets/styles/b2c/pages/list_orders/styleguide.css";
import { Typography } from '@material-tailwind/react';
import HistoryBackButton from "@src/components/ui/buttons/HistoryBackButton";
import { useHistory } from 'react-router-dom';
import { CommonRoutesStrings } from '@src/helpers/stringHelpers';

const ShowRedsysKoPage = () => {
  const { t, ready: translationsReady } = useTranslation("pages/orders/create");
  const history = useHistory();
  const goHome = () => history.push(CommonRoutesStrings.homePath);

  return (
    <div className="bg-white py-20 px-[135.9px] font-['Montserrat']  mx-auto w-full max-w-[1440px]">
      {!translationsReady ? (
        <Spinner />
      ) : (
        <>
          <div className="flex space-x-2 mr-auto">
            <img style={{ maxWidth: "50px", maxHeight: "50px" }} src={KoImg} alt="Payment-KO" />
            <Typography className="font-extrabold sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl leading-8 text-[#e30746]">{t("paymentKo")}</Typography>
          </div>
          <br />
          <HistoryBackButton onClick={goHome} />
        </>
      )}
    </div>
  );
};

export default ShowRedsysKoPage;
