import BasicFormTemplate, { FooterType } from "@src/components/forms/BasicFormTemplate";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { emptyArray } from "@src/helpers/arrayHelpers";
import Spinner from "@src/components/ui/Spinner";
import IrregularAvailabilitySelector from "@src/components/products/product_wizard/IrregularAvailabilitySelector";

const DateSelector = ({ initialValues, currentConfiguration, alreadyExistentDates, callbackNextClicked, callbackBackClicked }) => {
  const { t, ready: translationsReady } = useTranslation(["components/products/create/availability", "pages/products/create"]);

  const [configurations, setConfigurations] = useState(emptyArray(initialValues) ? [] : initialValues);

  const processDates = () => {
    const dateRange = configurations[0], dates = dateRange.dates;
    dates.sort();
    return [{
      fromDate: dates[0],
      toDate: dates.at(-1),
      dates
    }];
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <BasicFormTemplate
          formTitle={t("stepTitles", { ns: "pages/products/create" }).availability}
          backCallback={callbackBackClicked}
          nextCallback={() => callbackNextClicked(processDates())}
          footerType={FooterType.BACK_NEXT_BUTTONS}
          headerColor="teal"
          className="mt-10 max-w-3xl mx-auto"
          disableSubmitButton={emptyArray(configurations)}
        >
          <IrregularAvailabilitySelector
              currentConfiguration={currentConfiguration}
              alreadyExistentDates={alreadyExistentDates}
              creatingSingleDate={true}
              configurations={configurations}
              setConfigurations={setConfigurations}
            />
        </BasicFormTemplate>
      )}
    </>
  );
};

export default DateSelector;