import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import OrderDisplay from "@src/components/orders/b2c/list/OrderDisplay";
import { useHistory, useParams } from "react-router-dom";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { CommonRoutesStrings } from "@src/helpers/stringHelpers";
import OrderService from "@src/services/orders/orderService";
import Spinner from "@src/components/ui/Spinner";

import "@src/assets/styles/b2c/pages/list_orders/list-orders.css";
import "@src/assets/styles/b2c/pages/list_orders/globals.css";
import "@src/assets/styles/b2c/pages/list_orders/styleguide.css";

const ShowGuestOrderPage = () => {
  const [order, setOrder] = useState();
  const { t, ready: translationsReady } = useTranslation("pages/b2c/orders/showGuestOrder");
  const { id: orderBase64Id } = useParams();
  const history = useHistory();

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        setOrder(await OrderService.showAsGuest(orderBase64Id));
      } catch (err) {
        createNotification(NotificationTypes.error, t("genericRequestError"));
        history.replace(CommonRoutesStrings.homePath);
      }
    };

    translationsReady && fetchOrder();
    // eslint-disable-next-line
  }, [translationsReady]);

  return (
    <div className="bg-white py-20 px-[135.9px] font-['Montserrat']  mx-auto w-full max-w-[1440px]">
      {!translationsReady ? (
        <Spinner />
      ) : (
        <>
          <p className="font-extrabold sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl leading-8 text-[#e30746]">{t("title")}</p>
          {!order ? <Spinner /> : <OrderDisplay className="mt-10  mx-auto" order={order} printing={true} />}
        </>
      )}
    </div>
  );
};

export default ShowGuestOrderPage;
