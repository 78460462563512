import React from 'react';

const IconButton = ({ className: additionalClasses, children, ...props}) => (
  <button
    className={`shrink-0 w-[40px] h-[40px] bg-[#E30746] rounded-full hover:brightness-95 shadow-md disabled:shadow-none disabled:cursor-default disabled:brightness-100 disabled:opacity-70 ${additionalClasses ?? ""}`}
    {...props}
  >
    {children}
  </button>
);
 
export default IconButton;