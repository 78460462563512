import BasicFormTemplate, { FooterType } from '@src/components/forms/BasicFormTemplate';
import Button from '@src/components/ui/buttons/Button';
import PaginateData from '@src/components/ui/PaginateData';
import Spinner from '@src/components/ui/Spinner';
import { useToggle } from '@src/helpers/hooks/useToggle';
import { createNotification, NotificationTypes } from '@src/helpers/notificationHelpers';
import ProductService from '@src/services/products/productService';
import SupplementService from '@src/services/supplementService';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import SelectedSupplementsTable from '@src/components/products/edit_product_data/supplements/SelectedSupplementsTable';
import SupplementSelectorDialog from '@src/components/products/edit_product_data/supplements/SupplementSelectorDialog';
import { emptyArray } from '@src/helpers/arrayHelpers';

const EditProductSupplements = ({ productData, handleSupplementSelectionCallback, handleSupplementDeselectionCallback, readOnly, backCallback }) => {
  const [selectedSupplements, setSelectedSupplements] = useState();
  const [showSupplementSelectorDialog, setShowSupplementSelectorDialog] = useState(false);
  const [w18ApiResponse, toggleW18ApiResponse] = useToggle();
  const { t, ready: translationsReady } = useTranslation(["components/products/edit/supplements/mainComponent", "common/list"]);
  const { id: productId } = useParams();
  const SELECTED_SUPPLEMENTS_PER_PAGE = 5;

  const handleSupplementDeselection = async (supplementToDeselect) => {
    toggleW18ApiResponse();

    try {
      await ProductService.deleteSupplementFromProduct(productId, supplementToDeselect.id);
      createNotification(NotificationTypes.success, t("deselectAction.ok"));
      setSelectedSupplements(selectedSupplements.filter(supplement => supplement.id !== supplementToDeselect.id));
      handleSupplementDeselectionCallback(supplementToDeselect);
    } catch (err) {
      createNotification(NotificationTypes.error, t("deselectAction.ko"));
    } finally {
      toggleW18ApiResponse();
    }
  };

  const finalizeSupplementSelection = supplement => {
    setSelectedSupplements(selectedSupplements.concat(supplement));
    setShowSupplementSelectorDialog(false);
    handleSupplementSelectionCallback(supplement);
  };

  useEffect(() => {
    const fetchSupplements = async () => {
      try {
        if (emptyArray(productData.supplements)) {
          setSelectedSupplements([]);
        } else {
          setSelectedSupplements(await SupplementService.indexSupplementsWithIds(productData.supplements.map(s => s.id)));
        }
      } catch (err) {
        createNotification(NotificationTypes.error, t("genericRequestError"));
        backCallback();
      }
    };

    translationsReady && fetchSupplements();
    // eslint-disable-next-line
  }, [translationsReady]);

  return !translationsReady ? (
    <Spinner />
  ) : (
    <>
      <BasicFormTemplate
        formTitle={t("title")}
        backCallback={backCallback}
        footerType={FooterType.BACK_BUTTON}
        headerColor="teal"
        className="mt-10 max-w-3xl mx-auto"
      >
        <div className="space-y-4 mx-6 justify-center">
          <p className="mt-2 text-center">{t("description")}</p>
          {!readOnly && (
            <div className="flex justify-center">
              <Button color="green" onClick={() => setShowSupplementSelectorDialog(true)}>
                {t("add", { ns: "common/list" })}
              </Button>
            </div>
          )}
          {selectedSupplements &&
            <div className="grid grid-cols-1 place-items-center max-w-xl mx-auto">
              <PaginateData
                data={selectedSupplements}
                perPage={SELECTED_SUPPLEMENTS_PER_PAGE}
              >
                <SelectedSupplementsTable
                  deselectSupplementCallback={readOnly ? undefined : handleSupplementDeselection}
                />
              </PaginateData>
            </div>
          }
        </div>
        {w18ApiResponse && <Spinner />}
      </BasicFormTemplate>
      {selectedSupplements &&
        <SupplementSelectorDialog
          open={showSupplementSelectorDialog}
          selectedSupplementIds={selectedSupplements.map((supplement) => supplement.id)}
          handler={() => setShowSupplementSelectorDialog(false)}
          finalizeSupplementSelection={finalizeSupplementSelection}
        />
      }
    </>
  );
};
 
export default EditProductSupplements;