import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const GrayIconButton = ({ icon, ...props }) => {
  const color = "gray-300",
    styling = "inline-grid place-items-center "
     + "text-center uppercase transition-all w-10 max-w-[40px] h-10 "
     + `max-h-[40px] rounded-lg bg-${color} shadow-md `
     + `shadow-${color}/20 hover:shadow-lg hover:shadow-${color}/40 `
     + "focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] "
     + "active:shadow-none disabled:opacity-50 disabled:shadow-none";

  return (
    <button
      className={styling}
      {...props}
    ><FontAwesomeIcon icon={icon} /></button>
  );
};

export default GrayIconButton;