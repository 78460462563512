import axios from "@src/helpers/axiosInstance";
import { productDeadlineSaleToDate } from "@src/helpers/dateHelpers";
import { getJWT } from "@src/helpers/localStorageHelpers";
import { APIEndpointsStrings } from "@src/helpers/stringHelpers";

class ProductTimesliceService {
  static show = async (productId, productDateId, timesliceId) => {
    const requestEndpointWithIDs = APIEndpointsStrings.productTimeslicePath
      .replace(APIEndpointsStrings.idLocators.productId, productId)
      .replace(APIEndpointsStrings.idLocators.productDateId, productDateId)
      .replace(APIEndpointsStrings.idLocators.id, timesliceId);

    const res = await axios.get(requestEndpointWithIDs, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });

    return this.formatReceivedProductTimeslice(res.data, true);
  };

  static formatReceivedProductTimeslice = (timeslice, priceHasClientTypeData) => ({
    id: timeslice.id,
    quota: timeslice.quota,
    available: timeslice.available,
    duration: timeslice.duration,
    language: timeslice.language,
    beginsAt: timeslice.begins_at,
    deletedAt: !timeslice.deleted_at ? null : new Date(timeslice.deleted_at),
    disabledAt: !timeslice.disabled_at ? null : new Date(timeslice.disabled_at),
    cancelledAt: !timeslice.cancelled_at ? null : new Date(timeslice.cancelled_at),
    cancelledWhy: timeslice.cancelled_why,
    deadlineSale: timeslice.deadline_sale ? productDeadlineSaleToDate(timeslice.deadline_sale) : null,
    resourceUnitsTaken: timeslice.unit_services_taken?.map(resourceUnitTaken => ({
      serviceId: resourceUnitTaken.product_service_id,
      productDateId: resourceUnitTaken.date_product_id,
      serviceName: resourceUnitTaken.name_service,
      providerEmail: resourceUnitTaken.email_provider,
      resourceName: resourceUnitTaken.resource_name,
      priceHour: resourceUnitTaken.price_hour,
      capacity: resourceUnitTaken.capacity,
      startTime: resourceUnitTaken.time_start,
      endTime: resourceUnitTaken.time_end,
    })),
    prices: timeslice.prices.map(price => ({
      id: price.id,
      price: price.price,
      quota: price.quota,
      available: price.available,
      deletedAt: !price.deleted_at ? null : new Date(price.deleted_at),
      // priceHasClientTypeData === false indicates that price.type_client should be only the PeopleSegment ID
      clientType: !priceHasClientTypeData ? price.type_client : {
        id: price.type_client.id,
        name: price.type_client.name,
        quantity: price.type_client.quantity,
        deletedAt: price.type_client.deleted_at ? new Date(price.type_client.deleted_at) : null,
        createdAt: price.type_client.created_at ? new Date(price.type_client.created_at) : null,
        updatedAt: price.type_client.updated_at ? new Date(price.type_client.updated_at) : null,
      },
    })),
  });
};

export default ProductTimesliceService;