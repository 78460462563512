import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "@src/components/ui/Button";
import Spinner from "@src/components/ui/Spinner";
import { useTranslation } from "react-i18next";
import TrashButton from "@src/components/ui/buttons/TrashButton";
import { dateToStringDDMMYYYYHHMMSS } from "@src/helpers/dateHelpers";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { emptyArray } from "@src/helpers/arrayHelpers";
import IconButton from "@src/components/ui/buttons/IconButton";
import TableCard from "@src/components/ui/TableCard";

const ListResourceUnitsTable = ({ currentPageData : resourceUnitList, currentPage, disableResourceUnitCallback, deleteResourceUnitCallback, showResourceUnitTakenCallback, readOnly }) => {
  const { t, ready: translationsReady } = useTranslation(["components/services/edit/resource_units/list", "common/booleanResponse"]);

  const getHeadersArray = () => Object.values(t("labels.listResourceUnitsTableHeaders"));

  const formatDataMatrix = () => resourceUnitList.map(resourceUnit => [
    resourceUnit.id,
    resourceUnit.capacity,
    resourceUnit.rangeOfAvailability.startTime + " - " + resourceUnit.rangeOfAvailability.endTime,
    resourceUnit.disabledAt ?
      <>
        {t("no", { ns: "common/booleanResponse" })}
        {dateToStringDDMMYYYYHHMMSS(resourceUnit.disabledAt)}
      </>
      : 
      t("yes", { ns: "common/booleanResponse" }),
    <>
      <Button
        className="bg-gray-300"
        onClick={() => showResourceUnitTakenCallback(resourceUnit)}
        title={t("labels.viewResourceUnitTakenTitle")}
        disabled={emptyArray(resourceUnit.taken)}
      ><FontAwesomeIcon icon={faClock} /></Button>
      {!readOnly &&
        <>
          <IconButton
            color="yellow"
            variant="gradient"
            onClick={() => disableResourceUnitCallback(resourceUnit)}
            title={t("labels.disableResourceUnitAction.title")}
            disabled={resourceUnit.disabledAt}
          ><FontAwesomeIcon icon={faBan} color="white" /></IconButton>
          <TrashButton
            onClick={() => deleteResourceUnitCallback(resourceUnit)}
            disabled={currentPage === 0 && resourceUnitList.length < 2}
          />
        </>
      }
    </>
  ]);

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
        title={t("labels.title")}
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

export default ListResourceUnitsTable;