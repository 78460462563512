import { useFormikContext } from "formik";
import React from "react";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { getPeopleSegments } from "@src/helpers/peopleSegmentHelpers";
import FormSearchInput from "@src/components/forms/FormSearchInput";

const PeopleSegmentSearch = ({ label, placeholder, name, defaultPeopleSegment, loadOptionsErrorMsg, isMulti, yMargin, ...props }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const loadOptions = inputValue => 
    getPeopleSegments(inputValue)
      .then(res => res.data)
      .catch(err => {
        createNotification(NotificationTypes.error, loadOptionsErrorMsg);
      });

  const getOptionValue = option => option.id;
  const getOptionLabel = option => option.name;  
  
  const handleChange = async (option) => {
    if (isMulti) await setFieldValue(name, option.map(({ id }) => id));
    else await setFieldValue(name, option);
    setFieldTouched(name, true);
  };

  return (
    <FormSearchInput
      label={label}
      placeholder={placeholder}
      name={name}
      defaultOptions={true}
      isMulti={isMulti}
      defaultValue={defaultPeopleSegment}
      cbLoadOptions={loadOptions}
      cbGetOptionLabel={getOptionLabel}
      cbGetOptionValue={getOptionValue}
      cbHandleChange={handleChange}
      yMargin={yMargin}
      {...props}
    />    
  );
};

export default PeopleSegmentSearch;