import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Spinner from "@src/components/ui/Spinner";
import BasicFormTemplate, { FooterType } from "@src/components/forms/BasicFormTemplate";
import ErrorDisplayBox from "@src/components/ui/ErrorDisplayBox";
import FormikTextInput from "@src/components/forms/FormikTextInput";
import FormikCheckbox from "@src/components/forms/FormikCheckbox";

const ProductCategorySchema = Yup.object().shape({
  name: Yup.string().required("components/products/categories/form:noNameError"),
  public: Yup.boolean(),
});

const ProductCategoryInitialValues = {
  name: "",
  public: true,
};

const ProductCategoryForm = ({ initialValues, title, showLoadingSpinner, errors, handleSubmit, backCallback }) => {
  const { t, ready: translationsReady } = useTranslation("components/products/categories/form");

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <Formik
          initialValues={initialValues ? initialValues : ProductCategoryInitialValues}
          validationSchema={ProductCategorySchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <BasicFormTemplate
              className="mt-8"
              formTitle={title}
              showLoadingSpinner={showLoadingSpinner}
              footerType={FooterType.BACK_SUBMIT_BUTTONS}
              backCallback={backCallback}
            >
              <div className="space-y-2">
                {errors &&
                  <ErrorDisplayBox
                    boxDescription={t("errorBoxDescription")}
                    errors={errors}
                  />
                }
                <div className="my-6 px-4">
                  <FormikTextInput
                    name="name"
                    color="light-blue"
                    label={t("nameLabel")}
                  />
                </div>
                <div className="px-2">
                  <FormikCheckbox
                    name="public"
                    label={t("publicCheckboxLabel")}
                  />
                </div>
              </div>              
            </BasicFormTemplate>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default ProductCategoryForm;