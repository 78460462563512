import React, { useState } from "react";
import SubmitButton from "@src/components/ui/buttons/SubmitButton";
import { Form, Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { emptyArray } from "@src/helpers/arrayHelpers";
import FormLanguageSearch from "./FormLanguageSearch";
import { formatHour } from "@src/helpers/localeHelpers";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import Button from "@src/components/ui/buttons/Button";
import FormikTextInput from "@src/components/forms/FormikTextInput";
import CountryFlagWithLanguageCode from "@src/components/products/product_wizard/CountryFlagWithLanguageCode";

const BeginsAtSelectorInitialValue = {
  beginsAt: "",
  language: "",
};


const HoursSelector = ({ cbSubmitHourSelection, cbCancelHourSelector, hourSelectionsToModify }) => {
  const editingHoursSelections = hourSelectionsToModify != null;
  const [beginsAtList, setBeginsAtList] = useState(!editingHoursSelections ? [] : hourSelectionsToModify.times);
  const [languageList, setLanguageList] = useState(!editingHoursSelections ? [] : hourSelectionsToModify.languages);
  const [duration, setDuration] = useState(!editingHoursSelections ? "" : hourSelectionsToModify.duration);
  const [quota, setQuota] = useState(!editingHoursSelections ? "" : hourSelectionsToModify.quota);
  const { t, ready: translationsReady } = useTranslation(["components/products/create/timetables", "pages/products/create", "common/navigation", "common/form", "common/list"]);

  const removeBeginsAt = index => setBeginsAtList(beginsAtList.filter((beginsAt, i) => i !== index));

  const renderBeginsAtList = () => 
    <div className="flex space-x-1 my-2 overflow-x-auto">
      {beginsAtList.map((beginsAt, index) => 
        <div
          key={`beginsat-badge-${index}`}
          className=" rounded bg-gray-200 px-1 whitespace-nowrap"
        ><div className="d-flex flex-col">{formatHour(beginsAt[0])}<button type="button" className="px-1" onClick={() => removeBeginsAt(index)}><FontAwesomeIcon icon={faTimes} color="gray" /></button></div>{beginsAt[1].map((value) => <CountryFlagWithLanguageCode language={value} />)}</div>
      )}
    </div>;

  const validateBeginsAt = values => beginsAtList.some(beginsAt => beginsAt === values.beginsAt) ? 
    { beginsAt: "components/products/create/timetables:validations.hourAlreadySelected" } : {};

  const handleNewBeginsAt = async (values, actions) => {
    const newBeginsAtList = [...beginsAtList, [values.beginsAt, values.language]];
    newBeginsAtList.sort();
    setBeginsAtList(newBeginsAtList);

    actions.resetForm({ values: BeginsAtSelectorInitialValue});
    setLanguageList([])
  };

  const validateDurationQuota = values => {
    const errors = {};

    if (!values.duration) {
      errors.duration = "components/products/create/timetables:validations.noDurationSelected";
    } else if (!/^[1-9][0-9]*$/.test(values.duration)) {
      errors.duration = "components/products/create/timetables:validations.durationNotIntegerGreater0";
    }

    if (!values.quota) {
      errors.quota = "components/products/create/timetables:validations.noQuotaSelected";
    } else if (!/^[1-9][0-9]*$/.test(values.quota)) {
      errors.quota = "components/products/create/timetables:validations.quotaNotIntegerGreater0";
    }

    return errors;
  }

  const handleHourSelectionsSubmission = () => {
    cbSubmitHourSelection({
      duration: Number(duration),
      quota: Number(quota),
      times: beginsAtList,
      languages: languageList
    });
  };

  const isRequiredDataSubmitted = () => duration && quota && beginsAtList && !emptyArray(beginsAtList);

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="py-2 px-4">
          <p>{t("labels.hourSelector.description")}</p>
          <div className="grid grid-cols-1 mx-10">
            <Formik
              initialValues={{ duration, quota }}
              validate={validateDurationQuota}
              onSubmit={() => {}}
            >
              {({ handleChange }) => 
                <Form>
                  <div className="my-4">  
                  <FormikTextInput
                    label={t("labels.hourSelector.durationLabel")}
                    name="duration"
                    onChange={e => {
                      handleChange(e);
                      setDuration(e.target.value);
                    }}
                  />
                  </div>
                  <div className="my-4">
                  <FormikTextInput
                    label={t("labels.hourSelector.quotaLabel")}
                    name="quota"
                    onChange={e => {
                      handleChange(e);
                      setQuota(e.target.value);
                    }}
                  />
                  </div>
                </Form>          
              }
            </Formik>
            <Formik
              initialValues={BeginsAtSelectorInitialValue}
              validate={validateBeginsAt}
              onSubmit={handleNewBeginsAt}
            >
              {({ values }) => (
                <Form className="my-4">
                  <FormikTextInput
                    label={t("labels.hourSelector.beginsAtLabel")}
                    name="beginsAt"
                    type="time"
                  />
                  <FormLanguageSearch
                    label={t("labels.hourSelector.languageLabel")}
                    name="language"
                    loadOptionsErrorMsg={t("messages.errorRetrievingLanguages", { ns: "pages/products/create" })}
                    yMargin="2"
                    isMulti={true}
                    defaultLanguages={languageList}
                    cbSetLanguages={setLanguageList}
                  />         
                  {renderBeginsAtList()}
                  <SubmitButton
                    color="green"
                    disabled={(!values.beginsAt || !values.language)}
                  >{t("add", { ns: "common/list" })}</SubmitButton>            
                </Form>
              )}
            </Formik>
          </div>
          <div className="flex my-2">
            <Button
              onClick={cbCancelHourSelector}
              className="mr-auto"
              color="gray"
            >{t("back", { ns: "common/navigation" })}</Button>
            <Button
              onClick={handleHourSelectionsSubmission}
              className="ml-auto"
              disabled={!isRequiredDataSubmitted()}
            >{t("buttons.submit", { ns: "common/form" })}</Button>
          </div>
        </div>
      )}
    </>
  );
};

export default HoursSelector;