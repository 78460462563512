import React from "react";
import EyeButton from "@src/components/ui/buttons/EyeButton";
import TimesButton from "@src/components/ui/buttons/TimesButton";
import TableCard from "@src/components/ui/TableCard";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";

const SelectedImagesTable = ({ imageList, showImageCallback, deselectImageCallback }) => {
  const { t, ready: translationsReady } = useTranslation(["components/products/create/productImages", "common/list"]);

  const getHeadersArray = () => [t("imageTableHeader"), t("actionsTableHeader", { ns: "common/list" })];

  const formatDataMatrix = () =>
    imageList.map((image) => [
      image.description,
      <>
        <EyeButton onClick={() => showImageCallback(image)} />
        {deselectImageCallback && <TimesButton onClick={() => deselectImageCallback(image)} />}
      </>,
    ]);

  return !translationsReady ? (
    <Spinner />
  ) : (
    <TableCard headersArray={getHeadersArray()} dataMatrix={formatDataMatrix()} isLastColumnForActions={true} />
  );
};

export default SelectedImagesTable;
