import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import SmallSpinner from "react-spinkit";
import IconButton from '@src/components/ui/b2c/buttons/IconButton';

const CloseButton = ({ className, showLoadingSpinner, ...props}) => {
  return (
    <IconButton
      className={className}
      {...props}
    >
      {showLoadingSpinner ? (
        <div className="w-[19px] h-[19px] flex">
          <SmallSpinner name="circle" color="white" fadeIn="none" className="m-auto" />
        </div>
      ) : (
        <FontAwesomeIcon icon={faTimes} color="white" />
      )}
    </IconButton>
  );
}
 
export default CloseButton;