import React from "react";
import Spinner from "react-spinkit";
import Button from "./Button";

const SubmitButton = ({ children : text, className, showLoadingSpinner, ...props }) =>
  <Button
    type="submit"
    className={className ?? "bg-gray-700 font-bold text-white"}
    {...props}
  >
    <div className="flex">
    {showLoadingSpinner &&
      <Spinner name="circle" color="white" fadeIn="none" className="mr-2" />
    }
    {text}
    </div>
  </Button>;

export default SubmitButton;