import React from "react";
import Spinner from "@src/components/ui/Spinner";
import { useTranslation } from "react-i18next";
import PhotoAlbum from "react-photo-album";

const ListImageGallery = ({
  currentPageData: imageList,
  editImageCallback,
}) => {
  const { t, ready: translationsReady } = useTranslation([
    "components/imageBank/listTable",
    "common/list",
    "common/common",
    "pages/imageBank/list",
    "common/booleanResponse"
  ]);

  const photo_album = () => 
    imageList.map((image) => ({
      src: image.url,
      width: 500,
      height: 375
    }));

  const find_photo_edit = (photo) => 
    imageList.map((image) => {
      if (image.url === photo.photo.src){
        editImageCallback(image)
      }
    });
  return (
    <>
      {!translationsReady && !imageList ? (
        <Spinner />
      ) : (
        <div className="w-full my-5">
          <PhotoAlbum layout="masonry" columns={3} spacing={20} photos={photo_album()} onClick={(photo) => find_photo_edit(photo)}/>
        </div>
      )}
    </>
  );
};

export default ListImageGallery;
