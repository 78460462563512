import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Spinner from "@src/components/ui/Spinner";
import BasicFormTemplate, { FooterType } from "@src/components/forms/BasicFormTemplate";
import FormikTextInput from "@src/components/forms/FormikTextInput";

const ServiceCategorySchema = Yup.object().shape({
  name: Yup.string().required("components/services/categories/form:noNameError"),
});

const ServiceCategoryInitialValues = {
  name: "",
};

const ServiceCategoryForm = ({ initialValues, handleSubmit, backCallback, disableModification, showLoadingSpinnerSubmitButton, title }) => {
  const { t, ready: translationsReady } = useTranslation("components/services/categories/form");

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <Formik
          initialValues={initialValues ? initialValues : ServiceCategoryInitialValues}
          validationSchema={ServiceCategorySchema}
          onSubmit={handleSubmit}
        >
          <Form className="space-y-2">
          <BasicFormTemplate
              className="mt-8"
              formTitle={title}
              showLoadingSpinner={showLoadingSpinnerSubmitButton}
              footerType={FooterType.BACK_SUBMIT_BUTTONS}
              backCallback={backCallback}
              disableSubmitButton={disableModification}
            >
            <div className="my-6 px-4">
                  <FormikTextInput
                    name="name"
                    color="light-blue"
                    label={t("nameLabel")}
                    disabled={disableModification}
                  />
                </div>
            </BasicFormTemplate>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default ServiceCategoryForm;