import EyeButton from "@src/components/ui/buttons/EyeButton";
import PencilButton from "@src/components/ui/buttons/PencilButton";
import TrashButton from "@src/components/ui/buttons/TrashButton";
import TableCard from "@src/components/ui/TableCard";
import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";

const ListPriceTemplatesTable = ({ currentPageData : priceTemplates, showPeopleSegmentsOfPriceTemplateCallback, editPriceTemplateCallback, deletePriceTemplateCallback }) => {
  const { t, ready: translationsReady } = useTranslation(["components/products/priceTemplates/listTable", "pages/products/priceTemplates/list",]);

  const getHeadersArray = () => Object.values(t("headers"));

  const formatDataMatrix = () => priceTemplates.map(pt => [
    pt.id,
    pt.name,
    <>
      <EyeButton onClick={() => showPeopleSegmentsOfPriceTemplateCallback(pt)} />
      <PencilButton onClick={() => editPriceTemplateCallback(pt)} />
      <TrashButton onClick={() => deletePriceTemplateCallback(pt)} />
    </>
  ]);   

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
          title={t("title", { ns: "pages/products/priceTemplates/list", })}
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

export default ListPriceTemplatesTable;