import React, { useEffect, useState } from "react";
import { B2CRoutesStrings, CommonRoutesStrings } from "@src/helpers/stringHelpers";
import { Link } from "react-router-dom";
import { useQuery } from "@src/helpers/hooks/useQuery";
import { Trans, useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import { createNotification, delayedRedirect, NotificationTypes } from "@src/helpers/notificationHelpers";
import AuthenticationService from "@src/services/AuthenticationService";
import { clearSession, storeJWT, storeCurrentUser } from "@src/helpers/localStorageHelpers";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import SmallSpinner from "react-spinkit";
import { isClient } from "@src/helpers/userHelpers";

import "@src/assets/styles/b2c/pages/login/login.css";
import "@src/assets/styles/b2c/pages/login/globals.css";
import "@src/assets/styles/b2c/pages/login/styleguide.css";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("common/form:validationErrors.empty.email")
    .required("common/form:validationErrors.empty.email"),
  password: Yup.string().required("common/form:validationErrors.empty.password"),
});

const LoginInitialValues = {
  email: "",
  password: "",
};

const LoginPage = () => {
  const query = useQuery();
  const sessionExpired = query.get("sessionExpired");
  const currentUserEdited = query.get("currentUserEdited");
  const redirectToCheckout = query.get("redirectToCheckout");
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation(["pages/sessions/login", "common/form"]);

  useEffect(() => {
    if (translationsReady) {
      if (sessionExpired) {
        createNotification(NotificationTypes.info, t("messages.sessionExpired"));
      } else if (currentUserEdited) {
        createNotification(NotificationTypes.info, t("messages.currentUserEdited"));
      }
    }
    // eslint-disable-next-line
  }, [translationsReady]);

  const handleSubmit = async ({ email, password }) => {
    setW18ApiResponse((b) => !b);

    try {
      const [user, jwt] = await AuthenticationService.login(email, password);

      storeCurrentUser(user);
      storeJWT(jwt);

      createNotification(NotificationTypes.success, t("messages.successLogin"));
      if (isClient(user) && redirectToCheckout) {
        delayedRedirect(B2CRoutesStrings.checkoutPath);
      } else {
        delayedRedirect(CommonRoutesStrings.homePath);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        createNotification(NotificationTypes.error, t("messages.wrongCredentials"));
      } else {
        createNotification(NotificationTypes.error, t("messages.genericRequestError"));
        clearSession();
      }
    } finally {
      setW18ApiResponse((b) => !b);
    }
  };

  return (
    <div className="container-center-horizontal">
      <div className="login screen">
        <div className="overlap-group">
          {!translationsReady ? (
            <Spinner />
          ) : (
            <>
              <div className="rectangle-13"></div>
              {/* eslint-disable-next-line */}
              <img
                className="image-34"
                src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62babcf788aa0b91b68cf475/img/image-34@1x.png"
              />
              <Formik initialValues={LoginInitialValues} validationSchema={LoginSchema} onSubmit={handleSubmit}>
                {({ errors, touched }) => (
                  <Form>
                    <div className="frame-13">
                      <div className="frame-9">
                        <div className="frame-5">
                          <h1 className="iniciar-sesin">{t("title")}</h1>
                          <p className="no-tienes-una-cuent">
                            <span className="span">
                              <Trans
                                t={t}
                                i18nKey="messages.newUser"
                                components={{
                                  1: <Link to={B2CRoutesStrings.userTypePickerRegisterPath} className="span1" />,
                                }}
                              />
                            </span>
                          </p>
                        </div>
                        <div className="grid grid-cols-1 w-full">
                          <div
                            className={`input-wrapper-login-form ${
                              errors.email && touched.email ? "border-2 border-red-500" : ""
                            }`}
                          >
                            <Field
                              className="input-login-form montserrat-medium-gunsmoke-12px"
                              name="email"
                              placeholder={t("labels.email", { ns: "common/form" })}
                            />
                          </div>
                          {errors.email && touched.email && (
                            <p className="px-[30px] mt-2 text-red-500 font-bold">{t(errors.email)}</p>
                          )}
                        </div>
                        <div className="grid grid-cols-1 w-full">
                          <div
                            className={`input-wrapper-login-form ${
                              errors.password && touched.password ? "border-2 border-red-500" : ""
                            }`}
                          >
                            <Field
                              className="input-login-form montserrat-medium-gunsmoke-12px"
                              name="password"
                              placeholder={t("labels.password", { ns: "common/form" })}
                              type="password"
                            />
                          </div>
                          {errors.password && touched.password && (
                            <p className="px-[30px] mt-2 text-red-500 font-bold">{t(errors.password)}</p>
                          )}
                        </div>
                      </div>
                      <div className="frame-1831">
                        <Link to={B2CRoutesStrings.forgotPasswordPath}>
                          <div className="olvidaste-tu-contrasea">{t("forgotPasswordLink")}</div>
                        </Link>
                        <button type="submit" className="ml-auto">
                          <div className="frame-8">
                            {w18ApiResponse ? (
                              <div className="w-[19px] h-[19px] flex">
                                <SmallSpinner name="circle" color="white" fadeIn="none" className="m-auto" />
                              </div>
                            ) : (
                              <>
                                {/* eslint-disable-next-line */}
                                <img
                                  className="icon-paper_plane"
                                  src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62babcf788aa0b91b68cf475/img/group-2@2x.svg"
                                />
                              </>
                            )}
                            <div className="enviar montserrat-semi-bold-white-16px">
                              {t("buttons.submit", { ns: "common/form" })}
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
