import { Switch } from '@material-tailwind/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from '@src/components/ui/Spinner';
import TableCard from '@src/components/ui/TableCard';

const DisableProfessionalsTable = ({ currentPageData : professionals, w18ApiResponse, cbEnableProfessional, cbDisableProfessional }) => {
  const { t, ready: translationsReady } = useTranslation(["pages/users/disableProfessionals", "common/userTypes"]);
  
  const getHeadersArray = () => Object.values(t("tableHeaders"));

  const formatDataMatrix = () => professionals.map(professional => [
    professional.id,
    professional.email,
    t(professional.type, { ns: "common/userTypes" }),
    /*
      Para usar el componente Switch de "@material-tailwind/react", si vamos a renderizar varios,
      es necesario proveerle un ID único a cada uno, ya que sino los eventos que se lancen solo
      irá al primero de ellos, al tener todos por defecto el id "switch".
    */
    <Switch
      id={`disable-professional-switch-${professional.id}`}
      onChange={() => professional.disabledAt ? cbEnableProfessional(professional) : cbDisableProfessional(professional)}
      checked={!!professional.disabledAt}
      disabled={w18ApiResponse}
      color="red"
    />
  ]);

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
          title={t("title", { ns: "pages/users/disableProfessionals" })}
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};
 
export default DisableProfessionalsTable;