import axios from "@src/helpers/axiosInstance";
import { getJWT } from "@src/helpers/localStorageHelpers";
import { APIEndpointsStrings } from "@src/helpers/stringHelpers";

export const getPeopleSegments = name => {
  return axios.get(`/people_segments?q[name_cont]=${name ? name : ""}`, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });
};

export const createPeopleSegment = (name, quantity) =>
  axios.post(APIEndpointsStrings.createPeopleSegment, {
    people_segment: { name, quantity }
  }, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });

export const updatePeopleSegment = (id, name, quantity) =>
  axios.patch(APIEndpointsStrings.singlePeopleSegment.replace(APIEndpointsStrings.idLocators.id, id), {
    people_segment: { name, quantity }
  }, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });

export const deletePeopleSegment = id =>
  axios.delete(APIEndpointsStrings.singlePeopleSegment.replace(":id", id), {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });