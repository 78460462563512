import axios from "@src/helpers/axiosInstance";
import { getCurrentUserData, getJWT } from "@src/helpers/localStorageHelpers";
import { APIEndpointsStrings } from "@src/helpers/stringHelpers";
import {UserTypesStrings} from "@src/helpers/stringHelpers"

export const getProfessionals = (email_like) =>
  axios.get(`/users?q[type_in][]=${UserTypesStrings.provider}&q[type_in][]=${UserTypesStrings.servicesProvider}&q[email_cont]=${email_like}`);

export const getCreditsValidators = (email_like) =>
  axios.get(`/users?q[type_in][]=${UserTypesStrings.operator}&q[type_in][]=${"Admin"}&q[email_cont]=${email_like}`);

class UserService {
  static index = async (requestPath) => {
    const res = await axios.get(requestPath ?? APIEndpointsStrings.reviewedUsersPath);

    return res.data.map(user => this.formatReceivedUser(user));
  };

  static show = async (userId) => {
    const requestPath = APIEndpointsStrings.userPath.replace(APIEndpointsStrings.idLocators.id, userId),
      res = await axios.get(requestPath);

    return this.formatReceivedUser(res.data);
  };

  static showCurrentUser = async () => {
    const res = await axios.get(APIEndpointsStrings.showCurrentUserPath, {
      headers: {
        Authorization: `Bearer ${getJWT()}`,
      },
    });

    return this.formatReceivedUser(res.data);
  };

  static indexUserFilters = (emailFilter,userTypeFilter) =>{
    let requestPath = APIEndpointsStrings.reviewedUsersPath;

    if(emailFilter){
      requestPath += `&q[email_cont]=${emailFilter}`;
    }
    if( userTypeFilter ){
      requestPath += `&q[type_eq]=${userTypeFilter}`;
    }
    return axios.get(requestPath);
  };

  static indexUsersGivenPartialEmailAndUserTypes = (email, userTypesArray) =>{
    let requestPath = `${APIEndpointsStrings.reviewedUsersPath}&q[email_cont]=${email}`;
    userTypesArray.forEach(userType => requestPath += `&q[type_in][]=${userType}`);
    return this.index(requestPath);
  };

  static update = (userId, email, userType, password = null) => {
    const requestPath = APIEndpointsStrings.userPath.replace(APIEndpointsStrings.idLocators.id, userId),
      user = { email };
    if (password) user.password = password;
    if (userType) user.type = userType;

    return axios.put(requestPath, { user });
  };

  static updateMyProfile = (email, password) => this.update(getCurrentUserData().id, email, undefined, password);

  static impersonate = async (user) => {
    const endpoint = APIEndpointsStrings.impersonateUserPath.replace(APIEndpointsStrings.idLocators.id, user.id);

    const res = await axios.post(endpoint);

    return this.formatReceivedUser(res.data);
  };

  static stopImpersonating = async () => axios.delete(APIEndpointsStrings.stopImpersonatingUserPath);

  static formatReceivedUser = (user) => ({
    id: user.id,
    email: user.email,
    type: user.type,
    createdAt: !user.created_at ? undefined : new Date(user.created_at),
    updatedAt: !user.updated_at ? undefined : new Date(user.updated_at),
    validatedAt: !user.validated_at ? undefined : new Date(user.validated_at),
    revokedAt: !user.revoked_at ? undefined : new Date(user.revoked_at),
    disabledAt: !user.disabled_at ? undefined : new Date(user.disabled_at),
    company: !user.company
      ? undefined
      : {
          id: user.company.id,
          cif: user.company.cif,
          fiscalName: user.company.taxname,
          commercialName: user.company.tradename,
          address: user.company.address,
          managerName: user.company.responsible_name,
          managerLandlineNumber: user.company.responsible_phone,
          province: user.company.province,
          zipCode: user.company.zip_code ? user.company.zip_code : undefined,
          country: user.company.country ? user.company.country : undefined,
          managerPosition: user.company.responsible_position ? user.company.responsible_position : undefined,
          managerPhoneNumber: user.company.responsible_mobile ? user.company.responsible_mobile : undefined,
        },
  });
}

export default UserService;
