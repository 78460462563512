import React from "react";
import { useTranslation } from "react-i18next";
import PencilButton from "@src/components/ui/buttons/PencilButton";
import TrashButton from "@src/components/ui/buttons/TrashButton";
import Spinner from "@src/components/ui/Spinner";
import TableCard from "@src/components/ui/TableCard";

const ListServiceCategoriesTable = ({ currentPageData : categories, editCategoryCallback, deleteCategoryCallback }) => {
  const { t, ready: translationsReady } = useTranslation(["components/services/categories/listTable", "common/list"]);

  const getHeadersArray = () => Object.values(t("headers")).concat(t("actionsTableHeader", { ns: "common/list" }));

  const formatDataMatrix = () => categories.map(c => [
    c.id,
    c.name,
    <>
      <PencilButton onClick={() => editCategoryCallback(c)} />
      <TrashButton onClick={() => deleteCategoryCallback(c.id)} />
    </>
  ]);   

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
          title={t("title", { ns: "pages/services/categories/list" })}
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

export default ListServiceCategoriesTable;