import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "@src/assets/styles/b2c/pages/show_product/product-images-carousel.css";

const ProductImagesCarousel = ({ images }) => {
  const shouldAutoplay = images.length > 1,
    autoplayConfig = {
      delay: 5000,
      disableOnInteraction: false,
    };

  return (
    <Swiper
      className="w-[inherit]"
      modules={[Pagination, Autoplay]}
      loop={true}
      slidesPerView={1}
      speed={1000}
      autoplay={shouldAutoplay ? autoplayConfig : undefined}
      pagination={{
        clickable: true,
        renderBullet: (_, className) => '<span class="' + className + '"></span>',
      }}
    >
      {images.map((image) => (
        <SwiperSlide key={image.id}>
          <img src={image.url} alt={image.description} className="w-[1440px] h-[521px]" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ProductImagesCarousel;
