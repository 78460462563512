import React, { useState } from "react";
import { emptyArray } from "@src/helpers/arrayHelpers";
import { formatPrice } from "@src/helpers/localeHelpers";
import { createNotification, delayedRedirect, NotificationTypes } from "@src/helpers/notificationHelpers";
import { DefinedRoutesStrings } from "@src/helpers/stringHelpers";
import ServiceTimeSearchForm from "@src/components/products/joker/ServiceTimeSearchForm";
import Spinner from "@src/components/ui/Spinner";
import ServiceResourceService from "@src/services/services/serviceResourceService";
import ServiceService from "@src/services/services/serviceService";
import { useTranslation } from "react-i18next";
import HistoryBackButton from "@src/components/ui/buttons/HistoryBackButton";
import SubmitButton from "@src/components/ui/buttons/SubmitButton";
import { getDateFromStrDateAndTime } from "@src/helpers/dateHelpers";

const ServiceSearchAndAssignment = ({ serviceCategory, productDate, timeslice, cbBackNavigation }) => {
  const [serviceList, setServiceList] = useState();
  const [selectedHours, setSelectedHours] = useState();
  const [resourceUnitInInteraction, setResourceUnitInInteraction] = useState();
  const [w18SearchServices, setW18SearchServices] = useState(false);
  const [w18ServiceAssignment, setW18ServiceAssignment] = useState(false);
  const { t, ready: translationsReady } = useTranslation(["components/products/joker/serviceSelector", "components/products/joker/productTimesliceSelector"]);

  const performSearch = async ({ startTime, endTime }) => {
    setW18SearchServices(b => !b);
    setServiceList();

    try {
      const searchResults = await ServiceService.searchForAvailableServices(serviceCategory.id, productDate.date, startTime, endTime);
      setServiceList(searchResults);
      setSelectedHours({ startTime, endTime });
    } catch (_) {
      createNotification(NotificationTypes.error, t("dateSearch.errorRetrievingProducts", { ns: "components/products/joker/productTimesliceSelector" }));
    } finally {
      setW18SearchServices(b => !b);
    }
  };

  const handleResourceUnitAssignment = async (serviceDateId, resourceUnitId) => {
    if (window.confirm(t("serviceSearchAndAssignment.doRequestAssigment.confirmMsg"))) {
      setW18ServiceAssignment(b => !b);
      setResourceUnitInInteraction({ serviceDateId, resourceUnitId });

      try {
        await ServiceResourceService
          .assignServiceResourceToTimeslice(productDate.product.id, productDate.id, timeslice.id, serviceDateId, resourceUnitId, selectedHours.startTime, selectedHours.endTime);
        createNotification(NotificationTypes.success, t("serviceSearchAndAssignment.doRequestAssigment.okResponse"));
        delayedRedirect(DefinedRoutesStrings.jokerPath);
      } catch (_) {
        createNotification(NotificationTypes.error, t("serviceSearchAndAssignment.doRequestAssigment.koResponse"));
      } finally {
        setW18ServiceAssignment(b => !b);
      }
    }
  };

  const handleResourceUnitDisassociation = async (serviceDateId, resourceUnitId) => {
    if (window.confirm(t("serviceSearchAndAssignment.doRequestDisassociation.confirmMsg"))) {
      setW18ServiceAssignment(b => !b);
      setResourceUnitInInteraction({ serviceDateId, resourceUnitId });

      try {
        await ServiceResourceService
          .disassociateServiceResourceFromTimeslice(productDate.product.id, productDate.id, timeslice.id, serviceDateId, resourceUnitId);
        createNotification(NotificationTypes.success, t("serviceSearchAndAssignment.doRequestDisassociation.okResponse"));
        delayedRedirect(DefinedRoutesStrings.jokerPath);
      } catch (_) {
        createNotification(NotificationTypes.error, t("serviceSearchAndAssignment.doRequestDisassociation.koResponse"));
      } finally {
        setW18ServiceAssignment(b => !b);
      }
    }
  };

  const isTimeframeAlreadyAssigned = (resourceUnitsTaken, startTime, endTime) => {
    const dateStartTime = getDateFromStrDateAndTime(productDate.date, startTime),
      dateEndTime = getDateFromStrDateAndTime(productDate.date, endTime);

    return resourceUnitsTaken.some(resourceUnitTaken => {
      const resourceUnitTakenStartTime = getDateFromStrDateAndTime(productDate.date, resourceUnitTaken.startTime),
        resourceUnitTakenEndTime = getDateFromStrDateAndTime(productDate.date, resourceUnitTaken.endTime);
      
      return (resourceUnitTakenStartTime.valueOf() <= dateEndTime.valueOf() && dateEndTime.valueOf() <= resourceUnitTakenEndTime.valueOf())
      ||
      (dateStartTime.valueOf() <= resourceUnitTakenStartTime.valueOf() && resourceUnitTakenEndTime.valueOf() <= dateEndTime.valueOf())
      ||
      (resourceUnitTakenStartTime.valueOf() <= dateStartTime.valueOf() && dateEndTime.valueOf() <= resourceUnitTakenEndTime.valueOf())
      ||
      (resourceUnitTakenStartTime.valueOf() <= dateStartTime.valueOf() && dateStartTime.valueOf() <= resourceUnitTakenEndTime.valueOf());
    });
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="container p-2" style={{ minHeight: "50vh" }}>
          <div className="flex place-content-center">
            <HistoryBackButton onClick={cbBackNavigation} className="my-2" />
          </div>
          <p>{t("serviceSearchAndAssignment.description")}</p>
          <ServiceTimeSearchForm
            showLoadingSpinnerSubmitButton={w18SearchServices}
            cbHandleTimeSelection={performSearch}
          />
          {serviceList &&
            <>
              {emptyArray(serviceList) ? (
                <p>{t("serviceSearchAndAssignment.noServicesMsg")}</p>
              ) : (
                <>
                  <p className="mb-2">{t("serviceSearchAndAssignment.resourceCards.description")}</p>
                  <div className="flex overflow-auto" style={{ maxHeight: "50vh" }}>
                    {serviceList.map(service =>
                      <div
                        key={service.id}
                        className="flex flex-col mx-2 border rounded-md p-1 bg-gray-200 shadow-sm"
                      >
                        <p>{t("serviceSearchAndAssignment.resourceCards.serviceName", { name: service.name })}</p>
                        {service.resources.map(resource =>
                          <div key={resource.id} className="flex border rounded-md my-1 p-1 bg-white">
                            <div className="mr-auto">
                              <p>{t("serviceSearchAndAssignment.resourceCards.resourceName", { name: resource.name })}</p>
                              <p>{t("serviceSearchAndAssignment.resourceCards.priceHour", { price: formatPrice(resource.priceHour) })}</p>
                              <p>{t("serviceSearchAndAssignment.resourceCards.resourceUnits")}</p>
                              {resource.resourceUnits.map(resourceUnit =>
                                <div key={resourceUnit.id} className="flex border rounded-md my-1 p-1 bg-gray-50">
                                  <div className="grid grid-cols-2">
                                    <div>
                                      <p>{t("serviceSearchAndAssignment.resourceCards.capacity", { capacity: resourceUnit.capacity })}</p>
                                      <p>{t("serviceSearchAndAssignment.resourceCards.availability", { startTime: resourceUnit.rangeOfAvailability.startTime, endTime: resourceUnit.rangeOfAvailability.endTime })}</p>
                                    </div>
                                    {selectedHours && isTimeframeAlreadyAssigned(resourceUnit.resourceUnitsTaken, selectedHours.startTime, selectedHours.endTime) ? (
                                      <SubmitButton
                                        className="ml-auto"
                                        onClick={() => handleResourceUnitDisassociation(service.serviceDateId, resourceUnit.id)}
                                        showLoadingSpinner={w18ServiceAssignment && resourceUnitInInteraction?.serviceDateId === service.serviceDateId && resourceUnitInInteraction?.resourceUnitId === resourceUnit.id}
                                        color="yellow"
                                      >{t("serviceSearchAndAssignment.resourceCards.buttons.disassociate")}</SubmitButton>
                                    ) : (
                                      <SubmitButton
                                        className="ml-auto"
                                        onClick={() => handleResourceUnitAssignment(service.serviceDateId, resourceUnit.id)}
                                        showLoadingSpinner={w18ServiceAssignment && resourceUnitInInteraction?.serviceDateId === service.serviceDateId && resourceUnitInInteraction?.resourceUnitId === resourceUnit.id}
                                        color="green"
                                      >{t("serviceSearchAndAssignment.resourceCards.buttons.assign")}</SubmitButton>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          }
        </div>
      )}
    </>
  );
};

export default ServiceSearchAndAssignment;