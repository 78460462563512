import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableCard from "@src/components/ui/TableCard";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatHour } from "@src/helpers/localeHelpers";
import Spinner from "@src/components/ui/Spinner";


const ResourcesSelectedDetails = ({ resources, removeCallback, title }) => {
  const { t, ready: translationsReady } = useTranslation(["components/services/create/resources", "common/list"]);

  const getResourceTableHeaders = () => Object.values(t("mainScreen.resourceTableHeaders")).concat(t("remove", { ns: "common/list" }));
  
  const resourceTableDataRows = resources.map((resource, index) => {
    const dataRow = [
      resource.name,
      resource.quantity,
      resource.capacity,
      resource.priceHour,
      `${formatHour(resource.startTime)} - ${formatHour(resource.endTime)}`,
      <button onClick={() => removeCallback(index)}><FontAwesomeIcon icon={faTimesCircle} color="red" /></button>,
    ];

    return removeCallback ? dataRow : dataRow.slice(0, -1);
  });
  
  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
          title={title}
          color="teal"
          headersArray={removeCallback ? getResourceTableHeaders() : getResourceTableHeaders().slice(0, -1)}
          dataMatrix={resourceTableDataRows}
          centerData={true}
        />
      )}
    </>
  );
};

export default ResourcesSelectedDetails;