import { useField } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import AsyncSelect from 'react-select/async';

const FormSearchInput = ({
  innerRef,
  label,
  secondLabel,
  placeholder,
  noOptionsMessage,
  defaultOptions,
  defaultValue,
  isMulti,
  cbLoadOptions,
  cbGetOptionValue,
  cbGetOptionLabel,
  cbHandleChange,
  disabled,
  yMargin,
  isClearable,
  ...props
}) => {

  // eslint-disable-next-line
  const [field, meta] = useField(props);
  const { t } = useTranslation();
   
  return (
    <div className={`grid grid-cols-1 ${yMargin ? `my-${yMargin}` : "my-6"}`}>
      <label className="text-gray-700" htmlFor={props.id || props.name}>{label}</label>
      {secondLabel ? (
        <label className="text-sm text-gray-500 dark:text-gray-400">{secondLabel}</label>
      ) : null}
      <AsyncSelect
        ref={innerRef}
        isMulti={isMulti}
        {...(!Array.isArray(field.value) && field.value === "" ? { value: "" } : {})}
        loadOptions={cbLoadOptions}
        getOptionLabel={cbGetOptionLabel}
        getOptionValue={cbGetOptionValue}
        onChange={cbHandleChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        defaultOptions={defaultOptions}
        isDisabled={disabled}
        noOptionsMessage={() => noOptionsMessage}
        isClearable={isClearable??false}//conflict dialog of react-select beetwen material
        value={props.value}
      />
      {meta.touched && meta.error ? (
        <div className="text-red-500 font-semibold">{t(meta.error)}</div>
      ) : null}
    </div>
  );
};

export default FormSearchInput;