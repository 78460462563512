import React from 'react';
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import Spinner from '@src/components/ui/Spinner';
import { Form, Formik } from 'formik';
import FormikTextInput from '@src/components/forms/b2c/FormikTextInput';
import HistoryBackButton from '@src/components/ui/b2c/buttons/HistoryBackButton';
import SubmitButton from '@src/components/ui/b2c/buttons/SubmitButton';
import { faLongArrowAltRight, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

const EssentialUserDataSchema = Yup.object().shape({
  email: Yup.string().email("common/form:validationErrors.empty.email").required("common/form:validationErrors.empty.email"),
  password: Yup.string().required("common/form:validationErrors.empty.password").min(6, "common/form:validationErrors.passwords.passwordMinLength"),
  passwordConfirmation: Yup.string().required("common/form:validationErrors.empty.confirmationPassword")
    .oneOf([Yup.ref("password"), null], "common/form:validationErrors.passwords.notMatchingPasswords"),
});

const EssentialUserDataInitialValues = {
  email: "",
  password: "",
  passwordConfirmation: ""
};

const EssentialUserDataRegistrationForm = ({ title, initialValues, showLoadingSpinner, isLastStep, backCallback, nextCallback }) => {
  const { t, ready: translationsReady } = useTranslation(["pages/users/create", "common/form", "common/userTypes", "common/navigation"]);

  return !translationsReady ? <Spinner /> : (
    <Formik
      initialValues={initialValues ?? EssentialUserDataInitialValues}
      validationSchema={EssentialUserDataSchema}
      onSubmit={nextCallback}
    >
      <Form>
        <div className="flex flex-col items-center my-24 p-10 rounded-[28px] bg-white shadow-2xl">
          <div className="flex flex-col items-center">
            <h1 className="leading-7 -mt-px tracking-normal whitespace-no-wrap montserrat-bold-shark-36px">{title}</h1>
            <div className="flex flex-col items-center w-[340px] mt-8 space-y-8">
              <FormikTextInput
                name="email"
                placeholder={t("labels.email", { ns: "common/form" })}
                type="email"
              />
              <FormikTextInput
                name="password"
                placeholder={t("labels.password", { ns: "common/form" })}
                type="password"
              />
              <FormikTextInput
                name="passwordConfirmation"
                placeholder={t("labels.confirmPassword", { ns: "common/form" })}
                type="password"
              />
            </div>
          </div>
          <div className="flex items-start mt-6 w-[340px] justify-between">
            <HistoryBackButton onClick={backCallback} />
            {isLastStep ? (
              <SubmitButton
                icon={faPaperPlane}
                showLoadingSpinner={showLoadingSpinner}
              />
            ) : (
              <SubmitButton
                icon={faLongArrowAltRight}
                showLoadingSpinner={showLoadingSpinner}
              >{t("next", { ns: "common/navigation" })}</SubmitButton>
            )}
          </div>
        </div>
      </Form>
    </Formik>
  );
}
 
export default EssentialUserDataRegistrationForm;