import React from "react";
import {
  APIEndpointsStrings,
  CommonRoutesStrings,
  DefinedRoutesStrings,
} from "@src/helpers/stringHelpers";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import { isThirdPartyProduct } from "@src/helpers/productHelpers";
import EyeButton from "@src/components/ui/buttons/EyeButton";
import PencilButton from "@src/components/ui/buttons/PencilButton";
import TrashButton from "@src/components/ui/buttons/TrashButton";
import {
  isProvider,
  currentUserHasWritePermissions,
} from "@src/helpers/userHelpers";
import TableCard from "@src/components/ui/TableCard";
import { Chip, Switch } from "@material-tailwind/react";
import IconLinkButton from "@src/components/ui/buttons/IconLinkButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH
} from "@fortawesome/free-solid-svg-icons";
import BanButton from "@src/components/ui/buttons/BanButton";
import SpinnerButton from "../ui/buttons/SpinnerButton";

export const getCategoriesJSX = (categoryList, product) => (
  <div className="grid  place-items-center space-y-1">
    {categoryList?.length <= 2 ? (
      categoryList.map((category, index) => (
        <Chip
          key={index}
          color="amber"
          variant="gradient"
          value={category.name}
        />
      ))
    ) : (
      <IconLinkButton
        color="amber"
        variant="gradient"
        to={
          (isThirdPartyProduct(product.type)? CommonRoutesStrings.showThirdPartyProductPropertiesPath :CommonRoutesStrings.showOwnProductPropertiesPath).replace(
            APIEndpointsStrings.idLocators.id,
            product.id
          )
        }
      >
        <FontAwesomeIcon icon={faEllipsisH} />
      </IconLinkButton>
    )}
  </div>
);

const ListAllProductsTable = ({
  currentPageData: productList,
  deleteProductCallback,
  toggleProductHighlightCallback,
  cancelProductCallback,
  cancelProgress
}) => {
  const { t, ready: translationsReady } = useTranslation([
    "components/products/listTable",
    "pages/products/list",
  ]);

  // Ignore elements with index 2 and 4 (professionalEmail and highlight switch) if currentUser is ThirdPartyProvider
  const discardElems24 = (arr) =>
    arr.filter((_, index) => index !== 2 && index !== 4);

  const getHeadersArray = () =>
    !isProvider()
      ? Object.values(t("headers"))
      : discardElems24(Object.values(t("headers")));

  const getPageTitle = () =>
    isProvider()
      ? t("titles.myProducts", { ns: "pages/products/list" })
      : t("titles.generic", { ns: "pages/products/list" });

  const formatDataMatrix = () =>
    productList.map((product) => {
      const showProductPath = isThirdPartyProduct(product.type)
        ? CommonRoutesStrings.showThirdPartyProductPath
        : CommonRoutesStrings.showOwnProductPath;
      const editProductPath = isThirdPartyProduct(product.type)
        ? DefinedRoutesStrings.editThirdPartyProductPath
        : DefinedRoutesStrings.editOwnProductPath;

      const dataRow = [
        product.id,
        product.name,
        product.professionalEmail,
        product.commission,
        /*
          Para usar el componente Switch de "@material-tailwind/react", si vamos a renderizar varios,
          es necesario proveerle un ID único a cada uno, ya que sino los eventos que se lancen solo
          irá al primero de ellos, al tener todos por defecto el id "switch".
        */
        <Switch
          id={`highlight-product-switch-${product.id}`}
          onChange={() => toggleProductHighlightCallback(product)}
          checked={product.highlighted}
          color="green"
          disabled={product.canceled_at}
        />,
        getCategoriesJSX(product.categories, product),
        !product.serviceCategories
          ? null
          : getCategoriesJSX(product.serviceCategories, product),
        <>
          <EyeButton
            id={`detail-product-${product.id}`}
            targetPath={showProductPath.replace(
              APIEndpointsStrings.idLocators.id,
              product.id
            )}
          />
          {!product.canceled_at && !product.worker_cancelled  && (
            <>
              <PencilButton
                id={`edit-product-${product.id}`}
                targetPath={editProductPath.replace(
                  APIEndpointsStrings.idLocators.id,
                  product.id
                )}
                disabled={!currentUserHasWritePermissions()}
              />
              <TrashButton
                id={`delete-product-${product.id}`}
                onClick={() => deleteProductCallback(product)}
                disabled={!currentUserHasWritePermissions()}
              />
            </>
          )}
          {product.worker_cancelled && !product.worker_cancelled.finished_at  ? (
            <SpinnerButton ></SpinnerButton>
          ) : 
          (
            <BanButton
            id={`cancel-product-${product.id}`}
            onClick={() => cancelProductCallback(product)}
            disabled={!currentUserHasWritePermissions() || product.canceled_at}
          />
          )}
        </>,
      ];

      return !isProvider() ? dataRow : discardElems24(dataRow);
    });

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <TableCard
          title={getPageTitle()}
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

export default ListAllProductsTable;
