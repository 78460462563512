import { Option, Select } from "@material-tailwind/react";
import { useField, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

/*
  In order to component options and error message to be
  translated correctly, we need to pass as option label
  the key for its translation, and Select component will
  use i18next to translate this key to selected language
*/
const FormikSelect = ({
  label,
  className,
  defaultOptionLabel,
  options,
  hideDefaultValue,
  onChange,
  shouldNotTranslateLabel,
  ...props
}) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const { t } = useTranslation();

  const defaultOnChange = optionValue => setFieldValue(props.name, optionValue);

  return (
    <div className={`grid grid-cols-1 ${className ?? ""}`}>
      <Select
        variant="standard"
        label={label}
        error={meta.touched && meta.error ? true : false}
        {...field}
        {...props}
        onChange={onChange ?? defaultOnChange}
      >
        {options.map((option, index) =>
          <Option key={index} value={option.value}>{!shouldNotTranslateLabel ? t(option.label) : option.label}</Option>
        )}
      </Select>
      {meta.touched && meta.error ? (
        <div className="text-red-500 font-semibold">{t(meta.error)}</div>
      ) : null}
    </div>
  );
};

export default FormikSelect;