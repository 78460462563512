import { faCircle, faMinusSquare, faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { removeElemsFromArray } from "@src/helpers/arrayHelpers";
import { stringDateFormat } from "@src/helpers/dateHelpers";
import { rangeNumbersArray } from "@src/helpers/numberHelpers";
import { formatHour } from "@src/helpers/localeHelpers";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import PencilButton from "@src/components/ui/buttons/PencilButton";
import EyeButton from "@src/components/ui/buttons/EyeButton";
import TableCard from "@src/components/ui/TableCard";
import CountryFlagWithLanguageCode from "@src/components/products/product_wizard/CountryFlagWithLanguageCode";

const EventsTable = ({

  numElements,
  selectedRowsForPriceSelection,
  cbSetSelectedRowsForPriceSelection,
  arePricesSetForEvent,
  cbShowPriceTable,
  cbOpenEditPricesModal,
  currentPageData : dateRanges,
  currentPage,
  perPage : PER_PAGE

}) => {
  const { t, ready: translationsReady } = useTranslation(["components/products/create/prices", "pages/products/create"]);

  const getCurrentPageIndexes = () => {
    const indexStart = PER_PAGE * currentPage,
      indexEnd = numElements < PER_PAGE * (currentPage + 1) ? numElements : PER_PAGE * (currentPage + 1);

    return rangeNumbersArray(indexStart, indexEnd);
  };

  const allIndexesOfCurrentPageSelected = () => {
    const indexesToCheck = getCurrentPageIndexes();
    return indexesToCheck.every(i => selectedRowsForPriceSelection.includes(i));
  };

  const toggleSelections = () => {
    const currentPageIndexes = getCurrentPageIndexes();
    if (allIndexesOfCurrentPageSelected())      
      cbSetSelectedRowsForPriceSelection(removeElemsFromArray(selectedRowsForPriceSelection, currentPageIndexes));
    else
      cbSetSelectedRowsForPriceSelection(selectedRowsForPriceSelection.concat(currentPageIndexes));
  };

  const getSelectAllButton = () =>
    <button onClick={toggleSelections}><FontAwesomeIcon icon={allIndexesOfCurrentPageSelected() ? faMinusSquare : faPlusSquare} size="lg"/></button>
  
  const getAbsoluteIndex = index => index + currentPage * PER_PAGE;

  const isDateRangeSelected = index => selectedRowsForPriceSelection.some(i => getAbsoluteIndex(index) === i);

  const selectDateRange = (doSelect, index) => {
    if (doSelect)
      cbSetSelectedRowsForPriceSelection(selectedRowsForPriceSelection.concat(getAbsoluteIndex(index)));
    else
      cbSetSelectedRowsForPriceSelection(selectedRowsForPriceSelection.filter(i => i !== getAbsoluteIndex(index)));
  };

  const getHeadersArray = () => [ getSelectAllButton() ].concat(Object.values(t("labels.eventsTableHeaders")));

  const formatDataMatrix = () => {
    let matrix = [];

    dateRanges.forEach((dateRange, index) => {
      matrix.push([
          <button
            onClick={() => selectDateRange(isDateRangeSelected(index) ? false : true, index)}
          >
            {isDateRangeSelected(index) ? <FontAwesomeIcon icon={faCheckCircle} color="green" /> : <FontAwesomeIcon icon={faCircle} />}
          </button>,
          stringDateFormat(dateRange.fromDate) + " - " + stringDateFormat(dateRange.toDate),
          <div className="inline-grid grid-cols-2">
            {dateRange.times.map((beginsAt, index) => 
              <div
                key={`beginsat-badge-${index}`}
                className="rounded bg-gray-200 px-1 m-1 whitespace-nowrap"
              >{formatHour(beginsAt[0])}{beginsAt[1].map((value) => <CountryFlagWithLanguageCode language={value} />)}</div>
            )}
          </div>,
          dateRange.duration,
          
          dateRange.quota,
          <>
            <EyeButton
              onClick={() => cbShowPriceTable(getAbsoluteIndex(index))}
              disabled={!arePricesSetForEvent(dateRange)}
            />
            <PencilButton
              onClick={() => cbOpenEditPricesModal(getAbsoluteIndex(index))}
              disabled={!arePricesSetForEvent(dateRange)}
            />
          </>
      ]);
    });

    return matrix;
  };
  
  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
          title={t("stepTitles", { ns: "pages/products/create" }).pricesQuotas}
          description={t("labels.stepDescription")}
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

export default EventsTable;