import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from '@src/components/ui/Spinner';
import TableCard from '@src/components/ui/TableCard';
import { formatPrice } from '@src/helpers/localeHelpers';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PriceSeletionDetails = ({ prices, removeCallback }) => {
  const { t, ready: translationsReady } = useTranslation(["components/products/create/prices", "common/common"]);

  const getHeadersArray = () => Object.values(t("labels.priceTableHeaders"));
  
  const getDataMatrix = () => prices.map((price, index) => {
    const priceRow = [
      price.type_client.name,
      formatPrice(price.price),
      price.quota || t("notAvailable", { ns: "common/common" }),
      <button onClick={() => removeCallback(index)}><FontAwesomeIcon icon={faTimesCircle} color="red"/></button>
    ];

    return removeCallback ? priceRow : priceRow.slice(0, -1);
  });
  
  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
          headersArray={removeCallback ? getHeadersArray() : getHeadersArray().slice(0, -1)}
          dataMatrix={getDataMatrix()}
        />
      )}
    </>
  );
};

export default PriceSeletionDetails;