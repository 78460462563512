import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@src/components/ui/b2c/buttons/Button";
import BigSpinner from "@src/components/ui/Spinner";
import SmallSpinner from "react-spinkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HexStandardPinkStylingB2C } from "@src/helpers/stringHelpers";

const SubmitButton = ({ className: additionalClasses, icon, showLoadingSpinner, children: text, ...props }) => {
  const { t, ready: translationsReady } = useTranslation("common/form");

  return !translationsReady ? (
    <BigSpinner />
  ) : (
    <Button
      type="submit"
      className={`bg-[${HexStandardPinkStylingB2C}] text-white font-bold ${additionalClasses ?? ""}`}
      {...props}
    >
      <div className="flex items-center justify-center">
        {showLoadingSpinner ? (
          <SmallSpinner name="circle" color="white" fadeIn="none" className="mr-2" />
        ) : (
          <>{icon && <FontAwesomeIcon icon={icon} color="white" className="mr-2" />}</>
        )}
        {text ?? t("buttons.submit")}
      </div>
    </Button>
  );
};

export default SubmitButton;
