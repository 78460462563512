import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountryFlag from '@src/components/ui/CountryFlag';
import Spinner from '@src/components/ui/Spinner';
import { formatTimeMinutes } from '@src/helpers/dateHelpers';
import { formatPrice } from '@src/helpers/localeHelpers';
import { isThirdPartyProduct } from '@src/helpers/productHelpers';
import { APIEndpointsStrings, CommonRoutesStrings, HexStandardPinkStylingB2C } from '@src/helpers/stringHelpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ResultsListProductsSearch = ({ currentPageData: productList }) => {
  const { t, ready: translationsReady } = useTranslation(["pages/b2c/listProductsSearch", "common/common"]);

  return !translationsReady ? <Spinner /> : (
    <>
      {productList.map((product, index) => {
        const firstProduct = index === 0;
        const showProductPath = isThirdPartyProduct(product.type) ?
          CommonRoutesStrings.showThirdPartyProductPath : CommonRoutesStrings.showOwnProductPath;

        return (
          <div key={product.id} className={`flex items-center h-64 rounded-[28px] w-full shadow-2xl px-8 ${firstProduct ? "mt-0" : "mt-[29px]"}`}>
            {/* eslint-disable-next-line */}
            <img
              className="h-[191px] w-[175px]"
              src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bb0ccec0392b641a3b895f/img/rectangle-15-5@2x.png"
            />
            <div className="flex flex-col h-[192px] sm:w-[62.5%] xl:w-[700px] ml-6">
              <div className="flex flex-col h-[132px]">
                <div className="leading-8 h-[68px] montserrat-bold-shark-24px truncate">
                  {product.name}
                </div>
                <div className="leading-7 mt-2 h-[56px] montserrat-medium-shark-18px whitespace-pre-line overflow-clip">
                  {product.description}
                </div>
              </div>
              <div className="flex items-end justify-between mt-5">
                <div className="flex items-center w-fit">
                  <div className="flex h-8">
                    {/* eslint-disable-next-line */}
                    <img
                      className="self-center h-5 w-4"
                      src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bdbd04ad7f05c93f81a613/img/vector-6@2x.svg"
                    />
                    <div className="leading-7 ml-1 mt-[3px] whitespace-nowrap montserrat-normal-shark-18px">{formatTimeMinutes(product.duration)}</div>
                  </div>
                  <div className="flex items-start h-5 ml-8 min-w-[55px] w-full ">
                    {/* eslint-disable-next-line */}
                    <img
                      className="h-5 w-5"
                      src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bb0ccec0392b641a3b895f/img/group-1741-5@2x.svg"
                    />
                    <div className="flex items-start self-center">
                      {product.languages.map(language => (
                        <CountryFlag
                          key={language}
                          className="w-6 ml-2"
                          languageCode={language}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <Link
                  className={`flex sm:w-14 lg:w-auto h-10 py-2 px-6 bg-[${HexStandardPinkStylingB2C}] rounded-[34px]`}
                  to={showProductPath.replace(APIEndpointsStrings.idLocators.id, product.id)}
                >
                  <FontAwesomeIcon icon={faLongArrowAltRight} color="white" className="mt-1" />
                  <div className="ml-3 sm:invisible lg:visible whitespace-nowrap montserrat-semi-bold-white-16px">{t("priceFrom", { quantity: formatPrice(product.price) })}</div>
                </Link>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
 
export default ResultsListProductsSearch;