import React, { forwardRef } from 'react';
import OrderDisplay from '@src/components/orders/b2c/list/OrderDisplay';

const PrintOrderWrapper = forwardRef(({ order, t }, ref) => (
  <div ref={ref} className="flex flex-col items-center py-16 px-32 w-[1440px] montserrat-medium-shark-20px">
    <style type="text/css" media="print">
      {"\
        @page { size: landscape; }\
      "}
    </style>
    {!order ? null : (
      <>
        <p>{t("orderIdentifierPrint", { id: order.id })}</p>
        <OrderDisplay className="mt-10" order={order} printing={true} />
      </>
    )}
  </div>
));
 
export default PrintOrderWrapper;