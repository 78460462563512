import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { cancelProductDate, deleteProductDate } from "@src/helpers/productHelpers";
import Button from "@src/components/ui/buttons/Button";
import ErrorText from "@src/components/ui/ErrorText";
import PaginateData from "@src/components/ui/PaginateData";
import Spinner from "@src/components/ui/Spinner";
import ListDatesTable from "@src/components/products/edit_product_data/list_dates/ListDatesTable";
import HistoryBackButton from "@src/components/ui/buttons/HistoryBackButton";
import BooleanResponseDialog from "@src/components/ui/BooleanResponseDialog";
import CancelProductDialog from "../../cancel_product/CancelProductDialog";
import { stringDateFormat } from "@src/helpers/dateHelpers";

const ListDates = ({
                     productType,
                     dates,
                     backCallback,
                     addDateCallback,
                     removeDate,
                     refresh,
                     showTimeslicesOfDateCallback,
                     readOnly
                   }) => {
  const PER_PAGE = 5;
  const [error, setError] = useState();
  const [dateToDelete, setDateToDelete] = useState();
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { id : productId } = useParams();
  const [dateToCancel, setDateToCancel] = useState();
  const { t, ready: translationsReady } = useTranslation(["components/products/edit/listDates", "common/navigation", "common/list"]);

  const deleteDate = async () => {
    setW18ApiResponse(b => !b);

    try {
      await deleteProductDate(productId, dateToDelete.id);
      createNotification(NotificationTypes.success, t("labels.successfulDateDeletion"));
      removeDate(dateToDelete);
      setDateToDelete();
    } catch (err) {
      createNotification(NotificationTypes.error, t("labels.genericErrorDateDeletion"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  }

  const cancelProduct = async (day) => {
    setW18ApiResponse(b => !b);

    try {
      await cancelProductDate(productId, dateToCancel.id, day.reason);
      setDateToCancel();
      refresh();
      createNotification(NotificationTypes.success, t("labels.successfulDateCancel"));
    } catch (err) {
      console.error(err)
      setDateToCancel();
      createNotification(NotificationTypes.error, t("labels.genericErrorDateCancel"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  }

  const enableDateDeletion = date => {
    if (Array.isArray(dates) && dates.length <= 1) {
      setError("validations.noDateDeletionIfOnlyOneInList");
    } else {
      setError();
      setDateToDelete(date);
    }
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="grid grid-cols-1 place-items-center mx-auto max-w-screen-sm">
          <div className="space-x-2 my-2">
            <HistoryBackButton onClick={backCallback} size="md" />
            {!readOnly &&
              <Button
                onClick={addDateCallback}
              >{t("add", { ns: "common/list" })}</Button>
            }
          </div>
          {error &&
            <ErrorText>{t(error)}</ErrorText>
          }      
          <PaginateData
            data={dates}
            perPage={PER_PAGE}
          >
            <ListDatesTable
              deleteDateCallback={date => enableDateDeletion(date)}
              readOnly={readOnly}
              showTimeslicesOfDateCallback={showTimeslicesOfDateCallback}
              cancelProductCallback={date => setDateToCancel(date)}
            />
          </PaginateData>
          <BooleanResponseDialog
            open={!!dateToDelete}
            noCallback={() => setDateToDelete()}
            yesCallback={deleteDate}
            showLoadingSpinner={w18ApiResponse}
          >
            {t("labels.confirmDateDeletionMessage")}
          </BooleanResponseDialog>
          <CancelProductDialog
            open={!!dateToCancel}
            handleSubmit={(day) => cancelProduct(day)}
            handler={() => setDateToCancel()}
            showLoadingSpinner={w18ApiResponse}
            productToCancel={dateToCancel}
            title={t("labels.titleCancel")}
            message={dateToCancel && t("labels.confirmDateCancelnMessage", { date: stringDateFormat(dateToCancel.date) })}
          />
        </div>
      )}
    </>
  );
};

export default ListDates;