import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CommonRoutesStrings, DefinedRoutesStrings } from '@src/helpers/stringHelpers';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import IconButton from '@src/components/ui/buttons/IconButton';
import { useTranslation } from 'react-i18next';
import Spinner from '@src/components/ui/Spinner';
import { Link } from 'react-router-dom';
import { getCurrentUserData } from '@src/helpers/localStorageHelpers';
import { allowedToAccessRoute } from '@src/helpers/permissionHelpers';
import { isCurrentSessionForImpersonatingUser, isLoggedIn } from '@src/helpers/sessionHelpers';
import ImageBankService from '@src/services/imageBankService';

const Sidebar = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [myLogo, setMyLogo] = useState();
  const { t, ready: translationsReady } = useTranslation("components/sidebar");

  useEffect(() => {
    const fetchMyLogo = async () => {
      try {
        setMyLogo(await ImageBankService.indexImageWithDescription("Admintrip"));
      } catch (err) {
        console.log(err);
      }
    }
    fetchMyLogo();
  },
  []);

  const getSidebarElems = () => {
    const sidebarElems = [
      { path: DefinedRoutesStrings.myProfilePath, name: getCurrentUserData().email, requiresPermissions: false },
      { path: DefinedRoutesStrings.dashboardPath, name: t("dashboard"), requiresPermissions: true },
      { path: DefinedRoutesStrings.listUsersPath, name: t("userManagement"), requiresPermissions: true },
      { path: DefinedRoutesStrings.listProductsPath, name: t("productManagement"), requiresPermissions: true },
      { path: DefinedRoutesStrings.listServicesPath, name: t("serviceManagement"), requiresPermissions: true },
      { path: DefinedRoutesStrings.jokerPath, name: t("joker"), requiresPermissions: true },
      { path: CommonRoutesStrings.listOrdersPath, name: t("orderManagement"), requiresPermissions: true },
      { path: DefinedRoutesStrings.listSupplementsPath, name: t("supplementManagement"), requiresPermissions: true },
      { path: DefinedRoutesStrings.listNewslettersPath, name: t("newsletterManagement"), requiresPermissions: true },
      { path: DefinedRoutesStrings.listBannersPath, name: t("bannerManagement"), requiresPermissions: true },
      { path: DefinedRoutesStrings.listCreditsPath, name: t("creditsManagement"), requiresPermissions: true },
      { path: DefinedRoutesStrings.locationsPath, name: t("locationsManagement"), requiresPermissions: true },
      { path: DefinedRoutesStrings.listImageBankPath, name: t("imageBank"), requiresPermissions: true },
      { path: CommonRoutesStrings.logoutPath, name: t("logout"), requiresPermissions: false },
    ];

    return isCurrentSessionForImpersonatingUser() ? sidebarElems.slice(0, -1) : sidebarElems;
  };

  const toggleSidebar = () => setShowSidebar(b => !b);

  if (!isLoggedIn()) return null;

  return (
    <>
      <div className="md:hidden">
        <IconButton
          variant="text"
          size="lg"
          color="gray"
          ripple={false}
          onClick={toggleSidebar}
        >
          <FontAwesomeIcon icon={faBars} size="2xl" />
        </IconButton>
        <div
          className={`absolute top-0 md:hidden ${
            showSidebar ? 'left-64' : '-left-64'
          } z-50 transition-all duration-300`}
        >
          <IconButton
            variant="text"
            size="lg"
            color="gray"
            ripple={false}
            onClick={toggleSidebar}
          >
            <FontAwesomeIcon icon={faTimes} size="2xl" />
          </IconButton>
        </div>
      </div>
      <div className={`h-screen fixed top-0 md:left-0 ${showSidebar ? "left-0" : "-left-64"} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl bg-white w-64 z-10 py-4 px-6 transition-all duration-300`}>
        {!translationsReady ? <Spinner /> : (
          <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative">
            <Link to={CommonRoutesStrings.homePath} onClick={toggleSidebar}>
            {!myLogo ? <Spinner /> : ( 
              <img className="max-h-12 mx-auto"  src={myLogo[0].url} alt={t("appName")} />
              )}
              </Link>
            <div className="flex flex-col">
              <hr className="my-4 min-w-full" />
              <ul className="flex-col min-w-full flex list-none">
                {getSidebarElems().reduce((sidebarElemsToShow, sidebarElem, index) => {
                  const currSidebarElem = 
                    <li key={index} className="rounded-lg mb-2">
                      <NavLink
                        to={sidebarElem.path}
                        exact
                        className={isActive => `flex items-center gap-4 text-sm px-4 py-3 rounded-lg ${isActive ? "bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white font-bold shadow-md" : "border-2 hover:border-light-blue-400 border-white text-gray-700 font-light"}`}
                        onClick={toggleSidebar}
                      >{sidebarElem.name}</NavLink>
                    </li>;

                  return (!sidebarElem.requiresPermissions || allowedToAccessRoute(sidebarElem.path)) ?
                    sidebarElemsToShow.concat(currSidebarElem) : sidebarElemsToShow;
                }, [])}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Sidebar;