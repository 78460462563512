import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faCircle, faMinusSquare, faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { stringDateFormat } from "@src/helpers/dateHelpers";
import { rangeNumbersArray } from "@src/helpers/numberHelpers";
import { removeElemsFromArray } from "@src/helpers/arrayHelpers";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import TableCard from "@src/components/ui/TableCard";
import EyeButton from "@src/components/ui/buttons/EyeButton";
import PencilButton from "@src/components/ui/buttons/PencilButton";

const Timetable = ({
  
  numElements,
  selectedIndexes,
  cbSetSelectedIndexes,
  isTimetableSetForDateRange,
  cbShowHoursDetails,
  cbOpenEditHoursModal,
  currentPageData : dateRanges,
  currentPage,
  perPage : PER_PAGE

}) => {
  const { t, ready: translationsReady } = useTranslation(["components/products/create/timetables", "pages/products/create"]);

  const getCurrentPageIndexes = () => {
    const indexStart = PER_PAGE * currentPage,
      indexEnd = numElements < PER_PAGE * (currentPage + 1) ? numElements : PER_PAGE * (currentPage + 1);

    return rangeNumbersArray(indexStart, indexEnd);
  };

  const allIndexesOfCurrentPageSelected = () => {
    const indexesToCheck = getCurrentPageIndexes();
    return indexesToCheck.every(i => selectedIndexes.includes(i));
  };

  const toggleSelections = () => {
    const currentPageIndexes = getCurrentPageIndexes();
    if (allIndexesOfCurrentPageSelected())      
      cbSetSelectedIndexes(removeElemsFromArray(selectedIndexes, currentPageIndexes));
    else
      cbSetSelectedIndexes(selectedIndexes.concat(currentPageIndexes));
  };

  const getSelectAllButton = () =>
    <button onClick={toggleSelections}><FontAwesomeIcon icon={allIndexesOfCurrentPageSelected() ? faMinusSquare : faPlusSquare} size="lg"/></button>
  
  const getAbsoluteIndex = index => index + currentPage * PER_PAGE;

  const isDateRangeSelected = index => selectedIndexes.some(i => getAbsoluteIndex(index) === i);

  const selectDateRange = (doSelect, index) => {
    if (doSelect)
      cbSetSelectedIndexes(selectedIndexes.concat(getAbsoluteIndex(index)));
    else
      cbSetSelectedIndexes(selectedIndexes.filter(i => i !== getAbsoluteIndex(index)));
  };

  const getHeadersArray = () => [getSelectAllButton()].concat(Object.values(t("labels.datesTableHeaders")));

  const formatDataMatrix = () => {
    let matrix = [];

    dateRanges.forEach((dateRange, index) => {
      matrix.push([
          <button
            onClick={() => selectDateRange(isDateRangeSelected(index) ? false : true, index)}
          >
            {isDateRangeSelected(index) ? <FontAwesomeIcon icon={faCheckCircle} color="green" /> : <FontAwesomeIcon icon={faCircle} />}
          </button>,
          `${stringDateFormat(dateRange.fromDate)} - ${stringDateFormat(dateRange.toDate)}`,
          <>
            <EyeButton
              onClick={() => cbShowHoursDetails(getAbsoluteIndex(index))}
              disabled={!isTimetableSetForDateRange(dateRange)}
            />
            <PencilButton 
              onClick={() => cbOpenEditHoursModal(getAbsoluteIndex(index))}
              disabled={!isTimetableSetForDateRange(dateRange)}
            />
          </>
      ]);
    });

    return matrix;
  };
  
  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
          title={t("stepTitles", { ns: "pages/products/create" }).timetables}
          description={t("labels.stepDescription")}
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

export default Timetable;