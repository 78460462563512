import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "@src/components/ui/Button";
import Spinner from "@src/components/ui/Spinner";
import { Dialog } from "@material-tailwind/react";
import draftToHtml from "draftjs-to-html";
import { useState } from "react";
import SelectedBannersTable from "./SelectedBannersTable";
import ShowBannerDialog from "../banners/ShowBannerDialog";
import BannerService from "@src/services/bannerService";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";

const NewsletterBodyDetails = ({open, handler: backCallback, newsletter }) => {
  const { t, ready: translationsReady } = useTranslation(["common/navigation", "components/newsletters/form"]);
  const text = draftToHtml(JSON.parse(newsletter.body));
  const [banner, setBanner] = useState();
  const [selectedBannerToShow, setSelectedBannerToShow] = useState();

  const setHTMLText = () => {
    return {__html: text};
  }

  const loadBanner = async (banner_id) => {
    try{
      const banner = await BannerService.show(banner_id);
      setBanner(banner);
    } catch (error){
      createNotification(NotificationTypes.error, t("genericRequestError"));
    }
  }

  useEffect(() => {
    if (newsletter.banner_id){
      loadBanner(newsletter.banner_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <Dialog
          open={open}
        >
          <div className="grid grid-cols-1 m-2 space-y-6">
            <div dangerouslySetInnerHTML={setHTMLText()} className="grid grid-cols-1 p-2 border rounded-md bg-gray-50 overflow-auto" style={{ maxHeight: "70vh" }}>
            </div>
            {(newsletter.banner_id && banner) && (
              <div className="grid grid-cols-1 place-items-center max-w-xl mx-auto">
                <SelectedBannersTable
                  bannerList={[banner]}
                  showBannerCallback={setSelectedBannerToShow}
                />
              </div>
            )}
            <Button
              className="bg-gray-400 font-bold text-white place-self-center"
              onClick={backCallback}
              >{t("close")}
            </Button>
          </div>
          <ShowBannerDialog banner={selectedBannerToShow} backCallback={() => setSelectedBannerToShow()} /> 
        </Dialog>
      )}
    </>
  );
};

export default NewsletterBodyDetails;