import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "../components/ui/Spinner";

const NotFoundPage = () => {
  const { t, ready: translationsReady } = useTranslation("pages/notFound");

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <p className="text-center">{t("pageNotFoundMessage")}</p>
      )}
    </>
  );
};

export default NotFoundPage;