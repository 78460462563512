import axios from "@src/helpers/axiosInstance";
import { getJWT } from "@src/helpers/localStorageHelpers";
import { APIEndpointsStrings } from "@src/helpers/stringHelpers";

export const getAllCategories = () =>
  axios.get(APIEndpointsStrings.categoriesPath, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });

  export const getServiceCategories = () =>
  axios.get(APIEndpointsStrings.serviceCategoriesPath, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });

export const getCategoriesByName = name => {
  return axios.get(`/categories?q[name_cont]=${name}`, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });
};

export const getServiceCategoriesByName = name => {
  return axios.get(`/service_categories?q[name_cont]=${name}`, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });
};

export const getCategoriesWithId = categoryIds => {
  let filter = "";
  categoryIds.forEach(categoryId => filter += `q[id_in][]=${categoryId}&`);

  return axios.get(`/categories?${filter}`, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });
};

export const getServiceCategoriesWithId = serviceCategoryIds => {
  let filter = "";
  serviceCategoryIds.forEach(ServiceCategoryId => filter += `q[id_in][]=${ServiceCategoryId}&`);

  return axios.get(`/service_categories?${filter}`, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });
};

export const postNewCategory = (name, isPublic) =>
  axios.post(APIEndpointsStrings.categoriesPath, {
    category: { name, public: isPublic }
  }, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });

export const postNewServiceCategory = (name) =>
    axios.post(APIEndpointsStrings.serviceCategoriesPath, {
      service_category: { name }
    }, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });

export const patchCategory = (categoryId, name, isPublic) =>
  axios.patch(APIEndpointsStrings.categoryPath.replace(APIEndpointsStrings.idLocators.id, categoryId), {
    category: { name, public: isPublic }
  }, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });

  export const patchServiceCategory = (categoryId, name) =>
  axios.patch(APIEndpointsStrings.serviceCategoryPath.replace(APIEndpointsStrings.idLocators.id, categoryId), {
    service_category: { name }
  }, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });

export const delCategory = categoryId =>
  axios.delete(APIEndpointsStrings.categoryPath.replace(APIEndpointsStrings.idLocators.id, categoryId), {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });

  export const delServiceCategory = categoryId =>
  axios.delete(APIEndpointsStrings.serviceCategoryPath.replace(APIEndpointsStrings.idLocators.id, categoryId), {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });