import { Dialog, DialogBody, DialogFooter } from '@material-tailwind/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@src/components/ui/buttons/Button';
import SubmitButton from '@src/components/ui/buttons/SubmitButton';
import Spinner from '@src/components/ui/Spinner';

const BooleanResponseDialog = ({
  children,
  open,
  showLoadingSpinner,
  yesCallback,
  noCallback
}) => {
  const { t, ready: translationsReady } =
    useTranslation("common/booleanResponse");

  return (
    <Dialog open={open} handler={noCallback}>
      {!translationsReady ? <Spinner /> :
        <>
          <DialogBody>{children}</DialogBody>
            <DialogFooter className="flex">
              <Button
                className="mr-auto"
                color="gray"
                onClick={noCallback}
              >{t("no")}</Button>
              <SubmitButton
                className="ml-auto"
                onClick={yesCallback}
                showLoadingSpinner={showLoadingSpinner}
              >{t("yes")}</SubmitButton>
            </DialogFooter>
        </>
      }
    </Dialog>
  );
};
 
export default BooleanResponseDialog;