import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import Spinner from "@src/components/ui/Spinner";
import { Dialog } from "@material-tailwind/react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import BasicFormTemplate, { FooterType } from "@src/components/forms/BasicFormTemplate";
import FormikTextArea from "@src/components/forms/FormikTextArea";
import OrderRefundService from "@src/services/orders/OrderRefundService";

const FormInitialValues = {
  reason: ""
};

const FormSchema = Yup.object().shape({
  reason: Yup.string().required("components/orders/orderRefunds/denyDialog:reasonInput.empty"),
});

const DenyOrderRefundDialog = ({ open, handler: finalizeDialog, refundToDeny }) => {
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation("components/orders/orderRefunds/denyDialog");

  const denyRefund = async ({ reason }) => {
    setW18ApiResponse(b => !b);

    try {
      await OrderRefundService.deny(refundToDeny.orderId, refundToDeny.id, reason);
      createNotification(NotificationTypes.success, t("action.ok"));
      await finalizeDialog(true);
    } catch (_) {
      createNotification(NotificationTypes.error, t("action.ko"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <Dialog
          open={open}
          handler={finalizeDialog}
        >
          <Formik
            initialValues={FormInitialValues}
            validationSchema={FormSchema}
            onSubmit={denyRefund}
          >
            <Form>
              <BasicFormTemplate
                className="mt-8"
                formTitle={t("title")}
                showLoadingSpinner={w18ApiResponse}
                footerType={FooterType.BACK_SUBMIT_BUTTONS}
                backCallback={finalizeDialog}
              >
                <p>{t("description")}</p>
                <p>{t("reasonInput.description")}</p>
                <div className="my-6 px-4">
                  <FormikTextArea
                    name="reason"
                    label={t("reasonInput.label")}
                  />
                </div>              
              </BasicFormTemplate>
            </Form>
          </Formik>
        </Dialog>
      )}
    </>
  );
};

export default DenyOrderRefundDialog;