import { Formik, Form } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { isPastDate } from "@src/helpers/dateHelpers";
import TextInput from "@src/components/forms/TextInput";
import SubmitButton from "@src/components/ui/SubmitButton";
import Spinner from "@src/components/ui/Spinner";

const FormInitialValues = {
  date: "",
};

const FormSchema = Yup.object().shape({
  date: Yup.string().required("components/products/joker/productTimesliceSelector:dateSearch.validations.noDate")
    .test("isFutureDate", "components/products/joker/productTimesliceSelector:dateSearch.validations.pastDatesNotAllowed", strDate => {
      const selectedDate = new Date(strDate);
      return !isPastDate(selectedDate);
    }),
});

const DateSearchForm = ({ selectedDate, showLoadingSpinnerSubmitButton, cbPerformSearch }) => {
  const { t, ready: translationsReady } = useTranslation(["components/products/joker/productTimesliceSelector", "common/common"]);

  const handleSelection = async ({ date }, _) => {
    cbPerformSearch(date);
  };
  
  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <>
          <p>{t("dateSearch.description")}</p>
          <Formik
            initialValues={selectedDate ? { date: selectedDate } : FormInitialValues}
            validationSchema={FormSchema}
            onSubmit={handleSelection}
          >
            {({ values }) => (
              <Form>
                <div className="flex space-x-2">
                  <TextInput
                    label={t("dateSearch.dateLabel")}
                    name="date"
                    type="date"
                  />
                  <div className="flex items-center mt-2">
                    <SubmitButton
                      className="bg-green-500 text-white font-bold"
                      disabled={!values.date}
                      showLoadingSpinner={showLoadingSpinnerSubmitButton}
                    >{t("search", { ns: "common/common" })}</SubmitButton>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default DateSearchForm;