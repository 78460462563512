import { Dialog } from "@material-tailwind/react";
import { useToggle } from "@src/helpers/hooks/useToggle";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import * as Yup from "yup";
import ImageBankService from "@src/services/imageBankService";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import ImageBankForm from "@src/components/image_bank/ImageBankForm";

const UploadImageInitialValues = {
  description: "",
  picture: "",
  categories: [],
  fixed: false
};

const UploadImageValidationSchema = Yup.object().shape({
  description: Yup.string()
    .required("components/imageBank/imageBankForm:validationErrors.description.empty")
    .min(5, "components/imageBank/imageBankForm:validationErrors.description.min5characters")
    .max(255, "components/imageBank/imageBankForm:validationErrors.description.max255characters"),
  picture: Yup.mixed().required("common/form:validationErrors.empty.file"),
});

const UploadImageDialog = ({ open, backCallback, finalizeImageUploadCallback }) => {
  const [selectedFile, setSelectedFile] = useState();
  const [w18ApiResponse, setW18ApiResponse] = useToggle();
  const { t, ready: translationsReady } = useTranslation([
    "components/imageBank/uploadImageDialog",
    "components/imageBank/imageBankForm",
    "common/form",
  ]);

  const performImageUpload = async (values) => {
    setW18ApiResponse();

    try {
      await ImageBankService.create(selectedFile, values);
      createNotification(NotificationTypes.success, t("uploadImageAction.ok"));
      finalizeImageUploadCallback();
    } catch (error) {
      createNotification(NotificationTypes.error, t("uploadImageAction.ko"));
    } finally {
      setW18ApiResponse();
    }
  };

  return !translationsReady ? (
    <Spinner />
  ) : (
    <Dialog open={open} handler={backCallback}>
      <ImageBankForm
        initialValues={UploadImageInitialValues}
        validationSchema={UploadImageValidationSchema}
        showLoadingSpinner={w18ApiResponse}
        title={t("title")}
        setSelectedFileCallback={setSelectedFile}
        submitCallback={performImageUpload}
        backCallback={backCallback}
      />
    </Dialog>
  );
};

export default UploadImageDialog;
