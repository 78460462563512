import React from "react";
import { emptyArray } from "@src/helpers/arrayHelpers";
import { Alert } from "@material-tailwind/react";

const ErrorDisplayBox = ({ boxDescription, errors }) =>
  emptyArray(errors) ? null : (
    <Alert color="red">
      <p className="font-bold">{boxDescription}</p>
      <ul className="list-outside list-disc ml-8">
        {errors.map((error, index) => <li key={`errorDisplayBox-${index}`}>{error}</li>)}
      </ul>
    </Alert>
  );

export default ErrorDisplayBox;