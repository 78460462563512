import React, { useEffect } from "react";

const Modal = ({ show, cbSetVisibility, children, disableWidthRestriction, ...props }) => {

  const closeOnEscapeKeyDown = e => {
    if ((e.charCode || e.keyCode) === 27) {
      cbSetVisibility(false);
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
    // eslint-disable-next-line
  }, []);

  if (show === false) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center" onMouseDown={() => cbSetVisibility(false)}>
      <div className={`bg-white bg-opacity-100 border-2 border-gray-300 rounded-md ${!disableWidthRestriction ? "max-w-md" : ""}`} onMouseDown={e => e.stopPropagation()}>
        {children}
      </div>
    </div>);
};

export default Modal;