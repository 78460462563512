import { faCircle, faEye, faMinusSquare, faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { removeElemsFromArray } from "@src/helpers/arrayHelpers";
import { stringDateFormat } from "@src/helpers/dateHelpers";
import { rangeNumbersArray } from "@src/helpers/numberHelpers";
import Button from "@src/components/ui/Button";
import TableCard from "@src/components/ui/TableCard";
import Spinner from "@src/components/ui/Spinner";

const DateRangesForResourcesTable = ({
  
  numElems,
  selectedIndexes,
  cbSetSelectedIndexes,
  areResourcesSetForDateRange,
  cbShowResourceDetails,
  cbOpenEditResourcesModal,
  currentPageData : dateRanges,
  currentPage,
  perPage : PER_PAGE

}) => {
  const { t, ready: translationsReady } = useTranslation(["components/services/create/resources","pages/services/create", "common/list","pages/services/create"]);

  const getCurrentPageIndexes = () => {
    const indexStart = PER_PAGE * currentPage,
      indexEnd = numElems < PER_PAGE * (currentPage + 1) ? numElems : PER_PAGE * (currentPage + 1);

    return rangeNumbersArray(indexStart, indexEnd);
  };

  const allIndexesOfCurrentPageSelected = () => {
    const indexesToCheck = getCurrentPageIndexes();
    return indexesToCheck.every(i => selectedIndexes.includes(i));
  };

  const toggleSelections = () => {
    const currentPageIndexes = getCurrentPageIndexes();
    if (allIndexesOfCurrentPageSelected())      
      cbSetSelectedIndexes(removeElemsFromArray(selectedIndexes, currentPageIndexes));
    else
      cbSetSelectedIndexes(selectedIndexes.concat(currentPageIndexes));
  };

  const getSelectAllButton = () =>
    <button onClick={toggleSelections}><FontAwesomeIcon icon={allIndexesOfCurrentPageSelected() ? faMinusSquare : faPlusSquare} size="lg"/></button>
  
  const getAbsoluteIndex = index => index + currentPage * PER_PAGE;

  const isDateRangeSelected = index => selectedIndexes.some(i => getAbsoluteIndex(index) === i);

  const selectDateRange = (doSelect, index) => {
    if (doSelect)
      cbSetSelectedIndexes(selectedIndexes.concat(getAbsoluteIndex(index)));
    else
      cbSetSelectedIndexes(selectedIndexes.filter(i => i !== getAbsoluteIndex(index)));
  };

  const getHeadersArray = () => [
    getSelectAllButton(),
    t("mainScreen.dateRangesTableHeader"),
    t("actionsTableHeader", { ns: "common/list" })
  ];

  const formatDataMatrix = () => dateRanges.map((dateRange, index) => [
    <button
      onClick={() => selectDateRange(!isDateRangeSelected(index), index)}
    >
      {isDateRangeSelected(index) ? <FontAwesomeIcon icon={faCheckCircle} color="green" /> : <FontAwesomeIcon icon={faCircle} />}
    </button>,
    `${stringDateFormat(dateRange.fromDate)} - ${stringDateFormat(dateRange.toDate)}`,
    <>
      <Button
        onClick={() => cbShowResourceDetails(getAbsoluteIndex(index))}
        disabled={!areResourcesSetForDateRange(dateRange)}
        className="bg-green-600"
      ><FontAwesomeIcon icon={faEye} color="white" /></Button>
      <Button
        onClick={() => cbOpenEditResourcesModal(getAbsoluteIndex(index))}
        disabled={!areResourcesSetForDateRange(dateRange)}
        className="bg-blue-600"
      ><FontAwesomeIcon icon={faPencilAlt} color="white"/></Button>
    </>
  ]);
  
  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
          color="teal"
          title={t("stepTitles.resources", { ns: "pages/services/create" })}
          description={t("mainScreen.description")}
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

export default DateRangesForResourcesTable;