import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Dialog } from '@material-tailwind/react';
import Spinner from '@src/components/ui/Spinner';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BasicFormTemplate, { FooterType } from '@src/components/forms/BasicFormTemplate';
import SubmitButton from '@src/components/ui/buttons/SubmitButton';

const ValidateProfessionalDialog = ({ open, handler, professional, showLoadingSpinnerValidateAction, showLoadingSpinnerRevokeAction, cbValidateProfessional, cbRevokeProfessional }) => {
  const { t, ready: translationsReady } = useTranslation(["components/users/validateProfessionalDialog", "common/userTypes"]);

  return !translationsReady ? <Spinner /> : (
    <Dialog
      open={open}
      handler={handler}
    >
      <>
        {!professional ? undefined : (
          <BasicFormTemplate
            className="mt-8"
            formTitle={t("title")}
            footerType={FooterType.BACK_BUTTON}
            backCallback={handler}
          >
            <ul className="m-4 list-inside list-disc">
              <li>{t("content.email", { email: professional.email })}</li>
              <li>{t("content.type", { type: t(professional.type, { ns: "common/userTypes" }) })}</li>
              <li>{t("content.companyData.description")}</li>
              <ul className="ml-6 list-inside list-circle">
                <li>{t("content.companyData.cif", { cif: professional.company.cif })}</li>
                <li>{t("content.companyData.fiscalName", { name: professional.company.fiscalName })}</li>
                <li>{t("content.companyData.commercialName", { name: professional.company.commercialName })}</li>
                <li>{t("content.companyData.address", { address: professional.company.address })}</li>
                <li>{t("content.companyData.managerName", { name: professional.company.managerName })}</li>
                <li>{t("content.companyData.managerLandlineNumber", { number: professional.company.managerLandlineNumber })}</li>
                <li>{t("content.companyData.province", { province: professional.company.province })}</li>
                {/* Optional fields */}
                {professional.company.zipCode &&
                  <li>{t("content.companyData.zipCode", { zipCode: professional.company.zipCode })}</li>
                }
                {professional.company.country &&
                  <li>{t("content.companyData.country", { country: professional.company.country })}</li>
                }
                {professional.company.managerPosition &&
                  <li>{t("content.companyData.managerPosition", { managerPosition: professional.company.managerPosition })}</li>
                }
                {professional.company.managerPhoneNumber &&
                  <li>{t("content.companyData.managerPhoneNumber", { number: professional.company.managerPhoneNumber })}</li>
                }
              </ul>
            </ul>
            <div className="flex mt-8 justify-between">
              <SubmitButton
                color="red"
                icon={faTimes}
                showLoadingSpinner={showLoadingSpinnerRevokeAction}
                type="button"
                onClick={cbRevokeProfessional}
              >{t("actionButtons.revoke")}</SubmitButton>
              <SubmitButton
                color="green"
                icon={faCheck}
                showLoadingSpinner={showLoadingSpinnerValidateAction}
                type="button"
                onClick={cbValidateProfessional}
              >{t("actionButtons.validate")}</SubmitButton>
            </div>
          </BasicFormTemplate>
        )}
      </>
    </Dialog>
  );
};
 
export default ValidateProfessionalDialog;