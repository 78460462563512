import { Checkbox } from "@material-tailwind/react";
import { useField } from "formik";
import React from "react";

const FormikCheckbox = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <Checkbox
      checked={meta.value}
      label={label}
      {...field}
      {...props}
    />
  );
};

export default FormikCheckbox;