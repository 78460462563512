import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import IconButton from '@src/components/ui/buttons/IconButton';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const SpinnerButton = props => {
  return (
    <IconButton
      color="red"
      {...props}
    ><FontAwesomeIcon icon={faSpinner} /></IconButton>
  );
}
 
export default SpinnerButton;