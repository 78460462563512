import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormikTextInput from "@src/components/forms/FormikTextInput";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { emptyArray } from "@src/helpers/arrayHelpers";
import { stringDateFormat } from "@src/helpers/dateHelpers";
import SubmitButton from "@src/components/ui/buttons/SubmitButton";
import Spinner from "@src/components/ui/Spinner";

const RegularAvailabilityInitialValues = {
  fromDate: "",
  toDate: ""
};

const RegularAvailabilitySelector = ({ alreadyExistentConfigurations, editingProductConfigurations, configurations, setConfigurations }) => {
  const { t, ready: translationsReady } = useTranslation(["components/products/create/availability", "components/products/edit/addConfiguration", "common/list"]);

  const removeDate = dateIndex => setConfigurations(configurations.filter((value, index) => index !== dateIndex));
  
  const renderConfigurations = () => 
    configurations.length === 0 ? null : (
      <ul className="list-disc list-inside">      
        {configurations.map((configuration, index) => 
          <li key={`regular-dates-${index}`} className="ml-2">
            {`${stringDateFormat(configuration.fromDate)} - ${stringDateFormat(configuration.toDate)}`}
            <button
              onClick={() => removeDate(index)}
              className="ml-1"
            ><FontAwesomeIcon icon={faTimesCircle} color="red"/></button>
          </li>
        )}
      </ul>
    );


  const handleNewDate = async (values, actions) => {
    const fromDate = values.fromDate, toDate = values.toDate;
      setConfigurations(configurations.concat({ fromDate, toDate }));
      actions.resetForm({ values: RegularAvailabilityInitialValues });
  };

  const handleValidation = values => {
    const fromDate = values.fromDate, toDate = values.toDate, today = new Date();

    if (new Date(fromDate).valueOf() < new Date(today.getFullYear(), today.getMonth(), today.getDate()).valueOf())
      return { dates: "components/products/create/availability:validations.regularComponent.fromDateLowerThanCurrentDate" }
    if (new Date(fromDate).valueOf() > new Date(toDate).valueOf())
      return { dates: "components/products/create/availability:validations.regularComponent.fromDateLower" };
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <>
          <p>{t("labels.regularComponent.enterRanges")}</p>
          {renderConfigurations()}
          <Formik
            initialValues={RegularAvailabilityInitialValues}
            validate={handleValidation}
            onSubmit={handleNewDate}
          >
            {({ values, errors, touched }) => (
              <Form>
                <div className="flex space-x-2 mt-4">
                  <FormikTextInput
                    label={t("labels.regularComponent.fromDateInput")}
                    name="fromDate"
                    type="date"
                  />
                  <FormikTextInput
                    label={t("labels.regularComponent.toDateInput")}
                    name="toDate"
                    type="date"
                  />
                  <div className="flex items-center">
                    <SubmitButton
                      color="green"
                      disabled={(editingProductConfigurations && !emptyArray(configurations))|| !(values.fromDate && values.toDate) || configurations.length === 1}
                    >{t("add", { ns: "common/list" })}</SubmitButton>
                  </div>
                </div>
                {errors.dates && touched.toDate && touched.fromDate &&
                  <div className="text-red-500 font-semibold">{t(errors.dates)}</div>
                }
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default RegularAvailabilitySelector;