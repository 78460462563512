import React, { useState, useEffect, Fragment } from "react";
import { Redirect, useParams } from "react-router-dom";
import FormTitle from "@src/components/forms/FormTitle";
import Button from "@src/components/ui/Button";
import Modal from "@src/components/ui/Modal";
import { dateToStringDDMMYYYYHHMMSS } from "@src/helpers/dateHelpers";
import {
  createNotification,
  NotificationTypes,
} from "@src/helpers/notificationHelpers";
import { CommonRoutesStrings } from "@src/helpers/stringHelpers";
import OrderService from "@src/services/orders/orderService";
import ParticipantsDataTable from "@src/components/orders/new_order/ParticipantsDataTable";
import { isOperator, isAdmin } from "@src/helpers/userHelpers";
import PaginateData from "@src/components/ui/PaginateData";
import ListOrderStatusesTable from "@src/components/orders/ListOrderStatusesTable";
import { formatPrice } from "@src/helpers/localeHelpers";
import Spinner from "@src/components/ui/Spinner";
import { Trans, useTranslation } from "react-i18next";
import HistoryBackButton from "@src/components/ui/buttons/HistoryBackButton";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

const ShowOrderPage = () => {
  const { id } = useParams();
  const [order, setOrder] = useState();
  const [error, setError] = useState(false);
  const [showParticipantsModal, setShowParticipantsModal] = useState(false);
  const [selectedParticipants, setSelectedParticipants] = useState();
  const { t, ready: translationsReady } = useTranslation([
    "pages/orders/show",
    "pages/orders/create",
    "common/navigation",
    "common/orderStatuses",
  ]);
  const [openAccordionProduct, setOpenAccordionProduct] = useState(0);
  const handleOpen = (value) => {
    setOpenAccordionProduct(openAccordionProduct === value ? 0 : value);
  };

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const order = await OrderService.show(id);
        setOrder(order);
        setOpenAccordionProduct(1);
      } catch (error) {
        createNotification(NotificationTypes.error, t("genericRequestError"));
        setError(true);
      }
    };

    translationsReady && fetchOrder();
    // eslint-disable-next-line
  }, [translationsReady]);

  const showParticipants = (clientType, participants) => {
    setSelectedParticipants(
      participants.map((p) => ({ ...p, clientType: clientType.name }))
    );
    setShowParticipantsModal(true);
  };

  const getProductWithDateId = (id) =>
    order.orderProducts.find((op) => op.dateProductId === id).product;

  const getProductDatePrice = (productDate, priceId) => {
    let price = null;
    productDate.timeSlices.forEach((t) =>
      t.prices.forEach((p) => {
        if (!price && p.id === priceId) price = p;
      })
    );
    return price;
  };

  const renderOrderProductConfigurations = (orderProductConfigurations) =>
    orderProductConfigurations.map((c) => (
      <li key={c.id}>
        {getProductDatePrice(c.dateProductSnapshot, c.id)?.clientType?.name}:{" "}
        {c.quantity}
        {c.participants && (
          <Button
            className="ml-2 my-1 bg-gray-400 font-bold text-white"
            onClick={() =>
              showParticipants(
                getProductDatePrice(c.dateProductSnapshot, c.id)?.clientType,
                c.participants
              )
            }
          >
            {t("currentOrderData.showParticipantsButton", {
              ns: "pages/orders/create",
            })}
          </Button>
        )}
      </li>
    ));

  const renderOrderProducts = () =>
    order.orderProducts.map((op, index) => (
      <Accordion
        open={openAccordionProduct === index + 1}
        key={index + 1}
        className="mb-[-13px]"
      >
        <AccordionHeader onClick={() => handleOpen(index + 1)}>
          <Typography variant="small">
            {getProductWithDateId(op.dateProductId).name}
          </Typography>
        </AccordionHeader>
        <AccordionBody>
          <Typography variant="small">
            {t("orderProducts.selectedPrices")}
          </Typography>
          <ul className="ml-8 list-square ">
            {renderOrderProductConfigurations(op.configurations)}
          </ul>
        </AccordionBody>
      </Accordion>
    ));

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <>
          {error && <Redirect to={CommonRoutesStrings.listOrdersPath} />}
          <div className="flex justify-center items-center my-2 space-x-2">
            <HistoryBackButton size="md" />
          </div>
          {!order ? (
            <Spinner />
          ) : (
            <div className="container mx-auto max-w-lg space-y-2">
              <Card>
                <CardHeader color="yellow" className="relative h-15 top-7 py-4">
                  <FormTitle>{t("title")}</FormTitle>
                </CardHeader>
                <CardBody className="text-center mt-5">
                  <p>
                    {t("createdAt", {
                      date: dateToStringDDMMYYYYHHMMSS(order.createdAt),
                    })}
                  </p>
                  <p>
                    <Trans
                      t={t}
                      i18nKey="userEmail"
                      values={{ userEmail: order.userEmail }}
                      components={{ italic: <i /> }}
                    />
                  </p>
                  <p>
                    {t("status", {
                      status: t(order.status, { ns: "common/orderStatuses" }),
                    })}
                  </p>
                  <p>{t("price", { price: formatPrice(order.totalPrice) })}</p>
                  <p>{t("products")}</p>
                </CardBody>
                <CardFooter className="  items-center justify-between py-3">
                  {renderOrderProducts()}
                </CardFooter>
              </Card>
              {selectedParticipants && (
                <Modal
                  show={showParticipantsModal}
                  cbSetVisibility={setShowParticipantsModal}
                >
                  <div className="p-2">
                    <p>
                      {t("currentOrderData.participants", {
                        ns: "pages/orders/create",
                      })}
                    </p>
                    <ParticipantsDataTable
                      participantsData={selectedParticipants}
                    />
                    <Button
                      className="mt-2 bg-gray-700 font-bold text-white"
                      onClick={() => setShowParticipantsModal(false)}
                    >
                      {t("close", { ns: "common/navigation" })}
                    </Button>
                  </div>
                </Modal>
              )}
              {(isOperator() || isAdmin()) && (
                <div className="grid grid-cols-1 w-full border-t-4 justify-items-center">
                  <PaginateData data={order.orderStatuses} perPage={5}>
                    <ListOrderStatusesTable title={t("listStatus")} />
                  </PaginateData>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ShowOrderPage;
