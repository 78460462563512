import React from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from '@src/components/ui/Spinner';
import Button from '@src/components/ui/buttons/Button';

const CloseButton = props => {
  const { t, ready: translationsReady } = useTranslation("common/navigation");

  return !translationsReady ? <Spinner /> : (
    <Button
      color="gray"
      {...props}
    >{t("close")}</Button>
  );
};
 
export default CloseButton;