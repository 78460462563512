import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton as ParentButton } from '@material-tailwind/react';
import React from 'react';

const IconButton = ({ className, variant, icon, children, ...props }) =>
  <ParentButton
    className={"disabled:opacity-50 disabled:shadow-none " + className}
    variant={variant ?? "gradient"}
    {...props}
  >{children ?? <FontAwesomeIcon icon={icon} />}</ParentButton>;
 
export default IconButton;