import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { checkStrDatesSameDay, daysInMonth, generateYYYYMMDDwithParts } from "@src/helpers/dateHelpers";
import Spinner from "@src/components/ui/Spinner";

const OrderDateSelectorCalendar = ({ availableDays, selectedDay, selectDayCallback }) => {
  // Based on https://tailwindcomponents.com/component/calendar-ui-with-tailwindcss-and-alpinejs
  
  const [currentMonth, setCurrentMonth] = useState(new Date(availableDays[0]).getMonth());
  const [currentYear, setCurrentYear] = useState(new Date(availableDays[0]).getFullYear());
  const { t, ready: translationsReady } = useTranslation(["common/weekDays", "common/months"]);

  const stylingPreviousNextMonthButtons = "leading-none rounded-lg transition ease-in-out duration-100 inline-flex text-red-600 hover:bg-red-50 p-1 items-center";

  const getNumberPreviousMonthDays = () => {
    const weekday = new Date(currentYear, currentMonth).getDay();
    return weekday === 0 ? 6 : weekday - 1;
  };
  
  const previousMonthAction = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const nextMonthAction = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const isDayAvailable = (year, month, day) => !availableDays ? false : availableDays.some(date => checkStrDatesSameDay(date, year + "-" + (month+1) + "-" + day));
  
  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="container mx-auto p-2">
          <div className="flex items-center justify-between py-2">
            <button 
              type="button"
              className={stylingPreviousNextMonthButtons}
              onClick={previousMonthAction}
            >
              <FontAwesomeIcon icon={faAngleLeft} />
            </button>
            <p className="montserrat-semi-bold-shark-18px">{Object.values(t("months", { ns: "common/months" })).at(currentMonth)} {currentYear}</p>
            <button 
              type="button"
              className={stylingPreviousNextMonthButtons}
              onClick={nextMonthAction}
            >
              <FontAwesomeIcon icon={faAngleRight} />							  
            </button>
          </div>	

          <div className="-mx-1 -mb-1">
            <div className="flex flex-wrap">
              {Object.values(t("simplified")).map((day, index) =>
                <div key={"calendar-days-" + index} className="px-2 py-2 w-[14.28%]">
                  <div className="text-center montserrat-normal-mine-shaft-17px">{day}</div>
                </div>
              )}
            </div>

            <div className="flex flex-wrap">
              {getNumberPreviousMonthDays() === 0 ? null : [...new Array(getNumberPreviousMonthDays())].map((_, index) => 
                <div key={"calendar-blankday-" + index} className="px-2 w-[14.28%] mt-5" />
              )}
              {[...new Array(daysInMonth(new Date(currentYear, currentMonth)))].map((_, index) => {
                const isDaySelected = checkStrDatesSameDay(selectedDay, generateYYYYMMDDwithParts(currentYear, currentMonth + 1, index + 1));

                return (
                  <div
                    key={"calendar-monthday-" + index}
                    className={`px-2 w-[14.28%] text-center mt-5 ${isDayAvailable(currentYear, currentMonth, index + 1) ? `montserrat-bold-red-ribbon-16px ${isDaySelected ? "cursor-default" : "cursor-pointer"}` : "montserrat-medium-star-dust-16px cursor-default"}`}
                    onClick={isDayAvailable(currentYear, currentMonth, index + 1) && !isDaySelected ? () => selectDayCallback(generateYYYYMMDDwithParts(currentYear, currentMonth + 1, index + 1)) : () => {}}
                  >
                    <div
                      className={`inline-flex w-7 h-6 items-center justify-center text-center rounded-full ${isDayAvailable(currentYear, currentMonth, index + 1) ? (`${isDaySelected ? "bg-red-100 cursor-default" : "hover:bg-red-50"}`) : ""}`}
                    >{index + 1}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderDateSelectorCalendar;