import React from "react";
import { useParams } from "react-router-dom";
import ProductImageSelector from "@src/components/products/product_wizard/ProductImageSelector";
import { useToggle } from "@src/helpers/hooks/useToggle";
import ProductService from "@src/services/products/productService";
import { createNotification, delayedRedirect, NotificationTypes } from "@src/helpers/notificationHelpers";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import { DefinedRoutesStrings } from "@src/helpers/stringHelpers";

const EditProductImages = ({ productData, backCallback, readOnly }) => {
  const [w18ApiResponse, toggleW18ApiResponse] = useToggle();
  const { id: productId } = useParams();
  const { t, ready: translationsReady } = useTranslation("components/products/edit/editProductImages");

  const handleSelectedImages = async (imageIds) => {
    toggleW18ApiResponse();

    try {
      await ProductService.updateProductImages(productId, productData.type, imageIds);
      createNotification(NotificationTypes.success, t("action.ok"));
      delayedRedirect(DefinedRoutesStrings.listProductsPath);
    } catch (err) {
      createNotification(NotificationTypes.error, t("action.ko"));
    } finally {
      toggleW18ApiResponse();
    }
  };

  return !translationsReady ? (
    <Spinner />
  ) : (
    <ProductImageSelector
      editing={readOnly ? undefined : true}
      readOnly={readOnly}
      showLoadingSpinner={w18ApiResponse}
      callbackBackClicked={backCallback}
      callbackNextClicked={handleSelectedImages}
      initialValues={productData.images.map((image) => image.id)}
    />
  );
};

export default EditProductImages;
