import { faMinusSquare, faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import SmallSpinner from "react-spinkit";
import { listArrayElemsInString } from "@src/helpers/arrayHelpers";
import { stringDateFormat } from "@src/helpers/dateHelpers";
import { getServiceCategory } from "@src/helpers/serviceCategoryHelpers";
import { getUserRequest } from "@src/helpers/userHelpers";
import { Card, CardBody, CardHeader, Dialog, Typography } from "@material-tailwind/react";
import Button from "@src/components/ui/Button";
import ErrorDisplayBox from "@src/components/ui/ErrorDisplayBox";
import { getDateRangeRegularityDescription } from "@src/components/products/product_wizard/RegularityTable";
import ResourcesSelectedDetails from "@src/components/products/services/create/ResourcesSelectedDetails";
import { useTranslation } from "react-i18next";
import BigSpinner from "@src/components/ui/Spinner";
import BackNextSubmitButtons from "@src/components/products/product_wizard/BackNextSubmitButtons";


const ServiceSummary = ({ currentStep, numberSteps, initialValues : service, callbackBackClicked, callbackNextClicked, submissionErrors, showLoadingSpinner }) => {
  const [showDateRanges, setShowDateRanges] = useState(false);
  const [showResourcesTableModal, setShowResourcesTableModal] = useState(false);
  const [resourcesToShow, setResourcesToShow] = useState();
  const [professionalEmail, setProfessionalEmail] = useState();
  const [serviceCategory, setServiceCategory] = useState();
  const { t, ready: translationsReady } = useTranslation([
    "components/services/create/summary",
    "components/services/create/basicInfoForm",
    "components/services/create/resources",
    "common/navigation",
    "common/booleanResponse",
    "common/weekDays",
    "common/common",
    "common/regularityTypes",
  ]);

  useEffect(() => {
    const fetchServicesProviderData = async () => {
      try {
        const getUserResponse = await getUserRequest(service.servicesProviderId);
        setProfessionalEmail(getUserResponse.data.email);
      } catch (error) {
        console.log(error);
      }
    };
    const fetchServiceCategoryData = async () => {
      try {
        const getServiceCategoryResponse = await getServiceCategory(service.serviceCategoryId);
        setServiceCategory(getServiceCategoryResponse.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchServicesProviderData();
    fetchServiceCategoryData();
    // eslint-disable-next-line
  }, []);

  const showResourcesTable = resources => {
    setResourcesToShow(resources);
    setShowResourcesTableModal(true);
  };

  const renderData = (key, value) => <p><span className="font-bold">{key}:</span> {value}</p>;

  const renderDateRanges = () => service.configurations.map((dateRange, index) =>
    <div key={index} className="bg-gray-100 border-2 rounded p-2 my-2">
      {renderData(t("dateRange"), stringDateFormat(dateRange.fromDate) + " - " + stringDateFormat(dateRange.toDate))}
      {dateRange.regularityType != null &&
        renderData(t("regularityType"), t(dateRange.regularityType, { ns: "common/regularityTypes" }))}
      {dateRange.regularityData != null &&
        renderData(t("regularityData"), getDateRangeRegularityDescription(dateRange))
      }
      {dateRange.dates != null &&
        renderData(t("dates"), listArrayElemsInString(dateRange.dates))
      }
      <Button
        onClick={() => showResourcesTable(dateRange.resources)}
        className="bg-gray-700 text-white font-bold mt-2 mr-2"
      >{t("viewResourcesBtn")}</Button>
    </div>
  );

  return (
    <>
      {!translationsReady ? <BigSpinner /> : (
        <>
        <Card className="mx-auto max-w-5xl mt-10">
          <CardHeader color="teal" variant="gradient" className="flex py-10 px-2 place-content-center">
            <Typography variant="h4" color="white" className="text-center">
            {t("stepTitles.summary", { ns: "pages/services/create" })}
            </Typography>
          </CardHeader>
          <CardBody>
          <ErrorDisplayBox
            boxDescription={t("errorBoxDescription")}
            errors={submissionErrors}
          /> 
          {renderData(t("serviceName"), service.name)}
          {!professionalEmail ? <SmallSpinner name="circle" fadeIn="none" /> : renderData(t("labels.professional", { ns: "components/services/create/basicInfoForm" }), professionalEmail)}
          {!serviceCategory ? <SmallSpinner name="circle" fadeIn="none" /> : renderData(t("labels.serviceCategory", { ns: "components/services/create/basicInfoForm" }), serviceCategory.name)}
          {renderData(t("hasRegularity"), service.regularity ? t("yes", { ns: "common/booleanResponse" }) : t("no", { ns: "common/booleanResponse" }))}
          <div className="flex space-x-1">
            {renderData(t("dateRanges"))}
            <button
              onClick={() => setShowDateRanges(!showDateRanges)}
            ><FontAwesomeIcon icon={showDateRanges ? faMinusSquare : faPlusSquare} color="gray" size="lg"/></button>
          </div>
          <div className="ml-3">
            { showDateRanges && renderDateRanges() }
          </div>
          <Dialog
            open={showResourcesTableModal}
            handler={setShowResourcesTableModal}
          >
            <div className="m-2 space-y-2">
              <ResourcesSelectedDetails resources={resourcesToShow} title={t("mainScreen.resourceDetailsDescription", { ns: "components/services/create/resources" })} />
              <div className="flex justify-center items-center">
                <Button
                  onClick={() => setShowResourcesTableModal(false)}
                  className="bg-gray-700 text-white font-bold"
                >{t("close", { ns: "common/navigation" })}</Button>
              </div>
            </div>
          </Dialog>
          <BackNextSubmitButtons
            cbBackButton={callbackBackClicked}
            cbSubmitButton={callbackNextClicked}
            showLoadingSpinnerSubmitButton={showLoadingSpinner}
            currentStep={currentStep}
            numberSteps={numberSteps}
          />
           </CardBody>
        </Card>
        </>
      )}
    </>
  );
};

export default ServiceSummary;