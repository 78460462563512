import axios from "@src/helpers/axiosInstance";
import { getJWT } from "@src/helpers/localStorageHelpers";
import { APIEndpointsStrings } from "@src/helpers/stringHelpers";

export const getPriceTemplates = () => {
  return axios.get("/price_templates", {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });
};

export const createPriceTemplate = (name, peopleSegmentIds) =>
  axios.post(APIEndpointsStrings.priceTemplatesPath, { 
    price_template: {
      name, people_segment_ids: peopleSegmentIds
    }
  }, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });

export const updatePriceTemplate = (id, name, peopleSegmentIds) =>
  axios.patch(APIEndpointsStrings.singlePriceTemplate.replace(APIEndpointsStrings.idLocators.id, id), {
    price_template: {
      name, people_segment_ids: peopleSegmentIds
    }
  }, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });

export const deletePriceTemplate = id =>
  axios.delete(APIEndpointsStrings.singlePriceTemplate.replace(APIEndpointsStrings.idLocators.id, id), {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });