import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route } from "react-router";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { allowedToAccessRoute } from "@src/helpers/permissionHelpers";
import { isLoggedIn } from "@src/helpers/sessionHelpers";
import { B2CRoutesStrings, CommonRoutesStrings } from "@src/helpers/stringHelpers";
import Spinner from "@src/components/ui/Spinner";

const PrivateRoute = ({ children, ...props }) => {
  const { t, ready: translationsReady } = useTranslation("components/privateRoute");

  useEffect(() => {
    if (translationsReady) {
      if (!isLoggedIn()) {
        createNotification(NotificationTypes.error, t("notLoggedInMessage"));
      } else if (!allowedToAccessRoute(props.path)) {
        createNotification(NotificationTypes.error, t("notEnoughPermissions"));
      }
    }
    // eslint-disable-next-line
  }, [props.path, translationsReady]);

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <>
          {!isLoggedIn() ? (
            <>
              <Redirect to={B2CRoutesStrings.loginPath} />
            </>
          ) : !allowedToAccessRoute(props.path) ? (
            <>
              <Redirect to={CommonRoutesStrings.homePath} />
            </>
          ) : (
            <Route {...props}>
              {children}
            </Route>
          )}
        </>
      )}
    </>
  );
};

export default PrivateRoute;