import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "react-spinkit";
import TableCard from "@src/components/ui/TableCard";
import EyeButton from "@src/components/ui/buttons/EyeButton";
import {
  APIEndpointsStrings,
  DefinedRoutesStrings,
  OrderStatusStrings
} from "@src/helpers/stringHelpers";
import { dateToStringDDMMYYYYHHMMSS } from "@src/helpers/dateHelpers";
import TickButton from "@src/components/ui/buttons/TickButton";
import TimesButton from "@src/components/ui/buttons/TimesButton";
import { formatPrice } from "@src/helpers/localeHelpers";
import IconButton from "@src/components/ui/buttons/IconButton";
import { faComment } from "@fortawesome/free-solid-svg-icons";

const ListOrderRefundsTable = ({ currentPageData : orderRefunds, cbShowRefundComments, cbAcceptRefund, cbDenyRefund, }) => {
  const { t, ready: translationsReady } = useTranslation(["components/orders/orderRefunds/listTable", "pages/orders/listOrderRefunds", "common/orderStatuses", "common/common"]);

  const getHeadersArray = () => Object.values(t("headers"));

  const formatDataMatrix = () => orderRefunds.map(orderRefund => {
    const showOrderPathWithOrderId = DefinedRoutesStrings.showOrderPath.replace(APIEndpointsStrings.idLocators.id, orderRefund.orderId),
      isOrderAwaitingRefund = orderRefund.orderStatus.status === OrderStatusStrings.pendingRefund,
      showOrderButtonComponent = (
        <EyeButton
          targetPath={showOrderPathWithOrderId}
          title={t("buttonTitles.showOrderDetails", { ns: "pages/orders/listOrderRefunds" })}
        />
      ),
      showRefundCommentsButtonComponent = (
        <IconButton
          icon={faComment}
          onClick={() => cbShowRefundComments(orderRefund)}
          title={t("buttonTitles.showRefundComments", { ns: "pages/orders/listOrderRefunds" })}
        />
      );
    
    return [
      orderRefund.id,
      orderRefund.clientEmail,
      t(orderRefund.orderStatus.status, { ns: "common/orderStatuses" }),
      isOrderAwaitingRefund ? t("notAvailable", { ns: "common/common" }) : formatPrice(orderRefund.refundQuantity),
      dateToStringDDMMYYYYHHMMSS(orderRefund.createdAt),
      isOrderAwaitingRefund ? (
        <>
          {showOrderButtonComponent}
          {showRefundCommentsButtonComponent}
          <TickButton
            onClick={() => cbAcceptRefund(orderRefund)}
            title={t("buttonTitles.accept", { ns: "pages/orders/listOrderRefunds" })}
          />
          <TimesButton
            onClick={() => cbDenyRefund(orderRefund)}
            title={t("buttonTitles.deny", { ns: "pages/orders/listOrderRefunds" })}
          />
        </>
      ) : (
        <>
          {showOrderButtonComponent}
          {showRefundCommentsButtonComponent}
        </>
      ),
    ];
  });

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
          title={t("title", { ns: "pages/orders/listOrderRefunds" })}
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

export default ListOrderRefundsTable;