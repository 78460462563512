import React from 'react';
import Button from '@src/components/ui/buttons/Button';
import { useTranslation } from 'react-i18next';
import Spinner from '@src/components/ui/Spinner';

const NewButton = props => {
  const { t, ready: translationsReady } = useTranslation("common/common");

  return !translationsReady ? <Spinner /> : (
    <Button
      color="green"
      {...props}
    >{t("new")}</Button>
  );
};
 
export default NewButton;