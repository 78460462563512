import React from 'react';

/**
 * Just a wrapper for the HTML <a> tag :)
 * 
*/

const ExternalLinkWrapper = ({ href, children, ...props }) => (
  <a
    href={href}
    target="_blank" rel="noopener noreferrer"
    {...props}
  >{children}</a>
);
 
export default ExternalLinkWrapper;