import {
    Card,
    CardBody,
    CardHeader,
    Typography,
  } from "@material-tailwind/react";
  import { Form, Formik } from "formik";
  import React from "react";
  import { useTranslation } from "react-i18next";
  import FormikTextInput from "@src/components//forms/FormikTextInput";
  import Spinner from "@src/components//ui/Spinner";
  import { isServicesProvider } from "@src/helpers/userHelpers";
  import FormProfessionalSearch from "@src/components/products/FormProfessionalSearch";
  import FormikSelect from "@src/components/forms/FormikSelect";
  import { ProductTypesStrings } from "@src/helpers/stringHelpers";
import CategoryFormSearch from "@src/components/products/categories/CategoryFormSearch";
  
  const FormInitialValues = {
    name: "",
    productType: "",
    productProvider: "",
    productCategory: "",
    
  
  };
  let productType = undefined
  
  const FilterProductsForm = ({
    className,
    color,
    cbSetName,
    cbSetProductType,
    cbSetProductProvider,
    cbSetProductCategory,
  }) => {
    const { t, ready: translationsReady } = useTranslation([
      "components/products/create/productPropertiesForm",
      "components/services/create/basicInfoForm",
      "common/filterForm",
      "common/form",
      "common/productTypes",
    ]);
    const DEFAULT_COLOR = "light-blue";
  
    const getOptionsForProductTypeSelect = () => [
      {
        value: "",
        label: t("any", { ns: "common/common" })
      },
      {
        value: ProductTypesStrings.ownProduct,
        label: t("OwnProduct", { ns: "common/productTypes" })
      },
      {
        value: ProductTypesStrings.thirdPartyProduct,
        label: t("ThirdPartyProduct", { ns: "common/productTypes" })
      }
    ];
  
    return (
      <>
        {!translationsReady ? (
          <Spinner />
        ) : (
          <Card className={className}>
            <CardHeader
              color={color ?? DEFAULT_COLOR}
              variant="gradient"
              className="p-5"
            >
              <Typography variant="h5" color="white">
                {t("description", { ns: "common/filterForm" })}
              </Typography>
            </CardHeader>
            <CardBody>
              <Formik initialValues={FormInitialValues}>
                {({ handleChange }) => (
                  <Form className="grid  sm:grid-flow-row lg:grid-flow-col  lg:space-x-6 sm:items-start lg:items-center w-full">
                    <FormikTextInput
                      name="name"
                      type="name"
                      color={color ?? DEFAULT_COLOR}
                      label={t("labels.name", { ns: "common/form" })}
                      onChange={(e) => {
                        handleChange(e);
                        cbSetName(e.target.value);
                      }}
                    />
                    <FormikSelect
                      className={"sm:mt-5 lg:mt-0"}
                      label={t("labels.productType")}
                      name="productType"
                      options={getOptionsForProductTypeSelect()}
                      defaultOptionLabel={t("placeholders.selectOne", { ns: "common/form" })}
                      onChange={(optionValue) => {
                        cbSetProductType(optionValue);
                        cbSetProductProvider("");
                        productType = optionValue;
                      }}
                    />
                    <CategoryFormSearch
                      label={t("labels.categories")}
                      placeholder={t("placeholders.selectOne", {
                        ns: "common/form",
                      })}
                      name="productCategoryId"
                      loadOptionsErrorMsg={t(
                        "messages.errorRetrievingServiceCategories"
                      )}
                      onChange={(optionValue) =>
                        cbSetProductCategory(optionValue)
                      }
                    />
                    {!isServicesProvider() && productType === ProductTypesStrings.thirdPartyProduct  && (
                      <FormProfessionalSearch
                        productType={ productType }
                        label={t("labels.professional")}
                        placeholder={t("placeholders.selectOne", { ns: "common/form" })}
                        name="professionalId"
                        loadOptionsErrorMsg={t("messages.errorRetrievingProfessionals", { ns: "pages/products/create" })}
                        noOptionsMessage={t("placeholders.noOptionsInSelect", { ns: "common/form" })}
                        onChange={(optionValue) =>
                          cbSetProductProvider(optionValue)
                        }
                      />
                    )}
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        )}
      </>
    );
  };
  export default FilterProductsForm;