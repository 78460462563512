import { Dialog } from '@material-tailwind/react';
import Spinner from '@src/components/ui/Spinner';
import { createNotification, NotificationTypes } from '@src/helpers/notificationHelpers';
import ProductService from '@src/services/products/productService';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ProductTranslationForm from '@src/components/products/edit_product_data/edit_product_translations/ProductTranslationForm';

const EditProductTranslationDialog = ({ open, closeDialogCallback, translationEditedCallback, productTranslation, productType }) => {
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation("components/products/edit/editProductTranslations");
  const { id: productId } = useParams();

  const handleTranslationSubmission = async ({ language, ...translation }) => {
    setW18ApiResponse(b => !b);

    try {
      await ProductService.setTranslation(productId, productType, language, translation);
      await translationEditedCallback();
      createNotification(NotificationTypes.success, t("messages.edit.ok"));
    } catch (err) {
      createNotification(NotificationTypes.error, t("messages.edit.ko"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  return (
    <Dialog
      open={open}
      handler={closeDialogCallback}
    >
      {!translationsReady ? <Spinner /> : (
        <ProductTranslationForm
          initialValues={productTranslation}
          backCallback={closeDialogCallback}
          submitCallback={handleTranslationSubmission}
          title={t("titles.edit")}
          showLoadingSpinner={w18ApiResponse}
        />
      )}
    </Dialog>
  );
};
 
export default EditProductTranslationDialog;