import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ListAllProductsTable from "@src/components/products/ListAllProductsTable";
import LinkButton from "@src/components/ui/buttons/LinkButton";
import PaginateData from "@src/components/ui/PaginateData";
import { getCurrentUserData } from "@src/helpers/localStorageHelpers";
import {
  createNotification,
  NotificationTypes
} from "@src/helpers/notificationHelpers";
import { allowedToAccessRoute } from "@src/helpers/permissionHelpers";
import { deleteCancelProduct, getCountThirdPartyProductsToValidate, isOwnProduct } from "@src/helpers/productHelpers";
import { CommonRoutesStrings, DefinedRoutesStrings } from "@src/helpers/stringHelpers";
import { isProvider, isServicesProvider, currentUserHasWritePermissions } from "@src/helpers/userHelpers";
import {
  deleteOwnProductRequest,
  deleteThirdPartyProductRequest
} from "@src/helpers/productHelpers";
import Spinner from "@src/components/ui/Spinner";
import { useTranslation } from "react-i18next";
import ProductService from "@src/services/products/productService";
import BooleanResponseDialog from "@src/components/ui/BooleanResponseDialog";
import { Card, CardBody, CardHeader, Chip, Typography } from "@material-tailwind/react";
import Button from "@src/components/ui/buttons/Button";
import LoadProductsXlsxDialog from "@src/components/products/LoadProductsXlsxDialog";
import CancelProductDialog from "@src/components/products/cancel_product/CancelProductDialog";
import FilterProductsForm from "@src/components/forms/FilterProductsForm";


const ListProductsPage = () => {
  const [products, setProducts] = useState();
  const [countThirdPartyProductsToValidate, setCountThirdPartyProductsToValidate] = useState(0);
  const [productToDelete, setProductToDelete] = useState();
  const [productToCancel, setProductToCancel] = useState();
  const [showFilterForm, setShowFilterForm] = useState(true);
  const [nameFilter, setNameFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [categoryProductFilter, setProductCategoryFilter] = useState("");
  const [providerFilter, setProviderFilter] = useState("");
  const [showLoadProductsXlsxDialog, setShowLoadProductsXlsxDialog] = useState(false);
  const { t, ready: translationsReady, i18n } = useTranslation(["pages/products/list","common/filterForm"]);
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const PRODUCTS_PER_PAGE = 5;
  const history = useHistory();

  const toggleFiltering = () => {
    setShowFilterForm((show) => !show);
    setNameFilter("");
    setTypeFilter("");
    setProviderFilter("");
    setProductCategoryFilter("");
  }

  const deleteProduct = async () => {
    setW18ApiResponse(b => !b);

    try {
      if (isOwnProduct(productToDelete.type)) {
        await deleteOwnProductRequest(productToDelete.id);
      } else {
        await deleteThirdPartyProductRequest(productToDelete.id);
      }

      createNotification(NotificationTypes.success, t("messages.okProductDeletionMessage"));
      setProducts(products.filter(c => c.id !== productToDelete.id));
      setProductToDelete();
    } catch (error) {
      createNotification(NotificationTypes.error, t("messages.genericRequestError"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  function handleFilterInputChange(setStateCallback, newState) {
    setStateCallback(newState);
  }

  const cancelProduct = async (comment) => {
    setW18ApiResponse(b => !b);
    setProductToCancel();
    try {
      await deleteCancelProduct(productToCancel.id,comment.reason); 
      createNotification(NotificationTypes.success, t("messages.okProductCancelMessage"));
      setTimeout(() => {
        fetchProducts();
      }, "1000");
      setTimeout(() => {
        fetchProducts();
      }, "10000");
     
    } catch (error) 
    {
      console.error(error);
      createNotification(NotificationTypes.error, t("messages.genericRequestError"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  const fetchProducts = async () => {
    try {
      let receivedProducts;
      if (isProvider()) {
        receivedProducts = await ProductService.indexProviderProducts(getCurrentUserData().id);
      } else { // Operator and Admin
        receivedProducts = await ProductService.indexValidatedProducts();
        const responseGetCountThirdPartyProductsToValidate = await getCountThirdPartyProductsToValidate();
        setCountThirdPartyProductsToValidate(responseGetCountThirdPartyProductsToValidate.data.count);
      }

      setProducts(receivedProducts);
    } catch (error) {
      console.log(error)
      createNotification(NotificationTypes.error, t("messages.cannotRetrieveProducts"));
      history.push(CommonRoutesStrings.homePath);
    }
  };

  useEffect(() => {
    translationsReady && fetchProducts();
    // eslint-disable-next-line
  }, [translationsReady]);

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line
  }, [i18n.language]);

  useEffect(() => {
    const productsbyfilters = async () => {
      setProducts(
        await ProductService.indexProductsFilter(
          nameFilter,
          typeFilter,
          providerFilter,
          categoryProductFilter,
        )
      );
    }
    productsbyfilters();
  }, [nameFilter, typeFilter, providerFilter,categoryProductFilter]);

  const closeLoadProductsXlsxDialog = async (shouldReloadProducts) => {
    setShowLoadProductsXlsxDialog(false);
    shouldReloadProducts && await fetchProducts();
  };

  const toggleProductHighlight = async (product) => {
    const action  = product.highlighted ? ProductService.unhighlight : ProductService.highlight,
      messages = product.highlighted ? t("messages.unhighlightAction") : t("messages.highlightAction");

    setW18ApiResponse(b => !b);

    try {
      await action(product);
      await fetchProducts();
      createNotification(NotificationTypes.success, messages.ok);
    } catch (err) {
      createNotification(NotificationTypes.error, messages.ko);
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  const getCreateProductRoute = () => {
    if (isServicesProvider()) return DefinedRoutesStrings.createOwnProductPath;
    else if (isProvider()) return DefinedRoutesStrings.createThirdPartyProductsPath;
    else // Operator or Admin
      return DefinedRoutesStrings.createProductPath;
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <>
          <div className="flex justify-center">
            <Card className="mt-7 mb-2">
              <CardHeader
                color="yellow"
                variant="gradient"
                className="p-5"
              >
                <Typography
                  variant="h5"
                  color="white"
                >{t("createProductBox.title")}</Typography>
              </CardHeader>
              <CardBody className="space-x-2">
                <LinkButton
                  id="create-product"
                  to={getCreateProductRoute()}
                  color="green"
                  disabled={!currentUserHasWritePermissions()}
                >{t("createProductBox.buttons.single")}</LinkButton>
                <Button
                  color="purple"
                  onClick={() => setShowLoadProductsXlsxDialog(true)}
                  disabled={!currentUserHasWritePermissions()}
                >{t("createProductBox.buttons.bulk")}</Button>
              </CardBody>
            </Card>
          </div>
          <div className="flex justify-center items-center mt-2 space-x-2">
            {allowedToAccessRoute(DefinedRoutesStrings.validateThirdPartyProductsPath) &&
              <>
                <LinkButton
                  id="validate-product"
                  to={DefinedRoutesStrings.validateThirdPartyProductsPath}
                >{t("buttons.validateThirdPartyProducts.text")}</LinkButton>
                
                {countThirdPartyProductsToValidate > 0 &&
                  <Chip
                    color="red"
                    className="right-6 -top-4 absolute cursor-help"
                    title={t("buttons.validateThirdPartyProducts.badgeInfo")}
                    value={countThirdPartyProductsToValidate}
                  />
                }
              </>
            }
              {allowedToAccessRoute(DefinedRoutesStrings.listPeopleSegments) &&
                <LinkButton 
                  to={DefinedRoutesStrings.listPeopleSegments}
                >{t("buttons.peopleSegments")}</LinkButton>
              }
              {allowedToAccessRoute(DefinedRoutesStrings.listProductCategories) &&
                <LinkButton
                  to={DefinedRoutesStrings.listProductCategories}
                >{t("buttons.productCategories")}</LinkButton>
              }
              <Button className={"relative"} onClick={toggleFiltering}>
                {!showFilterForm
                  ? t("enableFilter", { ns: "common/filterForm" })
                  : t("disableFilter", { ns: "common/filterForm" })}
              </Button>
          </div>
          {!products ? <Spinner /> : (
            <div className="grid grid-cols-1 place-items-center">     
            {showFilterForm && (
                <FilterProductsForm
                  color="yellow"
                  className="mt-11 mb-2 w-full"
                  cbSetName={(name) =>
                    handleFilterInputChange(setNameFilter, name)
                  }
                  cbSetProductType={(type) =>
                    handleFilterInputChange(setTypeFilter, type)
                  }
                  
                  cbSetProductProvider={(provider) =>
                    handleFilterInputChange(setProviderFilter, provider)
                  }
                  cbSetProductCategory={(category) =>
                    handleFilterInputChange(setProductCategoryFilter, category)
                  }
                />
              )}      
              <PaginateData 
                data={products}
                emptyDataArrayMsg={t("messages.noProductsToList")}
                perPage={PRODUCTS_PER_PAGE}
              >
                <ListAllProductsTable
                  deleteProductCallback={product => setProductToDelete(product)}
                  toggleProductHighlightCallback={toggleProductHighlight}
                  cancelProductCallback={product => setProductToCancel(product)}
                />
              </PaginateData>
            </div>
          )}
          <BooleanResponseDialog
            open={!!productToDelete}
            yesCallback={deleteProduct}
            noCallback={() => setProductToDelete()}
            showLoadingSpinner={w18ApiResponse}
          >
            <>
              {productToDelete && t("messages.confirmProductDeletion", { productName: productToDelete.name })}
            </>
          </BooleanResponseDialog>
          <CancelProductDialog
            open={!!productToCancel}
            handleSubmit={(comment) => cancelProduct(comment)}
            handler={() => setProductToCancel()}
            showLoadingSpinner={w18ApiResponse}
            productToCancel={productToCancel}
            title={t("cancelProductBox.title")}
            message={productToCancel && t("messages.confirmProductCancel", {
                    productName: productToCancel.name,
                  })}
          />
          <LoadProductsXlsxDialog
            open={showLoadProductsXlsxDialog}
            handler={closeLoadProductsXlsxDialog}
          />
        </>
      )}
    </>
  );
};

export default ListProductsPage;