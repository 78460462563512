import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import PeopleSegmentSearch from "@src/components/products/product_wizard/PeopleSegmentSearch";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import BasicFormTemplate, { FooterType } from "@src/components/forms/BasicFormTemplate";
import FormikTextInput from "@src/components/forms/FormikTextInput";
import ErrorDisplayBox from "@src/components/ui/ErrorDisplayBox";

const PriceTemplateInitialValues = {
  name: "",
  peopleSegmentIds: []
};

const PriceTemplateSchema = Yup.object().shape({
  name: Yup.string().required("components/products/priceTemplates/form:validations.noName"),
  peopleSegmentIds: Yup.array().of(Yup.number()).min(1, "components/products/priceTemplates/form:validations.noPeopleSegment"),
});

const PriceTemplateForm = ({ errors, title, initialValues, backCallback, handleSubmit, showLoadingSpinner }) => {
  const { t, ready: translationsReady } = useTranslation(["components/products/priceTemplates/form", "pages/products/create"]);

  const getInitialValues = () => ({
    name: initialValues.name,
    peopleSegmentIds: initialValues.people_segments.map(p => p.id),
  });

  const getPeopleSegments = () => initialValues.people_segments;

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <Formik
          initialValues={initialValues ? getInitialValues() : PriceTemplateInitialValues}
          validationSchema={PriceTemplateSchema}
          onSubmit={handleSubmit}
        >
          <Form>            
            <BasicFormTemplate
              className="mt-8"
              formTitle={title}
              showLoadingSpinner={showLoadingSpinner}
              footerType={FooterType.BACK_SUBMIT_BUTTONS}
              backCallback={backCallback}
            >
              {errors &&
                <ErrorDisplayBox
                  boxDescription={t("errorBoxDescription")}
                  errors={errors}
                />
              }
              <div className="my-6 px-4">
                <FormikTextInput
                  color="light-blue"
                  label={t("labels.name")}
                  name="name"
                />
              </div>
              <div className="my-6 px-4">
                <PeopleSegmentSearch
                  label={t("labels.peopleSegmentSearch")}
                  placeholder={t("labels.peopleSegmentSearchPlaceholder")}
                  defaultPeopleSegment={initialValues ? getPeopleSegments() : null}
                  name="peopleSegmentIds"
                  isMulti={true}
                  loadOptionsErrorMsg={t("messages.errorRetrievingPeopleSegments", { ns: "pages/products/create" })}
                />
              </div>
            </BasicFormTemplate>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default PriceTemplateForm;