import CreateCredit from "@src/components/credits/CreateCredit";
import EditCredit from "@src/components/credits/EditCredit";
import ListCreditsTable from "@src/components/credits/ListCreditsTable";
import PaginateData from "@src/components/ui/PaginateData";
import Spinner from "@src/components/ui/Spinner";
import { dateToStringDDMMYYYYHHMMSS } from "@src/helpers/dateHelpers";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { CommonRoutesStrings } from "@src/helpers/stringHelpers";
import CreditService from "@src/services/creditService";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { getCurrentUserData } from "@src/helpers/localStorageHelpers";
import HistoryBackButton from "@src/components/ui/buttons/HistoryBackButton";
import BooleanResponseDialog from "@src/components/ui/BooleanResponseDialog";
import { currentUserHasWritePermissions, isProfessional } from "@src/helpers/userHelpers";
import Button from "@src/components/ui/buttons/Button";
import FilterCreditsForm from "@src/components/credits/FilterCreditsForm";
import { isServicesProvider } from "@src/helpers/userHelpers";


const ListCreditsPage = () => {
  const [credits, setCredits] = useState();
  const [error, setError] = useState(false);
  const [creditToDenied, setCreditToDenied] = useState();
  const [creditToApprove, setCreditToApprove] = useState();
  const [showCreditCreationModal, setShowCreditCreationModal] = useState(false);
  const [showCreditEditionModal, setShowCreditEditionModal] = useState(false);
  const [showFilterForm, setShowFilterForm] = useState(true);
  const [requestedFilter, setResquestedFilter] = useState("")
  const [autorizedFilter, setAutorizedFilter] = useState("")
  const [stateFilter, setStateFilter] = useState("")
  const [selectedCredit, setSelectedCredit] = useState();
  const [creditToDelete, setCreditToDelete] = useState();
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation(["pages/credits/list", "common/common", "common/list", "common/filterForm"]);
  const CREDIT_PER_PAGE = 5;

  const fetchCredits = async () => {

    try {
      if (isProfessional()) {
        let userId = getCurrentUserData().id;
        setCredits(await CreditService.findByUser(userId));
      } else {
        setCredits(await CreditService.findAll());
      }


    } catch (_) {
      createNotification(NotificationTypes.error, t("messages.genericRequestError"));
      setTimeout(() => setError(true), 3000);
    }
  };

  const approveCredit = async () => {
    const creditId = creditToApprove.id;
    setCreditToApprove();
    setW18ApiResponse(b => !b);

    try {
      let validated_at = dateToStringDDMMYYYYHHMMSS(new Date());
      await CreditService.authorize(creditId, validated_at);
      createNotification(NotificationTypes.success, t("messages.approveAction.ok"));
      await fetchCredits();
    } catch (_) {
      createNotification(NotificationTypes.error, t("messages.approveAction.ko"));
    } finally {
      setW18ApiResponse(b => !b);
    }

  };
  const deniedCredit = async () => {
    const creditId = creditToDenied.id;
    setCreditToDenied();
    setW18ApiResponse(b => !b);

    try {
      let validated_at = dateToStringDDMMYYYYHHMMSS(new Date());
      await CreditService.denied(creditId, validated_at);
      createNotification(NotificationTypes.success, t("messages.deniedAction.ok"));
      await fetchCredits();
    } catch (_) {
      createNotification(NotificationTypes.error, t("messages.deniedAction.ko"));
    } finally {
      setW18ApiResponse(b => !b);
    }

  };

  const toggleFiltering = () => {
    setShowFilterForm((show) => !show);
    if (isServicesProvider()) {
      setResquestedFilter(getCurrentUserData().id);
    } else {
      setResquestedFilter("");
    }
    setAutorizedFilter("");
    setStateFilter("");
  };

  function handleFilterInputChange(setStateCallback, newState) {
    setStateCallback(newState);
  }

  useEffect(() => {
    translationsReady && fetchCredits();
    // eslint-disable-next-line
  }, [translationsReady]);

  useEffect(() => {
    const getCreditsIndexFilter = async () => {
      setCredits( await CreditService.indexCreditsFilters(requestedFilter, autorizedFilter, stateFilter));
    }
    getCreditsIndexFilter();
  }, [requestedFilter, autorizedFilter, stateFilter]);


  const finishCreditCreation = () => {
    setShowCreditCreationModal(false);
    fetchCredits();
  };

  const enableCreditEdition = credit => {
    setSelectedCredit(credit);
    setShowCreditEditionModal(true);
  };


  const finishCreditModification = () => {
    setShowCreditEditionModal(false);
    setSelectedCredit();
    fetchCredits();
  };

  const deleteCredit = async () => {
    const creditId = creditToDelete.id;
    setCreditToDelete();
    setW18ApiResponse(b => !b);

    try {
      await CreditService.destroy(creditId);
      createNotification(NotificationTypes.success, t("messages.deleteAction.ok"));
      setCredits(credits.filter(n => n.id !== creditId));
    } catch (_) {
      createNotification(NotificationTypes.error, t("messages.deleteAction.ko"));
    } finally {
      setW18ApiResponse(b => !b);
    }

  };

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <>
          {error && <Redirect to={CommonRoutesStrings.homePath} />}
          <div className="flex justify-center items-center mt-2 space-x-2">
            {isProfessional() &&
              <>
                <HistoryBackButton size="md" />
                <Button
                  color="green"
                  onClick={() => setShowCreditCreationModal(true)}
                  disabled={!currentUserHasWritePermissions()}
                >
                  {t("new", { ns: "common/common" })}
                </Button>
              </>}
            {
              <Button className={"relative"} onClick={toggleFiltering} >
                {!showFilterForm
                  ? t("enableFilter", { ns: "common/filterForm" })
                  : t("disableFilter", { ns: "common/filterForm" })}
              </Button>
            }
          </div>
          {!credits ? (
            <Spinner />
          ) : (
            <div className="grid grid-cols-1 place-items-center w-full mx-auto">
              {showFilterForm && (
                <FilterCreditsForm
                  color="yellow"
                  className="mt-11 mb-2 w-[85%]"
                  cbSetRequested={(requested) =>
                    handleFilterInputChange(setResquestedFilter, requested)
                  }
                  cbSetAutorized={(autorized) =>
                    handleFilterInputChange(setAutorizedFilter, autorized)
                  }
                  cbSetState={(state) =>
                    handleFilterInputChange(setStateFilter, state)
                  }
                />
              )}
              <PaginateData
                data={credits}
                emptyDataArrayMsg={t("messages.noCreditsToList")}
                perPage={CREDIT_PER_PAGE}
              >
                <ListCreditsTable
                  title={t("title")}
                  hiddenEdit={isProfessional()}
                  hiddenApprove={isProfessional()}
                  hiddenDenied={isProfessional()}
                  hiddenDelete={!isProfessional()}
                  editCreditCallback={enableCreditEdition}
                  deleteCreditCallback={(credit) => setCreditToDelete(credit)}
                  approveCreditCallback={(credit) => setCreditToApprove(credit)}
                  deniedCreditCallback={(credit) => setCreditToDenied(credit)}
                />
              </PaginateData>
            </div>
          )}

          <BooleanResponseDialog
            open={!!creditToApprove}
            yesCallback={approveCredit}
            noCallback={() => setCreditToApprove()}
          >
            <>{creditToApprove && t("messages.confirmCreditApprove")}</>
          </BooleanResponseDialog>
          <BooleanResponseDialog
            open={!!creditToDenied}
            yesCallback={deniedCredit}
            noCallback={() => setCreditToDenied()}
          ></BooleanResponseDialog>


          <CreateCredit
            open={showCreditCreationModal}
            handler={() => setShowCreditCreationModal((b) => !b)}
            backCallback={() => setShowCreditCreationModal(false)}
            creationEndedCallback={finishCreditCreation}
          />

          <>
            {selectedCredit && (
              <EditCredit
                open={showCreditEditionModal}
                handler={() => setShowCreditEditionModal((b) => !b)}
                credit={selectedCredit}
                backCallback={() => setShowCreditEditionModal(false)}
                modificationEndedCallback={finishCreditModification}
              />
            )}
          </>
          <BooleanResponseDialog
            open={!!creditToDelete}
            yesCallback={deleteCredit}
            noCallback={() => setCreditToDelete()}
          >
            <>{creditToDelete && t("messages.confirmCreditDelete")}</>
          </BooleanResponseDialog>
          {w18ApiResponse && <Spinner />}
        </>
      )}
    </>
  );
};

export default ListCreditsPage;