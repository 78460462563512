import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import BannerForm from "@src/components/banners/BannerForm";
import BannerService from "@src/services/bannerService";
import ErrorDisplayBox from "@src/components/ui/ErrorDisplayBox";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import { DefinedRoutesStrings } from "@src/helpers/stringHelpers";
import { useEffect } from "react";

const EditBannerPage = () => {
  const [banner, setBanner] = useState();
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const [errors, setErrors] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const { t, ready: translationsReady } = useTranslation(["pages/banners/edit","components/imageBank/imageBankForm"]);
  const BannerSchema = Yup.object().shape({
    description: Yup.string()
    .required("components/imageBank/imageBankForm:validationErrors.description.empty")
    .min(5, "components/imageBank/imageBankForm:validationErrors.description.min5characters")
    .max(255, "components/imageBank/imageBankForm:validationErrors.description.max255characters"),
  });
  const history = useHistory();
  const { id: bannerId } = useParams();

  useEffect(() => {
    const fetchBanner = async () => {
      try {
        setBanner(await BannerService.show(bannerId));
      } catch (error) {
        createNotification(NotificationTypes.error, t("errorRetrievingBanner"));
        history.push(DefinedRoutesStrings.listBannersPath);
      }
    };

    translationsReady && fetchBanner();
    // eslint-disable-next-line
  }, [translationsReady]);

  const submitData = async (values, _) => {
    setW18ApiResponse(b => !b);


    try {

      await BannerService.update(banner.id, selectedFile,values.description,values.enabled);
      setErrors();
      createNotification(NotificationTypes.success, t("okSubmissionResponse"));
      history.push(DefinedRoutesStrings.listBannersPath);
    } catch (error) {
      createNotification(NotificationTypes.error, t("koSubmissionResponse"));

      if (error?.response?.data?.error) {
        const unformattedErrors = error.response.data.error;
        const formattedErrors = Object.entries(unformattedErrors).reduce((arr, e) => {
          const [errorKey, errorValues] = e;
          return arr.concat(errorValues.map(err => errorKey + " " + err));
        }, []);

        setErrors(formattedErrors);
      }
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  return (
    <>
      {!(translationsReady && banner) ? <Spinner /> : (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 space-y-2">
          {errors &&
            <ErrorDisplayBox
              boxDescription={t("errorBoxDescription")}
              errors={errors}
            />
          }
          <BannerForm
            title={t("title")}
            initialValues={banner}
            setSelectedFile={setSelectedFile}
            handleSubmit={submitData}
            bannerSchema={BannerSchema}
            backCallback={() => history.goBack()}
            showLoadingSpinnerSubmitButton={w18ApiResponse}
          />
        </div>
      )}
    </>
  );
};

export default EditBannerPage;