import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import Button from "@src/components/ui/buttons/Button";

const AddDeleteSelectAllButtons = ({ addBtnDisabled, deleteBtnDisabled, cbAdd, cbDelete, cbSelectAll }) => {
  const { t, ready: translationsReady } = useTranslation("common/list");
  
  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="flex justify-center items-center my-2 space-x-2">
          <Button
            onClick={cbAdd}
            disabled={addBtnDisabled}
          >{t("add")}</Button>
          <Button
            color="red"
            onClick={cbDelete}
            disabled={deleteBtnDisabled}
          >{t("delete")}</Button>
          <Button
            color="green"
            onClick={cbSelectAll}
          >{t("selectDeselectAll")}</Button>
        </div>
      )}
    </>
  );
};

export default AddDeleteSelectAllButtons;