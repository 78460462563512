import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import store from './app/store'
import { Provider } from 'react-redux';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import { ThemeProvider } from '@material-tailwind/react'
import reportWebVitals from './reportWebVitals';

// import i18n (needs to be bundled ;)) 
import './i18n';

ReactDOM.render(
  <div className="font-sans">
    <Provider store={store}>
      <ThemeProvider>
        <NotificationContainer />
        <App />
      </ThemeProvider>
    </Provider>
  </div>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
