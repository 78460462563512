import EditImageDialog from "@src/components/image_bank/EditImageDialog";
import ListImageBankTable from "@src/components/image_bank/ListImageBankTable";
import ShowImageDialog from "@src/components/image_bank/ShowImageDialog";
import UploadImageDialog from "@src/components/image_bank/UploadImageDialog";
import BooleanResponseDialog from "@src/components/ui/BooleanResponseDialog";
import NewButton from "@src/components/ui/buttons/NewButton";
import PaginateData from "@src/components/ui/PaginateData";
import Spinner from "@src/components/ui/Spinner";
import { useToggle } from "@src/helpers/hooks/useToggle";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { CommonRoutesStrings } from "@src/helpers/stringHelpers";
import ImageBankService from "@src/services/imageBankService";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "@src/components/ui/buttons/Button";
import FilterImageBankForm from '@src/components/image_bank/FilterImageBankForm';
import ListImageGallery from "@src/components/image_bank/ListImageGallery";

const ListImageBankPage = () => {

  const [showFilterForm, setShowFilterForm] = useState(true);
  const [showGallery, setShowGallery] = useState(false);
  const [descriptionFilter, setDescriptionFilter] = useState("");
  const [productFilter, setProductFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [images, setImages] = useState();
  const [showUploadImageDialog, setShowUploadImageDialog] = useToggle();
  const [imageToShow, setImageToShow] = useState();
  const [imageToEdit, setImageToEdit] = useState();
  const [imageToDelete, setImageToDelete] = useState();
  const [w18ApiResponseDeleteImage, setW18ApiResponseDeleteImage] = useToggle();
  const { t, ready: translationsReady } = useTranslation(["pages/imageBank/list", "common/filterForm", "pages/imageBank/list",]);
  const history = useHistory();
  const IMAGES_PER_PAGE = 5;
  const IMAGES_PER_GALLERY = 15;

  const fetchImages = async () => {
    try {
      setImages(await ImageBankService.index());
    } catch (_) {
      createNotification(NotificationTypes.error, t("messages.genericRequestError"));
      history.replace(CommonRoutesStrings.homePath);
    }
  };

  useEffect(() => {
    translationsReady && fetchImages();
    // eslint-disable-next-line
  }, [translationsReady]);

  useEffect(() => {
    const bankimagesbyfilters = async () => {
      setImages(
        await ImageBankService.indexFilters(
          descriptionFilter, productFilter, categoryFilter
        )
      );
    }
    bankimagesbyfilters();
  }, [descriptionFilter, productFilter, categoryFilter]);

  const toggleFiltering = () => {
    setShowFilterForm((show) => !show);
    setDescriptionFilter("");
    setProductFilter("");
    setCategoryFilter("");
  };

  const toggleGallery = () => {
    setShowGallery((show) => !show);
  }

  function handleFilterInputChange(setStateCallback, newState) {
    setStateCallback(newState);
  }

  const closeEditImageDialog = () => setImageToEdit();

  const closeShowImageDialog = () => setImageToShow();

  const finalizeImageUploadCallback = async () => {
    await fetchImages();
    setShowUploadImageDialog();
  };

  const finalizeImageEditionCallback = async () => {
    await fetchImages();
    setImageToEdit();
  };

  const deleteImage = async () => {
    setW18ApiResponseDeleteImage();

    try {
      await ImageBankService.delete(imageToDelete.id);
      await fetchImages();
      createNotification(NotificationTypes.success, t("messages.deleteImage.ok"));
      setImageToDelete();
    } catch (err) {
      createNotification(NotificationTypes.error, t("messages.deleteImage.ko"));
    } finally {
      setW18ApiResponseDeleteImage();
    }
  };

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <>
          <div className="flex justify-center items-center mt-2 space-x-2">
            <NewButton onClick={setShowUploadImageDialog} />
            {
              <Button onClick={toggleFiltering}>
                {!showFilterForm
                  ? t("enableFilter", { ns: "common/filterForm" })
                  : t("disableFilter", { ns: "common/filterForm" })}
              </Button>
            }
            {<Button onClick={toggleGallery}>
                {!showGallery
                  ? t("enableGallery", { ns: "pages/imageBank/list" })
                  : t("disableGallery", { ns: "pages/imageBank/list" })}
              </Button>
            }
          </div>
          {!images ? (
            <Spinner />
          ) : (
            <div className="grid grid-cols-1 place-items-center max-w-3xl mx-auto">
            {showFilterForm && (
                <FilterImageBankForm
                  color="yellow"
                  className="mt-11 mb-2 w-[90%]"
                  cbSetDescription={(description) =>
                    handleFilterInputChange(setDescriptionFilter, description)
                  }
                  cbSetProduct={(product) =>
                    handleFilterInputChange(setProductFilter, product)
                  }
                  cbSetCategory={(category) => 
                    handleFilterInputChange(setCategoryFilter, category)
                  }
                />
              )}
              {!showGallery && (
              <PaginateData data={images} emptyDataArrayMsg={t("messages.noImagesToList")} perPage={IMAGES_PER_PAGE}>
                <ListImageBankTable
                  title={t("title")}
                  showImageCallback={setImageToShow}
                  editImageCallback={setImageToEdit}
                  deleteImageCallback={setImageToDelete}
                />
              </PaginateData>)}
              {showGallery && (
                <PaginateData data={images} emptyDataArrayMsg={t("messages.noImagesToList")} perPage={IMAGES_PER_GALLERY}>
                  <ListImageGallery  editImageCallback={setImageToEdit} />
                </PaginateData>
              )}
            </div>
          )}
          <ShowImageDialog image={imageToShow} backCallback={closeShowImageDialog} />
          <UploadImageDialog
            open={showUploadImageDialog}
            backCallback={setShowUploadImageDialog}
            finalizeImageUploadCallback={finalizeImageUploadCallback}
          />
          <EditImageDialog
            image={imageToEdit}
            backCallback={closeEditImageDialog}
            finalizeImageEditionCallback={finalizeImageEditionCallback}
          />
          <BooleanResponseDialog
            open={!!imageToDelete}
            yesCallback={deleteImage}
            noCallback={() => setImageToDelete()}
            showLoadingSpinner={w18ApiResponseDeleteImage}
          >
            {t("messages.deleteImage.confirmDialog")}
          </BooleanResponseDialog>
        </>
      )}
    </>
  );
};

export default ListImageBankPage;
