import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { createNotification, delayedRedirect, NotificationTypes } from "@src/helpers/notificationHelpers";
import { isThirdPartyProduct, updateProductTimeslice } from "@src/helpers/productHelpers";
import { DefinedRoutesStrings } from "@src/helpers/stringHelpers";
import ProductTimesliceService from "@src/services/products/productTimesliceService";
import { formatHour } from "@src/helpers/localeHelpers";
import Spinner from "@src/components/ui/Spinner";
import { useTranslation } from "react-i18next";
import { stringDateFormat } from "@src/helpers/dateHelpers";
import FormLanguageSearch from "@src/components/products/product_wizard/FormLanguageSearch";
import BasicFormTemplate, { FooterType } from "@src/components/forms/BasicFormTemplate";
import FormikTextInput from "@src/components/forms/FormikTextInput";

const EditTimesliceInfo = ({ productType, date, timesliceId, backCallback }) => {
  const { id: productId } = useParams();
  const [timeslice, setTimeslice] = useState();
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation([
    "components/products/edit/editTimeslice",
    "components/products/create/timetables",
    "components/products/edit/listPrices",
    "pages/products/create",
  ]);

  const calculateQuotaDistributedBetweenPrices = t =>
    t.prices.reduce(
      (sumQuotas, price) => (price.quota == null ? sumQuotas : sumQuotas + price.quota * price.clientType.quantity),
      0
    );

  useEffect(() => {
    const fetchTimeslice = async () => {
      try {
        const timeslice = await ProductTimesliceService.show(productId, date.id, timesliceId);
        setTimeslice(timeslice);
      } catch (error) {
        createNotification(NotificationTypes.error, t("errorRetrievingTimesliceMessage"));
        backCallback();
      }
    };

    translationsReady && fetchTimeslice();
    // eslint-disable-next-line
  }, [translationsReady]);

  const submitData = async (values, _) => {
    const quota = Number(values.quota),
      duration = Number(values.duration);
    const requestBody = {
      duration,
      quota,
      language: values.language,
      begins_at: values.beginsAt,
    };
    const editProductPath = isThirdPartyProduct(productType)
      ? DefinedRoutesStrings.editThirdPartyProductPath
      : DefinedRoutesStrings.editOwnProductPath;

    setW18ApiResponse(b => !b);

    try {
      await updateProductTimeslice(productId, date.id, timeslice.id, requestBody);
      createNotification(NotificationTypes.success, t("successfulTimesliceUpdate"));
      delayedRedirect(editProductPath.replace(":id", productId));
    } catch (error) {
      createNotification(NotificationTypes.error, t("genericErrorTimesliceUpdate"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  // No validation for language, as there is no way to leave it empty
  const validateForm = values => {
    const errors = {};

    if (!values.duration) {
      errors.duration = "components/products/create/timetables:validations.noDurationSelected";
    } else if (!/^[1-9][0-9]*$/.test(values.duration)) {
      errors.duration = "components/products/create/timetables:validations.durationNotIntegerGreater0";
    }

    if (!values.quota) {
      errors.quota = "components/products/create/timetables:validations.noQuotaSelected";
    } else if (!/^[1-9][0-9]*$/.test(values.quota)) {
      errors.quota = "components/products/create/timetables:validations.quotaNotIntegerGreater0";
    } else if (Number(values.quota) < calculateQuotaDistributedBetweenPrices(timeslice)) {
      errors.quota = "components/products/edit/editTimeslice:validationLessQuotaThanSumQuotaPrices";
    }

    if (!values.beginsAt) {
      errors.beginsAt = "components/products/edit/editTimeslice:validationNoBeginsAtSelected";
    }

    return errors;
  };

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <div className="container max-w-sm mx-auto mt-10">
          {!timeslice ? (
            <Spinner />
          ) : (
            <Formik
              initialValues={{
                quota: timeslice.quota,
                duration: timeslice.duration,
                beginsAt: timeslice.beginsAt,
                language: timeslice.language,
              }}
              validate={validateForm}
              onSubmit={submitData}
            >
              <Form>
                <BasicFormTemplate
                  formTitle={t("title")}
                  showLoadingSpinner={w18ApiResponse}
                  backCallback={backCallback}
                  headerColor="deep-orange"
                  footerType={FooterType.BACK_SUBMIT_BUTTONS}
                >
                  <p>{t("eventInfoDescription")}</p>
                  <ul className="list-inside list-disc">
                    <li>
                      {t("labels.eventDetailsModal.date", {
                        ns: "components/products/edit/listPrices",
                        date: stringDateFormat(date.date),
                        hour: formatHour(timeslice.beginsAt),
                      })}
                    </li>
                    <li>
                      {t("labels.eventDetailsModal.language", {
                        ns: "components/products/edit/listPrices",
                        language: timeslice.language,
                      })}
                    </li>
                  </ul>
                  <div className="my-4">
                    <FormikTextInput
                      label={t("labels.hourSelector.durationLabel", { ns: "components/products/create/timetables" })}
                      name="duration"
                    />
                  </div>
                  <div className="my-4">
                    <FormikTextInput
                      label={t("labels.hourSelector.quotaLabel", { ns: "components/products/create/timetables" })}
                      name="quota"
                      placeholder={t("quotaPlaceholder", { quota: timeslice.quota })}
                    />
                    <p className="text-gray-600">
                      {t("quotaDistributedBetweenPrices", { quota: calculateQuotaDistributedBetweenPrices(timeslice) })}
                    </p>
                  </div>
                  <div className="my-4">
                    <FormikTextInput label={t("beginsAtLabel")} name="beginsAt" type="time" />
                  </div>
                  <FormLanguageSearch
                    label={t("labels.timetableHeaders.language", { ns: "components/products/create/timetables" })}
                    name="language"
                    loadOptionsErrorMsg={t("messages.errorRetrievingLanguages", { ns: "pages/products/create" })}
                    defaultLanguages={timeslice.language}
                    yMargin="2"
                    isMulti={false}
                    insideFormikForm={true}
                  />
                </BasicFormTemplate>
              </Form>
            </Formik>
          )}
        </div>
      )}
    </>
  );
};

export default EditTimesliceInfo;
