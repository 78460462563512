import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@src/components/ui/Button";
import Spinner from "@src/components/ui/Spinner";

const ServiceCategorySelector = ({ selectedProductDate, cbHandleServiceCategorySelection, cbBackNavigation }) => {
  const { t, ready: translationsReady } = useTranslation(["components/products/joker/serviceCategorySelector", "common/navigation", "common/list"]);

  const renderServiceCategoryCard = serviceCategory => {
    return (
      <div key={serviceCategory.id} className="flex border rounded-sm shadow-sm m-2 p-2">
        <p className="mr-auto self-center">{serviceCategory.name}</p>
        <Button
          className="ml-auto bg-green-500 font-bold text-white"
          onClick={() => cbHandleServiceCategorySelection(serviceCategory)}
        >{t("select", { ns: "common/list" })}</Button>
      </div>
    );
  };
  
  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="max-w-md mx-auto space-y-2">
          <p className="text-lg font-bold text-gray-700 text-center">{t("title")}</p>
          <p>{t("description")}</p>
          { selectedProductDate.product.serviceCategories.map(service => renderServiceCategoryCard(service)) }
          <Button
            className="bg-gray-400 font-bold text-white"
            onClick={cbBackNavigation}
          >{t("back", { ns: "common/navigation" })}</Button>
        </div>
      )}
    </>
  );
};

export default ServiceCategorySelector;