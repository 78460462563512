import React from 'react';
import NewsletterSubscriptionBox from '@src/components/newsletters/NewsletterSubscriptionBox';
import { useTranslation } from 'react-i18next';
import Spinner from '@src/components/ui/Spinner';
import FooterLanguageSelector from '@src/components/ui/b2c/FooterLanguageSelector';
import EkomiRating from '@src/components/ui/b2c/ratings/EkomiRating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faPinterest, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import faTripadvisor from "@src/assets/images/components/b2c/footer/tripadvisor_logo.svg";
import ExternalLinkWrapper from '@src/components/ui/ExternalLinkWrapper';
import { B2CRoutesStrings, SocialNetworkLinkStrings } from '@src/helpers/stringHelpers';
import { Link } from 'react-router-dom';

import "@src/assets/styles/b2c/components/ui/footer/globals.css";
import "@src/assets/styles/b2c/components/ui/footer/styleguide.css";

const RRSS_LINK_ICONS = [
  { link: SocialNetworkLinkStrings.twitter, icon: faTwitter, isCustom: false },
  { link: SocialNetworkLinkStrings.youtube, icon: faYoutube, isCustom: false },
  { link: SocialNetworkLinkStrings.tripadvisor, icon: faTripadvisor, isCustom: true },
  { link: SocialNetworkLinkStrings.pinterest, icon: faPinterest, isCustom: false },
  { link: SocialNetworkLinkStrings.linkedin, icon: faLinkedin, isCustom: false },
  { link: SocialNetworkLinkStrings.instagram, icon: faInstagram, isCustom: false },
  { link: SocialNetworkLinkStrings.facebook, icon: faFacebook, isCustom: false },
];

const Footer = () => {
  const { t, ready: translationsReady } = useTranslation("components/b2c/footer");

  return (
    <div className="flex flex-col mx-auto w-full max-w-[1440px] items-center py-16 px-32 bg-gradient-to-b from-[rgb(227,7,70)] to-[rgb(178.5,0,51.12)]">
      {!translationsReady ? <Spinner /> : (
        <>
          <div className="flex flex-col items-start mt-2 w-full ">
            <div className="social-media flex items-center justify-between w-full">
              <div className="flex items-center">
                <div className="leading-7 whitespace-no-wrap montserrat-bold-white-19px">{t("securePayment")}</div>
                <div className="flex items-center rounded-xl h-14 ml-5 py-2 px-6 bg-white">
                  {/* eslint-disable-next-line */}
                  <img
                    className="h-4 object-cover w-12"
                    src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62b5a948b2ead546686826dc/img/image-31@2x.png"
                  />
                  {/* eslint-disable-next-line */}
                  <img
                    className="h-9 ml-1 object-cover w-[84px]"
                    src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62b5a948b2ead546686826dc/img/image-32@2x.png"
                  />
                  {/* eslint-disable-next-line */}
                  <img
                    className="self-start h-10 ml-2 object-cover w-20"
                    src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62b5a948b2ead546686826dc/img/image-33@2x.png"
                  />
                </div>
              </div>

              {/* RRSS GRV */}
              <div className="social-media-icons flex space-x-4">
                {RRSS_LINK_ICONS.map((socialNetwork, index) => (
                  <ExternalLinkWrapper
                    key={index}
                    className="flex bg-white rounded-full h-10 w-10 justify-center items-center"
                    href={socialNetwork.link}
                  >
                    {socialNetwork.isCustom ? (
                      <>
                        {/* eslint-disable-next-line */}
                        <img src={socialNetwork.icon} />
                      </>
                    ) : (
                      <FontAwesomeIcon className="text-[#e30746] text-xl" icon={socialNetwork.icon} />
                    )}
                  </ExternalLinkWrapper>  
                ))}
              </div>
            </div>
            <div className="h-px mt-16 bg-white w-[1164px]"></div>
          </div>
          <div className="flex items-start self-end mt-12 w-full justify-between">
            <div className="flex flex-col items-start w-full">
              <div className="flex flex-col items-start">
                <div className="leading-7 tracking-normal whitespace-no-wrap montserrat-bold-white-19px">{t("legalMentions.title")}</div>
                <div className="leading-7 mt-2 tracking-normal montserrat-medium-white-19px">
                  <Link to={B2CRoutesStrings.staticHelpPaths.termsOfServicePath}>{t("legalMentions.termsAndConditionsOfUse")}</Link>
                  <br />
                  <Link to={B2CRoutesStrings.staticHelpPaths.gdprPath}>{t("legalMentions.confidentialityPolicy")}</Link>
                  <br />
                  <Link to={B2CRoutesStrings.staticHelpPaths.legalNoticePath}>
                    {t("legalMentions.generalSalesConditions")}
                  </Link>
                </div>
              </div>
              <div className="flex flex-col items-start mt-10 ">
                <Link
                  to={B2CRoutesStrings.staticHelpPaths.aboutUsPath}
                  className="leading-7 tracking-normal whitespace-no-wrap montserrat-bold-white-19px"
                >{t("aboutUs.title")}</Link>
                <div className="leading-7 mt-2 tracking-normal montserrat-medium-white-19px">
                  <Link to={B2CRoutesStrings.staticHelpPaths.contactUsPath}>{t("aboutUs.contactUs")}</Link>
                  <br />
                  <ExternalLinkWrapper href={SocialNetworkLinkStrings.googleMaps}>{t("aboutUs.sitemap")}</ExternalLinkWrapper>
                </div>
              </div>
              <div className="flex flex-col items-start mt-10">
                <Link
                  to={B2CRoutesStrings.staticHelpPaths.discoverMoreHerePath}
                  className="leading-7 tracking-normal whitespace-no-wrap montserrat-bold-white-19px"
                >{t("discoverMoreHere.title")}</Link>
                <div className="leading-7 mt-2 tracking-normal montserrat-medium-white-19px">
                  <Link to={B2CRoutesStrings.staticHelpPaths.ticketsPath}>{t("discoverMoreHere.tickets")}</Link>
                  <br />
                  <Link to={B2CRoutesStrings.staticHelpPaths.becomeAPartnerPath}>{t("discoverMoreHere.becomeAPartner")}</Link>
                </div>
              </div>
            </div>
            <div className="flex sm:flex-col lg:flex-row sm:items-center lg:items-start">
              <NewsletterSubscriptionBox />
              <div className="flex flex-col items-start w-40">
                <EkomiRating />
                <FooterLanguageSelector />
              </div>
            </div>
          </div>
          <div className="font-['Montserrat'] text-white font-black text-[19px] leading-7 mt-16 tracking-normal whitespace-no-wrap">{t("copyright", { year: new Date().getFullYear() })}</div>
        </>
      )}
    </div>
  );
}
 
export default Footer;