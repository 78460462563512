import React, { useEffect, useState } from "react";
import CreatePeopleSegmentDialog from "@src/components/products/people_segments/CreatePeopleSegmentDialog";
import EditPeopleSegmentDialog from "@src/components/products/people_segments/EditPeopleSegmentDialog";
import ListPeopleSegmentsTable from "@src/components/products/people_segments/ListPeopleSegmentsTable";
import Button from "@src/components/ui/buttons/Button";
import LinkButton from "@src/components/ui/buttons/LinkButton";
import PaginateData from "@src/components/ui/PaginateData";
import Spinner from "@src/components/ui/Spinner";
import { createNotification, delayedRedirect, NotificationTypes } from "@src/helpers/notificationHelpers";
import { deletePeopleSegment, getPeopleSegments } from "@src/helpers/peopleSegmentHelpers";
import { CommonRoutesStrings, DefinedRoutesStrings } from "@src/helpers/stringHelpers";
import { useTranslation } from "react-i18next";
import HistoryBackButton from "@src/components/ui/buttons/HistoryBackButton";
import BooleanResponseDialog from "@src/components/ui/BooleanResponseDialog";

const ListPeopleSegmentsPage = () => {
  const [peopleSegments, setPeopleSegments] = useState();
  const [showCreatePeopleSegment, setShowCreatePeopleSegment] = useState(false);
  const [peopleSegmentToEdit, setPeopleSegmentToEdit] = useState();
  const [peopleSegmentToDelete, setPeopleSegmentToDelete] = useState();
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation(["pages/products/peopleSegments/list", "common/common"]);
  const PEOPLE_SEGMENT_PER_PAGE = 5;

  const removePeopleSegment = async () => {
    setW18ApiResponse(b => !b);

    try {
      await deletePeopleSegment(peopleSegmentToDelete.id);
      createNotification(NotificationTypes.success, t("messages.deleteAction.success"));
      setPeopleSegments(peopleSegments.filter(p => p.id !== peopleSegmentToDelete.id));
      setPeopleSegmentToDelete();
    } catch (error) {
      createNotification(NotificationTypes.error, t("messages.deleteAction.error"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  const fetchPeopleSegments = async () => {
    try {
      const getPeopleSegmentsResponse = await getPeopleSegments();
      setPeopleSegments(getPeopleSegmentsResponse.data);
    } catch (err) {
      createNotification(NotificationTypes.error, t("messages.genericRequestError"));
      delayedRedirect(CommonRoutesStrings.homePath);
    }
  };

  useEffect(() => {
    translationsReady && fetchPeopleSegments();
    // eslint-disable-next-line
  }, [translationsReady]);

  const finishPeopleSegmentCreation = async () => {
    await fetchPeopleSegments();
    setShowCreatePeopleSegment(false);
  };

  const finishPeopleSegmentModification = async () => {
    await fetchPeopleSegments();
    setPeopleSegmentToEdit();
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="container mx-auto max-w-xl">
          <div className="flex justify-center items-center my-2 space-x-2">
            <HistoryBackButton size="md" />
            <Button
              color="green"
              onClick={() => setShowCreatePeopleSegment(true)}
            >{t("new", { ns: "common/common" })}</Button>
            <LinkButton
              color="blue-gray"
              to={DefinedRoutesStrings.listPriceTemplates}
            >{t("priceTemplatesButton")}</LinkButton>
          </div>
          <div className="grid grid-cols-1 place-items-center">
            {!peopleSegments ? <Spinner /> : (
              <PaginateData
                data={peopleSegments}
                emptyDataArrayMsg={t("messages.noPeopleSegments")}
                perPage={PEOPLE_SEGMENT_PER_PAGE}
              >
                <ListPeopleSegmentsTable
                  editPeopleSegmentCallback={peopleSegment => setPeopleSegmentToEdit(peopleSegment)}
                  deletePeopleSegmentCallback={peopleSegment => setPeopleSegmentToDelete(peopleSegment)}
                  showActionsColumn={true}
                />
              </PaginateData>
            )}
          </div>
          <CreatePeopleSegmentDialog
            open={showCreatePeopleSegment}
            handler={() => setShowCreatePeopleSegment(false)}
            handlePeopleSegmentCreation={finishPeopleSegmentCreation}
          />
          <EditPeopleSegmentDialog
            peopleSegment={peopleSegmentToEdit}
            handler={() => setPeopleSegmentToEdit()}
            handlePeopleSegmentModification={finishPeopleSegmentModification}
          />
          <BooleanResponseDialog
            open={!!peopleSegmentToDelete}
            yesCallback={removePeopleSegment}
            noCallback={() => setPeopleSegmentToDelete()}
            showLoadingSpinner={w18ApiResponse}
          >{t("messages.deleteAction.confirmDialog")}</BooleanResponseDialog>
        </div>
      )}
    </>
  );
};

export default ListPeopleSegmentsPage;