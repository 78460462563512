import React, { useState } from "react";
import { formatPrice } from "@src/helpers/localeHelpers";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import ErrorText from "@src/components/ui/ErrorText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { HexStandardPinkStylingB2C } from "@src/helpers/stringHelpers";

const PricesPicker = ({ timesliceAvailable, prices, selectedPrices, cbSetSelectedPrices }) => {
  const [error, setError] = useState();
  const { t, ready: translationsReady } = useTranslation("components/orders/create/prices");

  const getNumberOfTicketsAvailableForSaleWithoutQuotaAssigned = (priceWhoCalledAction) => {
    let totalAvailable = timesliceAvailable;
    prices.forEach((price, index) => {
      if (price.available != null) totalAvailable -= price.available * price.clientType.quantity;
      else if (price.id !== priceWhoCalledAction.id)
        totalAvailable -= selectedPrices.at(index).quantity * getPriceDataForPrice(price).clientType.quantity;
    });
    return totalAvailable;
  };

  const getPriceDataForPrice = (price) => prices.find((p) => p.id === price.id);

  const getErrorMessageIncrementQuantity = (price) => {
    let errorMsg = null;
    if (price.available != null && price.available === getQuantityForPrice(price)) {
      errorMsg = t("noAvailableForCertainTypeError");
    } else if (
      price.available == null &&
      getNumberOfTicketsAvailableForSaleWithoutQuotaAssigned(price) <
        (getQuantityForPrice(price) + 1) * getPriceDataForPrice(price).clientType.quantity
    ) {
      errorMsg = t("noAvailableForEventError");
    }

    return errorMsg;
  };

  const incrementQuantityOfPrice = (price) => {
    const errorMsg = getErrorMessageIncrementQuantity(price);
    if (errorMsg) {
      setError(errorMsg);
    } else {
      setError(null);
      let newSelectedPrices = selectedPrices.map((p) =>
        p.id !== price.id ? p : { id: price.id, quantity: p.quantity + 1 }
      );
      cbSetSelectedPrices(newSelectedPrices);
    }
  };

  const decrementQuantityOfPrice = (price) => {
    setError(null);

    let newSelectedPrices = selectedPrices.map((p) =>
      p.id !== price.id ? p : { id: price.id, quantity: p.quantity - 1 }
    );
    cbSetSelectedPrices(newSelectedPrices);
  };

  const hasQuantitySelected = (price) => selectedPrices.find((p) => p.id === price.id).quantity > 0;

  const getQuantityForPrice = (price) => selectedPrices.find((p) => p.id === price.id).quantity;

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <>
          {prices.map((price, index) => (
            <div key={price.id} className={`flex items-center justify-between w-full ${index === 0 ? "" : "mt-2"}`}>
              <div className="leading-8 mx-2 whitespace-nowrap montserrat-medium-shark-18px">
                {price.clientType?.name}
              </div>
              <div className="flex items-center ml-6">
                <div className="leading-8 mr-3 text-right whitespace-nowrap montserrat-semi-bold-shark-22px">
                  {formatPrice(price.price)}
                </div>
                <button
                  className={`disabled:opacity-60 flex justify-center items-center h-10 w-10 bg-[${HexStandardPinkStylingB2C}] rounded-full`}
                  onClick={() => decrementQuantityOfPrice(price)}
                  disabled={!hasQuantitySelected(price)}
                >
                  <FontAwesomeIcon icon={faMinus} color="white" />
                </button>
                <div className="leading-8 ml-3 whitespace-nowrap min-w-[16px] montserrat-semi-bold-red-ribbon-23px">
                  {getQuantityForPrice(price)}
                </div>
                <button
                  className={`disabled:opacity-60 ml-3 flex justify-center items-center h-10 w-10 bg-[${HexStandardPinkStylingB2C}] rounded-full`}
                  onClick={() => incrementQuantityOfPrice(price)}
                >
                  <FontAwesomeIcon icon={faPlus} color="white" />
                </button>
              </div>
            </div>
          ))}
          {error && <ErrorText className="place-self-center mt-2">{error}</ErrorText>}
        </>
      )}
    </>
  );
};

export default PricesPicker;
