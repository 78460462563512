import Axios from "axios";
import { clearSession, getJWT, stopImpersonationSession } from "@src/helpers/localStorageHelpers";
import { delayedRedirect } from "@src/helpers/notificationHelpers";
import { CommonRoutesStrings } from "@src/helpers/stringHelpers";
import { isLoggedIn, isCurrentSessionForImpersonatingUser } from "@src/helpers/sessionHelpers";
import { getShortCurrentLanguageCode } from "@src/helpers/localeHelpers";
import i18n from "@src/i18n";

const EXPIRATION_MSGS = {
  JWT_REVOKED: "revoked token",
  JWT_EXPIRED: "Signature has expired",
  IMPERSONATING_SESSION_EXPIRED: "Impersonating session expired",
};

const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API ?? 'http://localhost:3000'
});

axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';

axiosInstance.interceptors.request.use(
  // Do something before request is sent
  config => {
    if (i18n.language) config.headers["X-LOCALE"] = getShortCurrentLanguageCode();
    if (isLoggedIn()) config.headers["Authorization"] = `Bearer ${getJWT()}`;

    return config;
  },
  // Do something with request error
  error => error,
);

axiosInstance.interceptors.response.use(
  // Responses with status code 2xx
  response => response,
  // Responses with status code != 2xx
  error => {
    const statusCode = error?.response?.status,
      errorMsg = error?.response?.data?.error;

    if (isLoggedIn() && statusCode === 401 && (errorMsg === EXPIRATION_MSGS.JWT_REVOKED || errorMsg === EXPIRATION_MSGS.JWT_EXPIRED)) {
      const shouldClearImpersonator = isCurrentSessionForImpersonatingUser();

      clearSession(shouldClearImpersonator);
      delayedRedirect(CommonRoutesStrings.loginPathAfterSessionCleared(), 1);
    } else if (isCurrentSessionForImpersonatingUser() && statusCode === 403 && errorMsg === EXPIRATION_MSGS.IMPERSONATING_SESSION_EXPIRED) {
      stopImpersonationSession();
      delayedRedirect(CommonRoutesStrings.homePathAfterImpersonationSessionExpired(), 1);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;