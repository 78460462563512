import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { patchCategory } from "@src/helpers/categoryHelpers";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import ProductCategoryForm from "@src/components/products/categories/ProductCategoryForm";
import Spinner from "@src/components/ui/Spinner";
import { Dialog } from "@material-tailwind/react";

const EditProductCategoryDialog = ({ category, handler: backCallback, handleCategoryModification }) => {
  const [errors, setErrors] = useState();
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation("components/products/categories/edit");

  const submitData = async (values, _) => {
    setW18ApiResponse(b => !b);

    try {
      await patchCategory(category.id, values.name, values.public);
      setErrors(); // clear errorbox if present
      createNotification(NotificationTypes.success, t("okSubmissionResponse"));
      await handleCategoryModification();
    } catch (error) {
      createNotification(NotificationTypes.error, t("koSubmissionResponse"));
      if (error && error.response && error.response.data && error.response.data.error) {
        const unformattedErrors = error.response.data.error;
        const formattedErrors = Object.entries(unformattedErrors).reduce((arr, e) => {
          const [errorKey, errorValues] = e;
          return arr.concat(errorValues.map(err => errorKey + " " + err));
        }, []);

        setErrors(formattedErrors);
      }
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <Dialog
          open={!!category}
          handler={backCallback}
        >
          <ProductCategoryForm
            errors={errors}
            backCallback={backCallback}
            showLoadingSpinner={w18ApiResponse}
            title={t("title")}
            initialValues={category}
            handleSubmit={submitData}
          />
        </Dialog>
      )}
    </>
  );
};

export default EditProductCategoryDialog;