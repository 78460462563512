import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import Button from "@src/components/ui/b2c/buttons/Button";
import Dialog from "@src/components/ui/b2c/dialogs/Dialog";
import { B2CRoutesStrings, CommonRoutesStrings, HexStandardPinkStylingB2C } from "@src/helpers/stringHelpers";
import { useHistory } from "react-router-dom";
import OptionalSubmitButton from "@src/components/ui/b2c/buttons/OptionalSubmitButton";

const DialogOrderAsGuestOrLogin = ({ open, handler }) => {
  const { t, ready: translationsReady } = useTranslation([
    "components/orders/b2c/dialogOrderAsGuestOrLogin",
    "common/form",
  ]);
  const history = useHistory();

  return (
    <Dialog showDialog={open} cbCloseDialog={handler}>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <div className="grid grid-cols-1 space-y-4">
          <div className="flex items-center place-self-center space-x-2">
            <FontAwesomeIcon icon={faShoppingCart} color={HexStandardPinkStylingB2C} size="2x" />
            <p className="text-3xl font-bold">{t("title")}</p>
          </div>
          <p className="text-center whitespace-pre-line self-center">{t("dialogBody")}</p>
          <div className="flex justify-between space-x-4 items-center">
            <Button className="bg-black text-white font-bold" onClick={handler}>
              {t("buttons.cancel", { ns: "common/form" })}
            </Button>
            <OptionalSubmitButton className="h-auto min-h-[40px]" onClick={() => history.push(B2CRoutesStrings.checkoutPath)}>
              {t("buttons.guestOrder")}
            </OptionalSubmitButton>
            <Button
              className="h-auto min-h-[40px] bg-[#e30746] text-white font-bold"
              onClick={() => history.push(CommonRoutesStrings.loginPathAndRedirectToCheckout())}
            >
              {t("buttons.loginAndCheckout")}
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default DialogOrderAsGuestOrLogin;
