import React from "react";
import Spinner from "@src/components/ui/Spinner";
import { Dialog } from "@material-tailwind/react";
import { useToggle } from "@src/helpers/hooks/useToggle";
import { useTranslation } from "react-i18next";
import SupplementService from "@src/services/supplementService";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import SupplementForm from "@src/components/supplements/SupplementForm";

const CreateSupplementDialog = ({ open, handler, handleSupplementCreation }) => {
  const [w18ApiResponse, toggleW18ApiResponse] = useToggle();
  const { t, ready: translationsReady } = useTranslation([
    "components/supplements/createSupplementDialog",
    "common/form",
  ]);

  const handleSubmit = async (values, actions) => {
    toggleW18ApiResponse(b => !b);

    try {
      await SupplementService.create(values);
      createNotification(NotificationTypes.success, t("action.ok"));
      await handleSupplementCreation();
    } catch (error) {
      if (error?.response?.data?.error?.name) {
        actions.setFieldError("name", "common/form:validationErrors.nameTaken");
      }
      createNotification(NotificationTypes.error, t("action.ko"));
    } finally {
      toggleW18ApiResponse();
    }
  };

  return (
    <Dialog open={open}>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <SupplementForm
          title={t("title")}
          showLoadingSpinner={w18ApiResponse}
          handleSubmit={handleSubmit}
          backCallback={handler}
        />
      )}
    </Dialog>
  );
};

export default CreateSupplementDialog;
