import { Switch } from '@material-tailwind/react';
import AcceptOrderRefundDialog from '@src/components/orders/order_refunds/AcceptOrderRefundDialog';
import DenyOrderRefundDialog from '@src/components/orders/order_refunds/DenyOrderRefundDialog';
import ListOrderRefundsTable from '@src/components/orders/order_refunds/ListOrderRefundsTable';
import ShowRefundCommentsDialog from '@src/components/orders/order_refunds/ShowRefundCommentsDialog';
import HistoryBackButton from '@src/components/ui/buttons/HistoryBackButton';
import PaginateData from '@src/components/ui/PaginateData';
import Spinner from '@src/components/ui/Spinner';
import { createNotification, NotificationTypes } from '@src/helpers/notificationHelpers';
import { CommonRoutesStrings, OrderStatusStrings } from '@src/helpers/stringHelpers';
import OrderRefundService from '@src/services/orders/OrderRefundService';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const ListOrderRefundsPage = () => {
  const [orderRefunds, setOrderRefunds] = useState();
  const [refundToShowComments, setRefundToShowComments] = useState();
  const [refundToAccept, setRefundToAccept] = useState();
  const [refundToDeny, setRefundToDeny] = useState();
  const [showOnlyPendingRefunds, setShowOnlyPendingRefunds] = useState(false);
  const { t, ready: translationsReady } = useTranslation("pages/orders/listOrderRefunds");
  const history = useHistory();
  const ORDER_REFUNDS_PER_PAGE = 5;

  const fetchRefunds = async () => {
    try {
      const orderRefunds = await OrderRefundService.index();
      setOrderRefunds(orderRefunds);
    } catch (err) {
      createNotification(NotificationTypes.error, t("messages.genericRequestError"));
      history.replace(CommonRoutesStrings.homePath);
    }
  };

  useEffect(() => {
    translationsReady && fetchRefunds();
    // eslint-disable-next-line
  }, [translationsReady]);

  const handleAcceptDialogClosed = async (shouldReloadRefunds) => {
    if (shouldReloadRefunds) {
      await fetchRefunds();
    }
    
    setRefundToAccept();
  };

  const handleDenyDialogClosed = async (shouldReloadRefunds) => {
    if (shouldReloadRefunds) {
      await fetchRefunds();
    }
    
    setRefundToDeny();
  };

  const getOrderRefunds = () => !showOnlyPendingRefunds ? orderRefunds : orderRefunds.filter(refund => refund.orderStatus.status === OrderStatusStrings.pendingRefund);

  return !(translationsReady) ? <Spinner /> : (
    <>
      <div className="flex justify-center items-center mt-2 space-x-2">
        <HistoryBackButton size="md" />
      </div>
      <div className="flex justify-center items-center mt-2 space-x-2">
        <Switch
          onChange={() => setShowOnlyPendingRefunds(b => !b)}
          checked={showOnlyPendingRefunds}
          color="yellow"
          label={t("showOnlyPendingOrderRefundsToggle")}
        />
      </div>
      {!orderRefunds ? <Spinner /> : (
        <div className="grid grid-cols-1 place-items-center mx-auto">
          <PaginateData
            data={getOrderRefunds()}
            emptyDataArrayMsg={t("messages.noRefundsToList")}
            perPage={ORDER_REFUNDS_PER_PAGE}
          >
            <ListOrderRefundsTable
              cbShowRefundComments={refund => setRefundToShowComments(refund)}
              cbAcceptRefund={refund => setRefundToAccept(refund)}
              cbDenyRefund={refund => setRefundToDeny(refund)}
            />
          </PaginateData>
        </div>
      )}
      <ShowRefundCommentsDialog
        open={!!refundToShowComments}
        handler={() => setRefundToShowComments()}
        orderRefund={refundToShowComments}
      />
      <AcceptOrderRefundDialog
        open={!!refundToAccept}
        handler={handleAcceptDialogClosed}
        refundToAccept={refundToAccept}
      />
      <DenyOrderRefundDialog
        open={!!refundToDeny}
        handler={handleDenyDialogClosed}
        refundToDeny={refundToDeny}
      />
    </>
  );
}
 
export default ListOrderRefundsPage;