import { faCheckCircle, faCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PencilButton from "@src/components/ui/buttons/PencilButton";
import TrashButton from "@src/components/ui/buttons/TrashButton";
import TableCard from "@src/components/ui/TableCard";
import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import { Switch } from "@material-tailwind/react";

const ListProductCategoriesTable = ({ currentPageData : categories, toggleCategoryHighlightCallback, editCategoryCallback, deleteCategoryCallback }) => {
  const { t, ready: translationsReady } = useTranslation(["components/products/categories/listTable", "pages/products/categories/list", "common/list"]);
  
  const getHeadersArray = () => Object.values(t("headers")).concat(t("actionsTableHeader", { ns: "common/list" }));

  const formatDataMatrix = () => categories.map(category => [
    category.id,
    category.name,
    category.public ? <FontAwesomeIcon icon={faCheckCircle} color="green" /> : <FontAwesomeIcon icon={faCircle} color="red" />,
    /*
      Para usar el componente Switch de "@material-tailwind/react", si vamos a renderizar varios,
      es necesario proveerle un ID único a cada uno, ya que sino los eventos que se lancen solo
      irá al primero de ellos, al tener todos por defecto el id "switch".
    */
    <Switch
      id={`highlight-category-switch-${category.id}`}
      onChange={() => toggleCategoryHighlightCallback(category)}
      checked={category.highlighted}
      color="green"
    />,
    <>
      <PencilButton onClick={() => editCategoryCallback(category)} />
      <TrashButton onClick={() => deleteCategoryCallback(category)} />
    </>
  ]);   

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
          title={t("title", { ns: "pages/products/categories/list" })}
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

export default ListProductCategoriesTable;