import { isCurrentSessionForImpersonatingUser, isLoggedIn } from '@src/helpers/sessionHelpers';
import { B2CRoutesStrings, CommonRoutesStrings, HexStandardPinkStylingB2C, SocialNetworkLinkStrings } from '@src/helpers/stringHelpers';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Spinner from '@src/components/ui/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import Button from '@src/components/ui/b2c/buttons/Button';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import ExternalLinkWrapper from "@src/components/ui/ExternalLinkWrapper";
import ProductCategoryService from '@src/services/productCategoryService';
import NavbarLanguageSelector from '@src/components/ui/b2c/NavbarLanguageSelector';
import "@src/assets/styles/b2c/components/ui/navbar/globals.css";
import "@src/assets/styles/b2c/components/ui/navbar/styleguide.css";
import ImageBankService from '@src/services/imageBankService';


const Navbar = () => {
  const [myLogo, setMyLogo] = useState();
  const [highlightedCategories, setHighlightedCategories] = useState();
  const { t, ready: translationsReady } = useTranslation("components/b2c/navbar");

    useEffect(() => {
      const fetchHighlightedCategories = async () => {
        try {
          setHighlightedCategories(await ProductCategoryService.indexHighlightedOnes());
        } catch (err) { console.log(err) }
      };

      const fetchMyLogo = async () => {
        try {
          setMyLogo(await ImageBankService.indexImageWithDescription("Logolanding"));
        } catch (err) {
          console.log(err);
        }
      }

      fetchHighlightedCategories();
      fetchMyLogo();
    },
    []);

  return (
    <article className="flex flex-row items-center justify-between h-[136px] px-16 bg-white shadow-[0px_4px_49px_#00000040] w-full max-w-[1440px] mx-auto font-['Montserrat']">
      {!translationsReady ? <Spinner /> : (
        <>
          {!isLoggedIn() ? (
            <Link to={CommonRoutesStrings.homePath}>
              {/* eslint-disable-next-line */}
              {!myLogo ? <Spinner /> : (
              <img
                className="h-[87px] w-[217px]"
                src={myLogo[0].url}
                alt="logo"
              />
          )}
            </Link>
          ):(
            <>
            <Link to={CommonRoutesStrings.homePath}>
            {!myLogo ? <Spinner /> : ( 
              <img
                className="h-[87px] w-[217px]"
                src={myLogo[0].url}
                alt="logo"
              />
              )}
            </Link>
            </>
          )}
          {highlightedCategories &&
            <div className="sm:hidden lg:flex justify-between h-8 space-x-2 w-auto   sm:text-sm md:text-base lg:text-lg xl:text-xl montserrat-medium-shark">
              {highlightedCategories.map(category => {
                return(
                  <Link
                    key={category.id}
                    className={`border-2 border-white hover:border-red-200 p-1 self-center rounded-lg`}
                    to={`${B2CRoutesStrings.listProductsSearchPath}?categoryId=${category.id}`}
                  >
                      {category.name}
                  </Link>
                );
              })}
            </div>
          }
          <div className="flex items-start ml-5">
            <NavbarLanguageSelector />
            <ExternalLinkWrapper
              className="mr-[14px] h-10 w-10 rounded-full bg-green-600 flex justify-center items-center"
              href={SocialNetworkLinkStrings.whatsapp}
              title={t("buttonTitles.WhatsApp")}
            >
              <FontAwesomeIcon icon={faWhatsapp} color="white" size="2x" />
            </ExternalLinkWrapper>
            <Link
              className={`flex items-end h-10 w-10 py-3 px-[14px] rounded-[28px] bg-[${HexStandardPinkStylingB2C}]`}
              to={B2CRoutesStrings.staticHelpPaths.faqPath}
              title={t("buttonTitles.help")}
            >
              {/* eslint-disable-next-line */}
              <img
                className="h-4 w-3"
                src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62b583778c6dddba224e697b/img/-@2x.svg"
              />
            </Link>
            {!isLoggedIn() ? (
              <Link to={B2CRoutesStrings.loginPath} className="ml-[14px]">
                <Button
                  className="bg-gradient-to-b from-yellow-300 to-yellow-500 font-bold text-gray-900"
                >{t("login")}</Button>
              </Link>
            ) : (
              <>
                <Link
                  className={`flex items-end justify-end h-10 w-10 rounded-[28px] ml-3 p-3 bg-[${HexStandardPinkStylingB2C}]`}
                  to={CommonRoutesStrings.listOrdersPath}
                >
                  <FontAwesomeIcon className="h-[19px] w-[18px]" icon={faFileInvoice} color="white" />
                </Link>
                {!isCurrentSessionForImpersonatingUser() &&
                  <Link to={CommonRoutesStrings.logoutPath} className="ml-[14px]">
                    <Button
                      className="bg-gradient-to-b from-blue-gray-300 to-blue-gray-500 font-bold text-white"
                    >{t("logout")}</Button>
                  </Link>
                }
              </>
            )}
          </div>
        </>
      )}
    </article>
  );
}
 
export default Navbar;