import React from 'react';
import { useTranslation } from 'react-i18next';
import EsFlag from "@src/assets/images/country_flags/es-nav.svg";
import GbFlag from "@src/assets/images/country_flags/gb-nav.svg";

const NavbarLanguageSelector = () => {
  const { i18n } = useTranslation();

  const supportedI18nLanguages = [
    {
      code: "es-ES",
      flag: EsFlag,
      inverse_code: "en-GB"
    },
    {
      code: "en-GB",
      flag: GbFlag,
      inverse_code: "es-ES"
    },
  ];

  const handleLanguageChange = langCode => {
    i18n.changeLanguage(langCode);
  };

  return (
    <div className="flex justify-around mr-2">
      {supportedI18nLanguages.map(language => {
        const isCurrentLanguageSelected = i18n.language === language.code;

        return (
          <div key={language.code}  onClick={() => handleLanguageChange(language.inverse_code)}>
            
            {isCurrentLanguageSelected &&
              <>
                {/* eslint-disable-next-line */}
                <img className="mr-[14px] h-10 w-10 rounded-full flex justify-center items-center cursor-pointer" src={language.flag} />
              </>
            }
          </div>
        );
      })}
    </div>
  );
}
 
export default NavbarLanguageSelector;