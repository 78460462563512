import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import IconButton from '@src/components/ui/buttons/IconButton';

const TimesButton = props => {
  return (
    <IconButton
      color="red"
      {...props}
    ><FontAwesomeIcon icon={faTimes} /></IconButton>
  );
}
 
export default TimesButton;