import EyeButton from '@src/components/ui/buttons/EyeButton';
import HistoryBackButton from '@src/components/ui/buttons/HistoryBackButton';
import PencilButton from '@src/components/ui/buttons/PencilButton';
import PaginateData from '@src/components/ui/PaginateData';
import Spinner from '@src/components/ui/Spinner';
import TableCard from '@src/components/ui/TableCard';
import { createNotification, NotificationTypes } from '@src/helpers/notificationHelpers';
import ProductService from '@src/services/products/productService';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import EditProductTranslationDialog from '@src/components/products/edit_product_data/edit_product_translations/EditProductTranslationDialog';
import ShowProductTranslationDialog from '@src/components/products/edit_product_data/edit_product_translations/ShowProductTranslationDialog';
import CreateProductTranslationDialog from '@src/components/products/edit_product_data/edit_product_translations/CreateProductTranslationDialog';
import Button from '@src/components/ui/buttons/Button';
import CountryFlag from '@src/components/ui/CountryFlag';

const ListProductTranslationsTable = ({ currentPageData : productTranslations, showProductTranslationCallback, editProductTranslationCallback }) => {
  const { t, ready: translationsReady } = useTranslation(["components/products/edit/editProductTranslations", "common/list", "common/common"]);

  const getHeadersArray = () => [t("languageTableHeader"), t("actionsTableHeader", { ns: "common/list" })];

  const formatDataMatrix = () => productTranslations.map(productTranslation => [
    <div className="flex">
      <CountryFlag
        className="w-[25px]"
        languageCode={productTranslation.language}
      />
      <p className="ml-2">{productTranslation.language}</p>
    </div>,
    <>
      <EyeButton onClick={() => showProductTranslationCallback(productTranslation)} />
      {editProductTranslationCallback &&
        <PencilButton onClick={() => editProductTranslationCallback(productTranslation)} />
      }
    </>
  ]);

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
          title={t("titles.main")}
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

const EditProductTranslations = ({ productType, backCallback, readOnly }) => {
  const [productTranslations, setProductTranslations] = useState();
  const [showCreateTranslationDialog, setShowCreateTranslationDialog] = useState(false);
  const [productTranslationToShow, setProductTranslationToShow] = useState();
  const [productTranslationToEdit, setProductTranslationToEdit] = useState();
  const { t, ready: translationsReady } = useTranslation("components/products/edit/editProductTranslations");
  const { id: productId } = useParams();
  const PER_PAGE = 5;

  const fetchTranslations = async () => {
    try {
      setProductTranslations(await ProductService.indexProductTranslations(productId));
    } catch (err) {
      createNotification(NotificationTypes.error, t("messages.errorRetrievingTranslations"));
      backCallback();
    }
  };

  useEffect(() => {
    translationsReady && fetchTranslations();
    // eslint-disable-next-line
  }, [translationsReady]);

  const getLanguagesAlreadyInUse = () =>
    productTranslations.map(translation => translation.language);

  const finalizeTranslationCreation = async () => {
    await fetchTranslations();
    setShowCreateTranslationDialog(false);
  };

  const finalizeTranslationEdition = async () => {
    await fetchTranslations();
    setProductTranslationToEdit();
  };

  return !translationsReady ? <Spinner /> : (
    <div className="grid grid-cols-1 place-items-center mx-auto max-w-screen-sm">
      <div className="space-x-2 my-2">
        <HistoryBackButton onClick={backCallback} size="md" />
        {!readOnly &&
          <Button
            color="green"
            onClick={() => setShowCreateTranslationDialog(true)}
          >{t("new", { ns: "common/common" })}</Button>
        }
      </div>
      {!productTranslations ? <Spinner /> : (
        <>
          <PaginateData
            data={productTranslations}
            perPage={PER_PAGE}
          >
            <ListProductTranslationsTable
              showProductTranslationCallback={setProductTranslationToShow}
              editProductTranslationCallback={!readOnly ? setProductTranslationToEdit : undefined}
            />
          </PaginateData>
          <ShowProductTranslationDialog
            open={!!productTranslationToShow}
            closeDialogCallback={() => setProductTranslationToShow()}
            productTranslation={productTranslationToShow}
          />
          <CreateProductTranslationDialog
            open={showCreateTranslationDialog}
            closeDialogCallback={() => setShowCreateTranslationDialog(false)}
            translationCreatedCallback={finalizeTranslationCreation}
            productType={productType}
            productTranslation={productTranslationToEdit}
            languagesAlreadyInUse={getLanguagesAlreadyInUse()}
          />
          <EditProductTranslationDialog
            open={!!productTranslationToEdit}
            closeDialogCallback={() => setProductTranslationToEdit()}
            translationEditedCallback={finalizeTranslationEdition}
            productType={productType}
            productTranslation={productTranslationToEdit}
          />
        </>
      )}
    </div>
  );
};
 
export default EditProductTranslations;