import React from "react";
import {useTranslation} from "react-i18next";
import {dateToStringDDMMYYYYHHMMSS} from "@src/helpers/dateHelpers";
import Spinner from "@src/components/ui/Spinner";
import TableCard from "@src/components/ui/TableCard";
import { currentUserHasWritePermissions } from "@src/helpers/userHelpers";
import TrashButton from "@src/components/ui/buttons/TrashButton";
import PencilButton from "@src/components/ui/buttons/PencilButton";
import TickButton from "@src/components/ui/buttons/TickButton";
import BanButton from "@src/components/ui/buttons/BanButton";
import { formatPrice } from '@src/helpers/localeHelpers';


const ListCreditsTable = ({
                            hiddenEdit,
                            hiddenApprove,
                            hiddenDenied,
                            hiddenDelete,
                            title,
                            currentPageData: creditList,
                            editCreditCallback,
                            deleteCreditCallback,
                            approveCreditCallback,
                            deniedCreditCallback,
                          }) => {
  const {
    t,
    ready: translationsReady
  } = useTranslation(["components/credits/listTable", "common/common", "common/list"]);

  const getHeadersArray = () => Object.values(t("headers")).concat(t("actionsTableHeader", {ns: "common/list"}));

  const formatDataMatrix = () => creditList.map(n => [
    n.id,
    n.createdAt ? dateToStringDDMMYYYYHHMMSS(n.createdAt) : t("notAvailable", {ns: "common/common"}),
    n.deniedAt ? dateToStringDDMMYYYYHHMMSS(n.deniedAt) : t("notAvailable", {ns: "common/common"}),
    n.validatedAt ? dateToStringDDMMYYYYHHMMSS(n.validatedAt) : t("notAvailable", {ns: "common/common"}),
    n.requestBy,
    n.authorizedBy ? n.authorizedBy : t("notAvailable", {ns: "common/common"}),
    formatPrice(n.credit),
    <>
      {(!n.validatedAt && !n.deniedAt) && !hiddenApprove? (
        <TickButton
          className="mr-2"
          onClick={() => approveCreditCallback(n)}
        />
         
      ) : (
        <></>
      )}
      {(!n.validatedAt && !n.deniedAt) && !hiddenDenied  ? (
        <BanButton
          className="mr-2"
          onClick={() => deniedCreditCallback(n)}
        />
      ) : (
        <></>
      )}
      {(!n.validatedAt && !n.deniedAt) && !hiddenEdit  ? (
        <PencilButton
          className="mr-2"
          onClick={() => editCreditCallback(n)}
        />
      ) : (
        <></>
      )}
      {(!n.validatedAt && !n.deniedAt) && !hiddenDelete ? (
        <TrashButton 
         onClick={() => deleteCreditCallback(n)}
        disabled={!currentUserHasWritePermissions()}
        />
      ) : (
        <></>
      )}

    </>
  ]);

  return (
    <>
      {!translationsReady ? <Spinner/> : (
        <TableCard
          title={title}
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

export default ListCreditsTable;