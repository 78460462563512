import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import OrderDisplay from '@src/components/orders/b2c/list/OrderDisplay';
import Button from '@src/components/ui/b2c/buttons/Button';
import SubmitButton from '@src/components/ui/b2c/buttons/SubmitButton';
import Spinner from '@src/components/ui/Spinner';
import { createNotification, NotificationTypes } from '@src/helpers/notificationHelpers';
import { CommonRoutesStrings, OrderStatusStrings } from '@src/helpers/stringHelpers';
import OrderRefundService from '@src/services/orders/OrderRefundService';
import OrderService from '@src/services/orders/orderService';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from "yup";

const FormSchema = Yup.object().shape({
  comment: Yup.string().optional(),
});

const AskForOrderRefundPage = () => {
  const [order, setOrder] = useState();
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { id: orderId } = useParams();
  const { t, ready: translationsReady } = useTranslation(["pages/b2c/orders/askForOrderRefund", "pages/orders/show", "common/form"]);
  const history = useHistory();

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const order = await OrderService.show(orderId);
        if (order.status !== OrderStatusStrings.cancelled) {
          createNotification(NotificationTypes.error, t("orderNeedsCancelledStatus"));
          history.replace(CommonRoutesStrings.homePath);
        } else {
          setOrder(order);
        }
      } catch (_) {
        createNotification(NotificationTypes.error, t("genericRequestError", { ns: "pages/orders/show" }));
        history.replace(CommonRoutesStrings.homePath);
      }
    };

    translationsReady && fetchOrder();
    // eslint-disable-next-line
  }, [translationsReady]);

  const handleSubmit = async ({ comment }) => {
    const refundDescription = comment !== "" ? comment : null;
    setW18ApiResponse(b => !b);

    try {
      await OrderRefundService.create(order.id, refundDescription);
      createNotification(NotificationTypes.success, t("action.ok"));
      history.replace(CommonRoutesStrings.listOrdersPath);
    } catch (err) {
      createNotification(NotificationTypes.error, t("action.ko"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };
  
  return (
    <div className="bg-white py-[67px] px-[135.9px] grid grid-cols-1 montserrat-medium-shark-20px">
      {!(order && translationsReady) ? <Spinner /> : (
        <>
          <p className="font-['Montserrat'] self-start font-extrabold text-5xl leading-8 tracking-normal whitespace-no-wrap text-[#e30746]">{t("title")}</p>
          <p className="mt-10 mb-2">{t("description")}</p>
          <OrderDisplay
            order={order}
            printing={true}
          />
          <p className="mt-10 mb-4">{t("orderRefundCommentDescription")}</p>
          <Formik
            initialValues={{ comment: "" }}
            validationSchema={FormSchema}
            onSubmit={handleSubmit}
          >
            <Form className="grid grid-cols-1 space-y-4">
              <Field
                as="textarea"
                name="comment"
                rows="4"
                className="bg-gray-100 px-4 py-2 border-2 border-gray-400 rounded-xl"
              />
              <div className="flex justify-between">
                <Button
                  className="bg-black text-white font-bold"
                  onClick={() => history.goBack()}
                >{t("buttons.cancel", { ns: "common/form" })}</Button>
                <SubmitButton
                  icon={faHandHoldingUsd}
                  showLoadingSpinner={w18ApiResponse}
                >{t("submitButton")}</SubmitButton>
              </div>
            </Form>
          </Formik>
        </>
      )}
    </div>
  );
}
 
export default AskForOrderRefundPage;