import React from "react";
import { useField } from "formik";
import { useTranslation } from "react-i18next";

/*
  In order to text input error message to be translated
  correctly, we need to pass as error message the key
  for its translation, and TextInput component will
  use i18next to translate this key to selected language
*/
const TextInput = ({label, secondLabel, placeholder, yMargin, ...props}) => {
  const [field, meta] = useField(props);
  const { t } = useTranslation();

  return (
    <div className={`grid grid-cols-1 ${yMargin ? `my-${yMargin}` : "my-6"}`}>
      <label className="text-gray-700" htmlFor={props.id || props.name}>{label}</label>
      {secondLabel ? (
        <label className="text-sm text-gray-500 dark:text-gray-400">{secondLabel}</label>
      ) : null}
      <input className="rounded-md border border-gray-300 shadow-sm disabled:bg-gray-100"
             placeholder={placeholder} {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="text-red-500 font-semibold">{t(meta.error)}</div>
      ) : null}
    </div>
  );
};

export default TextInput;