import {
  isDailyRegularity,
  isMonthlyRegularity,
  isWeeklyRegularity,
  isYearlyRegularity,
} from "@src/components/products/product_wizard/RegularityTypeSelectorDialog";
import axios from "@src/helpers/axiosInstance";
import { stringDateFormat } from "@src/helpers/dateHelpers";
import { getJWT } from "@src/helpers/localStorageHelpers";
import {
  APIEndpointsStrings,
  WeekDaysStrings,
} from "@src/helpers/stringHelpers";

class ServiceService {
  static index = async (requestPath) => {
    const res = await axios.get(
      requestPath ?? APIEndpointsStrings.servicesPath,
      {
        headers: {
          Authorization: `Bearer ${getJWT()}`,
        },
      }
    );

    return res.data.map((service) => this.formatReceivedService(service));
  };

  static indexServicesFilter = (nameFilter, categoryFilter, providerFilter) => {
    let requestPath = undefined;
    if (nameFilter || categoryFilter || providerFilter) {
      requestPath = APIEndpointsStrings.servicesPath + `?`;
    }
    if (nameFilter) {
      requestPath += `q[translations_name_cont]=${nameFilter}&`;
    }
    if (categoryFilter) {
      requestPath += `q[service_category_id_eq]=${categoryFilter}&`;
    }
    if (providerFilter) {
      requestPath += `q[services_provider_id_eq]=${providerFilter}&`;
    }

    return this.index(requestPath);
  };

  static indexServicesProvider = (servicesProviderId) => {
    const requestPath =
      APIEndpointsStrings.servicesPath +
      `?q[services_provider_id_eq]=${servicesProviderId}`;

    return this.index(requestPath);
  };

  static async show(serviceId) {
    const res = await axios.get(
      APIEndpointsStrings.servicePath.replace(
        APIEndpointsStrings.idLocators.id,
        serviceId
      ),
      {
        headers: {
          Authorization: `Bearer ${getJWT()}`,
        },
      }
    );
    return this.formatReceivedService(res.data);
  }

  static async postService(service) {
    const formattedService = this.formatServiceToSend(service);

    return axios.post(
      APIEndpointsStrings.servicesPath,
      {
        service: formattedService,
      },
      {
        headers: {
          Authorization: `Bearer ${getJWT()}`,
        },
      }
    );
  }

  static createWithXlsx = (file) => {
    const formData = new FormData();
    formData.append("services_file", file);

    return axios.post(
      APIEndpointsStrings.createServicesWithXlsxPath,
      formData,
      {
        headers: {
          Authorization: `Bearer ${getJWT()}`,
        },
      }
    );
  };

  // Only updates name, servicesProviderId and serviceCategoryId
  static update = (serviceId, serviceData) => {
    const formattedServiceData = this.formatServiceToSend(serviceData);
    const endpoint = APIEndpointsStrings.servicePath.replace(
      APIEndpointsStrings.idLocators.id,
      serviceId
    );

    return axios.put(
      endpoint,
      {
        service: formattedServiceData,
      },
      {
        headers: {
          Authorization: `Bearer ${getJWT()}`,
        },
      }
    );
  };

  static destroyService = (serviceId) =>
    axios.delete(
      APIEndpointsStrings.servicePath.replace(
        APIEndpointsStrings.idLocators.id,
        serviceId
      ),
      {
        headers: {
          Authorization: `Bearer ${getJWT()}`,
        },
      }
    );

  static async searchForAvailableServices(
    serviceCategoryId,
    date,
    startTime,
    endTime
  ) {
    const endpoint =
      APIEndpointsStrings.searchResourcesByDateCategoryPath +
      `?resource[service_category_id]=${serviceCategoryId}&resource[date]=` +
      `${stringDateFormat(
        date
      )}&resource[time_start]=${startTime}&resource[time_end]=${endTime}`;

    const searchResults = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${getJWT()}`,
      },
    });

    return searchResults.data.map((service) =>
      this.formatReceivedServiceFromJokerSearch(service)
    );
  }

  static formatReceivedService(service) {
    return {
      id: service.id,
      name: service.name,
      professionalEmail: service.professional_email,
      servicesProviderId: service.services_provider_id,
      serviceCategory: service.service_category,
      updatedAt: service.updated_at ? new Date(service.updated_at) : null,
      dates: !service.dates
        ? null
        : service.dates.map((date) => this.formatReceivedServiceDate(date)),
    };
  }

  static formatReceivedServiceDate = (date) => ({
    id: date.id,
    date: date.date,
    productId: date.product_id,
    deletedAt: date.deleted_at ? new Date(date.deleted_at) : null,
    createdAt: date.created_at ? new Date(date.created_at) : null,
    updatedAt: date.updated_at ? new Date(date.updated_at) : null,
    resources: date.resources.map((resource) => ({
      id: resource.id,
      name: resource.name,
      priceHour: resource.price_hour,
      quantity: resource.quantity,
      deletedAt: resource.deleted_at ? new Date(resource.deleted_at) : null,
      resourceUnits: resource.units.map((unit) => ({
        id: unit.id,
        capacity: unit.capacity,
        rangeOfAvailability: {
          startTime: unit.range_of_availability.time_start,
          endTime: unit.range_of_availability.time_end,
        },
        deletedAt: unit.deleted_at ? new Date(unit.deleted_at) : null,
        disabledAt: unit.disabled_at ? new Date(unit.disabled_at) : null,
        taken: !unit.taken
          ? []
          : unit.taken.map((timeTaken) => ({
              id: timeTaken.id,
              productId: timeTaken.product_id,
              dateProductId: timeTaken.date_product_id,
              timeSliceId: timeTaken.time_slice_id,
              startTime: timeTaken.time_start,
              endTime: timeTaken.time_end,
            })),
      })),
    })),
  });

  static formatServiceToSend(service) {
    let formattedData = {
      name: service.name,
      services_provider_id: service.servicesProviderId,
      service_category_id: service.serviceCategoryId,
    };

    if (service.configurations) {
      formattedData.configurations = [];
      service.configurations.forEach((configuration) => {
        let formattedConfiguration = {
          from_date: stringDateFormat(configuration.fromDate),
          to_date: stringDateFormat(configuration.toDate),
          resources: configuration.resources.map((r) => ({
            name: r.name,
            quantity: Number(r.quantity),
            capacity: Number(r.capacity),
            price_hour: r.priceHour,
            availability: {
              time_start: r.startTime,
              time_end: r.endTime,
            },
          })),
        };

        if (
          service.regularity &&
          isWeeklyRegularity(configuration.regularityType)
        ) {
          formattedConfiguration.regularity_type =
            configuration.regularityType.toLowerCase();
          formattedConfiguration.regularity_data =
            configuration.regularityData.map((weekdayIndex) =>
              Object.keys(WeekDaysStrings.full).at(weekdayIndex)
            );
        } else if (
          service.regularity &&
          isYearlyRegularity(configuration.regularityType)
        ) {
          formattedConfiguration.regularity_type =
            configuration.regularityType.toLowerCase();
          formattedConfiguration.regularity_data = configuration.regularityData;
        } else if (
          service.regularity &&
          isMonthlyRegularity(configuration.regularityType)
        ) {
          formattedConfiguration.regularity_type =
            configuration.regularityType.toLowerCase();
          formattedConfiguration.regularity_data =
            configuration.regularityData.map((monthDay) => Number(monthDay));
        } else if (
          service.regularity &&
          isDailyRegularity(configuration.regularityType)
        ) {
          formattedConfiguration.regularity_type =
            configuration.regularityType.toLowerCase();
        } else {
          formattedConfiguration.regularity_data = configuration.dates.map((d) =>
            stringDateFormat(d)
          );
          formattedConfiguration.regularity_type = "none";
        }

        formattedData.configurations.push(formattedConfiguration);
      });
    }

    return formattedData;
  }

  static formatReceivedServiceFromJokerSearch = (service) => ({
    id: service.service_product_id,
    name: service.service_product_name,
    date: service.date,
    serviceDateId: service.date_product_id,
    servicesProviderEmail: service.services_provider_email,
    resources: service.resources.map((resource) => ({
      id: resource.id,
      name: resource.name,
      priceHour: resource.price_hour,
      resourceUnits: resource.units.map((resourceUnit) => ({
        id: resourceUnit.id,
        capacity: resourceUnit.capacity,
        rangeOfAvailability: {
          startTime: resourceUnit.range_of_availability.time_start,
          endTime: resourceUnit.range_of_availability.time_end,
        },
        resourceUnitsTaken: resourceUnit.taken.map((resourceUnitTaken) => ({
          id: resourceUnitTaken.id,
          startTime: resourceUnitTaken.time_start,
          endTime: resourceUnitTaken.time_end,
        })),
      })),
    })),
  });
}

export default ServiceService;
