import FormikTextInput from "@src/components/forms/FormikTextInput";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { emptyArray } from "@src/helpers/arrayHelpers";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { isPositiveFloat } from "@src/helpers/numberHelpers";
import { getPriceTemplates } from "@src/helpers/priceTemplateHelpers";
import SubmitButton from "@src/components/ui/buttons/SubmitButton";
import TextInput from "@src/components/forms/TextInput";
import Spinner from "@src/components/ui/Spinner";
import PriceSeletionDetails from "@src/components/products/product_wizard/PriceSelectionDetails";
import { PricesSelectorInitialValue } from "@src/components/products/product_wizard/PricesSelector";

const PeopleTemplatePriceSelector = ({ prices, cbSetPrices, minQuotaForSelectedDateRanges }) => {
  const [priceTemplates, setPriceTemplates] = useState();
  const [selectedPriceTemplate, setSelectedPriceTemplate] = useState();
  const [quotaLeftToAssign, setQuotaLeftToAssign] = useState(minQuotaForSelectedDateRanges);
  const [clientTypeArray, setClientTypeArray] = useState();
  const [actualClientType, setActualClientType] = useState();
  const { t, ready: translationsReady } = useTranslation([
    "components/products/create/prices",
    "pages/products/create",
    "common/list",
    "common/common"
  ]);

  useEffect(() => {
    const fetchPriceTemplates = async () => {
      try {
        const getPriceTemplatesResponse = await getPriceTemplates();
        setPriceTemplates(getPriceTemplatesResponse.data);
      } catch (err) {
        createNotification(NotificationTypes.error, t("messages.errorRetrievingPriceTemplates", { ns: "pages/products/create" }));
      }
    };

    translationsReady && fetchPriceTemplates();
    // eslint-disable-next-line
  }, [translationsReady]);

  const handlePriceTemplateChange = priceTemplateOption => {
    const priceTemplate = priceTemplates.find(p => p.id === priceTemplateOption.value);
    setSelectedPriceTemplate(priceTemplate);
    setClientTypeArray(priceTemplate.people_segments);
    cbSetPrices([]);
    setQuotaLeftToAssign(minQuotaForSelectedDateRanges);
    setActualClientType(priceTemplate.people_segments.at(0));
  };

  const getOptionsForPriceTemplates = () => priceTemplates.map(priceTemplate => ({
    value: priceTemplate.id,
    label: priceTemplate.name
  }));

  const removePrice = index => {
    const priceToDelete = prices.at(index);
    setQuotaLeftToAssign(quotaLeftToAssign + priceToDelete.quota);
    cbSetPrices(prices.filter((price, i) => i !== index));
    setClientTypeArray(clientTypeArray.concat(priceToDelete.type_client));
    if (emptyArray(clientTypeArray)) {
      setActualClientType(priceToDelete.type_client);
    }
  };

  const validatePriceSelection = values => {
    const errors = {};

    if (!values.type_client) {
      errors.type_client = "components/products/create/prices:validations.noClientType";
    }

    if (!values.price) {
      errors.price = "components/products/create/prices:validations.noPrice";
    } else if (!isPositiveFloat(values.price)) {
      errors.price = "components/products/create/prices:validations.priceNotIntegerGreater0";
    }

    if (values.quota) {
      if (!/^[1-9][0-9]*$/.test(values.quota)) {
        errors.quota = "components/products/create/prices:validations.quotaNotIntegerGreater0";
      } else if (quotaLeftToAssign - Number(values.quota) < 0) {
        errors.quota = "components/products/create/prices:validations.morePricesQuotasThanQuotaForSelectedHours";
      }
    }

    return errors;
  };

  const handleNewPriceSelection = async (values, actions) => {
    cbSetPrices([...prices, {
      type_client: actualClientType, 
      price: Number(values.price),
      quota: values.quota ? Number(values.quota) : null,
    }]);

    setQuotaLeftToAssign(quotaLeftToAssign - Number(values.quota));
    setClientTypeArray(clientTypeArray.slice(1))
    setActualClientType(clientTypeArray.at(1));

    if (clientTypeArray.length > 1) {
      actions.resetForm({ 
        values: {
          ...PricesSelectorInitialValue,
          type_client: clientTypeArray.at(1) == null ? "" : clientTypeArray.at(1).name,
        }
      });
    }    
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div>
          <Select
            className="my-2"
            placeholder={t("labels.priceSelector.priceTemplateSelectorPlaceholder")}
            options={priceTemplates ? getOptionsForPriceTemplates() : null}
            isDisabled={!priceTemplates}
            onChange={handlePriceTemplateChange}
          />
          {selectedPriceTemplate && clientTypeArray &&
            <>
            {!emptyArray(prices) && 
              <PriceSeletionDetails
                prices={prices}
                removeCallback={removePrice}
              />
            }
            {!emptyArray(clientTypeArray) &&
              <Formik
                initialValues={{
                  ...PricesSelectorInitialValue,
                  type_client: clientTypeArray.at(0) == null ? "" : clientTypeArray.at(0).name,
                }}
                validate={validatePriceSelection}
                onSubmit={handleNewPriceSelection}
              >
                {({ values }) => (
                  <Form>
                    <div className="grid grid-cols-1 mx-10">
                      <TextInput
                        label={t("labels.priceTableHeaders.clientType")}
                        name="type_client"
                        yMargin="2"
                        disabled={true}
                      />
                      <div className="my-4">
                        <FormikTextInput
                          label={t("labels.priceSelector.priceLabel")}
                          name="price"
                        />
                      </div>
                      <div className="my-4">
                        <FormikTextInput
                          label={t("labels.priceTableHeaders.quota")}
                          name="quota"
                        />
                        <p className="text-gray-600">{t("labels.priceSelector.quotaLeftDescription", { quota: quotaLeftToAssign })}</p>
                      </div>
                      <SubmitButton
                        color="green"
                        className="place-self-center"
                        disabled={!values.price}
                      >{t("add", { ns: "common/list" })}</SubmitButton>
                    </div>
                  </Form>
                )}
              </Formik>
            }
          </>
          }
        </div>
      )}
    </>
  );
};

export default PeopleTemplatePriceSelector;