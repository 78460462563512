import Spinner from '@src/components/ui/Spinner';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ResourcesSelectedDetails from "@src/components/products/services/create/ResourcesSelectedDetails";
import { emptyArray } from '@src/helpers/arrayHelpers';
import { CardHeader, Typography } from "@material-tailwind/react";
import { stringDateFormat } from '@src/helpers/dateHelpers';
import BackNextSubmitButtons from '@src/components/products/product_wizard/BackNextSubmitButtons';

const AddDateSummary = ({ currentStep, numberSteps, date, callbackBackClicked, callbackNextClicked, submissionErrors, showLoadingSpinner }) => {
  const { t, ready: translationsReady } = useTranslation([
    "components/products/edit/addTimeslice",
    "components/services/create/summary",
    "components/products/edit/addDate",
    "components/products/create/prices",
    "common/common",
    "pages/services/create"
  ]);
  
  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <>
          {!emptyArray(submissionErrors) &&
            <div className="bg-red-300 border border-red-500 rounded p-2">
              <p className="font-bold">{t("labels.summaryErrorBoxDescription", { ns: "components/products/edit/addDate" })}</p>
              {submissionErrors.map((error, index) => <p key={`submissionError-${index}`}>{error}</p>)}
            </div>
          }
          <div className="mt-10 mb-4 flex place-content-center">
              <CardHeader
                color="indigo"
                variant="gradient"
                className="px-8 py-6"
              >
            <Typography>{t("labels.timesliceSummary.date", { date: stringDateFormat(date.dates[0]) })}</Typography>
          </CardHeader>
            </div>
            <ResourcesSelectedDetails resources={date.resources} title={t("stepTitles.summary", { ns: "pages/services/create" })} />
          <BackNextSubmitButtons
            cbBackButton={callbackBackClicked}
            cbSubmitButton={callbackNextClicked}
            currentStep={currentStep}
            numberSteps={numberSteps}
            showLoadingSpinnerSubmitButton={showLoadingSpinner}
          />
        </>
      )}
    </>
  );
};
 
export default AddDateSummary;