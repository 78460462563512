import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "react-spinkit";
import TableCard from "@src/components/ui/TableCard";
import EyeButton from "@src/components/ui/buttons/EyeButton";
import PencilButton from "@src/components/ui/buttons/PencilButton";
import {
  APIEndpointsStrings,
  DefinedRoutesStrings
} from "@src/helpers/stringHelpers";
import TrashButton from "@src/components/ui/buttons/TrashButton";
import { isClient, isProfessional } from "@src/helpers/userHelpers";
import IconButton from "../ui/buttons/IconButton";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";

const ListUsersTable = ({
  currentPageData : userList,
  showUserDetailsCallback,
  deleteUserCallback,
  impersonateUserCallback,
}) => {
  const { t, ready: translationsReady } = useTranslation([
    "components/users/listTable",
    "common/userTypes",
    "pages/users/list"
  ]);

  const getHeadersArray = () => Object.values(t("headers"));

  const formatDataMatrix = () => userList.map(user => {
    const canBeImpersonated = isClient(user) || isProfessional(user);

    return [
      user.id,
      user.email,
      t(user.type, { ns: "common/userTypes" }),
      <>
        <EyeButton
          onClick={() => showUserDetailsCallback(user)}
        />
        <IconButton
          icon={faSignInAlt}
          color="green"
          title={t("impersonateUserBtnTitle")}
          onClick={() => impersonateUserCallback(user)}
          disabled={!canBeImpersonated}
        />
        <PencilButton
          targetPath={DefinedRoutesStrings.editUserPath
            .replace(APIEndpointsStrings.idLocators.id, user.id)}
        />
        <TrashButton
          onClick={() => deleteUserCallback(user)}
        />
      </>
    ];
  });
    

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
          title={t("title", { ns: "pages/users/list" })}
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

export default ListUsersTable;