import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@src/components/ui/buttons/Button";
import Spinner from "@src/components/ui/Spinner";
import SubmitButton from "@src/components/ui/buttons/SubmitButton";

const BackNextSubmitButtons = ({
  currentStep,
  numberSteps,
  editingProductConfigurations,
  showLoadingSpinnerSubmitButton,
  readOnly,
  nextSubmitButtonDisabled,
  cbBackButton,
  cbNextButton,
  cbSubmitButton,  
}) => {
  const firstStep = currentStep === 1,
    lastStep = currentStep === numberSteps;
  const { t, ready: translationsReady } = useTranslation(["common/navigation", "common/form"]);

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="flex my-5">
          {!(firstStep && !editingProductConfigurations) && 
            <Button
              type="button"
              className="mr-auto"
              color="gray"
              onClick={cbBackButton}
            >
              {t("back")}
            </Button>
          }
          <SubmitButton
            className="ml-auto"
            onClick={lastStep ? cbSubmitButton : cbNextButton}
            showLoadingSpinner={showLoadingSpinnerSubmitButton}
            disabled={nextSubmitButtonDisabled}
            hidden={readOnly}
          >
            {lastStep ? t("buttons.submit", { ns: "common/form" }) : t("next")}
          </SubmitButton>
        </div>
      )}
    </>
  );
};

export default BackNextSubmitButtons;