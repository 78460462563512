import React from 'react';
import { useEffect } from 'react';

const TripadvisorRating = () => {

  /*
    As in https://stackoverflow.com/questions/50093832/mounting-external-scripts-that-transform-the-dom-in-react-components,
    reading the content of scripts loaded by this widget, I could find and execute the function that injects the HTML/CSS
    for this windget. As with eKomi widget, as soon as some of this gets modified, this will break.
  */

  useEffect(() => {
    Object.keys(window).forEach((key) => {
      if (key.match(/^injectcdsratingsonlynarrow[0-9]+$/)) {
        window[key]();
      }
    });
  }, []);
  
  return (
    <div id="tripadvisor-rating">
      <div id="TA_cdsratingsonlynarrow729" className="TA_cdsratingsonlynarrow">
        <ul id="nINrgS4vIqyR" className="TA_links pClG5kc8clw">
          <li id="yC1ohW" className="YEfTqgY1fX">
            <a target="_blank" rel="noreferrer" href="https://www.tripadvisor.es/Attraction_Review-g187441-d2175894-Reviews-Granavision-Granada_Province_of_Granada_Andalucia.html%22">
              <img src="https://www.tripadvisor.es/img/cdsi/img2/branding/v2/Tripadvisor_lockup_horizontal_secondary_registered-18034-2.svg" alt="TripAdvisor"/>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
 
export default TripadvisorRating;