import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import TableCard from "@src/components/ui/TableCard";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import IconButton from "@src/components/ui/buttons/IconButton";

const ValidateProfessionalsTable = ({
  currentPageData : professionalList,
  reviewProfessionalCallback,
}) => {
  const { t, ready: translationsReady } = useTranslation([
    "components/users/validateProfessionalsTable",
    "common/userTypes",
    "pages/users/validateProfessionals"
  ]);

  const getHeadersArray = () => Object.values(t("headers"));

  const formatDataMatrix = () => professionalList.map(professional => [
    professional.id,
    professional.email,
    t(professional.type, { ns: "common/userTypes" }),
    <IconButton
      icon={faComment}
      onClick={() => reviewProfessionalCallback(professional)}
    />
  ]);

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
          title={t("title", { ns: "pages/users/validateProfessionals" })}
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

export default ValidateProfessionalsTable;