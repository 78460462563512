import TimesButton from "@src/components/ui/buttons/TimesButton";
import Spinner from "@src/components/ui/Spinner";
import TableCard from "@src/components/ui/TableCard";
import { formatPrice } from "@src/helpers/localeHelpers";
import React from "react";
import { useTranslation } from "react-i18next";

const SelectedSupplementsTable = ({ currentPageData: supplements, deselectSupplementCallback }) => {
  const { t, ready: translationsReady } = useTranslation([
    "components/products/edit/supplements/selectedSupplementsTable",
    "common/list",
  ]);

  const getHeadersArray = () => Object.values(t("headers")).concat(t("actionsTableHeader", { ns: "common/list" }));

  const formatDataMatrix = () =>
    supplements.map(supplement => {
      const dataRow = [
        supplement.name,
        formatPrice(supplement.price),
        supplement.maxPerProduct,
        <TimesButton onClick={() => deselectSupplementCallback(supplement)} />,
      ];

      return deselectSupplementCallback ? dataRow : dataRow.slice(0, -1);
    });

  return !translationsReady ? (
    <Spinner />
  ) : (
    <TableCard
      headersArray={deselectSupplementCallback ? getHeadersArray() : getHeadersArray().slice(0, -1)}
      dataMatrix={formatDataMatrix()}
      isLastColumnForActions={true}
    />
  );
};

export default SelectedSupplementsTable;
