import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@src/components/ui/Button";
import Spinner from "@src/components/ui/Spinner";

const BannerImageDetails = ({ banner, backCallback }) => {
  const { t, ready: translationsReady } = useTranslation("common/navigation");

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="grid grid-cols-1 m-2 space-y-2">
          <div className="grid grid-cols-1 p-2 border rounded-md bg-gray-50 overflow-auto" style={{ maxHeight: "70vh" }}>
            <img alt="banner" src={banner.pictureUrl}/>
          </div>
          <Button
            className="bg-gray-400 font-bold text-white place-self-center"
            onClick={backCallback}
          >{t("close")}</Button>
        </div>
      )}
    </>
  );
};

export default BannerImageDetails;