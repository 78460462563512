import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LocationService from "@src/services/locationService";
import {
  createNotification,
  NotificationTypes,
} from "@src/helpers/notificationHelpers";
import LocationForm from "@src/components/locations/LocationForm";
import Spinner from "@src/components/ui/Spinner";
import { Dialog } from "@material-tailwind/react";
import { getCurrentUserData } from "@src/helpers/localStorageHelpers";
import { isServicesProvider } from "@src/helpers/userHelpers";

const CreateLocationDialog = ({
  open,
  handler: backCallback,
  handleLocationCreation,
  data,
}) => {
  const [errors, setErrors] = useState();
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation(
    "components/locations/create"
  );

  const submitData = async (values, _) => {
    setW18ApiResponse((b) => !b);

    try {
      if (!isServicesProvider()) {
        await LocationService.postNewLocationServiceProvider(
          values.servicesProviderId,
          values.locationIds
        );
      } else {
        await LocationService.postNewLocationServiceProvider(
          getCurrentUserData().id,
          values.locationIds
        );
      }
      setErrors(); // clear errorbox if present
      createNotification(NotificationTypes.success, t("okSubmissionResponse"));
      await handleLocationCreation();
    } catch (error) {
      createNotification(NotificationTypes.error, t("koSubmissionResponse"));
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        const unformattedErrors = error.response.data.error;
        const formattedErrors = Object.entries(unformattedErrors).reduce(
          (arr, e) => {
            const [errorKey, errorValues] = e;
            return arr.concat(errorValues.map((err) => errorKey + " " + err));
          },
          []
        );

        setErrors(formattedErrors);
      }
    } finally {
      setW18ApiResponse((b) => !b);
    }
  };

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <Dialog open={open} handler={backCallback} className="overflow-visible">
          <LocationForm
            errors={errors}
            backCallback={backCallback}
            showLoadingSpinner={w18ApiResponse}
            title={t("title")}
            handleSubmit={submitData}
            data={data}
          />
        </Dialog>
      )}
    </>
  );
};

export default CreateLocationDialog;
