import React from "react";
import { formatPrice } from "@src/helpers/localeHelpers";
import { stringDateFormat } from "@src/helpers/dateHelpers";
import Table from "@src/components/ui/Table";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";

const ListAssignedServicesTable = ({ currentPageData : resourceUnitList, date }) => {
  const { t, ready: translationsReady } = useTranslation("components/products/joker/serviceSelector");

  const getHeadersArray = () => Object.values(t("assignedServicesTableHeaders"));

  const formatDataMatrix = () => resourceUnitList.map(resourceUnit => [
    resourceUnit.resourceName,
    resourceUnit.providerEmail,
    resourceUnit.capacity,
    formatPrice(resourceUnit.priceHour),
    `${stringDateFormat(date)} ${resourceUnit.startTime} - ${resourceUnit.endTime}`,
  ]);    

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <Table
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
        />
      )}
    </>
  );
};

export default ListAssignedServicesTable;