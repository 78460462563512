import i18n from "@src/i18n";
import { getNumberStringWith0IfLowerThan10 } from "@src/helpers/numberHelpers";

export const SupportedLocalesArray = ["es", "en"];

export const formatPrice = (price, maxDecimals) => {
  const localeToUse = i18n.language;

  const priceFormatter = new Intl.NumberFormat(localeToUse, {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: maxDecimals,
  });

  return priceFormatter.format(price);
};

export const formatPercentage = (num) => Number(num?num:0/100).toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2})

/**
 * Returns true if user's clock displays time in 24 hour format
 */
export const localeUses24HourTime = langCode =>
  new Intl.DateTimeFormat(langCode, {
    hour: "numeric",
  })
    .formatToParts(new Date(2020, 0, 1, 13))
    .find(part => part.type === "hour").value.length === 2;

export const formatHour = (hour, locale = undefined) => {
  if (localeUses24HourTime(locale)) return hour;

  const [hours, minutes] = hour.split(":");
  let formattedHour;
  if (Number(hours) === 0) {
    formattedHour = `12:${minutes} AM`;
  } else if (Number(hours) <= 11) {
    formattedHour = `${hours}:${minutes} AM`;
  } else if (Number(hours) === 12) {
    formattedHour = `${hours}:${minutes} PM`;
  } else {
    formattedHour = `${getNumberStringWith0IfLowerThan10(Number(hours) - 12)}:${minutes} PM`;
  }

  return formattedHour;
};

export const getShortLanguageCode = language => language.split("-")[0];

export const getShortCurrentLanguageCode = () => getShortLanguageCode(i18n.language);
