import { B2CRoutesStrings } from '@src/helpers/stringHelpers';
import AboutUsPage from '@src/pages/b2c/static_help_pages/AboutUsPage';
import BecomeAPartnerPage from '@src/pages/b2c/static_help_pages/BecomeAPartnerPage';
import ContactPage from '@src/pages/b2c/static_help_pages/ContactPage';
import DiscoverMoreHerePage from '@src/pages/b2c/static_help_pages/DiscoverMoreHerePage';
import FAQPage from '@src/pages/b2c/static_help_pages/FAQPage';
import GDPRPage from '@src/pages/b2c/static_help_pages/GDPRPage';
import LegalNoticePage from '@src/pages/b2c/static_help_pages/LegalNoticePage';
import TermsOfServicePage from '@src/pages/b2c/static_help_pages/TermsOfServicePage';
import TicketsPage from '@src/pages/b2c/static_help_pages/TicketsPage';
import React from 'react';
import { Route } from 'react-router-dom';

const StaticB2CHelpRoutes = () => (
  <>

    <Route exact path={B2CRoutesStrings.staticHelpPaths.faqPath}>
      <FAQPage />
    </Route>

    <Route exact path={B2CRoutesStrings.staticHelpPaths.termsOfServicePath}>
      <TermsOfServicePage />
    </Route>

    <Route exact path={B2CRoutesStrings.staticHelpPaths.gdprPath}>
      <GDPRPage />
    </Route>

    <Route exact path={B2CRoutesStrings.staticHelpPaths.legalNoticePath}>
      <LegalNoticePage />
    </Route>

    <Route exact path={B2CRoutesStrings.staticHelpPaths.aboutUsPath}>
      <AboutUsPage />
    </Route>

    <Route exact path={B2CRoutesStrings.staticHelpPaths.contactUsPath}>
      <ContactPage />
    </Route>

    <Route exact path={B2CRoutesStrings.staticHelpPaths.discoverMoreHerePath}>
      <DiscoverMoreHerePage />
    </Route>

    <Route exact path={B2CRoutesStrings.staticHelpPaths.ticketsPath}>
      <TicketsPage />
    </Route>

    <Route exact path={B2CRoutesStrings.staticHelpPaths.becomeAPartnerPath}>
      <BecomeAPartnerPage />
    </Route>

  </>
);
 
export default StaticB2CHelpRoutes;