import React from 'react';
import OrderDisplay from '@src/components/orders/b2c/list/OrderDisplay';

const ListOrderObjects = ({ currentPageData: orderList, cbSetOrderToCancel, cbPrintOrder, cbSetOrderToShowRefundDetails }) =>
  orderList.map(order => (
    <OrderDisplay
      key={order.id}
      className="mt-10"
      order={order}
      cbSetOrderToCancel={cbSetOrderToCancel}
      cbPrintOrder={cbPrintOrder}
      cbSetOrderToShowRefundDetails={cbSetOrderToShowRefundDetails}
    />
  ));
 
export default ListOrderObjects;