import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@src/components/ui/Button";
import PaginateData from "@src/components/ui/PaginateData";
import Spinner from "@src/components/ui/Spinner";
import ListAssignedServicesTable from "@src/components/products/joker/ListAssignedServicesTable";

const ServiceSelector = ({ selectedProductDate, selectedTimeslice, selectedServiceCategory, cbAddService, cbBackNavigation }) => {
  const { t, ready: translationsReady } = useTranslation(["components/products/joker/serviceSelector", "common/navigation", "common/list"]);

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="max-w-2xl mx-auto space-y-2">
          <p className="text-lg font-bold text-gray-700 text-center">{t("title")}</p>
          <p>{t("description", { serviceCategory: selectedServiceCategory.name })}</p>
          <div className="flex justify-center space-x-2 my-2">
            <Button
              className="bg-gray-400 text-white font-bold"
              onClick={cbBackNavigation}
            >{t("back", { ns: "common/navigation" })}</Button>
            <Button
              onClick={cbAddService}
              className="bg-blue-400 text-white font-bold"
            >{t("add", { ns: "common/list" })}</Button>
          </div>
          <PaginateData
            data={selectedTimeslice.resourceUnitsTaken}
            emptyDataArrayMsg={t("noServicesAssigned")}
          >
            <ListAssignedServicesTable date={selectedProductDate.date} />
          </PaginateData>
        </div>
      )}
    </>
  );
};

export default ServiceSelector;