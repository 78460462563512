import axios from "@src/helpers/axiosInstance";
import { getJWT } from "@src/helpers/localStorageHelpers";
import { APIEndpointsStrings } from "@src/helpers/stringHelpers";
import OrderService from "@src/services/orders/orderService";

class OrderRefundService {
  static index = async () => {
    const res = await axios.get(APIEndpointsStrings.indexOrderRefundsPath, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });

    return res.data.map(orderRefund => this.formatReceivedOrderRefund(orderRefund));
  };
  
  static create = (orderId, description) =>
    axios.post(APIEndpointsStrings.createOrderRefundPath.replace(APIEndpointsStrings.idLocators.id, orderId), {
      order_refund: { description }
    }, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });

  static accept = (orderId, orderRefundId, isTotalRefund, refundQuantity, reason) => {
    const endpoint = APIEndpointsStrings.manageOrderRefundPath
      .replace(APIEndpointsStrings.idLocators.orderId, orderId)
      .replace(APIEndpointsStrings.idLocators.id, orderRefundId);
    
    let orderRefundConfiguration;

    if (isTotalRefund) {
      orderRefundConfiguration = { type: "total" };
    } else {
      orderRefundConfiguration = { type: "partial", amount: refundQuantity };
    }
    
    return axios.post(endpoint, {
      order_refund: { 
        result: "accepted",
        configuration: orderRefundConfiguration,
        reason,
      }
    }, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });
  };

  static deny = (orderId, orderRefundId, reason) => {
    const endpoint = APIEndpointsStrings.manageOrderRefundPath
      .replace(APIEndpointsStrings.idLocators.orderId, orderId)
      .replace(APIEndpointsStrings.idLocators.id, orderRefundId);
    
    return axios.post(endpoint, {
      order_refund: { 
        result: "denied",
        reason,
      }
    }, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });
  };
  
  static formatReceivedOrderRefund = orderRefund => ({
    id: orderRefund.id,
    clientRefundReason: orderRefund.description,
    refundQuantity: orderRefund.refund_price,
    operatorRefundReason: orderRefund.reason,
    orderId: orderRefund.order_id,
    totalOrderPrice: String(orderRefund.order_total_price),
    clientEmail: orderRefund.user,
    orderStatus: OrderService.formatReceivedOrderStatus(orderRefund.status),
    createdAt: !orderRefund.created_at ? undefined : new Date(orderRefund.created_at),
    orderCancelledAt: !orderRefund.order_cancelled_at ? undefined : new Date(orderRefund.order_cancelled_at),
  });
}

export default OrderRefundService;