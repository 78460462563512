import { Form, Formik } from "formik";
import React from "react";
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Spinner from "@src/components/ui/Spinner";
import BasicFormTemplate, { FooterType } from "@src/components/forms/BasicFormTemplate";
import FormikTextInput from "@src/components/forms/FormikTextInput";
import { Editor } from "react-draft-wysiwyg";
import { useToggle } from "@src/helpers/hooks/useToggle";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "@src/assets/styles/b2b/components/newsletter/globals.css"
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import Button from "@src/components/ui/buttons/Button";
import BannerService from "@src/services/bannerService";
import { useState } from "react";
import SelectBannerDialog from "@src/components/newsletters/SelectBannerDialog";
import SelectedBannersTable from "@src/components/newsletters/SelectedBannersTable";
import ShowBannerDialog from "@src/components/banners/ShowBannerDialog";
import CreateBanner from "@src/components/banners/CreateBanner";

const NewsletterSchema = Yup.object().shape({
  number: Yup.number()
    .typeError("common/form:validationErrors.numbers.notANumber")
    .required("common/form:validationErrors.numbers.notANumber")
    .min(1, "common/form:validationErrors.numbers.requiredGreaterThan0")
    .integer("common/form:validationErrors.numbers.requiredNaturalNumber"),
  title: Yup.string().required("components/newsletters/form:validations.noTitle"),
});

const NewsletterInitialValues = {
  number: "",
  title: "",
  body: "",
};

const NewsletterForm = ({ initialValues, handleSubmit, backCallback, showLoadingSpinnerSubmitButton, title }) => {
  const { t, ready: translationsReady } = useTranslation(["components/newsletters/form", "common/form"]);
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
  const [selectedBanner, setSelectedBanner] = useState();
  const [showSelectBannerDialog, setShowSelectBannerDialog] = useToggle();
  const [showCreateBannerDialog, setShowCreateBannerDialog] = useToggle();
  const [banners, setBanners] = useState([]);
  const [selectedBannerToShow, setSelectedBannerToShow] = useState();

  const editorLabels = {
    // BlockType
    'components.controls.blocktype.h1': t("blockType.title", { ns: "components/newsletters/form" }),
    'components.controls.blocktype.h2': t("blockType.subtitle", { ns: "components/newsletters/form" }),
    'components.controls.blocktype.h3': t("blockType.pretitle", { ns: "components/newsletters/form" }),
    'components.controls.blocktype.h4': t("blockType.title4", { ns: "components/newsletters/form" }),
    'components.controls.blocktype.h5': t("blockType.title5", { ns: "components/newsletters/form" }),
    'components.controls.blocktype.h6': t("blockType.title6", { ns: "components/newsletters/form" }),
    'components.controls.blocktype.blockquote': t("blockType.blockQuote", { ns: "components/newsletters/form" }),
    'components.controls.blocktype.code': t("blockType.code", { ns: "components/newsletters/form" }),
    'components.controls.blocktype.blocktype': 'Block Type',
    'components.controls.blocktype.normal': 'Normal',
  }

  const loadBanners = async () => {
    try {
      const bannersFetched = await BannerService.indexFilters(false, true);
      setBanners(bannersFetched);
    } catch (error) {
      createNotification(NotificationTypes.error, t("genericRequestError"));
    }
  }

  const loadBanner = async (banner_id) => {
    try{
      const banner = await BannerService.show(banner_id);
      setSelectedBanner(banner);
    } catch (error){
      createNotification(NotificationTypes.error, t("genericRequestError"));
    }
  }

  useEffect(() => {
    loadBanners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialValues){
      const value = EditorState.createWithContent(convertFromRaw(JSON.parse(initialValues.body)));
      setEditorState(value);

      if (initialValues.banner_id){
        loadBanner(initialValues.banner_id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const handleShowSelectBannerDialog = () => {
    setShowSelectBannerDialog();
  };

  const handleShowCreateBannerDialog = () => {
    setShowCreateBannerDialog();
  }

  const handleCreatedBanner = () => {
    setShowCreateBannerDialog();
    loadBanners();
  }

  const handleSelectedBanner = (banner) => {
    setSelectedBanner(banner);
    setShowSelectBannerDialog();
  }

  const handleBannerDeselection = () => {
    setSelectedBanner(null);
  };

  const handleBannerReplacement = (banner) => {
    setShowSelectBannerDialog();
  }

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <Formik
          initialValues={initialValues ? initialValues : NewsletterInitialValues}
          validationSchema={NewsletterSchema}
          onSubmit={(values) => {
            values.body = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
            values.banner_id = selectedBanner?.id;
            /* istanbul ignore next */
            handleSubmit(values);
          }}
        >
          <Form>
            <BasicFormTemplate
              className="mt-8"
              formTitle={title}
              showLoadingSpinner={showLoadingSpinnerSubmitButton}
              footerType={FooterType.BACK_SUBMIT_BUTTONS}
              backCallback={backCallback}
            >
              <div  className="my-3 px-4">
                <FormikTextInput
                  name="number"
                  type="number"
                  color="light-blue"
                  label={t("labels.number")}

                />
              </div>

              <div  className="my-3 px-4">
                <FormikTextInput
                  name="title"
                  type="text"
                  color="light-blue"
                  label={t("labels.title")}
                />
              </div>

              <div  className="my-3 px-4">
                <Editor
                  editorState={editorState}
                  onEditorStateChange={setEditorState}
                  placeholder="Write something!"
                  localization={{ locale: 'en', translations: editorLabels }}
                />
              </div>
              
              {!selectedBanner && (<div className="flex justify-center">
                <Button color="green" className="mb-5" onClick={handleShowSelectBannerDialog} disabled={selectedBanner ? true : false}>
                  {t("buttons.addBanner", { ns: "components/newsletters/form" })}
                </Button>
              </div>)}

              {!selectedBanner && (<div className="flex justify-center">
                <Button color="green" className="mb-5" onClick={handleShowCreateBannerDialog} disabled={selectedBanner ? true : false}>
                  {t("buttons.createAndSelectBanner", { ns: "components/newsletters/form" })}
                </Button>
              </div>)}
              {banners.length === 0 && (
                <div className="flex justify-center mt-4">
                  <Spinner />
                </div>
              )}

              {selectedBanner && (
                <div className="grid grid-cols-1 place-items-center max-w-xl mx-auto">
                  <SelectedBannersTable
                    bannerList={[selectedBanner]}
                    showBannerCallback={setSelectedBannerToShow}
                    deselectBannerCallback={handleBannerDeselection}
                    replaceBannerCallback={handleBannerReplacement}
                  />
                </div>
              )} 
            </BasicFormTemplate>
            <ShowBannerDialog banner={selectedBannerToShow} backCallback={() => setSelectedBannerToShow()} />
            {banners && (
              <SelectBannerDialog
                className={"p-4"}
                open={showSelectBannerDialog}
                selectedBannerId={selectedBanner ? selectedBanner.id : null}
                allBanners={banners}
                handler={() => setShowSelectBannerDialog()}
                selectBannerCallback={handleSelectedBanner}
              />
            )}
              <CreateBanner
                open={showCreateBannerDialog}
                handler={() => setShowCreateBannerDialog()}
                creationEndedCallback={handleCreatedBanner}
                newsletterSelectedBanner={setSelectedBanner}
              />
          </Form>
        </Formik>
      )}
    </>
  );
};

export default NewsletterForm;