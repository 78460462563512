import React from "react";

import { useTranslation } from "react-i18next";

import { dateToStringDDMMYYYYHHMMSS } from "@src/helpers/dateHelpers";

import Spinner from "@src/components/ui/Spinner";
import TableCard from "@src/components/ui/TableCard";
import EyeButton from "@src/components/ui/buttons/EyeButton";
import PencilButton from "@src/components/ui/buttons/PencilButton";

import TrashButton from "@src/components/ui/buttons/TrashButton";
import { APIEndpointsStrings, DefinedRoutesStrings } from "@src/helpers/stringHelpers";

const ListBannersTable = ({ currentPageData : bannerList,title, showBannerImageCallback, deleteBannerCallback }) => {
  const { t, ready: translationsReady } = useTranslation(["components/banners/listTable", "common/common", "common/list"]);

  const getHeadersArray = () => Object.values(t("headers")).concat(t("actionsTableHeader", { ns: "common/list" }));

  const formatDataMatrix = () => bannerList.map(n => [
    n.id,
    n.description,
    n.createdAt ? dateToStringDDMMYYYYHHMMSS(n.createdAt) : t("notAvailable", { ns: "common/common" }),
    n.enabled ?  t("enabled", { ns: "common/common" }) : t("notEnabled", { ns: "common/common" }),
    <>
      <EyeButton
        onClick={() => showBannerImageCallback(n)}
      />
      <PencilButton
        targetPath={DefinedRoutesStrings.editBannerPath.replace(APIEndpointsStrings.idLocators.id, n.id)}
      />
      <TrashButton
        onClick={() => deleteBannerCallback(n)}
      />

    </>
  ]);
    

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
          title={title}
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

export default ListBannersTable;