import React from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from '@src/components/ui/Spinner';
import { formatPrice } from '@src/helpers/localeHelpers';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { B2CRoutesStrings } from '@src/helpers/stringHelpers';
import HighlightedProducts from '@src/components/products/b2c/HighlightedProducts';
import FormikTextInput from '@src/components/forms/b2c/FormikTextInput';
import TripadvisorRating from '@src/components/ui/b2c/ratings/TripadvisorRating';

import "@src/assets/styles/b2c/pages/landing/landing.css";
import "@src/assets/styles/b2c/pages/landing/globals.css";
import "@src/assets/styles/b2c/pages/landing/styleguide.css";
import AndalusiaToursMap from '@src/components/landing/AndalusiaToursMap';

const LandingPage = () => {
  const { t, ready: translationsReady } = useTranslation("pages/b2c/landing");
  const history = useHistory();

  const handleSubmitProductSearch = ({ productName }) => {
    history.push(`${B2CRoutesStrings.listProductsSearchPath}?productName=${productName}`);
  };

  return (
    <div className="container-center-horizontal">
      <div className="landing screen">
        {!translationsReady ? <Spinner /> : (
          <>
            {/* hero panel search products */}
            <div className="overlap-group10">
              <div className="rectangle-13"></div>
              {/* eslint-disable-next-line */}
              <img
                className="image-24"
                src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bab950d3ed8532d0c3cc19/img/image-24@1x.png"
              />
              <div className="frame-1830">
                <TripadvisorRating />
              </div>
              <div className='flex flex-col left-36 top-20 absolute'>
                <h1 className="vive-una-experiencia-nica animate-enter11 sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl">{t("heroPanelSearchProducts.title")}</h1>
                <div className="visitas-inolvidables-en-granada">{t("heroPanelSearchProducts.subtitle")}</div>
              </div>
              
              <Formik
                initialValues={{ productName: "" }}
                onSubmit={handleSubmitProductSearch}
              >
                {({ values }) => (
                  <Form>
                    <div className="group-1764">
                      <FormikTextInput
                        className="mx-2"
                        name="productName"
                        label={t("heroPanelSearchProducts.productSearchInputPlaceholder")}
                      />
                      <button
                        type="submit"
                        className="hover:brightness-95 w-10 h-10 self-center shrink-0 disabled:opacity-70 disabled:brightness-100"
                        disabled={!values.productName}
                      >
                        {/* eslint-disable-next-line */}
                        <img
                          className="group-1763"
                          src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bab950d3ed8532d0c3cc19/img/group-1763@2x.svg"
                        />
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

            {/* popular products */}
            <div className="group-1838">
              <div className="ms-populares montserrat-bold-oslo-gray-14px uppercase">{t("mostPopularProducts.subtitle")}</div>
              <div className="granada-en-todo-su-esplendor montserrat-bold-shark-52px animate-enter2">
                {t("mostPopularProducts.title")}
              </div>
            </div>
            <HighlightedProducts />

            {/* explore products */}
            { true ? "" : (
            <div className="overlap-group11">
              <div className="frame-16 animate-enter12">
                <div className="visita-todos-sus-rincones">{t("exploreProducts")}</div>
                <div className="frame-14">
                  <div className="group-1812">
                    <div className="rectangle-1-1"></div>
                    <div className="rectangle-28"></div>
                    <div className="rectangle-28"></div>
                  </div>
                  <div className="frame-9-2">
                    {/* eslint-disable-next-line */}
                    <img
                      className="vector"
                      src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bab950d3ed8532d0c3cc19/img/vector@2x.svg"
                    />
                    <div className="desde montserrat-semi-bold-white-16px">{t("productPriceFrom", { price: formatPrice(12) })}</div>
                  </div>
                </div>
              </div>
            </div>
        )}

            {/* andalusia map */}
            <div className="group-1837">
              <div className="todos-los-lugares-de-granada-en-tu-mano montserrat-bold-oslo-gray-14px uppercase">
                {t("andalusiaMap.subtitle")}
              </div>
              <div className="conoce-las-aventuras montserrat-bold-shark-52px">{t("andalusiaMap.title")}</div>
            </div>
            <AndalusiaToursMap className="mt-8 mb-12" />

            {/* client reviews */}
            <div className="group-1799">
              <div className="group-12">
                <div className="nuestros-clientes uppercase">{t("clientReviews.subtitle")}</div>
                <div className="sus-referencias">{t("clientReviews.title")}</div>
              </div>
              <div className="overlap-group-container">
                <a
                  href="https://www.tripadvisor.es/ShowUserReviews-g187441-d2175894-r851788367-Granavision-Granada_Province_of_Granada_Andalucia.html"
                  className="flex-row"
                  target="_blank" rel="noopener noreferrer"
                >
                  <div className="flex-col-100">
                    {/* eslint-disable-next-line */}
                    <img
                      className="mask rounded-full"
                      src="https://media-cdn.tripadvisor.com/media/photo-l/1a/f6/e6/ea/default-avatar-2020-54.jpg"
                    />
                    <div className="group-173">
                      {/* eslint-disable-next-line */}
                      <img
                        className="tripadvisor-svgrepo-com-1"
                        src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bab950d3ed8532d0c3cc19/img/tripadvisor-svgrepo-com-1@2x.svg"
                      />
                    </div>
                  </div>
                  <div className="flex-col-1">
                    <div className="prototyping-in-the-b montserrat-medium-shark-18px">
                    Muy buena excursión. Excelente organización. Y las explicaciones de nuestra guia Cinthia fueron muy claras y precisas.
                    Quefsmos muy contentos. Gracias.
                    </div>
                    <div className="timothy-nathan montserrat-bold-red-ribbon-14px">DANIEL M</div>
                  </div>
                </a>
                <a
                  href="https://www.tripadvisor.es/ShowUserReviews-g187441-d2175894-r851560522-Granavision-Granada_Province_of_Granada_Andalucia.html"
                  className="flex-row-2"
                  target="_blank" rel="noopener noreferrer"
                >
                  <div className="flex-col-2">
                    {/* eslint-disable-next-line */}
                    <img
                      className="mask rounded-full"
                      src="https://media-cdn.tripadvisor.com/media/photo-l/1a/f6/e6/ea/default-avatar-2020-54.jpg"
                    />
                    <div className="group-173">
                      {/* eslint-disable-next-line */}
                      <img
                        className="tripadvisor-svgrepo-com-1"
                        src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bab950d3ed8532d0c3cc19/img/tripadvisor-svgrepo-com-1-1@2x.svg"
                      />
                    </div>
                  </div>
                  <div className="flex-col-1">
                    <div className="prototyping-in-the-b montserrat-medium-shark-18px">
                      Muy bien organizado. Rosa, nuestra guía ha explicado durante todo el recorrido
                      los datos, la historia, etc de cada rincón. Además es hábil en la gestión del
                      tráfico de grupos...
                    </div>
                    <div className="timothy-nathan montserrat-bold-red-ribbon-14px">ANGEL F</div>
                  </div>
                </a>
              </div>
              <div className="overlap-group-container-1">
                <a
                  href="https://www.facebook.com/100063478574950/posts/494479319344683/"
                  className="flex-row"
                  target="_blank" rel="noopener noreferrer"
                >
                  <div className="flex-col-100">
                    {/* eslint-disable-next-line */}
                    <img
                      className="mask rounded-full"
                      src="https://scontent-mad1-2.xx.fbcdn.net/v/t1.18169-9/28379338_10210981165150655_4548793600289967792_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=0e6Feo_EtcYAX8I8-2l&_nc_ht=scontent-mad1-2.xx&oh=00_AT_GppxDbUh8fOdbV_KyDmRdz-4_YCn3zzeVQNNN98fnSg&oe=636404EA"
                    />
                    <div className="group-179">
                      {/* eslint-disable-next-line */}
                      <img
                        className="icon-facebook"
                        src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bab950d3ed8532d0c3cc19/img/vector-1@2x.svg"
                      />
                    </div>
                  </div>
                  <div className="flex-col-1">
                    <div className="prototyping-in-the-b montserrat-medium-shark-18px">
                      Hoy hemos hecho un visita a la Alhambra con una excelente guía ( Rosa )
                      nos ha echo pasar la mañana muy amena,en ningún momento ni siquiera me 
                      he mirado el reloj (buena señal)...
                    </div>
                    <div className="timothy-nathan montserrat-bold-red-ribbon-14px">VICTORIA G</div>
                  </div>
                </a>
                <a
                  href="https://www.google.com/maps/reviews/@37.173534,-3.586342,17z/data=!3m1!4b1!4m6!14m5!1m4!2m3!1sChZDSUhNMG9nS0VJQ0FnSUMydVlLLUtnEAE!2m1!1s0x0:0x8ab290e0ef99d732"
                  className="flex-row-3"
                  target="_blank" rel="noopener noreferrer"
                >
                  <div className="flex-col-3">
                    {/* eslint-disable-next-line */}
                    <img
                      className="mask"
                      src="https://lh3.googleusercontent.com/a-/ACNPEu8MY6g8QoLMG7_hdwwAyCeeMfajPD-dUWW_wBysQA=w120-h120-p-rp-mo-ba5-br100"
                    />
                    <div className="group-180">
                      {/* eslint-disable-next-line */}
                      <img
                        className="icon-google"
                        src="https://www.vectorlogo.zone/logos/google/google-tile.svg"
                      />
                    </div>
                  </div>
                  <div className="flex-col-1">
                    <div className="prototyping-in-the-b montserrat-medium-shark-18px">
                      Our knowledgeable guide was the sole reason we had such a marvelous visit, 
                      enabling us to imagine how it must have been to live in and visit the place at the end of the 1400's.
                    </div>
                    <div className="timothy-nathan montserrat-bold-red-ribbon-14px">ESBEN K</div>
                  </div>
                </a>
              </div>
            </div>

            {/* partners */}
            <div className="group-13">
              <div className="nuestros-partners montserrat-bold-oslo-gray-14px uppercase">{t("partners.subtitle")}</div>
              <div className="partners montserrat-bold-shark-52px">{t("partners.title")}</div>
            </div>
            <div className="group-1775 montserrat-light-quill-gray-50px">
              <div className="logo-1">LOGO</div>
              <div className="logo-3">LOGO</div>
              <div className="logo-3">LOGO</div>
              <div className="logo-2">LOGO</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
 
export default LandingPage;