import { faLock, faPencilAlt, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BasicFormTemplate, { FooterType } from "@src/components/forms/BasicFormTemplate";
import FormikEmailInput from "@src/components/forms/common_inputs/FormikEmailInput";
import FormikSelect from "@src/components/forms/FormikSelect";
import FormikTextInput from "@src/components/forms/FormikTextInput";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import Spinner from "@src/components/ui/Spinner";
import { clearSession, getCurrentUserData } from "@src/helpers/localStorageHelpers";
import { createNotification, delayedRedirect, NotificationTypes } from "@src/helpers/notificationHelpers";
import { CommonRoutesStrings, DefinedRoutesStrings } from "@src/helpers/stringHelpers";
import { getOptionsForUserTypeSelect } from "@src/pages/CreateUserPage";
import UserService from "@src/services/users/userService";
import AuthenticationService from "@src/services/AuthenticationService";
import EditCompanyDataDialog from "@src/components/users/company/EditCompanyDataDialog";
import { isProfessional } from "@src/helpers/userHelpers";
import SubmitButton from "@src/components/ui/buttons/SubmitButton";

const testMatchingPasswords = (value, testContext) =>
  !testContext.parent.newPassword || value === testContext.parent.newPassword;

const EditUserSchema = Yup.object().shape({
  email: Yup.string()
    .email("common/form:validationErrors.empty.email")
    .required("common/form:validationErrors.empty.email"),
  newPassword: Yup.string().optional().min(6, "common/form:validationErrors.passwords.passwordMinLength"),
  passwordConfirmation: Yup.string().test(
    "passwordsMatch",
    "common/form:validationErrors.passwords.notMatchingPasswords",
    testMatchingPasswords
  ),
  userType: Yup.string().required(),
});

const EditProfileSchema = Yup.object().shape({
  email: Yup.string()
    .email("common/form:validationErrors.empty.email")
    .required("common/form:validationErrors.empty.email"),
  newPassword: Yup.string().optional().min(6, "common/form:validationErrors.passwords.passwordMinLength"),
  passwordConfirmation: Yup.string().test(
    "passwordsMatch",
    "common/form:validationErrors.passwords.notMatchingPasswords",
    testMatchingPasswords
  ),
});

const EditUserPage = ({ editingProfile }) => {
  const { id } = useParams();
  const [user, setUser] = useState();
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const [showEditProfessionalCompanyData, setShowEditProfessionalCompanyData] = useState(false);
  const { t, ready: translationsReady } = useTranslation([
    "pages/users/edit",
    "common/form",
    "common/userTypes",
    "pages/users/profile",
  ]);
  const history = useHistory();

  const currentUserEdited = (userId) => userId === getCurrentUserData().id;

  const logoutAndRedirectToLogin = () => {
    AuthenticationService.logout().catch((err) => console.log(err));

    clearSession();

    delayedRedirect(CommonRoutesStrings.loginPathAfterEditingCurrentUser(), 1);
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userToEdit = await UserService.show(editingProfile ? getCurrentUserData().id : id);
        setUser(userToEdit);
      } catch (error) {
        createNotification(NotificationTypes.error, t("messages.cannotRetrieveUserData"));
        history.replace(CommonRoutesStrings.homePath);
      }
    };

    translationsReady && fetchUser();
    // eslint-disable-next-line
  }, [translationsReady]);

  const getFormInitialValues = () =>
    editingProfile
      ? {
          email: user.email,
          newPassword: "",
          passwordConfirmation: "",
        }
      : {
          email: user.email,
          newPassword: "",
          passwordConfirmation: "",
          userType: user.type,
        };

  const handleUserSubmit = async ({ email, userType, newPassword }) => {
    setW18ApiResponse((b) => !b);

    try {
      await UserService.update(id, email, userType, newPassword);
      if (currentUserEdited(Number(id))) {
        logoutAndRedirectToLogin();
      } else {
        createNotification(NotificationTypes.success, t("messages.successEditUser"));
        history.push(DefinedRoutesStrings.listUsersPath);
      }
    } catch (err) {
      if (err?.response?.status === 409 && err?.response?.data?.user?.hasOwnProperty("email")) {
        createNotification(NotificationTypes.error, t("validationErrors.emailTaken", { ns: "common/form" }));
      }
    } finally {
      setW18ApiResponse((b) => !b);
    }
  };

  const handleProfileSubmit = async ({ email, newPassword }) => {
    setW18ApiResponse((b) => !b);

    try {
      await UserService.updateMyProfile(email, newPassword);
      logoutAndRedirectToLogin();
    } catch (err) {
      if (err?.response?.status === 409 && err?.response?.data?.user?.hasOwnProperty("email")) {
        createNotification(NotificationTypes.error, t("validationErrors.emailTaken", { ns: "common/form" }));
      }
    } finally {
      setW18ApiResponse((b) => !b);
    }
  };

  const handleSubmit = (values) => (editingProfile ? handleProfileSubmit(values) : handleUserSubmit(values));

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <>
          {!user ? (
            <Spinner />
          ) : (
            <div className="relative max-w-sm w-96 mx-auto my-auto mt-20" >
              <Formik
                initialValues={getFormInitialValues()}
                validationSchema={editingProfile ? EditProfileSchema : EditUserSchema}
                onSubmit={handleSubmit}
              >
                <Form className="max-w-md mx-auto">
                  <BasicFormTemplate
                    formTitle={editingProfile ? t("titles.editProfile") : t("titles.editUser")}
                    showLoadingSpinner={w18ApiResponse}
                    footerType={FooterType.BACK_SUBMIT_BUTTONS}
                  >
                    <div className="mb-12 px-4">
                      <FormikEmailInput />
                    </div>
                    <div className="mb-8 px-4">
                      <FormikTextInput
                        name="newPassword"
                        type="password"
                        label={t("labels.newPassword", { ns: "common/form" })}
                        placeholder={t("placeholders.optional", { ns: "common/form" })}
                        icon={<FontAwesomeIcon icon={faLock} color="lightGray" />}
                      />
                    </div>
                    <div className="mb-12 px-4">
                      <FormikTextInput
                        name="passwordConfirmation"
                        type="password"
                        label={t("labels.confirmPassword", { ns: "common/form" })}
                        placeholder={t("confirmPasswordRequiredIfNewPassword")}
                        icon={<FontAwesomeIcon icon={faRedo} color="lightGray" />}
                      />
                    </div>
                    {!editingProfile && (
                      <>
                        <div className="mb-8 px-4">
                          <FormikSelect
                            label={t("labels.userType", { ns: "common/form" })}
                            name="userType"
                            options={getOptionsForUserTypeSelect()}
                          />
                        </div>
                        {isProfessional(user) && (
                          <div className="flex justify-center">
                            <SubmitButton
                              icon={faPencilAlt}
                              type="button"
                              color="yellow"
                              onClick={() => setShowEditProfessionalCompanyData(true)}
                            >
                              {t("buttons.company.edit", { ns: "pages/users/profile" })}
                            </SubmitButton>
                          </div>
                        )}
                      </>
                    )}
                  </BasicFormTemplate>
                </Form>
              </Formik>
              {isProfessional(user) && (
                <EditCompanyDataDialog
                  open={showEditProfessionalCompanyData}
                  handler={() => setShowEditProfessionalCompanyData(false)}
                  company={user.company}
                />
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default EditUserPage;
