import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import Spinner from "@src/components/ui/Spinner";
import { Dialog } from "@material-tailwind/react";
import { Form, Formik } from "formik";
import BasicFormTemplate, { FooterType } from "@src/components/forms/BasicFormTemplate";
import FormikTextArea from "@src/components/forms/FormikTextArea";
import OrderRefundService from "@src/services/orders/OrderRefundService";
import BooleanRadioButtons from "@src/components/forms/BooleanRadioButtons";
import FormikTextInput from "@src/components/forms/FormikTextInput";
import { formatPrice } from "@src/helpers/localeHelpers";

const FormInitialValues = {
  refundQuantity: "",
  reason: ""
};

const AcceptOrderRefundDialog = ({ open, handler: finalizeDialog, refundToAccept }) => {
  const [isTotalRefund, setIsTotalRefund] = useState(true);
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation(["components/orders/orderRefunds/acceptDialog", "common/form"]);

  const handleCloseDialog = async (shouldReloadRefunds) => {
    // clear component status, as this component is not really unmounted
    setIsTotalRefund(true);

    await finalizeDialog(shouldReloadRefunds);
  };

  const handleIsTotalRefundChange = (formikBag, newIsTotalRefundValue) => {
    formikBag.setFieldValue("refundQuantity", "");
    formikBag.setFieldTouched("refundQuantity", false);
    setIsTotalRefund(newIsTotalRefundValue);
  };

  const validateRefund = ({ refundQuantity, reason }) => {
    const errors = {};

    if (!isTotalRefund) {
      if (!refundQuantity || isNaN(refundQuantity)) {
        errors.refundQuantity = "common/form:validationErrors.numbers.notANumber";
      } else if (Number(refundQuantity) > Number(refundToAccept.totalOrderPrice)) {
        errors.refundQuantity = "components/orders/orderRefunds/acceptDialog:form.errors.refundGreaterTotalOrderPrice";
      }
    }

    if (!reason) {
      errors.reason = "components/orders/orderRefunds/acceptDialog:form.errors.reasonEmpty";
    }

    return errors;
  };

  const acceptRefund = async ({ refundQuantity, reason }) => {
    setW18ApiResponse(b => !b);

    try {
      await OrderRefundService.accept(refundToAccept.orderId, refundToAccept.id, isTotalRefund, refundQuantity, reason);
      createNotification(NotificationTypes.success, t("action.ok"));
      await handleCloseDialog(true);
    } catch (_) {
      createNotification(NotificationTypes.error, t("action.ko"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <Dialog
          open={open}
          handler={handleCloseDialog}
        >
          <Formik
            initialValues={FormInitialValues}
            validate={validateRefund}
            onSubmit={acceptRefund}
          >
            { formikBag => (
              <Form>
                <BasicFormTemplate
                  className="mt-8"
                  formTitle={t("title")}
                  showLoadingSpinner={w18ApiResponse}
                  footerType={FooterType.BACK_SUBMIT_BUTTONS}
                  backCallback={handleCloseDialog}
                >
                  <p>{t("description")}</p>
                  <p className="mt-2">{t("form.inputs.isTotalRefund.description")}</p>
                  <BooleanRadioButtons
                    defaultValue={isTotalRefund}
                    cbSetState={newIsTotalRefundValue => handleIsTotalRefundChange(formikBag, newIsTotalRefundValue)}
                  />
                  {!isTotalRefund &&
                    <>
                      <p>{t("form.inputs.refundQuantity.description")}</p>
                      <FormikTextInput
                        className="my-6 px-4"
                        name="refundQuantity"
                        label={t("form.inputs.refundQuantity.label")}
                        secondLabel={t("form.inputs.refundQuantity.totalOrderPriceInfo", { price: formatPrice(refundToAccept.totalOrderPrice) })}
                      />
                    </>
                  }
                  <p>{t("form.inputs.reason.description")}</p>
                  <div className="my-6 px-4">
                    <FormikTextArea
                      name="reason"
                      label={t("form.inputs.reason.label")}
                    />
                  </div>
                </BasicFormTemplate>
              </Form>
            )}
          </Formik>
        </Dialog>
      )}
    </>
  );
};

export default AcceptOrderRefundDialog;