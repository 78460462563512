import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ListProductCategoriesTable from "@src/components/products/categories/ListProductCategoriesTable";
import Button from "@src/components/ui/buttons/Button";
import PaginateData from "@src/components/ui/PaginateData";
import { delCategory, getAllCategories } from "@src/helpers/categoryHelpers";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { CommonRoutesStrings } from "@src/helpers/stringHelpers";
import CreateProductCategoryDialog from "@src/components/products/categories/CreateProductCategoryDialog";
import EditProductCategoryDialog from "@src/components/products/categories/EditProductCategoryDialog";
import Spinner from "@src/components/ui/Spinner";
import { useTranslation } from "react-i18next";
import BooleanResponseDialog from "@src/components/ui/BooleanResponseDialog";
import HistoryBackButton from "@src/components/ui/buttons/HistoryBackButton";
import ProductCategoryService from "@src/services/productCategoryService";

const ListProductCategoriesPage = () => {
  const [categories, setCategories] = useState();
  const [showModalCreateCategory, setShowModalCreateCategory] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState();
  const [categoryToDelete, setCategoryToDelete] = useState();
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation(["pages/products/categories/list", "common/list", "common/common"]);
  const history = useHistory();
  const CATEGORIES_PER_PAGE = 5;

  const fetchCategories = async () => {
    try {
      const getCategoriesRequest = await getAllCategories();
      setCategories(getCategoriesRequest.data);
    } catch (error) {
      createNotification(NotificationTypes.error, t("messages.genericRequestError"));
      history.push(CommonRoutesStrings.homePath);
    }
  };

  useEffect(() => {
    translationsReady && fetchCategories();
    // eslint-disable-next-line
  }, [translationsReady]);

  const deleteCategory = async () => {
    setW18ApiResponse(b => !b);

    try {
      await delCategory(categoryToDelete.id);
      createNotification(NotificationTypes.success, t("messages.deleteAction.ok"));
      setCategories(categories.filter(c => c.id !== categoryToDelete.id));
      setCategoryToDelete();
    } catch (error) {
      createNotification(NotificationTypes.error, t("messages.deleteAction.ko"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  const toggleCategoryHighlight = async (category) => {
    const action  = category.highlighted ? ProductCategoryService.unhighlight : ProductCategoryService.highlight,
      messages = category.highlighted ? t("messages.unhighlightAction") : t("messages.highlightAction");

    setW18ApiResponse(b => !b);

    try {
      await action(category.id);
      await fetchCategories();
      createNotification(NotificationTypes.success, messages.ok);
    } catch (err) {
      createNotification(NotificationTypes.error, messages.ko);
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  const finishCategoryCreation = async () => {
    await fetchCategories();
    setShowModalCreateCategory(false);
  };

  const finishCategoryModification = async () => {
    await fetchCategories();
    setCategoryToEdit();
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <>
          <div className="flex justify-center items-center mt-2 space-x-2">
            <HistoryBackButton size="md" />
            <Button
              color="green"
              onClick={() => setShowModalCreateCategory(true)}
            >{t("new", { ns: "common/common" })}</Button>
          </div>
          {!categories ? <Spinner /> : (
            <div className="grid grid-cols-1 place-items-center max-w-xl mx-auto">
              <PaginateData
                data={categories}
                emptyDataArrayMsg={t("messages.noCategoriesToList")}
                perPage={CATEGORIES_PER_PAGE}
              >
                <ListProductCategoriesTable
                  toggleCategoryHighlightCallback={toggleCategoryHighlight}
                  editCategoryCallback={category => setCategoryToEdit(category)}
                  deleteCategoryCallback={category => setCategoryToDelete(category)}
                />
              </PaginateData>
            </div>
          )}
          <CreateProductCategoryDialog
            open={showModalCreateCategory}
            handler={() => setShowModalCreateCategory(false)}
            handleCategoryCreation={finishCategoryCreation}
          />
          <EditProductCategoryDialog
            category={categoryToEdit}
            handler={() => setCategoryToEdit()}
            handleCategoryModification={finishCategoryModification}
          />
          <BooleanResponseDialog
            open={!!categoryToDelete}
            noCallback={() => setCategoryToDelete()}
            yesCallback={deleteCategory}
            showLoadingSpinner={w18ApiResponse}
          >
            {t("deleteModalMessage", { ns: "common/list" })}
          </BooleanResponseDialog>
        </>
      )}
    </>
  );
};

export default ListProductCategoriesPage;