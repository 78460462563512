import { Dialog, DialogBody, DialogFooter, DialogHeader } from '@material-tailwind/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from '@src/components/ui/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Button from '@src/components/ui/buttons/Button';

const InfoDialog = ({ children, open, unavoidable, okCallback, }) => {
  const { t, ready: translationsReady } = useTranslation(["common/common", "common/navigation"]);
  const handleOuterClickOrEscKeypress = unavoidable ? undefined : okCallback;

  return (
    <Dialog open={open} handler={handleOuterClickOrEscKeypress}>
      {!translationsReady ? <Spinner /> :
        <>
          <DialogHeader>
            <FontAwesomeIcon icon={faInfoCircle} color="SkyBlue" className="mr-2" />
            {t("info")}
          </DialogHeader>
          <DialogBody>{children}</DialogBody>
          <DialogFooter className="flex">
            <Button
              color="gray"
              className="ml-auto"
              onClick={okCallback}
            >{t("close", { ns: "common/navigation" })}</Button>
          </DialogFooter>
        </>
      }
    </Dialog>
  );
};
 
export default InfoDialog;