import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { updatePeopleSegment } from "@src/helpers/peopleSegmentHelpers";
import PeopleSegmentForm from "@src/components/products/people_segments/PeopleSegmentForm";
import Spinner from "@src/components/ui/Spinner";
import { Dialog } from "@material-tailwind/react";

const EditPeopleSegmentDialog = ({ peopleSegment, handler: backCallback, handlePeopleSegmentModification }) => {
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation("components/products/peopleSegments/edit");

  const submitData = async ({ name, quantity }, _) => {
    setW18ApiResponse(b => !b);

    try {
      await updatePeopleSegment(peopleSegment.id, name, quantity);
      createNotification(NotificationTypes.success, t("successEditPeopleSegment"));
      await handlePeopleSegmentModification();
    } catch (error) {
      createNotification(NotificationTypes.error, t("genericRequestError"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <Dialog
          open={!!peopleSegment}
          handler={backCallback}
        >
          <PeopleSegmentForm
            backCallback={backCallback}
            showLoadingSpinner={w18ApiResponse}
            title={t("title")}
            initialValues={peopleSegment}
            handleSubmit={submitData}
          />
        </Dialog>
      )}
    </>
  );
};

export default EditPeopleSegmentDialog;