import React from 'react';
import { Link } from 'react-router-dom';
import { B2CRoutesStrings } from '@src/helpers/stringHelpers';
import Spinner from '@src/components/ui/Spinner';
import { useTranslation } from 'react-i18next';

import "@src/assets/styles/b2c/pages/user_type_picker_register_page/user-type-picker-register-page.css";
import "@src/assets/styles/b2c/pages/user_type_picker_register_page/globals.css";
import "@src/assets/styles/b2c/pages/user_type_picker_register_page/styleguide.css";

const UserTypePickerRegisterPage = () => {
  const { t, ready: translationsReady } = useTranslation(["pages/users/userTypePickerRegister", "common/userTypes", "common/list", "common/navigation"]);

  return (
    <div className="container-center-horizontal">
      <div className="crear-usuario screen">
        <div className="overlap-group1">
          {!translationsReady ? <Spinner /> : (
            <>
              <div className="rectangle-13"></div>
              {/* eslint-disable-next-line */}
              <img
                className="image-35"
                src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62babdbc8606f7d7f2443e0a/img/image-35-4@1x.png"
              />
              <div className="frame-1846">
                <div className="frame-container">
                  <div className="frame-1847">
                    <div className="frame-5">
                      <h1 className="title">{t("title")}</h1>
                      <p className="elige-con-que-usuari">{t("description")}</p>
                    </div>
                    <div className="frame-1844">
                      <div className="frame-1839">
                        <div className="frame-1838">
                          <div className="cliente montserrat-bold-red-ribbon-19px">{t("Client", { ns: "common/userTypes" })}</div>
                          <p className="este-usuario-puede-c montserrat-normal-shark-12px">
                            {t("userDescriptions.client")}
                          </p>
                        </div>
                        <Link to={B2CRoutesStrings.registerClientPath}>
                          <div className="button-select-user-register-picker">
                            {/* eslint-disable-next-line */}
                            <img
                              className="vector"
                              src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62b9c85da8a9a74bd579b194/img/vector-21@2x.svg"
                            />
                            <div className="seleccionar montserrat-semi-bold-white-16px">{t("select", { ns: "common/list" })}</div>
                          </div>
                        </Link>
                      </div>
                      <div className="rectangle-30"></div>
                      <div className="frame-1841">
                        <div className="frame-1840">
                          <div className="proveedor-de-servicios montserrat-bold-red-ribbon-19px">{t("ServicesProvider", { ns: "common/userTypes" })}</div>
                          <p className="este-usuario-puede-o montserrat-normal-shark-12px">
                            {t("userDescriptions.servicesProvider")}
                          </p>
                        </div>
                        <Link to={B2CRoutesStrings.registerServicesProviderPath}>
                          <div className="button-select-user-register-picker">
                            {/* eslint-disable-next-line */}
                            <img
                              className="vector"
                              src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62b9c85da8a9a74bd579b194/img/vector-22@2x.svg"
                            />
                            <div className="seleccionar montserrat-semi-bold-white-16px">{t("select", { ns: "common/list" })}</div>
                          </div>
                        </Link>
                      </div>
                      <div className="rectangle-30"></div>
                      <div className="frame-1843">
                        <div className="frame-1842">
                          <div className="proveedor-de-terceros montserrat-bold-red-ribbon-19px">{t("Provider", { ns: "common/userTypes" })}</div>
                          <p className="este-usuario-puede-o-1 montserrat-normal-shark-12px">
                            {t("userDescriptions.thirdPartyProvider")}
                          </p>
                        </div>
                        <Link to={B2CRoutesStrings.registerProviderPath}>
                          <div className="button-select-user-register-picker">
                            {/* eslint-disable-next-line */}
                            <img
                              className="vector"
                              src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62b9c85da8a9a74bd579b194/img/vector-23@2x.svg"
                            />
                            <div className="seleccionar montserrat-semi-bold-white-16px">{t("select", { ns: "common/list" })}</div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="frame-1848">
                    <div className="rectangle-303"></div>
                    <div className="frame-8-1">
                      {/* eslint-disable-next-line */}
                      <img
                        className="group-1835"
                        src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62b9c85da8a9a74bd579b194/img/group-1835-2@2x.svg"
                      />
                      <Link to={B2CRoutesStrings.loginPath}>
                        <div className="volver montserrat-semi-bold-white-16px">{t("back", { ns: "common/navigation" })}</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
 
export default UserTypePickerRegisterPage;