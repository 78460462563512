import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { getDateWithCertainTime } from "../../../helpers/dateHelpers";
import TextInput from "../../forms/TextInput";
import ErrorText from "../../ui/ErrorText";
import Spinner from "../../ui/Spinner";
import SubmitButton from "../../ui/SubmitButton";

const FormInitialValues = {
  startTime: "",
  endTime: "",
};

const ServiceTimeSearchForm = ({ showLoadingSpinnerSubmitButton, cbHandleTimeSelection }) => {
  const { t, ready: translationsReady } = useTranslation(["components/products/joker/serviceSelector", "common/common"]);

  const validateForm = ({ startTime, endTime }) => {
    if (!(startTime && endTime)) return { hour: "components/products/joker/serviceSelector:serviceSearchAndAssignment.serviceTimeSearchForm.validations.noBothHours" };

    if (getDateWithCertainTime(startTime).valueOf() >= getDateWithCertainTime(endTime).valueOf())
      return { hour: "components/products/joker/serviceSelector:serviceSearchAndAssignment.serviceTimeSearchForm.validations.startTimeLower" };
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <Formik
          initialValues={FormInitialValues}
          validate={validateForm}
          onSubmit={(values, _) => cbHandleTimeSelection(values)}
        >
          {({ errors, touched }) =>
            <Form>
              <div className="flex space-x-2">
                <TextInput
                  label={t("serviceSearchAndAssignment.serviceTimeSearchForm.labels.startTime")}
                  name="startTime"
                  type="time"
                  yMargin="2"
                />
                <TextInput
                  label={t("serviceSearchAndAssignment.serviceTimeSearchForm.labels.endTime")}
                  name="endTime"
                  type="time"
                  yMargin="2"
                />
                <SubmitButton
                  className="bg-green-500 text-white font-bold place-self-center"
                  showLoadingSpinner={showLoadingSpinnerSubmitButton}
                >{t("search", { ns: "common/common" })}</SubmitButton>
              </div>
              {errors.hour && Object.keys(touched).length > 0 &&
                <ErrorText>{t(errors.hour)}</ErrorText>
              }          
            </Form>
          }      
        </Formik>
      )}
    </>
  );
};

export default ServiceTimeSearchForm;