import React from "react";

import EsFlag from "@src/assets/images/country_flags/es.svg";
import GbFlag from "@src/assets/images/country_flags/gb.svg";
import DeFlag from "@src/assets/images/country_flags/de.svg";
import FrFlag from "@src/assets/images/country_flags/fr.svg";
import ItFlag from "@src/assets/images/country_flags/it.svg";
import PtFlag from "@src/assets/images/country_flags/pt.svg";
import JaFlag from "@src/assets/images/country_flags/ja.svg";
import ZhFlag from "@src/assets/images/country_flags/zh.svg";
import DaFlag from "@src/assets/images/country_flags/da.svg";
import RuFlag from "@src/assets/images/country_flags/ru.svg";
import NlFlag from "@src/assets/images/country_flags/nl.svg";
import SvFlag from "@src/assets/images/country_flags/sv.svg";
import ArFlag from "@src/assets/images/country_flags/ar.svg";

export const SupportedProductLanguages = [
  { code: "es", flag: EsFlag },
  { code: "en", flag: GbFlag },
  { code: "fr", flag: FrFlag },
  { code: "it", flag: ItFlag },
  { code: "pt", flag: PtFlag },
  { code: "de", flag: DeFlag },
  { code: "ja", flag: JaFlag },
  { code: "zh", flag: ZhFlag },
  { code: "da", flag: DaFlag },
  { code: "ru", flag: RuFlag },
  { code: "nl", flag: NlFlag },
  { code: "sv", flag: SvFlag },
  { code: "ar", flag: ArFlag },
];

/**
 * This component uses some of the ISO 639-1 codes and maps them to a country flag.
 * Decisions where made, as not all languages have only one country.
 *
 */
const CountryFlag = ({ languageCode, ...props }) => {
  const desiredLanguage = SupportedProductLanguages.find(language => language.code === languageCode);

  return !desiredLanguage ? (
    <p {...props}>{languageCode}</p>
  ) : (
    <>
      {/* eslint-disable-next-line */}
      <img src={desiredLanguage.flag} {...props} />
    </>
  );
};

export default CountryFlag;
