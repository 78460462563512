import React, { useEffect, useState } from "react";
import PaginateData from "@src/components/ui/PaginateData";
import ValidateProfessionalsTable from "@src/components/users/ValidateProfessionalsTable";
import {
  createNotification,
  NotificationTypes
} from "@src/helpers/notificationHelpers";
import {
  revokeProfessionalRequest,
  validateProfessionalRequest,
} from "@src/helpers/userHelpers";
import Spinner from "@src/components/ui/Spinner";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import HistoryBackButton from "@src/components/ui/buttons/HistoryBackButton";
import ProfessionalService from "@src/services/users/professionalService";
import ValidateProfessionalDialog from "@src/components/users/ValidateProfessionalDialog";

const ValidateProfessionalsPage = () => {
  const [professionals, setProfessionals] = useState();
  const [professionalToReview, setProfessionalToReview] = useState();
  const PROFESSIONALS_PER_PAGE = 5;
  const [w18ApiResponseValidateAction, setW18ApiResponseValidateAction] = useState(false);
  const [w18ApiResponseRevokeAction, setW18ApiResponseRevokeAction] = useState(false);
  const { t, ready: translationsReady } = useTranslation([
    "pages/users/validateProfessionals",
    "components/users/validateProfessionalsTable",
    "pages/users/list"
  ]);
  const history = useHistory();

  const fetchProfessionals = async () => {
    try {
      setProfessionals(await ProfessionalService.indexReviewableOnes());
    } catch (err) {
      createNotification(NotificationTypes.error,
        t("messages.cannotRetrieveUserList", { ns: "pages/users/list" }));
      history.goBack();
    }
  };

  useEffect(() => {  
    fetchProfessionals();
    // eslint-disable-next-line
  }, []);

  const performReviewAction = async (setW18ApiResponse, reviewAction, okReviewMsg) => {
    setW18ApiResponse(b => !b);

    try {
      await reviewAction(professionalToReview.id);
      await fetchProfessionals();
      setProfessionalToReview();
      createNotification(NotificationTypes.success, okReviewMsg);
    } catch (err) {
      createNotification(NotificationTypes.error, t("messages.genericRequestError"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  const doValidateProfessional = () =>
    performReviewAction(setW18ApiResponseValidateAction, validateProfessionalRequest, t("messages.okProfessionalValidated"));

  const doRevokeProfessional = () => 
    performReviewAction(setW18ApiResponseRevokeAction, revokeProfessionalRequest, t("messages.okProfessionalRevoked"));

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <>
          {!professionals ? <Spinner /> : (
            <div className="grid grid-cols-1 place-items-center max-w-4xl mx-auto">
              <HistoryBackButton />
              <PaginateData 
                data={professionals}
                perPage={PROFESSIONALS_PER_PAGE}
                emptyDataArrayMsg={t("messages.noProfessionalsToValidate")}
              >
                <ValidateProfessionalsTable
                  reviewProfessionalCallback={professional => setProfessionalToReview(professional)}
                />
              </PaginateData>
              <ValidateProfessionalDialog
                open={!!professionalToReview}
                handler={() => setProfessionalToReview()}
                professional={professionalToReview}
                cbValidateProfessional={doValidateProfessional}
                cbRevokeProfessional={doRevokeProfessional}
                showLoadingSpinnerValidateAction={w18ApiResponseValidateAction}
                showLoadingSpinnerRevokeAction={w18ApiResponseRevokeAction}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ValidateProfessionalsPage;