import axios from "@src/helpers/axiosInstance";
import { APIEndpointsStrings } from "@src/helpers/stringHelpers";

class UserRegistrationService {

  static validateEmailAlreadyRegistered = email =>
    axios.post(APIEndpointsStrings.validateEmailAlreadyRegisteredPath, { email });

}

export default UserRegistrationService;