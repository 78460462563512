import React from 'react';
import { useEffect } from 'react';

import "@src/assets/styles/b2c/components/ui/ratings/ekomi-rating.css";

const EkomiRating = () => {

  const registerWidget = () => {
    var token = 'sf148421616d3c3c55b63';
    window['_ekomiWidgetsServerUrl'] = 'https://widgets.ekomi.com';
    window['_customerId'] = 148421;
    if (!window['_language']) {
      window['_language'] = [];
    }
    window['_language'][token] = 'auto';
    if(typeof(window['_ekomiWidgetTokens']) !== 'undefined'){
      window['_ekomiWidgetTokens'][window['_ekomiWidgetTokens'].length] = token;
    } else {
      window['_ekomiWidgetTokens'] = [ token ];
    }
    var scr = document.createElement('script');
    scr.src = 'https://sw-assets.ekomiapps.de/static_resources/widget.js';
    var head = document.getElementsByTagName('head')[0];
    head.appendChild(scr);
  };

  useEffect(() => {
    registerWidget();
  }, []);

  return (
    <>
      <div id="widget-container" className="ekomi-widget-container ekomi-widget-sf148421616d3c3c55b63" ></div>
      <a href="https://www.ekomi.es/testimonios-andalsurexcursiones.html" rel="noreferrer" target="_blank">
        <img alt="Andalsur Excursiones Reviews with ekomi.es" src="https://smart-widget-assets.ekomiapps.de/resources/ekomi_logo.png" style={{ display: "none" }}/>
      </a>
    </>
  );
}
 
export default EkomiRating;