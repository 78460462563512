import Spinner from '@src/components/ui/Spinner';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const DiscoverMoreHerePage = () => {
  const { t, ready: translationsReady } = useTranslation(["pages/b2c/staticHelpPages/discoverMoreHere", "components/b2c/footer"]);

  return (
    <div className="bg-white py-[67px] px-[135.9px] grid grid-cols-1 sm:text-sm md:text-base lg:text-lg xl:text-xl montserrat-medium-shark  w-full mx-auto max-w-[1440px]">
      {!translationsReady ? <Spinner /> : (
        <>
          <p className="self-start font-extrabold  sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-[#e30746]">{t("discoverMoreHere.title", { ns: "components/b2c/footer" })}</p>
          <p className="mt-8 whitespace-pre-line leading-relaxed">
            <Trans
              t={t}
              i18nKey="content"
              components={{
                1: <strong />,
              }}
            />
          </p>
        </>
      )}
    </div>
  );
};
 
export default DiscoverMoreHerePage;