import BooleanResponseDialog from "@src/components/ui/BooleanResponseDialog";
import PaginateData from "@src/components/ui/PaginateData";
import Spinner from "@src/components/ui/Spinner";
import {
  CommonRoutesStrings,
  DefinedRoutesStrings,
} from "@src/helpers/stringHelpers";
import { isClient } from "@src/helpers/userHelpers";
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import ListOrdersTable from "@src/components/orders/list/ListOrdersTable";
import LinkButton from "@src/components/ui/buttons/LinkButton";
import Button from "@src/components/ui/buttons/Button";
import { useTranslation } from "react-i18next";
import FilterOrderForm from "./FilterOrderForm";

const ORDER_PER_PAGE = 5;
const ListOrdersB2BSkin = ({
  orders,
  error,
  orderToCancel,
  w18ApiResponse,
  cbSetOrderToCancel,
  cbCancelOrder,
  cbPrintOrder,
  cbSetClientFilter,
  cbSetDateMinFilter,
  cbSetDateMaxFilter,
  cbSetStatusFilter, 
}) => {
  const [showFilterForm, setShowFilterForm] = useState(true);
  const { t, ready: translationsReady } = useTranslation(["common/filterForm","pages/orders/list", "common/orderStatuses"]);

  

  

  const toggleFiltering = () => {
    setShowFilterForm((show) => !show);
    handleFilterInputChange(cbSetClientFilter, "")
    handleFilterInputChange(cbSetStatusFilter, "")
    handleFilterInputChange(cbSetDateMinFilter, "")
    handleFilterInputChange(cbSetDateMaxFilter, "")
  };

  function handleFilterInputChange(setStateCallback, newState) {
    setStateCallback(newState);
  }

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <>
      <div className="flex flex-col">
        {error && <Redirect to={CommonRoutesStrings.homePath} />}
        <div className="flex mx-auto ">
          <LinkButton className="mr-1" to={DefinedRoutesStrings.listOrderRefundsPath}>
            {t("manageRefundsBtn", { ns: "pages/orders/list" })}
          </LinkButton>
          {
            <Button className="ml-1" onClick={toggleFiltering}>
              {!showFilterForm
                ? t("enableFilter", { ns: "common/filterForm" })
                : t("disableFilter", { ns: "common/filterForm" })}
            </Button>
          }
        </div>
        {!orders ? (
          <Spinner />
        ) : (
          <div className="grid grid-cols-1 place-items-center w-full">
            {showFilterForm && (
              <FilterOrderForm
                color="yellow"
                className="mt-11 mb-2 w-3/4"
                cbSetClient={(client) =>
                  handleFilterInputChange(cbSetClientFilter, client)
                }
                cbSetDateMin={(date_min) => 
                  handleFilterInputChange(cbSetDateMinFilter, date_min)
                }
                cbSetDateMax={(date_max) => 
                  handleFilterInputChange(cbSetDateMaxFilter, date_max)
                }
                cbSetStatus={(status) =>
                  handleFilterInputChange(cbSetStatusFilter, status)
                }
                
              />
            )}
            <PaginateData
              data={orders}
              emptyDataArrayMsg={t("messages.noOrdersToList")}
              perPage={ORDER_PER_PAGE}
            >
              <ListOrdersTable
                title={t("titles.admin", { ns: "pages/orders/list" })}
                clientPerspective={isClient()}
                cancelOrderCallback={(order) => cbSetOrderToCancel(order)}
                printOrderCallback={cbPrintOrder}
              />
            </PaginateData>
          </div>
        )}
        <BooleanResponseDialog
          open={!!orderToCancel}
          yesCallback={cbCancelOrder}
          noCallback={() => cbSetOrderToCancel()}
        >
          <>{orderToCancel && t("messages.confirmOrderCancel")}</>
        </BooleanResponseDialog>
        {w18ApiResponse && <Spinner />}
      </div>
      </>
    )}
    </>
  );
};

export default ListOrdersB2BSkin;
