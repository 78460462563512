import { useLocation } from "react-router";

/**
 * Hook for reading query params from URL
 * 
 * Usage:
 *  const query = useQuery();
 *  const queryParam = query.get("queryParam");
 */
export function useQuery() {
  return new URLSearchParams(useLocation().search);
}