import CreateSupplementDialog from "@src/components/supplements/CreateSupplementDialog";
import EditSupplementDialog from "@src/components/supplements/EditSupplementDialog";
import ListSupplementsTable from "@src/components/supplements/ListSupplementsTable";
import BooleanResponseDialog from "@src/components/ui/BooleanResponseDialog";
import NewButton from "@src/components/ui/buttons/NewButton";
import PaginateData from "@src/components/ui/PaginateData";
import Spinner from "@src/components/ui/Spinner";
import { useToggle } from "@src/helpers/hooks/useToggle";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { CommonRoutesStrings } from "@src/helpers/stringHelpers";
import SupplementService from "@src/services/supplementService";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const ListSupplementsPage = () => {
  const [supplements, setSupplements] = useState();
  const [showCreateSupplementDialog, setShowCreateSupplementDialog] = useState(false);
  const [supplementToEdit, setSupplementToEdit] = useState();
  const [supplementToDelete, setSupplementToDelete] = useState();
  const [w18ApiResponse, setW18ApiResponse] = useToggle();
  const { t, ready: translationsReady } = useTranslation("pages/supplements/list");
  const history = useHistory();
  const SUPPLEMENTS_PER_PAGE = 5;

  const fetchSupplements = async () => {
    try {
      setSupplements(await SupplementService.index());
    } catch (error) {
      createNotification(NotificationTypes.error, t("messages.genericRequestError"));
      history.push(CommonRoutesStrings.homePath);
    }
  };

  useEffect(() => {
    translationsReady && fetchSupplements();
    // eslint-disable-next-line
  }, [translationsReady]);

  const deleteSupplement = async () => {
    setW18ApiResponse();

    try {
      await SupplementService.destroy(supplementToDelete.id);
      createNotification(NotificationTypes.success, t("messages.deleteAction.ok"));
      await fetchSupplements();
      setSupplementToDelete();
    } catch (error) {
      createNotification(NotificationTypes.error, t("messages.deleteAction.ko"));
    } finally {
      setW18ApiResponse();
    }
  };

  const handleSupplementCreation = async () => {
    await fetchSupplements();
    setShowCreateSupplementDialog(false);
  };

  const handleSupplementModification = async () => {
    await fetchSupplements();
    setSupplementToEdit();
  };

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <>
          <div className="flex justify-center items-center mt-2">
            <NewButton onClick={() => setShowCreateSupplementDialog(true)} />
          </div>
          {!supplements ? (
            <Spinner />
          ) : (
            <div className="grid grid-cols-1 place-items-center max-w-5xl mx-auto">
              <PaginateData
                data={supplements}
                emptyDataArrayMsg={t("messages.noSupplementsToList")}
                perPage={SUPPLEMENTS_PER_PAGE}
              >
                <ListSupplementsTable
                  editSupplementCallback={supplement => setSupplementToEdit(supplement)}
                  deleteSupplementCallback={supplement => setSupplementToDelete(supplement)}
                />
              </PaginateData>
            </div>
          )}
          <CreateSupplementDialog
            open={showCreateSupplementDialog}
            handler={() => setShowCreateSupplementDialog()}
            handleSupplementCreation={handleSupplementCreation}
          />
          <EditSupplementDialog
            supplement={supplementToEdit}
            handler={() => setSupplementToEdit()}
            handleSupplementModification={handleSupplementModification}
          />
          <BooleanResponseDialog
            open={!!supplementToDelete}
            noCallback={() => setSupplementToDelete()}
            yesCallback={deleteSupplement}
            showLoadingSpinner={w18ApiResponse}
          >
            <p>
              <Trans
                t={t}
                i18nKey="messages.deleteAction.modal"
                values={{ supplement: supplementToDelete?.name }}
                components={{ 1: <i /> }}
              />
            </p>
          </BooleanResponseDialog>
        </>
      )}
    </>
  );
};

export default ListSupplementsPage;
