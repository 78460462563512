import { stringDateFormat } from "@src/helpers/dateHelpers";
import React from "react";
import { useTranslation } from "react-i18next";
import { emptyArray, listArrayElemsInString } from "@src/helpers/arrayHelpers";
import { formatHour } from "@src/helpers/localeHelpers";
import Spinner from "@src/components/ui/Spinner";
import BackNextSubmitButtons from "@src/components/products/product_wizard/BackNextSubmitButtons";
import PriceSeletionDetails from "@src/components/products/product_wizard/PriceSelectionDetails";
import { Card, CardBody, CardHeader, Typography } from "@material-tailwind/react";

const AddTimesliceSummary = ({ currentStep, numberSteps, date, timeslice, callbackBackClicked, callbackNextClicked, submissionErrors, showLoadingSpinner }) => {
  const { t, ready: translationsReady } = useTranslation([
    "components/products/edit/addTimeslice",
    "components/products/create/prices",
    "common/common"
  ]);
  
  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <Card className="mx-auto max-w-5xl mt-10">
          <CardHeader
            color="teal"
            variant="gradient"
            className="flex py-10 px-2 place-content-center"
          >
            <Typography
              variant="h4"
              color="white"
              className="text-center"
            >{t("labels.timesliceSummary.title")}</Typography>
          </CardHeader>
          <CardBody>
            {!emptyArray(submissionErrors) &&
              <div className="bg-red-300 border border-red-500 rounded p-2">
                <p className="font-bold">{t("labels.timesliceSummary.errorBoxDescription")}</p>
                {submissionErrors.map((error, index) => <p key={`submissionError-${index}`}>{error}</p>)}
              </div>
            }
            <ul className="list-inside list-disc">
              <li>{t("labels.timesliceSummary.date", { date: stringDateFormat(date.date) })}</li>
              <li>{t("labels.timesliceSummary.duration", { duration: timeslice.duration })}</li>
              <li>{t("labels.timesliceSummary.quota", { quota: timeslice.quota })}</li>
              <li>{t("labels.timesliceSummary.beginsAt", { hours: listArrayElemsInString(timeslice.times.map(t => formatHour(t))) })}</li>
              <li>{t("labels.timesliceSummary.languages", { languages: listArrayElemsInString(timeslice.languages) })}</li>
              <li>{t("labels.timesliceSummary.prices")}</li>
              <div className="flex mx-auto max-w-md">
                <PriceSeletionDetails prices={timeslice.prices} />
              </div>
            </ul>
            <BackNextSubmitButtons
              cbBackButton={callbackBackClicked}
              cbSubmitButton={callbackNextClicked}
              currentStep={currentStep}
              numberSteps={numberSteps}
              showLoadingSpinnerSubmitButton={showLoadingSpinner}
            />
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default AddTimesliceSummary;