import React from "react";
import { useTranslation } from "react-i18next";
import { dateToStringDDMMYYYYHHMMSS } from "@src/helpers/dateHelpers";
import Spinner from "@src/components/ui/Spinner";
import TableCard from "@src/components/ui/TableCard";
import EyeButton from "@src/components/ui/buttons/EyeButton";
import PencilButton from "@src/components/ui/buttons/PencilButton";
import TrashButton from "@src/components/ui/buttons/TrashButton";

const ListNewslettersTable = ({ currentPageData : newsletterList,title, showNewsletterBodyCallback, editNewsletterCallback, deleteNewsletterCallback }) => {
  const { t, ready: translationsReady } = useTranslation(["components/newsletters/listTable", "common/common", "common/list"]);

  const getHeadersArray = () => Object.values(t("headers")).concat(t("actionsTableHeader", { ns: "common/list" }));

  const formatDataMatrix = () => newsletterList.map(n => [
    n.id,
    n.number,
    n.title,
    n.createdAt ? dateToStringDDMMYYYYHHMMSS(n.createdAt) : t("notAvailable", { ns: "common/common" }),
    n.sentAt ? dateToStringDDMMYYYYHHMMSS(n.sentAt) : t("notAvailable", { ns: "common/common" }),
    <>
      <EyeButton
        onClick={() => showNewsletterBodyCallback(n)}
      />
      <PencilButton
        onClick={() => editNewsletterCallback(n)}
      />
      <TrashButton
        onClick={() => deleteNewsletterCallback(n)}
      />
    </>
  ]);
    

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
          title={title}
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

export default ListNewslettersTable;