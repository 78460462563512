import { Dialog } from "@material-tailwind/react";
import React from "react";
import CloseButton from "@src/components/ui/buttons/CloseButton";
import Button from "@src/components/ui/buttons/Button";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";

const ShowBannerDialog = ({ banner, backCallback, selectBannerCallback }) => {
  const { t, ready: translationsReady } = useTranslation("common/list");

  return !translationsReady ? (
    <Spinner />
  ) : (
    <Dialog open={!!banner} handler={backCallback}>
      <div className="grid grid-cols-1 m-2 space-y-2">
        <div className="p-2 border rounded-md bg-gray-50 overflow-auto max-h-[70vh]">
          {banner && (
            <>
              {/* eslint-disable-next-line */}
              <img src={banner.picture_url} />
            </>
          )}
        </div>
        <div
          className={`flex ${
            selectBannerCallback ? "justify-between mx-4" : "justify-center"
          }`}
        >
          <CloseButton onClick={backCallback} />
          {selectBannerCallback && (
            <Button onClick={() => selectBannerCallback(banner)}>
              {t("select")}
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default ShowBannerDialog;