import { faBoxes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import { Card, CardBody, CardFooter, CardHeader, Typography } from "@material-tailwind/react";
import Button from "@src/components/ui/buttons/Button";

const EditServiceMainScreen = ({ editBasicInfoCallback, editConfigurationsCallback, readOnly }) => {
  const { t, ready: translationsReady } = useTranslation(["pages/services/edit", "common/list"]);

  const cards = [
    {
      icon: faInfoCircle,
      header: t("labels.mainComponent.editBasicInfo.title"),
      description: t("labels.mainComponent.editBasicInfo.description"),
      selectCallback: editBasicInfoCallback,
    },
    {
      icon: faBoxes,
      header: t("labels.mainComponent.editConfigurations.title"),
      description: t("labels.mainComponent.editConfigurations.description"),
      selectCallback: editConfigurationsCallback,
    },
  ];

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="container max-w-2xl mx-auto space-y-2">
          <div className="flex flex-col md:flex-row md:space-x-2">
            {cards.map((card, index) =>
              <Card key={index} className="mt-8 max-w-xs m-auto">
                <CardHeader
                  color="light-blue"
                  variant="gradient"
                  className="flex py-5 px-2 place-content-center flex-col space-y-2"
                >
                  <FontAwesomeIcon className="flex mx-auto" icon={card.icon} size="3x" color="white" />
                  <Typography
                    variant="h4"
                    color="white"
                    className="text-center"
                  >{card.header}</Typography>
                </CardHeader>
                <CardBody>{card.description}</CardBody>
                <CardFooter>
                  <div className="flex place-content-center">
                    <Button
                      color="green"
                      onClick={card.selectCallback}              
                    >{t("select", { ns: "common/list" })}</Button>
                  </div>
                </CardFooter>
              </Card>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default EditServiceMainScreen;