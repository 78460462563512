import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faEuroSign, faHandHolding, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from '@src/components/ui/Spinner';
import React from 'react';
import { useTranslation } from 'react-i18next';

const BecomeAPartnerPage = () => {
  const { t, ready: translationsReady } = useTranslation(["pages/b2c/staticHelpPages/becomeAPartner", "components/b2c/footer"]);

  return (
    <div className="bg-white py-[67px] px-[135.9px] grid grid-cols-1 justify-items-center sm:text-sm md:text-base lg:text-lg xl:text-xl montserrat-medium-shark  w-full mx-auto max-w-[1440px] space-y-8">
      {!translationsReady ? <Spinner /> : (
        <>
          <p className="self-start font-extrabold  sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-[#e30745]">{t("discoverMoreHere.becomeAPartner", { ns: "components/b2c/footer" })}</p>
          <p className="sm:text-base md:text-lg lg:text-xl xl:text-2xl">{t("qualityPrice")}</p>
          <FontAwesomeIcon icon={faCheckCircle} color="#FFD000" size="6x" />
          <p className="sm:text-base md:text-lg lg:text-xl xl:text-2xl">{t("noWaitTickets")}</p>
          <FontAwesomeIcon icon={faEuroSign} color="#FFD000" size="6x" />
          <p className="sm:text-base md:text-lg lg:text-xl xl:text-2xl">{t("realTimePrices")}</p>
          <div className="grid grid-cols-1 justify-items-center">
            <FontAwesomeIcon icon={faUser} color="#FFD000" size="2x" />
            <FontAwesomeIcon icon={faHandHolding} color="#FFD000" className="-mt-16" size="6x" />
          </div>
          <p className="sm:text-base md:text-lg lg:text-xl xl:text-2xl">{t("personalisedCustomerSupport")}</p>
        </>
      )}
    </div>
  );
};
 
export default BecomeAPartnerPage;