import { IconButton as ParentButton } from '@material-tailwind/react';
import React from 'react';
import { useHistory } from "react-router-dom";

const IconLinkButton = ({ className, to: routeToLink, variant, children, ...props }) => {
  const history = useHistory();

  return (
    <ParentButton
      className={"disabled:opacity-50 disabled:shadow-none " + className}
      variant={variant ?? "gradient"}
      onClick={() => history.push(routeToLink)}
      {...props}
    >{children}</ParentButton>
  );
};

export default IconLinkButton;