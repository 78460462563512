import TrashButton from "@src/components/ui/buttons/TrashButton";
import TableCard from "@src/components/ui/TableCard";
import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import { isServicesProvider } from "@src/helpers/userHelpers";

const LocationsTable = ({
  currentPageData: locations,
  deleteLocationCallback,
}) => {
  const { t, ready: translationsReady } = useTranslation([
    "components/locations/listTable",
    "pages/locations/list",
    "common/list",
  ]);

  const getHeadersArray = () =>
    Object.values(t("headers"))
      .concat(t("actionsTableHeader", { ns: "common/list" }))
      .filter((h) => (h !== t("headers").name && isServicesProvider()) || !isServicesProvider());

  const formatDataMatrix = () =>
    locations.map((l) =>
      !isServicesProvider()
        ? [
            l.id,
            l.zip_code,
            l.email,
            <>
              <TrashButton
                onClick={() =>
                  deleteLocationCallback({
                    serviceProvider: l.serviceProvider,
                    location: l.location,
                  })
                }
              />
            </>,
          ]
        : [
            l.id,
            l.zip_code,
            <>
              <TrashButton
                onClick={() =>
                  deleteLocationCallback({
                    serviceProvider: l.serviceProvider,
                    location: l.location,
                  })
                }
              />
            </>,
          ]
    );

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <TableCard
          title={t("title", { ns: "pages/locations/list" })}
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

export default LocationsTable;
