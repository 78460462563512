import {Field, Form, Formik} from "formik";
import React from "react";
import {useTranslation} from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import FileInput from "@src/components/forms/FileInput";
import BasicFormTemplate, { FooterType } from "@src/components/forms/BasicFormTemplate";
import { useState } from "react";
import { Dialog } from "@material-tailwind/react";
import BannerImageDetails from "@src/components/banners/BannerImageDetails";
import Button from "@src/components/ui/buttons/Button";
import FormikTextInput from "../forms/FormikTextInput";


const BannerInitialValues = {
  picture: "",
  description: "",
  enabled: true
};

const BannerForm = ({
                      initialValues,
                      bannerSchema,
                      title,
                      setSelectedFile,
                      handleSubmit,
                      backCallback,
                      showLoadingSpinnerSubmitButton
                    }) => {
  const [showBannerImageDialog, setShowBannerImageDialog] = useState(false);
  const {t, ready: translationsReady} = useTranslation(["components/banners/form", "common/form"]);

  const toggleBannerImageDialog = () => setShowBannerImageDialog(b => !b);

  return (
    <>
      {!translationsReady ? <Spinner/> : (
        <>
          <Formik
            initialValues={initialValues ? { description: initialValues.description, enabled: initialValues.enabled } : BannerInitialValues}
            validationSchema={bannerSchema}
            onSubmit={handleSubmit}
          >
            <Form encType="multipart/form-data">
            <BasicFormTemplate
              className="mt-8"
              formTitle={title}
              showLoadingSpinner={showLoadingSpinnerSubmitButton}
              footerType={FooterType.BACK_SUBMIT_BUTTONS}
              backCallback={backCallback}
            >
                <div className="my-6 px-4">
                  <FormikTextInput className="mt-4 mb-4" label={t("labels.description")} name="description" />
                  {initialValues &&
                    <Button
                      color="gray"
                      onClick={toggleBannerImageDialog}
                    >{t("labels.showCurrentBannerImage")}</Button>
                  }
                  <FileInput
                  className="my-6 px-4"
                    name="picture"
                    onFileSelectSuccess={(file) => setSelectedFile(file)}
                    onFileSelectError={({error}) => alert(error)}
                  />
                  <div className="my-6 px-4">
                    <Field name="enabled" type="checkbox"/>
                    <label className="text-gray-700">{t("labels.enabledCheckbox")}</label>
                  </div>
                </div>
              </BasicFormTemplate>
            </Form>
          </Formik>
          <Dialog
            open={showBannerImageDialog}
            handler={toggleBannerImageDialog}
          >
            <>
              {showBannerImageDialog &&
                <BannerImageDetails
                  banner={initialValues}
                  backCallback={toggleBannerImageDialog}
                />
              }
            </>
          </Dialog>
        </>
        )}
        </>
        );
      };

      export default BannerForm;