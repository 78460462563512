import PencilButton from "@src/components/ui/buttons/PencilButton";
import TrashButton from "@src/components/ui/buttons/TrashButton";
import TableCard from "@src/components/ui/TableCard";
import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";

const ListPeopleSegmentsTable = ({ currentPageData : peopleSegments, editPeopleSegmentCallback, deletePeopleSegmentCallback, showActionsColumn }) => {
  const { t, ready: translationsReady } = useTranslation(["components/products/peopleSegments/listTable", "pages/products/peopleSegments/list"]);

  const getHeadersArray = () => Object.values(t("headers"));

  const formatDataMatrix = () => peopleSegments.map(ps => {
    let arr = [ ps.id, ps.name, ps.quantity ];

    if (showActionsColumn) arr.push(
      <>
        <PencilButton onClick={() => editPeopleSegmentCallback(ps)} />
        <TrashButton onClick={() => deletePeopleSegmentCallback(ps)} />
      </>
    );

    return arr;    
  });   

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
          title={t("title", { ns: "pages/products/peopleSegments/list" })}
          headersArray={showActionsColumn ? getHeadersArray() : getHeadersArray().slice(0, -1)}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

export default ListPeopleSegmentsTable;