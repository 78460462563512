import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LocationsTable from "@src/components/locations/LocationsTable";
import Button from "@src/components/ui/buttons/Button";
import PaginateData from "@src/components/ui/PaginateData";
import LocationService from "@src/services/locationService";
import {
  createNotification,
  NotificationTypes,
} from "@src/helpers/notificationHelpers";
import { getCurrentUserData } from "@src/helpers/localStorageHelpers";
import { isServicesProvider } from "@src/helpers/userHelpers";
import { CommonRoutesStrings } from "@src/helpers/stringHelpers";
import CreateLocationDialog from "@src/components/locations/CreateLocationDialog";
import Spinner from "@src/components/ui/Spinner";
import { useTranslation } from "react-i18next";
import BooleanResponseDialog from "@src/components/ui/BooleanResponseDialog";
import HistoryBackButton from "@src/components/ui/buttons/HistoryBackButton";

const LocationsPage = () => {
  const [locations, setLocations] = useState();
  const [showModalCreateLocation, setShowModalCreateLocation] = useState(false);
  const [LocationToDelete, setLocationToDelete] = useState();
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation([
    "pages/locations/list",
    "common/list",
    "common/common",
  ]);
  const history = useHistory();
  const LOCATIONS_PER_PAGE = 5;

  const fetchLocations = async () => {
    try {
      if (isServicesProvider()) {
        setLocations(
          locationMap(
            (await LocationService.getAllLocationById(getCurrentUserData().id))
              .data
          )
        );
      } else {
        // Admin
        setLocations(
          locationMap((await LocationService.getAllLocation()).data)
        );
      }
    } catch (error) {
      createNotification(
        NotificationTypes.error,
        t("messages.genericRequestError")
      );
      history.push(CommonRoutesStrings.homePath);
    }
  };

  useEffect(() => {
    translationsReady && fetchLocations();
    // eslint-disable-next-line
  }, [translationsReady]);

  const deleteLocation = async () => {
    setW18ApiResponse((b) => !b);

    try {
      await LocationService.delLocationServiceProvider(
        LocationToDelete.serviceProvider.id,
        LocationToDelete.location.id
      );
      createNotification(
        NotificationTypes.success,
        t("messages.deleteAction.ok")
      );

      setLocations(
        locations.filter(
          (location) =>
            (location.serviceProvider.id ===
              LocationToDelete.serviceProvider.id &&
              location.id !== LocationToDelete.location.id) ||
            location.serviceProvider.id !== LocationToDelete.serviceProvider.id
        )
      );
      setLocationToDelete();
    } catch (error) {
      createNotification(
        NotificationTypes.error,
        t("messages.deleteAction.ko")
      );
    } finally {
      setW18ApiResponse((b) => !b);
    }
  };

  const finishLocationCreation = async () => {
    await fetchLocations();
    setShowModalCreateLocation(false);
  };

  const locationMap = (locations) => {
    const rows = [];
    locations.map((serviceProvider) =>
      serviceProvider.location.map((location) =>
        rows.push({
          id: location.id,
          zip_code: location.name + " (" + location.zip_code + ")",
          email: serviceProvider.email,
          serviceProvider,
          location,
        })
      )
    );
    return rows;
  };

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <>
          <div className="flex justify-center items-center mt-2 space-x-2">
            <HistoryBackButton size="md" />
            <Button
              color="green"
              onClick={() => setShowModalCreateLocation(true)}
            >
              {t("new", { ns: "common/common" })}
            </Button>
          </div>
          {!locations ? (
            <Spinner />
          ) : (
            <div className="grid grid-cols-1 place-items-center max-w-3xl mx-auto">
              <PaginateData
                data={locations}
                emptyDataArrayMsg={t("messages.noCategoriesToList")}
                perPage={LOCATIONS_PER_PAGE}
              >
                <LocationsTable
                  deleteLocationCallback={(location) =>
                    setLocationToDelete(location)
                  }
                />
              </PaginateData>
            </div>
          )}
          <CreateLocationDialog
            open={showModalCreateLocation}
            handler={() => setShowModalCreateLocation(false)}
            handleLocationCreation={finishLocationCreation}
            data={locations}
          />
          <BooleanResponseDialog
            open={!!LocationToDelete}
            noCallback={() => setLocationToDelete()}
            yesCallback={deleteLocation}
            showLoadingSpinner={w18ApiResponse}
          >
            {t("deleteModalMessage", { ns: "common/list" })}
          </BooleanResponseDialog>
        </>
      )}
    </>
  );
};

export default LocationsPage;
