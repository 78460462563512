import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { createNotification, delayedRedirect, NotificationTypes } from "@src/helpers/notificationHelpers";
import { isThirdPartyProduct, updateProductPrice } from "@src/helpers/productHelpers";
import { DefinedRoutesStrings } from "@src/helpers/stringHelpers";
import Spinner from "@src/components/ui/Spinner";
import SingleProductPriceForm from "@src/components/products/edit_product_data/add_edit_price/SingleProductPriceForm";

const EditPrice = ({ productType, date, timeslice, price, backCallback }) => {
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { id : productId } = useParams();
  const { t, ready: translationsReady } = useTranslation("components/products/edit/editPrice");

  const alreadySelectedPeopleSegments = timeslice.prices.reduce((selectedPeopleSegments, p) => {
    if (p.id !== price.id) selectedPeopleSegments.push(p.type_client);
    return selectedPeopleSegments;
  }, []);

  const quotaLeftToAssign = timeslice.prices.reduce((remainingQuota, price) => remainingQuota - price.quota * price.type_client.quantity, timeslice.quota);

  const submitPriceData = async (values, _) => {
    const priceData = {
      price: Number(values.price),
      quota: values.quota ? Number(values.quota) : null,
    };

    setW18ApiResponse(b => !b);

    try {
      await updateProductPrice(productId, date.id, timeslice.id, price.id, priceData);
      createNotification(NotificationTypes.success, t("successfulPriceUpdate"));
      const path = isThirdPartyProduct(productType) ? DefinedRoutesStrings.editThirdPartyProductPath : DefinedRoutesStrings.editOwnProductPath;
      delayedRedirect(path.replace(":id", productId));
    } catch (err) {
      createNotification(NotificationTypes.error, t("genericErrorPriceUpdate"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };
  
  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="container max-w-sm mx-auto mt-10">
          <SingleProductPriceForm
            title={t("title")}
            alreadySelectedPeopleSegments={alreadySelectedPeopleSegments}
            quotaLeftToAssign={quotaLeftToAssign}
            editingPrice={true}
            initialValues={price}
            backCallback={backCallback}
            submitCallback={submitPriceData}
            showLoadingSpinner={w18ApiResponse}
          />
        </div>
      )}
    </>
  );
};

export default EditPrice;