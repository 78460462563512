import React from "react";

const Button = ({ className : additionalClasses, children, ...props }) =>
  <button
    type="button"
    className={"px-[25px] py-auto rounded-[34px] h-[40px] shadow-md hover:shadow-none disabled:shadow-none "
      + "transition-all duration-75 hover:brightness-90 disabled:cursor-default "
      + `disabled:opacity-50 disabled:filter-none font-['Montserrat'] ${additionalClasses ?? ""}`}
    {...props}
  >
    {children}
  </button>;

export default Button;