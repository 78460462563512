import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import IconButton from "@src/components/ui/buttons/IconButton";

const TrashButton = props =>
  <IconButton
    color="red"
    variant="gradient"
    {...props}
  ><FontAwesomeIcon icon={faTrashAlt} /></IconButton>;

export default TrashButton;