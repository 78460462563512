import Spinner from '@src/components/ui/Spinner';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const GDPRPage = () => {
  const { t, ready: translationsReady } = useTranslation(["pages/b2c/staticHelpPages/gdpr", "components/b2c/footer"]);

  return (
    <div className="bg-white py-[67px] px-[135.9px] grid grid-cols-1 sm:text-sm md:text-base lg:text-lg xl:text-xl montserrat-medium-shark mx-auto w-full max-w-[1440px]">
      {!translationsReady ? <Spinner /> : (
        <>
          <p className="self-start font-extrabold sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-[#e30746]">{t("legalMentions.confidentialityPolicy", { ns: "components/b2c/footer" })}</p>
          <p className="mt-8 whitespace-pre-line leading-relaxed">
            <Trans t={t} i18nKey="mainContent.firstParagraph" components={{ 1: <strong /> }} />
          </p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("mainContent.secondParagraph")}</p>
          <table className="mt-2" style={{ border: "1px solid black" }}>
            <tr>
              <td className="p-2 border-r border-black">{t("mainContent.table.dataManager.title")}</td>
              <td className="p-2 whitespace-pre-line leading-relaxed">{t("mainContent.table.dataManager.content")}</td>
            </tr>
            <tr className="border-t border-black">
              <td className="p-2 border-r border-black">{t("mainContent.table.dataProcessing.title")}</td>
              <td className="p-2 whitespace-pre-line leading-relaxed">
                <Trans
                  t={t}
                  i18nKey="mainContent.table.dataProcessing.content"
                  components={{
                    1: <ul className="list-disc ml-8" />,
                    2: <li />,
                  }}
                />
              </td>
            </tr>
            <tr className="border-t border-black">
              <td className="p-2 border-r border-black">{t("mainContent.table.thirdPartyProcessing.title")}</td>
              <td className="p-2 whitespace-pre-line leading-relaxed">{t("mainContent.table.thirdPartyProcessing.content")}</td>
            </tr>
            <tr className="border-t border-black">
              <td className="p-2 border-r border-black">{t("mainContent.table.dataCollection.title")}</td>
              <td className="p-2 whitespace-pre-line leading-relaxed">
                <Trans
                  t={t}
                  i18nKey="mainContent.table.dataCollection.content"
                  components={{
                    1: <ul className="list-disc ml-8" />,
                    2: <li />,
                  }}
                />
              </td>
            </tr>
            <tr className="border-t border-black">
              <td className="p-2 border-r border-black">{t("mainContent.table.yourRights.title")}</td>
              <td className="p-2 whitespace-pre-line leading-relaxed">{t("mainContent.table.yourRights.content")}</td>
            </tr>
            <tr className="border-t border-black">
              <td className="p-2 border-r border-black">{t("mainContent.table.timeDataKept.title")}</td>
              <td className="p-2 whitespace-pre-line leading-relaxed">
                <Trans
                  t={t}
                  i18nKey="mainContent.table.timeDataKept.content"
                  components={{
                    1: <ul className="list-disc ml-8" />,
                    2: <li />,
                  }}
                />
              </td>
            </tr>
          </table>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("mainContent.thirdParagraph")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("mainContent.fourthParagraph")}</p>
          <p className="mt-6 font-extrabold sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-[#e30746]">{t("cookiesPolicy.title")}</p>
          <p className="mt-8 font-extrabold sm:text-lg md:text-xl lg:text-2xl xl:text-3xl text-[#e30746]">{t("cookiesPolicy.intro.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("cookiesPolicy.intro.content")}</p>
          <p className="mt-6 font-extrabold sm:text-lg md:text-xl lg:text-2xl xl:text-3xl text-[#e30746]">{t("cookiesPolicy.cookieDefinition.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("cookiesPolicy.cookieDefinition.content")}</p>
          <p className="mt-6 font-extrabold  sm:text-lg md:text-xl lg:text-2xl xl:text-3xl text-[#e30746]">{t("cookiesPolicy.cookieUseAuthorization.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("cookiesPolicy.cookieUseAuthorization.content")}</p>
          <p className="mt-6 font-extrabold  sm:text-lg md:text-xl lg:text-2xl xl:text-3xl text-[#e30746]">{t("cookiesPolicy.cookieTypes.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("cookiesPolicy.cookieTypes.intro")}</p>
          <p className="mt-6 font-extrabold  sm:text-base md:text-lg lg:text-xl xl:text-2xl text-[#e30746]">{t("cookiesPolicy.cookieTypes.types.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">
            <Trans
              t={t}
              i18nKey="cookiesPolicy.cookieTypes.types.content"
              components={{
                1: <ul className="list-disc ml-8" />,
                2: <li />,
                3: <strong />,
              }}
            />
          </p>
          <p className="mt-6 font-extrabold sm:text-lg md:text-xl lg:text-2xl xl:text-3xl text-[#e30746]">{t("cookiesPolicy.usedCookies.title")}</p>
          <p className="mt-4 font-extrabold sm:text-base md:text-lg lg:text-xl xl:text-2xl text-[#e30746]">{t("cookiesPolicy.usedCookies.own.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("cookiesPolicy.usedCookies.own.content")}</p>
          <p className="mt-4 font-extrabold sm:text-base md:text-lg lg:text-xl xl:text-2xl text-[#e30746]">{t("cookiesPolicy.usedCookies.thirdPartyAnalytics.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("cookiesPolicy.usedCookies.thirdPartyAnalytics.content")}</p>
          <p className="mt-6 font-extrabold sm:text-lg md:text-xl lg:text-2xl xl:text-3xl text-[#e30746]">{t("cookiesPolicy.browserSettings.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("cookiesPolicy.browserSettings.content")}</p>
          <p className="mt-6 font-extrabold sm:text-lg md:text-xl lg:text-2xl xl:text-3xl text-[#e30746]">{t("cookiesPolicy.consentWithdrawal.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("cookiesPolicy.consentWithdrawal.content")}</p>
          <p className="mt-6 font-extrabold sm:text-lg md:text-xl lg:text-2xl xl:text-3xl text-[#e30746]">{t("cookiesPolicy.cookieSettingsModification.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("cookiesPolicy.cookieSettingsModification.intro")}</p>
          <p className="mt-4 font-extrabold sm:text-base md:text-lg lg:text-xl xl:text-2xl text-[#e30746]">{t("cookiesPolicy.cookieSettingsModification.iexplorer.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">
            <Trans
              t={t}
              i18nKey="cookiesPolicy.cookieSettingsModification.iexplorer.content"
              components={{
                1: <ul className="list-disc ml-8" />,
                2: <li />,
              }}
            />
          </p>
          <p className="mt-4 font-extrabold sm:text-base md:text-lg lg:text-xl xl:text-2xl text-[#e30746]">{t("cookiesPolicy.cookieSettingsModification.firefox.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("cookiesPolicy.cookieSettingsModification.firefox.content")}</p>
          <p className="mt-4 font-extrabold sm:text-base md:text-lg lg:text-xl xl:text-2xl text-[#e30746]">{t("cookiesPolicy.cookieSettingsModification.chrome.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("cookiesPolicy.cookieSettingsModification.chrome.content")}</p>
          <p className="mt-4 font-extrabold sm:text-base md:text-lg lg:text-xl xl:text-2xl text-[#e30746]">{t("cookiesPolicy.cookieSettingsModification.safari.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("cookiesPolicy.cookieSettingsModification.safari.content")}</p>
          <p className="mt-4 font-extrabold sm:text-base md:text-lg lg:text-xl xl:text-2xl text-[#e30746]">{t("cookiesPolicy.cookieSettingsModification.ios.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("cookiesPolicy.cookieSettingsModification.ios.content")}</p>
          <p className="mt-6 font-extrabold sm:text-lg md:text-xl lg:text-2xl xl:text-3xl text-[#e30746]">{t("cookiesPolicy.cookiePolicyChanges.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("cookiesPolicy.cookiePolicyChanges.content")}</p>
          <p className="mt-6 font-extrabold sm:text-lg md:text-xl lg:text-2xl xl:text-3xl text-[#e30746]">{t("cookiesPolicy.contactUs.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("cookiesPolicy.contactUs.content")}</p>
        </>
      )}
    </div>
  );
};
 
export default GDPRPage;