import React, { useState } from "react";
import { useEffect } from "react";
import Spinner from "@src/components/ui/Spinner";
import { useTranslation } from "react-i18next";
import {
  CardHeader,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  CardBody,
  Card,
  Typography,
} from "@material-tailwind/react";
import PaginateData from "@src/components/ui/PaginateData";
import {
  createNotification,
  NotificationTypes,
} from "@src/helpers/notificationHelpers";
import ProductService from "@src/services/products/productService";
import ListProductsTable from "@src/components/dashboard/ListProductsTable";
import DateSelector from "@src/components/dashboard/DateSelector";
import StatisticsChart from "@src/components/charts/statistics-chart";
import chartsConfig from "@src/configs/charts-config";
import OrderService from "@src/services/orders/orderService";
import { OrderStatusStrings } from "@src/helpers/stringHelpers";
import { isEmptyArray } from "formik";
import { isProvider, isServicesProvider } from "@src/helpers/userHelpers";
import { getCurrentUserData } from "@src/helpers/localStorageHelpers";
import CreditService from "@src/services/creditService";
import StatisticsCard from "@src/components/cards/statistics-card";
import {
  BanknotesIcon,
  ClockIcon,
} from "@heroicons/react/24/solid";
import { formatPercentage, formatPrice } from "@src/helpers/localeHelpers";

const USER_ID =
  isServicesProvider() || isProvider() ? getCurrentUserData().id : false;
const DashboardPage = () => {
  const { t, ready: translationsReady } = useTranslation([
    "pages/dashboard/page",
    "common/orderStatuses",
  ]);

  const [products, setProducts] = useState();
  const [credits, setCredits] = useState();
  const [productsChart, setProductsChart] = useState([]);
  const [statisticsChartsData, setStatisticsChartsData] = useState([]);
  const [statisticsCardsData, setStatisticsCardsData] = useState([]);
  const [orders, setOrders] = useState();
  const [regularConfigurations, setRegularConfigurations] = useState([]);
  const PRODUCTS_PER_PAGE = 5;
  const PRODUCTS = {
    VALID: 1,
    NOT_SERVICES: 2,
    THIRDPARTY: 3,
  };

  useEffect(() => {
    const cardsData = [
      {
        color: "blue",
        icon: BanknotesIcon,
        title: t("credits.pending"),
        value: formatPrice(
          credits
            ?.filter((c) => !c.deniedAt && !c.validatedAt)
            .map((c) => 1 * c.credit)
            .reduce((prev, curr) => prev + curr, 0)
        ),
        footer: {
          color: "text-green-500",
          value: formatPercentage(
            (credits
              ?.filter((c) => !c.deniedAt && !c.validatedAt)
              .map((c) => 1 * c.credit)
              .reduce((prev, curr) => prev + curr, 0) /
              credits
                ?.map((c) => 1 * c.credit)
                .reduce((prev, curr) => prev + curr, 0)) 
          ),
          label: t("credits.footerCard"),
        },
      },
      {
        color: "green",
        icon: BanknotesIcon,
        title: t("credits.approved"),
        value: formatPrice(
          credits
            ?.filter((c) => !c.validatedAt)
            .map((c) => 1 * c.credit)
            .reduce((prev, curr) => prev + curr, 0)
        ),
        footer: {
          color: "text-green-500",
          value: formatPercentage(
            (credits
              ?.filter((c) =>  !c.validatedAt)
              .map((c) => 1 * c.credit)
              .reduce((prev, curr) => prev + curr, 0) /
              credits
                ?.map((c) => 1 * c.credit)
                .reduce((prev, curr) => prev + curr, 0))
          ),
          label: t("credits.footerCard"),
        },
      },
      {
        color: "red",
        icon: BanknotesIcon,
        title: t("credits.denied"),
        value: formatPrice(
          credits
            ?.filter((c) => !c.deniedAt)
            .map((c) => 1 * c.credit)
            .reduce((prev, curr) => prev + curr, 0)
        ),
        footer: {
          color: "text-green-500",
          value: formatPercentage(
            (credits
              ?.filter((c) => !c.deniedAt )
              .map((c) => 1 * c.credit)
              .reduce((prev, curr) => prev + curr, 0) /
              credits
                ?.map((c) => 1 * c.credit)
                .reduce((prev, curr) => prev + curr, 0))
          ),
          label: t("credits.footerCard"),
        },
      },
    ];
    const ordersViewsChart = {
      type: "bar",
      height: 220,
      series: [
        {
          name: t("orders"),
          data: [
            orders?.filter((ord) => ord.status === OrderStatusStrings.completed)
              .length,
            orders?.filter((ord) => ord.status === OrderStatusStrings.cancelled)
              .length,
            orders?.filter(
              (ord) => ord.status === OrderStatusStrings.paymentPending
            ).length,
            orders?.filter(
              (ord) => ord.status === OrderStatusStrings.paymentReceived
            ).length,
            orders?.filter(
              (ord) => ord.status === OrderStatusStrings.pendingRefund
            ).length,
          ],
        },
      ],
      options: {
        ...chartsConfig,
        colors: "#fff",
        plotOptions: {
          bar: {
            columnWidth: "16%",
            borderRadius: 5,
          },
        },
        xaxis: {
          ...chartsConfig.xaxis,
          categories: [
            t(OrderStatusStrings.completed, { ns: "common/orderStatuses" }),
            t(OrderStatusStrings.cancelled, { ns: "common/orderStatuses" }),
            t(OrderStatusStrings.paymentPending, {
              ns: "common/orderStatuses",
            }),
            t(OrderStatusStrings.paymentReceived, {
              ns: "common/orderStatuses",
            }),
            t(OrderStatusStrings.pendingRefund, {
              ns: "common/orderStatuses",
            }),
          ],
        },
      },
    };
    const productsViewsChart = {
      type: "bar",
      height: 220,
      series: [
        {
          name: t("product"),
          data:
            isServicesProvider() || isProvider()
              ? [productsChart[0]?.length, productsChart[2]?.length]
              : [
                  productsChart[0]?.length,
                  productsChart[1]?.length,
                  productsChart[2]?.length,
                ],
        },
      ],
      options: {
        ...chartsConfig,
        colors: "#fff",
        plotOptions: {
          bar: {
            columnWidth: "16%",
            borderRadius: 5,
          },
        },
        xaxis: {
          ...chartsConfig.xaxis,
          categories:
            isServicesProvider() || isProvider()
              ? [t("products.active"), t("products.thirdparty")]
              : [
                  t("products.active"),
                  t("products.withoutServices"),
                  t("products.thirdparty"),
                ],
        },
      },
    };
    const CreditViewsChart = {
      type: "bar",
      height: 220,
      series: [
        {
          name: t("credit"),
          data: [
            credits?.filter((c) => c.deniedAt)?.length,
            credits?.filter((c) => c.validatedAt)?.length,
            credits?.filter((c) => !c.deniedAt && !c.validatedAt)?.length,
          ],
        },
      ],
      options: {
        ...chartsConfig,
        colors: "#fff",
        plotOptions: {
          bar: {
            columnWidth: "16%",
            borderRadius: 5,
          },
        },
        xaxis: {
          ...chartsConfig.xaxis,
          categories: [
            t("credits.denied"),
            t("credits.approved"),
            t("credits.pending"),
          ],
        },
      },
    };
    const dataProduct = [
      {
        label: t("products.active"),
        value: PRODUCTS.VALID,
        data: productsChart[0],
      },
      {
        label: t("products.withoutServices"),
        value: PRODUCTS.NOT_SERVICES,
        data: productsChart[1],
      },

      {
        label: t("products.thirdparty"),
        value: PRODUCTS.THIRDPARTY,
        data: productsChart[2],
      },
    ];
    const StatisticsOrdersChartsData = () => {
      setStatisticsCardsData(cardsData);
      if (!isServicesProvider() && !isProvider()) {
        setProducts(dataProduct);
      }
      if (
        (orders || isServicesProvider() || isProvider()) &&
        !isEmptyArray(productsChart)
      ) {
        setStatisticsChartsData(
          !isServicesProvider() && !isProvider()
            ? [
                {
                  color: "red",
                  title: t("orders"),
                  description: t("order.description"),
                  footer: t("order.footer"),
                  chart: ordersViewsChart,
                },
                {
                  color: "blue",
                  title: t("product"),
                  description: t("products.description"),
                  footer: t("products.footer"),
                  chart: productsViewsChart,
                },
                {
                  color: "green",
                  title: t("credit"),
                  description: t("credits.description"),
                  footer: t("credits.footer"),
                  chart: CreditViewsChart,
                },
              ]
            : [
                {
                  color: "blue",
                  title: t("product"),
                  description: t("products.description"),
                  footer: t("products.footer"),
                  chart: productsViewsChart,
                },
                {
                  color: "green",
                  title: t("credit"),
                  description: t("credits.description"),
                  footer: t("credits.footer"),
                  chart: CreditViewsChart,
                },
              ]
        );
      }
    };

     orders && StatisticsOrdersChartsData();
     credits && productsChart  && StatisticsOrdersChartsData();
    // eslint-disable-next-line
  }, [orders, productsChart, credits]);

  useEffect(() => {
    const getRegularConfigurations = async () => {
      try {
        if (!isEmptyArray(regularConfigurations)) {
          if (!isServicesProvider() && !isProvider()) {
            setOrders(await OrderService.index(regularConfigurations[0]));
          }
          setProductsChart([
            await ProductService.indexValidatedProducts(
              false,
              regularConfigurations[0],
              USER_ID
            ),
            await ProductService.indexNotServicesProducts(
              regularConfigurations[0]
            ),
            await ProductService.indexProductsThirdPartyProduct(
              regularConfigurations[0],
              USER_ID
            ),
          ]);
          setCredits(
            await CreditService.findAll(regularConfigurations[0], USER_ID)
          );
        } else {
          setProductsChart([
            await ProductService.indexValidatedProducts(false, false, USER_ID),
            await ProductService.indexNotServicesProducts(),
            await ProductService.indexProductsThirdPartyProduct(false, USER_ID),
          ]);
          setCredits(await CreditService.findAll(false, USER_ID));

          if (!isServicesProvider() && !isProvider()) {
            setOrders(await OrderService.index());
          }
        }
      } catch (error) {
        console.log(error);
        createNotification(
          NotificationTypes.error,
          t("messages.cannotRetrieveProducts")
        );
      }
    };
    regularConfigurations && getRegularConfigurations();
    // eslint-disable-next-line
  }, [regularConfigurations]);

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <div>
          <div className="mt-10 mb-4 flex place-content-center">
            <Card className="mt-7 mb-2">
              <CardHeader color="purple" className="p-4 space-y-2">
                <h2 className="text-white text-center text-2xl">
                  {t("dashboard")}
                </h2>
              </CardHeader>
              <CardBody className="space-x-2">
                <DateSelector
                  configurations={regularConfigurations}
                  setConfigurations={setRegularConfigurations}
                />
              </CardBody>
            </Card>
          </div>
          <div className="mb-20 mt-12  grid gap-y-12 gap-x-12 md:grid-cols-1 xl:grid-cols-3">
            {statisticsCardsData.map(({ icon, title, footer, ...rest }) => (
              <StatisticsCard
                key={title}
                {...rest}
                title={title}
                icon={React.createElement(icon, {
                  className: "w-6 h-6 text-white",
                })}
                footer={
                  <Typography className="font-normal text-blue-gray-600">
                    <strong className={footer.color}>{footer.value}</strong>
                    &nbsp;{footer.label}
                  </Typography>
                }
              />
            ))}
          </div>

          {isEmptyArray(statisticsChartsData) ? (
            <Spinner />
          ) : (
            <div className="mb-6 mt-12 grid gap-y-12 gap-x-6 md:grid-cols-1 xl:grid-cols-3">
              {statisticsChartsData.map((props) => (
                <StatisticsChart
                  key={props.title}
                  {...props}
                  footer={
                    <Typography
                      variant="small"
                      className="flex items-center font-normal text-blue-gray-600"
                    >
                      <ClockIcon
                        strokeWidth={2}
                        className="h-4 w-4 text-inherit"
                      />
                      &nbsp;{props.footer}
                    </Typography>
                  }
                />
              ))}
            </div>
          )}

          {!products ? (
            isServicesProvider() || isProvider() ? (
              ""
            ) : (
              <Spinner className="mt-24 " />
            )
          ) : (
            <Tabs value={PRODUCTS.VALID}>
              <TabsHeader>
                {products.map(({ label, value }) => (
                  <Tab key={value} value={value}>
                    {label}
                  </Tab>
                ))}
              </TabsHeader>
              <TabsBody>
                {products.map(({ label, value, data }) => (
                  <TabPanel key={value} value={value}>
                    <div className="grid grid-cols-1 place-items-center">
                      <PaginateData
                        data={data}
                        emptyDataArrayMsg={t("messages.noProductsToList")}
                        perPage={PRODUCTS_PER_PAGE}
                      >
                        <ListProductsTable title={label} />
                      </PaginateData>
                    </div>
                  </TabPanel>
                ))}
              </TabsBody>
            </Tabs>
          )}
        </div>
      )}
    </>
  );
};

export default DashboardPage;
