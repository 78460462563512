import React from 'react';
import { useTranslation } from 'react-i18next';
import EsFlag from "@src/assets/images/country_flags/es-nav.svg";
import GbFlag from "@src/assets/images/country_flags/gb-nav.svg";

const FooterLanguageSelector = () => {
  const { i18n } = useTranslation();

  const supportedI18nLanguages = [
    {
      code: "es-ES",
      flag: EsFlag,
    },
    {
      code: "en-GB",
      flag: GbFlag,
    },
  ];

  const handleLanguageChange = langCode => {
    i18n.changeLanguage(langCode);
  };

  return (
    <div className="flex justify-around mt-12 w-full">
      {supportedI18nLanguages.map(language => {
        const isCurrentLanguageSelected = i18n.language === language.code;

        return (
          <div key={language.code} className="bg-white rounded-lg flex flex-col items-end p-2 w-10 cursor-pointer" onClick={() => handleLanguageChange(language.code)}>
            {/* eslint-disable-next-line */}
            <img src={language.flag} />
            {isCurrentLanguageSelected &&
              <>
                {/* eslint-disable-next-line */}
                <img
                  className="mt-3"
                  src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62b5b776d4d57c89397da328/img/line-5@2x.svg"
                />
              </>
            }
          </div>
        );
      })}
    </div>
  );
}
 
export default FooterLanguageSelector;