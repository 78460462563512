import React from "react";

import { useTranslation } from "react-i18next";

import { dateToStringDDMMYYYYHHMMSS } from "@src/helpers/dateHelpers";

import Spinner from "@src/components/ui/Spinner";
import TableCard from "@src/components/ui/TableCard";
import EyeButton from "@src/components/ui/buttons/EyeButton";

const ListBannerTable = ({ currentPageData : bannerList,title, showBannerImageCallback, deleteBannerCallback }) => {
  const { t, ready: translationsReady } = useTranslation(["components/banners/listTable", "common/common", "common/list"]);

  const getHeadersArray = () => Object.values(t("headers")).concat(t("actionsTableHeader", { ns: "common/list" }));

  const formatDataMatrix = () => bannerList.map(n => [
    n.id,
    n.description,
    n.createdAt ? dateToStringDDMMYYYYHHMMSS(n.createdAt) : t("notAvailable", { ns: "common/common" }),
    n.enabled ?  t("enabled", { ns: "common/common" }) : t("notEnabled", { ns: "common/common" }),
    <>
      <EyeButton
        onClick={() => showBannerImageCallback(n)}
      />

    </>
  ]);
    

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
          title={title}
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

export default ListBannerTable;