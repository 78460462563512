import React from "react";
import { useTranslation } from "react-i18next";
import { dateToStringDDMMYYYYHHMMSS } from "../../helpers/dateHelpers";
import Spinner from "../ui/Spinner";
import TableCard from "@src/components/ui/TableCard";

const ListOrderStatusesTable = ({ currentPageData : statusList,title }) => {
  const { t, ready: translationsReady } = useTranslation(["components/orders/listOrderStatusesTable", "common/orderStatuses", "common/common"]);

  const getHeadersArray = () => Object.values(t("headers"));
  
  const formatDataMatrix = () => statusList.map(status => [
    dateToStringDDMMYYYYHHMMSS(status.createdAt),
    t(status.status, { ns: "common/orderStatuses" }),
    status.comments || t("notAvailable", { ns: "common/common" }),
  ]);

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
          title={title}
        headersArray={getHeadersArray()}
        dataMatrix={formatDataMatrix()}
        />
      )}
    </>
  );
};

export default ListOrderStatusesTable;