import axios from "@src/helpers/axiosInstance";
import { APIEndpointsStrings } from "@src/helpers/stringHelpers";

class ProductCategoryService {
  static index = async (requestPath) => {
    const res = await axios.get(requestPath ?? APIEndpointsStrings.categoriesPath);

    return res.data.map(category => this.formatReceivedCategory(category));
  };

  static indexPublicOnes = () => {
    const requestPath = APIEndpointsStrings.categoriesPath + "?q[public_true]=1";
    return this.index(requestPath);
  };

  static formatReceivedCategory = category => ({
    id: category.id,
    name: category.name,
    public: category.public,
    highlighted: category.highlighted,
  });
};

export default ProductCategoryService;