import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormikTextInput from "@src/components/forms/FormikTextInput";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { emptyArray } from "@src/helpers/arrayHelpers";
import { stringDateFormat } from "@src/helpers/dateHelpers";
import SubmitButton from "@src/components/ui/buttons/SubmitButton";
import Spinner from "@src/components/ui/Spinner";

const RegularAvailabilityInitialValues = {
  fromDate: "",
  toDate: "",
};

const DateSelector = ({
  alreadyExistentConfigurations,
  editingProductConfigurations,
  configurations,
  setConfigurations,
}) => {
  const { t, ready: translationsReady } = useTranslation([
    "components/products/create/availability",
    "components/products/edit/addConfiguration",
    "common/list",
  ]);

  const getAllConfigurations = () =>
    configurations.concat(
      editingProductConfigurations ? alreadyExistentConfigurations : []
    );

  const removeDate = (dateIndex) =>
    setConfigurations(
      configurations.filter((value, index) => index !== dateIndex)
    );

  const renderConfigurations = () =>
    configurations.length === 0 ? null : (
      <>
      <div className="flex place-content-center">
        {configurations.map((configuration, index) => (
          <p key={`regular-dates-${index}`} className="ml-2">
            {`${stringDateFormat(configuration.fromDate)} - ${stringDateFormat(
              configuration.toDate
            )}`}
            <button onClick={() => removeDate(index)} className="ml-1">
              <FontAwesomeIcon icon={faTimesCircle} color="red" />
            </button>
          </p>
        ))}
        </div>
      </>
    );

  const rangeAlreadyPresent = (fromDate, toDate) =>
    getAllConfigurations().some(
      (value) =>
        new Date(value.fromDate).valueOf() === new Date(fromDate).valueOf() &&
        new Date(value.toDate).valueOf() === new Date(toDate).valueOf()
    );

  const rangeOverlapping = (fromDate, toDate) =>
    getAllConfigurations().some((value) => {
      const currentRange = {
        from: new Date(value.fromDate).valueOf(),
        to: new Date(value.toDate).valueOf(),
      };
      const newRange = {
        from: new Date(fromDate).valueOf(),
        to: new Date(toDate).valueOf(),
      };
      return (
        (currentRange.from <= newRange.to && newRange.to <= currentRange.to) ||
        (newRange.from <= currentRange.from &&
          currentRange.to <= newRange.to) ||
        (currentRange.from <= newRange.from &&
          newRange.to <= currentRange.to) ||
        (currentRange.from <= newRange.from && newRange.from <= currentRange.to)
      );
    });

  const handleNewDate = async (values, actions) => {
    const fromDate = values.fromDate,
      toDate = values.toDate;
    if (!rangeAlreadyPresent(fromDate, toDate)) {
      setConfigurations(configurations.concat({ fromDate, toDate }));
      actions.resetForm({ values: RegularAvailabilityInitialValues });
    }
  };

  const handleValidation = (values) => {
    const fromDate = values.fromDate,
      toDate = values.toDate;


    if (new Date(fromDate).valueOf() > new Date(toDate).valueOf())
      return {
        dates:
          "components/products/create/availability:validations.regularComponent.fromDateLower",
      };
    if (new Date(toDate).valueOf() < new Date(fromDate).valueOf())
      return {
        dates:
          "components/products/create/availability:validations.regularComponent.toDateGreater",
      };
    if (rangeAlreadyPresent(fromDate, toDate))
      return {
        dates:
          "components/products/create/availability:validations.regularComponent.rangeAlreadyPresent",
      };
    if (rangeOverlapping(fromDate, toDate))
      return {
        dates:
          "components/products/create/availability:validations.regularComponent.rangeOverlaps",
      };
    if (editingProductConfigurations && !emptyArray(configurations))
      return {
        dates:
          "components/products/edit/addConfiguration:validations.createOnlyOneConfigurationAtATime",
      };
  };

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <>
          {emptyArray(configurations) ? (<Formik
            initialValues={RegularAvailabilityInitialValues}
            validate={handleValidation}
            onSubmit={handleNewDate}
          >
            {({ values, errors, touched }) => (
              <Form>
                <div className="flex space-x-2 mt-4">
                  <FormikTextInput
                    label={t("labels.regularComponent.fromDateInput")}
                    name="fromDate"
                    type="date"
                  />
                  <FormikTextInput
                    label={t("labels.regularComponent.toDateInput")}
                    name="toDate"
                    type="date"
                  />
                  <div className="flex items-center">
                    <SubmitButton
                      color="green"
                      disabled={
                        (editingProductConfigurations &&
                          !emptyArray(configurations)) ||
                        !(values.fromDate && values.toDate)
                      }
                    >
                      {t("add", { ns: "common/list" })}
                    </SubmitButton>
                  </div>
                </div>
                {errors.dates && touched.toDate && touched.fromDate && (
                  <div className="text-red-500 font-semibold">
                    {t(errors.dates)}
                  </div>
                )}
              </Form>
            )}
          </Formik>):(
          <p className="mt-2">{renderConfigurations()}</p>
          )}
        </>
      )}
    </>
  );
};

export default DateSelector;
