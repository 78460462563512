import axios from "@src/helpers/axiosInstance";
import { APIEndpointsStrings } from "@src/helpers/stringHelpers";

class LocationService {
  static getAllLocation = () =>
    axios.get(APIEndpointsStrings.locationServicesProvaiderPath);

  static getAllLocationById = (id) =>
    axios.get(
      APIEndpointsStrings.locationServicesProvaiderIdPath.replace(
        APIEndpointsStrings.idLocators.id,
        id
      )
    );

    static getLocationProvinces = () => {
      return axios.get(`/locations?q[zip_code_matches]=__`);
    };
  
    static getLocationByName = (name) => {
    return axios.get(`/locations?q[name_start]=${name}`);
  };


  static getLocationByZipCode = (name) => {
    return axios.get(`/locations?q[zip_code_start]=${name}`);
  };

  static getLocationWithId = (locationsIds) => {
    let filter = "";
    locationsIds.forEach(
      (locationsId) => (filter += `q[id_in][]=${locationsId}&`)
    );

    return axios.get(`/locations?${filter}`);
  };

  static postNewLocation = (name, zip_code) =>
    axios.post(APIEndpointsStrings.locationsPath, {
      location: { name, zip_code },
    });

  static putNewLocation = (name, zip_code) =>
    axios.put(APIEndpointsStrings.locationsPath, {
      location: { name, zip_code },
    });

  static DelNewLocation = (id) =>
    axios.delete(
      APIEndpointsStrings.locationPath.replace(
        APIEndpointsStrings.idLocators.id,
        id
      )
    );

  static postNewLocationServiceProvider = (id, ids) =>
    axios.post(
      APIEndpointsStrings.locationAddPath.replace(
        APIEndpointsStrings.idLocators.id,
        id
      ),
      {
        location_ids: ids,
      }
    );

  static delLocationServiceProvider = (id, ids) =>
    axios.post(
      APIEndpointsStrings.locationDeletePath.replace(
        APIEndpointsStrings.idLocators.id,
        id
      ),
      {
        location_ids: [ids],
      }
    );
}

export default LocationService;
