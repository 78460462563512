import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useState } from 'react';
import * as Yup from "yup";
import SmallSpinner from "react-spinkit";
import { createNotification, NotificationTypes } from '@src/helpers/notificationHelpers';
import { Trans, useTranslation } from 'react-i18next';
import Spinner from '@src/components/ui/Spinner';
import NewsletterSubscriptionService from '@src/services/newsletterSubscriptionService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

const NewsletterSubscriptionInitialValues = {
  email: "",
  confirmPrivacyPolicy: false,
};

const NewsletterSubscriptionSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  confirmPrivacyPolicy: Yup.boolean().test({ test: value => value }),
});

const NewsletterSubscriptionBox = () => {
  const [w18APIResponse, setW18APIResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation(["components/b2c/newsletterSubscriptionBox", "common/form"]);

  const handleSubmit = async (values, actions) => {
    setW18APIResponse(b => !b);

    try {
      await NewsletterSubscriptionService.subscribe(values.email);
      createNotification(NotificationTypes.success, t("submitAction.ok"));
      actions.resetForm({ values: NewsletterSubscriptionInitialValues });
    } catch {
      createNotification(NotificationTypes.error, t("submitAction.ko"));
    } finally {
      setW18APIResponse(b => !b);
    }
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="flex flex-col items-start">
          <Formik
            initialValues={NewsletterSubscriptionInitialValues}
            validationSchema={NewsletterSubscriptionSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors }) => (
              <Form>
                <div className="leading-7 tracking-normal whitespace-no-wrap montserrat-bold-white-19px">{t("description")}</div>
                <div className="flex items-end justify-end mt-3">
                  <div className="flex items-start h-10 w-[270px] bg-gray-50 rounded-[28px] py-3 px-6">
                    <Field
                      className="bg-transparent h-4 tracking-normal w-40 placeholder:text-[#84848499] montserrat-medium-gunsmoke-12px"
                      name="email"
                      placeholder={t("labels.email", { ns: "common/form" })}
                      type="email"
                    />
                  </div>
                  <button
                    type="submit"
                    className="disabled:cursor-default disabled:opacity-70 ml-3"
                    disabled={!(errors.email == null && values.confirmPrivacyPolicy)}
                  >
                    <div className="w-[40px] h-[40px] rounded-full bg-white flex">
                      {!w18APIResponse ? (
                        <FontAwesomeIcon icon={faPaperPlane} className="m-auto" color="#e30746" />
                      ) : (
                        <SmallSpinner name="circle" color="#e30746" fadeIn="none" className="m-auto" />
                      )}
                    </div>
                  </button>
                </div>
                <div className="flex items-center h-8 mt-2">
                  <Field
                    type="checkbox"
                    name="confirmPrivacyPolicy"
                  />
                  <p className="leading-7 ml-1 tracking-normal whitespace-no-wrap montserrat-medium-white-12px">
                    <Trans
                      t={t}
                      i18nKey="confirmPrivacyPolicy"
                      components={{ 1: <span className="montserrat-medium-white-12px" />, 2: <span className="font-bold" /> }}
                    />
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
}
 
export default NewsletterSubscriptionBox;