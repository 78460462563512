import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faPinterest,
  faTwitter,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import { isLoggedIn } from "@src/helpers/sessionHelpers";
import ExternalLinkWrapper from "@src/components/ui/ExternalLinkWrapper";
import { SocialNetworkLinkStrings } from "@src/helpers/stringHelpers";
import CountryFlag from "@src/components/ui/CountryFlag";

const Footer = () => {
  const { t, ready: translationsReady, i18n } = useTranslation("components/footer");
  const socialNetworkIcons = [
    { link: SocialNetworkLinkStrings.whatsapp, icon: faWhatsapp, color: "#25D366" },
    { link: SocialNetworkLinkStrings.twitter, icon: faTwitter, color: "#1DA1F2" },
    { link: SocialNetworkLinkStrings.facebook, icon: faFacebook, color: "#4267B2" },
    { link: SocialNetworkLinkStrings.instagram, icon: faInstagram, color: "#C13584" },
    { link: SocialNetworkLinkStrings.youtube, icon: faYoutube, color: "#FF0000" },
    { link: SocialNetworkLinkStrings.pinterest, icon: faPinterest, color: "#E60023" },
    { link: SocialNetworkLinkStrings.linkedin, icon: faLinkedin, color: "#0077B5" },
    { link: SocialNetworkLinkStrings.youtube, icon: faYoutube, color: "#FF0000" },
  ];
  const supportedI18nLanguages = ["es-ES", "en-GB"];

  const handleLanguageChange = langCode => {
    i18n.changeLanguage(langCode);
  };

  return (
    <div
      className={`absolute ${
        isLoggedIn() ? "md:left-64" : ""
      } left-0 right-0 px-4 bottom-0 h-[40px] items-center flex border-t-2`}
    >
      {!translationsReady ? (
        <Spinner />
      ) : (
        <>
          <div className="flex space-x-2 mr-auto">
            <p>{t("languages")}</p>
            {supportedI18nLanguages.map((language, index) => (
              <CountryFlag
                key={index}
                languageCode={language.split("-")[0]}
                className="w-[25px] cursor-pointer"
                onClick={() => handleLanguageChange(language)}
              />
            ))}
          </div>
          <div className="flex space-x-2 ml-auto">
            {socialNetworkIcons.map((socialNetwork, index) => (
              <ExternalLinkWrapper key={index} href={socialNetwork.link}>
                <FontAwesomeIcon icon={socialNetwork.icon} color={socialNetwork.color} size="lg" />
              </ExternalLinkWrapper>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Footer;
