import React from 'react';
import Navbar from '@src/components/ui/b2c/Navbar';
import B2CRoutes from '@src/components/B2CRoutes';
import Footer from '@src/components/ui/b2c/Footer';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { isCurrentSessionForImpersonatingUser } from '@src/helpers/sessionHelpers';
import ImpersonateUserAlert from '@src/components/ui/ImpersonateUserAlert';

const B2CApp = () => {
  const history = useHistory();

  useEffect(() => {
    const unlistenRouteChanges = history.listen(_ => {
      window.scrollTo(0, 0);
    });

    return unlistenRouteChanges;
  });

  return (
    <div className="mx-auto">
      {isCurrentSessionForImpersonatingUser() &&
        <ImpersonateUserAlert />
      }
      <Navbar />
      <B2CRoutes />
      <Footer />
    </div>
  );
}
 
export default B2CApp;