import { getCurrentUserData } from "@src/helpers/localStorageHelpers";
import {
  DefinedRoutesStrings as B2BRoutes,
  CommonRoutesStrings as CommonRoutes,
  UserTypesStrings,
  B2CRoutesStrings as B2CRoutes,
} from "@src/helpers/stringHelpers";
import { currentProfessionalIsValidated, currentUserHasWritePermissions } from "@src/helpers/userHelpers";

const b2bCommonRoutes = {
  read: [
    B2BRoutes.myProfilePath,
  ],
  write: [
    B2BRoutes.editMyProfilePath,
  ],
};

const platformWideCommonRoutes = {
  read: [
    CommonRoutes.logoutPath,
    CommonRoutes.showOwnProductPath,
    CommonRoutes.showThirdPartyProductPath,
  ],
  write: [],
};

const adminRoutes = [
  B2BRoutes.listUsersPath,
  B2BRoutes.dashboardPath,
  B2BRoutes.createUserPath,
  B2BRoutes.editUserPath,
  B2BRoutes.validateProfessionalsPath,
  B2BRoutes.disableProfessionalsPath,
  B2BRoutes.listProductsPath,
  B2BRoutes.createProductPath,
  B2BRoutes.editOwnProductPath,
  B2BRoutes.editThirdPartyProductPath,
  CommonRoutes.showOwnProductPath,
  CommonRoutes.showThirdPartyProductPath,
  B2BRoutes.validateThirdPartyProductsPath,
  B2BRoutes.listServicesPath,
  B2BRoutes.showServicePath,
  B2BRoutes.editServicePath,
  B2BRoutes.createServicePath,
  B2BRoutes.jokerPath,
  B2BRoutes.listPeopleSegments,
  B2BRoutes.listPriceTemplates,
  B2BRoutes.listProductCategories,
  B2BRoutes.listServiceCategories,
  B2BRoutes.showOrderPath,
  B2BRoutes.listNewslettersPath,
  B2BRoutes.listBannersPath,
  B2BRoutes.editBannerPath,
  B2BRoutes.listCreditsPath,
  CommonRoutes.listOrdersPath,
  B2BRoutes.listOrderRefundsPath,
  B2BRoutes.locationsPath,
  B2BRoutes.listImageBankPath,
  B2BRoutes.listSupplementsPath,
  ...b2bCommonRoutes.read,
  ...b2bCommonRoutes.write,
  ...platformWideCommonRoutes.read,
  ...platformWideCommonRoutes.write,
];

const operatorRoutes = [
  B2BRoutes.listUsersPath,
  B2BRoutes.createUserPath,
  B2BRoutes.editUserPath,
  B2BRoutes.validateProfessionalsPath,
  B2BRoutes.disableProfessionalsPath,
  B2BRoutes.listProductsPath,
  B2BRoutes.createProductPath,
  B2BRoutes.editOwnProductPath,
  B2BRoutes.editThirdPartyProductPath,
  CommonRoutes.showOwnProductPath,
  CommonRoutes.showThirdPartyProductPath,
  B2BRoutes.validateThirdPartyProductsPath,
  B2BRoutes.listServicesPath,
  B2BRoutes.showServicePath,
  B2BRoutes.editServicePath,
  B2BRoutes.createServicePath,
  B2BRoutes.jokerPath,
  B2BRoutes.listPeopleSegments,
  B2BRoutes.listPriceTemplates,
  B2BRoutes.listProductCategories,
  B2BRoutes.listServiceCategories,
  B2BRoutes.showOrderPath,
  B2BRoutes.listNewslettersPath,
  B2BRoutes.listBannersPath,
  B2BRoutes.editBannerPath,
  B2BRoutes.listCreditsPath,
  CommonRoutes.listOrdersPath,
  B2BRoutes.listOrderRefundsPath,
  B2BRoutes.listImageBankPath,
  B2BRoutes.listSupplementsPath,
  ...b2bCommonRoutes.read,
  ...b2bCommonRoutes.write,
  ...platformWideCommonRoutes.read,
  ...platformWideCommonRoutes.write,
];

const providerRoutes = {
  read: [
    B2BRoutes.listProductsPath,
    CommonRoutes.showThirdPartyProductPath,
    B2BRoutes.listCreditsPath,
    B2BRoutes.listSupplementsPath,
    B2BRoutes.dashboardPath,
    ...b2bCommonRoutes.read,
    ...platformWideCommonRoutes.read,
  ],
  write: [
    B2BRoutes.createThirdPartyProductsPath,
    B2BRoutes.editThirdPartyProductPath,
    ...b2bCommonRoutes.write,
    ...platformWideCommonRoutes.write,
  ],
};

const servicesProviderRoutes = {
  read: [
    B2BRoutes.listServicesPath,
    B2BRoutes.showServicePath,
    B2BRoutes.listCreditsPath,
    B2BRoutes.dashboardPath,
    ...b2bCommonRoutes.read,
    ...platformWideCommonRoutes.read,
  ],
  write: [
    B2BRoutes.createServicePath,
    B2BRoutes.editServicePath,
    B2BRoutes.locationsPath,
    ...b2bCommonRoutes.write,
    ...platformWideCommonRoutes.write,
  ],
};

const clientRoutes = [
  CommonRoutes.listOrdersPath,
  B2CRoutes.askForOrderRefundPath,
  ...platformWideCommonRoutes.read,
  ...platformWideCommonRoutes.write,
];

const checkAbility = (permittedRoutes, route) => {
  return permittedRoutes.some(element => element === route);
};

export const allowedToAccessRoute = route => {
  switch (getCurrentUserData().type) {
    case UserTypesStrings.admin:
      return checkAbility(adminRoutes, route);
    case UserTypesStrings.operator:
      return checkAbility(operatorRoutes, route);
    case UserTypesStrings.provider:
      const providerRoutesToCheck = !currentProfessionalIsValidated() ? [...b2bCommonRoutes.read, ...platformWideCommonRoutes.read]
        : (!currentUserHasWritePermissions() ? providerRoutes.read : [...providerRoutes.read, ...providerRoutes.write]);

      return checkAbility(providerRoutesToCheck, route);
    case UserTypesStrings.servicesProvider:
      const servicesProviderRoutesToCheck = !currentProfessionalIsValidated() ? [...b2bCommonRoutes.read, ...platformWideCommonRoutes.read]
        : (!currentUserHasWritePermissions() ? servicesProviderRoutes.read : [...servicesProviderRoutes.read, ...servicesProviderRoutes.write]);

      return checkAbility(servicesProviderRoutesToCheck, route);
    case UserTypesStrings.client:
      return checkAbility(clientRoutes, route);
    default:
      return false;
  }
}