import axios from "@src/helpers/axiosInstance";
import { getJWT, getCurrentUserData } from "@src/helpers/localStorageHelpers";
import { APIEndpointsStrings, UserTypesStrings } from "@src/helpers/stringHelpers";

const filterByNotValidatedProfessionals = "q[reviewable_professionals]=1";

const checkUserTypeEquality = (user, type) => (user ? user.type === type : getCurrentUserData().type === type);
export const isAdmin = (user) => checkUserTypeEquality(user, UserTypesStrings.admin);
export const isOperator = (user) => checkUserTypeEquality(user, UserTypesStrings.operator);
export const isProfessional = (user) =>
  checkUserTypeEquality(user, UserTypesStrings.servicesProvider) ||
  checkUserTypeEquality(user, UserTypesStrings.provider);
export const isServicesProvider = (user) => checkUserTypeEquality(user, UserTypesStrings.servicesProvider);
export const isProvider = (user) => checkUserTypeEquality(user, UserTypesStrings.provider);
export const isClient = (user) => checkUserTypeEquality(user, UserTypesStrings.client);

export const currentUserHasWritePermissions = () =>
  isClient() || (isProfessional() && !getCurrentUserData().isDisabled) || isOperator() || isAdmin();
export const currentProfessionalIsValidated = () => !isProfessional() || getCurrentUserData().isValidated;

export const indexUsersRequest = () => {
  return axios.get(APIEndpointsStrings.reviewedUsersPath, {
    headers: {
      Authorization: `Bearer ${getJWT()}`,
    },
  });
};


export const getUserRequest = (userId) => {
  return axios.get(`/users/${userId}`, {
    headers: {
      Authorization: `Bearer ${getJWT()}`,
    },
  });
};

export const getUsersByTypeAndContainingEmail = (email_like, type) =>
  axios.get(`/users?q[type_eq]=${type}&q[email_cont]=${email_like}`);



export const createUserRequest = (email, password, userType) => {
  return axios.post(
    "/create-user",
    {
      user: {
        email,
        password,
        type: userType,
      },
    },
    {
      headers: {
        Authorization: `Bearer ${getJWT()}`,
      },
    }
  );
};

export const updateCompanyDataRequest = (
  cif,
  fiscalName,
  tradeName,
  direction,
  responsibleName,
  responsiblePhone,
  province,
  postalCode,
  countryAgency,
  responsiblePosition,
  responsibleMobile
) => {
  let userData = {
    cif: cif,
    taxname: fiscalName,
    tradename: tradeName,
    address: direction,
    responsible_name: responsibleName,
    responsible_phone: responsiblePhone,
    province: province,
    zip_code: postalCode,
    country: countryAgency,
    responsible_position: responsiblePosition,
    responsible_mobile: responsibleMobile,
  };

  return axios.put(
    `/companies/${getCurrentUserData().id}`,
    {
      user: userData,
    },
    {
      headers: {
        Authorization: `Bearer ${getJWT()}`,
      },
    }
  );
};
export const deleteUserRequest = (id) => {
  return axios.delete(`/users/${id}`, {
    headers: {
      Authorization: `Bearer ${getJWT()}`,
    },
  });
};

export const getNotValidatedProfessionalsRequest = () => {
  return axios.get(`/users?${filterByNotValidatedProfessionals}`, {
    headers: {
      Authorization: `Bearer ${getJWT()}`,
    },
  });
};

export const getCountProfessionalsToValidateRequest = () => {
  return axios.get(`/users/count?${filterByNotValidatedProfessionals}`, {
    headers: {
      Authorization: `Bearer ${getJWT()}`,
    },
  });
};

export const validateProfessionalRequest = (userId) => {
  return axios.patch("/users/professional/validate/" + userId, undefined, {
    headers: {
      Authorization: `Bearer ${getJWT()}`,
    },
  });
};

export const revokeProfessionalRequest = (userId) => {
  return axios.patch("/users/professional/deny/" + userId, undefined, {
    headers: {
      Authorization: `Bearer ${getJWT()}`,
    },
  });
};
