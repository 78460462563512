import axios from "@src/helpers/axiosInstance";
import { getCurrentUserData } from "@src/helpers/localStorageHelpers";
import { APIEndpointsStrings } from "@src/helpers/stringHelpers";
import { isProvider } from "@src/helpers/userHelpers";

class SupplementService {
  static index = async (requestPath) => {
    const res = await axios.get(requestPath ?? APIEndpointsStrings.supplementsPath);
    return res.data.map(supplement => this.formatReceivedSupplement(supplement));
  };

  static indexSupplementsWithIds = async (ids) => {
    let requestPath = APIEndpointsStrings.supplementsPath + "?";
    ids.forEach(id => requestPath += `q[id_in][]=${id}&`);
    return this.index(requestPath);
  };

  static indexDependingOnCurrentUserTypeExcludingIDs = async (idsToExclude) => {
    let requestPath = APIEndpointsStrings.supplementsPath + "?";
    
    if (isProvider()) {
      requestPath += `q[provider_id_eq]=${getCurrentUserData().id}&`;
    } else {
      requestPath += `q[provider_id_null]=1&`;
    }

    idsToExclude.forEach(id => requestPath += `q[id_not_in][]=${id}&`);

    return this.index(requestPath);
  };

  static create = supplement => {
    const formattedSupplement = this.formatSupplementToSend(supplement);

    return axios.post(APIEndpointsStrings.supplementsPath, { supplement: formattedSupplement });
  };

  static update = (supplementId, supplementData) => {
    const formattedSupplement = this.formatSupplementToSend(supplementData),
      requestPath = APIEndpointsStrings.supplementPath.replace(APIEndpointsStrings.idLocators.id, supplementId);

    return axios.put(requestPath, { supplement: formattedSupplement });
  };

  static destroy = async supplementId =>
    axios.delete(APIEndpointsStrings.supplementPath.replace(APIEndpointsStrings.idLocators.id, supplementId));

  static formatReceivedSupplement = supplement => ({
    id: supplement.id,
    name: supplement.name,
    price: supplement.price,
    maxPerProduct: supplement.upper_limit,
    provider: !supplement.provider
      ? undefined
      : {
          id: supplement.provider.id,
          email: supplement.provider.email,
        },
  });

  static formatSupplementToSend = supplement => ({
    name: supplement.name,
    price: Number(supplement.price),
    upper_limit: Number(supplement.maxPerProduct),
    provider_id: supplement.providerId != null ? Number(supplement.providerId) : null,
  });
}

export default SupplementService;
