import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import BasicInfoForm from "@src/components/products/services/create/BasicInfoForm";
import ServiceService from "@src/services/services/serviceService";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { useParams } from "react-router-dom";

const EditBasicInfo = ({ serviceData, backAction, successCallback, readOnly }) => {
  const { t, ready: translationsReady } = useTranslation("components/products/edit/editBasicInfo");
  const formInitialValues = useRef({
    name: serviceData.name,
    servicesProviderId: serviceData.servicesProviderId,
    serviceCategoryId: serviceData.serviceCategory.id,
  });
  const { id : serviceId } = useParams();
  const [w18ApiResponse, setW18ApiResponse] = useState(false);

  const submitEditedData = async (values) => {
    setW18ApiResponse(b => !b);

    try {
      await ServiceService.update(serviceId, values);
      createNotification(NotificationTypes.success, t("successfulUpdate"));
      await successCallback();
    } catch (err) {
      createNotification(NotificationTypes.error, t("genericError"));
      setW18ApiResponse(b => !b);
    }
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <BasicInfoForm
          initialValues={formInitialValues.current}
          backCallback={backAction}
          nextCallback={submitEditedData}
          editingServiceData={true}
          showLoadingSpinner={w18ApiResponse}
          readOnly={readOnly}
        />
      )}
    </>
  );
};

export default EditBasicInfo;