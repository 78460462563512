import {Form, Formik} from "formik";
import React from "react";
import {useTranslation} from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import NumInput from "@src/components/forms/NumInput";
import BasicFormTemplate, { FooterType } from "@src/components/forms/BasicFormTemplate";

const CreditInitialValues = {
  credit: 0
};

const CreditForm = ({
                      initialValues,
                      creditSchema,
                      handleSubmit,
                      backCallback,
                      title,
                      showLoadingSpinnerSubmitButton
                    }) => {
  const {t, ready: translationsReady} = useTranslation(["components/credits/form", "common/form"]);
  return (
    <>
      {!translationsReady ? <Spinner/> : (
        <Formik
          initialValues={initialValues ? initialValues : CreditInitialValues}
          validationSchema={creditSchema}
          onSubmit={handleSubmit}
        >
          <Form className="space-y-2" encType="multipart/form-data">
          <BasicFormTemplate
              className="mt-8"
              formTitle={title}
              showLoadingSpinner={showLoadingSpinnerSubmitButton}
              footerType={FooterType.BACK_SUBMIT_BUTTONS}
              backCallback={backCallback}
            >
            <div className="space-x-1">
              <NumInput
                name="credit"
                yMargin="0"
                label={t("labels.amount")}
              />
            </div>
            </BasicFormTemplate>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default CreditForm;