import Spinner from '@src/components/ui/Spinner';
import { Progress } from '@material-tailwind/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ResourcesConfiguration from '@src/components/products/services/create/ResourcesConfiguration';
import { stringDateFormat } from '@src/helpers/dateHelpers';
import AddDateSummary from '@src/components/products/services/edit/dates/add_date/AddDateSummary';
import ServiceResourceService from '@src/services/services/serviceResourceService';
import { createNotification, NotificationTypes } from '@src/helpers/notificationHelpers';

const AddResource = ({ date, backCallback, successCallback }) => {
  const { id : serviceId } = useParams();
  const FIRST_STEP = 1;
  const [currentStep, setCurrentStep] = useState(FIRST_STEP);
  const [dateData, setDateData] = useState([{
    fromDate: date.date,
    toDate: date.date,
    dates: [ date.date ],
  }]);
  const { t, ready: translationsReady } = useTranslation(["components/services/edit/resources/add", "common/common"]);
  const getStepTitles = () => t("stepTitles");
  const getNumberSteps = () => Object.keys(getStepTitles()).length;
  const [submissionErrors, setSubmissionErrors] = useState([]);
  const [w18ApiResponse, setW18ApiResponse] = useState(false);

  const handleSubmit = async () => {
    setW18ApiResponse(b => !b);

    try {
      await ServiceResourceService.create(serviceId, date.id, dateData[0].resources);
      createNotification(NotificationTypes.success, t("submitAction.ok"));
      await successCallback();
    } catch (err) {
      createNotification(NotificationTypes.error, t("submitAction.ko"));
      setW18ApiResponse(b => !b);
    }
  };

  const handleStepDataReceived = data => {
    console.log(data)
    switch (currentStep) {
      case 1:
      case 2:
        setDateData(data);
        break;
      default:
        return;
    }
  };

  const handleStepChange = targetStep => {
    setCurrentStep(targetStep);
    setSubmissionErrors([]);
  };

  const handlePreviousStep = currentStep === FIRST_STEP ? backCallback : () => handleStepChange(currentStep - 1);

  const handleNextStep = data => {
    handleStepDataReceived(data);    
    handleStepChange(currentStep + 1);
  };

  const getStepComponent = () => {
    console.log(dateData)
    switch (currentStep) {
      case 1:
        return <ResourcesConfiguration
                  editingProductConfigurations={true}
                  currentStep={currentStep}
                  numberSteps={getNumberSteps()}
                  initialValues={dateData}
                  callbackNextClicked={handleNextStep}
                  callbackBackClicked={handlePreviousStep}
                />;
      case 2:
        return <AddDateSummary
                  currentStep={currentStep}
                  numberSteps={getNumberSteps()}
                  date={dateData[0]}
                  callbackNextClicked={handleSubmit}
                  callbackBackClicked={handlePreviousStep}
                  submissionErrors={submissionErrors}
                  showLoadingSpinner={w18ApiResponse}
                />;
      default: return <p className="text-center">{t("contentNotFound", { ns: "common/common" })}</p>;
    }
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="container max-w-2xl mx-auto border-t mt-2">
          <p className="mt-2 text-lg font-bold text-center text-gray-700">{t("title", { date: stringDateFormat(date.date) })}</p>
          {currentStep !== FIRST_STEP && (
              <Progress
                color="green"
                value={Number(
                  (
                    ((currentStep - 1) / Object.keys(t("stepTitles")).length) *
                    100
                  ).toFixed(2)
                )}
                label={t("progressBarCompletionLabel", { ns: "common/common" })}
                className="mb-2"
              />
            )}      
          {getStepComponent()}
        </div>
      )}
    </>
  );
};
 
export default AddResource;