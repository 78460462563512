import { Typography } from "@material-tailwind/react";
import OrderService from "@src/services/orders/orderService";
import { useEffect, useCallback } from 'react';
import OkImg from '@src/assets/images/ok.svg';
import "@src/assets/styles/b2c/pages/list_orders/globals.css";
import "@src/assets/styles/b2c/pages/list_orders/list-orders.css";
import "@src/assets/styles/b2c/pages/list_orders/styleguide.css";
import HistoryBackButton from "@src/components/ui/buttons/HistoryBackButton";
import Spinner from "@src/components/ui/Spinner";
import { useSearchParams } from "@src/helpers/hooks/useSearchParams";
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { CommonRoutesStrings } from '@src/helpers/stringHelpers';
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";

const ShowRedsysOkPage = () => {
  const { t, ready: translationsReady } = useTranslation("pages/orders/create");
  const searchParams = useSearchParams();
  const history = useHistory();
  const goHome = () => history.push(CommonRoutesStrings.homePath);
  const orderId = searchParams.get('orderId');

  const fetchOrder = useCallback(async () => {
    try {
      await OrderService.paymentConfirmation(orderId);
    } catch (err) {
      createNotification(NotificationTypes.error, t("genericRequestError"));
      console.log(err);
      // history.replace(CommonRoutesStrings.homePath);
    }
  // eslint-disable-next-line
  }, [orderId]);

  useEffect(() => {
    translationsReady && fetchOrder();
    // eslint-disable-next-line
  }, [translationsReady]);

  return (
    <div className="bg-white py-20 px-[135.9px] font-['Montserrat']  mx-auto w-full max-w-[1440px]">
      {!translationsReady ? (
        <Spinner />
      ) : (
        <>
          <div className="flex space-x-2 mr-auto">
            <img style={{ maxWidth: "50px" }} src={OkImg} alt="Payment-OK" />
            <Typography className="font-extrabold sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl leading-8 text-[#e30746]">{t("paymentOk")}</Typography>
          </div>
          <br />
          <Typography>ORDER ID : {orderId}</Typography>
          <br />
          <HistoryBackButton onClick={goHome} />
        </>
      )}
    </div>
  );
};

export default ShowRedsysOkPage;
