import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ProductService from "@src/services/products/productService";
import { useHistory, useParams } from "react-router-dom";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { CommonRoutesStrings, HexStandardPinkStylingB2C } from "@src/helpers/stringHelpers";
import Spinner from "@src/components/ui/Spinner";
import CreateOrderPane from "@src/components/orders/b2c/new/CreateOrderPane";
import HighlightedProducts from "@src/components/products/b2c/HighlightedProducts";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglass } from "@fortawesome/free-regular-svg-icons";

import "@src/assets/styles/b2c/pages/show_product/globals.css";
import "@src/assets/styles/b2c/pages/show_product/styleguide.css";
import ProductImagesCarousel from "@src/components/products/b2c/ProductImagesCarousel";

const ShowProductPage = ({ productType }) => {
  const [product, setProduct] = useState();
  const { t, ready: translationsReady } = useTranslation(["pages/b2c/showProduct", "pages/products/edit"]);
  const { id: productId } = useParams();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchProduct = async () => {
      try {
        // need to reset product state, in case useEffect ran in componentDidUpdate, not in componentDidMount
        setProduct();

        setProduct(await ProductService.show(productId, productType));
      } catch (err) {
        const errorMsgKey =
          err?.response?.status === 404 ? "validations.productNotFount" : "validations.genericRequestError";

        createNotification(NotificationTypes.error, t(errorMsgKey, { ns: "pages/products/edit" }));
        history.replace(CommonRoutesStrings.homePath);
      }
    };

    t && fetchProduct();
    // eslint-disable-next-line
  }, [t, productId]);

  const formattedProductDuration = () => {
    const totalMinutes = product.productCache?.duration;

    if (totalMinutes < 60){
      return (<span>{totalMinutes}M</span>)
    }

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return (<span>{hours}H {minutes}M</span>)
  }

  const locations = (location) => 
    location.map( loc =>(
      <span>{loc.name} ({loc.zip_code}), </span>
    ))
  

  return (
    <div className="container-center-horizontal font-['Montserrat']">
      <div className="flex flex-col items-center w-full max-w-[1440px] pb-14 bg-white">
        {!(translationsReady && product) ? (
          <Spinner />
        ) : (
          <>
            {/* hero banner + info bar */}
            <div className="relative h-[521px] w-full">
              <ProductImagesCarousel images={product.images} />
              <h1 className="z-10 absolute left-[136px] top-[125px] font-extrabold text-white text-5xl leading-8 whitespace-nowrap">
                {product.name}
              </h1>
              <div className="rounded-xl flex items-center h-20 px-5 absolute shadow-2xl left-[136px] w-[81%] top-[460px] z-10 bg-white">
                <div className="flex items-start justify-end h-8">
                  <FontAwesomeIcon icon={faHourglass} className="self-center h-5 w-4" color="gray" />
                  <div className="leading-7 ml-1 mt-px whitespace-nowrap montserrat-normal-shark-18px">
                    {(product.productCache?.duration) ? formattedProductDuration() :
                      (<span>{t("durations.noDuration")}</span>)
                    }
                  </div>
                </div>
                <div className={`h-12 ml-5 w-px bg-[${HexStandardPinkStylingB2C}]`}></div>
                <div className="flex items-start justify-end h-8 ml-5">
                  <FontAwesomeIcon icon={faUsers} className="self-center h-6 w-8" color="gray" />
                  <div className="leading-7 ml-2 mt-1 whitespace-nowrap montserrat-normal-shark-18px">
                    {(product.productCache?.quota) ? (<span>MAX {product.productCache?.quota}P</span>) :
                      (<span>{t("quotas.noQuota")}</span>)
                    }
                  </div>
                </div>
                <div className={`h-12 ml-5 w-px bg-[${HexStandardPinkStylingB2C}]`}></div>
                <div className="flex items-start ml-5">
                  {/* eslint-disable-next-line */}
                  <img
                    className="self-center h-8 w-8"
                    src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bc6b3fff23d78fb72d45dc/img/1@2x.svg"
                  />
                  <div className="leading-7 ml-2 whitespace-nowrap montserrat-normal-shark-18px">
                    {(product.queues) ? (<span>{t("queues.hasQueues")}</span>) : 
                      (<span>{t("queues.hasNotQueues")}</span>)
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start mt-16 w-auto mx-5">
              <div className="flex items-start w-full justify-between">
                {/* product info left column */}
                <div className="flex flex-col items-start w-[40.6%]">
                  <div className="flex flex-col items-start mx-1">
                    <div className="leading-8 -mt-px whitespace-nowrap montserrat-bold-shark-24px">
                      {t("description")}
                    </div>
                    <div className="leading-8 mt-2 montserrat-medium-shark-18px whitespace-pre-line">
                      {product.description}
                    </div>
                  </div>
                  <div className="flex flex-col items-start mt-16">
                    <div className="leading-8 -mt-px whitespace-nowrap montserrat-bold-shark-24px">
                      {t("conditions")}
                    </div>
                    <div className="leading-8 mt-2 montserrat-medium-shark-18px whitespace-pre-line">
                      {product.conditions}
                    </div>
                  </div>
                </div>

                {/* create order right column */}
                <CreateOrderPane product={product} />
              </div>
              <div className="flex flex-col items-start mt-16">
                <div className="flex flex-col items-start">
                  <div className="leading-8 -mt-px whitespace-nowrap montserrat-bold-shark-24px">
                  {t("meetingPoint")}
                  </div>
                  <div className="leading-8 mt-2 whitespace-nowrap montserrat-medium-shark-18px">
                    {locations(product.locations)}
                  </div>
                </div>
                {/* eslint-disable-next-line */}
                <img
                  className="mt-6 object-cover h-[305px] w-[81%]"
                  src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bc6b3fff23d78fb72d45dc/img/image-36@1x.png"
                />
              </div>
            </div>
            <div className={`h-px mt-16 bg-[${HexStandardPinkStylingB2C}] w-[80.7%]`}></div>
            <HighlightedProducts />
          </>
        )}
      </div>
    </div>
  );
};

export default ShowProductPage;
