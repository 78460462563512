import axios from "@src/helpers/axiosInstance";
import { stringDateFormat } from "@src/helpers/dateHelpers";
import { APIEndpointsStrings } from "@src/helpers/stringHelpers";

class ProductFiltersB2CService {
  static search = async (languages, dates, priceLimits, durationLimits, categories, productName, locations) => {
    const requestBody = {
      languages,
      categories,
      name: productName,
      locations
    };
    if (dates) requestBody.dates = { min: stringDateFormat(dates.from), max: stringDateFormat(dates.to) };
    if (priceLimits) requestBody.prices = { min: priceLimits[0], max: priceLimits[1] };
    if (durationLimits) requestBody.duration = { min: durationLimits[0], max: durationLimits[1] };

    const res = await axios.post(APIEndpointsStrings.productSearchFiltersB2C, requestBody);

    return res.data.map(product => ({
      id: product.id,
      name: product.name,
      description: product.description,
      languages: product.languages,
      duration: Math.min(...product.duration),
      price: Math.min(...product.prices),
    }));
  };

  static showFilterLimits = async (requestPath) => {
    const res = await axios.get(requestPath);

    return [res.data.min, res.data.max];
  };

  static showPriceLimits = async () => {
    const requestPath = APIEndpointsStrings.productSearchFilterLimitsB2C + "?q[limits_by]=prices";
    return this.showFilterLimits(requestPath);
  };
  
  static showDurationLimits = async () => {
    const requestPath = APIEndpointsStrings.productSearchFilterLimitsB2C + "?q[limits_by]=durations";
    return this.showFilterLimits(requestPath);
  };

};

export default ProductFiltersB2CService;