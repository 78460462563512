import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Alert } from '@material-tailwind/react';
import { getCurrentUserData, getImpersonatorData, stopImpersonationSession } from '@src/helpers/localStorageHelpers';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Spinner from '@src/components/ui/Spinner';
import SubmitButton from '@src/components/ui/buttons/SubmitButton';
import { createNotification, delayedRedirect, NotificationTypes } from '@src/helpers/notificationHelpers';
import UserService from '@src/services/users/userService';
import { CommonRoutesStrings } from '@src/helpers/stringHelpers';

const ImpersonateUserAlert = () => {
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation("components/impersonateUserAlert");

  const impersonatorEmail = getImpersonatorData().email,
    impersonated = getCurrentUserData();

  const stopImpersonating = async () => {
    try {
      await UserService.stopImpersonating();
      stopImpersonationSession();
      delayedRedirect(CommonRoutesStrings.homePath, 1);
    } catch (_) {
      createNotification(NotificationTypes.error, t("errorStopingImpersonation"));
      setW18ApiResponse(b => !b);
    }
  };

  return (
    <Alert
      color="amber"
      className="!sticky top-0 z-10 mb-4"
    >
      {!translationsReady ? <Spinner /> : (
        <div className="ml-12 flex space-x-4 items-center justify-center">
          <FontAwesomeIcon icon={faExclamationTriangle} color="red" size="2x" />
          <div className="font-bold text-lg">{t("description", { impersonatorEmail, impersonatedEmail: impersonated.email })}</div>
          <SubmitButton
            color="green"
            onClick={stopImpersonating}
            showLoadingSpinner={w18ApiResponse}
          >{t("stopImpersonating")}</SubmitButton>
        </div>
      )}
    </Alert>
  );
}

export default ImpersonateUserAlert;