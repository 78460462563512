import {
  B2CRoutesStrings,
  CommonRoutesStrings,
  ProductTypesStrings,
  UserTypesStrings,
} from "@src/helpers/stringHelpers";
import LandingPage from "@src/pages/b2c/LandingPage";
import NotFoundPage from "@src/pages/NotFoundPage";
import React from "react";
import { Route, Switch } from "react-router-dom";
import RouteWithoutLogin from "@src/components/RouteWithoutLogin";
import UserTypePickerRegisterPage from "@src/pages/b2c/UserTypePickerRegisterPage";
import LoginPage from "@src/pages/b2c/LoginPage";
import ForgotPasswordPage from "@src/pages/b2c/ForgotPasswordPage";
import EditPasswordPage from "@src/pages/b2c/EditPasswordPage";
import RegisterUserPage from "@src/pages/b2c/RegisterUserPage";
import PrivateRoute from "@src/components/PrivateRoute";
import LogoutPage from "@src/pages/LogoutPage";
import ConfirmEmailPage from "@src/pages/ConfirmEmailPage";
import ListProductsSearchPage from "@src/pages/b2c/ListProductsSearchPage";
import ShowProductPage from "@src/pages/b2c/ShowProductPage";
import ListOrdersPage from "@src/pages/ListOrdersPage";
import AskForOrderRefundPage from "@src/pages/b2c/AskForOrderRefundPage";
import StaticB2CHelpRoutes from "@src/components/ui/b2c/StaticB2CHelpRoutes";
import CheckoutPage from "@src/pages/b2c/CheckoutPage";
import ShowGuestOrderPage from "@src/pages/b2c/ShowGuestOrderPage";
import ShowRedsysOkPage from "@src/pages/b2c/ShowRedsysOkPage";
import ShowRedsysKoPage from "@src/pages/b2c/ShowRedsysKoPage";

const B2CRoutes = () => {
  return (
    <Switch>
      <Route exact path={CommonRoutesStrings.homePath}>
        <LandingPage />
      </Route>

      <RouteWithoutLogin exact path={B2CRoutesStrings.loginPath}>
        <LoginPage />
      </RouteWithoutLogin>

      <PrivateRoute exact path={CommonRoutesStrings.logoutPath}>
        <LogoutPage />
      </PrivateRoute>

      <RouteWithoutLogin exact path={B2CRoutesStrings.forgotPasswordPath}>
        <ForgotPasswordPage />
      </RouteWithoutLogin>

      <RouteWithoutLogin exact path={B2CRoutesStrings.editPasswordPath}>
        <EditPasswordPage />
      </RouteWithoutLogin>

      <RouteWithoutLogin exact path={B2CRoutesStrings.userTypePickerRegisterPath}>
        <UserTypePickerRegisterPage />
      </RouteWithoutLogin>

      <RouteWithoutLogin exact path={B2CRoutesStrings.registerClientPath}>
        <RegisterUserPage defaultUserType={UserTypesStrings.client} />
      </RouteWithoutLogin>

      <RouteWithoutLogin exact path={B2CRoutesStrings.registerProviderPath}>
        <RegisterUserPage defaultUserType={UserTypesStrings.provider} />
      </RouteWithoutLogin>

      <RouteWithoutLogin exact path={B2CRoutesStrings.registerServicesProviderPath}>
        <RegisterUserPage defaultUserType={UserTypesStrings.servicesProvider} />
      </RouteWithoutLogin>

      <RouteWithoutLogin exact path={B2CRoutesStrings.confirmEmailPath}>
        <ConfirmEmailPage />
      </RouteWithoutLogin>

      <Route exact path={B2CRoutesStrings.listProductsSearchPath}>
        <ListProductsSearchPage />
      </Route>

      <Route exact path={CommonRoutesStrings.showOwnProductPath}>
        <ShowProductPage productType={ProductTypesStrings.ownProduct} />
      </Route>

      <Route exact path={CommonRoutesStrings.showThirdPartyProductPath}>
        <ShowProductPage productType={ProductTypesStrings.thirdPartyProduct} />
      </Route>

      <Route exact path={B2CRoutesStrings.checkoutPath}>
        <CheckoutPage />
      </Route>

      <PrivateRoute exact path={CommonRoutesStrings.listOrdersPath}>
        <ListOrdersPage isB2C={true} />
      </PrivateRoute>

      <RouteWithoutLogin exact path={B2CRoutesStrings.showGuestOrderPath}>
        <ShowGuestOrderPage />
      </RouteWithoutLogin>

      <Route exact path="/redsys/pago-ok">
        <ShowRedsysOkPage />
      </Route>

      <Route exact path="/redsys/pago-ko">
        <ShowRedsysKoPage />
      </Route>

      <PrivateRoute exact path={B2CRoutesStrings.askForOrderRefundPath}>
        <AskForOrderRefundPage />
      </PrivateRoute>

      <StaticB2CHelpRoutes />

      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  );
};

export default B2CRoutes;
