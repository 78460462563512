import React, { cloneElement, useRef, useState } from "react";
import { useEffect } from "react";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import OrderService from "@src/services/orders/orderService";
import Spinner from "@src/components/ui/Spinner";
import { useTranslation } from "react-i18next";
import ListOrdersB2BSkin from "@src/components/orders/list/ListOrdersB2BSkin";
import ListOrdersB2CSkin from "@src/components/orders/b2c/list/ListOrdersB2CSkin";
import { useReactToPrint } from "react-to-print";
import PrintOrderWrapper from "@src/components/orders/b2c/PrintOrderWrapper";

const ListOrdersPage = ({ isB2C }) => {
  const [orders, setOrders] = useState();
  const [orderToPrint, setOrderToPrint] = useState();
  const [error, setError] = useState(false);
  const [orderToCancel, setOrderToCancel] = useState();
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const [clientFilter, setClientFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [dateMinFilter, setDateMinFilter] = useState("");
  const [dateMaxFilter, setDateMaxFilter] = useState("");
  const { t, ready: translationsReady } = useTranslation(["pages/orders/list", "common/orderStatuses"]);
  const listOrdersSkinToUse = isB2C ? <ListOrdersB2CSkin /> : <ListOrdersB2BSkin />;

  const refToOrderComponentToPrint = useRef();
  const handlePrint = useReactToPrint({
    content: () => refToOrderComponentToPrint.current,
    onAfterPrint: () => setOrderToPrint(),
  });

  const fetchOrders = async (clientFilter,dateMinFilter,dateMaxFilter,statusFilter) => {
    try {
      setOrders(await OrderService.indexFilters(clientFilter, dateMinFilter,dateMaxFilter, statusFilter));
    } catch (error) {
      createNotification(NotificationTypes.error, t("messages.genericRequestError"));
      setError(true);
    }
  };

  useEffect(() => {    
    translationsReady && fetchOrders(clientFilter ,dateMinFilter,dateMaxFilter ,statusFilter);
    // eslint-disable-next-line
  }, [translationsReady,clientFilter ,dateMinFilter,dateMaxFilter ,statusFilter]);

  useEffect(() => {
    orderToPrint && handlePrint();
    // eslint-disable-next-line
  }, [orderToPrint?.id]);

 

  const cancelOrder = async () => {
    const orderId = orderToCancel.id;
    setOrderToCancel();
    setW18ApiResponse(b => !b);

    try {
      await OrderService.cancelOrder(orderId);
      createNotification(NotificationTypes.success, t("messages.cancelAction.okResponse"));
      fetchOrders();
    } catch (error) {
      createNotification(NotificationTypes.error, t("messages.cancelAction.koResponse"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  const printOrder = order => {
    setOrderToPrint(order);
  };

  const listOrdersSkinWithPropsInjected = cloneElement(listOrdersSkinToUse, {
    orders, error, orderToCancel, w18ApiResponse, t, refToOrderComponentToPrint,
    cbCancelOrder: cancelOrder,
    cbSetOrderToCancel: setOrderToCancel,
    cbPrintOrder: printOrder,
    cbSetClientFilter: setClientFilter,
    cbSetDateMinFilter: setDateMinFilter,
    cbSetDateMaxFilter: setDateMaxFilter,
    cbSetStatusFilter: setStatusFilter,
  });

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <>
          {listOrdersSkinWithPropsInjected}
          <div className="hidden">
            <PrintOrderWrapper ref={refToOrderComponentToPrint} order={orderToPrint} t={t} />
          </div>
        </>
      )}
    </>
  );
};

export default ListOrdersPage;