import { NotificationManager } from 'react-notifications';

const NOTIFICATION_TIMEOUT = 5000; // 5000ms -> 5s
const REDIRECT_TIMEOUT = 3000; // same as before

export const NotificationTypes = {
  info: "info",
  success: "success",
  warning: "warning",
  error: "error"
};

export const createNotification = (type, msg, title) => {
  switch (type) {
    case NotificationTypes.info:
      return NotificationManager.info(msg, title, NOTIFICATION_TIMEOUT);
    case NotificationTypes.success:
      return NotificationManager.success(msg, title, NOTIFICATION_TIMEOUT);
    case NotificationTypes.warning:
      return NotificationManager.warning(msg, title, NOTIFICATION_TIMEOUT);
    case NotificationTypes.error:
      return NotificationManager.error(msg, title, NOTIFICATION_TIMEOUT);
    default: 
      return NotificationManager.info(msg, title, NOTIFICATION_TIMEOUT);
  }
};

export const delayedRedirect = (url, customRedirectTimeout = null) => {
  setTimeout(function(){
      window.location.href = url;
  }, customRedirectTimeout ? customRedirectTimeout : REDIRECT_TIMEOUT);
}