import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import TableCard from "@src/components/ui/TableCard";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import EyeButton from "@src/components/ui/buttons/EyeButton";
import { APIEndpointsStrings, DefinedRoutesStrings } from "@src/helpers/stringHelpers";
import IconLinkButton from "@src/components/ui/buttons/IconLinkButton";
import IconButton from "@src/components/ui/buttons/IconButton";
import {isServicesProvider} from "@src/helpers/userHelpers";

const ListServicesTable = ({ currentPageData : serviceList, servicesProviderPerspective, cbDeleteService }) => {
  const { t, ready: translationsReady } = useTranslation(["components/services/listTable", "common/list", "pages/services/list"]);

  const getGeneralHeadersArray = () => Object.values(t("headers")).concat(t("actionsTableHeader", { ns: "common/list" })),
    getServicesProviderHeadersArray = () => [
      t("headers.id"),
      t("headers.name"),
      t("headers.serviceCategory"),
      t("actionsTableHeader", { ns: "common/list" }),
    ];

  const formatDataMatrix = () => {
    let matrix = [];

    serviceList.forEach(service => {
      if (servicesProviderPerspective) {
        matrix.push([
          service.id,
          service.name,
          service.serviceCategory.name,
          <>
            <EyeButton
              targetPath={DefinedRoutesStrings.showServicePath.replace(APIEndpointsStrings.idLocators.id, service.id)}
            />
            <IconLinkButton
              to={DefinedRoutesStrings.editServicePath.replace(APIEndpointsStrings.idLocators.id, service.id)}
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </IconLinkButton>
            <IconButton
              color="red"
              onClick={() => cbDeleteService(service)}
            ><FontAwesomeIcon icon={faTrashAlt} /></IconButton>
          </>
        ]);
      } else {
        matrix.push([
          service.id,
          service.name,
          service.professionalEmail,
          service.serviceCategory.name,
          <>
            <EyeButton
              targetPath={DefinedRoutesStrings.showServicePath.replace(APIEndpointsStrings.idLocators.id, service.id)}
            />
            <IconLinkButton
              to={DefinedRoutesStrings.editServicePath.replace(APIEndpointsStrings.idLocators.id, service.id)}
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </IconLinkButton>
            <IconButton
              color="red"
              onClick={() => cbDeleteService(service)}
            ><FontAwesomeIcon icon={faTrashAlt} /></IconButton>
          </>
        ]);
      }      
    });

    return matrix;
  }
    

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
        title={isServicesProvider()
              ? t("titles.myServices",{ ns:"pages/services/list"})
              : t("titles.generic",{ ns:"pages/services/list"})}
          headersArray={servicesProviderPerspective ? getServicesProviderHeadersArray() : getGeneralHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

export default ListServicesTable;