import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import ListServicesTable from "@src/components/products/services/ListServicesTable";
import LinkButton from "@src/components/ui/buttons/LinkButton";
import PaginateData from "@src/components/ui/PaginateData";
import { getCurrentUserData } from "@src/helpers/localStorageHelpers";
import {
  createNotification,
  NotificationTypes,
} from "@src/helpers/notificationHelpers";
import {
  CommonRoutesStrings,
  DefinedRoutesStrings,
} from "@src/helpers/stringHelpers";
import {
  isServicesProvider,
  currentUserHasWritePermissions,
} from "@src/helpers/userHelpers";
import ServiceService from "@src/services/services/serviceService";
import { allowedToAccessRoute } from "@src/helpers/permissionHelpers";
import Spinner from "@src/components/ui/Spinner";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import Button from "@src/components/ui/buttons/Button";
import LoadServicesXlsxDialog from "@src/components/products/services/LoadServicesXlsxDialog";
import FilterServicesForm from "@src/components/forms/FilterServicesForm";
import BooleanResponseDialog from "@src/components/ui/BooleanResponseDialog";

const ListServicesPage = () => {
  const [serviceList, setServiceList] = useState();
  const [showLoadServicesXlsxDialog, setShowLoadServicesXlsxDialog] =
    useState(false);
  const [error, setError] = useState(false);
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const [showFilterForm, setShowFilterForm] = useState(true);
  const [nameFilter, setNameFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [providerFilter, setProviderFilter] = useState("");
  const [deleteLocation, setLocationToDelete] = useState("");

  const { t, ready: translationsReady } = useTranslation([
    "pages/services/list",
    "common/list",
    "common/filterForm",
  ]);
  const SERVICE_PER_PAGE = 5;

  const toggleFiltering = () => {
    setShowFilterForm((show) => !show);
    setNameFilter("");
    setCategoryFilter("");
    isServicesProvider()
      ? setProviderFilter(getCurrentUserData().id)
      : setProviderFilter("");
  };

  const fetchServices = async () => {
    try {
      let services;
      if (isServicesProvider()) {
        services = await ServiceService.indexServicesProvider(
          getCurrentUserData().id
        );
      } else {
        // Operator and Admin
        services = await ServiceService.index();
      }
      setServiceList(services);
    } catch (error) {
      createNotification(
        NotificationTypes.error,
        t("messages.cannotRetrieveServices")
      );
      setError(true);
    }
  };

  useEffect(() => {
    translationsReady && fetchServices();
    // eslint-disable-next-line
  }, [translationsReady]);

  useEffect(() => {
    const servicesbyfilters = async () => {
      setServiceList(
        await ServiceService.indexServicesFilter(
          nameFilter,
          categoryFilter,
          providerFilter
        )
      );
    }
    servicesbyfilters();
  }, [nameFilter, categoryFilter, providerFilter]);

  const handleDeleteAction = async () => {
      setW18ApiResponse((b) => !b);

      try {
        await ServiceService.destroyService(deleteLocation.id);
        setServiceList(serviceList.filter((s) => s.id !== deleteLocation.id));
        createNotification(
          NotificationTypes.success,
          t("messages.deleteAction.ok")
        );
      } catch (_) {
        createNotification(
          NotificationTypes.error,
          t("messages.deleteAction.ko")
        );
      } finally {
        setLocationToDelete();
        setW18ApiResponse((b) => !b);
      }
  };

  const closeLoadServicesXlsxDialog = async (shouldReloadServices) => {
    setShowLoadServicesXlsxDialog(false);
    shouldReloadServices && (await fetchServices());
  };

  function handleFilterInputChange(setStateCallback, newState) {
    setStateCallback(newState);
  }

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <>
          {error && <Redirect to={CommonRoutesStrings.homePath} />}
          <div className="flex justify-center">
            <Card className="mt-7 mb-2">
              <CardHeader color="yellow" variant="gradient" className="p-5">
                <Typography variant="h5" color="white">
                  {t("createServiceBox.title")}
                </Typography>
              </CardHeader>
              <CardBody className="space-x-2">
                <LinkButton
                  to={DefinedRoutesStrings.createServicePath}
                  color="green"
                  disabled={!currentUserHasWritePermissions()}
                >
                  {t("createServiceBox.buttons.single")}
                </LinkButton>
                <Button
                  color="purple"
                  onClick={() => setShowLoadServicesXlsxDialog(true)}
                  disabled={!currentUserHasWritePermissions()}
                >
                  {t("createServiceBox.buttons.bulk")}
                </Button>
              </CardBody>
            </Card>
          </div>
     
          <div className="flex justify-center items-center mt-2 space-x-2 mb-2">
            {allowedToAccessRoute(
              DefinedRoutesStrings.listServiceCategories
            ) && (
              <LinkButton to={DefinedRoutesStrings.listServiceCategories}>
                {t("buttons.serviceCategories")}
              </LinkButton>
            )}
            {
              <Button className={"relative"} onClick={toggleFiltering}>
                {!showFilterForm
                  ? t("enableFilter", { ns: "common/filterForm" })
                  : t("disableFilter", { ns: "common/filterForm" })}
              </Button>
            }
          </div>
          {!serviceList ? (
            <Spinner />
          ) : (
            <div className="grid grid-cols-1 place-items-center">
              {showFilterForm && (
                <FilterServicesForm
                  color="yellow"
                  className="mt-11 !mb-5 w-full"
                  cbSetName={(name) =>
                    handleFilterInputChange(setNameFilter, name)
                  }
                  cbSetServiceCategory={(category) =>
                    handleFilterInputChange(setCategoryFilter, category)
                  }
                  cbSetServiceProvider={(provider) =>
                    handleFilterInputChange(setProviderFilter, provider)
                  }
                />
              )}
              <PaginateData
                data={serviceList}
                emptyDataArrayMsg={t("messages.noServicesToList")}
                perPage={SERVICE_PER_PAGE}
              >
                <ListServicesTable
                  servicesProviderPerspective={isServicesProvider()}
                  cbDeleteService={(service) => setLocationToDelete(service)}
                />
              </PaginateData>
            </div>
          )}
            <BooleanResponseDialog
            open={!!deleteLocation}
            noCallback={() => setLocationToDelete()}
            yesCallback={handleDeleteAction}
            showLoadingSpinner={w18ApiResponse}
          >
            {t("deleteModalMessage", { ns: "common/list" })}
          </BooleanResponseDialog>
          <LoadServicesXlsxDialog
            open={showLoadServicesXlsxDialog}
            handler={closeLoadServicesXlsxDialog}
          />
          {w18ApiResponse && <Spinner />}
        </>
      )}
    </>
  );
};

export default ListServicesPage;
