import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import IconButton from '@src/components/ui/buttons/IconButton';
import {faBan} from "@fortawesome/free-solid-svg-icons/faBan";

const BanButton = props => {
  return (
    <IconButton
      color="red"
      {...props}
    ><FontAwesomeIcon icon={faBan} /></IconButton>
  );
}
 
export default BanButton;