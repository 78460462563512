import axios from "@src/helpers/axiosInstance";
import { formattedStrDateToYYYYMMDD } from "@src/helpers/dateHelpers";
import { getJWT } from "@src/helpers/localStorageHelpers";
import { APIEndpointsStrings } from "@src/helpers/stringHelpers";

class ServiceResourceService {
  static create = (serviceId, dateId, resourcesData) => {
    const formattedResourcesData = resourcesData.map(r => this.formatResourceToSend(r));
    const endpoint = APIEndpointsStrings.createServiceResourcesPath
      .replace(APIEndpointsStrings.idLocators.id, serviceId);

    return axios.post(endpoint, {
      configuration: {
        // Atm, we only support creating resources for a single date
        dates_product_id: [ dateId ],
        resources: formattedResourcesData,
      }
    }, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    })
  };

  static destroy = (serviceId, dateId, resourceId) => {
    const endpoint = APIEndpointsStrings.serviceResourcePath
      .replace(APIEndpointsStrings.idLocators.serviceId, serviceId)
      .replace(APIEndpointsStrings.idLocators.productDateId, dateId)
      .replace(APIEndpointsStrings.idLocators.id, resourceId);

    return axios.delete(endpoint, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });
  };

  static assignServiceResourceToTimeslice = (productId, productDateId, timesliceId, serviceDateId, resourceUnitId, startTime, endTime) => {
    const endpoint = APIEndpointsStrings.addServiceToProductPath
      .replace(APIEndpointsStrings.idLocators.productId, productId)
      .replace(APIEndpointsStrings.idLocators.productDateId, productDateId)
      .replace(APIEndpointsStrings.idLocators.id, timesliceId);

    return axios.post(endpoint, {
      product_service: {
        date_service_id: serviceDateId,
        unit_service_id: resourceUnitId,
        time_start: startTime,
        time_end: endTime,
      }
    }, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });
  };

  static disassociateServiceResourceFromTimeslice = (productId, productDateId, timesliceId, serviceDateId, resourceUnitId) => {
    const endpoint = APIEndpointsStrings.removeServiceFromProductPath
      .replace(APIEndpointsStrings.idLocators.productId, productId)
      .replace(APIEndpointsStrings.idLocators.productDateId, productDateId)
      .replace(APIEndpointsStrings.idLocators.id, timesliceId);

    return axios.post(endpoint, {
      product_service: {
        date_service_id: serviceDateId,
        unit_service_id: resourceUnitId,
      }
    }, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });
  };

  static formatReceivedServiceResource(resource) {
    return {
      id: resource.id,
      name: resource.name,
      priceHour: resource.price_hour,
      quantity: resource.quantity,
      resourceUnits: resource.units_resources.map(resourceUnit => ({
        id: resourceUnit.id,
        capacity: resourceUnit.capacity,
        disabledAt: !resourceUnit.disabledAt ? null : new Date(formattedStrDateToYYYYMMDD(resourceUnit.disabled_at)),
        rangeOfAvailability: {
          startTime: resourceUnit.range_of_availability.time_start,
          endTime: resourceUnit.range_of_availability.time_end,
        },
        taken: resourceUnit.taken.map(resourceUnitTaken => ({
          id: resourceUnitTaken.id,
          startTime: resourceUnitTaken.time_start,
          endTime: resourceUnitTaken.time_end,
          timeSliceId: resourceUnitTaken.time_slice_id,
        })),
      })),
    };
  }

  static formatResourceToSend = resource => ({
    name: resource.name,
    quantity: Number(resource.quantity),
    capacity: Number(resource.capacity),
    price_hour: Number(resource.priceHour),
    availability: {
      time_start: resource.startTime,
      time_end: resource.endTime,
    }
  });
}

export default ServiceResourceService;