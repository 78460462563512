import axios from "@src/helpers/axiosInstance";
import { checkStrDatesSameDay } from "@src/helpers/dateHelpers";
import { getJWT } from "@src/helpers/localStorageHelpers";
import { APIEndpointsStrings, ProductTypesStrings } from "@src/helpers/stringHelpers";

const filterByNotValidatedThirdPartyProducts = "q[type_eq]=ThirdPartyProduct&q[accepted_at_null]=1&q[denied_at_null]=1";

export const indexProductsRequest = () =>
  axios.get("/products", {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });

export const getProductByName = (name) => 
  axios.get(`/products/?q[translations_name_cont]=${name}`, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });

export const getCountThirdPartyProductsToValidate = () =>
  axios.get(`/products/count?${filterByNotValidatedThirdPartyProducts}`, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });

export const getNotValidatedThirdPartyProductsRequest = () =>
  axios.get(`/products?${filterByNotValidatedThirdPartyProducts}`, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });

export const getValidatedProductsRequest = () =>
  axios.get(APIEndpointsStrings.productsPath + "?q[validated_products]=true", {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });
  
export const isThirdPartyProduct = type =>
  type === ProductTypesStrings.thirdPartyProduct;

export const isOwnProduct = type =>
  type === ProductTypesStrings.ownProduct;

export const getProductRequest = (productId, productType) => {
  const requestPath = isThirdPartyProduct(productType) ?
    APIEndpointsStrings.thirdPartyProductPath : APIEndpointsStrings.ownProductPath;

  return axios.get(requestPath.replace(APIEndpointsStrings.idLocators.id, productId), {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });
};

export const createThirdPartyProductRequest = (name, ownerPrice, capacity, categoryIds, providerId) => {
  return axios.post("/third_party_products", {
    product: {
      name, capacity,
      owner_price: ownerPrice,
      category_ids: categoryIds,
      provider_id: providerId
    }
  }, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });
};

export const createOwnProductRequest = (name, ownerPrice, capacity, categoryIds, servicesProviderId) => {
  return axios.post(APIEndpointsStrings.ownProductsPath, {
    product: {
      name, capacity,
      owner_price: ownerPrice,
      category_ids: categoryIds,
      services_provider_id: servicesProviderId
    }
  }, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });
};

export const createProduct = (productData, productType) => {
  if (isOwnProduct(productType))
    return axios.post(APIEndpointsStrings.ownProductsPath, {
      product: productData
    }, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });

  if (isThirdPartyProduct(productType))
    return axios.post("/third_party_products", {
      product: productData
    }, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });

  return null;
};

export const editThirdPartyProductBasicInfoRequest = (productId, data) =>
  axios.patch(APIEndpointsStrings.patchThirdPartyProduct.replace(":id", productId), {
    product: data
  }, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });

export const editOwnProductBasicInfoRequest = (productId, data) =>
  axios.patch(APIEndpointsStrings.ownProductPath.replace(APIEndpointsStrings.idLocators.id, productId), {
    product: data
  }, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });

export const editThirdPartyProductRequest = (productId, name, ownerPrice, capacity, categoryIds, providerId) => {
  return axios.put(`/third_party_products/${productId}`, {
    product: {
      name, capacity,
      owner_price: ownerPrice,
      category_ids: categoryIds,
      provider_id: providerId
    }
  }, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });
};

export const editOwnProductRequest = (productId, name, ownerPrice, capacity, categoryIds, servicesProviderId) => {
  return axios.put(APIEndpointsStrings.ownProductPath.replace(APIEndpointsStrings.idLocators.id, productId), {
    product: {
      name, capacity,
      owner_price: ownerPrice,
      category_ids: categoryIds,
      services_provider_id: servicesProviderId
    }
  }, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });
};

export const deleteThirdPartyProductRequest = productId =>
    axios.delete(APIEndpointsStrings.patchThirdPartyProduct.replace(APIEndpointsStrings.idLocators.id, productId), {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });

export const deleteOwnProductRequest = productId =>
  axios.delete(APIEndpointsStrings.ownProductPath.replace(APIEndpointsStrings.idLocators.id, productId), {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });

export const deleteProduct = (productType, productId) => {
  switch (productType) {
    case ProductTypesStrings.thirdPartyProduct:
      return deleteThirdPartyProductRequest(productId);
    case ProductTypesStrings.ownProduct:
      return deleteOwnProductRequest(productId);
    default:
      throw new Error(`delete-product: product type ${productType} not allowed`);
  }  
};

export const deleteCancelProduct = (productId,comment) =>
    axios.patch(APIEndpointsStrings.productCancelPath.replace(APIEndpointsStrings.idLocators.id, productId),
    {
      comment
    },
    {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });

export const validateThirdPartyProductRequest = productId =>
  axios.patch(`/third_party_products/${productId}/validate`, {}, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });

export const denyThirdPartyProductRequest = productId =>
  axios.patch(`/third_party_products/${productId}/denied`, {}, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });

export const createProductConfiguration = (productId, configurationData) =>
  axios.post(APIEndpointsStrings.createProductConfiguration.replace(":product_id", productId), {
    product: {
      configurations: configurationData
    }
  }, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });

export const deleteProductConfiguration = (productId, configurationId) =>
  axios.delete(APIEndpointsStrings.deleteProductConfiguration.replace(":product_id", productId).replace(":id", configurationId), {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });

export const createProductDate = (productId, dateData) => {
  const requestPath = APIEndpointsStrings.productDatesPath;

  return axios.post(requestPath.replace(APIEndpointsStrings.idLocators.id, productId), {
    configuration: dateData
  }, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });
};  

export const deleteProductDate = (productId, dateId) => {
  const requestPath = APIEndpointsStrings.productDatePath;
  const requestPathWithIDs = requestPath.replace(APIEndpointsStrings.idLocators.productId, productId)
    .replace(APIEndpointsStrings.idLocators.id, dateId);
  
  return axios.delete(requestPathWithIDs, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });
};

export const cancelProductDate = (productId, dateId, comment) => {
    const requestPathWithIDs = APIEndpointsStrings.productDateCancelPath
      .replace(APIEndpointsStrings.idLocators.productId, productId)
      .replace(APIEndpointsStrings.idLocators.productDateId, dateId);
  
    return axios.patch(requestPathWithIDs,{comment},
     {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });
};

export const createProductTimeslice = (productId, productType, productDateId, timesliceData) => {
  const requestPath = APIEndpointsStrings.productTimeSlicesPath;
  const requestPathWithIDs = requestPath.replace(APIEndpointsStrings.idLocators.productId, productId)
    .replace(APIEndpointsStrings.idLocators.productDateId, productDateId);

  return axios.post(requestPathWithIDs, {
    product: {
      time_slice: timesliceData
    }
  }, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });
};

export const updateProductTimeslice = (productId, productDateId, timesliceId, timesliceData) => {
  const requestPathWithIDs = APIEndpointsStrings.productTimeslicePath
    .replace(APIEndpointsStrings.idLocators.productId, productId)
    .replace(APIEndpointsStrings.idLocators.productDateId, productDateId)
    .replace(APIEndpointsStrings.idLocators.id, timesliceId);
  
  return axios.patch(requestPathWithIDs, {
    time_slice: timesliceData
  }, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });
};

export const deleteProductTimeslice = (productId, productDateId, timesliceId) => {
  const requestPathWithIDs = APIEndpointsStrings.productTimeslicePath
    .replace(APIEndpointsStrings.idLocators.productId, productId)
    .replace(APIEndpointsStrings.idLocators.productDateId, productDateId)
    .replace(APIEndpointsStrings.idLocators.id, timesliceId);

  return axios.delete(requestPathWithIDs, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });
};

export const cancelProductTimeslice = (productId, productDateId, timesliceId,comment) => {
  const requestPathWithIDs = APIEndpointsStrings.productTimeslicePath
    .replace(APIEndpointsStrings.idLocators.productId, productId)
    .replace(APIEndpointsStrings.idLocators.productDateId, productDateId)
    .replace(APIEndpointsStrings.idLocators.id, timesliceId).concat("/cancel_event");

  return axios.patch(requestPathWithIDs,{comment},
   { headers: {
    Authorization: `Bearer ${getJWT()}`
  }
});
};

export const changeStateProductTimeslice = (productId, productDateId, timesliceId, status) => {  
  const requestPathWithIDs = APIEndpointsStrings.statusProductTimeslicePath
    .replace(APIEndpointsStrings.idLocators.productId, productId)
    .replace(APIEndpointsStrings.idLocators.productDateId, productDateId)
    .replace(APIEndpointsStrings.idLocators.id, timesliceId);

  const body = status ? { time_slice: { enable: true } } : { time_slice: { disable: true  } }
  
  return axios.patch(requestPathWithIDs, body, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });
};

export const createProductPrice = (productId, productDateId, timesliceId, prices) => {
  const requestPath = APIEndpointsStrings.productPricesPath;
  const requestPathWithIDs = requestPath.replace(APIEndpointsStrings.idLocators.productId, productId)
    .replace(APIEndpointsStrings.idLocators.productDateId, productDateId)
    .replace(APIEndpointsStrings.idLocators.timesliceId, timesliceId);
  
  return axios.post(requestPathWithIDs, {
    product: {
      price: prices
    }
  }, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });
};

export const updateProductPrice = (productId, productDateId, timesliceId, priceId, priceData) => {
  const requestPath = APIEndpointsStrings.productPricePath;
  const requestPathWithIDs = requestPath.replace(APIEndpointsStrings.idLocators.productId, productId)
    .replace(APIEndpointsStrings.idLocators.productDateId, productDateId)
    .replace(APIEndpointsStrings.idLocators.timesliceId, timesliceId)
    .replace(APIEndpointsStrings.idLocators.id, priceId);

  return axios.patch(requestPathWithIDs, {
    price: priceData
  }, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });
};

export const deleteProductPrice = (productId, productDateId, timesliceId, priceId) => {
  const requestPath = APIEndpointsStrings.productPricePath;
  const requestPathWithIDs = requestPath.replace(APIEndpointsStrings.idLocators.productId, productId)
    .replace(APIEndpointsStrings.idLocators.productDateId, productDateId)
    .replace(APIEndpointsStrings.idLocators.timesliceId, timesliceId)
    .replace(APIEndpointsStrings.idLocators.id, priceId);

  return axios.delete(requestPathWithIDs, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });
};

export const getProductDatesWithAvailableTimeslices = product =>
  product.dates.reduce((currDates, date) =>
    date.timeSlices.some(t => t.deadlineSale != null && t.deadlineSale.valueOf() > new Date().valueOf()) ? currDates.concat(date.date) : currDates
  , []);

export const findAvailableDatesInProductGivenLanguage = (product, language) =>
  product.dates.reduce((dates, currDate) => {
    const currDateHasTimeslicesOnSaleAndWithSelectedLanguage =
      currDate.timeSlices.some(timeSlice => timeSlice.deadlineSale?.valueOf() > new Date().valueOf() && timeSlice.language === language && !timeSlice.disabledAt);

    return currDateHasTimeslicesOnSaleAndWithSelectedLanguage ? dates.concat(currDate.date) : dates;
  }, []);

export const findAvailableHoursInProductDateGivenLanguage = (productDate, language) =>
  productDate.timeSlices.reduce((timeSlices, currTimeSlice) => {
    const currTimeSliceHasDesiredLanguage = currTimeSlice.language === language;

    return currTimeSliceHasDesiredLanguage ? timeSlices.concat(currTimeSlice.beginsAt) : timeSlices;
  }, []);

export const findProductDateForStrDate = (product, strDate) =>
  product.dates.find(d => checkStrDatesSameDay(d.date, strDate));