import { faEye } from "@fortawesome/free-regular-svg-icons";
import React from "react";
import { useHistory } from "react-router-dom";
import GrayIconButton from "@src/components/ui/buttons/GrayIconButton";

const EyeButton = ({ targetPath, onClick, ...props }) => {
  const history = useHistory();

  return (
    <GrayIconButton
      icon={faEye}
      onClick={targetPath ? () => history.push(targetPath) : onClick}
      {...props}
    />
  );
};

export default EyeButton;