import { Form, Formik } from "formik";
import React, { useState } from "react";
import { RegularityTypesStrings } from "@src/helpers/stringHelpers";
import ErrorText from "@src/components/ui/ErrorText";
import { generateDatesForDateRange } from "@src/components/products/product_wizard/Regularity";
import { emptyArray } from "@src/helpers/arrayHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { YYYYMMDDtoDDMM } from "@src/helpers/dateHelpers";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import { Dialog } from "@material-tailwind/react";
import FormikSelect from "@src/components/forms/FormikSelect";
import SubmitButton from "@src/components/ui/buttons/SubmitButton";
import FormikTextInput from "@src/components/forms/FormikTextInput";
import BasicFormTemplate, {
  FooterType,
} from "@src/components/forms/BasicFormTemplate";

export const isDailyRegularity = regularityType => regularityType === RegularityTypesStrings.daily;
export const isWeeklyRegularity = regularityType => regularityType === RegularityTypesStrings.weekly;
export const isMonthlyRegularity = regularityType => regularityType === RegularityTypesStrings.monthly;
export const isYearlyRegularity = regularityType => regularityType === RegularityTypesStrings.yearly;

const RegularityTypeSelectorDialog = ({ open, handler: cbCancelRegularity, selectedDateRanges, cbSubmitRegularity }) => {
  const [regularityType, setRegularityType] = useState();
  const [error, setError] = useState();
  const [selectedWeekDays, setSelectedWeekDays] = useState([]);
  const [selectedMonthDays, setSelectedMonthDays] = useState([]);
  const [selectedYearDays, setSelectedYearDays] = useState([]);
  const { t, ready: translationsReady } = useTranslation(["components/products/create/regularity", "common/form", "common/regularityTypes", "common/weekDays", "common/list"]);
  const MAX_NUMBER_DAYS_MONTH = 31;

  const getSpecificRegularityData = () => {
    if (isWeeklyRegularity(regularityType)) return selectedWeekDays;
    if (isMonthlyRegularity(regularityType)) return selectedMonthDays;
    if (isYearlyRegularity(regularityType)) return selectedYearDays;
    // Daily regularity does not require extra data, so returns null
    return null;
  };

  const getRegularitySelectorStyling = cbCheckSelected =>
    `border px-2 py-1 rounded ${cbCheckSelected(regularityType) ? "bg-yellow-400 border-yellow-400 font-bold cursor-default" : "hover:bg-yellow-200"}`;

  const renderWeekDaySelector = () => Object.values(t("simplified", { ns: "common/weekDays" })).map((weekDay, index) =>
    <div
      key={`weekday-selector-${index}`}
      className={`border rounded-lg py-2 px-4 cursor-pointer ${isWeekDaySelected(index) ? "bg-yellow-400 border-yellow-400 hover:bg-yellow-500" : "hover:bg-yellow-200"}`}
      onClick={() => toggleWeekDaySelection(index)}
    >{weekDay}</div>
  );

  const selectRegularityType = regularityType => {
    setError(null);
    setRegularityType(regularityType);
  };

  const isWeekDaySelected = weekDayIndex => selectedWeekDays.some(w => w === weekDayIndex);
  const toggleWeekDaySelection = weekDayIndex => {
    if (isWeekDaySelected(weekDayIndex))
      setSelectedWeekDays(selectedWeekDays.filter(w => w !== weekDayIndex));
    else
      setSelectedWeekDays(selectedWeekDays.concat(weekDayIndex));
  };

  const removeMonthDay = monthDay => setSelectedMonthDays(selectedMonthDays.filter(d => d !== monthDay));

  const renderMonthDaySelections = () => 
    <div className="flex space-x-1 my-2 overflow-x-auto">
      {selectedMonthDays.map((day, index) => 
        <div
          key={`beginsat-badge-${index}`}
          className="rounded bg-gray-200 px-1 whitespace-nowrap"
        >{day}<button type="button" className="px-1" onClick={() => removeMonthDay(day)}><FontAwesomeIcon icon={faTimes} color="gray" /></button></div>
      )}
    </div>;

  const handleNewMonthDay = async (values, actions) => {
    let sortedSelectedMonthDays = selectedMonthDays.concat(values.date);
    sortedSelectedMonthDays = sortedSelectedMonthDays.sort((a, b) => Number(a) - Number(b));
    setSelectedMonthDays(sortedSelectedMonthDays);
    actions.resetForm({ values: { date: "" } });
  };

  const getMonthDayOptions = () => [...Array(MAX_NUMBER_DAYS_MONTH).keys()].reduce((monthDayOptions, monthDay) => {
    const currentMonthDay = String(monthDay + 1);
    return !selectedMonthDays.some(d => d === currentMonthDay) ?
      monthDayOptions.concat({ value: currentMonthDay, label: currentMonthDay }) : monthDayOptions;
  }, []);

  const removeYearDay = index => setSelectedYearDays(selectedYearDays.filter((yearDay, i) => i !== index));

  const renderYearDaySelections = () =>
    <div className="flex space-x-1 my-2 overflow-x-auto">
      {selectedYearDays.map((day, index) => 
        <div
          key={`yearDaySelection-${index}`}
          className="rounded bg-gray-200 px-1 whitespace-nowrap"
        >{day}<button type="button" className="px-1" onClick={() => removeYearDay(index)}><FontAwesomeIcon icon={faTimes} color="gray" /></button></div>
      )}
    </div>;

  const validateYearDay = values => selectedYearDays.some(day => day === YYYYMMDDtoDDMM(values.date)) ?
    { date: "components/products/create/regularity:validations.dayAlreadySelected" } : {};

  const handleNewYearDay = async (values, actions) => {
    let sortedSelectedYearDays = selectedYearDays.concat(YYYYMMDDtoDDMM(values.date));
    sortedSelectedYearDays.sort((a, b) => {
      const [dayA, monthA] = a.split("/"), [dayB, monthB] = b.split("/");
      let compare = Number(monthA) - Number(monthB);
      if (compare === 0) {
        compare = Number(dayA) - Number(dayB);
      }
      return compare;
    });
    setSelectedYearDays(sortedSelectedYearDays);
    actions.resetForm({ values: { date: "" } });
  };

  const canGenerateDatesInDateRange = () => selectedDateRanges.every(dateRange => {
    if (isDailyRegularity(regularityType)) {
      return true;
    }
    if (isWeeklyRegularity(regularityType) || isMonthlyRegularity(regularityType) || isYearlyRegularity(regularityType)) {
      return generateDatesForDateRange(dateRange, regularityType, getSpecificRegularityData()).length > 0;
    }
    return false;
  });

  const hasSubmissionErrors = () =>
    regularityType == null
    ||
    (isWeeklyRegularity(regularityType) && selectedWeekDays.length === 0)
    ||
    (isMonthlyRegularity(regularityType) && emptyArray(selectedMonthDays))
    ||
    (isYearlyRegularity(regularityType) && emptyArray(selectedYearDays))
    ||
    !canGenerateDatesInDateRange();

  const handleRegularitySubmission = () => {
    if ((
      selectedDateRanges.some(dateRange => dateRange.regularityType && dateRange.dates)
      && window.confirm(t("labels.regularitySelectorModal.overwriteConfigurationsMessage"))
    ) || (
      selectedDateRanges.every(dateRange => dateRange.dates == null))
    ) {
      if (isWeeklyRegularity(regularityType)) {
        const selectedWeekdays = getSpecificRegularityData();
        selectedWeekdays.sort();
        submitDialog({ regularityType, regularityData: selectedWeekdays });
      } else {
        submitDialog({ regularityType, regularityData: getSpecificRegularityData() });
      }
    }
  };

  // needed as Dialog's children component is never unmounted, only not shown
  const clearComponentState = () => {
    setError();
    setRegularityType();
    setSelectedMonthDays([]);
    setSelectedWeekDays([]);
    setSelectedYearDays([]);
  };

  const submitDialog = data => {
    cbSubmitRegularity(data);
    clearComponentState();
  };

  const closeDialog = () => {
    cbCancelRegularity();
    clearComponentState();
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <Dialog
          className="p-2"
          open={open}
          handler={closeDialog}
        >
          <BasicFormTemplate
              className="mt-8"
              formTitle={t("labels.regularitySelectorModal.type")}
              backCallback={closeDialog}
              nextCallback={handleRegularitySubmission}
              disableSubmitButton={hasSubmissionErrors()}
              footerType={FooterType.BACK_SUBMIT_BUTTONS}
              headerColor="teal"
            >
          <div className="flex my-2 space-x-4">
            <button
              onClick={() => selectRegularityType(RegularityTypesStrings.daily)}
              className={getRegularitySelectorStyling(isDailyRegularity)}
            >{t("daily", { ns: "common/regularityTypes" })}</button>
            <button
              onClick={() => selectRegularityType(RegularityTypesStrings.weekly)}
              className={getRegularitySelectorStyling(isWeeklyRegularity)}
            >{t("weekly", { ns: "common/regularityTypes" })}</button>
            <button
              onClick={() => selectRegularityType(RegularityTypesStrings.monthly)}
              className={getRegularitySelectorStyling(isMonthlyRegularity)}
            >{t("monthly", { ns: "common/regularityTypes" })}</button>
            <button
              onClick={() => selectRegularityType(RegularityTypesStrings.yearly)}
              className={getRegularitySelectorStyling(isYearlyRegularity)}
            >{t("yearly", { ns: "common/regularityTypes" })}</button>
          </div>
          {regularityType && 
            <div className="border rounded p-2">
              {isDailyRegularity(regularityType) &&
                <p>{t("labels.regularitySelectorModal.dailyRegularityDescription")}</p>
              }
              {isWeeklyRegularity(regularityType) &&
                <>
                  <p>{t("labels.regularitySelectorModal.weeklyRegularitySelect")}</p>
                  <div className="flex space-x-2 mt-2">
                    {renderWeekDaySelector()}
                  </div>
                </>
              }
              {isMonthlyRegularity(regularityType) &&
                <>
                  <p>{t("labels.regularitySelectorModal.monthlyRegularity.description")}</p>
                  <Formik
                    initialValues={{ date: "" }}
                    onSubmit={handleNewMonthDay}
                  >
                    {({ values }) =>
                      <Form>
                        <div className="inline-grid grid-cols-2 space-x-2 my-2">
                          <div>
                            <FormikSelect
                              label={t("labels.regularitySelectorModal.monthlyRegularity.selectPlaceholder")}
                              name="date"
                              options={getMonthDayOptions()}
                            />
                            <p className="text-gray-400">{t("labels.regularitySelectorModal.monthlyRegularity.selectDescription")}</p>
                          </div>
                          <div className="flex">
                            <SubmitButton
                              color="green"
                              className="self-start"
                              disabled={!values.date}
                            >{t("add", { ns: "common/list" })}</SubmitButton>
                          </div>
                        </div>
                      </Form>
                    }
                  </Formik>
                  <p>{t("labels.regularitySelectorModal.monthlyRegularity.disclaimer")}</p>
                  {!emptyArray(selectedMonthDays) &&
                    <>
                      <div className="border-t m-2" />
                      <p>{t("labels.regularitySelectorModal.monthlyYearlyRegularitySelectedDays")}</p>
                      {renderMonthDaySelections()} 
                    </>
                  }
                </>
              }
              {isYearlyRegularity(regularityType) &&
                <>
                  <p>{t("labels.regularitySelectorModal.yearlyRegularity.description")}</p>
                  <Formik
                    initialValues={{ date: "" }}
                    onSubmit={handleNewYearDay}
                    validate={validateYearDay}
                  >
                    {({ values }) => (
                      <Form>
                        <div className="inline-grid grid-cols-2 space-x-2 my-2">
                          <FormikTextInput
                            type="date"
                            name="date"
                          />
                          <div className="flex">
                            <SubmitButton
                              color="green"
                              className="self-center"
                              disabled={!values.date}
                            >{t("add", { ns: "common/list" })}</SubmitButton>
                          </div>
                        </div>
                      </Form>
                    )}                
                  </Formik>
                  <p>{t("labels.regularitySelectorModal.yearlyRegularity.disclaimer")}</p>
                  {!emptyArray(selectedYearDays) &&
                    <>
                      <div className="border-t m-2" />
                      <p>{t("labels.regularitySelectorModal.monthlyYearlyRegularitySelectedDays")}</p>
                      {renderYearDaySelections()} 
                    </>
                  }
                </>
              }
            </div>
          }
          <ErrorText className="">{t(error)}</ErrorText>
          </BasicFormTemplate>
        </Dialog>
      )}
    </>
  );
};

export default RegularityTypeSelectorDialog;