import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import Spinner from "@src/components/ui/Spinner";
import { Trans, useTranslation } from "react-i18next";
import BasicFormTemplate, { FooterType } from "@src/components/forms/BasicFormTemplate";
import FormikTextInput from "@src/components/forms/FormikTextInput";
import FormikTextArea from "@src/components/forms/FormikTextArea";
import { LanguageStrings } from "@src/helpers/stringHelpers";
import { getShortCurrentLanguageCode } from "@src/helpers/localeHelpers";

const ProductDetailsSchema = Yup.object().shape({
  name: Yup.string().required("common/form:validationErrors.empty.name"),
  description: Yup.string().required("components/products/create/productDetailsForm:validations.noDescription"),
  conditions: Yup.string().required("components/products/create/productDetailsForm:validations.noConditions"),
});

const ProductDetailsForm = ({ initialValues, callbackNextClicked }) => {
  const { t, ready: translationsReady } = useTranslation([
    "components/products/create/productDetailsForm",
    "pages/products/create",
    "common/form",
  ]);

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="max-w-md mx-auto mt-10">
          <Formik
            initialValues={initialValues}
            validationSchema={ProductDetailsSchema}
            onSubmit={(values, actions) => callbackNextClicked({ values, actions })}
          >
            <Form>
              <BasicFormTemplate
                formTitle={t("stepTitles.productDetails", { ns: "pages/products/create" })}
                footerType={FooterType.BACK_NEXT_BUTTONS}
                headerColor="teal"
              >
                <div className="space-y-4">
                  <p>{t("description")}</p>
                  <FormikTextInput
                    label={t("labels.name")}
                    name="name"
                  />
                  <FormikTextArea
                    label={t("labels.description")}
                    name="description"
                  />
                  <FormikTextArea
                    label={t("labels.conditions")}
                    name="conditions"
                  />
                  <p>
                    <Trans
                      t={t}
                      i18nKey="warningLanguage"
                      values={{ language: LanguageStrings[getShortCurrentLanguageCode()] }}
                      components={{
                        1: <span className="font-bold text-xl text-red-500" />,
                        2: <strong />
                      }}
                    />
                  </p>
                </div>
              </BasicFormTemplate>
            </Form>      
          </Formik>
        </div>
      )}
    </>
  );
};

export default ProductDetailsForm;