import axios from "@src/helpers/axiosInstance";
import { getJWT } from "@src/helpers/localStorageHelpers";
import { APIEndpointsStrings } from "@src/helpers/stringHelpers";
import ProductService from "@src/services/products/productService";
import OrderRefundService from "@src/services/orders/OrderRefundService";

class OrderService {
  static index = async (date) => {
    const indexOrdersResponse = await axios.get(APIEndpointsStrings.ordersPath +
      `${
        date ? `?q[created_at_gteq]=${date.fromDate}&q[created_at_lteq]=${date.toDate}` : ""
      }`, {
      headers: {
        Authorization: `Bearer ${getJWT()}`,
      },
    });

    return indexOrdersResponse.data.map((order) => this.formatReceivedOrder(order));
  };

  static indexFilters = async (client, date_min, date_max, status) => {
    let requestPath = APIEndpointsStrings.ordersPath + `?`;
    if(client){
      requestPath += `q[user_email_cont]=${client}&`;
    }
    if(date_min && date_max){
      requestPath += `q[created_at_gteq]=${date_min}&q[created_at_lteq]=${date_max}&`;
    }
    if( status ){
      status = status.split(' ')
      requestPath += `q[order_statuses_status_cont]=${status[0]}&`;
    }
    const indexFiltersData = await axios.get(requestPath);

    return indexFiltersData.data.map((order) => this.formatReceivedOrder(order));
  }

  static show = async (orderId) => {
    const showOrderResponse = await axios.get(
      APIEndpointsStrings.orderPath.replace(APIEndpointsStrings.idLocators.id, orderId),
      {
        headers: {
          Authorization: `Bearer ${getJWT()}`,
        },
      }
    );

    return this.formatReceivedOrder(showOrderResponse.data);
  };

  static showAsGuest = async (orderBase64Id) => {
    const res = await axios.get(
      APIEndpointsStrings.guestOrderPath.replace(APIEndpointsStrings.idLocators.id, orderBase64Id)
    );

    return this.formatReceivedOrder(res.data);
  };

  static create = (productId, dateProductId, selectedPrices, selectedSupplements) =>
    axios.post(APIEndpointsStrings.ordersPath, {
      order: {
        products: [
          {
            product_id: productId,
            date_product_id: dateProductId,
            configurations: selectedPrices,
            supplements: selectedSupplements,
          },
        ],
      },
    });

  static createAsGuest = (productId, dateProductId, selectedPrices, guestContactDetails, selectedSupplements) =>
    axios.post(APIEndpointsStrings.guestOrdersPath, {
      order: {
        client: guestContactDetails,
        products: [
          {
            product_id: productId,
            date_product_id: dateProductId,
            configurations: selectedPrices,
            supplements: selectedSupplements,
          },
        ],
      },
    });

  static cancelOrder = (orderId) =>
    axios.put(
      APIEndpointsStrings.cancelOrderPath.replace(APIEndpointsStrings.idLocators.id, orderId),
      {},
      {
        headers: {
          Authorization: `Bearer ${getJWT()}`,
        },
      }
    );

  static formatReceivedOrder = (order) => ({
    id: order.id,
    userId: order.user_id,
    userEmail: order.user_email,
    totalPrice: order.total_price,
    status: order.status,
    observations: order.observations,
    createdAt: new Date(order.created_at),
    cancelledAt: order.canceled_at ? new Date(order.canceled_at) : null,
    orderProducts: !order.order_products ? null : this.formatReceivedOrderProducts(order.order_products),
    orderStatuses: !order.order_statuses
      ? null
      : order.order_statuses.map((orderStatus) => this.formatReceivedOrderStatus(orderStatus)),
    orderRefund: !order.order_refund ? null : OrderRefundService.formatReceivedOrderRefund(order.order_refund),
  });

  static formatReceivedOrderProducts = (orderProducts) =>
    orderProducts.map((op) => ({
      dateProductId: op.date_product_id,
      observations: op.observation,
      configurations: op.configuration.map((c) => ({
        id: c.id,
        quantity: c.quantity,
        dateProductSnapshot: !c.date_order ? null : ProductService.formatReceivedProductDate(c.date_order),
        participants: !c.participants
          ? null
          : c.participants.map((p) => ({
              email: p.email,
              phone: p.phone,
              fullName: p.full_name,
              identification: p.identification,
            })),
      })),
      product: ProductService.formatReceivedProduct(op.product),
    }));

  static formatReceivedOrderStatus = (orderStatus) => ({
    id: orderStatus.id,
    orderId: orderStatus.order_id,
    status: orderStatus.status,
    comments: orderStatus.comments,
    createdAt: new Date(orderStatus.created_at),
    updatedAt: new Date(orderStatus.updated_at),
  });

  static paymentRedirection = (order) => {
    return axios.post('/form', { order_id: order.id});
  }

  static paymentConfirmation = (orderId) => {
    return axios.put(APIEndpointsStrings.confirmOrderPath.replace(APIEndpointsStrings.idLocators.id, orderId), {});
  }
}

export default OrderService;
