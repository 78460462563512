import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import FormikTextInput from "@src/components//forms/FormikTextInput";
import Spinner from "@src/components//ui/Spinner";
import ProductFormSearch from "@src/components/products/b2c/ProductFormSearch";
import CategoryFormSearch from "@src/components/products/categories/CategoryFormSearch";


const FormInitialValues = {
  description: "",
};

const FilterImageBankForm = ({
  className,
  color,
  cbSetDescription,
  cbSetProduct,
  cbSetCategory,
}) => {
  const { t, ready: translationsReady } = useTranslation([
    "common/filterForm",
    "common/form",
  ]);
  const DEFAULT_COLOR = "light-blue";

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <Card className={className}>
          <CardHeader
            color={color ?? DEFAULT_COLOR}
            variant="gradient"
            className="p-5"
          >
            <Typography variant="h5" color="white">
              {t("description", { ns: "common/filterForm" })}
            </Typography>
          </CardHeader>
          <CardBody>
            <Formik initialValues={FormInitialValues}>
              {({ handleChange }) => (
                <Form className="grid sm:grid-flow-row lg:grid-flow-col  lg:space-x-6 sm:items-start lg:items-center w-full">
                  <FormikTextInput
                    name="description"
                    type="description"
                    color={color ?? DEFAULT_COLOR}
                    label={t("labels.description", { ns: "common/form" })}
                    onChange={(e) => {
                      handleChange(e);
                      cbSetDescription(e.target.value);
                    }}
                  />
                  <ProductFormSearch
                    label={t("labels.products", {
                      ns: "common/form",
                    })}
                    placeholder={t("placeholders.selectOne", {
                      ns: "common/form",
                    })}
                    name="productId"
                    loadOptionsErrorMsg={t(
                      "messages.errorRetrievingServicesProviders"
                    )}
                    noOptionsMessage={t("placeholders.noOptionsInSelect", {
                      ns: "common/form",
                    })}
                    onChange={(optionValue) =>
                      cbSetProduct(optionValue)
                    }
                  />
                  <CategoryFormSearch
                    label={t("labels.categories", {
                      ns: "common/form",
                    })}
                    placeholder={t("placeholders.selectOne", {
                      ns: "common/form",
                    })}
                    name="categoryIds"
                    loadOptionsErrorMsg={t(
                      "messages.errorRetrievingCategories",
                      { ns: "pages/products/create" }
                    )}
                    onChange={(optionValue) =>
                      cbSetCategory(optionValue)
                    }
                  />
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      )}
    </>
  );
};
export default FilterImageBankForm;
