import { Dialog } from "@material-tailwind/react";
import Spinner from "@src/components/ui/Spinner";
import React from "react";
import { useTranslation } from "react-i18next";
import CompanyDataForm from "@src/components/users/company/CompanyDataForm";
import ProfessionalService from "@src/services/users/professionalService";
import { createNotification, delayedRedirect, NotificationTypes } from "@src/helpers/notificationHelpers";
import { CommonRoutesStrings } from "@src/helpers/stringHelpers";
import { useToggle } from "@src/helpers/hooks/useToggle";

const EditCompanyDataDialog = ({ open, handler, company }) => {
  const [w18ApiResponse, toggleW18ApiResponse] = useToggle();
  const { t, ready: translationsReady } = useTranslation("components/users/companyDataForm");

  const updateCompanyData = async (companyData) => {
    toggleW18ApiResponse();

    try {
      await ProfessionalService.updateCompanyData(companyData.id, companyData);
      createNotification(NotificationTypes.success, t("editActionMessages.ok"));
      delayedRedirect(CommonRoutesStrings.homePath);
    } catch (err) {
      createNotification(NotificationTypes.error, t("editActionMessages.ko"));
    } finally {
      toggleW18ApiResponse((b) => !b);
    }
  };

  return (
    <Dialog open={open} handler={handler}>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <CompanyDataForm
          initialValues={company}
          title={t("titles.edit")}
          showLoadingSpinner={w18ApiResponse}
          backCallback={handler}
          submitCallback={updateCompanyData}
        />
      )}
    </Dialog>
  );
};

export default EditCompanyDataDialog;
