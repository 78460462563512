import axios from "@src/helpers/axiosInstance";
import { getJWT } from "@src/helpers/localStorageHelpers";
import { APIEndpointsStrings } from "@src/helpers/stringHelpers";

class ServiceResourceUnitService {
  static create = (serviceId, dateId, resourceId) => {
    const endpoint = APIEndpointsStrings.serviceResourceUnitsPath
      .replace(APIEndpointsStrings.idLocators.serviceId, serviceId)
      .replace(APIEndpointsStrings.idLocators.productDateId, dateId)
      .replace(APIEndpointsStrings.idLocators.id, resourceId);
    
    return axios.post(endpoint, null, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });
  };

  static disable = (serviceId, dateId, resourceUnitId) => {
    const requestBody = {
      date_service_id: dateId,
      unit_resource_id: resourceUnitId,
    };
    const requestEndpoint = APIEndpointsStrings.disableServiceResourceUnitPath
      .replace(APIEndpointsStrings.idLocators.id, serviceId);

    return axios.put(requestEndpoint, requestBody, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });
  };

  static destroy = (serviceId, dateId, resourceId, resourceUnitId) => {
    const endpoint = APIEndpointsStrings.serviceResourceUnitPath
      .replace(APIEndpointsStrings.idLocators.serviceId, serviceId)
      .replace(APIEndpointsStrings.idLocators.productDateId, dateId)
      .replace(APIEndpointsStrings.idLocators.resourceId, resourceId)
      .replace(APIEndpointsStrings.idLocators.id, resourceUnitId);
    
    return axios.delete(endpoint, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    })
  };
}

export default ServiceResourceUnitService;