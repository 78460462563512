import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import { Form, Formik } from "formik";
import BasicFormTemplate, { FooterType } from "@src/components/forms/BasicFormTemplate";
import FormikTextInput from "@src/components/forms/FormikTextInput";
import FileInput from "@src/components/forms/FileInput";
import FormikCheckbox from "@src/components/forms/FormikCheckbox";
import FormCategorySearch from "@src/components/products/FormCategorySearch";

const ImageBankForm = ({
  title,
  initialValues,
  validationSchema,
  editing,
  showLoadingSpinner,
  submitCallback,
  backCallback,
  setSelectedFileCallback,
}) => {
  const { t, ready: translationsReady } = useTranslation("components/imageBank/imageBankForm");

  if (editing && initialValues) { initialValues.picture = "" };

  return !translationsReady ? (
    <Spinner />
  ) : (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitCallback}>
      <Form encType="multipart/form-data">
        <BasicFormTemplate
          className="mt-8"
          formTitle={title}
          showLoadingSpinner={showLoadingSpinner}
          footerType={FooterType.BACK_SUBMIT_BUTTONS}
          backCallback={backCallback}
        >
          <div className="px-4">
            <FormikTextInput className="mt-4" label={t("labels.description")} name="description" disabled={editing && initialValues.fixed} />
            <div className="mt-2">
              <FormikCheckbox 
                name="fixed"
                label={t("labels.fixed")}
                id="fixedCheckBox"
                disabled={editing && initialValues.fixed}
              />
            </div>
            <div className="mt-2">
              <FormCategorySearch
                label={t("labels.categories", {
                  ns: "common/form",
                })}
                placeholder={t("placeholders.selectOne", {
                  ns: "common/form",
                })}
                defaultCategories={initialValues.categories}
                name="categoryIds"
                loadOptionsErrorMsg={t(
                  "messages.errorRetrievingCategories",
                  { ns: "pages/products/create" }
                )}
                disabled={editing && initialValues.fixed}
              />
            </div>
              <FileInput
                className="mt-6"
                name="picture"
                onFileSelectSuccess={(file) => setSelectedFileCallback(file)}
                onFileSelectError={({ error }) => alert(error)}
              />
          </div>
        </BasicFormTemplate>
      </Form>
    </Formik>
  );
};

export default ImageBankForm;
