import React, { useState } from "react";
import { Dialog } from "@material-tailwind/react";
import HistoryBackButton from "@src/components/ui/buttons/HistoryBackButton";
import PaginateData from "@src/components/ui/PaginateData";
import Spinner from "@src/components/ui/Spinner";
import { useTranslation } from "react-i18next";
import ListBannerTable from "@src/components/banners/ListBannerTable";
import ShowBannerDialog from "@src/components/banners/ShowBannerDialog";

const SelectBannerDialog = ({ open, allBanners, handler, selectBannerCallback }) => {
  const [bannerToShow, setBannerToShow] = useState();
  const { t, ready: translationsReady } = useTranslation("pages/newsletters/list");

  const closeShowBannerDialog = () => setBannerToShow();

  const handleSelectedBanner = (banner) => {
    selectBannerCallback(banner);
    setBannerToShow();
  };

  return (
    <Dialog open={open}>
      {!(translationsReady && allBanners) ? (
        <Spinner />
      ) : (
        <div className="grid grid-cols-1 place-items-center max-w-xl mx-auto p-4">
          <PaginateData data={allBanners} emptyDataArrayMsg={t("messages.noBannersToList")}>
            <ListBannerTable showBannerImageCallback={setBannerToShow} />
          </PaginateData>
        </div>
      )}
      <div className="flex justify-center my-4">
        <HistoryBackButton onClick={handler} />
      </div>
      <ShowBannerDialog
        banner={bannerToShow}
        backCallback={closeShowBannerDialog}
        selectBannerCallback={handleSelectedBanner}
      />
    </Dialog>
  );
};

export default SelectBannerDialog;