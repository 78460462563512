import {useField} from "formik";
import React from "react";
import {useTranslation} from "react-i18next";

const FileInput = ({onFileSelectSuccess,onFileSelectError, ...props}) => {
  const [field, meta] = useField(props);
  const { t } = useTranslation();
  const handleFileInput = (e) => {
    // handle validations
    field.onChange(e)
    const file = e.target.files[0];
   onFileSelectSuccess(file);
  };
  return (
    <div>
      <input type="file" {...field} {...props} onChange={handleFileInput}/>
      {meta.touched && meta.error ? (
        <div className="text-red-500 font-semibold">{t(meta.error)}</div>
      ) : null}
    </div>
  );
};

export default FileInput;