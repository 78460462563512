import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import FormSearchInput from "@src/components/forms/FormSearchInput";
import Spinner from "@src/components/ui/Spinner";
import { useEffect } from "react";
import CountryFlagWithLanguageCode from "@src/components/products/product_wizard/CountryFlagWithLanguageCode";

const FormLanguageSearch = ({
  label,
  placeholder,
  name,
  loadOptionsErrorMsg,
  yMargin,
  defaultLanguages,
  cbSetLanguages,
  isMulti,
  insideFormikForm,
  cbHandleChange,
  ...props
}) => {
  const { values, setFieldValue } = useFormikContext();
  const { t, ready: translationsReady } = useTranslation("common/form");

  const generateOptionWithValue = value => ({
    value,
    label: <CountryFlagWithLanguageCode language={value} />,
  });

  const availableLanguages = ["es", "en", "fr", "it", "pt", "de", "ja", "zh", "da", "ru", "nl", "sv", "ar"].map(language => generateOptionWithValue(language));

  const loadOptions = async (inputValue) => availableLanguages.filter(lang => lang.value.includes(inputValue));
  
  const handleChange = options => {
    setFieldValue(name, isMulti ? options.map(({ value }) => value) : options.value);
    isMulti ? cbSetLanguages(options.map(({ value }) => value)) : !insideFormikForm && cbSetLanguages(options.value);
  };

  useEffect(() => {
    defaultLanguages && setFieldValue(name, defaultLanguages);
    // eslint-disable-next-line
  }, []);

  const getDefaultSelectedLanguages = () => defaultLanguages.map(language => generateOptionWithValue(language));

  return (
    <>
      {!(availableLanguages && translationsReady) ? <Spinner /> : (
        <FormSearchInput
          label={label}
          placeholder={placeholder ?? t("placeholders.selectOne")}
          name={name}
          defaultOptions={true}
          isMulti={isMulti}
          value={!isMulti ? generateOptionWithValue(values[name]) : getDefaultSelectedLanguages()}
          cbLoadOptions={loadOptions}
          cbHandleChange={handleChange}
          yMargin={yMargin}
          {...props}
        />
      )}
    </>        
  );
};

export default FormLanguageSearch;