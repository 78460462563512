import React, { useState } from "react";
import FormTitle from "@src/components/forms/FormTitle";
import ProductTimesliceSelector from "@src/components/products/joker/ProductTimesliceSelector";
import ServiceCategorySelector from "@src/components/products/joker/ServiceCategorySelector";
import ServiceSelector from "@src/components/products/joker/ServiceSelector";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import ServiceSearchAndAssignment from "@src/components/products/joker/ServiceSearchAndAssignment";

const JokerPage = () => {
  const COMPONENT_STATUS = {
    PRODUCT_TIMESLICE_SELECTOR: 1,
    SERVICE_CATEGORY_SELECTOR: 2,
    SERVICE_SELECTOR: 3,
    SERVICE_SEARCH_AND_ASSIGNMENT: 4,
  };

  const [currentComponent, setCurrentComponent] = useState(COMPONENT_STATUS.PRODUCT_TIMESLICE_SELECTOR);
  const [productDateListSearchResults, setProductDateListSearchResults] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedProductDate, setSelectedProductDate] = useState();
  const [selectedTimeslice, setSelectedTimeslice] = useState();
  const [selectedServiceCategory, setSelectedServiceCategory] = useState();
  const { t, ready: translationsReady } = useTranslation("pages/products/joker");

  const getComponentToRender = () => {
    switch (currentComponent) {
      case COMPONENT_STATUS.PRODUCT_TIMESLICE_SELECTOR:
        return <ProductTimesliceSelector
                  productDateListSearchResults={productDateListSearchResults}
                  selectedDate={selectedDate}
                  cbSetProductDateListSearchResults={setProductDateListSearchResults}
                  cbSetSelectedDate={setSelectedDate}
                  cbHandleTimesliceSelection={productDateAndTimeslice => changeCurrentComponent(COMPONENT_STATUS.SERVICE_CATEGORY_SELECTOR, productDateAndTimeslice)}
                />;
      case COMPONENT_STATUS.SERVICE_CATEGORY_SELECTOR:
        return <ServiceCategorySelector
                  selectedProductDate={selectedProductDate}
                  cbHandleServiceCategorySelection={serviceCategory => changeCurrentComponent(COMPONENT_STATUS.SERVICE_SELECTOR, serviceCategory)}
                  cbBackNavigation={() => changeCurrentComponent(COMPONENT_STATUS.PRODUCT_TIMESLICE_SELECTOR)}
                />;
      case COMPONENT_STATUS.SERVICE_SELECTOR:
        return <ServiceSelector
                  selectedProductDate={selectedProductDate}
                  selectedTimeslice={selectedTimeslice}
                  selectedServiceCategory={selectedServiceCategory}
                  cbAddService={() => changeCurrentComponent(COMPONENT_STATUS.SERVICE_SEARCH_AND_ASSIGNMENT)}
                  cbBackNavigation={() => changeCurrentComponent(COMPONENT_STATUS.SERVICE_CATEGORY_SELECTOR)}
                />;
      case COMPONENT_STATUS.SERVICE_SEARCH_AND_ASSIGNMENT:
        return <ServiceSearchAndAssignment
                  serviceCategory={selectedServiceCategory}
                  productDate={selectedProductDate}
                  timeslice={selectedTimeslice}
                  cbBackNavigation={() => changeCurrentComponent(COMPONENT_STATUS.SERVICE_SELECTOR)}
                />;
      default: return <p>Not found</p>;
    }
  };

  const changeCurrentComponent = (targetComponent, data) => {
    switch (targetComponent) {
      case COMPONENT_STATUS.PRODUCT_TIMESLICE_SELECTOR:
        setCurrentComponent(targetComponent);
        break;
      case COMPONENT_STATUS.SERVICE_CATEGORY_SELECTOR:
        if (data) {
          setSelectedProductDate(data.productDate);
          setSelectedTimeslice(data.timeslice);
        }
        setCurrentComponent(targetComponent);
        break;
      case COMPONENT_STATUS.SERVICE_SELECTOR:
        if (data) setSelectedServiceCategory(data);
        setCurrentComponent(targetComponent);
        break;
      case COMPONENT_STATUS.SERVICE_SEARCH_AND_ASSIGNMENT:
        setCurrentComponent(targetComponent);
        break;
      default:
        return;
    }
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="container">
          <FormTitle>{t("title")}</FormTitle>
          { getComponentToRender() }
        </div>
      )}
    </>
  );
};

export default JokerPage;