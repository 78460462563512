import Spinner from '@src/components/ui/Spinner';
import React from 'react';
import { useTranslation } from 'react-i18next';

const LegalNoticePage = () => {
  const { t, ready: translationsReady } = useTranslation(["pages/b2c/staticHelpPages/legalNotice", "components/b2c/footer"]);

  return (
    <div className="bg-white py-[67px] px-[135.9px] grid grid-cols-1 sm:text-sm md:text-base lg:text-lg xl:text-xl montserrat-medium-shark w-full mx-auto max-w-[1440px]">
      {!translationsReady ? <Spinner /> : (
        <>
          <p className="self-start font-extrabold sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-[#e30746]">{t("legalMentions.generalSalesConditions", { ns: "components/b2c/footer" })}</p>
          <p className="mt-8 font-extrabold sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-[#e30746]">{t("idDataCompany.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("idDataCompany.content")}</p>
          <p className="mt-6 font-extrabold sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-[#e30746]">{t("users.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("users.content")}</p>
          <p className="mt-6 font-extrabold sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-[#e30746]">{t("portalUsage.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("portalUsage.content")}</p>
          <p className="mt-6 font-extrabold sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-[#e30746]">{t("ip.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("ip.content")}</p>
          <p className="mt-6 font-extrabold sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-[#e30746]">{t("liabilityExclusion.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("liabilityExclusion.content")}</p>
          <p className="mt-6 font-extrabold sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-[#e30746]">{t("modifications.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("modifications.content")}</p>
          <p className="mt-6 font-extrabold sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-[#e30746]">{t("links.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("links.content")}</p>
          <p className="mt-6 font-extrabold sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-[#e30746]">{t("rightOfExclusion.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("rightOfExclusion.content")}</p>
          <p className="mt-6 font-extrabold sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-[#e30746]">{t("general.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("general.content")}</p>
          <p className="mt-6 font-extrabold sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-[#e30746]">{t("termsModification.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("termsModification.content")}</p>
          <p className="mt-6 font-extrabold sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-[#e30746]">{t("applicableLaws.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("applicableLaws.content")}</p>
        </>
      )}
    </div>
  );
};
 
export default LegalNoticePage;