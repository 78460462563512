import React from "react";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import { Textarea } from "@material-tailwind/react";

/*
  In order to text input error message to be translated
  correctly, we need to pass as error message the key
  for its translation, and FormikTextArea component will
  use i18next to translate this key to selected language
*/
const FormikTextArea = ({label, secondLabel, placeholder, className, color, variant, ...props}) => {
  const [field, meta] = useField(props);
  const { t } = useTranslation();

  return (
    <div className={`grid grid-cols-1 ${className ?? ""}`}>
      {/**
       * Text inputs from "@material-tailwind/react" (<Input />, <Textarea />...)
       * do not show input label if input is disabled and has some value assigned.
       * Time to solve that problem! :)
      */}
      {field.value && !!props.disabled &&
        <p>{label}</p>
      }
      <Textarea
        variant={variant ?? "outlined"}
        label={label}
        error={meta.touched && meta.error ? true : false}
        color={color ?? "light-blue"}
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="text-red-500 font-semibold">{t(meta.error)}</div>
      ) : null}
    </div>
  );
};

export default FormikTextArea;