import { getCurrentUserData } from "@src/helpers/localStorageHelpers";
import { isProvider } from "@src/helpers/userHelpers";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Spinner from "@src/components/ui/Spinner";
import BasicFormTemplate, { FooterType } from "@src/components/forms/BasicFormTemplate";
import { Form, Formik } from "formik";
import FormikTextInput from "@src/components/forms/FormikTextInput";
import { UserTypesStrings } from "@src/helpers/stringHelpers";
import FormUserSearch from "@src/components/users/FormUserSearch";



const FormSchema = Yup.object().shape({
  name: Yup.string().required("common/form:validationErrors.empty.name"),
  price: Yup.number()
    .typeError("common/form:validationErrors.numbers.notANumber")
    .required("components/supplements/supplementForm:validations.emptyPrice")
    .positive("common/form:validationErrors.numbers.requiredGreaterThan0"),
  maxPerProduct: Yup.number()
    .typeError("common/form:validationErrors.numbers.notANumber")
    .required("components/supplements/supplementForm:validations.emptyMaxPerProduct")
    .positive("common/form:validationErrors.numbers.requiredGreaterThan0")
    .integer("common/form:validationErrors.numbers.requiredNaturalNumber"),
  providerId: Yup.number().nullable(),
});

const SupplementForm = ({ title, initialValues, showLoadingSpinner, handleSubmit, backCallback }) => {
  const FormInitialValues = {
    name: "",
    price: "",
    maxPerProduct: "",
    providerId: isProvider() ? getCurrentUserData().id : null,
  };
  const { t, ready: translationsReady } = useTranslation(["components/supplements/supplementForm", "common/form"]);

  return !translationsReady ? (
    <Spinner />
  ) : (
    <Formik
      initialValues={initialValues ? { ...initialValues, providerId: initialValues?.provider?.id } : FormInitialValues}
      validationSchema={FormSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <BasicFormTemplate
          className="mt-8"
          formTitle={title}
          showLoadingSpinner={showLoadingSpinner}
          footerType={FooterType.BACK_SUBMIT_BUTTONS}
          backCallback={backCallback}
        >
          <div className="space-y-2">
            <FormikTextInput name="name" label={t("labels.name", { ns: "common/form" })} />
            <FormikTextInput name="price" label={t("labels.price")} />
            <FormikTextInput
              name="maxPerProduct"
              label={t("labels.maxPerProduct.main")}
              secondLabel={t("labels.maxPerProduct.description")}
            />
            {!isProvider() && (
              <FormUserSearch
                name="providerId"
                label={t("labels.provider")}
                userTypesArray={[UserTypesStrings.provider]}
                defaultUser={initialValues?.provider}
                loadOptionsErrorMsg={t("errorRetrievingProviders")}
                placeholder={t("placeholders.optional", { ns: "common/form" })}
                noOptionsMessage={t("placeholders.noOptionsInSelect", { ns: "common/form" })}
              />
            )}
          </div>
        </BasicFormTemplate>
      </Form>
    </Formik>
  );
};

export default SupplementForm;
