import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import React from "react";
import { stringDateFormat } from "@src/helpers/dateHelpers";
import { emptyArray } from "@src/helpers/arrayHelpers";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import FormikTextInput from "@src/components/forms/FormikTextInput";
import SubmitButton from "@src/components/ui/buttons/SubmitButton";

const IrregularAvailabilityInitialValue = {
  date: ""
};

const IrregularAvailabilitySelector = ({ creatingSingleDate, alreadyExistentDates, configurations, setConfigurations }) => {  
  const { t, ready: translationsReady } = useTranslation(["components/products/create/availability", "components/products/edit/addDate", "components/products/edit/addConfiguration", "common/list"]);

  const getDates = () => emptyArray(configurations) ? [] : configurations[0].dates;
  
  const removeDate = dateIndex => {
    const newDates = getDates().filter((_, index) => index !== dateIndex);
    if (emptyArray(newDates)) {
      setConfigurations([]);
    } else {
      setConfigurations([ {
        dates: newDates
      } ]);
    }
  };

  const renderConfigurations = () => 
    configurations.length === 0 ? null : (
      <ul className="list-disc list-inside">      
        {getDates().map((date, index) => 
          <li key={`non-regular-dates-${index}`} className="ml-2">
            {stringDateFormat(date)}
            <button
              onClick={() => removeDate(index)}
              className="ml-1"
            ><FontAwesomeIcon icon={faTimesCircle} color="red"/></button>
          </li>
        )}
      </ul>
    );

  const dateAlreadyPresent = date => getDates().some(value => new Date(value).valueOf() === new Date(date).valueOf());

  const validateDate = values => {
    const today = new Date(), selectedDate = new Date(values.date);

    if (selectedDate.valueOf() < new Date(today.getFullYear(), today.getMonth(), today.getDate()).valueOf())
      return { date: "components/products/create/availability:validations.irregularComponent.selectedDateLowerThanToday" };

    if (dateAlreadyPresent(values.date))
      return { date: "components/products/create/availability:validations.irregularComponent.dateAlreadyPresent" };
    
    if (creatingSingleDate && !emptyArray(configurations))
      return { date: "components/products/create/availability:validations.irregularComponent.creatingSingleDateError" };

    if (creatingSingleDate && alreadyExistentDates.some(d => d === values.date))
      return { date: "components/products/edit/addDate:labels.dateAlreadyRegisteredForProductError" };

    return {};
  };

  const handleNewDate = async (values, actions) => {
    const newDate = values.date;
    if (newDate != null && newDate !== "") {
      if (emptyArray(configurations)) {
        setConfigurations([ {
          dates: [ newDate ]
        } ]);
      } else {
        setConfigurations([ {
          dates: [...configurations.at(0).dates, newDate]
        } ])
      }
      actions.resetForm({ values: IrregularAvailabilityInitialValue });
    }
  };
  
  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <>
          <p>{creatingSingleDate ? 
            t("labels.availabilityDescription", { ns: "components/products/edit/addDate" }) 
            :
            t("labels.irregularComponent.enterDates")}
          </p>
          {alreadyExistentDates && !emptyArray(alreadyExistentDates) &&
            <div className="flex my-2">
              <div className="border-2 rounded-sm shadow-md mr-auto p-2">
                <p>{t("labels.alreadyRegisteredDates", { ns: "components/products/edit/addDate" })}</p>
                <ul className="list-inside list-disc">
                {alreadyExistentDates.map((date, index) => 
                  <li key={`existent-dates-${index}`}>{stringDateFormat(date)}</li>
                )}
                </ul>
              </div>
            </div>
          }
          {renderConfigurations()}
          <Formik
            initialValues={IrregularAvailabilityInitialValue}
            validate={validateDate}
            onSubmit={handleNewDate}
          >
            {({ values }) => (
              <Form>
                <div className="flex mt-8">
                  <FormikTextInput
                    name="date"
                    type="date"
                    label={t("labels.irregularComponent.dateInput")}
                  />
                  <SubmitButton
                    color="green"
                    className="ml-2 place-self-center"
                    disabled={(creatingSingleDate && !emptyArray(configurations)) || !values.date}
                  >{t("add", { ns: "common/list" })}</SubmitButton>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default IrregularAvailabilitySelector;