import { faMinusSquare, faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SmallSpinner from "react-spinkit";
import { emptyArray, listArrayElemsInString } from "@src/helpers/arrayHelpers";
import { getCategoriesWithId, getServiceCategoriesWithId } from "@src/helpers/categoryHelpers";
import { stringDateFormat } from "@src/helpers/dateHelpers";
import { formatHour } from "@src/helpers/localeHelpers";
import { getUserRequest } from "@src/helpers/userHelpers";
import Badge from "@src/components/ui/Badge";
import Button from "@src/components/ui/buttons/Button";
import { getDateRangeRegularityDescription } from "@src/components/products/product_wizard/RegularityTable";
import BigSpinner from "@src/components/ui/Spinner";
import BackNextSubmitButtons from "@src/components/products/product_wizard/BackNextSubmitButtons";
import { Card, CardBody, CardHeader, Dialog, Typography } from "@material-tailwind/react";
import PriceSeletionDetails from "@src/components/products/product_wizard/PriceSelectionDetails";
import ImageBankService from "@src/services/imageBankService";
import SelectedImagesTable from "@src/components/products/product_wizard/SelectedImagesTable";
import ShowImageDialog from "@src/components/image_bank/ShowImageDialog";
import CountryFlagWithLanguageCode from "@src/components/products/product_wizard/CountryFlagWithLanguageCode";
import LocationService from "@src/services/locationService";

const ProductWizardSummary = ({
  currentStep,
  numberSteps,
  initialValues: product,
  callbackBackClicked,
  callbackNextClicked,
  submissionErrors,
  showLoadingSpinner,
}) => {
  const [showDateRanges, setShowDateRanges] = useState(false);
  const [showSelectedImages, setShowSelectedImages] = useState(false);
  const [selectedImages, setSelectedImages] = useState();
  const [imageToShow, setImageToShow] = useState();
  const [showPriceTableModal, setShowPriceTableModal] = useState(false);
  const [pricesToShow, setPricesToShow] = useState();
  const [professionalEmail, setProfessionalEmail] = useState();
  const [categories, setCategories] = useState();
  const [serviceCategories, setServiceCategories] = useState();
  const [locations, setLocations] = useState();
  const { t, ready: translationsReady } = useTranslation([
    "components/products/create/summary",
    "components/products/create/prices",
    "components/products/create/timetables",
    "pages/products/create",
    "common/regularityTypes",
    "common/navigation",
    "common/booleanResponse",
    "common/weekDays",
    "common/common",
    "common/productTypes",
    "components/locations/form"
  ]);

  const getCategoriesJSX = () =>
    !categories ? (
      <SmallSpinner name="circle" fadeIn="none" />
    ) : (
      <div className="inline-grid grid-cols-2 space-x-1">
        {categories.map((category, index) => (
          <Badge key={`category-list-${index}`} bgColor="bg-yellow-300">
            {category.name}
          </Badge>
        ))}
      </div>
    );

  const getServiceCategoriesJSX = () =>
    !serviceCategories ? (
      <SmallSpinner name="circle" fadeIn="none" />
    ) : (
      <div className="inline-grid grid-cols-2 space-x-1">
        {serviceCategories.map((serviceCategory, index) => (
          <Badge key={`service-category-list-${index}`} bgColor="bg-yellow-300">
            {serviceCategory.name}
          </Badge>
        ))}
      </div>
    );

    const getLocationJSX = () =>
    !locations ? (
      <SmallSpinner name="circle" fadeIn="none" />
    ) : (
      <div className="inline-grid grid-cols-2 space-x-1">
        {locations.map((location, index) => (
          <Badge key={`service-category-list-${index}`} bgColor="bg-yellow-300">
            {location.name}
          </Badge>
        ))}
      </div>
    );


  useEffect(() => {
    const fetchImages = async () => {
      try {
        setSelectedImages(await ImageBankService.indexImagesWithIds(product.bankImageIds));
        setLocations(await (await LocationService.getLocationWithId(product.locationIds)).data)
      } catch (err) {
        console.log(err);
      }
    };
    fetchImages();

    getCategoriesWithId(product.categoryIds)
      .then(res => setCategories(res.data))
      .catch(err => console.log(err));

    getServiceCategoriesWithId(product.serviceCategoryIds)
      .then(res => setServiceCategories(res.data))
      .catch(err => console.log(err));

    if (product.professionalId) {
      getUserRequest(product.professionalId)
        .then(res => setProfessionalEmail(res.data.email))
        .catch(err => console.log(err));
    }
    // eslint-disable-next-line
  }, []);

  const showPriceTable = prices => {
    setPricesToShow(prices);
    setShowPriceTableModal(true);
  };

  const renderData = (key, value) => (
    <p>
      <span className="font-bold">{key}:</span> {value}
    </p>
  );

  const renderDateRanges = () =>
    product.configurations.map((dateRange, index) => (
      <div key={`list-dateranges-${index}`} className="bg-gray-100 border-2 rounded p-2 my-2">
        {renderData(t("dateRange"), stringDateFormat(dateRange.fromDate) + " - " + stringDateFormat(dateRange.toDate))}
        {dateRange.regularityType != null &&
          renderData(t("regularityType"), t(dateRange.regularityType, { ns: "common/regularityTypes" }))}
        {dateRange.regularityData != null &&
          renderData(t("regularityData"), getDateRangeRegularityDescription(dateRange))}
        {dateRange.dates != null && renderData(t("dates"), listArrayElemsInString(dateRange.dates))}
        {renderData(
          t("labels.hourSelector.durationLabel", { ns: "components/products/create/timetables" }),
          dateRange.duration
        )}
        {renderData(
          t("labels.hourSelector.quotaLabel", { ns: "components/products/create/timetables" }),
          dateRange.quota
        )}
        {renderData(
          t("labels.eventsTableHeaders.beginsAt", { ns: "components/products/create/prices" }),
          <div className="flex">{dateRange.times.map((t) => <div className="flex-col rounded bg-gray-300 px-1 whitespace-nowrap mx-2">{formatHour(t[0])}{t[1].map((value) => <CountryFlagWithLanguageCode className={"mx-1"} language={value} />)}</div>)}</div>
        )}
        <Button onClick={() => showPriceTable(dateRange.prices)} color="blue-gray" className="my-2">
          {t("viewEventPrices")}
        </Button>
      </div>
    ));

  return (
    <>
      {!translationsReady ? (
        <BigSpinner />
      ) : (
        <Card className="mx-auto max-w-5xl mt-10">
          <CardHeader color="teal" variant="gradient" className="flex py-10 px-2 place-content-center">
            <Typography variant="h4" color="white" className="text-center">
              {t("stepTitles", { ns: "pages/products/create" }).summary}
            </Typography>
          </CardHeader>
          <CardBody>
            {!emptyArray(submissionErrors) && (
              <div className="bg-red-300 border border-red-500 rounded p-2">
                <p className="font-bold">{t("errorsTitle")}</p>
                {submissionErrors.map((error, index) => (
                  <p key={`submissionError-${index}`}>{error}</p>
                ))}
              </div>
            )}
            {renderData(t("productName"), product.name)}
            {renderData(t("description"))}
            <p className="border-2 p-2 rounded-md whitespace-pre-line">{product.description}</p>
            {renderData(t("conditions"))}
            <p className="border-2 p-2 rounded-md whitespace-pre-line">{product.conditions}</p>
            {renderData(t("type"), t(product.productType, { ns: "common/productTypes" }))}
            {product.comission != null && product.comission !== "" && renderData(t("commission"), product.comission)}
            {product.professionalId !== "" && (
              <>
                {!professionalEmail ? (
                  <SmallSpinner name="circle" fadeIn="none" />
                ) : (
                  renderData(t("professional"), professionalEmail)
                )}
              </>
            )}
            {renderData(t("categories"))}
            {getCategoriesJSX()}
            {product.serviceCategoryIds.length > 0 && (
              <>
                {renderData(t("serviceCategories"))}
                {getServiceCategoriesJSX()}
              </>
            )}
            {renderData( t("nameLabel", {
                          ns: "components/locations/form",
                        }))}
            {getLocationJSX()}
            {!selectedImages ? (
              <SmallSpinner name="circle" fadeIn="none" />
            ) : (
              <>
                <div className="flex space-x-1">
                  {renderData(t("selectedImages"))}
                  <button onClick={() => setShowSelectedImages(b => !b)}>
                    <FontAwesomeIcon icon={showSelectedImages ? faMinusSquare : faPlusSquare} color="gray" size="lg" />
                  </button>
                </div>
                {showSelectedImages && (
                  <div className="my-3 grid grid-cols-1 place-items-center max-w-xl mx-auto">
                    <SelectedImagesTable imageList={selectedImages} showImageCallback={setImageToShow} />
                  </div>
                )}
                <ShowImageDialog image={imageToShow} backCallback={() => setImageToShow()} />
              </>
            )}
            {renderData(
              t("productRegularity"),
              product.regularity
                ? t("yes", { ns: "common/booleanResponse" })
                : t("no", { ns: "common/booleanResponse" })
            )}
            {renderData(
              t("productRequirePersonalData"),
              product.requirePersonalData
                ? t("yes", { ns: "common/booleanResponse" })
                : t("no", { ns: "common/booleanResponse" })
            )}
            {renderData(
              t("productHasQueues"),
              product.queues
                ? t("yes", { ns: "common/booleanResponse" })
                : t("no", { ns: "common/booleanResponse" })
            )}
            <div className="flex space-x-1">
              {renderData(t("dateRanges"))}
              <button onClick={() => setShowDateRanges(!showDateRanges)}>
                <FontAwesomeIcon icon={showDateRanges ? faMinusSquare : faPlusSquare} color="gray" size="lg" />
              </button>
            </div>
            <div className="mx-3">{showDateRanges && renderDateRanges()}</div>
            <Dialog open={showPriceTableModal} handler={() => setShowPriceTableModal(false)}>
              <div className="px-4 py-2">
                <p className="text-center">
                  {t("labels.priceTableDescription", { ns: "components/products/create/prices" })}
                </p>
                <PriceSeletionDetails prices={pricesToShow} />
                <div className="flex justify-center items-center">
                  <Button onClick={() => setShowPriceTableModal(false)} color="gray" className="mt-2">
                    {t("close", { ns: "common/navigation" })}
                  </Button>
                </div>
              </div>
            </Dialog>
            <BackNextSubmitButtons
              cbBackButton={callbackBackClicked}
              cbSubmitButton={callbackNextClicked}
              currentStep={currentStep}
              numberSteps={numberSteps}
              showLoadingSpinnerSubmitButton={showLoadingSpinner}
            />
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default ProductWizardSummary;
