import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import { useHistory } from "react-router-dom";
import Button from "@src/components/ui/b2c/buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";

const HistoryBackButton = ({ onClick, ...props }) => {
  const history = useHistory();
  const { t, ready: translationsReady } = useTranslation("common/navigation");
  
  const handleBackAction = () => history.goBack();

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <Button
          className="bg-black font-bold text-white"
          onClick={onClick ?? handleBackAction}
          {...props}
        ><FontAwesomeIcon icon={faLongArrowAltLeft} color="white" className="mr-2" />{t("back")}</Button>
      )}
    </>
  );
};

export default HistoryBackButton;