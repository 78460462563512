import { Dialog } from '@material-tailwind/react';
import Spinner from '@src/components/ui/Spinner';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ProductTranslationForm from '@src/components/products/edit_product_data/edit_product_translations/ProductTranslationForm';

const ShowProductTranslationDialog = ({ open, closeDialogCallback, productTranslation }) => {
  const { t, ready: translationsReady } = useTranslation("components/products/edit/editProductTranslations");

  return (
    <Dialog
      open={open}
      handler={closeDialogCallback}
    >
      {!translationsReady ? <Spinner /> : (
        <ProductTranslationForm
          initialValues={productTranslation}
          backCallback={closeDialogCallback}
          title={t("titles.show")}
          readOnly={true}
        />
      )}
    </Dialog>
  );
};
 
export default ShowProductTranslationDialog;