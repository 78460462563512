import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createNotification, delayedRedirect, NotificationTypes } from "@src/helpers/notificationHelpers";
import { editOwnProductBasicInfoRequest, editThirdPartyProductBasicInfoRequest, isOwnProduct, isThirdPartyProduct } from "@src/helpers/productHelpers";
import { DefinedRoutesStrings } from "@src/helpers/stringHelpers";
import { isAdmin, isOperator } from "@src/helpers/userHelpers";
import Spinner from "@src/components/ui/Spinner";
import ProductPropertiesForm from "@src/components/products/product_wizard/ProductPropertiesForm";

const EditProductProperties = ({ productData, backAction, readOnly }) => {
  const { t, ready: translationsReady } = useTranslation("components/products/edit/editProductProperties");
  const productId = productData.id;
  // eslint-disable-next-line
  const [productProperties, setProductProperties] = useState({
    productType: productData.type,
    comission: productData.comission == null ? "" : productData.comission,
    categoryIds: productData.categories.map(category => category.id),
    serviceCategoryIds: productData.service_categories_required == null ? "" :  productData.service_categories_required.map(serviceCategory => serviceCategory.id),
    professionalId: productData.provider_id ?? "",
    requirePersonalData: productData.required_participant,
    queues: productData.queues,
    locationIds: productData.locations.map(location => location.id)
  });
  const [w18ApiResponse, setW18ApiResponse] = useState(false);

  const submitEditedData = async ({ values }) => {
    setW18ApiResponse(b => !b);

    const productType = values.productType;
    const processedData = {
      name: values.name,
      category_ids: values.categoryIds,
      location_ids: values.locationIds,
      queues: values.queues
    };

    if (isOperator() || isAdmin()) {
      if (isThirdPartyProduct(productType)) {
        processedData.provider_id = values.professionalId;
        processedData.comission = values.comission;
      } else if (isOwnProduct(productType)) {
        processedData.service_category_ids = values.serviceCategoryIds;
      }
    }

    try {
      if (isThirdPartyProduct(productType)) {
        await editThirdPartyProductBasicInfoRequest(productId, processedData);
      } else if (isOwnProduct(productType)) {
        await editOwnProductBasicInfoRequest(productId, processedData);
      } else {
        throw new Error();
      }
      createNotification(NotificationTypes.success, t("successfulUpdate"));
      delayedRedirect(DefinedRoutesStrings.listProductsPath);
    } catch (err) {
      createNotification(NotificationTypes.error, t("genericError"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <ProductPropertiesForm
          initialValues={productProperties}
          callbackBackClicked={backAction}
          callbackNextClicked={submitEditedData}
          editing={true}
          showLoadingSpinner={w18ApiResponse}
          readOnly={readOnly}
        />
      )}
    </>
  );
};

export default EditProductProperties;