import { Dialog } from "@material-tailwind/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { emptyArray } from "@src/helpers/arrayHelpers";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { updatePriceTemplate } from "@src/helpers/priceTemplateHelpers";
import Spinner from "@src/components/ui/Spinner";
import PriceTemplateForm from "@src/components/products/price_templates/PriceTemplateForm";

const EditPriceTemplateDialog = ({ priceTemplate, handler: backCallback, handlePriceTemplateModification }) => {
  const [errors, setErrors] = useState([]);
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation("components/products/priceTemplates/edit");

  const submitData = async ({ name, peopleSegmentIds }, _) => {
    setW18ApiResponse(b => !b);

    try {
      await updatePriceTemplate(priceTemplate.id, name, peopleSegmentIds)
      if (!emptyArray(errors)) setErrors([]);
      createNotification(NotificationTypes.success, t("successEditPriceTemplate"));
      await handlePriceTemplateModification();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.errors && Array.isArray(err.response.data.errors))
        setErrors(err.response.data.errors);
      else
        createNotification(NotificationTypes.error, t("genericRequestError"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };
  
  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <Dialog
          open={!!priceTemplate}
          handler={backCallback}
        >
          <PriceTemplateForm
            errors={errors}
            backCallback={backCallback}
            showLoadingSpinner={w18ApiResponse}
            title={t("title")}
            initialValues={priceTemplate}
            handleSubmit={submitData}
          />
        </Dialog>
      )}
    </>
  );
};

export default EditPriceTemplateDialog;