import React from "react";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import GrayIconButton from "@src/components/ui/buttons/GrayIconButton";

const ClockButton = props =>
  <GrayIconButton
    icon={faClock}
    {...props}
  />;

export default ClockButton;