export const isPositiveFloat = num => {
  return !isNaN(num) && Number(num) > 0;
}

/** 
 * Returns array of numbers from "start" to ("end" - 1).
 * 
 * @param {Number} start 
 * @param {Number} end
 */
export const rangeNumbersArray = (start, end) => {
  const numElems = Math.abs(end - start);
  const arr = Array(numElems).fill().map((_, i) => i + start);
  return arr;
};

export const getNumberStringWith0IfLowerThan10 = number =>
  number >= 10 ? number : `0${number}`;