import FormikTextInput from "@src/components/forms/b2c/FormikTextInput";
import SubmitButton from "@src/components/ui/b2c/buttons/SubmitButton";
import Spinner from "@src/components/ui/Spinner";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const OrderGuestClientDataInitialValues = {
  name: "",
  email: "",
  phone: "",
};

const OrderGuestClientDataSchema = Yup.object().shape({
  name: Yup.string().required("common/form:validationErrors.empty.name"),
  email: Yup.string()
    .email("common/form:validationErrors.empty.email")
    .required("common/form:validationErrors.empty.email"),
  phone: Yup.string().required("components/orders/b2c/orderGuestClientDataForm:phoneForm.emptyError"),
});

const OrderGuestClientDataForm = ({ initialValues, finalizeForm }) => {
  const { t, ready: translationsReady } = useTranslation([
    "components/orders/b2c/orderGuestClientDataForm",
    "common/form",
  ]);

  return !translationsReady ? (
    <Spinner />
  ) : (
    <Formik
      initialValues={initialValues ?? OrderGuestClientDataInitialValues}
      validationSchema={OrderGuestClientDataSchema}
      onSubmit={finalizeForm}
    >
      <Form className="grid grid-cols-1 space-y-4">
        <p>{t("description")}</p>
        <FormikTextInput label={t("labels.name", { ns: "common/form" })} name="name" />
        <FormikTextInput label={t("labels.email", { ns: "common/form" })} name="email" />
        <FormikTextInput label={t("phoneForm.label")} name="phone" />
        <div className="flex">
          <SubmitButton>{t("buttons.save", { ns: "common/form" })}</SubmitButton>
        </div>
      </Form>
    </Formik>
  );
};

export default OrderGuestClientDataForm;
