import FormikTextInput from '@src/components/forms/b2c/FormikTextInput';
import Button from '@src/components/ui/b2c/buttons/Button';
import SubmitButton from '@src/components/ui/b2c/buttons/SubmitButton';
import MinMaxSlider from '@src/components/ui/b2c/MinMaxSlider';
import CountryFlag from '@src/components/ui/CountryFlag';
import Spinner from '@src/components/ui/Spinner';
import { formatTimeMinutes } from '@src/helpers/dateHelpers';
import { formatPrice } from '@src/helpers/localeHelpers';
import { HexStandardPinkStylingB2C } from '@src/helpers/stringHelpers';
import ProductCategoryService from '@src/services/products/productCategoryService';
import ProductFiltersB2CService from '@src/services/products/productFiltersB2CService';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from "yup";

const FilteringDatesForm = {
  initialValues: {
    from: "",
    to: "",
  },
  schema: Yup.object().shape({
    from: Yup.string().required(),
    to: Yup.string().required(),
  }),
};

const FiltersProductSearch = ({ selectedLanguages, setSelectedLanguageCallback, setSelectedDatesCallback, selectedCategoryIds, setSelectedCategoryIdCallback, selectedPriceLimits, setSelectedPriceLimitsCallback, selectedDurationLimits, setSelectedDurationLimitsCallback }) => {
  const [categories, setCategories] = useState();
  const [priceLimits, setPriceLimits] = useState();
  const [durationLimits, setDurationLimits] = useState();
  const [datesMessageError, setDatesMessageError] = useState();
  const { t, ready: translationsReady } = useTranslation(["pages/b2c/listProductsSearch", "common/form", "common/common"]);
  const SupportedLanguagesForFilters = [
    ["es", "en", "de"],
    ["fr", "it", "pt"],
  ];

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setCategories(await ProductCategoryService.indexPublicOnes());
      } catch (err) {}
    };
    const fetchPriceLimits = async () => {
      try {
        const limits = await ProductFiltersB2CService.showPriceLimits();
        setPriceLimits(limits);
        setSelectedPriceLimitsCallback(limits);
      } catch (err) {}
    };
    const fetchDurationLimits = async () => {
      try {
        const limits = await ProductFiltersB2CService.showDurationLimits();
        setDurationLimits(limits);
        setSelectedDurationLimitsCallback(limits);
      } catch (err) {}
    };

    fetchCategories();
    fetchPriceLimits();
    fetchDurationLimits();
    // eslint-disable-next-line
  }, []);

  const clearFilteringDatesForm = resetFormCallback => {
    FilteringDatesForm.initialValues.from = ""
    FilteringDatesForm.initialValues.to = ""
    resetFormCallback(FilteringDatesForm.initialValues);
    setDatesMessageError();
    setSelectedDatesCallback();
  };

  const checkSelectedDates = () => {
    console.log("heeey, ", FilteringDatesForm.initialValues.from,  FilteringDatesForm.initialValues.to);
    if (FilteringDatesForm.initialValues.from > FilteringDatesForm.initialValues.to){
      setDatesMessageError(t("validationErrors.dates.errorDateSelected", { ns: "common/form" }));
    }
    else {
      setDatesMessageError("");
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "from"){
      FilteringDatesForm.initialValues.from = value
    }

    else if(name === "to"){
      FilteringDatesForm.initialValues.to = value
    }
      
    checkSelectedDates();
  }

  return (
    <div className="rounded-xl flex flex-col items-center py-8 shadow-2xl w-[21.3%] max-w-[307px]">
      {!translationsReady ? <Spinner /> : (
        <>

          {/* Languages */}
          <div className="items-center w-[80%] space-y-2">
            <div className="leading-8 h-[34px] whitespace-no-wrap montserrat-bold-shark-24px">{t("searchFilters.languages")}</div>
            {SupportedLanguagesForFilters.map((languageRow, index) => (
              <div key={`filters_language_row_${index}`} className="flex justify-between w-[100%]">
                {languageRow.map(language => {
                  const isLanguageSelected = selectedLanguages.some(l => l === language);

                  return (
                    <div
                      key={language}
                      className="flex cursor-pointer"
                      onClick={() => setSelectedLanguageCallback(language, isLanguageSelected)}
                    >
                      <input
                        type="checkbox"
                        checked={isLanguageSelected}
                        readOnly={true}
                      />
                      <CountryFlag
                        languageCode={language}
                        className="ml-1 w-7"
                      />
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
          <div className={`h-px mt-6 w-[80%] bg-[${HexStandardPinkStylingB2C}]`}></div>

          {/* Dates */}
          <Formik
            initialValues={FilteringDatesForm.initialValues}
            validationSchema={FilteringDatesForm.schema}
            onSubmit={dates => setSelectedDatesCallback(dates)}
          >
            {({ resetForm }) => (
              <Form className="items-center mt-6 w-[80%]">
                <div className="leading-8 h-[34px] whitespace-no-wrap montserrat-bold-shark-24px">{t("searchFilters.dates.title")}</div>
                <FormikTextInput
                  showLabelOutsideInput={true}
                  hideErrorMessage={true}
                  className="mt-2"
                  name="from"
                  label={t("searchFilters.dates.from")}
                  type="date"
                  onChange={handleChange}
                />
                <FormikTextInput
                  showLabelOutsideInput={true}
                  hideErrorMessage={true}
                  className="mt-2"
                  name="to"
                  label={t("searchFilters.dates.to")}
                  type="date"
                  onChange={handleChange}
                />
                <label className='text-red-700 text-lg mt-1.5 font-semibold'>{datesMessageError && datesMessageError}</label>
                <div className="flex xl:flex-row sm:flex-col flex-center place-content-center mt-4">
                  <Button
                    className="xl:mr-auto  sm:m-auto bg-gray-500 text-gray font-bold"
                    onClick={() => clearFilteringDatesForm(resetForm)}
                  >{t("buttons.clear", { ns: "common/form" })}</Button>
                  {!datesMessageError && (
                    <SubmitButton
                      className="xl:ml-auto sm:m-auto sm:mt-2 xl:mt-0">{t("buttons.apply", { ns: "common/form" })}
                    </SubmitButton>
                  )}
                </div>
              </Form>
            )}
          </Formik>
          {priceLimits && selectedPriceLimits &&
            <>
              <div className={`h-px mt-6 w-[80%] bg-[${HexStandardPinkStylingB2C}]`}></div>

              {/* Prices */}
              <div className="flex flex-col items-start mt-6 w-[80%] mb-2">
                <div className="leading-8 h-[34px] whitespace-no-wrap montserrat-bold-shark-24px">{t("searchFilters.price")}</div>
                <MinMaxSlider
                  className="w-full mt-2"
                  limits={priceLimits}
                  currentValues={selectedPriceLimits}
                  onChange={setSelectedPriceLimitsCallback}
                  valueFormatter={value => formatPrice(value, 0)}
                />
              </div>
            </>
          }

          {durationLimits && selectedDurationLimits &&
            <>
              <div className={`h-px mt-6 w-[80%] bg-[${HexStandardPinkStylingB2C}]`}></div>

              {/* Duration */}
              <div className="flex flex-col items-start mt-6 w-[80%] mb-2">
                <div className="leading-8 h-[34px] whitespace-no-wrap montserrat-bold-shark-24px">{t("searchFilters.duration")}</div>
                <MinMaxSlider
                  className="w-full mt-2"
                  limits={durationLimits}
                  currentValues={selectedDurationLimits}  
                  onChange={setSelectedDurationLimitsCallback}
                  valueFormatter={formatTimeMinutes}
                />
              </div>
            </>
          }

          {categories &&
            <>
              <div className={`h-px mt-6 w-[80%] bg-[${HexStandardPinkStylingB2C}]`}></div>

              {/* Categories */}
              <div className="flex flex-col items-start mr-5 mt-6 w-[75%]">
                <div className="leading-8 -mt-px h-[34px] whitespace-no-wrap montserrat-bold-shark-24px">{t("searchFilters.category")}</div>
                <div className="flex flex-col items-start w-16">
                  {categories.map(category => {
                    const isCategorySelected = selectedCategoryIds.some(id => id === category.id);

                    return (
                      <div
                        key={category.id}
                        className="flex mt-2 cursor-pointer"
                        onClick={() => setSelectedCategoryIdCallback(category.id, isCategorySelected)}
                      >
                        <input
                          type="checkbox"
                          checked={isCategorySelected}
                          readOnly={true}
                        />
                        <div className="ml-2 montserrat-medium-shark-18px">{category.name}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          }
        </>
      )}
    </div>
  );
};
 
export default FiltersProductSearch;