import React from "react";
import GrayIconButton from "@src/components/ui/buttons/GrayIconButton";
import { faEuroSign } from "@fortawesome/free-solid-svg-icons";

const EuroButton = props =>
  <GrayIconButton
    icon={faEuroSign}
    {...props}
  />;

export default EuroButton;