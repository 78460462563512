import axios from "@src/helpers/axiosInstance";
import { getJWT } from "@src/helpers/localStorageHelpers";
import { APIEndpointsStrings, UserTypesStrings } from "@src/helpers/stringHelpers";
import UserService from "@src/services/users/userService";
import ProfessionalService from "@src/services/users/professionalService";

class AuthenticationService {

  /**
   *
   * @param {string} email
   * @param {string} password
   * @returns {Array<any>} arr[0] = formatted user object | arr[1] = authenticated user JWT
   */
  static login = async (email, password) => {
    const res = await axios.post(APIEndpointsStrings.loginPath, {
      user: {
        email: email,
        password: password
      }
    });

    const bearer = res.headers.authorization.replaceAll(/Bearer /g, ""),
      user = UserService.formatReceivedUser(res.data.user);

    return [ user, bearer ];
  };
  
  static logout = () =>
    axios.delete(APIEndpointsStrings.logoutPath, {
      headers: {
        "Authorization": `Bearer ${getJWT()}`
      }
    });
  
  static forgotPassword = email =>
    axios.post(APIEndpointsStrings.forgotPasswordPath, {
      user: {
        email: email
      }
    });
  
  static editPassword = (newPasswd, confirmationPasswd, resetToken) =>
    axios.patch(APIEndpointsStrings.forgotPasswordPath, {
      user: {
        reset_password_token: resetToken,
        password: newPasswd,
        password_confirmation: confirmationPasswd
      }
    });
  
  static registerUser = (userType, email, password) => {
    let endpoint;

    switch (userType) {
      case UserTypesStrings.client:
        endpoint = APIEndpointsStrings.registerClientPath;
        break;
      case UserTypesStrings.provider:
          endpoint = APIEndpointsStrings.registerThirdPartyProviderPath;
          break;
      case UserTypesStrings.servicesProvider:
        endpoint = APIEndpointsStrings.registerServicesProviderPath;
        break;
      default:
        endpoint = "";
        break;
    }
    
    return axios.post(endpoint, {
      user: {
        email,
        password
      }
    });
  };

  static registerProfessionalWithCompany = (userType, email, password, company) => {
    let endpoint;

    switch (userType) {
      case UserTypesStrings.provider:
          endpoint = APIEndpointsStrings.registerThirdPartyProviderPath;
          break;
      case UserTypesStrings.servicesProvider:
        endpoint = APIEndpointsStrings.registerServicesProviderPath;
        break;
      default:
        endpoint = "";
        break;
    }
    
    return axios.post(endpoint, {
      user: {
        email,
        password,
        company_attributes: ProfessionalService.formatCompanyDataToSend(company)
      }
    });
  };

  static confirmUserEmail = confirmationToken =>
    axios.get(APIEndpointsStrings.confirmEmailPath + confirmationToken);

}

export default AuthenticationService;