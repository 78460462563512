import React, { useState } from "react";
import BackNextSubmitButtons from "@src/components/products/product_wizard/BackNextSubmitButtons";
import PaginateData from "@src/components/ui/PaginateData";
import Timetable from "@src/components/products/product_wizard/Timetable";
import HoursSelector from "@src/components/products/product_wizard/HoursSelector";
import AddDeleteSelectAllButtons from "@src/components/products/product_wizard/AddDeleteSelectAllButtons";
import HourSelectionDetails from "@src/components/products/product_wizard/HourSelectionDetails";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import { emptyArray } from "@src/helpers/arrayHelpers";
import { Card, Dialog } from "@material-tailwind/react";
import BooleanResponseDialog from "@src/components/ui/BooleanResponseDialog";
import Button from "@src/components/ui/buttons/Button";

const isTimetableSetForDateRange = dateRange =>
    dateRange.duration != null && dateRange.quota != null && dateRange.times != null && dateRange.languages != null;

const HoursConfiguration = ({ currentStep, numberSteps, initialValues, editingProductConfigurations, callbackNextClicked, callbackBackClicked }) => {
  const [showHoursSelectionModal, setShowHoursSelectionModal] = useState(false);
  const [showHoursDetailsDialog, setShowHoursDetailsDialog] = useState(false);
  const [showHoursDeletionDialog, setShowHoursDeletionDialog] = useState(false);
  const [indexHoursDetails, setIndexHoursDetails] = useState();
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [datesRanges, setDateRanges] = useState(initialValues);
  const [indexDateRangeToEdit, setIndexDateRangeToEdit] = useState();
  const { t, ready: translationsReady } = useTranslation(["components/products/create/timetables", "pages/products/create", "common/navigation"]);

  const toggleSelectionAllRows = () => {
    if (selectedIndexes.length === datesRanges.length) {
      setSelectedIndexes([]);
    } else {
      setSelectedIndexes([...Array(datesRanges.length).keys()]);
    }
  }

  const openEditHoursModal = dateRangeIndex => {
    setIndexDateRangeToEdit(dateRangeIndex);
    setShowHoursSelectionModal(true);
  };

  const closeHoursSelector = () => {
    setShowHoursSelectionModal(false);
    setIndexDateRangeToEdit(null);
  }

  const closeHoursDetailsDialog = () => setShowHoursDetailsDialog(false);

  const deleteHoursOfSelectedIndexes = () => {
    setDateRanges(datesRanges.map((date, index) => {
      if (selectedIndexes.some(i => i === index)) {
        return {
          ...date,
          duration: null,
          languages: null,
          quota: null,
          times: null
        }; 
      } else
        return date;
    }));

    setSelectedIndexes([]);
    setShowHoursDeletionDialog(false);
  };

  const enableHoursDetailsDialog = index => {
    setIndexHoursDetails(index);
    setShowHoursDetailsDialog(true);
  };

  const handleHoursSelection = data => {
    setDateRanges(datesRanges.map((dateRange, index) => {
      if (selectedIndexes.some(i => i === index)) {
        return {
          ...dateRange,
          ...data
        }; 
      } else if (index === indexDateRangeToEdit) {
        return {
          ...datesRanges.at(indexDateRangeToEdit),
          ...data
        };
      } else
        return dateRange;
    }));
    
    setShowHoursSelectionModal(false);
    setSelectedIndexes([]);
    setIndexDateRangeToEdit(null);
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="grid grid-cols-1 max-w-5xl mx-auto">
          <Card className="place-self-center px-4 py-2">
            <AddDeleteSelectAllButtons
              addBtnDisabled={emptyArray(selectedIndexes) || selectedIndexes.some(index => isTimetableSetForDateRange(datesRanges.at(index)))}
              deleteBtnDisabled={emptyArray(selectedIndexes) || selectedIndexes.some(index => !isTimetableSetForDateRange(datesRanges.at(index)))}
              cbAdd={() => setShowHoursSelectionModal(true)}
              cbDelete={() => setShowHoursDeletionDialog(true)}
              cbSelectAll={() => toggleSelectionAllRows()}
            />
          </Card>
          <div className="grid grid-cols-1 place-items-center">
            <PaginateData
              data={datesRanges}
            >
              <Timetable
                numElements={datesRanges.length}
                selectedIndexes={selectedIndexes}
                cbSetSelectedIndexes={setSelectedIndexes}
                isTimetableSetForDateRange={isTimetableSetForDateRange}
                cbShowHoursDetails={enableHoursDetailsDialog}   
                cbOpenEditHoursModal={openEditHoursModal}     
              />
            </PaginateData>
          </div>
          <Dialog
            open={showHoursSelectionModal}
            handler={closeHoursSelector}
            className="overflow-visible"
          >
            <HoursSelector
              cbSubmitHourSelection={handleHoursSelection}
              cbCancelHourSelector={closeHoursSelector}
              hourSelectionsToModify={indexDateRangeToEdit != null ? datesRanges.at(indexDateRangeToEdit) : null}
            />
          </Dialog>
          <Dialog
            open={showHoursDetailsDialog}
            handler={closeHoursDetailsDialog}
          >
            {indexHoursDetails != null &&
              <div className="py-2 px-4">
                <p className="text-xl font-bold">{t("labels.timetableDescription")}</p>
                <HourSelectionDetails dateRange={datesRanges.at(indexHoursDetails)} />
                <div className="flex justify-center items-center">
                  <Button
                    onClick={closeHoursDetailsDialog}
                    color="gray"
                  >{t("close", { ns: "common/navigation" })}</Button>
                </div>
              </div>
            }        
          </Dialog>
          <BooleanResponseDialog
            open={showHoursDeletionDialog}
            yesCallback={deleteHoursOfSelectedIndexes}
            noCallback={() => setShowHoursDeletionDialog(false)}
          >{t("labels.deleteTimetableMessage")}</BooleanResponseDialog>
          <BackNextSubmitButtons
            currentStep={currentStep}
            numberSteps={numberSteps}
            nextSubmitButtonDisabled={datesRanges.some(date => !isTimetableSetForDateRange(date))}
            editingProductConfigurations={editingProductConfigurations}
            cbBackButton={callbackBackClicked}
            cbNextButton={() => callbackNextClicked(datesRanges)}
          />
        </div>
      )}
    </>
  );
};

export default HoursConfiguration;