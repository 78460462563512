import React, { cloneElement, useState } from "react";
import ReactPaginate from "react-paginate";
import { emptyArray } from "@src/helpers/arrayHelpers";

import "@src/assets/styles/b2c/components/ui/paginate-data.css";

const PaginateData = ({ data, perPage, emptyDataArrayMsg, children, forceFirstPage, usedInB2CPortal, setForceFirstPageCallback }) => {
  const PER_PAGE = perPage ?? 10; // Get prop or set default value
  const FIRST_PAGE = 0;
  const [currentPage, setCurrentPage] = useState(FIRST_PAGE);
  const offset = forceFirstPage ? FIRST_PAGE : currentPage * PER_PAGE;
  const currentPageData = data.slice(offset, offset + PER_PAGE);
  const pageCount = Math.ceil(data.length / PER_PAGE);
  
  const b2bPortalStyles = {
    container: "flex flex-inline space-x-3 mt-4",
    cursor: "bg-gray-300 bg-opacity-90 hover:bg-opacity-70 px-2 py-1 rounded",
    pageNumbers: "border bg-white px-2 py-1 rounded-sm hover:bg-gray-100",
    activePageNumber: "border bg-gray-200 px-2 py-1 rounded-full"
  };

  const b2cPortalStyles = {
    container: "flex flex-inline space-x-3 mt-4 place-self-center",
    cursor: "montserrat-medium-shark-18px",
    pageNumbers: "font-['Montserrat'] text-xl",
    activePageNumber: "bg-red-600 font-['Montserrat'] text-white font-bold px-2"
  };

  const reactPaginateStyles = usedInB2CPortal ? b2cPortalStyles : b2bPortalStyles;

  const paginationChildren = cloneElement(children,
    { currentPageData, currentPage, perPage: PER_PAGE });

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
    setForceFirstPageCallback && setForceFirstPageCallback(false);
  }

  if (emptyArray(data))
    return <p className="text-center">{emptyDataArrayMsg}</p>;
  
  return(
    <>
      {paginationChildren}
      {pageCount > 1 &&
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={reactPaginateStyles.container}
          previousLinkClassName={reactPaginateStyles.cursor}
          nextLinkClassName={reactPaginateStyles.cursor}
          pageLinkClassName={reactPaginateStyles.pageNumbers}
          activeLinkClassName={reactPaginateStyles.activePageNumber}
        />
      }
    </>
  );
};

export default PaginateData;