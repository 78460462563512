import ProductService from "@src/services/products/productService";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AvailabilityForm from "@src/components/products/product_wizard/AvailabilityForm";
import ProductPropertiesForm from "@src/components/products/product_wizard/ProductPropertiesForm";
import HoursConfiguration from "@src/components/products/product_wizard/HoursConfiguration";
import PricesConfiguration from "@src/components/products/product_wizard/PricesConfiguration";
import ProductWizardSummary from "@src/components/products/product_wizard/ProductWizardSummary";
import Regularity from "@src/components/products/product_wizard/Regularity";
import Spinner from "@src/components/ui/Spinner";
import { getCurrentUserData } from "@src/helpers/localStorageHelpers";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { DefinedRoutesStrings, isString } from "@src/helpers/stringHelpers";
import { isProfessional, isProvider } from "@src/helpers/userHelpers";
import { CardHeader, Progress, Typography } from "@material-tailwind/react";
import ProductDetailsForm from "@src/components/products/product_wizard/ProductDetailsForm";
import ProductImageSelector from "@src/components/products/product_wizard/ProductImageSelector";
import { useHistory } from "react-router-dom";

const CreateProductPage = ({ productType }) => {
  const FIRST_PAGE = 1;
  const [currentStep, setCurrentStep] = useState(FIRST_PAGE);
  const [productData, setProductData] = useState({
    name: "",
    description: "",
    conditions: "",
    bankImageIds: [],
    productType: productType != null ? productType : "",
    comission: "",
    categoryIds: [],
    serviceCategoryIds: [],
    professionalId: isProfessional() ? getCurrentUserData().id : "",
    regularity: true,
    requirePersonalData: false,
    queues: false,
    configurations: [],
    locationIds: []
  });
  const getNumberSteps = () => Object.keys(t("stepTitles")).length;
  const [submissionErrors, setSubmissionErrors] = useState([]);
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation(["pages/products/create", "common/common"]);
  const history = useHistory();

  const handleSubmit = async () => {
    setW18ApiResponse(b => !b);

    try {
      await ProductService.create(productData);
      if (isProvider()) {
        createNotification(NotificationTypes.info, t("messages.createAction.ok.provider"));
      } else {
        createNotification(NotificationTypes.success, t("messages.createAction.ok.other"));
      }
      history.push(DefinedRoutesStrings.listProductsPath);
    } catch (err) {
      console.log(err);
      if (err.response && Array.isArray(err.response.data.error))
        setSubmissionErrors(err.response.data.error);
      else if (err.response && isString(err.response.data.error))
        setSubmissionErrors([ err.response.data.error ]);

      createNotification(NotificationTypes.error, t("messages.createAction.ko"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  const handleStepDataReceived = data => {
    switch (currentStep) {
      case 1:
        setProductData({...productData, ...data.values});
        break;
      case 2:
        setProductData({...productData, bankImageIds: data});
        break;
      case 3:
        setProductData({...productData, ...data.values});
        break;
      case 4:
        setProductData({...productData, ...data});
        break;
      case 5:
      case 6:
      case 7:
        setProductData({...productData, configurations: data});
        break;
      default:
        return;
    }
  };

  const handleStepChange = targetStep => {
    setCurrentStep(targetStep);
    setSubmissionErrors([]);
  };

  const handlePreviousStep = () => {
    if (currentStep === 6 && productData.regularity === false)
      handleStepChange(currentStep - 2);
    else
      handleStepChange(currentStep - 1);
  };

  const handleNextStep = data => {
    if (data?.hasOwnProperty("actions")) data.actions.setSubmitting(false);

    handleStepDataReceived(data);

    if (currentStep === 4 && data.regularity === false)
      handleStepChange(currentStep + 2);
    else
      handleStepChange(currentStep + 1);
  };

  const getStepComponent = () => {
    console.log(productData)
    switch (currentStep) {
      case 1:
        return <ProductDetailsForm
                  initialValues={{
                    name: productData.name,
                    description: productData.description,
                    conditions: productData.conditions,
                  }}
                  callbackNextClicked={handleNextStep}
                />;
      case 2:
        return <ProductImageSelector
                  initialValues={productData.bankImageIds}
                  callbackBackClicked={handlePreviousStep}
                  callbackNextClicked={handleNextStep}
                />;
      case 3:
        return <ProductPropertiesForm
                  initialValues={productData}
                  callbackNextClicked={handleNextStep}
                  callbackBackClicked={handlePreviousStep}
                />;
      case 4:
        return <AvailabilityForm
                  initialValues={{
                    regularity: productData.regularity,
                    configurations: productData.configurations
                  }}
                  callbackNextClicked={handleNextStep}
                  callbackBackClicked={handlePreviousStep}
                />;
      case 5:
        return <Regularity
                  currentStep={currentStep}
                  numberSteps={getNumberSteps()}
                  initialValues={productData.configurations}
                  callbackNextClicked={handleNextStep}
                  callbackBackClicked={handlePreviousStep}
                />;
      case 6:
        return <HoursConfiguration
                  currentStep={currentStep}
                  numberSteps={getNumberSteps()}
                  initialValues={productData.configurations}
                  callbackNextClicked={handleNextStep}
                  callbackBackClicked={handlePreviousStep}
                />;
      case 7:
        return <PricesConfiguration
                  currentStep={currentStep}
                  numberSteps={getNumberSteps()}
                  initialValues={productData.configurations}
                  callbackNextClicked={handleNextStep}
                  callbackBackClicked={handlePreviousStep}
                />;
      case 8:
        return <ProductWizardSummary
                  currentStep={currentStep}
                  numberSteps={getNumberSteps()}
                  initialValues={productData}
                  callbackNextClicked={handleSubmit}
                  callbackBackClicked={handlePreviousStep}
                  submissionErrors={submissionErrors}
                  showLoadingSpinner={w18ApiResponse}
                />;
      default:
        return <div>Not found</div>
    }
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="container mx-auto">
          <div className="mt-10 mb-4 flex place-content-center">
            <CardHeader
              color="indigo"
              variant="gradient"
              className="px-8 py-6"
            >
              <Typography
                variant="h5"
                color="white"
              >{t("title")}</Typography>
            </CardHeader>
          </div>
          {currentStep !== FIRST_PAGE &&
            <Progress
              color="green"
              value={Number(((currentStep - 1) / Object.keys(t("stepTitles")).length * 100).toFixed(2))}
              label={t("progressBarCompletionLabel", { ns: "common/common" })}
              className="mb-2 h-auto"
            />
          }
          {getStepComponent()}
        </div>
      )}
    </>
  );
};

export default CreateProductPage;