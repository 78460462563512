import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { createNotification, delayedRedirect, NotificationTypes } from "@src/helpers/notificationHelpers";
import { cancelProductTimeslice, changeStateProductTimeslice, deleteProductTimeslice, isThirdPartyProduct } from "@src/helpers/productHelpers";
import Button from "@src/components/ui/buttons/Button";
import ErrorText from "@src/components/ui/ErrorText";
import PaginateData from "@src/components/ui/PaginateData";
import Spinner from "@src/components/ui/Spinner";
import ListTimeslicesTable from "@src/components/products/edit_product_data/list_timeslices/ListTimeslicesTable";
import HistoryBackButton from "@src/components/ui/buttons/HistoryBackButton";
import BooleanResponseDialog from "@src/components/ui/BooleanResponseDialog";
import CancelProductDialog from "@src/components/products/cancel_product/CancelProductDialog";
import { DefinedRoutesStrings } from "@src/helpers/stringHelpers";

const ListTimeslices = ({
                          productType,
                          date,
                          timeslices,
                          backCallback,
                          addTimesliceCallback,
                          editTimesliceCallback,
                          removeTimeslice,
                          refresh,
                          showPricesOfTimesliceCallback,
                          readOnly,
                          disableTimeslice
                        }) => {
  const PER_PAGE = 5;
  const [error, setError] = useState();
  const [timesliceToDelete, setTimesliceToDelete] = useState();
  const [timesliceToEnable, setTimesliceToEnable] = useState();
  const [timesliceToDisable, setTimesliceToDisable] = useState();
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const [cancelProgress, setCancelProgress ] = useState();
  const { id : productId } = useParams();
  const { t, ready: translationsReady } = useTranslation(["components/products/edit/listTimeslices", "common/navigation", "common/list"]);

  const deleteTimeslice = async () => {
    setW18ApiResponse(b => !b);

    try {
      await deleteProductTimeslice(productId, date.id, timesliceToDelete.id);
      createNotification(NotificationTypes.success, t("labels.successfulTimesliceDeletion"));
      removeTimeslice(timesliceToDelete);
      setTimesliceToDelete();
    } catch (err) {
      createNotification(NotificationTypes.error, t("labels.genericErrorTimesliceDeletion"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  const cancelTimeslice = async (event) => {
    setW18ApiResponse(b => !b);
    try {
      await cancelProductTimeslice(productId, date.id, cancelProgress.id,event.reason);
      setCancelProgress();
      refresh();
      createNotification(NotificationTypes.success, t("labels.successfulTimesliceCancelation"));
    } catch (err) {
      createNotification(NotificationTypes.error, t("labels.genericErrorTimesliceCancelation"));
    }
  }
  const changeStatusTimeslice = async (status, timesliceId) => {
    setW18ApiResponse(b => !b);

    try {
      const successfulMessage = status ? t("labels.successfulTimesliceEnable") : t("labels.successfulTimesliceDisable");
      await changeStateProductTimeslice(productId, date.id, timesliceId, status);
      createNotification(NotificationTypes.success, successfulMessage);
      status ? setTimesliceToEnable() : setTimesliceToDisable();
      const path = isThirdPartyProduct(productType) ? DefinedRoutesStrings.editThirdPartyProductPath : DefinedRoutesStrings.editOwnProductPath;
      delayedRedirect(path.replace(":id", productId));
    } catch (err) {
      const errorMessage = status ? t("labels.genericErrorTimesliceEnable") : t("labels.genericErrorTimesliceDisable")
      createNotification(NotificationTypes.error, errorMessage);
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  const enableTimesliceDeletion = timeslice => {
    if (Array.isArray(timeslices) && timeslices.length <= 1) {
      setError(t("validations.noTimesliceDeletionIfOnlyOneInList"));
    } else {
      setError();
      setTimesliceToDelete(timeslice);
    }
  };

  const newTimeSliceToEnable = timeslice => setTimesliceToEnable(timeslice);

  const newTimeSliceToDisable = timeslice => {
    if (Array.isArray(timeslices) && timeslices.length <= 1) {
      setError(t("validations.noTimesliceDisableIfOnlyOneInList"));
    } else {
      setError();
      setTimesliceToDisable(timeslice);
    }
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="grid grid-cols-1 place-items-center mx-auto max-w-screen-lg">
          <div className="space-x-2 my-2">
            <HistoryBackButton onClick={backCallback} size="md" />
            {!readOnly &&
              <Button
                onClick={addTimesliceCallback}
              >{t("add", { ns: "common/list" })}</Button>
            }
          </div>
          {error &&
            <ErrorText>{t(error)}</ErrorText>
          }
          <PaginateData
            data={timeslices}
            perPage={PER_PAGE}
          >
            <ListTimeslicesTable
              date={date.date}
              editTimesliceCallback={editTimesliceCallback}
              deleteTimesliceCallback={timeslice => enableTimesliceDeletion(timeslice)}
              enableTimesliceCallback={timeslice => newTimeSliceToEnable(timeslice)}
              disableTimesliceCallback={timeslice => newTimeSliceToDisable(timeslice)}
              showPricesOfTimesliceCallback={showPricesOfTimesliceCallback}
              cancelTimesCallback={timeslice => setCancelProgress(timeslice)}
              readOnly={readOnly}
            />
          </PaginateData>
          <BooleanResponseDialog
            open={!!timesliceToDelete}
            noCallback={() => setTimesliceToDelete()}
            yesCallback={deleteTimeslice}
            showLoadingSpinner={w18ApiResponse}
          >
            {t("labels.confirmTimesliceDeletionMessage")}
          </BooleanResponseDialog>
          <CancelProductDialog
            open={!!cancelProgress}
            handleSubmit={(event) => cancelTimeslice(event)}
            handler={() => setCancelProgress()}
            showLoadingSpinner={w18ApiResponse}
            productToCancel={cancelProgress}
            title={t("labels.titleCancel")}
            message={t("labels.confirmTimesliceDeletionMessage")}
          />
          <BooleanResponseDialog
            open={!!timesliceToEnable}
            noCallback={() => setTimesliceToEnable()}
            yesCallback={() => changeStatusTimeslice(true, timesliceToEnable.id)}
            showLoadingSpinner={w18ApiResponse}
          >
            {t("labels.confirmTimesliceEnableMessage")}
          </BooleanResponseDialog>
          <BooleanResponseDialog
            open={!!timesliceToDisable}
            noCallback={() => setTimesliceToDisable()}
            yesCallback={() => changeStatusTimeslice(false, timesliceToDisable.id)}
            showLoadingSpinner={w18ApiResponse}
          >
            {t("labels.confirmTimesliceDisableMessage")}
          </BooleanResponseDialog>
        </div>
      )}
    </>
  );
};

export default ListTimeslices;