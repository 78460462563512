import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@src/components/ui/Button";
import PaginateData from "@src/components/ui/PaginateData";
import Spinner from "@src/components/ui/Spinner";
import ListDatesTable from "@src/components/products/services/edit/dates/ListDatesTable";
import BooleanResponseDialog from "@src/components/ui/BooleanResponseDialog";
import ServiceDateService from "@src/services/services/serviceDateService";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { useParams } from "react-router-dom";

const ListDates = ({
                     dates,
                     backCallback,
                     addDateCallback,
                     refreshServiceDataCallback,
                     showResourcesOfDateCallback,
                     readOnly
                   }) => {
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const [serviceDateToDelete, setServiceDateToDelete] = useState();
  const { t, ready: translationsReady } = useTranslation(["components/services/edit/dates/list", "common/navigation", "common/list"]);
  const { id : serviceId } = useParams();
  const LIST_DATES_PER_PAGE = 5;

  const deleteServiceDate = async () => {
    setW18ApiResponse(b => !b);

    try {
      await ServiceDateService.destroy(serviceId, serviceDateToDelete.id);
      createNotification(NotificationTypes.success, t("labels.deleteDateAction.ok"));
      await refreshServiceDataCallback();
      setServiceDateToDelete();
    } catch (error) {
      createNotification(NotificationTypes.error, t("labels.deleteDateAction.ko"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="mt-2 border-t grid grid-cols-1 place-items-center mx-auto max-w-screen-sm">
          <div className="space-x-2 my-2">
            <Button
              className="bg-gray-400 text-white font-bold"
              onClick={backCallback}
            >{t("back", { ns: "common/navigation" })}</Button>
            <Button
              onClick={addDateCallback}
              className="bg-blue-400 text-white font-bold"
              hidden={readOnly}
            >{t("add", { ns: "common/list" })}</Button>
          </div>
          <PaginateData
            data={dates}
            perPage={LIST_DATES_PER_PAGE}
          >
            <ListDatesTable
              deleteDateCallback={date => setServiceDateToDelete(date)}
              readOnly={readOnly}
              showResourcesOfDateCallback={showResourcesOfDateCallback}
            />
          </PaginateData>
          <BooleanResponseDialog
            open={!!serviceDateToDelete}
            showLoadingSpinner={w18ApiResponse}
            yesCallback={deleteServiceDate}
            noCallback={() => setServiceDateToDelete()}
          >{t("labels.deleteDateAction.description")}</BooleanResponseDialog>
        </div>
      )}
    </>
  );
};

export default ListDates;