import { Dialog } from '@material-tailwind/react';
import { createNotification, NotificationTypes } from '@src/helpers/notificationHelpers';
import ServiceService from '@src/services/services/serviceService';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from "yup";
import BasicFormTemplate, { FooterType } from '@src/components/forms/BasicFormTemplate';
import FileInput from '@src/components/forms/FileInput';
import ErrorDisplayBox from '@src/components/ui/ErrorDisplayBox';
import Spinner from '@src/components/ui/Spinner';

const LoadServicesXlsxFormSchema = Yup.object().shape({
  file: Yup.string().required("common/form:validationErrors.empty.file"),
});

const LoadServicesXlsxFormInitialValues = {
  file: "",
};

const LoadServicesXlsxDialog = ({ open, handler: cbFinalizeDialog }) => {
  const [selectedFile, setSelectedFile] = useState();
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const [errors, setErrors] = useState();
  const { t, ready: translationsReady } = useTranslation(["components/services/loadServicesXlsxDialog", "common/form"]);

  const handleSubmit = async () => {
    setW18ApiResponse(b => !b);

    try {
      await ServiceService.createWithXlsx(selectedFile);
      setErrors(); // clear errorbox if present
      createNotification(NotificationTypes.success, t("action.ok"));
      await closeDialog(true);
    } catch (error) {
      if (error?.response?.data?.errors) {
        setErrors(error.response.data.errors);
      }

      createNotification(NotificationTypes.error, t("action.ko"));
      setW18ApiResponse(b => !b);
    }
  };

  const closeDialog = async (shouldReloadServices) => {
    await cbFinalizeDialog(shouldReloadServices);
    cleanComponentState();
  };

  const cleanComponentState = () => {
    setSelectedFile();
    setW18ApiResponse(false);
    setErrors();
  };

  return (
    <Dialog
      open={open}
      handler={closeDialog}
    >
      {!translationsReady ? <Spinner /> : (
        <Formik
          initialValues={LoadServicesXlsxFormInitialValues}
          validationSchema={LoadServicesXlsxFormSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <BasicFormTemplate
              className="mt-8"
              formTitle={t("title")}
              showLoadingSpinner={w18ApiResponse}
              footerType={FooterType.BACK_SUBMIT_BUTTONS}
              backCallback={closeDialog}
            >
              <div className="space-y-4">
                {errors &&
                  <ErrorDisplayBox
                    boxDescription={t("errorBoxDescription")}
                    errors={errors}
                  />
                }
                <p>{t("description")}</p>
                <FileInput
                  name="file"
                  onFileSelectSuccess={file => setSelectedFile(file)}
                  onFileSelectError={({ error }) => alert(error)}
                />
              </div>
            </BasicFormTemplate>
          </Form>
        </Formik>
      )}
    </Dialog>
  );
}
 
export default LoadServicesXlsxDialog;