import CreateNewsletter from "@src/components/newsletters/CreateNewsletter";
import EditNewsletter from "@src/components/newsletters/EditNewsletter";
import ListNewslettersTable from "@src/components/newsletters/ListNewslettersTable";
import NewsletterBodyDetails from "@src/components/newsletters/NewsletterBodyDetails";
import PaginateData from "@src/components/ui/PaginateData";
import Spinner from "@src/components/ui/Spinner";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { CommonRoutesStrings, DefinedRoutesStrings } from "@src/helpers/stringHelpers";
import NewsletterService from "@src/services/newsletterService";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import BooleanResponseDialog from "@src/components/ui/BooleanResponseDialog";
import HistoryBackButton from "@src/components/ui/buttons/HistoryBackButton";
import Button from "@src/components/ui/buttons/Button";
import FilterNewsletterForm from "@src/components/newsletters/FilterNewsletterForm";

const ListNewslettersPage = () => {
  const [newsletters, setNewsletters] = useState();
  const [error, setError] = useState(false);
  const [showNewsletterCreationModal, setShowNewsletterCreationModal] = useState(false);
  const [showNewsletterEditionModal, setShowNewsletterEditionModal] = useState(false);
  const [newsletterToDelete, setNewsletterToDelete] = useState();
  const [showNewsletterBodyDetailsModal, setShowNewsletterBodyDetailsModal] = useState(false);
  const [showFilterForm, setShowFilterForm] = useState(true);
  const [numberFilter, setNumberFilter] = useState("");
  const [titleFilter, setTitleFilter] = useState("");
  const [bodyFilter, setBodyFilter] = useState("");
  const [selectedNewsletter, setSelectedNewsletter] = useState();
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation(["pages/newsletters/list", "common/common", "common/list", "common/filterForm"]);
  const NEWSLETTERS_PER_PAGE = 5;

  const fetchNewsletters = async () => {
    try {
      setNewsletters(await NewsletterService.findAll());
    } catch (_) {
      createNotification(NotificationTypes.error, t("messages.genericRequestError"));
      setTimeout(() => setError(true), 3000);
    }
  };

  const toggleFiltering = () => {
    setShowFilterForm((show) => !show);
    setNumberFilter("");
    setTitleFilter("");
    setBodyFilter("");
  };

  function handleFilterInputChange(setStateCallback, newState) {
    setStateCallback(newState);
  }

  useEffect(() => {
    translationsReady && fetchNewsletters();
    // eslint-disable-next-line
  }, [translationsReady]);

  useEffect(() => {
    const newsletterbyfilters = async () => {
      setNewsletters(
        await NewsletterService.indexNewsletterFilter(
          numberFilter,
          titleFilter,
          bodyFilter
        )
      );
    }
    newsletterbyfilters();
  }, [numberFilter, titleFilter, bodyFilter]);

  const finishNewsletterCreation = () => {
    setShowNewsletterCreationModal(false);
    fetchNewsletters();
  };

  const enableNewsletterEdition = newsletter => {
    setSelectedNewsletter(newsletter);
    setShowNewsletterEditionModal(true);
  };

  const showNewsletterBody = newsletter => {
    setSelectedNewsletter(newsletter);
    setShowNewsletterBodyDetailsModal(true);
  };

  const finishNewsletterModification = () => {
    setShowNewsletterEditionModal(false);
    setSelectedNewsletter();
    fetchNewsletters();
  };

  const deleteNewsletter = async () => {
    const newsletterId = newsletterToDelete.id;
    setNewsletterToDelete();
    setW18ApiResponse(b => !b);

      try {
        await NewsletterService.destroy(newsletterId);
        createNotification(NotificationTypes.success, t("messages.deleteAction.ok"));
        setNewsletters(newsletters.filter(n => n.id !== newsletterId));
      } catch (_) {
        createNotification(NotificationTypes.error, t("messages.deleteAction.ko"));
      } finally {
        setW18ApiResponse(b => !b);
      }

  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <>
          {error && <Redirect to={CommonRoutesStrings.homePath} />}
          <div className="flex justify-center items-center mt-2 space-x-2">
            <HistoryBackButton size="md" />
            <Button
              color="green"
              onClick={() => setShowNewsletterCreationModal(true)}
              to={DefinedRoutesStrings.createUserPath}
            >{t("new", { ns: "common/common" })}</Button>
            {
              <Button className={"relative"} onClick={toggleFiltering}>
                {!showFilterForm
                  ? t("enableFilter", { ns: "common/filterForm" })
                  : t("disableFilter", { ns: "common/filterForm" })}
              </Button>
            }
          </div>
          {!newsletters ? <Spinner /> : (
            <div className="grid grid-cols-1 place-items-center max-w-4xl mx-auto">
              {showFilterForm && (
                <FilterNewsletterForm
                  color="yellow"
                  className="mt-11 mb-2 w-full"
                  cbSetNumber={(number) =>
                    handleFilterInputChange(setNumberFilter, number)
                  }
                  cbSetTitle={(title) =>
                    handleFilterInputChange(setTitleFilter, title)
                  }
                  cbSetBody={(body) =>
                    handleFilterInputChange(setBodyFilter, body)
                  }
                />
              )}
              <PaginateData
                data={newsletters}
                emptyDataArrayMsg={t("messages.noNewslettersToList")}
                perPage={NEWSLETTERS_PER_PAGE}
              >
                <ListNewslettersTable
                  title={t("title")}
                  showNewsletterBodyCallback={showNewsletterBody}
                  editNewsletterCallback={enableNewsletterEdition}
                  deleteNewsletterCallback={newsletter => setNewsletterToDelete(newsletter)}
                />
              </PaginateData>
            </div>
          )}
            <CreateNewsletter
              open={showNewsletterCreationModal}
              handler={() => setShowNewsletterCreationModal(b => !b)}
              backCallback={() => setShowNewsletterCreationModal(false)}
              creationEndedCallback={finishNewsletterCreation}
            />

            <EditNewsletter
              open={showNewsletterEditionModal}
              handler={() => setShowNewsletterEditionModal(b => !b)}
              newsletter={selectedNewsletter}
              backCallback={() => setShowNewsletterEditionModal(false)}
              modificationEndedCallback={finishNewsletterModification}
            />
      
         
            <>
              {selectedNewsletter &&
                <NewsletterBodyDetails
                  open={showNewsletterBodyDetailsModal}
                  handler={() => setShowNewsletterBodyDetailsModal(b => !b)}
                  newsletter={selectedNewsletter}
                  backCallback={() => setShowNewsletterBodyDetailsModal(false)}
                />
              }
            </>
          
          <BooleanResponseDialog
            open={!!newsletterToDelete}
            yesCallback={deleteNewsletter}
            noCallback={() => setNewsletterToDelete()}
          >
            <>
              {newsletterToDelete &&
                t("messages.confirmNewsletterDeletion")
              }
            </>
          </BooleanResponseDialog>
          {w18ApiResponse && <Spinner />}
        </>
      )}
    </>
  );
};

export default ListNewslettersPage;