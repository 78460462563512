import React from "react";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import GrayIconButton from "@src/components/ui/buttons/GrayIconButton";

const TrashButton = props =>
  <GrayIconButton
    icon={faPowerOff}
    variant="gradient"
    {...props}
  ></GrayIconButton>;

export default TrashButton;