import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "@src/components/ui/Button";
import Spinner from "@src/components/ui/Spinner";
import { useTranslation } from "react-i18next";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import TableCard from "@src/components/ui/TableCard";

const ListResourceUnitsTakenTable = ({ currentPageData : resourceUnitTakenList, deleteResourceUnitTakenCallback, readOnly }) => {
  const { t, ready: translationsReady } = useTranslation(["components/services/edit/resource_units_taken/list"]);

  const getHeadersArray = () => Object.values(t("labels.listResourceUnitsTakenTableHeaders"));

  const formatDataMatrix = () => resourceUnitTakenList.map(resourceUnitTaken => {
    const arr = [
      resourceUnitTaken.startTime + " - " + resourceUnitTaken.endTime,
      resourceUnitTaken.timesliceId,
      <>
        <Button
          className="bg-red-500"
          onClick={deleteResourceUnitTakenCallback}
          title={t("labels.breakAssociationTitle")}
          hidden={readOnly}
          disabled={true}
        ><FontAwesomeIcon icon={faTimes} color="white" /></Button>
      </>
    ];
    return readOnly ? arr.slice(0, -1) : arr;
  });

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
          title={t("labels.title")}
          headersArray={readOnly ? getHeadersArray().slice(0, -1) : getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={!readOnly}
        />
      )}
    </>
  );
};

export default ListResourceUnitsTakenTable;