import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@src/components/ui/Button";
import PaginateData from "@src/components/ui/PaginateData";
import Spinner from "@src/components/ui/Spinner";
import { stringDateFormat } from "@src/helpers/dateHelpers";
import ListResourcesTable from "@src/components/products/services/edit/resources/ListResourcesTable";
import BooleanResponseDialog from "@src/components/ui/BooleanResponseDialog";
import ServiceResourceService from "@src/services/services/serviceResourceService";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { useParams } from "react-router-dom";

const ListResources = ({
                     date,
                     backCallback,
                     addResourceCallback,
                     refreshServiceDataCallback,
                     showResourceUnitsCallback,
                     readOnly
                   }) => {
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const [resourceToDelete, setResourceToDelete] = useState();
  const { t, ready: translationsReady } = useTranslation(["components/services/edit/resources/list", "common/navigation"]);
  const { id : serviceId } = useParams();
  const LIST_RESOURCES_PER_PAGE = 5;

  const deleteResource = async () => {
    setW18ApiResponse(b => !b);

    try {
      await ServiceResourceService.destroy(serviceId, date.id, resourceToDelete.id);
      createNotification(NotificationTypes.success, t("labels.deleteResourceAction.ok"));
      await refreshServiceDataCallback();
      setResourceToDelete();
    } catch (error) {
      createNotification(NotificationTypes.error, t("labels.deleteResourceAction.ko"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="mt-2 border-t grid grid-cols-1 place-items-center mx-auto max-w-screen-sm">
          <div className="space-x-2 my-2">
            <Button
              className="bg-gray-400 text-white font-bold"
              onClick={backCallback}
            >{t("back", { ns: "common/navigation" })}</Button>
            <Button
              onClick={addResourceCallback}
              className="bg-blue-400 text-white font-bold"
              hidden={readOnly}
            >{t("add", { ns: "common/list" })}</Button>
          </div>
          <PaginateData
            data={date.resources}
            perPage={LIST_RESOURCES_PER_PAGE}
          >
            <ListResourcesTable
              deleteResourceCallback={resource => setResourceToDelete(resource)}
              readOnly={readOnly}
              showResourceUnitsCallback={showResourceUnitsCallback}
              title={t("labels.title", { date: stringDateFormat(date.date) })}
            />
          </PaginateData>
          <BooleanResponseDialog
            open={!!resourceToDelete}
            showLoadingSpinner={w18ApiResponse}
            yesCallback={deleteResource}
            noCallback={() => setResourceToDelete()}
          >{t("labels.deleteResourceAction.description")}</BooleanResponseDialog>
        </div>
      )}
    </>
  );
};

export default ListResources;