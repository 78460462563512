import React, { useEffect, useState } from "react";
import { DefinedRoutesStrings } from "@src/helpers/stringHelpers";
import BasicInfoForm from "@src/components/products/services/create/BasicInfoForm";
import { isServicesProvider } from "@src/helpers/userHelpers";
import { getCurrentUserData } from "@src/helpers/localStorageHelpers";
import AvailabilityForm from "@src/components/products/product_wizard/AvailabilityForm";
import Regularity from "@src/components/products/product_wizard/Regularity";
import ResourcesConfiguration from "@src/components/products/services/create/ResourcesConfiguration";
import ServiceSummary from "@src/components/products/services/create/ServiceSummary";
import ServiceService from "@src/services/services/serviceService";
import {
  createNotification,
  delayedRedirect,
  NotificationTypes,
} from "@src/helpers/notificationHelpers";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import {
  Progress,
  CardHeader,
  Typography,
} from "@material-tailwind/react";

const CreateServicePage = () => {
  const FIRST_PAGE = 1;
  const [currentStep, setCurrentStep] = useState(FIRST_PAGE);
  const [serviceData, setServiceData] = useState({
    name: "",
    servicesProviderId: isServicesProvider() ? getCurrentUserData().id : null,
    serviceCategoryId: null,
    regularity: true,
    configurations: [],
  });
  // eslint-disable-next-line
  const [submissionErrors, setSubmissionErrors] = useState([]);
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation([
    "pages/services/create",
    "common/common",
  ]);

  // eslint-disable-next-line
  useEffect(() => console.log(serviceData), [currentStep]);

  const handleStepDataReceived = (data) => {
    switch (currentStep) {
      case 1:
        setServiceData({ ...serviceData, ...data });
        break;
      case 2:
        setServiceData({ ...serviceData, ...data });
        break;
      case 3:
        setServiceData({ ...serviceData, configurations: data });
        break;
      case 4:
        setServiceData({ ...serviceData, configurations: data });
        break;
      default:
        return;
    }
  };

  const handleNextStep = (data) => {
    handleStepDataReceived(data);
    if (currentStep === 2 && data.regularity === false)
      handleStepChange(currentStep + 2);
    else handleStepChange(currentStep + 1);
  };

  const handlePreviousStep = () => {
    if (currentStep === 4 && serviceData.regularity === false)
      handleStepChange(currentStep - 2);
    else handleStepChange(currentStep - 1);
  };

  const handleStepChange = (targetStep) => {
    setCurrentStep(targetStep);
  };

  const handleSubmit = async () => {
    setW18ApiResponse((b) => !b);

    try {
      await ServiceService.postService(serviceData);
      createNotification(
        NotificationTypes.success,
        t("createServiceNotifications.ok")
      );
      delayedRedirect(DefinedRoutesStrings.listServicesPath);
    } catch (error) {
      console.log(error);
      createNotification(
        NotificationTypes.error,
        t("createServiceNotifications.ko")
      );
    } finally {
      setW18ApiResponse((b) => !b);
    }
  };

  const getStepComponent = () => {
    switch (currentStep) {
      case 1:
        return (
          <BasicInfoForm
            currentStep={currentStep}
            initialValues={serviceData}
            nextCallback={handleNextStep}
          />
        );
      case 2:
        return (
          <AvailabilityForm
            currentStep={currentStep}
            numberSteps={Object.keys(t("stepTitles")).length}
            initialValues={{
              regularity: serviceData.regularity,
              configurations: serviceData.configurations,
            }}
            callbackNextClicked={handleNextStep}
            callbackBackClicked={handlePreviousStep}
          />
        );
      case 3:
        return (
          <Regularity
            currentStep={currentStep}
            numberSteps={Object.keys(t("stepTitles")).length}
            initialValues={serviceData.configurations}
            callbackNextClicked={handleNextStep}
            callbackBackClicked={handlePreviousStep}
          />
        );
      case 4:
        return (
          <ResourcesConfiguration
            currentStep={currentStep}
            numberSteps={Object.keys(t("stepTitles")).length}
            initialValues={serviceData.configurations}
            callbackNextClicked={handleNextStep}
            callbackBackClicked={handlePreviousStep}
          />
        );
      case 5:
        return (
          <ServiceSummary
            currentStep={currentStep}
            numberSteps={Object.keys(t("stepTitles")).length}
            initialValues={serviceData}
            callbackNextClicked={handleSubmit}
            callbackBackClicked={handlePreviousStep}
            submissionErrors={submissionErrors}
            showLoadingSpinner={w18ApiResponse}
          />
        );
      default:
        return (
          <p className="text-center">
            {t("contentNotFound", { ns: "common/common" })}
          </p>
        );
    }
  };

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <div className="container max-w-2xl mx-auto">
          <div className="container mx-auto">
            <div className="mt-10 mb-4 flex place-content-center">
              <CardHeader
                color="indigo"
                variant="gradient"
                className="px-8 py-6"
              >
                <Typography variant="h5" color="white">
                  {t("title")}
                </Typography>
              </CardHeader>
            </div>
            {currentStep !== FIRST_PAGE && (
              <Progress
                color="green"
                value={Number(
                  (
                    ((currentStep - 1) / Object.keys(t("stepTitles")).length) *
                    100
                  ).toFixed(2)
                )}
                label={t("progressBarCompletionLabel", { ns: "common/common" })}
                className="mb-2"
              />
            )}
          </div>
          {getStepComponent()}
        </div>
      )}
    </>
  );
};

export default CreateServicePage;
