import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@src/components/ui/Button";
import PaginateData from "@src/components/ui/PaginateData";
import Spinner from "@src/components/ui/Spinner";
import { stringDateFormat } from "@src/helpers/dateHelpers";
import ListResourceUnitsTable from "@src/components/products/services/edit/resource_units/ListResourceUnitsTable";
import BooleanResponseDialog from "@src/components/ui/BooleanResponseDialog";
import ServiceResourceUnitService from "@src/services/services/serviceResourceUnitService";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { useParams } from "react-router-dom";
import SubmitButton from "@src/components/ui/buttons/SubmitButton";

const ListResourceUnits = ({
                     date,
                     resource,
                     resourceUnits,
                     backCallback,
                     refreshServiceDataCallback,
                     showResourceUnitTakenCallback,
                     readOnly
                   }) => {
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const [resourceUnitToDelete, setResourceUnitToDelete] = useState();
  const [resourceUnitToDisable, setResourceUnitToDisable] = useState();
  const { t, ready: translationsReady } = useTranslation(["components/services/edit/resource_units/list", "common/navigation"]);
  const { id : serviceId } = useParams();
  const LIST_RESOURCE_UNIT_PER_PAGE = 5;

  const createResourceUnit = async () => {
    setW18ApiResponse(b => !b);

    try {
      await ServiceResourceUnitService.create(serviceId, date.id, resource.id);
      createNotification(NotificationTypes.success, t("labels.newResourceUnitAction.ok"));
      await refreshServiceDataCallback();
    } catch (error) {
      createNotification(NotificationTypes.error, t("labels.newResourceUnitAction.ko"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  const disableResourceUnit = async () => {
    setW18ApiResponse(b => !b);

    try {
      await ServiceResourceUnitService.disable(serviceId, date.id, resourceUnitToDisable.id);
      createNotification(NotificationTypes.success, t("labels.disableResourceUnitAction.ok"));
      await refreshServiceDataCallback();
      setResourceUnitToDisable();
    } catch (error) {
      createNotification(NotificationTypes.error, t("labels.disableResourceUnitAction.ko"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  const deleteResourceUnit = async () => {
    setW18ApiResponse(b => !b);

    try {
      await ServiceResourceUnitService.destroy(serviceId, date.id, resource.id, resourceUnitToDelete.id);
      createNotification(NotificationTypes.success, t("labels.deleteResourceUnitAction.ok"));
      await refreshServiceDataCallback();
      setResourceUnitToDelete();
    } catch (error) {
      createNotification(NotificationTypes.error, t("labels.deleteResourceUnitAction.ko"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="mt-2 border-t grid grid-cols-1 place-items-center mx-auto max-w-2xl">
          <div className="border-2 border-gray-400 rounded-md shadow-md my-2 p-2">
            <p>{t("labels.description.date", { date: stringDateFormat(date.date) })}</p>
            <p>{t("labels.description.resource", { resourceName: resource.name })}</p>
          </div>
          <div className="space-x-2 my-2">
            <Button
              className="bg-gray-400 text-white font-bold"
              onClick={backCallback}
            >{t("back", { ns: "common/navigation" })}</Button>
            {!readOnly &&
              <SubmitButton
                onClick={createResourceUnit}
                showLoadingSpinner={w18ApiResponse}
              >{t("add", { ns: "common/list" })}</SubmitButton>
            }
          </div>
          <PaginateData
            data={resourceUnits}
            perPage={LIST_RESOURCE_UNIT_PER_PAGE}
          >
            <ListResourceUnitsTable
              disableResourceUnitCallback={resourceUnit => setResourceUnitToDisable(resourceUnit)}
              deleteResourceUnitCallback={resourceUnit => setResourceUnitToDelete(resourceUnit)}
              readOnly={readOnly}
              showResourceUnitTakenCallback={showResourceUnitTakenCallback}
            />
          </PaginateData>
          <BooleanResponseDialog
            open={!!resourceUnitToDisable}
            showLoadingSpinner={w18ApiResponse}
            yesCallback={disableResourceUnit}
            noCallback={() => setResourceUnitToDisable()}
          >{t("labels.disableResourceUnitAction.description")}</BooleanResponseDialog>
          <BooleanResponseDialog
            open={!!resourceUnitToDelete}
            showLoadingSpinner={w18ApiResponse}
            yesCallback={deleteResourceUnit}
            noCallback={() => setResourceUnitToDelete()}
          >{t("labels.deleteResourceUnitAction.description")}</BooleanResponseDialog>
        </div>
      )}
    </>
  );
};

export default ListResourceUnits;