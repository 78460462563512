import { getNumberStringWith0IfLowerThan10 } from "@src/helpers/numberHelpers";
import { t } from "i18next";

/**
 * 
 * This method returns input "stringDate" with new format.
 *
 * @param {*} stringDate - Has format "YYYY-mm-dd"
 * @returns {string} Input with new format "dd/mm/YYYY"
 */
export const stringDateFormat = stringDate => {
  const date = new Date(stringDate);
  
  let day = date.getDate();
  if (day < 10)
    day = `0${day}`;
  
  let month = date.getMonth() + 1;
  if (month < 10)
    month = `0${month}`;
  
  let year = date.getFullYear();

  return "dd/mm/YYYY"
    .replace("YYYY", year)
    .replace('mm', month)
    .replace('dd', day);
};

export const dateToStringYYYYMMDD = date =>
  "YYYY-mm-dd"
    .replace("YYYY", date.getFullYear())
    .replace('mm', date.getMonth() + 1) // [0,11] for Date.getMonth()
    .replace('dd', date.getDate());

/**
 * 
 * Returns all dates between "startDate" and "endDate".
 * 
 * @param {Date} startDate 
 * @param {Date} endDate
 * @returns {Array} Strings with format "YYYY-mm-dd"
 */
export const getAllDaysInDateRange = (startDate, endDate) => {
  let dates = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    dates.push(dateToStringYYYYMMDD(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
};

/**
 * 
 * Returns mentioned weekdays between "startDate" and "endDate".
 * 
 * @param {Date} startDate 
 * @param {Date} endDate
 * @param {*} weekDays - Array of strings [0,1,2..6] for ["Mo", "Tu", "We".."Su"]
 * @returns {Array} Strings with format "YYYY-mm-dd"
 */
export const getWeekDaysInDateRange = (startDate, endDate, weekDays) => {
  let dates = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    if (weekDays.some(weekDayIndex => weekDayIndex === currentDate.getDay() - 1))
      dates.push(dateToStringYYYYMMDD(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
};

/**
 * 
 * Returns days with "monthDays" between "startDate" and "endDate".
 * 
 * @param {Date} startDate 
 * @param {Date} endDate
 * @param {*} monthDays - String array with values in [1,31]
 * @returns {Array} Strings with format "YYYY-mm-dd"
 */
 export const getMonthDaysInDateRange = (startDate, endDate, monthDays) => {
  let dates = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    if (startDate <= currentDate && currentDate <= endDate && monthDays.some(d => Number(d) === currentDate.getDate()))
      dates.push(dateToStringYYYYMMDD(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  
  return dates;
};

/**
 * 
 * Returns days with "yearDays" between "startDate" and "endDate".
 * 
 * @param {Date} startDate 
 * @param {Date} endDate
 * @param {*} yearDays - String array with values like "DD/MM"
 * @returns {Array} Strings with format "YYYY-mm-dd"
 */
 export const getYearDaysInDateRange = (startDate, endDate, yearDays) => {
  let dates = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    if (startDate <= currentDate && currentDate <= endDate && yearDays.some(day => day === YYYYMMDDtoDDMM(dateToStringYYYYMMDD(currentDate))))
      dates.push(dateToStringYYYYMMDD(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
};

/**
 * 
 * Months in JavaScript are 0-indexed (January is 0, February is 1, etc), 
 * but by using 0 as the day it will give us the last day of the prior
 * month. So passing in 1 as the month number will return the last day
 * of January, not February.
 * 
 * https://stackoverflow.com/a/1184359
 * 
 * @returns Last day number of month given in "date".
 */
export const daysInMonth = date => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
}

export const getDateWithCertainTime = (time, strDate) => {
  const date = strDate != null ? new Date(strDate) : new Date();
  const [hour, minutes] = time.split(":");
  date.setHours(hour, minutes, 0, 0);
  return date;
};

export const getDateFromStrDateAndTime = (strDate, time) =>
  getDateWithCertainTime(time, strDate);

export const YYYYMMDDtoDDMM = str => {
  // eslint-disable-next-line
  const [year, month, day] = str.split("-");
  return `${day}/${month}`;
};

export const formattedStrDateToYYYYMMDD = str => {
  const [day, month, year] = str.split("/");
  return `${year}-${month}-${day}`;
};

export const timestampStrDate = strDate => new Date(strDate).valueOf();

export const productDeadlineSaleToDate = strDeadlineSale => {
  const [day, month, yearAndHour] = strDeadlineSale.split("/");
  const [year, hoursWithSpace, minutes] = yearAndHour.split(":");
  const hours = hoursWithSpace.substring(1);
  return new Date(`${year}-${month}-${day}T${hours}:${minutes}:00`);
};

export const generateYYYYMMDDwithParts = (year, month, day) => `${year}-${month}-${day}`;

export const checkStrDatesSameDay = (d1, d2) => {
  const date1 = new Date(d1), date2 = new Date(d2);
  return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
};

export const dateToStringDDMMYYYYHHMMSS = date => {
  const day = `${getNumberStringWith0IfLowerThan10(date.getDate())}/${getNumberStringWith0IfLowerThan10(date.getMonth()+1)}/${date.getFullYear()}`,
    hour = `${getNumberStringWith0IfLowerThan10(date.getHours())}:${getNumberStringWith0IfLowerThan10(date.getMinutes())}`;
  
  return day + " " + hour;
};

export const isPastDate = date => {
  const today = new Date();
  today.setHours(0,0,0,0); // Set HH:MM:SS:MM to 0 browser-independently

  return date.valueOf() < today.valueOf();
};

/**
 * WARNING: Import "common/common" i18n JSON when using this method.
 * 
 * Example: 
 *  input: 167
 *  output: 2h 47min (h/min will depend on user locale)
 */
export const formatTimeMinutes = minutes => {
  const hours = Math.trunc(minutes / 60),
    remainingMinutes = minutes % 60;

  if (minutes < 60) return t("common/common:formatNumMinutes.toMinutes", { minutes });
  if (remainingMinutes === 0) return t("common/common:formatNumMinutes.toHours", { hours });
  return t("common/common:formatNumMinutes.toHoursMinutes", { hours, minutes: remainingMinutes });
};