import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import BannerForm from "@src/components/banners/BannerForm";
import BannerService from "@src/services/bannerService";
import ErrorDisplayBox from "@src/components/ui/ErrorDisplayBox";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import * as Yup from "yup";
import { Dialog } from "@material-tailwind/react";

const CreateBanner = ({ open, handler: backCallback, creationEndedCallback, newsletterSelectedBanner }) => {
 
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const [errors, setErrors] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const { t, ready: translationsReady } = useTranslation(["components/banners/create","components/imageBank/imageBankForm"]);
  const BannerSchema = Yup.object().shape({
    description: Yup.string()
    .required("components/imageBank/imageBankForm:validationErrors.description.empty")
    .min(5, "components/imageBank/imageBankForm:validationErrors.description.min5characters")
    .max(255, "components/imageBank/imageBankForm:validationErrors.description.max255characters"),
    picture: Yup.mixed()
      .required("common/form:validationErrors.empty.file"),
  });

  const submitData = async (values, _) => {
    setW18ApiResponse(b => !b);

    try {
      const bannerCreated = await BannerService.create(selectedFile,values.description,values.enabled);
      setErrors(); // clear errorbox if present
      createNotification(NotificationTypes.success, t("okSubmissionResponse"));
      if (newsletterSelectedBanner){
        newsletterSelectedBanner(bannerCreated?.data);
      }
      creationEndedCallback();
    } catch (error) {
      createNotification(NotificationTypes.error, t("koSubmissionResponse"));
      if (error?.response?.data?.error) {
        const unformattedErrors = error.response.data.error;
        const formattedErrors = Object.entries(unformattedErrors).reduce((arr, e) => {
          const [errorKey, errorValues] = e;
          return arr.concat(errorValues.map(err => errorKey + " " + err));
        }, []);

        setErrors(formattedErrors);
      }

    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <Dialog open={open} handler={backCallback}>
          {errors && (
            <ErrorDisplayBox
              boxDescription={t("errorBoxDescription")}
              errors={errors}
            />
          )}
          <BannerForm
            title={t("title")}
            enabled={true}
            setSelectedFile={setSelectedFile}
            handleSubmit={submitData}
            bannerSchema={BannerSchema}
            backCallback={backCallback}
            showLoadingSpinnerSubmitButton={w18ApiResponse}
          />
        </Dialog>
      )}
    </>
  );
};

export default CreateBanner;