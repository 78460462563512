import React from "react";
import {formatPrice} from "@src/helpers/localeHelpers";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import TableCard from "@src/components/ui/TableCard";
import PencilButton from "@src/components/ui/buttons/PencilButton";
import TrashButton from "@src/components/ui/buttons/TrashButton";

const ListPricesTable = ({ currentPageData : priceList, editPriceCallback, deletePriceCallback, readOnly }) => {
  const { t, ready: translationsReady } = useTranslation(["components/products/edit/listPrices", "common/list", "common/common"]);

  const getHeadersArray = () => readOnly ? Object.values(t("labels.listPricesTableHeaders"))
   : Object.values(t("labels.listPricesTableHeaders")).concat(t("actionsTableHeader", { ns: "common/list" }));

  const formatDataMatrix = () =>
    priceList.map(price => {
      const dataRow = [
        price.type_client.name,
        formatPrice(price.price),
        price.quota || t("notAvailable", { ns: "common/common" }),
        price.available,
        <>
          <PencilButton onClick={() => editPriceCallback(price)} />
          <TrashButton onClick={() => deletePriceCallback(price)} />
        </>
      ];

      return readOnly ? dataRow.slice(0, -1) : dataRow;
    });

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
          title={t("labels.title")}
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

export default ListPricesTable;