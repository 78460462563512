import CountryFlag from '@src/components/ui/CountryFlag';
import React from 'react';

const CountryFlagWithLanguageCode = ({ language, className }) => (
  <div className={`flex ${className ?? ""}`}>
    <CountryFlag className="w-[25px]" languageCode={language} />
    <p className="ml-2">{language}</p>
  </div>
);
 
export default CountryFlagWithLanguageCode;