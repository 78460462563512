import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { emptyArray } from "@src/helpers/arrayHelpers";
import { getDateWithCertainTime } from "@src/helpers/dateHelpers";
import TextInput from "@src/components/forms/TextInput";
import Button from "@src/components/ui/Button";
import ErrorText from "@src/components/ui/ErrorText";
import ResourcesSelectedDetails from "@src/components/products/services/create/ResourcesSelectedDetails";
import Spinner from "@src/components/ui/Spinner";
import { Dialog } from "@material-tailwind/react";
import BasicFormTemplate, {
  FooterType,
} from "@src/components/forms/BasicFormTemplate";

const functionTestStartEndTime = (_, testContext) => {
  if (!(testContext.parent.startTime && testContext.parent.endTime)) return true;

  const dateStartTime = getDateWithCertainTime(testContext.parent.startTime),
    dateEndTime = getDateWithCertainTime(testContext.parent.endTime);
      
  return dateStartTime.valueOf() < dateEndTime.valueOf();
};

const ResourceFormInitialValues = {
  name: "",
  quantity: 0,
  capacity: 0,
  priceHour: 0,
  startTime: "",
  endTime: "",
};

const ResourceFormSchema = Yup.object().shape({
  name: Yup.string().required("common/form:validationErrors.empty.name"),
  quantity: Yup.number().required("components/services/create/resources:resourcesSelector.form.validations.noQuantity")
    .typeError("common/form:validationErrors.numbers.notANumber")
    .min(1, "common/form:validationErrors.numbers.requiredGreaterThan0")
    .integer("common/form:validationErrors.numbers.requiredNaturalNumber"),
  capacity: Yup.number().required("components/services/create/resources:resourcesSelector.form.validations.noCapacity")
    .typeError("common/form:validationErrors.numbers.notANumber")
    .min(1, "common/form:validationErrors.numbers.requiredGreaterThan0")
    .integer("common/form:validationErrors.numbers.requiredNaturalNumber"),
  priceHour: Yup.number().required("components/services/create/resources:resourcesSelector.form.validations.noPriceHour")
    .typeError("common/form:validationErrors.numbers.notANumber")
    .min(1, "common/form:validationErrors.numbers.requiredGreaterThan0"),
  startTime: Yup.string().required("common/form:validationErrors.empty.hour")
    .test("startTimeLower", "components/services/create/resources:resourcesSelector.form.validations.startTimeLower", functionTestStartEndTime),
  endTime: Yup.string().required("common/form:validationErrors.empty.hour")
    .test("endTimeGreater", "components/services/create/resources:resourcesSelector.form.validations.endTimeGreater", functionTestStartEndTime),
});

const ResourcesSelector = ({open, handler: closeDialog, cbSubmitResourcesSelection, cbCancelResourceSelector, resourcesToEdit }) => {
  const [resources, setResources] = useState(resourcesToEdit ? resourcesToEdit : []);
  const [errorMsg, setErrorMsg] = useState();
  const { t, ready: translationsReady } = useTranslation(["components/services/create/resources", "common/form", "pages/services/create", "common/list"]);

  const handleResourcesSelectionSubmission = () => {
    if (emptyArray(resources)) {
      setErrorMsg("resourcesSelector.noResourceError");
    } else {
      cbSubmitResourcesSelection(resources);
    }
  };

  const handleNewResource = async (values, actions) => {
    setResources(resources.concat(values));
    setErrorMsg();
    actions.resetForm({ values: ResourceFormInitialValues });
  };

  const removeResource = index => {
    setResources(resources.filter((_, i) => i !== index));
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <Dialog
          className="p-2"
          open={open}
          handler={closeDialog}
        >
        <div className="m-2 min-w-[24rem]">
          {!emptyArray(resources) &&
            <ResourcesSelectedDetails
              resources={resources}
              removeCallback={removeResource}
            />
          }
          <Formik
            initialValues={ResourceFormInitialValues}
            validationSchema={ResourceFormSchema}
            onSubmit={handleNewResource}
          >
            <Form>
             <BasicFormTemplate
              className="mt-8"
              formTitle={t("stepTitles.resources", { ns: "pages/services/create" })}
              backCallback={cbCancelResourceSelector}
              nextCallback={handleResourcesSelectionSubmission}
              footerType={FooterType.BACK_SUBMIT_BUTTONS}
              headerColor="teal"
            >
          <p>{t("resourcesSelector.description")}</p>
              <TextInput
                label={t("mainScreen.resourceTableHeaders.name")}
                name="name"
                yMargin="2"
              />
              <TextInput
                label={t("mainScreen.resourceTableHeaders.quantity")}
                name="quantity"
                yMargin="2"
              />
              <TextInput
                label={t("mainScreen.resourceTableHeaders.capacity")}
                name="capacity"
                yMargin="2"
              />
              <TextInput
                label={t("mainScreen.resourceTableHeaders.priceHour")}
                name="priceHour"
                yMargin="2"
              />
              <div className="my-2 p-2 border border-gray-400 rounded-md">
                <p>{t("mainScreen.resourceTableHeaders.availability")}</p>
                <TextInput
                  type="time"
                  label={t("resourcesSelector.form.labels.startTime")}
                  name="startTime"
                  yMargin="2"
                />
                <TextInput
                  type="time"
                  label={t("resourcesSelector.form.labels.endTime")}
                  name="endTime"
                  yMargin="2"
                />
                <Button
                type="submit"
                className="bg-gray-700 text-white font-bold"
              >{t("add", { ns: "common/list" })}</Button>
              </div>
            </BasicFormTemplate>
            </Form>
          </Formik>
          <ErrorText>{t(errorMsg)}</ErrorText>
        </div>
        </Dialog>
      )}
    </>
  );
};

export default ResourcesSelector;