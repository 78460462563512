import Spinner from '@src/components/ui/Spinner';
import React from 'react';
import { useTranslation } from 'react-i18next';


const FAQPage = () => {
  const { t, ready: translationsReady } = useTranslation("pages/b2c/staticHelpPages/faq");

  return (
    <div className="bg-white py-[67px] px-[135.9px] grid grid-cols-1 sm:text-sm md:text-base lg:text-lg xl:text-xl montserrat-medium-shark mx-auto w-full max-w-[1440px]">
      {!translationsReady ? <Spinner /> : (
        <>
          <p className="self-start font-extrabold sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-[#e30746]">{t("title")}</p>
          <p className="mt-10 font-bold md:text-xl xl:text-2xl text-[#e30746]">{t("sections.buyTickets.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("sections.buyTickets.content")}</p>
          <p className="mt-6 font-bold md:text-xl xl:text-2xl text-[#e30746]">{t("sections.officeLocation.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("sections.officeLocation.content")}</p>
          <p className="mt-6 font-bold md:text-xl xl:text-2xl text-[#e30746]">{t("sections.whatToDoInGranada.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("sections.whatToDoInGranada.content")}</p>
        </>
      )}
    </div>
  );
};
 
export default FAQPage;