import React from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from '@src/components/ui/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Button from '@src/components/ui/b2c/buttons/Button';
import Dialog from '@src/components/ui/b2c/dialogs/Dialog';

const BooleanResponseDialog = ({ children, open, yesCallback, noCallback }) => {
  const { t, ready: translationsReady } = useTranslation(["common/booleanResponse", "common/common"]);

  return (
    <Dialog
      showDialog={open}
      cbCloseDialog={noCallback}
    >
      {!translationsReady ? <Spinner /> : (
        <div className="grid grid-cols-1 space-y-4">
          <div className="flex items-center place-self-center space-x-2">
            <FontAwesomeIcon icon={faExclamationTriangle} color="orange" size="3x" />
            <p className="text-3xl font-bold">{t("warning", { ns: "common/common" })}</p>
          </div>
          <p className="text-center self-center">{children}</p>
          <div className="flex justify-between items-center">
            <Button
              className="bg-black text-white font-bold"
              onClick={noCallback}
            >{t("no")}</Button>
            <Button
              className="bg-[#e30746] text-white font-bold"
              onClick={yesCallback}
            >{t("yes")}</Button>
          </div>
        </div>
      )}
    </Dialog>
  );
}
 
export default BooleanResponseDialog;