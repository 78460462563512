import axios from "@src/helpers/axiosInstance";
import { getJWT } from "@src/helpers/localStorageHelpers";
import { APIEndpointsStrings } from "@src/helpers/stringHelpers";
import UserService from "@src/services/users/userService";

class ProfessionalService {
  static indexValidatedOnes = async () => {
    const endpoint = APIEndpointsStrings.usersPath + "?q[type_in][]=Provider&q[type_in][]=ServicesProvider&q[validated_at_not_null]=1&q[revoked_at_null]=1";
    const res = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });

    return res.data.map(professional => UserService.formatReceivedUser(professional));
  };

  static indexReviewableOnes = async () => {
    const endpoint = APIEndpointsStrings.usersPath + "?q[reviewable_professionals]=1";
    const res = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });

    return res.data.map(professional => UserService.formatReceivedUser(professional));
  }

  static enable = async (professional) => {
    const endpoint = APIEndpointsStrings.enableProfessionalPath.replace(APIEndpointsStrings.idLocators.id, professional.id);
    const res = await axios.patch(endpoint, undefined, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });

    return UserService.formatReceivedUser(res.data);
  };

  static disable = async (professional) => {
    const endpoint = APIEndpointsStrings.disableProfessionalPath.replace(APIEndpointsStrings.idLocators.id, professional.id);
    const res = await axios.patch(endpoint, undefined, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });

    return UserService.formatReceivedUser(res.data);
  };

  static submitCompanyData = companyData => 
    axios.post(APIEndpointsStrings.companiesPath, {
      company: this.formatCompanyDataToSend(companyData)
    }, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });

    static updateCompanyData = (companyId, companyData) => {
      const company = this.formatCompanyDataToSend(companyData),
      requestPath = APIEndpointsStrings.companyPath.replace(APIEndpointsStrings.idLocators.id, companyId);
      return axios.put(requestPath, { company });
    };
  
  static formatCompanyDataToSend = companyData => ({
    cif: companyData.cif,
    taxname: companyData.fiscalName,
    tradename: companyData.commercialName,
    address: companyData.address,
    responsible_name: companyData.managerName,
    responsible_phone: companyData.managerLandlineNumber,
    province: companyData.province,
    zip_code: companyData.zipCode ? companyData.zipCode : null,
    country: companyData.country ? companyData.country : null,
    responsible_position: companyData.managerPosition ? companyData.managerPosition : null,
    responsible_mobile: companyData.managerPhoneNumber ? companyData.managerPhoneNumber : null,
  });
}

export default ProfessionalService;