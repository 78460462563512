import React from "react";
import EyeButton from "@src/components/ui/buttons/EyeButton";
import TimesButton from "@src/components/ui/buttons/TimesButton";
import PencilButton from "@src/components/ui/buttons/PencilButton";
import TableCard from "@src/components/ui/TableCard";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";

const SelectedBannersTable = ({ bannerList, showBannerCallback, deselectBannerCallback, replaceBannerCallback }) => {
  const { t, ready: translationsReady } = useTranslation(["components/products/create/productImages", "common/list"]);

  const getHeadersArray = () => [t("imageTableHeader"), t("actionsTableHeader", { ns: "common/list" })];

  const formatDataMatrix = () =>
    bannerList.map((banner) => [
      banner.description,
      <>
        <EyeButton onClick={() => showBannerCallback(banner)} />
        {replaceBannerCallback && <PencilButton onClick={() => replaceBannerCallback()} />}
        {deselectBannerCallback && <TimesButton onClick={() => deselectBannerCallback()} />}
      </>,
    ]);

  return !translationsReady ? (
    <Spinner />
  ) : (
    <TableCard headersArray={getHeadersArray()} dataMatrix={formatDataMatrix()} isLastColumnForActions={true} />
  );
};

export default SelectedBannersTable;
