import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { dateToStringDDMMYYYYHHMMSS } from "@src/helpers/dateHelpers";
import {
  APIEndpointsStrings,
  DefinedRoutesStrings,
} from "@src/helpers/stringHelpers";
import TimesButton from "@src/components/ui/buttons/TimesButton";
import EyeButton from "@src/components/ui/buttons/EyeButton";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { formatPrice } from "@src/helpers/localeHelpers";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import TableCard from "@src/components/ui/TableCard";
import IconButton from "@src/components/ui/buttons/IconButton";

const ListOrdersTable = ({
  currentPageData: orderList,
  clientPerspective,
  cancelOrderCallback,
  printOrderCallback,
  title,
}) => {
  const { t, ready: translationsReady } = useTranslation([
    "components/orders/listTable",
    "common/orderStatuses",
    "common/list",
  ]);

  const getHeadersArray = () =>
    Object.values(t("headers")).concat(
      t("actionsTableHeader", { ns: "common/list" })
    );

  const formatDataMatrix = () =>
    orderList.map((order) => {
      const orderRow = [
        order.id,
        order.userEmail,
        formatPrice(order.totalPrice),
        dateToStringDDMMYYYYHHMMSS(order.createdAt),
        t(order.status, { ns: "common/orderStatuses" }),
        <>
          <EyeButton
            targetPath={DefinedRoutesStrings.showOrderPath.replace(
              APIEndpointsStrings.idLocators.id,
              order.id
            )}
          />
          <IconButton color="green" onClick={() => printOrderCallback(order)}>
            <FontAwesomeIcon icon={faPrint} />
          </IconButton>
          <TimesButton
            onClick={() => cancelOrderCallback(order)}
            disabled={order.cancelledAt}
          />
        </>,
      ];

      return !clientPerspective ? orderRow : orderRow.filter((_, i) => i !== 1); // Ignore userEmail if client (own order)
    });

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <TableCard
          title={title}
          headersArray={
            !clientPerspective
              ? getHeadersArray()
              : getHeadersArray().filter((_, i) => i !== 1)
          }
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

export default ListOrdersTable;
