import React from "react";
import Button from "@src/components/ui/b2c/buttons/Button";
import SmallSpinner from "react-spinkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HexStandardPinkStylingB2C } from "@src/helpers/stringHelpers";

const OptionalSubmitButton = ({ className: additionalClasses, icon, showLoadingSpinner, children: text, ...props }) => (
  <Button
    type="button"
    className={`bg-white border border-[${HexStandardPinkStylingB2C}] text-[${HexStandardPinkStylingB2C}] ${
      additionalClasses ?? ""
    }`}
    {...props}
  >
    <div className="flex items-center">
      {showLoadingSpinner ? (
        <SmallSpinner name="circle" fadeIn="none" color={HexStandardPinkStylingB2C} className="mr-2" />
      ) : (
        <>{icon && <FontAwesomeIcon icon={icon} className={`mr-2 text-[${HexStandardPinkStylingB2C}]`} />}</>
      )}
      {text}
    </div>
  </Button>
);

export default OptionalSubmitButton;
