import HistoryBackButton from '@src/components/ui/buttons/HistoryBackButton';
import PaginateData from '@src/components/ui/PaginateData';
import Spinner from '@src/components/ui/Spinner';
import DisableProfessionalsTable from '@src/components/users/DisableProfessionalsTable';
import { createNotification, NotificationTypes } from '@src/helpers/notificationHelpers';
import { CommonRoutesStrings } from '@src/helpers/stringHelpers';
import ProfessionalService from '@src/services/users/professionalService';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const DisableProfessionalsPage = () => {
  const [professionals, setProfessionals] = useState();
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation("pages/users/disableProfessionals");
  const history = useHistory();
  const PROFESSIONALS_PER_PAGE = 5;

  useEffect(() => {
    const fetchProfessionals = async () => {
      try {
        setProfessionals(await ProfessionalService.indexValidatedOnes());
      } catch (err) {
        createNotification(NotificationTypes.error, t("messages.cannotRetrieveProfessionals"));
        history.replace(CommonRoutesStrings.homePath);
      }
    };

    translationsReady && fetchProfessionals();
    // eslint-disable-next-line
  }, [translationsReady]);

  const updateProfessionalInTable = newProfessional => {
    const newProfessionalsList = professionals.map(professional => professional.id === newProfessional.id ? newProfessional : professional);
    setProfessionals(newProfessionalsList);
  };

  const performEnableDisable = async (professional, action, messages) => {
    setW18ApiResponse(b => !b);

    try {
      const updatedProfessional = await action(professional);
      createNotification(NotificationTypes.success, messages.ok);
      updateProfessionalInTable(updatedProfessional);
    } catch (err) {
      createNotification(NotificationTypes.error, messages.ko);
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  const enableProfessional = professional =>
    performEnableDisable(professional, ProfessionalService.enable, t("messages.enableProfessional"));

  const disableProfessional = professional =>
    performEnableDisable(professional, ProfessionalService.disable, t("messages.disableProfessional"));

  return !(translationsReady && professionals) ? <Spinner /> : (
    <div className="grid grid-cols-1 place-items-center">
      <HistoryBackButton />
      <PaginateData 
        data={professionals}
        perPage={PROFESSIONALS_PER_PAGE}
        emptyDataArrayMsg={t("messages.noProfessionalsToList")}
      >
        <DisableProfessionalsTable
          w18ApiResponse={w18ApiResponse}
          cbEnableProfessional={enableProfessional}
          cbDisableProfessional={disableProfessional}
        />
      </PaginateData>
      {w18ApiResponse && <Spinner />}
    </div>
  );
}
 
export default DisableProfessionalsPage;