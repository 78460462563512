import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { emptyArray } from "@src/helpers/arrayHelpers";
import { stringDateFormat } from "@src/helpers/dateHelpers";
import BooleanRadioButtons from "@src/components/forms/BooleanRadioButtons";
import IrregularAvailabilitySelector from "@src/components/products/product_wizard/IrregularAvailabilitySelector";
import RegularAvailabilitySelector from "@src/components/products/product_wizard/RegularAvailabilitySelector";
import Spinner from "@src/components/ui/Spinner";
import BasicFormTemplate, { FooterType } from "@src/components/forms/BasicFormTemplate";

const AvailabilityForm = ({ editingProductConfigurations, alreadyExistentConfigurations, initialValues, callbackNextClicked, callbackBackClicked }) => {
  const [isRegular, setIsRegular] = useState(initialValues.regularity);
  const [regularConfigurations, setRegularConfigurations] = useState(initialValues.regularity ? initialValues.configurations : []);
  const [irregularConfigurations, setIrregularConfigurations] = useState(initialValues.regularity ? [] : initialValues.configurations);
  const { t, ready: translationsReady } = useTranslation(["components/products/create/availability", "pages/products/create", "components/products/edit/addConfiguration"]);

  const sortDateRanges = () => 
    [...regularConfigurations].sort((a, b) => new Date(a.fromDate).valueOf() - new Date(b.fromDate).valueOf());

  const processIrregularConfigurations = () => {
    const dateRange = irregularConfigurations[0], dates = dateRange.dates;
    dates.sort();
    return [{
      ...dateRange,
      fromDate: dates[0],
      toDate: dates.at(-1),
      dates
    }];
  };

  const changedRegularityAndSomeDataAlreadySet = () => 
    (isRegular === false && regularConfigurations.some(configuration => configuration.regularityType != null))
    ||
    (isRegular === true && irregularConfigurations[0] && irregularConfigurations[0].times != null);

  const handleNextStep = () => {    
    let data = isRegular ? sortDateRanges() : processIrregularConfigurations();
    if (changedRegularityAndSomeDataAlreadySet() && window.confirm(t("labels.main.configurationsAlreadySetAndRegularityChangedMessage")))
      callbackNextClicked({ regularity: isRegular, configurations: data });
    else if (!changedRegularityAndSomeDataAlreadySet())
      callbackNextClicked({ regularity: isRegular, configurations: data });  
  }

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <>
          <BasicFormTemplate
            formTitle={t("stepTitles", { ns: "pages/products/create" }).availability}
            backCallback={callbackBackClicked}
            nextCallback={handleNextStep}
            footerType={FooterType.BACK_NEXT_BUTTONS}
            headerColor="teal"
            className="mt-10 max-w-3xl mx-auto"
            disableSubmitButton={(isRegular && emptyArray(regularConfigurations)) || (!isRegular && emptyArray(irregularConfigurations))}
          >
            <p>{t("labels.main.chooseRegularity")}</p>
            <div>
              <BooleanRadioButtons cbSetState={setIsRegular} defaultValue={isRegular} />
              {alreadyExistentConfigurations && !emptyArray(alreadyExistentConfigurations) &&
                <div className="flex my-2">
                  <div className="border-2 rounded-sm shadow-md mr-auto p-2">
                    <p>{t("labels.alreadyRegisteredRanges", { ns: "components/products/edit/addConfiguration" })}</p>
                    <ul className="list-inside list-disc">
                    {alreadyExistentConfigurations.map((configuration, index) => 
                      <li key={`existent-configurations-${index}`}>
                        {`${stringDateFormat(configuration.fromDate)} - ${stringDateFormat(configuration.toDate)}`}
                      </li>
                    )}
                    </ul>
                  </div>
                </div>
              }
              {isRegular ? (
                <RegularAvailabilitySelector
                  editingProductConfigurations={editingProductConfigurations}
                  alreadyExistentConfigurations={alreadyExistentConfigurations}
                  configurations={regularConfigurations}
                  setConfigurations={setRegularConfigurations}
                />
              ) : (
                <IrregularAvailabilitySelector
                  editingProductConfigurations={editingProductConfigurations}
                  alreadyExistentConfigurations={alreadyExistentConfigurations}
                  configurations={irregularConfigurations}
                  setConfigurations={setIrregularConfigurations}
                />
              )}
            </div>
          </BasicFormTemplate>
        </>
      )}
    </>
  );
};

export default AvailabilityForm;