import { Form, Formik } from "formik";
import React, { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import { ProductTypesStrings } from "@src/helpers/stringHelpers";
import FormCategorySearch from "@src/components/products/FormCategorySearch";
import FormProfessionalSearch from "@src/components/products/FormProfessionalSearch";
import { getUserRequest, isProfessional } from "@src/helpers/userHelpers";
import {getCategoriesWithId, getServiceCategoriesWithId} from "@src/helpers/categoryHelpers";
import { isOwnProduct, isThirdPartyProduct } from "@src/helpers/productHelpers";
import FormServiceCategorySearch from "@src/components/products/FormServiceCategorySearch";
import Spinner from "@src/components/ui/Spinner";
import { useTranslation } from "react-i18next";
import BasicFormTemplate, { FooterType } from "@src/components/forms/BasicFormTemplate";
import FormikTextInput from "@src/components/forms/FormikTextInput";
import FormikSelect from "@src/components/forms/FormikSelect";
import FormikCheckbox from "@src/components/forms/FormikCheckbox";
import FormLocationSearch from "@src/components/locations/FormLocationSearch";
import LocationService from "@src/services/locationService";

const ProductPropertiesSchema = Yup.object().shape({
  productType: Yup.string().required("components/products/create/productPropertiesForm:validations.noProductType"),
  comission: Yup.number().typeError("components/products/create/productPropertiesForm:validations.commissionIsNotANumber")
                .min(0, "components/products/create/productPropertiesForm:validations.positiveCommission")
                .max(100, "components/products/create/productPropertiesForm:validations.commissionBetween0n100"),
  categoryIds: Yup.array().of(Yup.number()).min(1, "components/products/create/productPropertiesForm:validations.noCategorySelected"),
  serviceCategoryIds: Yup.array().test("mandatoryIfThttirdPartyProduct", "components/products/create/productPropertiesForm:validations.noServiceCategorySelected", (value, testContext) =>
      !(testContext.parent.serviceCategoryIds.length === 0 && !(isThirdPartyProduct(testContext.parent.productType)))
  ),
  professionalId: Yup.number().test("mandatoryIfThirdPartyProduct", "components/products/create/productPropertiesForm:validations.noProfessionalSelected", (value, testContext) =>
    !(isThirdPartyProduct(testContext.parent.productType) && testContext.parent.professionalId == null)
  ),
  locationIds:  Yup.array().test("mandatoryIfThirdPartyProduct","components/products/create/productPropertiesForm:validations.noLocationSelected", (value, testContext) =>
  !(testContext.parent.locationIds.length === 0)
),
  requirePersonalData: Yup.boolean(),
  queues: Yup.boolean(),
});

const ProductPropertiesForm = ({ initialValues, callbackNextClicked, callbackBackClicked, editing, showLoadingSpinner, readOnly }) => {
  const [professional, setProfessional] = useState();
  const [categories, setCategories] = useState();
  const [locations, setLocations] = useState();
  const [serviceCategories, setServiceCategories] = useState();
  const [loadedProfessional, setLoadedProfessional] = useState(false);
  const [loadedCategories, setLoadedCategories] = useState(false);
  const [loadedServiceCategories, setLoadedServiceCategories] = useState(false);
  const [loadedLocations, setLoadLocations] = useState(false);
  const serviceCategoriesSelectRef = useRef();
  const { t, ready: translationsReady } = useTranslation([
    "components/products/create/productPropertiesForm",
    "pages/products/create",
    "common/form",
    "common/productTypes",
    "components/locations/form"
  ]);

  useEffect(() => {
    const fetchProfessional = async () => {
      if (!isProfessional() && !(initialValues.professionalId == null || initialValues.professionalId === "")) {
        try {
          const getUserResponse = await getUserRequest(initialValues.professionalId);
          setProfessional(getUserResponse.data);
        } catch (err) {
          console.log(err);
        }
      }

      setLoadedProfessional(true);
    };

    const fetchCategories = async () => {
      if (initialValues.categoryIds.length !== 0) {
        try {
          const getCategoriesResponse = await getCategoriesWithId(initialValues.categoryIds);
          setCategories(getCategoriesResponse.data);
        } catch (err) {
          console.log(err);
        }
      }

      setLoadedCategories(true);
    };

    const fetchServiceCategories = async () => {
      if (initialValues.serviceCategoryIds.length !== 0) {
        try {
          const getServiceCategoriesResponse = await getServiceCategoriesWithId(initialValues.serviceCategoryIds);
          setServiceCategories(getServiceCategoriesResponse.data);
        } catch (err) {
          console.log(err);
        }
      }

      setLoadedServiceCategories(true);
    };

    const fetchLocations = async () => {
      if (initialValues.locationIds.length !== 0) {
        try {
          const getLocationsResponse = await LocationService.getLocationWithId(initialValues.locationIds);
          setLocations(getLocationsResponse.data);
        } catch (err) {
          console.log(err);
        }
      }

      setLoadLocations(true);
    };

    fetchProfessional();
    fetchCategories();
    fetchServiceCategories();
    fetchLocations();
    // eslint-disable-next-line
  }, []);

  const getOptionsForProductTypeSelect = () => [
    {
      value: ProductTypesStrings.ownProduct,
      label: t("OwnProduct", { ns: "common/productTypes" })
    },
    {
      value: ProductTypesStrings.thirdPartyProduct,
      label: t("ThirdPartyProduct", { ns: "common/productTypes" })
    }
  ];

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <div className="max-w-md mx-auto mt-10">
          <Formik
            initialValues={initialValues}
            validationSchema={ProductPropertiesSchema}
            onSubmit={(values, actions) =>
              callbackNextClicked({ values, actions })
            }
          >
            {({ values, setFieldValue, setFieldError }) => (
              <Form>
                <BasicFormTemplate
                  formTitle={t("stepTitles.productProperties", {
                    ns: "pages/products/create",
                  })}
                  backCallback={callbackBackClicked}
                  footerType={
                    readOnly
                      ? FooterType.BACK_BUTTON
                      : editing
                      ? FooterType.BACK_SUBMIT_BUTTONS
                      : FooterType.BACK_NEXT_BUTTONS
                  }
                  headerColor="teal"
                  showLoadingSpinner={showLoadingSpinner}
                >
                  <div className="space-y-4">
                    {!isProfessional() && (
                      <>
                        {!editing && (
                          <FormikSelect
                            label={t("labels.productType")}
                            name="productType"
                            options={getOptionsForProductTypeSelect()}
                            disabled={readOnly}
                            defaultOptionLabel={t("placeholders.selectOne", {
                              ns: "common/form",
                            })}
                            onChange={(selectedType) => {
                              setFieldValue("productType", selectedType);
                              setFieldError("productType", undefined);
                              if (isOwnProduct(selectedType)) {
                                setFieldValue("comission", "");
                              } else {
                                serviceCategoriesSelectRef.current.clearValue();
                              }
                              setFieldValue("professionalId", "");
                            }}
                          />
                        )}
                        {/* <FormikTextInput
                          disabled={
                            readOnly || !isThirdPartyProduct(values.productType)
                          }
                          label={t("labels.commission")}
                          secondLabel={t(
                            "labels.conditional.thirdPartyProductOnly"
                          )}
                          name="comission"
                        /> */}
                        {!loadedProfessional ? (
                          <Spinner />
                        ) : (
                          <FormProfessionalSearch
                            disabled={
                              values.productType === "" ||
                              values.productType ===
                                ProductTypesStrings.ownProduct ||
                              readOnly
                            }
                            productType={values.productType}
                            label={t("labels.professional")}
                            secondLabel={t(
                              "labels.conditional.thirdPartyProductOnly"
                            )}
                            placeholder={t("placeholders.selectOne", {
                              ns: "common/form",
                            })}
                            name="professionalId"
                            defaultProfessional={professional}
                            loadOptionsErrorMsg={t(
                              "messages.errorRetrievingProfessionals",
                              { ns: "pages/products/create" }
                            )}
                            noOptionsMessage={t(
                              "placeholders.noOptionsInSelect",
                              { ns: "common/form" }
                            )}
                          />
                        )}
                      </>
                    )}
                    {!loadedCategories ? (
                      <Spinner />
                    ) : (
                      <FormCategorySearch
                        label={t("labels.categories")}
                        placeholder={t("placeholders.selectOne", {
                          ns: "common/form",
                        })}
                        name="categoryIds"
                        defaultCategories={categories}
                        loadOptionsErrorMsg={t(
                          "messages.errorRetrievingCategories",
                          { ns: "pages/products/create" }
                        )}
                        disabled={readOnly}
                      />
                    )}
                    {!loadedServiceCategories ? (
                      <Spinner />
                    ) : (
                      <FormServiceCategorySearch
                        innerRef={serviceCategoriesSelectRef}
                        disabled={
                          values.productType === "" ||
                          values.productType ===
                            ProductTypesStrings.thirdPartyProduct ||
                          readOnly
                        }
                        label={t("labels.serviceCategories")}
                        secondLabel={t("labels.conditional.ownProductOnly")}
                        placeholder={t("placeholders.selectOne", {
                          ns: "common/form",
                        })}
                        name="serviceCategoryIds"
                        defaultServiceCategories={serviceCategories}
                        loadOptionsErrorMsg={t(
                          "messages.errorRetrievingCategories",
                          { ns: "pages/products/create" }
                        )}
                      />
                    )}
                    {!loadedLocations ? (
                      <Spinner />
                    ) : (
                      <FormLocationSearch
                        label={t("zip_code",{
                          ns: "components/locations/form",
                        }) + " / " + t("nameLabel", {
                          ns: "components/locations/form",
                        })}
                        defaultLocations={locations}
                        placeholder={t("placeholders.noOptionsInSelect", {
                          ns: "common/form",
                        })}
                        name="locationIds"
                        loadOptionsErrorMsg={t("errorBoxDescriptions", {
                          ns: "pages/locations/create",
                        })}
                        noOptionsMessage={t("placeholders.noOptionsInSelect", {
                          ns: "common/form",
                        })}
                        disabled={readOnly}
                      />
                    )}
                    <FormikCheckbox
                      name="requirePersonalData"
                      disabled={
                        readOnly || isThirdPartyProduct(values.productType)
                      }
                      label={t("labels.requirePersonalData")}
                      id="personalDataCheckbox"
                    />
                    <FormikCheckbox
                      name="queues"
                      disabled={readOnly}
                      label={t("labels.productQueues")}
                      id="queuesCheckbox"
                    />
                  </div>
                </BasicFormTemplate>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default ProductPropertiesForm;