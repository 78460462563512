import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { getCurrentUserData } from "@src/helpers/localStorageHelpers";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { DefinedRoutesStrings, UserTypesStrings } from "@src/helpers/stringHelpers";
import { createUserRequest } from "@src/helpers/userHelpers";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import BasicFormTemplate, { FooterType } from "@src/components/forms/BasicFormTemplate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock, faRedo } from "@fortawesome/free-solid-svg-icons";
import FormikTextInput from "@src/components/forms/FormikTextInput";
import { useHistory } from "react-router-dom";
import FormikSelect from "@src/components/forms/FormikSelect";

const CreateUserSchema = Yup.object().shape({
  email: Yup.string().email("common/form:validationErrors.empty.email").required("common/form:validationErrors.empty.email"),
  password: Yup.string().required("common/form:validationErrors.empty.password").min(6, "common/form:validationErrors.passwords.passwordMinLength"),
  passwordConfirmation: Yup.string().required("common/form:validationErrors.empty.confirmationPassword")
    .oneOf([Yup.ref("password"), null], "common/form:validationErrors.passwords.notMatchingPasswords"),
  userType: Yup.string().required("pages/users/create:noUserTypeValidationError")
});

const CreateUserInitialValues = {
  email: "",
  password: "",
  passwordConfirmation: "",
  userType: ""
};

export function getOptionsForUserTypeSelect() {
  const userTypes = [
    UserTypesStrings.client,
    UserTypesStrings.provider,
    UserTypesStrings.servicesProvider,
    UserTypesStrings.operator, 
    UserTypesStrings.admin
  ];
  let options = [];

  if (getCurrentUserData().type === UserTypesStrings.operator) userTypes.pop();

  userTypes.forEach(value => options.push({
    value,
    label: `common/userTypes:${value}`
  }));

  return options;
};

const CreateUserPage = () => {
  const history = useHistory();
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation(["pages/users/create", "common/form", "common/userTypes"]);

  const handleSubmit = async ({ email, password, userType }) => {
    setW18ApiResponse(b => !b);

    try {
      await createUserRequest(email, password, userType);
      createNotification(NotificationTypes.success, t("messages.successCreateUser"));
      history.push(DefinedRoutesStrings.listUsersPath);
    } catch (err) {
      if (err.response.status === 401 && err.response.data.error.hasOwnProperty("email")) {
        createNotification(NotificationTypes.error, t("validationErrors.emailTaken", { ns: "common/form" }));
      }
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="relative max-w-sm w-96 mx-auto my-auto mt-20">
          <Formik
            initialValues={CreateUserInitialValues}
            validationSchema={CreateUserSchema}
            onSubmit={handleSubmit}
          >
            <Form className="max-w-md mx-auto">
              <BasicFormTemplate
                formTitle={t("titles.generic")}
                showLoadingSpinner={w18ApiResponse}
                footerType={FooterType.BACK_SUBMIT_BUTTONS}
              >
                <div className="mb-12 px-4">
                  <FormikTextInput
                    name="email"
                    type="email"
                    color="light-blue"
                    label={t("labels.email", { ns: "common/form" })}
                    icon={<FontAwesomeIcon icon={faEnvelope} color="lightGray" />}
                  />
                </div>
                <div className="mb-8 px-4">
                  <FormikTextInput
                    name="password"
                    type="password"
                    color="light-blue"
                    label={t("labels.password", { ns: "common/form" })}
                    icon={<FontAwesomeIcon icon={faLock} color="lightGray" />}
                  />
                </div>
                <div className="mb-12 px-4">
                  <FormikTextInput
                    name="passwordConfirmation"
                    type="password"
                    color="light-blue"
                    label={t("labels.confirmPassword", { ns: "common/form" })}
                    icon={<FontAwesomeIcon icon={faRedo} color="lightGray" />}
                  />
                </div>
                <div className="mb-8 px-4">
                  <FormikSelect
                    label={t("labels.userType", { ns: "common/form" })}
                    name="userType"
                    options={getOptionsForUserTypeSelect()}
                  />
                </div>
              </BasicFormTemplate>
            </Form>
          </Formik>
        </div>
      )}
    </>
  );
};

export default CreateUserPage;