import React from "react";
import { useTranslation } from "react-i18next";
import { formatHour } from "@src/helpers/localeHelpers";
import Spinner from "@src/components/ui/Spinner";
import TableCard from "@src/components/ui/TableCard";
import { stringDateFormat } from "@src/helpers/dateHelpers";
import EuroButton from "@src/components/ui/buttons/EuroButton";
import PencilButton from "@src/components/ui/buttons/PencilButton";
import TrashButton from "@src/components/ui/buttons/TrashButton";
import SwitchOnButton from "@src/components/ui/buttons/SwitchOnButton";
import SwitchOffButton from "@src/components/ui/buttons/SwitchOffButton";
import CountryFlagWithLanguageCode from "@src/components/products/product_wizard/CountryFlagWithLanguageCode";
import BanButton from "@src/components/ui/buttons/BanButton";

const ListTimeslicesTable = ({
                               currentPageData: timesliceList,
                               date,
                               editTimesliceCallback,
                               deleteTimesliceCallback,
                               enableTimesliceCallback,
                               disableTimesliceCallback,
                               showPricesOfTimesliceCallback,
                               cancelTimesCallback,
                               readOnly
                             }) => {
  const { t, ready: translationsReady } = useTranslation("components/products/edit/listTimeslices");

  const getHeadersArray = () => Object.values(t("labels.listTimeslicesTableHeaders"));

  const formatDataMatrix = () =>
    timesliceList.map(timeslice => [
      formatHour(timeslice.begins_at),
      t("labels.durationMinutesText", { duration: timeslice.duration }),
      timeslice.quota,
      timeslice.available,
      <CountryFlagWithLanguageCode language={timeslice.language} />,
      timeslice.deadline_sale,
      <>
        <EuroButton
          onClick={() => showPricesOfTimesliceCallback(timeslice)}
          title={t("labels.viewTimeslicePricesTitle")}
        />
        {!readOnly &&
          <>
            <PencilButton
              hidden={readOnly}
              onClick={() => editTimesliceCallback(timeslice)}
            />
            <TrashButton
              hidden={readOnly}
              onClick={() => deleteTimesliceCallback(timeslice)}
            />
             <BanButton
            id={`cancel-product-${timeslice}`}
            onClick={() => cancelTimesCallback(timeslice)}
          />
            {timeslice.disabled_at ? 
              <SwitchOnButton
                hidden={readOnly}
                title={t("labels.viewTimesliceSwitchOnTitle")}
                onClick={() => enableTimesliceCallback(timeslice)}
              />
              :
              <SwitchOffButton
                hidden={readOnly}
                title={t("labels.viewTimesliceSwitchOffTitle")}
                onClick={() => disableTimesliceCallback(timeslice)}
              />
            }
          </>
        }        
      </>
    ]);

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
          title={t("labels.title", { date: stringDateFormat(date) })}
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
          timeSlices={timesliceList}
        />
      )}
    </>
  );
};

export default ListTimeslicesTable;