import { useFormikContext } from "formik";
import React from "react";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import FormSearchInput from "@src/components/forms/FormSearchInput";
import { getCategoriesByName } from "@src/helpers/categoryHelpers";

const CategoryFormSearch = ({
  onChange,
  label,
  placeholder,
  name,
  loadOptionsErrorMsg,
  noOptionsMessage,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();

  const loadOptions = (inputValue) =>
    getCategoriesByName(
      inputValue
    )
      .then((res) => res.data)
      .catch((err) =>
        createNotification(NotificationTypes.error, loadOptionsErrorMsg)
      );

  const getOptionValue = (option) => option.id;
  const getOptionLabel = (option) => option.name;

  const handleChange =  options => {
    if (onChange) {
      onChange(options);
    } else {
      setFieldValue(name, options.map(({ id }) => id));
    }
  };

  return (
    <FormSearchInput
      label={label}
      placeholder={placeholder}
      name={name}
      noOptionsMessage={noOptionsMessage}
      isClearable={true}
      isMulti={true}
      cbLoadOptions={loadOptions}
      cbGetOptionLabel={getOptionLabel}
      cbGetOptionValue={getOptionValue}
      cbHandleChange={handleChange}
      {...props}
    />
  );
};

export default CategoryFormSearch;
