import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import LinkButton from "@src/components/ui/buttons/LinkButton";
import Spinner from "@src/components/ui/Spinner";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { CommonRoutesStrings, DefinedRoutesStrings } from "@src/helpers/stringHelpers";
import {
  currentUserHasWritePermissions,
  currentProfessionalIsValidated,
  isProfessional,
} from "@src/helpers/userHelpers";
import UserCard from "@src/components/users/UserCard";
import FormTitle from "@src/components/forms/FormTitle";
import SubmitButton from "@src/components/ui/buttons/SubmitButton";
import AddCompanyDataDialog from "@src/components/users/company/AddCompanyDataDialog";
import ShowCompanyDataDialog from "@src/components/users/company/ShowCompanyDataDialog";
import UserService from "@src/services/users/userService";
import EditCompanyDataDialog from "@src/components/users/company/EditCompanyDataDialog";

const ProfilePage = () => {
  const [user, setUser] = useState();
  const [showAddCompanyDataDialog, setShowAddCompanyDataDialog] = useState(false);
  const [showShowCompanyDataDialog, setShowShowCompanyDataDialog] = useState(false);
  const [showEditCompanyDataDialog, setShowEditCompanyDataDialog] = useState(false);
  const { t, ready: translationsReady } = useTranslation(["pages/users/profile"]);
  const history = useHistory();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setUser(await UserService.showCurrentUser());
      } catch (_) {
        createNotification(NotificationTypes.error, t("messages.genericRequestError"));
        history.replace(CommonRoutesStrings.homePath);
      }
    };

    translationsReady && fetchUser();
    // eslint-disable-next-line
  }, [translationsReady]);

  return (
    <>
      {!(translationsReady && user) ? (
        <Spinner />
      ) : (
        <>
          <div className="grid grid-cols-1 justify-items-center space-y-2">
            <FormTitle>{t("title")}</FormTitle>
            <UserCard user={user} />
            <LinkButton
              to={DefinedRoutesStrings.editMyProfilePath}
              disabled={!currentUserHasWritePermissions() || !currentProfessionalIsValidated()}
            >
              <FontAwesomeIcon icon={faPencilAlt} color="white" className="mr-2" />
              {t("buttons.editAcc")}
            </LinkButton>
            {isProfessional() && (
              <>
                {user.validatedAt || user.revokedAt ? (
                  <SubmitButton
                    icon={faEye}
                    type="button"
                    color="gray"
                    onClick={() => setShowShowCompanyDataDialog(true)}
                  >
                    {t("buttons.company.show")}
                  </SubmitButton>
                ) : (
                  <>
                    {user.company ? (
                      <SubmitButton
                        icon={faPencilAlt}
                        type="button"
                        color="yellow"
                        onClick={() => setShowEditCompanyDataDialog(true)}
                      >
                        {t("buttons.company.edit")}
                      </SubmitButton>
                    ) : (
                      <SubmitButton
                        icon={faPlus}
                        type="button"
                        color="green"
                        onClick={() => setShowAddCompanyDataDialog(true)}
                      >
                        {t("buttons.company.add")}
                      </SubmitButton>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <AddCompanyDataDialog open={showAddCompanyDataDialog} handler={() => setShowAddCompanyDataDialog(false)} />
          <EditCompanyDataDialog
            open={showEditCompanyDataDialog}
            handler={() => setShowEditCompanyDataDialog(false)}
            company={user.company}
          />
          <ShowCompanyDataDialog
            open={showShowCompanyDataDialog}
            handler={() => setShowShowCompanyDataDialog(false)}
            company={user.company}
          />
        </>
      )}
    </>
  );
};

export default ProfilePage;
