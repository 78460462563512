import Button from "@src/components/ui/buttons/Button";
import React from "react";
import { useHistory } from "react-router-dom";

const LinkButton = ({ className, to: routeToLink, children, ...props}) => {
  const history = useHistory();

  return (
    <Button
      className={className}
      onClick={() => history.push(routeToLink)}
      {...props}
    >{children}</Button>
  );
};

export default LinkButton;