import { Dialog } from '@material-tailwind/react';
import Spinner from '@src/components/ui/Spinner';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BasicFormTemplate, { FooterType } from '@src/components/forms/BasicFormTemplate';

const ShowCompanyDataDialog = ({ open, handler, company }) => {
  const { t, ready: translationsReady } = useTranslation(["components/users/validateProfessionalDialog", "components/users/companyDataForm"]);

  return (
    <Dialog
      open={open}
      handler={handler}
    >
      <>
        {!(translationsReady && company) ? <Spinner /> : (
          <BasicFormTemplate
            className="mt-8"
            formTitle={t("titles.show", { ns: "components/users/companyDataForm" })}
            footerType={FooterType.BACK_BUTTON}
            backCallback={handler}
          >
            <ul className="m-4 list-inside list-disc">
              <li>{t("content.companyData.cif", { cif: company.cif })}</li>
              <li>{t("content.companyData.fiscalName", { name: company.fiscalName })}</li>
              <li>{t("content.companyData.commercialName", { name: company.commercialName })}</li>
              <li>{t("content.companyData.address", { address: company.address })}</li>
              <li>{t("content.companyData.managerName", { name: company.managerName })}</li>
              <li>{t("content.companyData.managerLandlineNumber", { number: company.managerLandlineNumber })}</li>
              <li>{t("content.companyData.province", { province: company.province })}</li>
              {/* Optional fields */}
              {company.zipCode &&
                <li>{t("content.companyData.zipCode", { zipCode: company.zipCode })}</li>
              }
              {company.country &&
                <li>{t("content.companyData.country", { country: company.country })}</li>
              }
              {company.managerPosition &&
                <li>{t("content.companyData.managerPosition", { managerPosition: company.managerPosition })}</li>
              }
              {company.managerPhoneNumber &&
                <li>{t("content.companyData.managerPhoneNumber", { number: company.managerPhoneNumber })}</li>
              }
            </ul>
          </BasicFormTemplate>
        )}
      </>
    </Dialog>
  );
};
 
export default ShowCompanyDataDialog;