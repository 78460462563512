import PaginateData from '@src/components/ui/PaginateData';
import React, { useState } from 'react';
import ListOrderObjects from '@src/components/orders/b2c/list/ListOrderObjects';
import Spinner from '@src/components/ui/Spinner';
import { Redirect } from 'react-router-dom';
import { CommonRoutesStrings } from '@src/helpers/stringHelpers';
import BooleanResponseDialog from '@src/components/ui/b2c/dialogs/BooleanResponseDialog';
import HighlightedProducts from '@src/components/products/b2c/HighlightedProducts';
import OrderRefundDetailsDialog from '@src/components/orders/b2c/list/OrderRefundDetailsDialog';

import "@src/assets/styles/b2c/pages/list_orders/list-orders.css";
import "@src/assets/styles/b2c/pages/list_orders/globals.css";
import "@src/assets/styles/b2c/pages/list_orders/styleguide.css";

const ListOrdersB2CSkin = ({
  orders,
  error,
  orderToCancel,
  cbSetOrderToCancel,
  w18ApiResponse,
  t,
  cbCancelOrder,
  cbPrintOrder,
}) => {
  const [orderToShowRefundDetails, setOrderToShowRefundDetails] = useState();

  return (
    <div className="container-center-horizontal">
      {error && <Redirect to={CommonRoutesStrings.homePath} />}
      <div className="pedidos screen">
        <h1 className="title">{t("titles.client")}</h1>

        {/* listado pedidos */}
        {!orders ? <Spinner /> : (
          <div className="frame-1973 montserrat-medium-shark-20px">
            <PaginateData
              data={orders}
              emptyDataArrayMsg={t("messages.noOrdersToList")}
              usedInB2CPortal={true}
              perPage={5}
            >
              <ListOrderObjects cbSetOrderToCancel={cbSetOrderToCancel} cbPrintOrder={cbPrintOrder} cbSetOrderToShowRefundDetails={setOrderToShowRefundDetails} />
            </PaginateData>
          </div>
        )}
        {w18ApiResponse && <Spinner />}

        <BooleanResponseDialog
          open={!!orderToCancel}
          noCallback={() => cbSetOrderToCancel()}
          yesCallback={cbCancelOrder}
        >{t("messages.cancelAction.confirmDialog")}</BooleanResponseDialog>

        <OrderRefundDetailsDialog
          orderRefund={orderToShowRefundDetails?.orderRefund}
          handler={() => setOrderToShowRefundDetails()}
        />

        {/* productos destacados */}
        <div className="rectangle-301"></div>
        <HighlightedProducts />
        
      </div>
    </div>
  )
};
 
export default ListOrdersB2CSkin;