import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import ListServiceCategoriesTable from "@src/components/products/services/categories/ListServiceCategoriesTable";
import Button from "@src/components/ui/buttons/Button";
import PaginateData from "@src/components/ui/PaginateData";
import HistoryBackButton from "@src/components/ui/buttons/HistoryBackButton";
import { delServiceCategory, getServiceCategories } from "@src/helpers/categoryHelpers";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { CommonRoutesStrings } from "@src/helpers/stringHelpers";
import CreateServiceCategory from "@src/components/products/services/categories/CreateServiceCategory";
import EditServiceCategory from "@src/components/products/services/categories/EditServiceCategory";
import Spinner from "@src/components/ui/Spinner";
import { useTranslation } from "react-i18next";
import BooleanResponseDialog from "@src/components/ui/BooleanResponseDialog";

const ListServiceCategoriesPage = () => {
  const [categories, setCategories] = useState();
  const [error, setError] = useState(false);
  const [showModalCreateCategory, setShowModalCreateCategory] = useState(false);
  const [showModalEditCategory, setShowModalEditCategory] = useState(false);
  const [selectedCategoryToEdit, setSelectedCategoryToEdit] = useState();
  const [categoryIdToDelete, setCategoryIdToDelete] = useState();
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation(["pages/services/categories/list", "common/list", "common/common"]);
    const CATEGORIES_PER_PAGE = 5;

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const getCategoriesRequest = await getServiceCategories();
        setCategories(getCategoriesRequest.data);
      } catch (error) {
        createNotification(NotificationTypes.error, t("messages.genericRequestError"));
        setError(true);
      }
    };

    translationsReady && fetchCategories();
    // eslint-disable-next-line
  }, [translationsReady]);

  const enableEditionOfCategory = category => {
    setSelectedCategoryToEdit(category);
    setShowModalEditCategory(true);
  };

  const deleteIdCategory = async () => {
      setW18ApiResponse(b => !b);
      try {
        await delServiceCategory(categoryIdToDelete);
        createNotification(NotificationTypes.success, t("messages.deleteAction.ok"));
        setCategories(categories.filter(c => c.id !== categoryIdToDelete));
        setCategoryIdToDelete()
      } catch (error) {
        createNotification(NotificationTypes.error, t("messages.deleteAction.ko"));
      } finally {
        setW18ApiResponse(b => !b);
      }
  }
  

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <>
          {error && <Redirect to={CommonRoutesStrings.homePath} />}
          <div className="flex justify-center items-center mt-2 space-x-2">
            <HistoryBackButton size="md" />
            <Button
              color="green"
              onClick={() => setShowModalCreateCategory(true)}
            >{t("new", { ns: "common/common" })}</Button>
          </div>
          
          {!categories ? <Spinner /> : (
            <div className="grid grid-cols-1 place-items-center max-w-xl mx-auto">
            <PaginateData
                data={categories}
                emptyDataArrayMsg={t("messages.noCategoriesToList")}
                perPage={CATEGORIES_PER_PAGE}
              >
                <ListServiceCategoriesTable
                  editCategoryCallback={enableEditionOfCategory}
                  deleteCategoryCallback={categoryId => setCategoryIdToDelete(categoryId)}
                />
              </PaginateData>
            </div>
          )}
        
            <CreateServiceCategory
              open={showModalCreateCategory}
              backCallback={() => setShowModalCreateCategory(false)}
            />
            <EditServiceCategory
              categoryData={selectedCategoryToEdit}
              backCallback={() => setShowModalEditCategory(false)}
              open={showModalEditCategory}
            />
            <BooleanResponseDialog
            open={!!categoryIdToDelete}
            noCallback={() => setCategoryIdToDelete()}
            yesCallback={deleteIdCategory}
            showLoadingSpinner={w18ApiResponse}
          >
            {t("deleteModalMessage", { ns: "common/list" })}
          </BooleanResponseDialog>
        </>
      )}
    </>
  );
};

export default ListServiceCategoriesPage;