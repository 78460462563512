import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Spinner from "@src/components/ui/Spinner";
import BasicFormTemplate, { FooterType } from "@src/components/forms/BasicFormTemplate";
import FormikTextInput from "@src/components/forms/FormikTextInput";

const PeopleSegmentInitialValues = {
  name: "",
  quantity: 1,
};

const PeopleSegmentSchema = Yup.object().shape({
  name: Yup.string().required("components/products/peopleSegments/form:validations.noName"),
  quantity: Yup.number()
                .required("components/products/peopleSegments/form:validations.noQuantity")
                .typeError("common/form:validationErrors.numbers.notANumber")
                .min(1, "common/form:validationErrors.numbers.requiredGreaterThan0"),
});

const PeopleSegmentForm = ({ title, initialValues, handleSubmit, backCallback, showLoadingSpinner }) => {
  const { t, ready: translationsReady } = useTranslation("components/products/peopleSegments/form");

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <Formik
          initialValues={initialValues ? initialValues : PeopleSegmentInitialValues}
          validationSchema={PeopleSegmentSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <BasicFormTemplate
              className="mt-8"
              formTitle={title}
              showLoadingSpinner={showLoadingSpinner}
              footerType={FooterType.BACK_SUBMIT_BUTTONS}
              backCallback={backCallback}
            >
              <div className="my-6 px-4">
                <FormikTextInput
                  color="light-blue"
                  label={t("labels.name")}
                  name="name"
                />
              </div>
              <div className="my-6 px-4">
                <FormikTextInput
                  label={t("labels.quantity")}
                  name="quantity"
                />
              </div>
            </BasicFormTemplate>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default PeopleSegmentForm;