import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

const Dialog = ({ children, showDialog, cbCloseDialog, }) => {
  
  useEffect(() => {
    const closeOnEscapeKey = e => e.key === "Escape" ? cbCloseDialog() : null;
    document.body.addEventListener("keydown", closeOnEscapeKey);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, [cbCloseDialog]);

  if (!showDialog) return null;

  return createPortal(
    <div
      className="inset-0 transition-all duration-300 ease-in-out bg-black/60 flex flex-col items-center justify-center overflow-hidden px-5 pb-5 pt-10 fixed"
      onClick={cbCloseDialog}
    >
      <div
        className="max-w-[90%] max-h-[90%] bg-white/100 p-10 rounded-3xl font-['Montserrat']"
        onClick={e => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  , document.getElementById("react-portal-root"));
}
 
export default Dialog;