import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { createNotification, delayedRedirect, NotificationTypes } from "@src/helpers/notificationHelpers";
import { createProductPrice, isThirdPartyProduct } from "@src/helpers/productHelpers";
import { DefinedRoutesStrings } from "@src/helpers/stringHelpers";
import Spinner from "@src/components/ui/Spinner";
import SingleProductPriceForm from "@src/components/products/edit_product_data/add_edit_price/SingleProductPriceForm";

const AddPrice = ({ productType, date, timeslice, backCallback }) => {
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { id : productId } = useParams();
  const { t, ready: translationsReady } = useTranslation("components/products/edit/addPrice");

  const alreadySelectedPeopleSegments = timeslice.prices.map(p => p.type_client),
    quotaLeftToAssign = timeslice.prices.reduce((remainingQuota, price) => remainingQuota - price.quota * price.type_client.quantity, timeslice.quota);

  const submitPriceData = async (values, _) => {
    let processedPrices = null;
    if (values.quota === "") processedPrices = [ { price: Number(values.price), quota: null, type_client: values.type_client.id } ];
    else processedPrices = [ { price: Number(values.price), quota: Number(values.quota), type_client: values.type_client.id } ];

    setW18ApiResponse(b => !b);

    try {
      /*
        Right now, although it is only possible to insert one price for one timeslice at a time,
        it is mandatory to send EVERYTHING inside arrays for being future-proof.
      */
      await createProductPrice(productId, date.id, timeslice.id, processedPrices);
      createNotification(NotificationTypes.success, t("successfulPriceCreation"));
      const path = isThirdPartyProduct(productType) ? DefinedRoutesStrings.editThirdPartyProductPath : DefinedRoutesStrings.editOwnProductPath;
      delayedRedirect(path.replace(":id", productId));
    } catch (err) {
      createNotification(NotificationTypes.error, t("genericErrorPriceCreation"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="container max-w-sm mx-auto mt-10">
          <SingleProductPriceForm
            title={t("title")}
            alreadySelectedPeopleSegments={alreadySelectedPeopleSegments}
            quotaLeftToAssign={quotaLeftToAssign}
            backCallback={backCallback}
            submitCallback={submitPriceData}
            showLoadingSpinner={w18ApiResponse}
          />
        </div>
      )}
    </>
  );
};

export default AddPrice;