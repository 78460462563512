import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@src/components/ui/b2c/buttons/Button';
import OptionalSubmitButton from '@src/components/ui/b2c/buttons/OptionalSubmitButton';
import Dialog from '@src/components/ui/b2c/dialogs/Dialog';
import Spinner from '@src/components/ui/Spinner';
import { formatPrice } from '@src/helpers/localeHelpers';
import { HexStandardPinkStylingB2C } from '@src/helpers/stringHelpers';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const getInitialStateDialogGivenSupplements = supplements => supplements.map(supplement => ({
  id: supplement.id,
  name: supplement.name,
  price: supplement.price,
  maxPerProduct: supplement.maxPerProduct,
  selectedQuantity: 0,
}));

const ProductSupplementSelectorOrderingDialog = ({ open, handler, availableSupplements, skipSupplementSelectionsCallback, handleSupplementSelectionsCallback }) => {
  const [supplements, setSupplements] = useState(getInitialStateDialogGivenSupplements(availableSupplements));
  const { t, ready: translationsReady } = useTranslation(["components/orders/b2c/productSupplementSelectorOrderingDialog"]);

  const finalizeSupplementSelections = () => {
    const filteredSupplements = supplements.reduce((arrSupplements, currSupplement) => currSupplement.selectedQuantity === 0 ? arrSupplements : arrSupplements.concat({
      id: currSupplement.id,
      quantity: currSupplement.selectedQuantity,
      price: currSupplement.price,
    }), []);
    setSupplements(getInitialStateDialogGivenSupplements(availableSupplements));
    handleSupplementSelectionsCallback(filteredSupplements);
  };

  const skipSupplementSelection = () => {
    setSupplements(getInitialStateDialogGivenSupplements(availableSupplements));
    skipSupplementSelectionsCallback();
  };

  const incrementQuantityOfSupplement = (supplement) => {
    const newSupplements = supplements.map(s => s.id !== supplement.id ? s : ({ ...supplement, selectedQuantity: supplement.selectedQuantity + 1 }));
    setSupplements(newSupplements);
  };

  const decrementQuantityOfSupplement = (supplement) => {
    const newSupplements = supplements.map(s => s.id !== supplement.id ? s : ({ ...supplement, selectedQuantity: supplement.selectedQuantity - 1 }));
    setSupplements(newSupplements);
  };

  return (
    <Dialog showDialog={open} cbCloseDialog={handler}>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <div className="grid grid-cols-1">
          <div className="flex items-center place-self-center space-x-2">
            <FontAwesomeIcon icon={faPlus} color={HexStandardPinkStylingB2C} size="2x" />
            <p className="text-3xl font-bold">{t("title")}</p>
          </div>
          <p className="text-center my-4">{t("description")}</p>
          {supplements.map((supplement, index) => (
            <div key={supplement.id} className={`flex items-center justify-between w-full ${index === 0 ? "" : "mt-2"}`}>
              <div className="leading-8 mx-2 whitespace-nowrap montserrat-medium-shark-18px">
                {t("supplementName", { name: supplement.name, maxPerProduct: supplement.maxPerProduct })}
              </div>
              <div className="flex items-center ml-6">
                <div className="leading-8 mr-3 text-right whitespace-nowrap montserrat-semi-bold-shark-22px">
                  {formatPrice(supplement.price)}
                </div>
                <button
                  className={`disabled:opacity-60 flex justify-center items-center h-10 w-10 bg-[${HexStandardPinkStylingB2C}] rounded-full`}
                  onClick={() => decrementQuantityOfSupplement(supplement)}
                  disabled={supplement.selectedQuantity === 0}
                >
                  <FontAwesomeIcon icon={faMinus} color="white" />
                </button>
                <div className="leading-8 ml-3 whitespace-nowrap min-w-[16px] montserrat-semi-bold-red-ribbon-23px">
                  {supplement.selectedQuantity}
                </div>
                <button
                  className={`disabled:opacity-60 ml-3 flex justify-center items-center h-10 w-10 bg-[${HexStandardPinkStylingB2C}] rounded-full`}
                  onClick={() => incrementQuantityOfSupplement(supplement)}
                  disabled={supplement.selectedQuantity === supplement.maxPerProduct}
                >
                  <FontAwesomeIcon icon={faPlus} color="white" />
                </button>
              </div>
            </div>
          ))}
          <div className="flex justify-between space-x-4 items-center mt-6">
            <Button className="bg-black text-white font-bold" onClick={handler}>
              {t("buttons.cancel", { ns: "common/form" })}
            </Button>
            <OptionalSubmitButton onClick={skipSupplementSelection}>
              {t("buttons.omit")}
            </OptionalSubmitButton>
            <Button
              className="bg-[#e30746] text-white font-bold"
              onClick={finalizeSupplementSelections}
            >
              {t("buttons.confirm")}
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  );
};
 
export default ProductSupplementSelectorOrderingDialog;