import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from '@material-tailwind/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from '@src/components/ui/Spinner';

const ProfessionalValidationStatusAlert = ({ validationStatus }) => {
  const { t, ready: translationsReady } = useTranslation("components/users/professionalValidationStatus");

  return (
    <Alert
      color="amber"
      className="!sticky top-0 z-10 mb-4"
    >
      {!translationsReady ? <Spinner /> : (
        <div className="ml-12 flex space-x-4 items-center justify-center">
          <FontAwesomeIcon icon={faInfoCircle} color="gray" size="2x" />
          <div className="font-bold text-lg">{t(`${validationStatus}.short`)}</div>
        </div>
      )}
    </Alert>
  );
};
 
export default ProfessionalValidationStatusAlert;