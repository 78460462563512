import { stringDateFormat } from "@src/helpers/dateHelpers";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { createNotification, delayedRedirect, NotificationTypes } from "@src/helpers/notificationHelpers";
import { createProductTimeslice, isThirdPartyProduct } from "@src/helpers/productHelpers";
import { DefinedRoutesStrings, isString } from "@src/helpers/stringHelpers";
import Spinner from "@src/components/ui/Spinner";
import HoursConfiguration from "@src/components/products/product_wizard/HoursConfiguration";
import PricesConfiguration from "@src/components/products/product_wizard/PricesConfiguration";
import AddTimesliceSummary from "@src/components/products/edit_product_data/add_edit_timeslice/AddTimesliceSummary";
import { CardHeader, Progress, Typography } from "@material-tailwind/react";

const AddTimeslice = ({ productType, date, backCallback }) => {
  const { id : productId } = useParams();
  const FIRST_STEP = 1;
  const [currentStep, setCurrentStep] = useState(FIRST_STEP);
  const [timesliceData, setTimesliceData] = useState([{
    fromDate: date.date,
    toDate: date.date,
  }]);
  const { t, ready: translationsReady } = useTranslation(["components/products/edit/addTimeslice", "common/common"]);
  const getStepTitles = () => t("stepTitles");
  const getNumberSteps = () => Object.keys(getStepTitles()).length;
  const [submissionErrors, setSubmissionErrors] = useState([]);
  const [w18ApiResponse, setW18ApiResponse] = useState(false);

  const getTimesliceConfiguration = () => timesliceData.at(0);

  const handleSubmit = async () => {
    const timeslice = getTimesliceConfiguration();

    const processedTimeslice = {
      duration: timeslice.duration,
      quota: timeslice.quota,
      available: timeslice.quota,
      prices: timeslice.prices.map(p => ({
        ...p,
        type_client: p.type_client.id,
      })),
      times: timeslice.times.map(hour => ({ hour, languages: timeslice.languages })),
    };

    setW18ApiResponse(b => !b);

    try {
      await createProductTimeslice(productId, productType, date.id, processedTimeslice);
      createNotification(NotificationTypes.success, t("labels.successfulCreateTimeslice"));
      const path = isThirdPartyProduct(productType) ? DefinedRoutesStrings.editThirdPartyProductPath : DefinedRoutesStrings.editOwnProductPath;
      delayedRedirect(path.replace(":id", productId));
    } catch (err) {
      if (err.response && err.response.data && err.response.data.errors && Array.isArray(err.response.data.errors.configurations))
        setSubmissionErrors(err.response.data.errors.configurations);
      else if (err.response && err.response.data && err.response.data.errors && isString(err.response.data.errors.configurations))
        setSubmissionErrors([ err.response.data.errors.configurations ]);

      createNotification(NotificationTypes.error, t("labels.genericErrorCreateTimeslice"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  const handleStepDataReceived = data => {
    console.log(data)
    switch (currentStep) {
      case 1:
        setTimesliceData(data);
        break;
      case 2:
        setTimesliceData(data);
        break;
      default:
        return;
    }
  };

  const handleStepChange = targetStep => {
    setCurrentStep(targetStep);
    setSubmissionErrors([]);
  };

  const handlePreviousStep = currentStep === FIRST_STEP ? backCallback : () => handleStepChange(currentStep - 1);

  const handleNextStep = data => {
    handleStepDataReceived(data);    
    handleStepChange(currentStep + 1);
  };

  const getStepComponent = () => {
    console.log(timesliceData)
    switch (currentStep) {
      case 1:
        return <HoursConfiguration
                  currentStep={currentStep}
                  numberSteps={getNumberSteps()}
                  initialValues={timesliceData}
                  editingProductConfigurations={true}
                  callbackNextClicked={handleNextStep}
                  callbackBackClicked={handlePreviousStep}
                />;
      case 2:
        return <PricesConfiguration
                  currentStep={currentStep}
                  numberSteps={getNumberSteps()}
                  initialValues={timesliceData}
                  callbackNextClicked={handleNextStep}
                  callbackBackClicked={handlePreviousStep}
                />;
      case 3:
        return <AddTimesliceSummary
                  currentStep={currentStep}
                  numberSteps={getNumberSteps()}
                  date={date}
                  timeslice={getTimesliceConfiguration()}
                  callbackNextClicked={handleSubmit}
                  callbackBackClicked={handlePreviousStep}
                  submissionErrors={submissionErrors}
                  showLoadingSpinner={w18ApiResponse}
                />;
      default:
        return <div>Not found</div>
    }
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="container max-w-2xl mx-auto border-t mt-2">
          <div className="mt-10 mb-4 flex place-content-center">
            <CardHeader
              color="deep-orange"
              variant="gradient"
              className="px-4 py-3"
            >
              <Typography
                variant="h5"
                color="white"
              >{t("labels.title", { date: stringDateFormat(date.date) })}</Typography>
            </CardHeader>
          </div>
          {currentStep !== FIRST_STEP &&
            <Progress
              color="green"
              value={Number(((currentStep - 1) / Object.keys(t("stepTitles")).length * 100).toFixed(2))}
              label={t("progressBarCompletionLabel", { ns: "common/common" })}
              className="mb-2"
            />
          }
          {getStepComponent()}
        </div>
      )}
    </>
  );
};

export default AddTimeslice;