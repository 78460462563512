import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from '@src/components/ui/Spinner';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormikTextInput from '@src/components/forms/FormikTextInput';

const FormikEmailInput = ({ name, color, label, icon }) => {
  const { t, ready: translationsReady } = useTranslation("common/form");

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <FormikTextInput
          name={name ?? "email"}
          type="email"
          color={color ?? "light-blue"}
          label={label ?? t("labels.email")}
          icon={icon ?? <FontAwesomeIcon icon={faEnvelope} color="lightGray" />}
        />
      )}
    </>
  );
}
 
export default FormikEmailInput;