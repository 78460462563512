import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import BasicFormTemplate, { FooterType } from "@src/components/forms/BasicFormTemplate";
import { getUserRequest, isServicesProvider } from "@src/helpers/userHelpers";
import ServicesProviderFormSearch from "@src/components/products/services/create/ServicesProviderFormSearch";
import { getServiceCategory } from "@src/helpers/serviceCategoryHelpers";
import ServiceCategoryFormSearch from "@src/components/products/ServiceCategoryFormSearch";
import Spinner from "@src/components/ui/Spinner";
import { useTranslation } from "react-i18next";
import FormikTextInput from "@src/components/forms/FormikTextInput";

const BasicInfoSchema = Yup.object().shape({
  name: Yup.string().required("common/form:validationErrors.empty.name"),
  servicesProviderId: Yup.number().required().typeError("components/services/create/basicInfoForm:validations.noServicesProviderSelected"),
  serviceCategoryId: Yup.number().required().typeError("components/services/create/basicInfoForm:validations.noServiceCategorySelected"),
});

const BasicInfoForm = ({ currentStep, initialValues, editingServiceData, showLoadingSpinner, readOnly, backCallback, nextCallback }) => {
  const [servicesProvider, setServicesProvider] = useState();
  const [serviceCategory, setServiceCategory] = useState();
  const [loadedServicesProvider, setLoadedServicesProvider] = useState(false);
  const [loadedServiceCategory, setLoadedServiceCategory] = useState(false);
  const { t, ready: translationsReady } = useTranslation(["components/services/create/basicInfoForm", "common/form", "pages/services/create"]);

  useEffect(() => {
    const fetchServicesProviderData = async () => {
      if (!isServicesProvider() && initialValues.servicesProviderId != null) {
        try {
          const getUserResponse = await getUserRequest(initialValues.servicesProviderId);
          setServicesProvider(getUserResponse.data);
        } catch (error) {
          console.log(error);
        }
      }
      setLoadedServicesProvider(true);
    };
    const fetchServiceCategoryData = async () => {
      if (initialValues.serviceCategoryId != null) {
        try {
          const getServiceCategoryResponse = await getServiceCategory(initialValues.serviceCategoryId);
          setServiceCategory(getServiceCategoryResponse.data);
        } catch (error) {
          console.log(error);
        }
      }
      setLoadedServiceCategory(true);
    };

    fetchServicesProviderData();
    fetchServiceCategoryData();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (values, _) => {
    nextCallback(values);
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
          <Formik
            initialValues={initialValues}
            validationSchema={BasicInfoSchema}
            onSubmit={handleSubmit}
          >
            <Form>
        <BasicFormTemplate
            formTitle={t("stepTitles.basicInfo", { ns: "pages/services/create" })}
            footerType={FooterType.BACK_NEXT_BUTTONS}
            cbBackButton={backCallback}
            showLoadingSpinner={editingServiceData && showLoadingSpinner}
            headerColor="teal"
            className="mt-10 max-w-3xl mx-auto"
            disableSubmitButton={readOnly}
          >
        <div>
              <FormikTextInput
                label={t("labels.name", { ns: "common/form" })}
                name="name"
                disabled={readOnly}
              />
              {!isServicesProvider() &&
                <>
                  {!loadedServicesProvider ? <Spinner /> : (
                    <ServicesProviderFormSearch
                      label={t("labels.professional")}
                      placeholder={t("placeholders.selectOne", { ns: "common/form" })}
                      name="servicesProviderId"
                      defaultProfessional={servicesProvider}
                      loadOptionsErrorMsg={t("messages.errorRetrievingServicesProviders")}
                      noOptionsMessage={t("placeholders.noOptionsInSelect", { ns: "common/form" })}
                      disabled={readOnly}
                    />
                  )}
                </>
              }
              {!loadedServiceCategory ? <Spinner /> : (
                <ServiceCategoryFormSearch
                  label={t("labels.serviceCategory")}
                  placeholder={t("placeholders.selectOne", { ns: "common/form" })}
                  name="serviceCategoryId"
                  defaultValue={serviceCategory}
                  loadOptionsErrorMsg={t("messages.errorRetrievingServiceCategories")}
                  disabled={readOnly}
                />
              )}
        </div>
      </BasicFormTemplate>
            </Form>
          </Formik>
      )}
    </>
  );
};

export default BasicInfoForm;