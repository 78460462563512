import axios from "@src/helpers/axiosInstance";
import {getJWT} from "@src/helpers/localStorageHelpers";
import {APIEndpointsStrings} from "@src/helpers/stringHelpers";

class CreditService {
  static async findAll(date,idProvider) {
    const findAllResponse = await axios.get(APIEndpointsStrings.creditsPath+`${
      date ||idProvider  ? `?` : ""
    }${
      idProvider ? `q[request_by_id_eq]=${idProvider}` : ""
    }${
      date ? `&q[created_at_gteq]=${date.fromDate}&q[created_at_lteq]=${date.toDate}` : ""
    }`, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });

    return findAllResponse.data.map(credit => this.formatReceivedCredit(credit));
  }
  static async findByUser(userId) {

    const findByUserResponse = await axios.get(APIEndpointsStrings.creditsPathByUserId.replace(APIEndpointsStrings.idLocators.id, userId), {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });

    return findByUserResponse.data.map(credit => this.formatReceivedCredit(credit));
  }
  static async indexCreditsFilters(requestedFilter, autorizedFilter, stateFilter){
    let RequestPath = APIEndpointsStrings.creditsPath + `?`;
    if(requestedFilter){
      RequestPath += `q[request_by_id_eq]=${requestedFilter}&`;
    }
    if(autorizedFilter){
      RequestPath += `q[authorized_by_id_eq]=${autorizedFilter}&`;
    }
    if(stateFilter){
      switch(stateFilter){
        case "Denied":
          RequestPath += `q[denied_at_not_null]=1&`;
          break;
        case "Pending": 
          RequestPath += `q[denied_at_null]=1&q[validated_at_null]=1&`;
          break;
        case "Validated":
          RequestPath += `q[validated_at_not_null]=1&`;
          break;
        default:
          break;
      }
    }

    const findbyFilter =  await axios.get(RequestPath);
    return findbyFilter.data.map(credit => this.formatReceivedCredit(credit));
  }
  static create(credit) {
    return axios.post(APIEndpointsStrings.creditsPath, credit, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });
  }

  static update(creditId, value) {
    return axios.put(APIEndpointsStrings.creditPath.replace(APIEndpointsStrings.idLocators.id, creditId), {
      credit: value
    }, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });
  }

  static destroy(creditId) {
    return axios.delete(APIEndpointsStrings.creditPath.replace(APIEndpointsStrings.idLocators.id, creditId), {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });
  }

  static authorize(creditId, validatedAt) {
    return axios.put(APIEndpointsStrings.authorizeCreditPath.replace(APIEndpointsStrings.idLocators.id, creditId), {
      validated_at: validatedAt
    }, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });
  }
  static denied(creditId, deniedAt) {
    return axios.put(APIEndpointsStrings.authorizeCreditPath.replace(APIEndpointsStrings.idLocators.id, creditId), {
      denied_at: deniedAt
    }, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });
  }
  static formatReceivedCredit(credit) {
    return {
      ...credit,
      createdAt: credit.request_by.created_at ? new Date(credit.request_by.created_at) : null,
      deniedAt: credit.denied_at ? new Date(credit.denied_at) : null,
      validatedAt: credit.validated_at ? new Date(credit.validated_at) : null,
      requestBy: credit.request_by.email,
      authorizedBy: credit.authorized_by ? credit.authorized_by.email : null
    };
  }
}

export default CreditService;