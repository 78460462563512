import React from "react";

import "@src/assets/styles/b2c/components/landing/andalusia_tours_map/globals.css";
import "@src/assets/styles/b2c/components/landing/andalusia_tours_map/styleguide.css";
import { B2CRoutesStrings } from "@src/helpers/stringHelpers";
import { Link } from "react-router-dom";

const MapMarkerPoint = ({ className, zip_code }) => (
  <div
    className={`${
      className ?? ""
    } cursor-pointer flex bg-white items-center justify-center h-[23px] w-[23px] p-1 rounded-[11.48px] shadow-sm transition-all hover:scale-[1.3]`}
  >
    <div
      className={`flex items-start h-[19px] w-[19px] bg-white p-[2.9px] rounded-[9.45px] border-solid border-[#e30746] border-2`}
    >
      <Link
        to={`${B2CRoutesStrings.listProductsSearchPath}?locations=${zip_code}`}
      >
        <div className="rounded h-2 w-2 bg-[#e30746]"></div>
      </Link>
    </div>
  </div>
);

const MapMarkerTitle = ({ className, children }) => (
  <div
    className={`${className ?? ""} leading-7 mt-1 montserrat-bold-delta-21px`}
  >
    {children}
  </div>
);

const MapMarker = ({ pointStyling, titleStyling, children, zip_code }) => (
  <>
    <MapMarkerPoint className={pointStyling} zip_code={zip_code} />
    <MapMarkerTitle className={titleStyling}>{children}</MapMarkerTitle>
  </>
);

const AndalusiaToursMap = ({ className }) => (
  <div className={`container-center-horizontal ${className ?? ""}`}>
    <div className="flex items-start">
      <div className="flex w-auto items-center px-14 py-16 bg-contain bg-[url(@src/assets/images/components/b2c/landing/andalusia_tours_map/svg3268@1x.svg)] h-[515px] montserrat-bold-delta-21px">
        <div className="flex items-start self-end h-[349px]">
          <div className="flex flex-col items-end self-end w-full">
            <div className="flex items-start self-start h-20">
              <div className="flex flex-col items-center w-full">
                <MapMarker zip_code="21">Huelva</MapMarker>
              </div>
              <MapMarkerPoint className="ml-[140px] self-end"   zip_code="41"/>
            </div>
            <MapMarkerTitle >Sevilla</MapMarkerTitle>
            <MapMarker
              pointStyling="mr-[47.53px] mt-[130px]"
              titleStyling="mr-[28.52px]"
              zip_code="11"
            >
              Cádiz
            </MapMarker>
          </div>
          <div className="flex flex-col items-center ml-16 w-full h-[268px]">
            <MapMarker
              pointStyling="mr-[6.67px]"
              titleStyling="mr-[6.67px]"
              zip_code="14"
            >
              Córdoba
            </MapMarker>
            <MapMarker
              pointStyling="ml-[19.67px] mt-[158px]"
              titleStyling="self-end"
              zip_code="29"
            >
              Málaga
            </MapMarker>
          </div>
        </div>
        <div className="flex flex-col items-center mb-16 ml-24 w-full h-[212px]">
          <MapMarker zip_code="23">Jaén</MapMarker>
          <MapMarker pointStyling="mt-[102px]" zip_code="18">
            Granada
          </MapMarker>
        </div>
        <div className="flex flex-col items-center mt-24 ml-24 w-full h-[55px]">
          <MapMarker zip_code="04">Almería</MapMarker>
        </div>
      </div>
    </div>
  </div>
);

export default AndalusiaToursMap;
