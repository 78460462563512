import {useFormikContext} from "formik";
import React from "react";
import {createNotification, NotificationTypes} from "@src/helpers/notificationHelpers";
import {ProductTypesStrings, UserTypesStrings} from "@src/helpers/stringHelpers";
import {getUsersByTypeAndContainingEmail} from "@src/helpers/userHelpers";
import FormSearchInput from "@src/components/forms/FormSearchInput";

const FormProfessionalSearch = ({
                                  productType,
                                  label,
                                  onChange,
                                  secondLabel,
                                  placeholder,
                                  name,
                                  loadOptionsErrorMsg,
                                  defaultProfessional,
                                  noOptionsMessage,
                                  ...props
                                }) => {
  const {setFieldValue} = useFormikContext();

  const loadOptions = inputValue => {
    switch (productType) {
      case ProductTypesStrings.ownProduct:
        return getUsersByTypeAndContainingEmail(inputValue, UserTypesStrings.servicesProvider)
          .then(res => res.data)
          .catch(err => createNotification(NotificationTypes.error, loadOptionsErrorMsg));
      case ProductTypesStrings.thirdPartyProduct:
        return getUsersByTypeAndContainingEmail(inputValue, UserTypesStrings.provider)
          .then(res => res.data)
          .catch(err => createNotification(NotificationTypes.error, loadOptionsErrorMsg));
      default:
        throw new Error(`create-product: product type ${productType} not allowed`);
    }
  }

  const getOptionValue = option => option.id;
  const getOptionLabel = option => option.email;

  const handleChange = option => {
    if(onChange && option){
      onChange(option.id);
    } else {
      setFieldValue(name, option ? option.id : null);
    }
  };

  return (
    <FormSearchInput
      label={label}
      secondLabel={secondLabel}
      placeholder={placeholder}
      name={name}
      noOptionsMessage={noOptionsMessage}
      defaultValue={defaultProfessional}
      isClearable={true}
      cbLoadOptions={loadOptions}
      cbGetOptionLabel={getOptionLabel}
      cbGetOptionValue={getOptionValue}
      cbHandleChange={handleChange}
      {...props}
    />
  );
};

export default FormProfessionalSearch;