import axios from "@src/helpers/axiosInstance";
import { APIEndpointsStrings } from "@src/helpers/stringHelpers";

class ImageBankService {
  static index = async (requestPath) => {
    const res = await axios.get(requestPath ?? APIEndpointsStrings.imagesBankPath);
    
    return res.data.map((image) => this.formatReceivedImage(image));
  };

  static indexFilters = async (descriptionFilter,productFilter,categoryFilter) => {
    let requestPath = APIEndpointsStrings.imagesBankPath + `/?`; 
    if(productFilter){
      productFilter.images.map((image) => {
        requestPath += `q[id_eq]=${image.id}&`;
        return null;
      });
    }
    if (descriptionFilter){
      requestPath += `q[description_cont]=${descriptionFilter}&`;
    }
    if(categoryFilter){
      categoryFilter.map((category) =>  {
        requestPath += `q[categories_id_in]=${category.id}`;
        return null;
      });
    }
    return this.index(requestPath);
  }

  static indexImageWithDescription = async(description) => {
    let requestPath = APIEndpointsStrings.imagesBankPath;
    if (description){
      requestPath += `?q[description_cont_all]=${description}`;
    }
    return this.index(requestPath);
  }

  static indexImagesWithIds = async (ids) => {
    const requestPath =
      APIEndpointsStrings.imagesBankPath + ids.map((id, index) => `${index === 0 ? "?" : "&"}q[id_in][]=${id}`);
    return this.index(requestPath);
  };

  static indexImagesWithoutIds = async (ids) => {
    const requestPath =
      APIEndpointsStrings.imagesBankPath + ids.map((id, index) => `${index === 0 ? "?" : "&"}q[id_not_in][]=${id}`);
    return this.index(requestPath);
  };

  static create(image, values) {
    const formData = new FormData();
    formData.append("image[picture]", image);
    formData.append("image[description]", values.description);
    formData.append("image[fixed]", values.fixed);
    if(values.categoryIds?.length > 0) {
      values.categoryIds.map((cat_id) => {
        formData.append('image[category_ids][]', cat_id);
        return null;
      });
    }else{
      formData.append('image[category_ids][]', '[]');
    }
    return axios.post(APIEndpointsStrings.imagesBankPath, formData);
  }

  static update(imageId, values, file) {
    const requestPath = APIEndpointsStrings.imageBankPath.replace(APIEndpointsStrings.idLocators.id, imageId),
    formData = new FormData();
    formData.append("image[description]", values.description);
    formData.append("image[fixed]", values.fixed);
    if(values.categoryIds?.length > 0) {
      values.categoryIds.map((cat_id) => {
        formData.append('image[category_ids][]', cat_id);
        return null;
      });
    }else{
      formData.append('image[category_ids][]', '[]');
    }
    if(file !== undefined) {
      formData.append("image[picture]", file);
    }
    return axios.put(requestPath, formData);
  }

  static delete = async (imageId) =>
    axios.delete(APIEndpointsStrings.imageBankPath.replace(APIEndpointsStrings.idLocators.id, imageId));

  static formatReceivedImage = (image) => ({
    id: image.id,
    fixed: image.fixed,
    description: image.description,
    url: image.picture_url,
    categories: image.categories,
    createdAt: image.created_at ? new Date(image.created_at) : undefined,
    updatedAt: image.updated_at ? new Date(image.updated_at) : undefined,
  });
}

export default ImageBankService;
