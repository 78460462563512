import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardBody, CardHeader, Typography } from "@material-tailwind/react";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { getOptionsForUserTypeSelect } from "@src/pages/CreateUserPage";
import FormikSelect from "@src/components/forms/FormikSelect";
import FormikTextInput from "@src/components/forms/FormikTextInput";
import Spinner from "@src/components/ui/Spinner";

const FormInitialValues = {
  email: "",
  userType: "",
};

const FilterUsersForm = ({ className, color, cbSetEmail, cbSetUserType }) => {
  const { t, ready: translationsReady } = useTranslation(["common/filterForm", "common/form", "common/userTypes", "common/common"]);
  const DEFAULT_COLOR = "light-blue";

  const getOptionsForSelect = () =>
    [ {
      value: "",
      label: t("any", { ns: "common/common" }),
    } ].concat(getOptionsForUserTypeSelect());

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <Card className={className}>
          <CardHeader
            color={color ?? DEFAULT_COLOR}
            variant="gradient"
            className="p-5"
          >
            <Typography
              variant="h5"
              color="white"
            >{t("description")}</Typography>
          </CardHeader>
          <CardBody>
            <Formik
              initialValues={FormInitialValues}
            >
              {({ handleChange }) =>
                <Form className="grid grid-cols-2 space-x-2">
                  <FormikTextInput
                    name="email"
                    type="email"
                    color={color ?? DEFAULT_COLOR}
                    label={t("labels.email", { ns: "common/form" })}
                    onChange={e => {
                      handleChange(e);
                      cbSetEmail(e.target.value);
                    }}
                    icon={<FontAwesomeIcon icon={faEnvelope} color="lightGray" />}
                  />
                  <FormikSelect
                    label={t("labels.userType", { ns: "common/form" })}
                    name="userType"
                    options={getOptionsForSelect()}
                    onChange={optionValue => cbSetUserType(optionValue)}
                  />
                </Form>
              }
            </Formik>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default FilterUsersForm;