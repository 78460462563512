import React, { useEffect, useState } from "react";
import { Dialog } from "@material-tailwind/react";
import ListImageBankTable from "@src/components/image_bank/ListImageBankTable";
import ShowImageDialog from "@src/components/image_bank/ShowImageDialog";
import HistoryBackButton from "@src/components/ui/buttons/HistoryBackButton";
import PaginateData from "@src/components/ui/PaginateData";
import Spinner from "@src/components/ui/Spinner";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import ImageBankService from "@src/services/imageBankService";
import { useTranslation } from "react-i18next";

const SelectProductImageDialog = ({ open, selectedImageIds, handler, selectImageCallback }) => {
  const [images, setImages] = useState();
  const [imageToShow, setImageToShow] = useState();
  const { t, ready: translationsReady } = useTranslation("pages/imageBank/list");
  const IMAGES_PER_PAGE = 5;

  const fetchImages = async () => {
    try {
      setImages(await ImageBankService.indexImagesWithoutIds(selectedImageIds));
    } catch (_) {
      createNotification(NotificationTypes.error, t("messages.genericRequestError"));
    }
  };

  useEffect(() => {
    if (open && translationsReady) {
      fetchImages();
    }
    // eslint-disable-next-line
  }, [translationsReady, open]);

  const closeShowImageDialog = () => setImageToShow();

  const handleSelectedImage = (image) => {
    selectImageCallback(image);
    setImageToShow();
  };

  return (
    <Dialog open={open}>
      {!(translationsReady && images) ? (
        <Spinner />
      ) : (
        <div className="grid grid-cols-1 place-items-center max-w-xl mx-auto">
          <PaginateData data={images} emptyDataArrayMsg={t("messages.noImagesToList")} perPage={IMAGES_PER_PAGE}>
            <ListImageBankTable showImageCallback={setImageToShow} />
          </PaginateData>
        </div>
      )}
      <div className="flex justify-center my-4">
        <HistoryBackButton onClick={handler} />
      </div>
      <ShowImageDialog
        image={imageToShow}
        backCallback={closeShowImageDialog}
        selectImageCallback={handleSelectedImage}
      />
    </Dialog>
  );
};

export default SelectProductImageDialog;
