import React, { useEffect, useState } from "react";
import ListPriceTemplatesTable from "@src/components/products/price_templates/ListPriceTemplatesTable";
import Button from "@src/components/ui/buttons/Button";
import PaginateData from "@src/components/ui/PaginateData";
import { createNotification, delayedRedirect, NotificationTypes } from "@src/helpers/notificationHelpers";
import { deletePriceTemplate, getPriceTemplates } from "@src/helpers/priceTemplateHelpers";
import { CommonRoutesStrings } from "@src/helpers/stringHelpers";
import ListPeopleSegmentsTable from "@src/components/products/people_segments/ListPeopleSegmentsTable";
import CreatePriceTemplateDialog from "@src/components/products/price_templates/CreatePriceTemplateDialog";
import EditPriceTemplateDialog from "@src/components/products/price_templates/EditPriceTemplateDialog";
import Spinner from "@src/components/ui/Spinner";
import { useTranslation } from "react-i18next";
import HistoryBackButton from "@src/components/ui/buttons/HistoryBackButton";
import { Dialog } from "@material-tailwind/react";
import BooleanResponseDialog from "@src/components/ui/BooleanResponseDialog";

const ListPriceTemplatesPage = () => {
  const [priceTemplates, setPriceTemplates] = useState();
  const [modalCreatePriceTemplateEnabled, setModalCreatePriceTemplateEnabled] = useState(false);
  const [priceTemplateToShow, setPriceTemplateToShow] = useState();
  const [priceTemplateToEdit, setPriceTemplateToEdit] = useState();
  const [priceTemplateToDelete, setPriceTemplateToDelete] = useState();
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const PRICE_TEMPLATES_PER_PAGE = 5;
  const { t, ready: translationsReady } = useTranslation([
    "pages/products/priceTemplates/list",
    "pages/products/list",
    "common/navigation",
    "common/common"
  ]);

  const fetchPriceTemplates = async () => {
    try {
      const getPriceTemplatesResponse = await getPriceTemplates();
      setPriceTemplates(getPriceTemplatesResponse.data);
    } catch (_) {
      createNotification(NotificationTypes.error, t("messages.genericRequestError"));
      delayedRedirect(CommonRoutesStrings.homePath);
    }
  };

  useEffect(() => {
    translationsReady && fetchPriceTemplates();
    // eslint-disable-next-line
  }, [translationsReady]);

  const removePriceTemplate = async () => {
    setW18ApiResponse(b => !b);

    try {
      await deletePriceTemplate(priceTemplateToDelete.id);
      createNotification(NotificationTypes.success, t("messages.deleteAction.success"));
      setPriceTemplates(priceTemplates.filter(pt => pt.id !== priceTemplateToDelete.id));
      setPriceTemplateToDelete();
    } catch (error) {
      createNotification(NotificationTypes.error, t("messages.deleteAction.error"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  const finishPriceTemplateCreation = async () => {
    await fetchPriceTemplates();
    setModalCreatePriceTemplateEnabled(false);
  };

  const finishPriceTemplateModification = async () => {
    await fetchPriceTemplates();
    setPriceTemplateToEdit();
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="container mx-auto max-w-xl">
          <div className="flex justify-center items-center my-2 space-x-2">
            <HistoryBackButton size="md" />
            <Button
              color="green"
              onClick={() => setModalCreatePriceTemplateEnabled(true)}
            >{t("new", { ns: "common/common" })}</Button>
          </div>
          <div className="grid grid-cols-1 place-items-center">
            {!priceTemplates ? <Spinner /> : (        
              <PaginateData
                data={priceTemplates}
                emptyDataArrayMsg={t("messages.noPriceTemplates")}
                perPage={PRICE_TEMPLATES_PER_PAGE}
              >
                <ListPriceTemplatesTable
                  showPeopleSegmentsOfPriceTemplateCallback={priceTemplate => setPriceTemplateToShow(priceTemplate)}
                  editPriceTemplateCallback={priceTemplate => setPriceTemplateToEdit(priceTemplate)}
                  deletePriceTemplateCallback={priceTemplate => setPriceTemplateToDelete(priceTemplate)}
                />
              </PaginateData>
            )}
          </div>
          <Dialog
            open={!!priceTemplateToShow}
            handler={() => setPriceTemplateToShow()}
          >
            <>
              {priceTemplateToShow &&
                <div className="p-2">
                  <PaginateData
                    data={priceTemplateToShow.people_segments}
                  >
                    <ListPeopleSegmentsTable />
                  </PaginateData>
                  <div className="flex justify-center items-center">
                    <Button
                      className="mt-2"
                      onClick={() => setPriceTemplateToShow()}
                    >{t("close", { ns: "common/navigation" })}</Button>
                  </div>          
                </div>
              }
            </>
          </Dialog>
          <CreatePriceTemplateDialog
            open={modalCreatePriceTemplateEnabled}
            handler={() => setModalCreatePriceTemplateEnabled(false)}
            handlePriceTemplateCreation={finishPriceTemplateCreation}
          />
          <EditPriceTemplateDialog
            priceTemplate={priceTemplateToEdit}
            handler={() => setPriceTemplateToEdit()}
            handlePriceTemplateModification={finishPriceTemplateModification}
          />
          <BooleanResponseDialog
            open={!!priceTemplateToDelete}
            yesCallback={removePriceTemplate}
            noCallback={() => setPriceTemplateToDelete()}
            showLoadingSpinner={w18ApiResponse}
          >{t("messages.deleteAction.confirmDialog")}</BooleanResponseDialog>
        </div>
      )}
    </>
  );
};

export default ListPriceTemplatesPage;