import axios from "@src/helpers/axiosInstance";
import { getJWT } from "@src/helpers/localStorageHelpers";
import { APIEndpointsStrings } from "@src/helpers/stringHelpers";

class NewsletterService {
  static async findAll() {
    const findAllResponse = await axios.get(APIEndpointsStrings.newslettersPath, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });

    return findAllResponse.data.map(newsletter => this.formatReceivedNewsletter(newsletter));
  }

  static async indexNewsletterFilter(number, title, body){
    let requestPath = APIEndpointsStrings.newslettersPath + `/?`;
    if(number){
      requestPath += `q[number_eq]=${number}&`;
    }
    if(title){
      requestPath += `q[title_cont]=${title}&`;
    }
    if(body){
      requestPath += `q[body_cont]=${body}&`;
    }
    const findFilterResponse = await axios.get(requestPath);

    return findFilterResponse.data.map(newsletter => this.formatReceivedNewsletter(newsletter))
  }

  static create(newsletter) {
    return axios.post(APIEndpointsStrings.newslettersPath, newsletter, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });
  }

  static update(newsletterId, newsletterData) {
    return axios.patch(APIEndpointsStrings.newsletterPath.replace(APIEndpointsStrings.idLocators.id, newsletterId), {
      newsletter: {
        number: newsletterData.number,
        title: newsletterData.title,
        body: newsletterData.body,
        banner_id: newsletterData.banner_id ? newsletterData.banner_id : null,
      }
    }, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });
  }

  static destroy(newsletterId) {
    return axios.delete(APIEndpointsStrings.newsletterPath.replace(APIEndpointsStrings.idLocators.id, newsletterId), {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });
  }

  static formatReceivedNewsletter(newsletter) {
    return {
      ...newsletter,
      sentAt: newsletter.sent_at ? new Date(newsletter.sent_at) : null,
      createdAt: newsletter.created_at ? new Date(newsletter.created_at) : null,
    };
  }
}

export default NewsletterService;