import axios from "@src/helpers/axiosInstance";
import { stringDateFormat } from "@src/helpers/dateHelpers";
import { getJWT } from "@src/helpers/localStorageHelpers";
import { APIEndpointsStrings } from "@src/helpers/stringHelpers";
import ServiceResourceService from "@src/services/services/serviceResourceService";

class ServiceDateService {
  static create = (serviceId, dateData) => {
    const formattedDateData = this.formatDateToSend(dateData);
    const endpoint = APIEndpointsStrings.serviceDatesPath
      .replace(APIEndpointsStrings.idLocators.id, serviceId);

    return axios.post(endpoint, {
      configuration: formattedDateData
    }, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });
  };

  static destroy = (serviceId, dateId) => {
    const endpoint = APIEndpointsStrings.serviceDatePath
      .replace(APIEndpointsStrings.idLocators.serviceId, serviceId)
      .replace(APIEndpointsStrings.idLocators.id, dateId);

    return axios.delete(endpoint, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });
  };

  static formatDateToSend = date => ({
    date: stringDateFormat(date.dates[0]),
    resources: date.resources.map(r => ServiceResourceService.formatResourceToSend(r)),
  });
};

export default ServiceDateService;