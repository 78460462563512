import { Dialog } from '@material-tailwind/react';
import Spinner from '@src/components/ui/Spinner';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CompanyDataForm from '@src/components/users/company/CompanyDataForm';
import ProfessionalService from '@src/services/users/professionalService';
import { createNotification, delayedRedirect, NotificationTypes } from '@src/helpers/notificationHelpers';
import { CommonRoutesStrings } from '@src/helpers/stringHelpers';

const AddCompanyDataDialog = ({ open, handler }) => {
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation("components/users/companyDataForm");

  const submitCompanyData = async (companyData) => {
    setW18ApiResponse(b => !b);

    try {
      await ProfessionalService.submitCompanyData(companyData);
      createNotification(NotificationTypes.success, t("addActionMessages.ok"));
      delayedRedirect(CommonRoutesStrings.homePath);
    } catch (err) {
      createNotification(NotificationTypes.error, t("addActionMessages.ko"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  return (
    <Dialog
      open={open}
      handler={handler}
    >
      {!translationsReady ? <Spinner /> : (
        <CompanyDataForm
          title={t("titles.add")}
          showLoadingSpinner={w18ApiResponse}
          backCallback={handler}
          submitCallback={submitCompanyData}
        />
      )}
    </Dialog>
  );
};
 
export default AddCompanyDataDialog;