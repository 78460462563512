import { Dialog } from "@material-tailwind/react";
import React from "react";
import CloseButton from "@src/components/ui/buttons/CloseButton";
import Button from "@src/components/ui/buttons/Button";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";

const ShowImageDialog = ({ image, backCallback, selectImageCallback }) => {
  const { t, ready: translationsReady } = useTranslation("common/list");

  return !translationsReady ? (
    <Spinner />
  ) : (
    <Dialog open={!!image} handler={backCallback}>
      <div className="grid grid-cols-1 m-2 space-y-2">
        <div className="p-2 border rounded-md bg-gray-50 overflow-auto max-h-[70vh]">
          {image && (
            <>
              {/* eslint-disable-next-line */}
              <img src={image.url} />
            </>
          )}
        </div>
        <div
          className={`flex ${
            selectImageCallback ? "justify-between mx-4" : "justify-center"
          }`}
        >
          <CloseButton onClick={backCallback} />
          {selectImageCallback && (
            <Button onClick={() => selectImageCallback(image)}>
              {t("select")}
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default ShowImageDialog;
