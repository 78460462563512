import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Spinner from "@src/components/ui/Spinner";
import BasicFormTemplate, {
  FooterType,
} from "@src/components/forms/BasicFormTemplate";
import ErrorDisplayBox from "@src/components/ui/ErrorDisplayBox";
import FormLocationSearch from "./FormLocationSearch";
import ServicesProviderFormSearch from "../products/services/create/ServicesProviderFormSearch";
import { isServicesProvider } from "@src/helpers/userHelpers";


let locationSchema;
if (!isServicesProvider()) {
  locationSchema = Yup.object().shape({
    locationIds: Yup.array()
      .of(Yup.number())
      .min(1, "components/locations/form:noNamenoZipError"),
    servicesProviderId: Yup.number().required(
      "components/locations/form:noProdessionalServiError"
    ),
  });
} else {
  locationSchema = Yup.object().shape({
    locationIds: Yup.array()
      .of(Yup.number())
      .min(1, "components/locations/form:noNamenoZipError"),
  });
}

const ProductCategoryInitialValues = {
  locationIds: [],
  servicesProviderId: ""
};

const LocationForm = ({
  initialValues,
  title,
  showLoadingSpinner,
  errors,
  handleSubmit,
  backCallback,
}) => {
  const { t, ready: translationsReady } = useTranslation(
    ["components/locations/form","common/form"]
  );

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={
            initialValues ? initialValues : ProductCategoryInitialValues
          }
          validationSchema={locationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <BasicFormTemplate
              className="mt-8"
              formTitle={title}
              showLoadingSpinner={showLoadingSpinner}
              footerType={FooterType.BACK_SUBMIT_BUTTONS}
              backCallback={backCallback}
            >
              <div className="space-y-2">
                {errors && (
                  <ErrorDisplayBox
                    boxDescription={t("errorBoxDescription")}
                    errors={errors}
                  />
                )}
                <FormLocationSearch
                  label={t("zip_code") + " / " + t("nameLabel")}
                  placeholder={t("placeholders.noOptionsInSelect", {
                    ns: "common/form",
                  })}
                  name="locationIds"
                  loadOptionsErrorMsg={t("errorBoxDescriptions", {
                    ns: "pages/locations/create",
                  })}
                  noOptionsMessage={t("placeholders.noOptionsInSelect", {
                    ns: "common/form",
                  })}
                  disabled={false}
                />
                {!isServicesProvider() && (
                  <>
                    <ServicesProviderFormSearch
                      label={t("professional")}
                      placeholder={t("placeholders.noOptionsInSelect", {
                        ns: "common/form",
                      })}
                      name="servicesProviderId"
                      loadOptionsErrorMsg={t(
                        "messages.errorRetrievingServicesProviders"
                      )}
                      noOptionsMessage={t("placeholders.noOptionsInSelect", {
                        ns: "common/form",
                      })}
                      disabled={false}
                    />
                  </>
                )}
              </div>
            </BasicFormTemplate>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default LocationForm;
