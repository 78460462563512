import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "@src/components/forms/FormTitle";
import Button from "@src/components/ui/Button";
import ErrorText from "@src/components/ui/ErrorText";
import PaginateData from "@src/components/ui/PaginateData";
import Spinner from "@src/components/ui/Spinner";
import { stringDateFormat } from "@src/helpers/dateHelpers";
import ListResourceUnitsTakenTable from "@src/components/products/services/edit/resource_units_taken/ListResourceUnitsTakenTable";

const ListResourceUnitsTaken = ({
                     date,
                     resourceName,
                     resourceUnitId,
                     resourceUnitTakens,
                     backCallback,
                     removeResourceUnitTaken,
                     readOnly
                   }) => {
  const [error, setError] = useState();
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation(["components/services/edit/resource_units_taken/list", "common/navigation"]);
  const LIST_RESOURCE_UNIT_TAKEN_PER_PAGE = 5;

  const breakAssociationResourceUnitTakenConfirmation = async (resource) => {
    alert("//TODO: Break timeslice-resource unit association")
    if (false) { // temporaly disable ESLint warnings
      setW18ApiResponse(false);
      setError();
    }
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="mt-2 border-t grid grid-cols-1 place-items-center mx-auto max-w-screen-sm">
          <FormTitle>{}</FormTitle>
          <div className="border-2 border-gray-400 rounded-md shadow-md my-2 p-2">
            <p>{t("labels.description.date", { date: stringDateFormat(date) })}</p>
            <p>{t("labels.description.resource", { resourceName })}</p>
            <p>{t("labels.description.resourceUnitId", { resourceUnitId })}</p>
          </div>
          <div className="space-x-2 my-2">
            <Button
              className="bg-gray-400 text-white font-bold"
              onClick={backCallback}
            >{t("back", { ns: "common/navigation" })}</Button>
          </div>
          {error &&
            <ErrorText>{t(error)}</ErrorText>
          }      
          <PaginateData
            data={resourceUnitTakens}
            perPage={LIST_RESOURCE_UNIT_TAKEN_PER_PAGE}
          >
            <ListResourceUnitsTakenTable
              deleteResourceUnitTakenCallback={breakAssociationResourceUnitTakenConfirmation}
              readOnly={readOnly}
            />
          </PaginateData>
          {w18ApiResponse && <Spinner />}
        </div>
      )}
    </>
  );
};

export default ListResourceUnitsTaken;