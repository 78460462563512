import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getCurrentUserData } from "@src/helpers/localStorageHelpers";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { getProductRequest } from "@src/helpers/productHelpers";
import { CommonRoutesStrings } from "@src/helpers/stringHelpers";
import { isProvider } from "@src/helpers/userHelpers";
import EditProductProperties from "@src/components/products/edit_product_data/EditProductProperties";
import ListDates from "@src/components/products/edit_product_data/list_dates/ListDates";
import ListTimeslices from "@src/components/products/edit_product_data/list_timeslices/ListTimeslices";
import ListPrices from "@src/components/products/edit_product_data/list_prices/ListPrices";
import AddPrice from "@src/components/products/edit_product_data/add_edit_price/AddPrice";
import EditPrice from "@src/components/products/edit_product_data/add_edit_price/EditPrice";
import EditTimesliceInfo from "@src/components/products/edit_product_data/add_edit_timeslice/EditTimesliceInfo";
import AddTimeslice from "@src/components/products/edit_product_data/add_edit_timeslice/AddTimeslice";
import AddDate from "@src/components/products/edit_product_data/add_date/AddDate";
import EditProductMainScreen from "@src/components/products/edit_product_data/EditProductMainScreen";
import Spinner from "@src/components/ui/Spinner";
import { Trans, useTranslation } from "react-i18next";
import { CardHeader } from "@material-tailwind/react";
import { isPastDate } from "@src/helpers/dateHelpers";
import EditProductTranslations from "@src/components/products/edit_product_data/edit_product_translations/EditProductTranslations";
import EditProductImages from "@src/components/products/edit_product_data/EditProductImages";
import EditProductSupplements from "@src/components/products/edit_product_data/supplements/EditProductSupplements";

const EditProductPage = ({ productType }) => {
  const [product, setProduct] = useState();
  const history = useHistory();
  const { id } = useParams();
  const { t, ready: translationsReady } = useTranslation("pages/products/edit");

  const DEFINED_COMPONENTS = {
    MAIN_COMPONENT: "MAIN_COMPONENT",
    EDIT_PRODUCT_TRANSLATIONS: "EDIT_PRODUCT_TRANSLATIONS",
    EDIT_PRODUCT_PROPERTIES: "EDIT_PRODUCT_PROPERTIES",
    EDIT_PRODUCT_IMAGES: "EDIT_PRODUCT_IMAGES",
    EDIT_PRODUCT_SUPPLEMENTS: "EDIT_PRODUCT_SUPPLEMENTS",
    LIST_DATES: "LIST_DATES",
    LIST_TIMESLICES_OF_DATE: "LIST_TIMESLICES_OF_DATE",
    LIST_PRICES_OF_TIMESLICE: "LIST_PRICES_OF_TIMESLICE",
    ADD_DATE: "ADD_DATE",
    ADD_TIMESLICE_TO_DATE: "ADD_TIMESLICE_TO_DATE",
    ADD_PRICE_TO_TIMESLICE: "ADD_PRICE_TO_TIMESLICE",
    EDIT_INFO_TIMESLICE: "EDIT_INFO_TIMESLICE",
    EDIT_INFO_PRICE: "EDIT_INFO_PRICE",
  };

  const [currentComponent, setCurrentComponent] = useState(DEFINED_COMPONENTS.MAIN_COMPONENT);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTimeslice, setSelectedTimeslice] = useState();
  const [selectedPrice, setSelectedPrice] = useState();

  const fetchProduct = async () => {
    try {
      const getProductResponse = await getProductRequest(id, productType);
      const productData = getProductResponse.data;
      if (isProvider() && productData.provider_id !== getCurrentUserData().id) {
        createNotification(NotificationTypes.error, t("validations.noPermissions.edit"));
        history.push(CommonRoutesStrings.homePath);
      } else {
        setProduct(productData);
      }
    } catch (err) {
      const errorMsg = err?.response?.status === 404 ?
        t("validations.productNotFound") : t("validations.genericRequestError");

      createNotification(NotificationTypes.error, errorMsg);
      history.push(CommonRoutesStrings.homePath);
    }
  };

  useEffect(() => {
    translationsReady && fetchProduct();
    // eslint-disable-next-line
  }, [translationsReady]);


  const refresh = () => fetchProduct();

  const removeDate = date => {
    setProduct({
      ...product,
      dates: product.dates.filter(d => d.id !== date.id)
    });
  };

  const removeTimeslice = timeslice => {
    const updatedSelectedDate = {
      ...selectedDate,
      time_slices: selectedDate.time_slices.filter(t => t.id !== timeslice.id)
    };
    setSelectedDate(updatedSelectedDate);
    setProduct({
      ...product,
      dates: product.dates.map(d => {
        if (d.id !== selectedDate.id) return d;
        else return updatedSelectedDate;
      })
    });
  };

  const removePrice = price => {
    const updatedSelectedTimeslice = {
      ...selectedTimeslice,
      prices: selectedTimeslice.prices.filter(p => p.id !== price.id)
    };
    const updatedSelectedDate = {
      ...selectedDate,
      time_slices: selectedDate.time_slices.map(t => {
        if (t.id !== selectedTimeslice.id) return t;
        else return updatedSelectedTimeslice;
      })
    };
    setSelectedTimeslice(updatedSelectedTimeslice);
    setSelectedDate(updatedSelectedDate);
    setProduct({
      ...product,
      dates: product.dates.map(d => {
        if (d.id !== selectedDate.id) return d;
        else return updatedSelectedDate;
      })
    });
  };

  const getAlreadyExistentDates = () => product.dates.map(d => d.date);

  const handleSupplementSelection = supplement =>
    setProduct({ ...product, supplements: product.supplements.concat(supplement) });

  const handleSupplementDeselection = supplement =>
    setProduct({ ...product, supplements: product.supplements.filter(s => s.id !== supplement.id) });

  const renderCurrentComponent = () => {
    switch (currentComponent) {
      case DEFINED_COMPONENTS.MAIN_COMPONENT:
        return <EditProductMainScreen
                  editProductTranslationsCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.EDIT_PRODUCT_TRANSLATIONS)}
                  editProductPropertiesCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.EDIT_PRODUCT_PROPERTIES)}
                  editProductImagesCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.EDIT_PRODUCT_IMAGES)}
                  editProductSupplementsCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.EDIT_PRODUCT_SUPPLEMENTS)}
                  editConfigurationsCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.LIST_DATES)}
                />;
      case DEFINED_COMPONENTS.EDIT_PRODUCT_TRANSLATIONS:
        return <EditProductTranslations
                  productType={product.type}
                  backCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.MAIN_COMPONENT)}
                />;
      case DEFINED_COMPONENTS.EDIT_PRODUCT_PROPERTIES:
        return <EditProductProperties
                  productData={product}
                  backAction={() => changeSelectedComponent(DEFINED_COMPONENTS.MAIN_COMPONENT)}
                />;
      case DEFINED_COMPONENTS.EDIT_PRODUCT_IMAGES:
        return <EditProductImages
                  productData={product}
                  backCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.MAIN_COMPONENT)}
                />;
      case DEFINED_COMPONENTS.EDIT_PRODUCT_SUPPLEMENTS:
        return <EditProductSupplements
                  productData={product}
                  handleSupplementSelectionCallback={handleSupplementSelection}
                  handleSupplementDeselectionCallback={handleSupplementDeselection}
                  backCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.MAIN_COMPONENT)}
                />;
      case DEFINED_COMPONENTS.LIST_DATES:
        return <ListDates
                  productType={product.type}
                  dates={product.dates}
                  backCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.MAIN_COMPONENT)}
                  addDateCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.ADD_DATE)}
                  removeDate={removeDate}
                  refresh={refresh}
                  showTimeslicesOfDateCallback={date => changeSelectedComponent(DEFINED_COMPONENTS.LIST_TIMESLICES_OF_DATE, date)}
                />;
      case DEFINED_COMPONENTS.LIST_TIMESLICES_OF_DATE:
        return <ListTimeslices
                  readOnly={isPastDate(new Date(selectedDate.date))}
                  productType={product.type}
                  date={selectedDate}
                  timeslices={selectedDate.time_slices}
                  backCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.LIST_DATES)}
                  addTimesliceCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.ADD_TIMESLICE_TO_DATE)}
                  editTimesliceCallback={timeslice => changeSelectedComponent(DEFINED_COMPONENTS.EDIT_INFO_TIMESLICE, timeslice)}
                  removeTimeslice={removeTimeslice}
                  refresh={refresh}
                  showPricesOfTimesliceCallback={timeslice => changeSelectedComponent(DEFINED_COMPONENTS.LIST_PRICES_OF_TIMESLICE, timeslice)}
                />;
      case DEFINED_COMPONENTS.LIST_PRICES_OF_TIMESLICE:
        return <ListPrices
                  readOnly={isPastDate(new Date(selectedDate.date))}
                  productType={product.type}
                  date={selectedDate}
                  timeslice={selectedTimeslice}
                  backCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.LIST_TIMESLICES_OF_DATE)}
                  addPriceCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.ADD_PRICE_TO_TIMESLICE)}
                  editPriceCallback={price => changeSelectedComponent(DEFINED_COMPONENTS.EDIT_INFO_PRICE, price)}
                  removePrice={removePrice}
                />;
      case DEFINED_COMPONENTS.ADD_DATE:
        return <AddDate
                  productType={product.type}
                  alreadyExistentDates={getAlreadyExistentDates()}
                  backCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.LIST_DATES)}
                />;
      case DEFINED_COMPONENTS.ADD_TIMESLICE_TO_DATE:
        return <AddTimeslice
                  productType={product.type}
                  date={selectedDate}
                  backCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.LIST_TIMESLICES_OF_DATE)}
                />;
      case DEFINED_COMPONENTS.ADD_PRICE_TO_TIMESLICE:
        return <AddPrice
                  productType={product.type}
                  date={selectedDate}
                  timeslice={selectedTimeslice}
                  backCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.LIST_PRICES_OF_TIMESLICE)}
                />;
      case DEFINED_COMPONENTS.EDIT_INFO_TIMESLICE:
        return <EditTimesliceInfo
                  productType={product.type}
                  date={selectedDate}
                  timesliceId={selectedTimeslice.id}
                  backCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.LIST_TIMESLICES_OF_DATE)}
                />;
      case DEFINED_COMPONENTS.EDIT_INFO_PRICE:
        return <EditPrice
                  productType={product.type}
                  date={selectedDate}
                  timeslice={selectedTimeslice}
                  price={selectedPrice}
                  backCallback={() => changeSelectedComponent(DEFINED_COMPONENTS.LIST_PRICES_OF_TIMESLICE)}
                />;
      default: return <div>Not found :(</div>;
    }
  }

  const changeSelectedComponent = (selectedComponent, requiredData) => {
    // eslint-disable-next-line
    switch (selectedComponent) {
      case DEFINED_COMPONENTS.MAIN_COMPONENT:
        setCurrentComponent(DEFINED_COMPONENTS.MAIN_COMPONENT);
        break;
      case DEFINED_COMPONENTS.EDIT_PRODUCT_TRANSLATIONS:
        setCurrentComponent(DEFINED_COMPONENTS.EDIT_PRODUCT_TRANSLATIONS);
        break;
      case DEFINED_COMPONENTS.EDIT_PRODUCT_PROPERTIES:
        setCurrentComponent(DEFINED_COMPONENTS.EDIT_PRODUCT_PROPERTIES);
        break;
      case DEFINED_COMPONENTS.EDIT_PRODUCT_IMAGES:
        setCurrentComponent(DEFINED_COMPONENTS.EDIT_PRODUCT_IMAGES);
        break;
      case DEFINED_COMPONENTS.EDIT_PRODUCT_SUPPLEMENTS:
        setCurrentComponent(DEFINED_COMPONENTS.EDIT_PRODUCT_SUPPLEMENTS);
        break;  
      case DEFINED_COMPONENTS.LIST_DATES:
        setCurrentComponent(DEFINED_COMPONENTS.LIST_DATES);
        break;
      case DEFINED_COMPONENTS.LIST_TIMESLICES_OF_DATE:
        setCurrentComponent(DEFINED_COMPONENTS.LIST_TIMESLICES_OF_DATE);
        if (requiredData) setSelectedDate(requiredData);
        break;
      case DEFINED_COMPONENTS.LIST_PRICES_OF_TIMESLICE:
        setCurrentComponent(DEFINED_COMPONENTS.LIST_PRICES_OF_TIMESLICE);
        if (requiredData) setSelectedTimeslice(requiredData);
        break;
      case DEFINED_COMPONENTS.ADD_DATE:
        setCurrentComponent(DEFINED_COMPONENTS.ADD_DATE);
        break;
      case DEFINED_COMPONENTS.ADD_TIMESLICE_TO_DATE:
        setCurrentComponent(DEFINED_COMPONENTS.ADD_TIMESLICE_TO_DATE);
        break;
      case DEFINED_COMPONENTS.ADD_PRICE_TO_TIMESLICE:
        setCurrentComponent(DEFINED_COMPONENTS.ADD_PRICE_TO_TIMESLICE);
        break;
      case DEFINED_COMPONENTS.EDIT_INFO_TIMESLICE:
        setCurrentComponent(DEFINED_COMPONENTS.EDIT_INFO_TIMESLICE);
        setSelectedTimeslice(requiredData);
        break;
      case DEFINED_COMPONENTS.EDIT_INFO_PRICE:
        setCurrentComponent(DEFINED_COMPONENTS.EDIT_INFO_PRICE);
        setSelectedPrice(requiredData);
        break;
    }
  }

  return (
    <>
      {!(translationsReady && product) ? <Spinner /> : (
        <div>
          <div className="mt-10 mb-4 flex place-content-center">
            <CardHeader color="purple" className="p-4 space-y-2">                
              <h2 className="text-white text-center text-2xl">
                {t("titles.edit")}
              </h2>
              <h6 className="uppercase text-gray-200 text-xs font-medium">
                <Trans t={t} i18nKey="productName" values={{ name: product.name }} components={{ italic: <i /> }} />  
              </h6>
            </CardHeader>
          </div>
          { renderCurrentComponent() }
        </div>
      )}
    </>
  );
};

export default EditProductPage;