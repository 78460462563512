import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import CreditForm from "@src/components/credits/CreditForm";
import CreditService from "@src/services/creditService";
import ErrorDisplayBox from "@src/components/ui/ErrorDisplayBox";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import * as Yup from "yup";
import { Dialog } from "@material-tailwind/react";

const EditCredit = ({ credit, open, handler: backCallback, modificationEndedCallback }) => {

  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const [errors, setErrors] = useState();
  const { t, ready: translationsReady } = useTranslation("components/credits/edit");
  const CreditSchema = Yup.object().shape({});

  const submitData = async (values, _) => {
    setW18ApiResponse(b => !b);


    try {

      await CreditService.update(credit.id, values.credit);
      setErrors();
      createNotification(NotificationTypes.success, t("okSubmissionResponse"));
      modificationEndedCallback();
    } catch (error) {
      createNotification(NotificationTypes.error, t("koSubmissionResponse"));

      if (error?.response?.data?.error) {
        const unformattedErrors = error.response.data.error;
        const formattedErrors = Object.entries(unformattedErrors).reduce((arr, e) => {
          const [errorKey, errorValues] = e;
          return arr.concat(errorValues.map(err => errorKey + " " + err));
        }, []);

        setErrors(formattedErrors);
      }
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <Dialog
          open={open}
          handler={backCallback}
        >
          {errors &&
            <ErrorDisplayBox
              boxDescription={t("errorBoxDescription")}
              errors={errors}
            />
          }
          <CreditForm
            title={t("title")}
            initialValues={credit}
            handleSubmit={submitData}
            creditSchema={CreditSchema}
            backCallback={backCallback}
            showLoadingSpinnerSubmitButton={w18ApiResponse}
          />
        </Dialog>
      )}
    </>
  );
};

export default EditCredit;