import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route } from "react-router";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { isLoggedIn } from "@src/helpers/sessionHelpers";
import { CommonRoutesStrings } from "@src/helpers/stringHelpers";
import Spinner from "@src/components/ui/Spinner";

const RouteWithoutLogin = ({ children, ...props }) => {
  const { t, ready: translationsReady } = useTranslation("components/routeWithoutLogin");
  
  useEffect(() => {
    if (translationsReady && isLoggedIn()) {
      createNotification(NotificationTypes.error, t("loggedInMessage"));
    }
    // eslint-disable-next-line
  }, [translationsReady]);

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <>
          {isLoggedIn() ? (
            <>
              <Redirect to={CommonRoutesStrings.homePath} />
            </>
          ) : (
            <Route {...props}>
              {children}
            </Route>
          )}
        </>
      )}
    </>
  );
};

export default RouteWithoutLogin;