import {
  isDailyRegularity,
  isMonthlyRegularity,
  isWeeklyRegularity,
  isYearlyRegularity,
} from "@src/components/products/product_wizard/RegularityTypeSelectorDialog";
import axios from "@src/helpers/axiosInstance";
import { stringDateFormat } from "@src/helpers/dateHelpers";
import { getShortCurrentLanguageCode } from "@src/helpers/localeHelpers";
import { getJWT } from "@src/helpers/localStorageHelpers";
import { isOwnProduct, isThirdPartyProduct } from "@src/helpers/productHelpers";
import {
  APIEndpointsStrings,
  ProductTypesStrings,
  RegularityTypesStrings,
  WeekDaysStrings,
} from "@src/helpers/stringHelpers";
import ProductTimesliceService from "@src/services/products/productTimesliceService";
import SupplementService from "@src/services/supplementService";

class ProductService {
  static index = async (requestPath) => {
    const res = await axios.get(requestPath ?? APIEndpointsStrings.productsPath);

    return res.data.map((product) => this.formatReceivedProduct(product));
  };

  static indexValidatedProducts = (productNameFilter,date, idProvider) => {
    const requestPath =
      APIEndpointsStrings.productsPath +
      `?q[validated_products]=true&locale=${getShortCurrentLanguageCode()}${
        productNameFilter ? `&q[translations_name_cont]=${productNameFilter}` : ""
      }${
        date ? `&q[created_at_gteq]=${date.fromDate}&q[created_at_lteq]=${date.toDate}` : ""
      }${
        idProvider ? `&q[provider_id_eq]=${idProvider}` : ""
      }`;

    return this.index(requestPath);
  };

  static indexProductsFilter = async (nameFilter, typeFilter,  providerFilter, categoryProductFilter) =>{
    let requestPath = APIEndpointsStrings.productsPath + `?q[validated_products]=true&locale=${getShortCurrentLanguageCode()}&`;
    if (nameFilter) {
      requestPath += `q[translations_name_cont]=${nameFilter}&`;
    }
    if (typeFilter) {
      requestPath += `q[type_eq]=${typeFilter}&`;
    }
    if (providerFilter) {
      requestPath += `q[provider_id_eq]=${providerFilter}&`;
    }
    if (categoryProductFilter) {
      categoryProductFilter.forEach((category) => {
        requestPath += `q[categories_id_in][]=${category.id}&`;
      })
    }
    
    return this.index(requestPath);
  };

  static indexHighlightedProducts = async (excludedProductId) => {
    let endpoint = APIEndpointsStrings.productsPath + "?q[highlighted_eq]=1";
    if (excludedProductId != null) endpoint += `&q[id_not_eq]=${excludedProductId}`;

    return this.index(endpoint);
  };

  static indexProviderProducts = async (providerId) => {
    const requestPath =
      APIEndpointsStrings.productsPath +
      `?q[type_eq]=${ProductTypesStrings.thirdPartyProduct}` +
      `&q[provider_id_eq]=${providerId}&q[accepted_at_not_null]=true` +
      "&q[denied_at_null]=true";

    return this.index(requestPath);
  };

  static indexProductsThirdPartyProduct = async (date,idProvider) => {
    const requestPath =
      APIEndpointsStrings.productsPath +
      `?q[type_eq]=${ProductTypesStrings.thirdPartyProduct}${
        date ? `&q[created_at_gteq]=${date.fromDate}&q[created_at_lteq]=${date.toDate}` : ""
      }${
        idProvider ? `&q[provider_id_eq]=${idProvider}` : ""
      }`;
    return this.index(requestPath);
  };

  static indexNotServicesProducts = (date) => {
    const requestPath =
      APIEndpointsStrings.productsPath +
      `?q[services_provider_id_blank]=1${
        date ? `&q[created_at_gteq]=${date.fromDate}&q[created_at_lteq]=${date.toDate}` : ""
      }`;

    return this.index(requestPath);
  };

  static indexProductTranslations = async (productId) => {
    const requestPath = APIEndpointsStrings.productTranslationsPath.replace(
      APIEndpointsStrings.idLocators.id,
      productId
    );

    const res = await axios.get(requestPath);

    return res.data.map((translation) => ({
      language: translation.locale,
      name: translation.name,
      description: translation.description,
      conditions: translation.conditions,
    }));
  };

  static setTranslation = (productId, productType, language, translation) => {
    const requestPath = isThirdPartyProduct(productType)
      ? APIEndpointsStrings.thirdPartyProductPath
      : APIEndpointsStrings.ownProductPath;
    const requestPathWithIDs = requestPath.replace(APIEndpointsStrings.idLocators.id, productId);

    return axios.patch(requestPathWithIDs, {
      locale: language,
      product: {
        name: translation.name,
        description: translation.description,
        conditions: translation.conditions,
      },
    });
  };

  static show = async (productId, productType) => {
    const requestPath = isThirdPartyProduct(productType)
      ? APIEndpointsStrings.thirdPartyProductPath
      : APIEndpointsStrings.ownProductPath;
    const requestPathWithIDs = requestPath
      .replace(APIEndpointsStrings.idLocators.id, productId)
      .replace(APIEndpointsStrings.idLocators.id, productId);

    const res = await axios.get(requestPathWithIDs);

    return this.formatReceivedProduct(res.data);
  };

  static create = (productData) => {
    const formattedProductData = this.formatProductForSubmission(productData);
    if (isOwnProduct(productData.productType))
      return axios.post(
        APIEndpointsStrings.ownProductsPath,
        {
          product: formattedProductData,
        },
        {
          headers: {
            Authorization: `Bearer ${getJWT()}`,
          },
        }
      );

    if (isThirdPartyProduct(productData.productType))
      return axios.post(
        APIEndpointsStrings.thirdPartyProductsPath,
        {
          product: formattedProductData,
        },
        {
          headers: {
            Authorization: `Bearer ${getJWT()}`,
          },
        }
      );

    return null;
  };

  static createWithXlsx = (file) => {
    const formData = new FormData();
    formData.append("products_file", file);

    return axios.post(APIEndpointsStrings.createProductsWithXlsxPath, formData, {
      headers: {
        Authorization: `Bearer ${getJWT()}`,
      },
    });
  };

  static performHighlightUnhighlight = (product, highlighted) => {
    const requestPath = isThirdPartyProduct(product.type)
      ? APIEndpointsStrings.thirdPartyProductPath
      : APIEndpointsStrings.ownProductPath;
    const requestPathWithID = requestPath.replace(APIEndpointsStrings.idLocators.id, product.id);

    return axios.put(
      requestPathWithID,
      {
        product: { highlighted },
      },
      {
        headers: {
          Authorization: `Bearer ${getJWT()}`,
        },
      }
    );
  };

  static highlight = (product) => this.performHighlightUnhighlight(product, 1);

  static unhighlight = (product) => this.performHighlightUnhighlight(product, 0);

  static updateProductImages = (productId, productType, bankImageIds) => {
    const requestPath = isThirdPartyProduct(productType)
      ? APIEndpointsStrings.thirdPartyProductPath
      : APIEndpointsStrings.ownProductPath;
    const requestPathWithID = requestPath.replace(APIEndpointsStrings.idLocators.id, productId);

    return axios.put(requestPathWithID, {
      product: { bank_image_ids: bankImageIds },
    });
  };

  static assignSupplementToProduct = (productId, supplementId) => {
    const requestPath = APIEndpointsStrings.assignSupplementToProductPath
      .replace(APIEndpointsStrings.idLocators.id, productId);

    return axios.post(requestPath, {
      supplement: {
          supplement_id: supplementId,
      }
    });
  };

  static deleteSupplementFromProduct = (productId, supplementId) => {
    const requestPath = APIEndpointsStrings.deleteSupplementFromProductPath
      .replace(APIEndpointsStrings.idLocators.productId, productId)
      .replace(APIEndpointsStrings.idLocators.id, supplementId);

    return axios.delete(requestPath);
  };

  static async searchEventsForGiveDate(date) {
    const res = await axios.post(
      APIEndpointsStrings.searchEventsByDatePath,
      {
        date: date,
      },
      {
        headers: {
          Authorization: `Bearer ${getJWT()}`,
        },
      }
    );

    return res.data.map((productDate) => this.formatReceivedProductDate(productDate));
  }

  static formatReceivedProduct = (product) => ({
    id: product.id,
    name: product.name,
    description: product.description,
    conditions: product.conditions,
    type: product.type,
    highlighted: product.highlighted,
    commission: product.comission,
    providerId: product.provider_id,
    professionalEmail: product.professional_email,
    requirePersonalData: product.required_participant,
    queues: product.queues,
    categories: product.categories,
    serviceCategories: product.service_categories_required,
    images: product.images?.map((image) => ({
      id: image.id,
      description: image.description,
      url: image.picture_url,
    })),
    supplements: !product.supplements
      ? undefined
      : product.supplements.map(supplement => SupplementService.formatReceivedSupplement(supplement)),
    productCache: !product.product_cache ? undefined : ({
      languages: product.product_cache.languages,
      duration: product.product_cache.max_duration,
      price: Math.min(...product.product_cache.prices),
      quota: product.product_cache.max_quota,
    }),
    dates: !product.dates ? null : product.dates.map((date) => this.formatReceivedProductDate(date)),
    locations: product.locations,
    canceled_at: product.canceled_at,
    worker_cancelled: product?.worker_cancelled
  });

  static formatReceivedProductDate = (date) => ({
    id: date.id,
    date: date.date,
    productId: date.product_id,
    product: !date.product ? null : this.formatReceivedProduct(date.product),
    deletedAt: !date.deleted_at ? null : new Date(date.deleted_at),
    createdAt: !date.created_at ? null : new Date(date.created_at),
    updatedAt: !date.updated_at ? null : new Date(date.updated_at),
    timeSlices: date.time_slices.map((timeSlice) =>
      ProductTimesliceService.formatReceivedProductTimeslice(timeSlice, false)
    ),
  });

  static formatProductForSubmission = (productData) => {
    const generateTimesWith = (hours) =>
      hours.map((hour) => ({
        hour: hour[0],
        languages: hour[1],
      }));

    let formattedData = {
      name: productData.name,
      description: productData.description,
      queues: productData.queues,
      conditions: productData.conditions,
      category_ids: productData.categoryIds,
      bank_image_ids: productData.bankImageIds,
      required_participant: productData.requirePersonalData,
      configurations: [],
      location_ids: productData.locationIds,
    };

    if (isOwnProduct(productData.productType)) {
      formattedData["service_category_ids"] = productData.serviceCategoryIds;
      formattedData["services_provider_id"] = productData.professionalId;
    } else if (isThirdPartyProduct(productData.productType)) {
      formattedData["provider_id"] = productData.professionalId;
      if (productData.comission != null && productData.comission !== "")
        formattedData["comission"] = productData.comission;
    }

    productData.configurations.forEach((configuration) => {
      let formattedConfiguration = {
        from_date: stringDateFormat(configuration.fromDate),
        to_date: stringDateFormat(configuration.toDate),
        duration: configuration.duration,
        quota: configuration.quota,
        prices: configuration.prices.map((p) => ({
          ...p,
          type_client: p.type_client.id,
        })),
      };

      if (productData.regularity === true && isWeeklyRegularity(configuration.regularityType)) {
        formattedConfiguration.regularity_type = configuration.regularityType.toLowerCase();
        formattedConfiguration.data = configuration.regularityData.map((weekdayIndex) => ({
          day: Object.keys(WeekDaysStrings.full).at(weekdayIndex),
          times: generateTimesWith(configuration.times),
        }));
      } else if (productData.regularity === true && isYearlyRegularity(configuration.regularityType)) {
        formattedConfiguration.regularity_type = configuration.regularityType.toLowerCase();
        formattedConfiguration.data = configuration.regularityData.map((day) => ({
          day,
          times: generateTimesWith(configuration.times),
        }));
      } else if (productData.regularity === true && isMonthlyRegularity(configuration.regularityType)) {
        formattedConfiguration.regularity_type = configuration.regularityType.toLowerCase();
        formattedConfiguration.data = configuration.regularityData.map((monthDay) => ({
          day: Number(monthDay),
          times: generateTimesWith(configuration.times),
        }));
      } else if (productData.regularity === true && isDailyRegularity(configuration.regularityType)) {
        formattedConfiguration.regularity_type = configuration.regularityType.toLowerCase();
        formattedConfiguration.data = Object.values(WeekDaysStrings.full).map((weekday) => ({
          day: weekday,
          times: generateTimesWith(configuration.times),
        }));
      } else {
        formattedConfiguration.regularity_type = RegularityTypesStrings.none;
        formattedConfiguration.data = configuration.dates.map((d) => ({
          day: stringDateFormat(d),
          times: generateTimesWith(configuration.times),
        }));
      }

      formattedData.configurations.push(formattedConfiguration);
    });
    return formattedData;
  };
}

export default ProductService;
