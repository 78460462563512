import { useFormikContext } from "formik";
import React from "react";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { getCreditsValidators } from "@src/services/users/userService";
import FormSearchInput from "@src/components/forms/FormSearchInput";

const CreditsValidatorsSelector = ({
  onChange,
  label,
  placeholder,
  name,
  loadOptionsErrorMsg,
  defaultProfessional,
  noOptionsMessage,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();

  const loadOptions = (inputValue) =>
    getCreditsValidators(
      inputValue
    )
      .then((res) => res.data)
      .catch((err) =>
        createNotification(NotificationTypes.error, loadOptionsErrorMsg)
      );

  const getOptionValue = (option) => option.id;
  const getOptionLabel = (option) => option.email;

  const handleChange = (option) => {
    onChange(option.id);
    setFieldValue(name, option ? option.id : null);

  };

  return (
    <FormSearchInput
      label={label}
      placeholder={placeholder}
      name={name}
      noOptionsMessage={noOptionsMessage}
      defaultValue={defaultProfessional}
      cbLoadOptions={loadOptions}
      cbGetOptionLabel={getOptionLabel}
      cbGetOptionValue={getOptionValue}
      cbHandleChange={handleChange}
      {...props}
    />
  );
};

export default CreditsValidatorsSelector;
