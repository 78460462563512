import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import IconButton from '@src/components/ui/buttons/IconButton';

const TickButton = props => {
  return (
    <IconButton
      color="green"
      {...props}
    ><FontAwesomeIcon icon={faCheck} /></IconButton>
  );
}
 
export default TickButton;