import { t } from "i18next";

export const listArrayElemsInString = arr => {
  if (arr.length === 1) return arr[0];
  const firsts = arr.slice(0, arr.length - 1);
  const last = arr[arr.length - 1];
  return firsts.join(', ') + ` ${t("and", { ns: "common/common" })} ` + last;
};

export const emptyArray = arr => Array.isArray(arr) && arr.length === 0;

/**
 * Returns array without the elements given in 2nd param.
 * 
 * @param {Array} arr 
 * @param {Array} elems Elements to remove 
 */
export const removeElemsFromArray = (arr, elems) => 
  arr.filter(x => !elems.some(elem => elem === x));