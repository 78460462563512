import React from "react";

const Table = ({ headersArray, dataMatrix, isLastColumnForActions, centerData }) => {
  const headersStyling = "py-3 uppercase text-left font-semibold text-sm",
    firstHeaderStyling = "pl-4 " + headersStyling,
    lastHeaderStyling = "text-center " + headersStyling;

  const isLastColumnAndIsForActions = index =>
    index + 1 === headersArray.length && isLastColumnForActions;

  const renderHeaderRow = () => <>
    <tr>
      {headersArray.map((value, index) => 
        <th
          key={`table-header-${index}`}
          className={index === 0 ? firstHeaderStyling : isLastColumnAndIsForActions(index) ? lastHeaderStyling : headersStyling}
        >
          {value}
        </th>
      )}
    </tr>
  </>;

  const renderDataRows = () => <>
    {dataMatrix.map((row, rowIndex) => 
      <tr
        key={`table-row-${rowIndex}`}
        className={rowIndex % 2 === 0 ? "bg-white" : "bg-gray-100"}
      >
        {row.map((cellData, index) =>
          <td
            key={`table-cell-${rowIndex}-${index}`}
            className={index === 0 ? "py-3 pl-4"  : (centerData ? "text-center" : "")}
          >
            {isLastColumnAndIsForActions(index) ? (
              <div className="grid grid-flow-col place-items-center">
                {cellData}
              </div>
            ) : cellData}
          </td>
        )}
      </tr>
    )}
  </>;

  return (
    <div className="mt-4 w-full shadow rounded-xl overflow-hidden border-b border-gray-200">
      <table className="min-w-full">
        <thead className="bg-gray-800 text-white">
          {renderHeaderRow()}
        </thead>
        <tbody className="text-gray-700">
          {renderDataRows()}
        </tbody>     
      </table>
    </div>
  );
};

export default Table;