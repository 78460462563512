import React, { useState, useEffect } from "react";
import BasicFormTemplate, { FooterType } from "@src/components/forms/BasicFormTemplate";
import ShowImageDialog from "@src/components/image_bank/ShowImageDialog";
import Button from "@src/components/ui/buttons/Button";
import Spinner from "@src/components/ui/Spinner";
import { emptyArray } from "@src/helpers/arrayHelpers";
import { useToggle } from "@src/helpers/hooks/useToggle";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import ImageBankService from "@src/services/imageBankService";
import SelectedImagesTable from "@src/components/products/product_wizard/SelectedImagesTable";
import SelectProductImageDialog from "@src/components/products/product_wizard/SelectProductImageDialog";
import { useTranslation } from "react-i18next";

const ProductImageSelector = ({
  initialValues: alreadySelectedImageIds,
  editing,
  readOnly,
  showLoadingSpinner,
  callbackBackClicked,
  callbackNextClicked,
}) => {
  const [selectedImages, setSelectedImages] = useState();
  const [nextPressedWithoutConditionsSatisfied, setNextPressedWithoutConditionsSatisfied] = useState(false);
  const [showSelectProductImageDialog, setShowSelectProductImageDialog] = useToggle();
  const [selectedImageToShow, setSelectedImageToShow] = useState();
  const { t, ready: translationsReady } = useTranslation([
    "components/products/create/productImages",
    "pages/products/create",
    "common/list",
  ]);
  const MAX_IMAGES_PER_PRODUCT = 3;

  const handleShowSelectProductImageDialog = () => {
    if (selectedImages.length === MAX_IMAGES_PER_PRODUCT) {
      setNextPressedWithoutConditionsSatisfied(true);
    } else {
      setNextPressedWithoutConditionsSatisfied(false);
      setShowSelectProductImageDialog();
    }
  };

  const handleSelectedImage = (image) => {
    setSelectedImages(selectedImages.concat(image));
    setShowSelectProductImageDialog();
  };

  const handleImageDeselection = (image) => {
    setSelectedImages(selectedImages.filter((i) => i.id !== image.id));
    setNextPressedWithoutConditionsSatisfied(false);
  };

  const handleNextStep = () =>
    emptyArray(selectedImages)
      ? setNextPressedWithoutConditionsSatisfied(true)
      : callbackNextClicked(selectedImages.map((image) => image.id));

  useEffect(() => {
    const fetchImages = async () => {
      try {
        setSelectedImages(await ImageBankService.indexImagesWithIds(alreadySelectedImageIds));
      } catch (err) {
        createNotification(NotificationTypes.error, t("genericRequestError"));
      }
    };

    if (translationsReady) {
      emptyArray(alreadySelectedImageIds) ? setSelectedImages([]) : fetchImages();
    }
    // eslint-disable-next-line
  }, [translationsReady]);

  return !translationsReady ? (
    <Spinner />
  ) : (
    <>
      <BasicFormTemplate
        formTitle={t("stepTitles.productImages", {
          ns: "pages/products/create",
        })}
        backCallback={callbackBackClicked}
        nextCallback={handleNextStep}
        footerType={
          editing ? FooterType.BACK_SUBMIT_BUTTONS : readOnly ? FooterType.BACK_BUTTON : FooterType.BACK_NEXT_BUTTONS
        }
        headerColor="teal"
        className="mt-10 max-w-3xl mx-auto"
        showLoadingSpinner={showLoadingSpinner}
      >
        <div className="space-y-4 mx-6 justify-center">
          <p className="mt-2 text-center">{t("description")}</p>
          {!readOnly && (
            <>
              <p className={`text-center ${nextPressedWithoutConditionsSatisfied ? "font-bold text-red-500" : ""}`}>
                {t("requirements")}
              </p>
              <div className="flex justify-center">
                <Button color="green" onClick={handleShowSelectProductImageDialog}>
                  {t("add", { ns: "common/list" })}
                </Button>
              </div>
            </>
          )}
          {!selectedImages ? (
            <div className="flex justify-center mt-4">
              <Spinner />
            </div>
          ) : (
            <div className="grid grid-cols-1 place-items-center max-w-xl mx-auto">
              <SelectedImagesTable
                imageList={selectedImages}
                showImageCallback={setSelectedImageToShow}
                deselectImageCallback={readOnly ? undefined : handleImageDeselection}
              />
            </div>
          )}
        </div>
      </BasicFormTemplate>
      <ShowImageDialog image={selectedImageToShow} backCallback={() => setSelectedImageToShow()} />
      {selectedImages && (
        <SelectProductImageDialog
          open={showSelectProductImageDialog}
          selectedImageIds={selectedImages.map((image) => image.id)}
          handler={() => setShowSelectProductImageDialog()}
          selectImageCallback={handleSelectedImage}
        />
      )}
    </>
  );
};

export default ProductImageSelector;
