import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "../ui/Spinner";

const UserCard = ({ user }) => {
  const { t, ready: translationsReady } = useTranslation("common/userTypes");

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="grid grid-cols-1 text-center p-2 border rounded-md bg-gray-50">
          <FontAwesomeIcon icon={faUserCircle} className="mx-auto" size="5x" color="gray" />
          <p>{user.email}</p>
          <p>{t(user.type)}</p>
        </div>
      )}
    </>
  );
};

export default UserCard;