import CreateBanner from "@src/components/banners/CreateBanner";
import ListBannersTable from "@src/components/banners/ListBannersTable";
import BannerImageDetails from "@src/components/banners/BannerImageDetails";
import PaginateData from "@src/components/ui/PaginateData";
import Spinner from "@src/components/ui/Spinner";
import {createNotification, NotificationTypes} from "@src/helpers/notificationHelpers";
import {CommonRoutesStrings} from "@src/helpers/stringHelpers";
import BannerService from "@src/services/bannerService";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Redirect} from "react-router-dom";
import BooleanResponseDialog from "@src/components/ui/BooleanResponseDialog";
import {Dialog} from "@material-tailwind/react";
import HistoryBackButton from "@src/components/ui/buttons/HistoryBackButton";
import Button from "@src/components/ui/buttons/Button";
import FilterBannerForm from "@src/components/banners/FilterBannerForm";

const ListBannersPage = () => {
  const [banners, setBanners] = useState();
  const [error, setError] = useState(false);
  const [showBannerCreationModal, setShowBannerCreationModal] = useState(false);
  const [bannerToShow, setBannerToShow] = useState();
  const [showFilterForm, setShowFilterForm] = useState(true);
  const [descriptionFilter, setDescriptionFilter] = useState("");
  const [EnabledFilter, setEnabledFilter] = useState("");
  const [bannerToDelete, setBannerToDelete] = useState();
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const {t, ready: translationsReady} = useTranslation(["pages/banners/list", "common/common", "common/list","common/filterForm"]);
  const BANNER_PER_PAGE = 5;

  const fetchBanners = async () => {
    try {
      setBanners(await BannerService.findAll());
    } catch (_) {
      createNotification(NotificationTypes.error, t("messages.genericRequestError"));
      setTimeout(() => setError(true), 3000);
    }
  };

  useEffect(() => {
    translationsReady && fetchBanners();
    // eslint-disable-next-line
  }, [translationsReady]);

  useEffect(() => {
    const bannerbyfilters = async () => {
      setBanners(
        await BannerService.indexFilters(
          descriptionFilter,
          EnabledFilter
        )
      );
    }
    bannerbyfilters();
  }, [descriptionFilter, EnabledFilter]);

  const finishBannerCreation = () => {
    setShowBannerCreationModal(false);
    fetchBanners();
  };

  const toggleFiltering = () => {
    setShowFilterForm((show) => !show);
    setDescriptionFilter("");
  };

  function handleFilterInputChange(setStateCallback, newState) {
    setStateCallback(newState);
  }

  const closeShowBannerDialog = () => setBannerToShow();

  const deleteBanner = async () => {
    const bannerId = bannerToDelete.id;
    setBannerToDelete();
    setW18ApiResponse(b => !b);

    try {
      await BannerService.destroy(bannerId);
      createNotification(NotificationTypes.success, t("messages.deleteAction.ok"));
      setBanners(banners.filter(n => n.id !== bannerId));
    } catch (_) {
      createNotification(NotificationTypes.error, t("messages.deleteAction.ko"));
    } finally {
      setW18ApiResponse(b => !b);
    }

  };
  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <>
          {error && <Redirect to={CommonRoutesStrings.homePath} />}
          <div className="flex justify-center items-center mt-2 space-x-2">
            <HistoryBackButton size="md" />
            <Button
              color="green"
              onClick={() => setShowBannerCreationModal(true)}
            >
              {t("new", { ns: "common/common" })}
            </Button>
            {
              <Button onClick={toggleFiltering}>
                {!showFilterForm
                  ? t("enableFilter", { ns: "common/filterForm" })
                  : t("disableFilter", { ns: "common/filterForm" })}
              </Button>
            }
          </div>
          {!banners ? (
            <Spinner />
          ) : (
            <div className="grid grid-cols-1 place-items-center max-w-4xl mx-auto">
            {showFilterForm && (
                <FilterBannerForm
                  color="yellow"
                  className="mt-11 mb-2 w-[90%]"
                  cbSetDescription={(description) =>
                    handleFilterInputChange(setDescriptionFilter, description)
                  }
                  cbSetEnabled={(enabled) =>
                    handleFilterInputChange(setEnabledFilter, enabled)
                  }
                />
              )}
              <PaginateData
                data={banners}
                emptyDataArrayMsg={t("messages.noBannersToList")}
                perPage={BANNER_PER_PAGE}
              >
                <ListBannersTable
                  title={t("title")}
                  showBannerImageCallback={(banner) => setBannerToShow(banner)}
                  deleteBannerCallback={(banner) => setBannerToDelete(banner)}
                />
              </PaginateData>
            </div>
          )}
          <CreateBanner
            open={showBannerCreationModal}
            handler={() => setShowBannerCreationModal((b) => !b)}
            backCallback={() => setShowBannerCreationModal(false)}
            creationEndedCallback={finishBannerCreation}
          />
          <Dialog open={!!bannerToShow} handler={closeShowBannerDialog}>
            <>
              {bannerToShow && (
                <BannerImageDetails
                  banner={bannerToShow}
                  backCallback={closeShowBannerDialog}
                />
              )}
            </>
          </Dialog>
          <BooleanResponseDialog
            open={!!bannerToDelete}
            yesCallback={deleteBanner}
            noCallback={() => setBannerToDelete()}
          >
            <>{bannerToDelete && t("messages.confirmBannerDeletion")}</>
          </BooleanResponseDialog>
          {w18ApiResponse && <Spinner />}
        </>
      )}
    </>
  );
};

export default ListBannersPage;