import React from "react";
import {
  BrowserRouter as Router
} from "react-router-dom";
import { isLoggedIn } from "@src/helpers/sessionHelpers";
import { isClient } from "@src/helpers/userHelpers";
import B2CApp from "@src/components/B2CApp";
import B2BApp from "@src/components/B2BApp";

const bgImageStyles = {
  backgroundImage: `linear-gradient(rgba(249,249,249,.8),rgba(249,249,249,.8))`,
  backgroundSize: "cover",
  backgroundPosition: "100%",
};

function App() {
  return (
    <div className="min-w-full min-h-full max-w-full absolute overflow-hidden " style={bgImageStyles}>
      <Router>
        {!isLoggedIn() || (isLoggedIn() && isClient()) ? <B2CApp /> : <B2BApp />}
      </Router>
    </div>
  );
}

export default App;
