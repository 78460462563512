import React, { useEffect, useState } from "react";
import Spinner from "@src/components/ui/Spinner";
import { useTranslation } from "react-i18next";
import { useQuery } from "@src/helpers/hooks/useQuery";
import {
  createNotification,
  NotificationTypes,
} from "@src/helpers/notificationHelpers";
import PaginateData from "@src/components/ui/PaginateData";
import ResultsListProductsSearch from "@src/components/products/b2c/ResultsListProductsSearch";
import {
  CommonRoutesStrings,
  HexStandardPinkStylingB2C,
} from "@src/helpers/stringHelpers";
import { useHistory } from "react-router-dom";
import FiltersProductSearch from "@src/components/products/b2c/FiltersProductSearch";
import ProductFiltersB2CService from "@src/services/products/productFiltersB2CService";
import { useDebounce } from "@src/helpers/hooks/useDebounce";

import "@src/assets/styles/b2c/pages/list_products_search/globals.css";
import "@src/assets/styles/b2c/pages/list_products_search/styleguide.css";

const ListProductsSearchPage = () => {
  const DEBOUNCE_DELAY_MS = 1000; // 1s
  const [products, setProducts] = useState();
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedDates, setSelectedDates] = useState();
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [selectedPriceLimits, setSelectedPriceLimits] = useState();
  const [locations, setLocations] = useState();
  const [selectedDurationLimits, setSelectedDurationLimits] = useState();
  const debouncedSelectedLanguages = useDebounce(
    selectedLanguages,
    DEBOUNCE_DELAY_MS
  );
  const debouncedSelectedCategoryIds = useDebounce(
    selectedCategoryIds,
    DEBOUNCE_DELAY_MS
  );
  const debouncedSelectedPriceLimits = useDebounce(
    selectedPriceLimits,
    DEBOUNCE_DELAY_MS
  );
  const debouncedSelectedDurationLimits = useDebounce(
    selectedDurationLimits,
    DEBOUNCE_DELAY_MS
  );
  const {
    t,
    ready: translationsReady,
    i18n,
  } = useTranslation("pages/b2c/listProductsSearch");
  const query = useQuery();
  const productName = query.get("productName") ?? ""; // get empty string if no query param
  const PRODUCTS_PER_PAGE = 5;
  const history = useHistory();

  const fetchProducts = async () => {
    try {
      setProducts(
        await ProductFiltersB2CService.search(
          debouncedSelectedLanguages,
          selectedDates,
          selectedPriceLimits,
          selectedDurationLimits,
          debouncedSelectedCategoryIds,
          productName,
          locations
        )
      );
    } catch (err) {
      createNotification(NotificationTypes.error, t("errorRetrievingProducts"));
      history.replace(CommonRoutesStrings.homePath);
    }
  };

  useEffect(() => {
    const fetchProductsGets = () => {
      setSelectedCategoryIds([]);
      setLocations([]);
      if (query.get("categoryId")) {
        setSelectedCategoryIds([Number.parseInt(query.get("categoryId"))]);
      }
      if (query.get("locations")) {
        setLocations([query.get("locations")]);
      }
    };
    fetchProductsGets();
    // eslint-disable-next-line
  }, [query.get("categoryId"), query.get("locations")]);

  // TODO: ver si en vez de dos useEffect (initial load y filtros), podemos juntarlos en uno
  useEffect(() => {
    i18n.language && fetchProducts();
    // eslint-disable-next-line
  }, [
    i18n.language,
    debouncedSelectedLanguages,
    selectedDates,
    debouncedSelectedCategoryIds,
    debouncedSelectedPriceLimits,
    debouncedSelectedDurationLimits,
    locations
  ]);

  const toggleLanguageSelection = (language, isPresent) =>
    isPresent
      ? setSelectedLanguages(selectedLanguages.filter((l) => l !== language))
      : setSelectedLanguages(selectedLanguages.concat(language));

  const toggleCategoryIdSelection = (categoryId, isPresent) =>
    isPresent
      ? setSelectedCategoryIds(
          selectedCategoryIds.filter((l) => l !== categoryId)
        )
      : setSelectedCategoryIds(selectedCategoryIds.concat(categoryId));

  return (
    <div className="container-center-horizontal">
      <div className="flex flex-col items-center max-w-[1440px] w-[100%] bg-white font-['Montserrat']">
        {!translationsReady ? (
          <Spinner />
        ) : (
          <>
            <div className="flex flex-col items-start w-[100%]">
              <div className="flex justify-center relative xl:h-[504px] md:h-[360px] sm:h-[275px] w-full">
                {/* eslint-disable-next-line */}
                <img
                  className="absolute"
                  src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bb0ccec0392b641a3b895f/img/image-24@1x.png"
                />
                <h1 className="font-extrabold text-7xl absolute mt-24">
                  {t("title")}
                </h1>
              </div>
            </div>
            <div className="flex items-start my-8 w-[100%] justify-evenly">
              {/* left column filter panel */}

              <FiltersProductSearch
                selectedLanguages={selectedLanguages}
                setSelectedLanguageCallback={toggleLanguageSelection}
                setSelectedDatesCallback={setSelectedDates}
                selectedCategoryIds={selectedCategoryIds}
                setSelectedCategoryIdCallback={toggleCategoryIdSelection}
                selectedPriceLimits={selectedPriceLimits}
                setSelectedPriceLimitsCallback={setSelectedPriceLimits}
                selectedDurationLimits={selectedDurationLimits}
                setSelectedDurationLimitsCallback={setSelectedDurationLimits}
              />
              <div className="flex flex-col items-end ml-8 w-[67.7%]">
                {!products ? (
                  <Spinner />
                ) : (
                  <>
                    <div
                      className={`font-bold leading-7 mb-8 ml-32 tracking-normal whitespace-no-wrap h-[29px] text-4xl text-[${HexStandardPinkStylingB2C}]`}
                    >
                      {t("numberResults", { number: products.length })}
                    </div>

                    {/* product list */}
                    <PaginateData
                      data={products}
                      emptyDataArrayMsg={t("noProductsToShow")}
                      perPage={PRODUCTS_PER_PAGE}
                      usedInB2CPortal={true}
                    >
                      <ResultsListProductsSearch />
                    </PaginateData>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ListProductsSearchPage;
