import { dateToStringDDMMYYYYHHMMSS } from "@src/helpers/dateHelpers";
import React from "react";
import EyeButton from "@src/components/ui/buttons/EyeButton";
import Spinner from "@src/components/ui/Spinner";
import TableCard from "@src/components/ui/TableCard";
import TrashButton from "@src/components/ui/buttons/TrashButton";
import { useTranslation } from "react-i18next";
import PencilButton from "@src/components/ui/buttons/PencilButton";

const ListImageBankTable = ({
  currentPageData: imageList,
  showImageCallback,
  editImageCallback,
  deleteImageCallback,
}) => {
  const { t, ready: translationsReady } = useTranslation([
    "components/imageBank/listTable",
    "common/list",
    "common/common",
    "pages/imageBank/list",
    "common/booleanResponse"
  ]);

  const getHeadersArray = () => Object.values(t("headers")).concat(t("actionsTableHeader", { ns: "common/list" }));

  const formatDataMatrix = () =>
    imageList.map((image) => [
      image.fixed ? t("yes", {ns : "common/booleanResponse"}) : t("no", {ns : "common/booleanResponse"}) ,
      image.description,
      image.updatedAt ? dateToStringDDMMYYYYHHMMSS(image.updatedAt) : t("notAvailable", { ns: "common/common" }),
      <>
        <EyeButton onClick={() => showImageCallback(image)} />
        {editImageCallback && <PencilButton onClick={() => editImageCallback(image)} />}
        {deleteImageCallback && <TrashButton onClick={() => deleteImageCallback(image)} disabled={image.fixed} />}
      </>,
    ]);

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <TableCard
          title={t("title", { ns: "pages/imageBank/list" })}
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

export default ListImageBankTable;
