import { Form, Formik } from "formik";
import React from "react";
import PeopleSegmentSearch from "@src/components/products/product_wizard/PeopleSegmentSearch";
import { isPositiveFloat } from "@src/helpers/numberHelpers";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import FormikTextInput from "@src/components/forms/FormikTextInput";
import BasicFormTemplate, { FooterType } from "@src/components/forms/BasicFormTemplate";

const ProductPriceFormInitialValues = {
  type_client: "",
  price: "",
  quota: ""
};

const SingleProductPriceForm = ({ title, alreadySelectedPeopleSegments, quotaLeftToAssign, editingPrice, initialValues, backCallback, submitCallback, showLoadingSpinner }) => {
  const { t, ready: translationsReady } = useTranslation(["components/products/create/prices", "pages/products/create"]);

  const defaultPeopleSegment = initialValues ? { name: initialValues.type_client.name } : null;

  const clientTypeAlreadySelected = clientType => alreadySelectedPeopleSegments.some(p => p.id === clientType.id);

  const validateData = values => {
    const errors = {};

    if (!editingPrice){
      if (!values.type_client) {
        errors.type_client = "components/products/create/prices:validations.noClientType";
      } else if (clientTypeAlreadySelected(values.type_client)) {
        errors.type_client = "components/products/create/prices:validations.clientTypeAlreadySelected";
      }
    }

    if (!values.price) {
      errors.price = "components/products/create/prices:validations.noPrice";
    } else if (!isPositiveFloat(values.price)) {
      errors.price = "components/products/create/prices:validations.priceNotIntegerGreater0";
    }

    if (values.quota) {
      if (!/^[1-9][0-9]*$/.test(values.quota)) {
        errors.quota = "components/products/create/prices:validations.quotaNotIntegerGreater0";
      } else {
        const newQuota = Number(values.quota);
        const differenceQuota = initialValues ? newQuota - initialValues.quota : newQuota;

        if (values.type_client && quotaLeftToAssign - differenceQuota * values.type_client.quantity < 0) {
          errors.quota = "components/products/create/prices:validations.morePricesQuotasThanQuotaForSelectedHours";
        }
      }
    }
    
    return errors;
  };
  
  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <Formik
          initialValues={initialValues ? initialValues : ProductPriceFormInitialValues}
          validate={validateData}
          onSubmit={submitCallback}
        >
          <Form>
            <BasicFormTemplate
              formTitle={title}
              showLoadingSpinner={showLoadingSpinner}
              backCallback={backCallback}
              headerColor="deep-orange"
              footerType={FooterType.BACK_SUBMIT_BUTTONS}
            >
              <div className="grid grid-cols-1">
                <PeopleSegmentSearch
                  label={t("labels.priceTableHeaders.clientType")}
                  placeholder={t("labels.priceSelector.priceTemplateSelectorPlaceholder")}
                  name="type_client"
                  defaultPeopleSegment={defaultPeopleSegment}
                  loadOptionsErrorMsg={t("messages.errorRetrievingPeopleSegments", { ns: "pages/products/create" })}
                  yMargin="2"
                  disabled={editingPrice}
                />
                <div className="my-2">
                  <FormikTextInput
                    label={t("labels.priceSelector.priceLabel")}
                    name="price"
                  />
                </div>
                <div className="my-2">
                  <FormikTextInput
                    label={t("labels.priceTableHeaders.quota")}
                    name="quota"
                  />
                  <p className="text-gray-600">{t("labels.priceSelector.quotaLeftDescription", { quota: quotaLeftToAssign })}</p>
                </div>
              </div>
            </BasicFormTemplate>
          </Form>
        </Formik>
      )}
    </>
  );
}

export default SingleProductPriceForm;