import React, { useState } from "react";
import BackNextSubmitButtons from "@src/components/products/product_wizard/BackNextSubmitButtons";
import PaginateData from "@src/components/ui/PaginateData";
import RegularityTable from "@src/components/products/product_wizard/RegularityTable";
import RegularityTypeSelectorDialog, { isDailyRegularity, isMonthlyRegularity, isWeeklyRegularity, isYearlyRegularity } from "@src/components/products/product_wizard/RegularityTypeSelectorDialog";
import { getAllDaysInDateRange, getMonthDaysInDateRange, getWeekDaysInDateRange, getYearDaysInDateRange } from "@src/helpers/dateHelpers";
import AddDeleteSelectAllButtons from "@src/components/products/product_wizard/AddDeleteSelectAllButtons";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import { Card } from "@material-tailwind/react";
import { emptyArray } from "@src/helpers/arrayHelpers";
import BooleanResponseDialog from "@src/components/ui/BooleanResponseDialog";

export const generateDatesForDateRange = (dateRange, regularityType, specificRegularityData) => {
  const startDate = new Date(dateRange.fromDate), endDate = new Date(dateRange.toDate)
  if (isDailyRegularity(regularityType)) {
    return getAllDaysInDateRange(startDate, endDate);
  }
  if (isWeeklyRegularity(regularityType)) {
    return getWeekDaysInDateRange(startDate, endDate, specificRegularityData);
  }
  if (isMonthlyRegularity(regularityType)) {
    return getMonthDaysInDateRange(startDate, endDate, specificRegularityData);
  }
  if (isYearlyRegularity(regularityType)) {
    return getYearDaysInDateRange(startDate, endDate, specificRegularityData);
  }
  return [];
};

const Regularity = ({ currentStep, numberSteps, initialValues, callbackNextClicked, callbackBackClicked }) => {
  const [showRegularitySelectorDialog, setShowRegularitySelectorDialog] = useState(false);
  const [showRegularityDeletionDialog, setShowRegularityDeletionDialog] = useState(false);
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [dateRanges, setDateRanges] = useState(initialValues);
  const { t, ready: translationsReady } = useTranslation(["components/products/create/regularity", "pages/products/create"]);

  const toggleSelectionAllRows = () => {
    if (selectedIndexes.length === dateRanges.length) {
      setSelectedIndexes([]);
    } else {
      setSelectedIndexes([...Array(dateRanges.length).keys()]);
    }
  }

  const getSelectedDateRanges = () => selectedIndexes.map(index => dateRanges.at(index));

  const deleteRegularitiesOfSelectedIndexes = () => {
    setDateRanges(dateRanges.map((dateRange, index) => {
      if (selectedIndexes.some(i => i === index)) {
        return {
          ...dateRange,
          regularityType: null,
          regularityData: null
        }; 
      } else
        return dateRange;
    }));
    setShowRegularityDeletionDialog(false);
  };

  const handleNextStep = () => {
    callbackNextClicked(dateRanges);
  };

  const handleRegularitySelection = data => {    
    setDateRanges(dateRanges.map((dateRange, index) => {
      if (selectedIndexes.some(i => i === index)) {        
        return {
          ...dateRange,
          regularityType: data.regularityType,
          regularityData: data.regularityData
        }; 
      } else
        return dateRange;
    }));

    setShowRegularitySelectorDialog(false);
    setSelectedIndexes([]);
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="grid grid-cols-1 max-w-5xl mx-auto">
          <Card className="place-self-center px-4 py-2">
            <AddDeleteSelectAllButtons
              addBtnDisabled={emptyArray(selectedIndexes)}
              deleteBtnDisabled={emptyArray(selectedIndexes) || selectedIndexes.some(index => !dateRanges.at(index).regularityType)}
              cbAdd={() => setShowRegularitySelectorDialog(true)}
              cbDelete={() => setShowRegularityDeletionDialog(true)}
              cbSelectAll={toggleSelectionAllRows}
            />
          </Card>
          <div className="grid grid-cols-1 place-items-center">
            <PaginateData
              data={dateRanges}
            >
              <RegularityTable
                numElements={dateRanges.length}
                selectedIndexes={selectedIndexes}
                cbSetSelectedIndexes={setSelectedIndexes}
              />
            </PaginateData>
          </div>          
          <RegularityTypeSelectorDialog
            open={showRegularitySelectorDialog}
            handler={() => setShowRegularitySelectorDialog(false)}
            selectedDateRanges={getSelectedDateRanges()}
            cbSubmitRegularity={handleRegularitySelection}
          />
          <BooleanResponseDialog
            open={showRegularityDeletionDialog}
            noCallback={() => setShowRegularityDeletionDialog(false)}
            yesCallback={deleteRegularitiesOfSelectedIndexes}
          >{t("labels.deleteRegularityMsg")}</BooleanResponseDialog>
          <BackNextSubmitButtons
            currentStep={currentStep}
            numberSteps={numberSteps}
            nextSubmitButtonDisabled={dateRanges.some(dateRange => dateRange.regularityType == null)}
            cbBackButton={callbackBackClicked}
            cbNextButton={handleNextStep}
          />
        </div>
      )}
    </>
  );
};

export default Regularity;