import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import OrderGuestClientDataForm from "@src/components/orders/b2c/new/OrderGuestClientDataForm";
import OptionalSubmitButton from "@src/components/ui/b2c/buttons/OptionalSubmitButton";
import SubmitButton from "@src/components/ui/b2c/buttons/SubmitButton";
import Spinner from "@src/components/ui/Spinner";
import { formatPrice } from "@src/helpers/localeHelpers";
import {
  decodeProductToOrderFromStorage,
  getCurrentUserData,
  LocalStorageKeys,
} from "@src/helpers/localStorageHelpers";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { isLoggedIn } from "@src/helpers/sessionHelpers";
import OrderService from "@src/services/orders/orderService";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

const CheckoutPage = () => {
  const [guestClientData, setGuestClientData] = useState();
  const [showGuestClientDataForm, setShowGuestClientDataForm] = useState(false);
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const productToOrder = decodeProductToOrderFromStorage();
  const pricePreVAT = productToOrder.totalPrice / 1.21,
    productVAT = productToOrder.totalPrice - pricePreVAT;
  const { t, ready: translationsReady } = useTranslation("pages/b2c/checkout");

  const finalizeOrderGuestClientDataForm = clientData => {
    setGuestClientData(clientData);
    setShowGuestClientDataForm(false);
  };

  const canPlaceOrder = () => isLoggedIn() || (!isLoggedIn() && guestClientData && !showGuestClientDataForm);

  const performPaymentRedirection = async (order) => {
    const { data } = await OrderService.paymentRedirection(order);
    if (data) {
      localStorage.removeItem(LocalStorageKeys.productToOrder);
      const inputMerchantParameters = document.getElementById('Ds_MerchantParameters');
      inputMerchantParameters.value = data.Ds_MerchantParameters;
      const inputSignature = document.getElementById('Ds_Signature');
      inputSignature.value = data.Ds_Signature;
      const inputSignatureVersion = document.getElementById('Ds_SignatureVersion');
      inputSignatureVersion.value = data.Ds_SignatureVersion;
      const form = document.getElementById('payment-form');
      form.action = data.redsys_url;

      form.submit();
    }
  }

  const performAction = async action => {
    setW18ApiResponse(b => !b);

    try {
      const { data } = await action();
      createNotification(NotificationTypes.success, t("submitOrderAction.ok"));
      performPaymentRedirection(data.order);
    } catch (error) {
      console.error(error);
      createNotification(NotificationTypes.error, t("submitOrderAction.ko"));
      setW18ApiResponse(b => !b);
    }
  };

  const handleOrderSubmission = () =>
    isLoggedIn()
      ? performAction(() =>
          OrderService.create(
            productToOrder.productId,
            productToOrder.dateProductId,
            productToOrder.configurations,
            productToOrder.supplements
          )
        )
      : performAction(() =>
          OrderService.createAsGuest(
            productToOrder.productId,
            productToOrder.dateProductId,
            productToOrder.configurations,
            guestClientData,
            productToOrder.supplements
          )
        );

  return (
    <div className="bg-white py-[67px] px-[9.4%] grid grid-cols-1  sm:text-sm md:text-base lg:text-lg xl:text-xl montserrat-medium-shark w-full mx-auto max-w-[1440px]">
      {!translationsReady ? (
        <Spinner />
      ) : (
        <>
          <p className="self-start font-extrabold sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-8 tracking-normal whitespace-no-wrap text-[#e30746]">
            {t("title")}
          </p>
          <div className="mt-8 grid grid-cols-3 space-x-6">
            <div className="grid grid-cols-1 bg-gray-50 col-span-2 space-y-4 border-2 border-gray-600 rounded-[28px] p-4">
              {isLoggedIn() ? (
                <p>
                  <Trans
                    t={t}
                    i18nKey="contactDetailsBox.loggedInAs"
                    components={{ 1: <i /> }}
                    values={{ email: getCurrentUserData().email }}
                  />
                </p>
              ) : (
                <>
                  {guestClientData && !showGuestClientDataForm ? (
                    <>
                      <p className="border-b-2 border-gray-600">{t("contactDetailsBox.guestUserData.description")}</p>
                      <p>{t("contactDetailsBox.guestUserData.name", { name: guestClientData.name })}</p>
                      <p>{t("contactDetailsBox.guestUserData.email", { email: guestClientData.email })}</p>
                      <p>{t("contactDetailsBox.guestUserData.phone", { phone: guestClientData.phone })}</p>
                      <div className="flex">
                        <OptionalSubmitButton type="button" onClick={() => setShowGuestClientDataForm(true)}>
                          {t("contactDetailsBox.guestUserData.editDataBtn")}
                        </OptionalSubmitButton>
                      </div>
                    </>
                  ) : (
                    <OrderGuestClientDataForm
                      initialValues={guestClientData}
                      finalizeForm={finalizeOrderGuestClientDataForm}
                    />
                  )}
                </>
              )}
            </div>
            <div className="grid grid-cols-1 h-fit border-y-2 border-gray-600 p-4">
              <div className="flex justify-between">
                <p>{t("pricingSummary.subtotal")}</p>
                <p>{formatPrice(pricePreVAT)}</p>
              </div>
              <div className="flex justify-between">
                <p>{t("pricingSummary.vat")}</p>
                <p>{formatPrice(productVAT)}</p>
              </div>
              <div className="flex justify-between border-t-2 border-gray-600 mt-2 pt-2">
                <p>{t("pricingSummary.total")}</p>
                <p>{formatPrice(productToOrder.totalPrice)}</p>
              </div>
            </div>
          </div>
          <div className="flex">
            <form id="payment-form" method="POST" target="_self">
              <input id="Ds_MerchantParameters" type="hidden" name="Ds_MerchantParameters" value="" />
              <input id="Ds_Signature" type="hidden" name="Ds_Signature" value="" />
              <input id="Ds_SignatureVersion" type="hidden" name="Ds_SignatureVersion" value="" />
            </form>
            <SubmitButton
              type="button"
              className="mt-6"
              disabled={!canPlaceOrder()}
              onClick={handleOrderSubmission}
              icon={faShoppingCart}
              showLoadingSpinner={w18ApiResponse}
            >
              {t("confirmPurchaseBtn")}
            </SubmitButton>
          </div>
        </>
      )}
    </div>
  );
};

export default CheckoutPage;
