import { stringDateFormat } from "@src/helpers/dateHelpers";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { formatHour } from "@src/helpers/localeHelpers";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { deleteProductPrice } from "@src/helpers/productHelpers";
import Button from "@src/components/ui/buttons/Button";
import ErrorText from "@src/components/ui/ErrorText";
import PaginateData from "@src/components/ui/PaginateData";
import Spinner from "@src/components/ui/Spinner";
import ListPricesTable from "@src/components/products/edit_product_data/list_prices/ListPricesTable";
import HistoryBackButton from "@src/components/ui/buttons/HistoryBackButton";
import BooleanResponseDialog from "@src/components/ui/BooleanResponseDialog";
import { Dialog } from "@material-tailwind/react";

const ListPrices = ({ productType, date, timeslice, backCallback, addPriceCallback, editPriceCallback, removePrice, readOnly }) => {
  const [error, setError] = useState();
  const { id : productId } = useParams();
  const [showEventDetailsDialog, setShowEventDetailsDialog] = useState(false);
  const [priceToDelete, setPriceToDelete] = useState();
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const PER_PAGE = 5;
  const { t, ready: translationsReady } = useTranslation(["components/products/edit/listPrices", "common/navigation", "common/list"]);

  const deletePrice = async () => {
    setW18ApiResponse(b => !b);

    try {
      await deleteProductPrice(productId, date.id, timeslice.id, priceToDelete.id);
      createNotification(NotificationTypes.success, t("labels.successfulPriceDeletion"));
      removePrice(priceToDelete);
      setPriceToDelete();
    } catch (err) {
      createNotification(NotificationTypes.error, t("labels.genericErrorPriceDeletion"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  const enablePriceDeletion = price => {
    if (Array.isArray(timeslice.prices) && timeslice.prices.length <= 1) {
      setError("validations.noPriceDeletionIfOnlyOneInList");
    } else {
      setError();
      setPriceToDelete(price);
    }
  };

  const closeEventDetailsDialog = () => setShowEventDetailsDialog(false);

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="grid grid-cols-1 place-items-center mx-auto max-w-screen-lg">
          <div className="space-x-2 my-2">
            <HistoryBackButton onClick={backCallback} size="md" />
            <Button
              color="green"
              onClick={() => setShowEventDetailsDialog(true)}
            >{t("labels.showEventDetailsBtn")}</Button>
            {!readOnly &&
              <Button
                onClick={addPriceCallback}
              >{t("add", { ns: "common/list" })}</Button>
            }
          </div>
          {error &&
            <ErrorText>{t(error)}</ErrorText>
          }      
          <PaginateData
            data={timeslice.prices}
            perPage={PER_PAGE}
          >
            <ListPricesTable
              editPriceCallback={editPriceCallback}
              deletePriceCallback={price => enablePriceDeletion(price)}
              readOnly={readOnly}
            />
          </PaginateData>
          <BooleanResponseDialog
            open={!!priceToDelete}
            noCallback={() => setPriceToDelete()}
            yesCallback={deletePrice}
            showLoadingSpinner={w18ApiResponse}
          >
            {t("labels.confirmPriceDeletionMessage")}
          </BooleanResponseDialog>
          <Dialog
            open={showEventDetailsDialog}
            handler={closeEventDetailsDialog}
          >
            <div className="p-2">
              <p className="text-lg font-bold">{t("labels.eventDetailsModal.description")}</p>
              <ul className="list-inside list-disc">
                <li>{t("labels.eventDetailsModal.date", { date: stringDateFormat(date.date), hour: formatHour(timeslice.begins_at) })}</li>
                <li>{t("labels.eventDetailsModal.duration", { duration: timeslice.duration })}</li>
                <li>{t("labels.eventDetailsModal.language", { language: timeslice.language })}</li>
                <li>{t("labels.eventDetailsModal.quota", { quota: timeslice.quota })}</li>
                <li>{t("labels.eventDetailsModal.available", { available: timeslice.available })}</li>
                <li>{t("labels.eventDetailsModal.buyingDeadline", { date: timeslice.deadline_sale })}</li>
              </ul>
              <Button
                className="bg-gray-400 font-bold text-white mt-2"
                onClick={() => setShowEventDetailsDialog(false)}
              >{t("close", { ns: "common/navigation" })}</Button>
            </div>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default ListPrices;