import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { faImages, faInfoCircle, faLanguage, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardFooter, CardHeader, Typography } from "@material-tailwind/react";
import Button from "@src/components/ui/buttons/Button";
import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";

const EditProductSection = ({ className, icon, title, description, selectCallback }) => {
  const { t, ready: translationsReady } = useTranslation("common/list");

  return !translationsReady ? (
    <Spinner />
  ) : (
    <Card className={`mt-8 max-w-xs m-auto ${className ?? ""}`}>
      <CardHeader
        color="light-blue"
        variant="gradient"
        className="flex py-5 px-2 place-content-center flex-col space-y-2"
      >
        <FontAwesomeIcon className="flex mx-auto" icon={icon} size="3x" color="white" />
        <Typography variant="h4" color="white" className="text-center">
          {title}
        </Typography>
      </CardHeader>
      <div className="min-h-[144px] flex justify-center p-6 flex-col">
        <p>{description}</p>
      </div>
      <CardFooter>
        <div className="flex place-content-center">
          <Button color="green" onClick={selectCallback}>
            {t("select", { ns: "common/list" })}
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
};

const EditProductMainScreen = ({
  editProductTranslationsCallback,
  editProductPropertiesCallback,
  editProductImagesCallback,
  editProductSupplementsCallback,
  editConfigurationsCallback,
}) => {
  const { t, ready: translationsReady } = useTranslation("pages/products/edit");

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <>
          <div className="container max-w-5xl mx-auto space-y-2">
            <div className="grid grid-row-1 sm:space-y-12 lg:space-y-6 lg:grid-cols-3 lg:space-x-2">
              <EditProductSection
                icon={faLanguage}
                title={t("labels.mainComponent.editProductTranslations.title")}
                description={t("labels.mainComponent.editProductTranslations.description")}
                selectCallback={editProductTranslationsCallback}
              />
              <EditProductSection
                icon={faInfoCircle}
                title={t("labels.mainComponent.editProductProperties.title")}
                description={t("labels.mainComponent.editProductProperties.description")}
                selectCallback={editProductPropertiesCallback}
              />
              <EditProductSection
                icon={faImages}
                title={t("labels.mainComponent.editProductImages.title")}
                description={t("labels.mainComponent.editProductImages.description")}
                selectCallback={editProductImagesCallback}
              />
            </div>
          </div>
          <div className="container mt-4 max-w-2xl mx-auto space-y-2">
            <div className="grid grid-cols-1 sm:space-y-12 lg:space-y-6 lg:grid-cols-2 lg:space-x-2">
              <EditProductSection
                icon={faPlus}
                title={t("labels.mainComponent.editProductSupplements.title")}
                description={t("labels.mainComponent.editProductSupplements.description")}
                selectCallback={editProductSupplementsCallback}
              />
              <EditProductSection
                icon={faCalendarAlt}
                title={t("labels.mainComponent.editConfigurations.title")}
                description={t("labels.mainComponent.editConfigurations.description")}
                selectCallback={editConfigurationsCallback}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EditProductMainScreen;
