import { faSadTear } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const NoResultsBubble = ({ message }) => (
  <div className="grid grid-cols-1 place-items-center mx-auto space-y-2">
    <FontAwesomeIcon icon={faSadTear} size="2xl" color="#f04c4c" />
    <p>{message}</p>
  </div>
);
 
export default NoResultsBubble;