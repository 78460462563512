import React from "react";
import Spinner from "@src/components/ui/Spinner";
import { useTranslation } from "react-i18next";
import { isPastDate, stringDateFormat } from "@src/helpers/dateHelpers";
import TrashButton from "@src/components/ui/buttons/TrashButton";
import ClockButton from "@src/components/ui/buttons/ClockButton";
import TableCard from "@src/components/ui/TableCard";
import BanButton from "@src/components/ui/buttons/BanButton";

const ListDatesTable = ({ currentPageData : dateList, deleteDateCallback, showTimeslicesOfDateCallback,cancelProductCallback, readOnly }) => {
  const { t, ready: translationsReady } = useTranslation("components/products/edit/listDates");

  const getHeadersArray = () => Object.values(t("labels.listDatesTableHeaders"));

  const formatDataMatrix = () =>
    dateList.map((date) => [
      stringDateFormat(date.date),
      <>
        <ClockButton
          onClick={() => showTimeslicesOfDateCallback(date)}
          title={t("labels.viewDateTimeslicesTitle")}
        />
        {!readOnly && (
          <TrashButton
            onClick={() => deleteDateCallback(date)}
            disabled={isPastDate(new Date(date.date))}
          />
        )}
        {!readOnly && (
          <BanButton
            onClick={() => cancelProductCallback(date)}
            disabled={date.canceled_at || isPastDate(new Date(date.date))}
          />
        )}
      </>,
    ]);

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
          title={t("labels.title")}
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

export default ListDatesTable;