import Button from '@src/components/ui/b2c/buttons/Button';
import CommentBox from '@src/components/ui/b2c/CommentBox';
import Dialog from '@src/components/ui/b2c/dialogs/Dialog';
import Spinner from '@src/components/ui/Spinner';
import { formatPrice } from '@src/helpers/localeHelpers';
import { OrderStatusStrings } from '@src/helpers/stringHelpers';
import React from 'react';
import { useTranslation } from 'react-i18next';

const OrderRefundDetailsDialog = ({ orderRefund, handler }) => {
  const { t, ready: translationsReady } = useTranslation(["components/orders/b2c/orderRefundDetailsDialog", "common/navigation"]);

  return (
    <Dialog
      showDialog={!!orderRefund}
      cbCloseDialog={handler}
    >
      {!(translationsReady && orderRefund) ? <Spinner /> : (
        <div className="space-y-4 overflow-y-auto h-full">
          <p className="text-[#e30746] font-bold text-2xl">{t("title")}</p>
          {orderRefund.clientRefundReason &&
            <>
              <p>{t("refundReason.client")}</p>
              <CommentBox>{orderRefund.clientRefundReason}</CommentBox>
            </>
          }
          {orderRefund.orderStatus.status === OrderStatusStrings.pendingRefund ? (
            <p>{t("awaitingResolution")}</p>
          ) : (
            <>
              <p>{t("refundAmount", { amount: formatPrice(orderRefund.refundQuantity) })}</p>
              <p className="mt-4">{t("refundReason.operator")}</p>
              <CommentBox>{orderRefund.operatorRefundReason}</CommentBox>
            </>
          )}
          <Button
            className="bg-gray-500 font-bold text-white place-self-center"
            onClick={handler}
          >{t("close", { ns: "common/navigation" })}</Button>
        </div>
      )}
    </Dialog>
  );
}
 
export default OrderRefundDetailsDialog;