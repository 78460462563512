import axios from "@src/helpers/axiosInstance";
import { APIEndpointsStrings } from "@src/helpers/stringHelpers";

class ProductCategoryService {
  static index = async (requestPath) => {
    const res = await axios.get(requestPath ?? APIEndpointsStrings.categoriesPath);
    return res.data;
  };

  static indexHighlightedOnes = () => {
    const requestPath = APIEndpointsStrings.categoriesPath + "?q[highlighted_eq]=1";

    return this.index(requestPath);
  };

  static performHighlightUnhighlight = (categoryId, highlighted) => 
    axios.put(APIEndpointsStrings.categoryPath.replace(APIEndpointsStrings.idLocators.id, categoryId), {
      category: { highlighted }
    });

  static highlight = categoryId => this.performHighlightUnhighlight(categoryId, 1);

  static unhighlight = categoryId => this.performHighlightUnhighlight(categoryId, 0);
};

export default ProductCategoryService;