import React, { useEffect, useState } from "react";
import PaginateData from "@src/components/ui/PaginateData";
import ListUsersTable from "@src/components/users/ListUsersTable";
import { 
  createNotification,
  delayedRedirect,
  NotificationTypes
} from "@src/helpers/notificationHelpers";
import { CommonRoutesStrings, DefinedRoutesStrings } from "@src/helpers/stringHelpers";
import {
  deleteUserRequest,
  getCountProfessionalsToValidateRequest,
  indexUsersRequest
} from "@src/helpers/userHelpers";
import FilterUsersForm from "@src/components/users/FilterUsersForm";
import Spinner from "@src/components/ui/Spinner";
import { useTranslation } from "react-i18next";
import UserCard from "@src/components/users/UserCard";
import LinkButton from "@src/components/ui/buttons/LinkButton";
import { Chip, Dialog } from "@material-tailwind/react";
import { useHistory } from "react-router-dom";
import Button from "@src/components/ui/buttons/Button";
import BooleanResponseDialog from "@src/components/ui/BooleanResponseDialog";
import UserService from "@src/services/users/userService";
import { storeImpersonatedUser } from "@src/helpers/localStorageHelpers";

const ListUsersPage = () => {
  const [data, setData] = useState();
  const [
    countProfessionalsToValidate,
    setCountProfessionalsToValidate
  ] = useState(0);
  const [emailFilter, setEmailFilter] = useState("");
  const [userTypeFilter, setUserTypeFilter] = useState("");
  const [showFilterForm, setShowFilterForm] = useState(true);
  const [selectedUserForDetails, setSelectedUserForDetails] = useState();
  const [showUserDetailsModal, setShowUserDetailsModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState();
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const [forceFirstPageListUsers, setForceFirstPageListUsers] = useState(false);
  const { t, ready: translationsReady } = useTranslation([
    "pages/users/list",
    "common/filterForm",
    "common/navigation"
  ]);
  const history = useHistory();
  const USERS_PER_PAGE = 5;

  const deleteUserAction = async () => {
    const userId = userToDelete.id;
    setUserToDelete();
    setW18ApiResponse(b => !b);

    try {
      await deleteUserRequest(userId);
      createNotification(NotificationTypes.success,
        t("messages.delete.ok"));
      setData(data.filter(c => c.id !== userId));
    } catch (error) {
      createNotification(NotificationTypes.error,
        t("messages.delete.ko"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  const impersonateUser = async (user) => {
    setW18ApiResponse(b => !b);

    try {
      const impersonatedUser = await UserService.impersonate(user);
      storeImpersonatedUser(impersonatedUser);
      delayedRedirect(CommonRoutesStrings.homePath, 1);
    } catch (err) {
      createNotification(NotificationTypes.error, t("messages.errorImpersonateRequest"));
      setW18ApiResponse(b => !b);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const indexUsersResponse = await indexUsersRequest();
        setData(indexUsersResponse.data);
        const getCountProfessionalsToValidateResponse = 
          await getCountProfessionalsToValidateRequest();
        setCountProfessionalsToValidate(
          getCountProfessionalsToValidateResponse.data.count);
      } catch (err) {
        createNotification(NotificationTypes.error, 
          t("messages.cannotRetrieveUserList"));
        history.push(CommonRoutesStrings.homePath);
      } finally {
        setW18ApiResponse(false);
      }
    };

    translationsReady && fetchData();
    // eslint-disable-next-line
  }, [translationsReady]);

  useEffect(() => {
    const getUsersGivenFilters = async () => {
      const indexUser = await UserService.indexUserFilters(emailFilter, userTypeFilter);
      setData(indexUser.data);
    }
    getUsersGivenFilters();
  }, [emailFilter, userTypeFilter]);

  const toggleFiltering = () => {
    setShowFilterForm(show => !show);
    setEmailFilter("");
    setUserTypeFilter("");
  };

  const showUserDetails = user => {
    setSelectedUserForDetails(user);
    setShowUserDetailsModal(true);
  };

  const handleFilterInputChange = (setStateCallback, newState) => {
    setForceFirstPageListUsers(true);
    setStateCallback(newState);
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <>
        <div className="flex lg:flex-row sm:flex-col justify-center items-center space-x-2 my-2">
          <div className="flex flex-row justify-center items-center space-x-2 my-2">
            <LinkButton
              id="create-user"
              to={DefinedRoutesStrings.createUserPath}
            >{t("buttons.createUser")}</LinkButton>
            <LinkButton
              to={DefinedRoutesStrings.disableProfessionalsPath}
            >{t("buttons.disableProfessionals")}</LinkButton>
            </div>
            <div className="flex flex-row justify-center items-center space-x-2 my-2">
            <LinkButton
              id="validate-professionals"
              to={DefinedRoutesStrings.validateProfessionalsPath}
            >{t("buttons.validateProfessionals.text")}</LinkButton>
            {countProfessionalsToValidate > 0 &&
              <Chip
                color="red"
                className="right-6 -top-4 absolute cursor-help"
                title={t("buttons.validateProfessionals.badgeInfo")}
                value={countProfessionalsToValidate}
              />
            }
            <Button
              className={countProfessionalsToValidate && "relative -left-7"}
              onClick={toggleFiltering}
              disabled={!data}
            >
              {!showFilterForm ? 
                t("enableFilter", { ns: "common/filterForm" })
                :
                t("disableFilter", { ns: "common/filterForm" })}
            </Button>
          </div>
        </div>
          {!data ? (
            <Spinner />
          ) : (
            <div className="grid grid-cols-1 place-items-center">
              {showFilterForm &&
                <FilterUsersForm
                  color="yellow"
                  className="mt-7 mb-2"
                  cbSetEmail={email => handleFilterInputChange(setEmailFilter, email)}
                  cbSetUserType={userType => handleFilterInputChange(setUserTypeFilter, userType)}
                />
              }
              <PaginateData
                data={data}
                perPage={USERS_PER_PAGE}
                emptyDataArrayMsg={t("messages.noUserToList")}
                forceFirstPage={forceFirstPageListUsers}
                setForceFirstPageCallback={setForceFirstPageListUsers}
              >
                <ListUsersTable
                  showUserDetailsCallback={showUserDetails}
                  deleteUserCallback={user => setUserToDelete(user)}
                  impersonateUserCallback={impersonateUser}
                />
              </PaginateData>
            </div>
          )}
          {w18ApiResponse && <Spinner />}
          <Dialog
            open={showUserDetailsModal}
            handler={() => setShowUserDetailsModal(b => !b)}
          >
            <div className="grid grid-cols-1 m-2 space-y-2">
              {selectedUserForDetails &&
                <UserCard  user={selectedUserForDetails} />
              }
              <Button
                color="gray"
                className="place-self-center"
                onClick={() => setShowUserDetailsModal(false)}
              >{t("close", { ns: "common/navigation" })}</Button>
            </div>
          </Dialog>
          <BooleanResponseDialog
            open={!!userToDelete}
            yesCallback={deleteUserAction}
            noCallback={() => setUserToDelete()}
          >
            <>
              {userToDelete &&
                t("messages.delete.confirm",
                  { userEmail: userToDelete.email })
              }
            </>
          </BooleanResponseDialog>
        </>
      )}
    </>
  );
};

export default ListUsersPage;