import Spinner from "@src/components/ui/Spinner";
import { emptyArray } from "@src/helpers/arrayHelpers";
import { formatPrice } from "@src/helpers/localeHelpers";
import {
  findAvailableDatesInProductGivenLanguage,
  findAvailableHoursInProductDateGivenLanguage,
  findProductDateForStrDate,
} from "@src/helpers/productHelpers";
import { B2CRoutesStrings, HexStandardPinkStylingB2C } from "@src/helpers/stringHelpers";
import React, { Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import NoResultsBubble from "@src/components/orders/b2c/new/NoResultsBubble";
import OrderDateSelectorCalendar from "@src/components/orders/b2c/new/OrderDateSelectorCalendar";
import PricesPicker from "@src/components/orders/b2c/new/PricesPicker";
import EsFlag from "@src/assets/images/country_flags/es.svg";
import GbFlag from "@src/assets/images/country_flags/gb.svg";
import DeFlag from "@src/assets/images/country_flags/de.svg";
import FrFlag from "@src/assets/images/country_flags/fr.svg";
import ItFlag from "@src/assets/images/country_flags/it.svg";
import PtFlag from "@src/assets/images/country_flags/pt.svg";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import SubmitButton from "@src/components/ui/b2c/buttons/SubmitButton";
import { isLoggedIn } from "@src/helpers/sessionHelpers";
import { storeProductToOrder } from "@src/helpers/localStorageHelpers";
import { useHistory } from "react-router-dom";
import DialogOrderAsGuestOrLogin from "@src/components/orders/b2c/new/DialogOrderAsGuestOrLogin";
import ProductSupplementSelectorOrderingDialog from "@src/components/orders/b2c/new/ProductSupplementSelectorOrderingDialog";

const CreateOrderPane = ({ product }) => {
  const supportedLanguages = [
    { code: "es", flag: EsFlag },
    { code: "en", flag: GbFlag },
    { code: "fr", flag: FrFlag },
    { code: "it", flag: ItFlag },
    { code: "pt", flag: PtFlag },
    { code: "de", flag: DeFlag },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState();
  const [selectedProductDate, setSelectedProductDate] = useState();
  const [selectedTimeSlice, setSelectedTimeSlice] = useState();
  const [selectedPrices, setSelectedPrices] = useState();

  const [showDialogOrderAsGuestOrLogin, setShowDialogOrderAsGuestOrLogin] = useState(false);
  const [showProductSupplementSelectorOrderingDialog, setShowProductSupplementSelectorOrderingDialog] = useState(false);

  const { t, ready: translationsReady } = useTranslation("pages/orders/create");
  const history = useHistory();

  const availableDays = useMemo(
    () => (!selectedLanguage ? [] : findAvailableDatesInProductGivenLanguage(product, selectedLanguage)),
    // eslint-disable-next-line
    [selectedLanguage]
  );
  const availableHoursForLanguageAndDate = useMemo(
    () =>
      !(selectedLanguage && selectedProductDate)
        ? []
        : findAvailableHoursInProductDateGivenLanguage(selectedProductDate, selectedLanguage),
    // eslint-disable-next-line
    [selectedLanguage, selectedProductDate?.id]
  );

  const handleLanguageSelection = (language) => {
    setSelectedLanguage(language);
    setSelectedProductDate();
    setSelectedTimeSlice();
    setSelectedPrices();
  };

  const handleDateSelectionInCalendar = (date) => {
    setSelectedProductDate(findProductDateForStrDate(product, date));
    setSelectedTimeSlice();
    setSelectedPrices();
  };

  const handleTimeSliceHourSelection = (hour) => {
    const timeSlice = selectedProductDate.timeSlices.find(
      (timeSlice) => timeSlice.language === selectedLanguage && timeSlice.beginsAt === hour
    );
    setSelectedTimeSlice(timeSlice);
    initializeSelectedPrices(timeSlice.prices);
  };

  const initializeSelectedPrices = (prices) => {
    const initialPrices = prices.map((price) => ({ id: price.id, quantity: 0 }));
    setSelectedPrices(initialPrices);
  };

  const getTicketPrice = (priceId) => selectedTimeSlice.prices.find((p) => p.id === priceId).price;

  const getTotalPrice = () =>
    selectedPrices.reduce((sum, price) => sum + price.quantity * getTicketPrice(price.id), 0);

  const finalizePriceSelections = () => {
    if (emptyArray(product.supplements)) {
      handleCheckoutRedirection();
    } else {
      setShowProductSupplementSelectorOrderingDialog(true);
    }
  };

  const handleSupplementSelections = selectedSupplements =>
    handleCheckoutRedirectionWithDelay(selectedSupplements);

  const handleCheckoutRedirection = async (selectedSupplements) => {
    let supplements = [], supplementPrices = 0;
    selectedSupplements?.forEach(supplement => {
      supplements.push({ id: supplement.id, quantity: supplement.quantity });
      supplementPrices += supplement.price * supplement.quantity;
    });

    const productToOrder = {
      productId: product.id,
      dateProductId: selectedProductDate.id,
      configurations: selectedPrices.filter(selectedPrice => selectedPrice.quantity > 0),
      totalPrice: getTotalPrice() + supplementPrices,
    };

    if (selectedSupplements) productToOrder.supplements = supplements;

    storeProductToOrder(productToOrder);
    if (isLoggedIn()) {
      history.push(B2CRoutesStrings.checkoutPath);
    } else {
      setShowDialogOrderAsGuestOrLogin(true);
    }
  };

  const handleCheckoutRedirectionWithDelay = (selectedSupplements) => {
    setShowProductSupplementSelectorOrderingDialog(false);
    setTimeout(() => handleCheckoutRedirection(selectedSupplements), 1000);
  };

  return !translationsReady ? (
    <Spinner />
  ) : (
    <div className="flex flex-col ml-16 p-8 rounded-[28px] w-[518px] shadow-2xl">
      {product.requirePersonalData ? (
        <NoResultsBubble message={t("productWithPersonalDataNotSupported")} />
      ) : (
        <>
          <div className="flex flex-col items-start w-64">
            <div className="leading-8 whitespace-nowrap montserrat-bold-shark-24px">{t("descriptions.language")}</div>
            <div className="flex items-start h-5 mt-6">
              {/* eslint-disable-next-line */}
              <img
                className="h-5 w-5"
                src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bc4675b1e3967329ef0e4a/img/group-1741@2x.svg"
              />
              <div className="flex items-start self-center ml-2">
                {supportedLanguages.map((language) => {
                  const isCurrentLanguageSelected = language.code === selectedLanguage;

                  return (
                    <Fragment key={language.code}>
                      {/* eslint-disable-next-line */}
                      <img
                        src={language.flag}
                        className={`h-[40px] mx-2 p-2 rounded-md ${
                          isCurrentLanguageSelected
                            ? "border-4 border-red-600 cursor-default"
                            : "border-2 border-white hover:border-red-200 cursor-pointer"
                        }`}
                        onClick={!isCurrentLanguageSelected ? () => handleLanguageSelection(language.code) : () => {}}
                      />
                    </Fragment>
                  );
                })}
              </div>
            </div>
          </div>
          {selectedLanguage && (
            <>
              <div className={`h-px my-6 bg-[${HexStandardPinkStylingB2C}]`}></div>
              {emptyArray(availableDays) ? (
                <NoResultsBubble message={t("noDaysForThatLanguageError")} />
              ) : (
                <>
                  <div className="leading-8 mb-2 whitespace-nowrap montserrat-bold-shark-24px">
                    {t("descriptions.date")}
                  </div>
                  <OrderDateSelectorCalendar
                    availableDays={availableDays}
                    selectedDay={selectedProductDate?.date}
                    selectDayCallback={handleDateSelectionInCalendar}
                  />
                </>
              )}
              {selectedProductDate && (
                <>
                  <div className={`h-px mt-6 bg-[${HexStandardPinkStylingB2C}]`}></div>
                  <div className="flex flex-col items-start mt-6">
                    <div className="leading-8 whitespace-nowrap montserrat-bold-shark-24px">
                      {t("descriptions.timetable")}
                    </div>
                    <div className="items-start mt-6 grid grid-cols-4">
                      {availableHoursForLanguageAndDate.map((hour, index) => (
                        <div
                          key={index}
                          className={`flex items-center justify-center h-10 py-1 px-4 rounded-[34px] min-w-[103px] ${
                            index >= 4 ? "mt-[15px]" : ""
                          } ${index % 4 > 0 ? "ml-4" : ""} ${
                            hour === selectedTimeSlice?.beginsAt
                              ? "bg-red-600 cursor-default"
                              : "bg-gray-500 hover:bg-red-300 cursor-pointer"
                          }`}
                          onClick={() => handleTimeSliceHourSelection(hour)}
                        >
                          <div className="leading-7 whitespace-nowrap montserrat-semi-bold-white-16px">{hour}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {selectedTimeSlice && (
                    <>
                      <div className={`h-px my-6 bg-[${HexStandardPinkStylingB2C}]`}></div>
                      <div className="flex flex-col items-start">
                        <div className="leading-8 whitespace-nowrap montserrat-bold-shark-24px">
                          {t("descriptions.prices")}
                        </div>
                        <div className="flex flex-col items-start mt-6 w-full">
                          <PricesPicker
                            prices={selectedTimeSlice.prices}
                            selectedPrices={selectedPrices}
                            cbSetSelectedPrices={setSelectedPrices}
                            timesliceAvailable={selectedTimeSlice.available}
                          />
                        </div>
                      </div>
                      <div className={`h-px mt-6 bg-[${HexStandardPinkStylingB2C}]`}></div>
                      <div className="flex items-start justify-between mt-6">
                        <div className="leading-8 whitespace-nowrap montserrat-bold-shark-24px">
                          {t("descriptions.totalPrice")}
                        </div>
                        <div
                          className={`font-['Montserrat'] font-bold text-[${HexStandardPinkStylingB2C}] text-2xl leading-8 text-right whitespace-nowrap`}
                        >
                          {formatPrice(getTotalPrice())}
                        </div>
                      </div>
                      <SubmitButton
                        type="button"
                        className="mt-6"
                        disabled={!(getTotalPrice() > 0)}
                        onClick={finalizePriceSelections}
                        icon={faShoppingCart}
                      >
                        {t("checkoutButton")}
                      </SubmitButton>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      <ProductSupplementSelectorOrderingDialog
        open={showProductSupplementSelectorOrderingDialog}
        handler={() => setShowProductSupplementSelectorOrderingDialog(false)}
        availableSupplements={product.supplements}
        handleSupplementSelectionsCallback={handleSupplementSelections}
        skipSupplementSelectionsCallback={handleCheckoutRedirectionWithDelay}
      />
      <DialogOrderAsGuestOrLogin
        open={showDialogOrderAsGuestOrLogin}
        handler={() => setShowDialogOrderAsGuestOrLogin(false)}
      />
    </div>
  );
};

export default CreateOrderPane;
