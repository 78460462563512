import React from "react";

const Badge = ({ textColor, bgColor, children : text }) => {
  return (
    <div className="flex">
      <div
        className={`items-center rounded my-1 px-1 ${textColor} ${bgColor}`}
      >
        {text}
      </div>
    </div>
  );
};

export default Badge;