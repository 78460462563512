import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Typography
} from "@material-tailwind/react";
import React from "react";
import HistoryBackButton from "@src/components/ui/buttons/HistoryBackButton";
import SubmitButton from "@src/components/ui/buttons/SubmitButton";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";

export const FooterType = Object.freeze({
  NO_BUTTONS: "NO_BUTTONS",
  SUBMIT_BUTTON: "SUBMIT_BUTTON",
  BACK_BUTTON: "BACK_BUTTON",
  BACK_SUBMIT_BUTTONS: "BACK_SUBMIT_BUTTONS",
  BACK_NEXT_BUTTONS: "BACK_NEXT_BUTTONS",
});

const BasicFormTemplate = ({
  formTitle,
  showLoadingSpinner,
  disableSubmitButton,
  className,
  children,
  footerType,
  headerColor,
  backCallback,
  nextCallback,
}) => {
  const { t, ready: translationsReady } = useTranslation("common/navigation");

  const renderCardFooter = () => {
    switch (footerType) {
      case FooterType.SUBMIT_BUTTON:
        return (
          <CardFooter>
            <div className="flex place-content-center">
              <SubmitButton
                showLoadingSpinner={showLoadingSpinner}
                size="lg"
                disabled={disableSubmitButton}
              />
            </div>
          </CardFooter>
        );
      
      case FooterType.BACK_BUTTON:
        return (
          <CardFooter>
            <div className="flex place-content-center">
              <HistoryBackButton onClick={backCallback} />
            </div>
          </CardFooter>
        );

      case FooterType.BACK_SUBMIT_BUTTONS:
        return (
          <CardFooter>
            <div className="flex place-content-center">
              <HistoryBackButton
                className="mr-auto"
                onClick={backCallback}
              />
              <SubmitButton
                showLoadingSpinner={showLoadingSpinner}
                size="lg"
                className="ml-auto"
                disabled={disableSubmitButton}
                onClick={nextCallback}
              />
            </div>
          </CardFooter>
        );
      
      case FooterType.BACK_NEXT_BUTTONS:
        return (
          <CardFooter>
            <div className="flex place-content-center">
              <HistoryBackButton
                className="mr-auto"
                onClick={backCallback}
              />
              <SubmitButton
                showLoadingSpinner={showLoadingSpinner}
                size="lg"
                className="ml-auto"
                disabled={disableSubmitButton}
                onClick={nextCallback}
              >{t("next")}</SubmitButton>
            </div>
          </CardFooter>
        );

      case FooterType.NO_BUTTONS:
      default:
        return null;
    }
  }

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <Card className={className}>
          <CardHeader
            color={headerColor ?? "light-blue"}
            variant="gradient"
            className="flex py-10 px-2 place-content-center"
          >
            <Typography
              variant="h4"
              color="white"
              className="text-center"
            >{formTitle}</Typography>
          </CardHeader>
          <CardBody>
            {children}
          </CardBody>
          {renderCardFooter()}
        </Card>
      )}
    </>
  );
};

export default BasicFormTemplate;