import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faCircle, faMinusSquare, faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { stringDateFormat } from "@src/helpers/dateHelpers";
import { isWeeklyRegularity, isMonthlyRegularity, isYearlyRegularity } from "@src/components/products/product_wizard/RegularityTypeSelectorDialog";
import { listArrayElemsInString, removeElemsFromArray } from "@src/helpers/arrayHelpers";
import { rangeNumbersArray } from "@src/helpers/numberHelpers";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import TableCard from "@src/components/ui/TableCard";

export const getDateRangeRegularityDescription = (dateRange, dataFromAPI) => {
  if (isWeeklyRegularity(dateRange.regularityType)) {
    const weekdayList = dataFromAPI ?
      dateRange.regularityData.map(weekday => t("simplified", { ns: "common/weekDays" })[weekday])
      :
      dateRange.regularityData.map(weekdayIndex => Object.values(t("simplified", { ns: "common/weekDays" })).at(weekdayIndex));
    return t("labels.regularityDescription.weekly", { ns: "components/products/create/regularity", days: listArrayElemsInString(weekdayList) });
  }
  if (isMonthlyRegularity(dateRange.regularityType))
    return t("labels.regularityDescription.monthly", { ns: "components/products/create/regularity", days: listArrayElemsInString(dateRange.regularityData) });
  if (isYearlyRegularity(dateRange.regularityType)) {
    return t("labels.regularityDescription.yearly", { ns: "components/products/create/regularity", days: listArrayElemsInString(dateRange.regularityData) });
  }

  return null;
};

const RegularityTable = ({ numElements, selectedIndexes, cbSetSelectedIndexes, currentPageData : dateRanges, currentPage, perPage : PER_PAGE}) => {
  const { t, ready: translationsReady } = useTranslation(["components/products/create/regularity", "pages/products/create", "common/regularityTypes", "common/common", "common/weekDays"]);

  const getCurrentPageIndexes = () => {
    const indexStart = PER_PAGE * currentPage,
      indexEnd = numElements < PER_PAGE * (currentPage + 1) ? numElements : PER_PAGE * (currentPage + 1);

    return rangeNumbersArray(indexStart, indexEnd);
  };

  const allIndexesOfCurrentPageSelected = () => {
    const indexesToCheck = getCurrentPageIndexes();
    return indexesToCheck.every(i => selectedIndexes.includes(i));
  };

  const toggleSelections = () => {
    const currentPageIndexes = getCurrentPageIndexes();
    if (allIndexesOfCurrentPageSelected())
      cbSetSelectedIndexes(removeElemsFromArray(selectedIndexes, currentPageIndexes));
    else
      cbSetSelectedIndexes(selectedIndexes.concat(currentPageIndexes));
  };

  const getSelectAllButton = () =>
    <button onClick={toggleSelections}><FontAwesomeIcon icon={allIndexesOfCurrentPageSelected() ? faMinusSquare : faPlusSquare} size="lg"/></button>
  
  const getAbsoluteIndex = index => index + currentPage * PER_PAGE;

  const isDateRangeSelected = index => selectedIndexes.some(i => getAbsoluteIndex(index) === i);

  const selectDateRange = (doSelect, index) => {
    if (doSelect)
      cbSetSelectedIndexes(selectedIndexes.concat(getAbsoluteIndex(index)));
    else
      cbSetSelectedIndexes(selectedIndexes.filter(i => i !== getAbsoluteIndex(index)));
  };

  const getHeadersArray = () => [getSelectAllButton()].concat(Object.values(t("labels.regularityTableHeaders")));

  const formatDataMatrix = () => {
    let matrix = [];

    dateRanges.forEach((dateRange, index) => {
      matrix.push([
          <button
            onClick={() => selectDateRange(isDateRangeSelected(index) ? false : true, index)}
          >
            {isDateRangeSelected(index) ? <FontAwesomeIcon icon={faCheckCircle} color="green" /> : <FontAwesomeIcon icon={faCircle} />}
          </button>,
          `${stringDateFormat(dateRange.fromDate)} - ${stringDateFormat(dateRange.toDate)}`,
          dateRange.regularityType ? t(dateRange.regularityType, { ns: "common/regularityTypes" }) : t("notAvailable", { ns: "common/common" }),
          getDateRangeRegularityDescription(dateRange)
      ]);
    });

    return matrix;
  };
  
  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
          color="teal"
          title={t("stepTitles", { ns: "pages/products/create" }).regularity}
          description={t("labels.stepDescription")}
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
        />
      )}
    </>
  );
};

export default RegularityTable;