import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import Table from "../../ui/Table";
import Spinner from "../../ui/Spinner";

const ParticipantsDataTable = ({ participantsData, cbRemoveParticipant }) => {
  const { t, ready: translationsReady } = useTranslation(["components/orders/create/participantsData", "common/common"]);

  const getHeadersArray = () => Object.values(t("participantsDataTableHeaders"));

  const formatDataMatrix = () => participantsData.map(p => {
    const participantDataArray = [
      p.clientType,
      p.identification,
      p.fullName,
      p.email || t("notAvailable", { ns: "common/common" }),
      p.phone || t("notAvailable", { ns: "common/common" }),
    ];

    return !cbRemoveParticipant ? participantDataArray : participantDataArray.concat(
      <>
        <button onClick={() => cbRemoveParticipant(p.index)}><FontAwesomeIcon icon={faTimesCircle} color="red"/></button>
      </>
    );
  });

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <Table
          headersArray={cbRemoveParticipant ? getHeadersArray() : getHeadersArray().slice(0, -1)}
          dataMatrix={formatDataMatrix()}
        /> 
      )}
    </>
  );
};

export default ParticipantsDataTable;