import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { B2CRoutesStrings } from '@src/helpers/stringHelpers';
import { useTranslation } from 'react-i18next';
import Spinner from '@src/components/ui/Spinner';
import AuthenticationService from '@src/services/AuthenticationService';
import { createNotification, NotificationTypes } from '@src/helpers/notificationHelpers';
import { Field, Form, Formik } from 'formik';
import * as Yup from "yup";
import SmallSpinner from "react-spinkit";

import "@src/assets/styles/b2c/pages/forgot_password/forgot-password.css";
import "@src/assets/styles/b2c/pages/forgot_password/globals.css";
import "@src/assets/styles/b2c/pages/forgot_password/styleguide.css";

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email("common/form:validationErrors.empty.email").required("common/form:validationErrors.empty.email")
});

const ForgotPasswordInitialValues = {
  email: "",
};

const ForgotPasswordPage = () => {
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation(["pages/users/forgotPassword", "common/form", "common/navigation"]);
  const history = useHistory();

  const handleSubmit = async ({ email }, _) => {
    setW18ApiResponse(b => !b);

    try {
      await AuthenticationService.forgotPassword(email);
      createNotification(NotificationTypes.success, t("requestSentMessage"));
      history.push(B2CRoutesStrings.loginPath);
    } catch (err) {
      console.log(err);
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  return (
    <div className="container-center-horizontal">
      <div className="restablecer-contrasena-1 screen">
        <div className="overlap-group">
          {!translationsReady ? <Spinner /> : (
            <Formik
              initialValues={ForgotPasswordInitialValues}
              validationSchema={ForgotPasswordSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form>
                  <div className="frame-13">
                    <div className="frame-9">
                      <h1 className="restablecer-contrasea montserrat-bold-shark-36px">{t("title")}</h1>
                      <div className="grid grid-cols-1">
                        <div className={`frame-1837 ${errors.email && touched.email ? "border-2 border-red-500" : ""}`}>
                          <Field
                            className="email montserrat-medium-gunsmoke-12px"
                            name="email"
                            placeholder={t("labels.email", { ns: "common/form" })}
                          />
                        </div>
                        {errors.email && touched.email &&
                          <p className="px-[30px] mt-2 text-red-500 font-bold">{t(errors.email)}</p>
                        }
                      </div>
                    </div>
                    <div className="frame-1831">
                      <Link to={B2CRoutesStrings.loginPath}>
                        <div className="frame-8">
                          {/* eslint-disable-next-line */}
                          <img
                            className="group-1835"
                            src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bac2755fed1ac5a21a2dc5/img/group-1835@2x.svg"
                          />
                          <div className="volver montserrat-semi-bold-white-16px">{t("back", { ns: "common/navigation" })}</div>
                        </div>
                      </Link>
                      <button type="submit">
                        <div className="frame-8-1">
                          {w18ApiResponse ? (
                            <div className="w-[19px] h-[19px] flex">
                              <SmallSpinner name="circle" color="white" fadeIn="none" className="m-auto" />
                            </div>
                          ) : (
                            <>
                              {/* eslint-disable-next-line */}
                              <img
                                className="icon-paper_plane"
                                src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62babcf788aa0b91b68cf475/img/group-2@2x.svg"
                              />
                            </>
                          )}
                          <div className="enviar montserrat-semi-bold-white-16px">{t("buttons.submit", { ns: "common/form" })}</div>
                        </div>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
}
 
export default ForgotPasswordPage;