import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import FormikSelect from "@src/components//forms/FormikSelect";
import Spinner from "@src/components//ui/Spinner";
import { isProfessional } from "@src/helpers/userHelpers";
import ProfessionalSelector from "@src/components/credits/ProfessionalSelector";
import CreditsValidatorsSelector from "@src/components/credits/CreditsValidatorsSelector";
import { getOptionsForCreditStateSelect } from "@src/components/credits/CreateCredit";


const FormInitialValues = {
  professionalId: "",
  validatorsId: "",
  serviceProvider: "",
};


const FilterCreditsForm = ({
  className,
  color,
  cbSetRequested,
  cbSetAutorized,
  cbSetState,
}) => {
  const { t, ready: translationsReady } = useTranslation([
    "components/services/create/basicInfoForm",
    "components/credits/filterForm",
    "common/filterForm",
    "common/creditState",
    "common/form"
  ]);
  const DEFAULT_COLOR = "light-blue";

  const getOptionsForSelect = () =>
    [ {
      value: "",
      label: t("any", { ns: "common/common" }),
    } ].concat(getOptionsForCreditStateSelect());

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <Card className={className}>
          <CardHeader
            color={color ?? DEFAULT_COLOR}
            variant="gradient"
            className="p-5"
          >
            <Typography variant="h5" color="white">
              {t("description", { ns: "common/filterForm" })}
            </Typography>
          </CardHeader>
          <CardBody>
            <Formik initialValues={FormInitialValues}>
              {({ handleChange }) => (
                <Form className="grid sm:grid-flow-row lg:grid-flow-col  lg:space-x-6 sm:items-start lg:items-center w-full">
                {!isProfessional() && ( 
                    <ProfessionalSelector
                      label={t("labels.professional")}
                      placeholder={t("placeholders.selectOne", {
                        ns: "common/form",
                      })}
                      name="professionalId"
                      loadOptionsErrorMsg={t(
                        "messages.errorRetrievingProfessionals"
                      )}
                      noOptionsMessage={t("placeholders.noOptionsInSelect", {
                        ns: "common/form",
                      })}
                      onChange={(optionValue) =>
                        cbSetRequested(optionValue)
                      }
                    />
                  )}
                  {!isProfessional() && (<CreditsValidatorsSelector
                    label={t("labels.validators", { ns: "components/credits/filterForm" })}
                    placeholder={t("placeholders.selectOne", {
                      ns: "common/form",
                    })}
                    name="validatorsId"
                    loadOptionsErrorMsg={t(
                      "messages.errorRetrievingServiceCategories"
                    )}
                    onChange={(optionValue) =>
                      cbSetAutorized(optionValue)
                    }
                  />)}
                  <FormikSelect
                    label={t("labels.creditState", { ns: "components/credits/filterForm" })}
                    name="creditState"
                    options={getOptionsForSelect()}
                    onChange={optionValue => cbSetState(optionValue)}
                  />
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      )}
    </>
  );
};
export default FilterCreditsForm;
