import axios from "@src/helpers/axiosInstance";
import {getJWT} from "@src/helpers/localStorageHelpers";
import {APIEndpointsStrings} from "@src/helpers/stringHelpers";

class BannerService {
  static async findAll() {
    const findAllResponse = await axios.get(APIEndpointsStrings.bannersPath, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });

    return findAllResponse.data.map(banner => this.formatReceivedBanner(banner));
  }

  static show = async (id) => {
    const endpoint = APIEndpointsStrings.bannerPath
      .replace(APIEndpointsStrings.idLocators.id, id);
    
    const res = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });

    return this.formatReceivedBanner(res.data);
  };

  static indexFilters= async (description,enabled) => {
    let requestPath = APIEndpointsStrings.bannersPath + `?`;
    if(description){
      requestPath += `q[description_cont]=${description}&`;
    }
    if(enabled !== null){
      requestPath += `q[enabled_eq]=${enabled}&`;
    }

    const request = await axios.get(requestPath);

    return request.data.map(banner => this.formatReceivedBanner(banner))
  };

  static create(picture,description, enabled) {
    const formData = new FormData();
    formData.append("picture", picture);
    formData.append("description",description)
    formData.append("enabled", enabled);

    return axios.post(APIEndpointsStrings.bannersPath, formData, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });
  }

  static update(bannerId, picture,description, enabled) {

    const formData = new FormData();
    if (picture) {
      formData.append("picture", picture);
    }
    if (description) {
      formData.append("description", description);
    }
    formData.append("enabled", enabled);

    return axios.patch(APIEndpointsStrings.bannerPath.replace(APIEndpointsStrings.idLocators.id, bannerId), formData, {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });
  }

  static destroy(bannerId) {
    return axios.delete(APIEndpointsStrings.bannerPath.replace(APIEndpointsStrings.idLocators.id, bannerId), {
      headers: {
        Authorization: `Bearer ${getJWT()}`
      }
    });
  }

  static formatReceivedBanner(banner) {
    return {
      ...banner,
      description: banner.description,
      createdAt: banner.created_at ? new Date(banner.created_at) : null,
      pictureUrl: banner.picture_url
    };
  }
}

export default BannerService;