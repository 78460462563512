import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import { Form, Formik } from "formik";
import FormikTextInput from "@src/components/forms/FormikTextInput";
import BasicFormTemplate, { FooterType } from "@src/components/forms/BasicFormTemplate";

const CompanyDataInitialValues = {
  cif: "",
  fiscalName: "",
  province: "",
  commercialName: "",
  zipCode: "",
  address: "",
  country: "",
  managerName: "",
  managerPosition: "",
  managerLandlineNumber: "",
  managerPhoneNumber: "",
};

const CompanyDataForm = ({ title, initialValues, showLoadingSpinner, submitCallback, backCallback }) => {
  const { t, ready: translationsReady } = useTranslation(["components/users/companyDataForm", "common/form"]);
  const submitDisabled = (values) =>
    !(
      values.cif &&
      values.fiscalName &&
      values.province &&
      values.commercialName &&
      values.address &&
      values.managerName &&
      values.managerLandlineNumber
    );

  return !translationsReady ? (
    <Spinner />
  ) : (
    <Formik initialValues={initialValues ? initialValues : CompanyDataInitialValues} onSubmit={submitCallback}>
      {({ values }) => (
        <Form>
          <BasicFormTemplate
            className="mt-8"
            formTitle={title}
            showLoadingSpinner={showLoadingSpinner}
            footerType={FooterType.BACK_SUBMIT_BUTTONS}
            backCallback={backCallback}
            disableSubmitButton={submitDisabled(values)}
          >
            <div className="flex flex-col items-center w-full">
              <p className="text-red-400 font-bold">{t("requiredFieldDisclaimer", { ns: "common/form" })}</p>
              <div className="grid grid-cols-2 items-center w-full mt-4 space-y-8">
                <FormikTextInput name="cif" label={t("labels.cif") + " *"} className="mr-4" />
                <div />
                <FormikTextInput name="fiscalName" label={t("labels.fiscalName") + " *"} className="mr-4" />
                <FormikTextInput name="province" label={t("labels.province") + " *"} />
                <FormikTextInput name="commercialName" label={t("labels.commercialName") + " *"} className="mr-4" />
                <FormikTextInput name="zipCode" label={t("labels.zipCode")} />
                <FormikTextInput name="address" label={t("labels.address") + " *"} className="mr-4" />
                <FormikTextInput name="country" label={t("labels.country")} />
                <FormikTextInput name="managerName" label={t("labels.managerName") + " *"} className="mr-4" />
                <FormikTextInput name="managerPosition" label={t("labels.managerPosition")} />
                <FormikTextInput
                  name="managerLandlineNumber"
                  label={t("labels.managerLandlineNumber") + " *"}
                  className="mr-4"
                />
                <FormikTextInput name="managerPhoneNumber" label={t("labels.managerPhoneNumber")} />
              </div>
            </div>
          </BasicFormTemplate>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyDataForm;
