import LocationService from "@src/services/locationService";
import { useFormikContext } from "formik";
import React from "react";

import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import FormSearchInput from "@src/components/forms/FormSearchInput";

const FormLocationSearch = ({ label, placeholder, name, loadOptionsErrorMsg, defaultLocations, ...props }) => {
  const { setFieldValue } = useFormikContext();

  const loadOptions = inputValue =>{
    if(!isNaN(parseInt(inputValue))){
     return LocationService.getLocationByZipCode(inputValue?inputValue:'0')
      .then(res => res.data)
      .catch(err => {
        createNotification(NotificationTypes.error, loadOptionsErrorMsg);
      });
    }else{
      return LocationService.getLocationByName(inputValue)
        .then(res => res.data)
        .catch(err => {
          createNotification(NotificationTypes.error, loadOptionsErrorMsg);
        });

    }
  } 

  const getOptionValue = option => option.id;
  const getOptionLabel = option => option.name+" ("+option.zip_code+")";  
  
  const handleChange = options => setFieldValue(name, options.map(({ id }) => id));

  return (
    <FormSearchInput
      label={label}
      placeholder={placeholder}
      name={name}
      defaultValue={defaultLocations}
      defaultOptions={false}
      isMulti={true}
      cbLoadOptions={loadOptions}
      cbGetOptionLabel={getOptionLabel}
      cbGetOptionValue={getOptionValue}
      cbHandleChange={handleChange}
      {...props}
    />    
  );
};

export default FormLocationSearch;