import {Dialog } from "@material-tailwind/react";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import BasicFormTemplate, {
  FooterType,
} from "@src/components/forms/BasicFormTemplate";
import Spinner from "@src/components/ui/Spinner";
import FormikTextArea from "@src/components/forms/FormikTextArea";
import BooleanResponseDialog from "@src/components/ui/BooleanResponseDialog";

const CancelProductFormSchema = Yup.object().shape({
  reason: Yup.string().required(
    "common/form:validationErrors.empty.description"
  ),
});

const CancelProductFormInitialValues = {
  reason: "",
};

const CancelProductDialog = ({
  open,
  handler: cbFinalizeDialog,
  handleSubmit,
  message,
  title
}) => {
  const [validate, setValidate] = useState();
  const { t, ready: translationsReady } = useTranslation([
    "pages/products/list",
    "common/form",
  ]);

  useEffect(() => {
    return () => {
      setValidate();
    };
  }, [open]);

  return (
    <Dialog open={open}>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={CancelProductFormInitialValues}
          validationSchema={CancelProductFormSchema}
          onSubmit={sumbit => setValidate(sumbit)}
        >
          <Form>
            <BasicFormTemplate
              className="mt-8"
              formTitle={title}
              footerType={FooterType.BACK_SUBMIT_BUTTONS}
              backCallback={cbFinalizeDialog}
            >
              <p>{message}</p>
              <div className="my-6 px-4">
                <FormikTextArea name="reason" label={t("reasonInput.label")} />
              </div>
            </BasicFormTemplate>
          </Form>
        </Formik>
      )}
      <BooleanResponseDialog
            open={!!validate}
            noCallback={() => setValidate()}
            yesCallback={() => handleSubmit(validate)}
          >
            {message}
          </BooleanResponseDialog>
    </Dialog>
  );
};

export default CancelProductDialog;
