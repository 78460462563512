import React from "react";
import { isLoggedIn } from "@src/helpers/sessionHelpers";
import { B2CRoutesStrings } from "@src/helpers/stringHelpers";
import Spinner from "@src/components/ui/Spinner";
import { useTranslation } from "react-i18next";
import { getCurrentUserData } from "@src/helpers/localStorageHelpers";
import { Redirect } from "react-router-dom";
import { useEffect } from "react";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { useQuery } from "@src/helpers/hooks/useQuery";

const HomePage = () => {
  const query = useQuery();
  const impersonationExpired = query.get("impersonationExpired");
  const { t, ready : translationsReady } = useTranslation(["pages/home", "common/userTypes"]);

  useEffect(() => {
    if (translationsReady && impersonationExpired) {
      createNotification(NotificationTypes.info, t("messages.impersonationExpired"));
    }
    // eslint-disable-next-line
  }, [translationsReady]);

  return (
    <div className="container mx-auto">
      {!translationsReady ? <Spinner /> : (
        <>
          {!isLoggedIn() ?
            <Redirect to={B2CRoutesStrings.loginPath} />
            :
            <div className="container mx-auto max-w-xl">
              <p className="text-center">
                {t("messages.loggedIn", { userEmail: getCurrentUserData().email, userType: t(getCurrentUserData().type, { ns: "common/userTypes" }).toLowerCase() })}
              </p>
            </div>
          }
        </>
      )}
    </div>
  );
};

export default HomePage;