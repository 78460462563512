import { useFormikContext } from "formik";
import React from "react";
import { getCategoriesByName } from "@src/helpers/categoryHelpers";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import FormSearchInput from "@src/components/forms/FormSearchInput";

const FormCategorySearch = ({ label, placeholder, name, loadOptionsErrorMsg, defaultCategories, ...props }) => {
  const { setFieldValue } = useFormikContext();

  const loadOptions = inputValue => 
    getCategoriesByName(inputValue)
      .then(res => res.data)
      .catch(err => {
        createNotification(NotificationTypes.error, loadOptionsErrorMsg);
      });

  const getOptionValue = option => option.id;
  const getOptionLabel = option => option.name;  
  
  const handleChange = options => setFieldValue(name, options.map(({ id }) => id));

  return (
    <FormSearchInput
      label={label}
      placeholder={placeholder}
      name={name}
      defaultValue={defaultCategories}
      defaultOptions={true}
      isMulti={true}
      cbLoadOptions={loadOptions}
      cbGetOptionLabel={getOptionLabel}
      cbGetOptionValue={getOptionValue}
      cbHandleChange={handleChange}
      {...props}
    />    
  );
};

export default FormCategorySearch;