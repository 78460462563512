import axios from "@src/helpers/axiosInstance";
import { getJWT } from "@src/helpers/localStorageHelpers";
import { APIEndpointsStrings } from "@src/helpers/stringHelpers";

export const getServiceCategoriesByName = name => {
  return axios.get(`${APIEndpointsStrings.serviceCategoriesPath}?q[name_cont]=${name}`, {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });
};

export const getServiceCategory = id => {
  return axios.get(APIEndpointsStrings.serviceCategoryPath.replace(APIEndpointsStrings.idLocators.id, id), {
    headers: {
      Authorization: `Bearer ${getJWT()}`
    }
  });
};