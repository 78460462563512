export const LocalStorageKeys = {
  jwt: "jwt",
  userId: "userId",
  userType: "userType",
  userEmail: "userEmail",
  userDisabled: "userDisabled",
  professionalValidated: "professionalValidated",
  impersonator: "impersonator",
  productToOrder: "productToOrder",
};

export const storeJWT = (jwt) => localStorage.setItem(LocalStorageKeys.jwt, jwt);

export const storeCurrentUser = (user) => {
  localStorage.setItem(LocalStorageKeys.userId, user.id);
  localStorage.setItem(LocalStorageKeys.userType, user.type);
  localStorage.setItem(LocalStorageKeys.userEmail, user.email);
  localStorage.setItem(LocalStorageKeys.userDisabled, user.disabledAt?.toISOString());
  localStorage.setItem(LocalStorageKeys.professionalValidated, user.validatedAt?.toISOString());
};

export const storeImpersonatedUser = (impersonatedUser) => {
  const impersonator = getCurrentUserData();
  localStorage.setItem(LocalStorageKeys.impersonator, JSON.stringify(impersonator));

  storeCurrentUser(impersonatedUser);
};

export const getJWT = () => localStorage.getItem(LocalStorageKeys.jwt);

export const getCurrentUserData = () => ({
  id: parseInt(localStorage.getItem(LocalStorageKeys.userId)),
  type: localStorage.getItem(LocalStorageKeys.userType),
  email: localStorage.getItem(LocalStorageKeys.userEmail),
  isDisabled:
    localStorage.getItem(LocalStorageKeys.userDisabled) !== "null" &&
    localStorage.getItem(LocalStorageKeys.userDisabled) !== "undefined",
  isValidated:
    localStorage.getItem(LocalStorageKeys.professionalValidated) !== "null" &&
    localStorage.getItem(LocalStorageKeys.professionalValidated) !== "undefined",
});

export const getImpersonatorData = () => {
  const impersonatorString = localStorage.getItem(LocalStorageKeys.impersonator),
    impersonator = JSON.parse(impersonatorString);

  return impersonator;
};

export const clearSession = (shouldClearImpersonator) => {
  if (shouldClearImpersonator) localStorage.removeItem(LocalStorageKeys.impersonator);

  localStorage.removeItem(LocalStorageKeys.jwt);
  localStorage.removeItem(LocalStorageKeys.userId);
  localStorage.removeItem(LocalStorageKeys.userType);
  localStorage.removeItem(LocalStorageKeys.userEmail);
  localStorage.removeItem(LocalStorageKeys.userDisabled);
  localStorage.removeItem(LocalStorageKeys.professionalValidated);
  localStorage.removeItem(LocalStorageKeys.productToOrder);
};

export const stopImpersonationSession = () => {
  localStorage.removeItem(LocalStorageKeys.userId);
  localStorage.removeItem(LocalStorageKeys.userType);
  localStorage.removeItem(LocalStorageKeys.userEmail);
  localStorage.removeItem(LocalStorageKeys.userDisabled);
  localStorage.removeItem(LocalStorageKeys.professionalValidated);
  localStorage.removeItem(LocalStorageKeys.productToOrder);

  const impersonator = getImpersonatorData();
  localStorage.removeItem(LocalStorageKeys.impersonator);
  storeCurrentUser(impersonator);
};

export const storeProductToOrder = (productConfigurations) => {
  const base64Product = btoa(JSON.stringify(productConfigurations));
  localStorage.setItem(LocalStorageKeys.productToOrder, base64Product);
};

export const decodeProductToOrderFromStorage = () => {
  const base64Product = localStorage.getItem(LocalStorageKeys.productToOrder);
  const decodedBase64Product = atob(base64Product);
  return JSON.parse(decodedBase64Product);
};
