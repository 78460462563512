import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { useQuery } from "@src/helpers/hooks/useQuery";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { B2CRoutesStrings, CommonRoutesStrings } from "@src/helpers/stringHelpers";
import Spinner from "@src/components/ui/Spinner";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import AuthenticationService from "@src/services/AuthenticationService";

const ConfirmEmailPage = () => {
  const query = useQuery();
  const confirmationToken = query.get("confirmation_token");
  const [w18ApiResponse, setW18ApiResponse] = useState(true);
  const { t, ready: translationsReady } = useTranslation("pages/users/confirmEmail");
  const history = useHistory();

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        await AuthenticationService.confirmUserEmail(confirmationToken);
        createNotification(NotificationTypes.success, t("messages.okConfirmEmail"));
      } catch (err) {
        createNotification(NotificationTypes.error, t("messages.genericRequestError"));
        history.push(CommonRoutesStrings.homePath);
      } finally {
        setW18ApiResponse(false);
      }
    };

    translationsReady && confirmEmail();
    // eslint-disable-next-line
  }, [translationsReady]);

  return(
    <>
      {w18ApiResponse || !translationsReady ? <Spinner /> : (
        <Redirect to={B2CRoutesStrings.loginPath} />
      )}
    </>    
  );
};

export default ConfirmEmailPage;