import { Card, Dialog } from "@material-tailwind/react";
import BooleanResponseDialog from "@src/components/ui/BooleanResponseDialog";
import Button from "@src/components/ui/buttons/Button";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { emptyArray } from "@src/helpers/arrayHelpers";
import PaginateData from "@src/components/ui/PaginateData";
import Spinner from "@src/components/ui/Spinner";
import AddDeleteSelectAllButtons from "@src/components/products/product_wizard/AddDeleteSelectAllButtons";
import BackNextSubmitButtons from "@src/components/products/product_wizard/BackNextSubmitButtons";
import EventsTable from "@src/components/products/product_wizard/EventsTable";
import HourSelectionDetails from "@src/components/products/product_wizard/HourSelectionDetails";
import PriceSeletionDetails from "@src/components/products/product_wizard/PriceSelectionDetails";
import PricesSelector from "@src/components/products/product_wizard/PricesSelector";

const PricesConfiguration = ({ currentStep, numberSteps, initialValues, callbackBackClicked, callbackNextClicked }) => {
  const [showPricesSelectorDialog, setShowPricesSelectorModal] = useState(false);
  const [showPriceTableDialog, setShowPriceTableModal] = useState(false);
  const [showPriceDeletionDialog, setShowPriceDeletionDialog] = useState(false);
  const [selectedRowForDetails, setSelectedRowForDetails] = useState();
  const [selectedRowsForPriceSelection, setSelectedRowsForPriceSelection] = useState([]);
  const [indexDateRangeToEdit, setIndexDateRangeToEdit] = useState();
  const [dateRanges, setDateRanges] = useState(initialValues);
  const { t, ready: translationsReady } = useTranslation([
    "components/products/create/prices",
    "pages/products/create",
    "common/navigation",
    "common/common"
  ]);

  const toggleSelectionAllRows = () => {
    if (selectedRowsForPriceSelection.length === dateRanges.length) {
      setSelectedRowsForPriceSelection([]);
    } else {
      setSelectedRowsForPriceSelection([...Array(dateRanges.length).keys()]);
    }
  }

  const arePricesSetForDateRange = dateRange => dateRange.prices != null;

  const openEditPricesModal = dateRangeIndex => {
    setIndexDateRangeToEdit(dateRangeIndex);
    setShowPricesSelectorModal(true);
  };

  const closePricesSelector = () => {
    setIndexDateRangeToEdit(null);
    setShowPricesSelectorModal(false);
  };

  const deletePricesOfSelectedIndexes = () => {
    setDateRanges(dateRanges.map((dateRange, index) => {
      if (selectedRowsForPriceSelection.some(i => i === index)) {
        return {
          ...dateRange,
          prices: null
        }; 
      } else
        return dateRange;
    }));

    setSelectedRowsForPriceSelection([]);
    setShowPriceDeletionDialog(false);
  };

  const showPriceTable = index => {
    setSelectedRowForDetails(index);
    setShowPriceTableModal(true);
  };

  const handlePricesSelection = data => {
    setDateRanges(dateRanges.map((dateRange, index) => {
      if (selectedRowsForPriceSelection.some(i => i === index)) {
        return {
          ...dateRange,
          prices: data
        }; 
      } else if (index === indexDateRangeToEdit) {
        return {
          ...dateRanges.at(indexDateRangeToEdit),
          prices: data
        };
      } else
        return dateRange;
    }));

    setShowPricesSelectorModal(false);
    setIndexDateRangeToEdit(null);
    setSelectedRowsForPriceSelection([]);
  };

  const getMinQuotaForSelectedDateRanges = () => {
    let minQuota = Number.MAX_SAFE_INTEGER;
    selectedRowsForPriceSelection.forEach(index => {
      const currentQuota = dateRanges.at(index).quota;
      if (minQuota > currentQuota)
        minQuota = currentQuota;      
    });
    return minQuota;
  };

  const getPendingQuotaForDateRangeToEdit = () => {
    const dateRangeToEdit = dateRanges.at(indexDateRangeToEdit);
    let pendingQuota = dateRangeToEdit.quota;
    dateRangeToEdit.prices.forEach(price => pendingQuota -= price.quota * price.type_client.quantity);
    return pendingQuota;
  };

  const closeDeletePricesDialog = () => setShowPriceDeletionDialog(false);
  const closeShowPriceTableDialog = () => setShowPriceTableModal(false);

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="grid grid-cols-1">
          <Card className="place-self-center px-4 py-2">
            <AddDeleteSelectAllButtons
              addBtnDisabled={emptyArray(selectedRowsForPriceSelection)}
              deleteBtnDisabled={emptyArray(selectedRowsForPriceSelection) || selectedRowsForPriceSelection.some(index => !arePricesSetForDateRange(dateRanges.at(index)))}
              cbAdd={() => setShowPricesSelectorModal(true)}
              cbDelete={closeDeletePricesDialog}
              cbSelectAll={toggleSelectionAllRows}
            />
          </Card>
          <div className="grid grid-cols-1 place-items-center">
            {dateRanges &&
              <PaginateData
                data={dateRanges}
              >
                <EventsTable
                  numElements={dateRanges.length}
                  selectedRowsForPriceSelection={selectedRowsForPriceSelection}
                  cbSetSelectedRowsForPriceSelection={setSelectedRowsForPriceSelection}
                  arePricesSetForEvent={arePricesSetForDateRange}
                  cbShowPriceTable={showPriceTable}
                  cbOpenEditPricesModal={openEditPricesModal}
                />
              </PaginateData>
            }
            
          </div>
          <Dialog
            open={showPricesSelectorDialog}
            handler={closePricesSelector}
            className="overflow-visible"
          >
            <PricesSelector
              cbSubmitPriceSelection={handlePricesSelection}
              cbCancelPriceSelector={closePricesSelector}
              minQuotaForSelectedDateRanges={indexDateRangeToEdit == null ? getMinQuotaForSelectedDateRanges() : getPendingQuotaForDateRangeToEdit()}
              priceSelectionsToModify={indexDateRangeToEdit == null ? null : dateRanges.at(indexDateRangeToEdit).prices}
            />
          </Dialog>
          <Dialog
            open={showPriceTableDialog}
            handler={closeShowPriceTableDialog}
          >
            <div className="px-4 py-2">
              <p className="text-xl font-bold">{t("labels.priceTableDescription")}</p>
              <HourSelectionDetails dateRange={dateRanges.at(selectedRowForDetails)} />
              <p className="text-lg font-bold mt-2">{t("labels.prices")}</p>
              <PriceSeletionDetails prices={dateRanges.at(selectedRowForDetails).prices}/>
              <div className="flex justify-center items-center mt-2">
                <Button
                  color="gray"
                  onClick={closeShowPriceTableDialog}
                >{t("close", { ns: "common/navigation" })}</Button>
              </div>
            </div> 
          </Dialog>
          <BooleanResponseDialog
            open={showPriceDeletionDialog}
            yesCallback={deletePricesOfSelectedIndexes}
            noCallback={closeDeletePricesDialog}
          >{t("labels.deletePricesMessage")}</BooleanResponseDialog>
          <BackNextSubmitButtons
            currentStep={currentStep}
            numberSteps={numberSteps}
            nextSubmitButtonDisabled={dateRanges.some(event => !arePricesSetForDateRange(event))}
            cbBackButton={callbackBackClicked}
            cbNextButton={() => callbackNextClicked(dateRanges)}
          />
        </div>
      )}
    </>
  );
};

export default PricesConfiguration;