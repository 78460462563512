import React, { useState } from "react";
import { emptyArray } from "@src/helpers/arrayHelpers";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import ProductService from "@src/services/products/productService";
import Button from "@src/components/ui/Button";
import DateSearchForm from "@src/components/products/joker/DateSearchForm";
import { formatHour } from "@src/helpers/localeHelpers";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";

const ProductTimesliceSelector = ({ productDateListSearchResults, selectedDate, cbSetProductDateListSearchResults, cbSetSelectedDate, cbHandleTimesliceSelection }) => {
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation(["components/products/joker/productTimesliceSelector", "common/list"]);

  const performProductSearchWithDates = async (date) => {
    setW18ApiResponse(b => !b);
    cbSetProductDateListSearchResults();

    try {
      cbSetSelectedDate(date);
      const searchResults = await ProductService.searchEventsForGiveDate(date);
      cbSetProductDateListSearchResults(searchResults);
    } catch (_) {
      createNotification(NotificationTypes.error, t("dateSearch.errorRetrievingProducts"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  const handleTimesliceSelection = (productDate, timeslice) => {
    cbHandleTimesliceSelection({
      productDate: {
        ...productDate,
        timeSlices: null,
      },
      timeslice,
    });
  }

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <>
          <DateSearchForm
            selectedDate={selectedDate}
            showLoadingSpinnerSubmitButton={w18ApiResponse}
            cbPerformSearch={performProductSearchWithDates}
          />
          {productDateListSearchResults &&
            <>
              {emptyArray(productDateListSearchResults) ? (
                <p>{t("noEventsMsg")}</p>
              ) : (
                <>
                  <p className="mb-2">{t("description")}</p>
                  <div className="container overflow-auto flex" style={{ maxHeight: "50vh" }}>
                    {productDateListSearchResults.map(productDate =>
                      <div
                        key={productDate.product.id}
                        className="flex flex-col mx-2 p-1"
                        style={{ minWidth: "288px", maxWidth: "288px" }}
                      >
                        <p className="text-center bg-white rounded-md border">{productDate.product.name}</p>
                        {productDate.timeSlices.map(ts =>
                          <div
                            key={ts.id}
                            className="flex border rounded-md my-1 p-1 bg-white">
                            <div className="mr-auto">
                              <p>{formatHour(ts.beginsAt)} - {ts.language}</p>
                              <p>{t("timesliceCard.duration", { minutes: ts.duration })}</p>
                              <p>{t("timesliceCard.sold", { sold: ts.quota - ts.available, quota: ts.quota })}</p>
                              {productDate.product.serviceCategories?.length > 0 &&
                                <p className="text-sm">{t("timesliceCard.requiresServices")}</p>
                              }
                            </div>
                            <Button
                              className="ml-auto bg-green-500 font-bold text-white"
                              onClick={() => handleTimesliceSelection(productDate, ts)}
                              disabled={!(ts.quota - ts.available > 0 && productDate.product.serviceCategories?.length > 0)}
                            >{t("select", { ns: "common/list" })}</Button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          }
        </>
      )}
    </>
  );
};

export default ProductTimesliceSelector;