import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { patchServiceCategory } from "@src/helpers/categoryHelpers";
import { createNotification, delayedRedirect, NotificationTypes } from "@src/helpers/notificationHelpers";
import { DefinedRoutesStrings } from "@src/helpers/stringHelpers";
import ErrorDisplayBox from "@src/components/ui/ErrorDisplayBox";
import ServiceCategoryForm from "@src/components/products/services/categories/ServiceCategoryForm";
import Spinner from "@src/components/ui/Spinner";
import { Dialog } from "@material-tailwind/react";

const EditServiceCategory = ({ categoryData, backCallback,open }) => {
  const [serviceSavedBD, setServiceSavedBD] = useState(false);
  const [errors, setErrors] = useState();
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation("components/services/categories/edit");

  const submitData = async ({ name }, _) => {
    setW18ApiResponse(b => !b);
    
    try {
      await patchServiceCategory(categoryData.id, name);
      setServiceSavedBD(true);
      setErrors(); // clear errorbox if present
      createNotification(NotificationTypes.success, t("okSubmissionResponse"));
      delayedRedirect(DefinedRoutesStrings.listServiceCategories);
    } catch (error) {
      createNotification(NotificationTypes.error, t("koSubmissionResponse"));
      if (error && error.response && error.response.data && error.response.data.error) {
        const unformattedErrors = error.response.data.error;
        const formattedErrors = Object.entries(unformattedErrors).reduce((arr, e) => {
          const [errorKey, errorValues] = e;
          return arr.concat(errorValues.map(err => errorKey + " " + err));
        }, []);

        setErrors(formattedErrors);
      }
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <Dialog
          open={open}
          handler={backCallback}
        >
          {errors &&
            <ErrorDisplayBox
              boxDescription={t("errorBoxDescription")}
              errors={errors}
            />
          }
          <ServiceCategoryForm
            initialValues={categoryData}
            disableModification={serviceSavedBD}
            handleSubmit={submitData}
            backCallback={backCallback}
            showLoadingSpinnerSubmitButton={w18ApiResponse}
            title = {t("title")}
          />
        </Dialog>
      )}
    </>
  );
};

export default EditServiceCategory;