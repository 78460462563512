import { Radio } from "@material-tailwind/react";
import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";

const BooleanRadioButtons = ({ inputName, cbSetState, defaultValue }) => {
  const { t, ready: translationsReady } = useTranslation("common/booleanResponse");

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="grid grid-cols-1 my-2">
          <Radio
            name={inputName ? inputName : "boolean-radio-buttons"}
            onChange={() => cbSetState(true)}
            defaultChecked={defaultValue === true}
            label={t("yes")}
          />
          <Radio
            name={inputName ? inputName : "boolean-radio-buttons"}
            onChange={() => cbSetState(false)}
            defaultChecked={defaultValue === false}
            label={t("no")}
          />
        </div>
      )}
    </>
  );
};

export default BooleanRadioButtons;