import { useField } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FormikTextInput = ({ label, className, showLabelOutsideInput, hideErrorMessage, ...props }) => {
  const [field, meta] = useField(props);
  const { t } = useTranslation();

  const inputHasErrors = meta.touched && meta.error;

  return (
    <div className={`grid grid-cols-1 w-full ${className ?? ""}`}>
      {showLabelOutsideInput &&
        <label className="font-bold text-lg">{label}</label>
      }
      <div className={`flex content-center rounded-[29px] bg-gray-200 h-10 px-8 w-full ${inputHasErrors ? "border-2 border-red-500" : ""}`}>
        <input
          className="bg-inherit tracking-normal w-full font-medium text-xs not-italic text-[#848484] font-['Montserrat']"
          placeholder={!showLabelOutsideInput ? label : undefined}
          {...field}
          {...props}
        />
      </div>
      {inputHasErrors && !hideErrorMessage &&
        <p className="px-[30px] mt-2 text-red-500 font-bold">{t(meta.error)}</p>
      }
    </div>
  );
}
 
export default FormikTextInput;