import React from "react";
import {
  APIEndpointsStrings,
  CommonRoutesStrings
} from "@src/helpers/stringHelpers";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import { isThirdPartyProduct } from "@src/helpers/productHelpers";
import {
  isProvider
} from "@src/helpers/userHelpers";
import TableCard from "@src/components/ui/TableCard";
import { Chip } from "@material-tailwind/react";
import IconLinkButton from "@src/components/ui/buttons/IconLinkButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGripHorizontal
} from "@fortawesome/free-solid-svg-icons";

export const getCategoriesJSX = (categoryList, product) => (
  <div className="grid grid-cols-2 place-items-center space-y-1">
    {categoryList?.length <= 4 ? (
      categoryList.map((category, index) => (
        <Chip
          key={index}
          color="amber"
          variant="gradient"
          value={category.name}
        />
      ))
    ) : (
      <IconLinkButton
        to={
          (isThirdPartyProduct(product.type)? CommonRoutesStrings.showThirdPartyProductPropertiesPath :CommonRoutesStrings.showOwnProductPropertiesPath).replace(
            APIEndpointsStrings.idLocators.id,
            product.id
          )
        }
      >
        <FontAwesomeIcon icon={faGripHorizontal} />
      </IconLinkButton>
    )}
  </div>
);

const ListProductsTable = ({
  currentPageData: productList,
  title
}) => {
  const { t, ready: translationsReady } = useTranslation([
    "components/products/listTable",
    "pages/products/list",
  ]);

  // Ignore elements with index 2 and 4 (professionalEmail and highlight switch) if currentUser is ThirdPartyProvider
  const discardElems24 = (arr) =>
    arr.filter((_, index) => index !== 2 && index !== 4 && index !== 7);

  const getHeadersArray = () =>
    !isProvider()
      ? Object.values(t("headers")).filter((_, index) => index !== 4 && index !== 7)
      : discardElems24(Object.values(t("headers")));

  const formatDataMatrix = () =>
    productList.map((product) => {
      

      const dataRow = [
        product.id,
        product.name,
        product.professionalEmail,
        product.commission,
        getCategoriesJSX(product.categories, product),
        !product.serviceCategories
          ? null
          : getCategoriesJSX(product.serviceCategories, product),
      ];

      return !isProvider() ? dataRow : discardElems24(dataRow);
    });

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <TableCard
          title={title}
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

export default ListProductsTable;
