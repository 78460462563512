import React, { useEffect, useState } from "react";
import { clearSession } from "@src/helpers/localStorageHelpers";
import { createNotification, delayedRedirect, NotificationTypes } from "@src/helpers/notificationHelpers";
import { CommonRoutesStrings } from "@src/helpers/stringHelpers";
import Spinner from "@src/components/ui/Spinner";
import { useTranslation } from "react-i18next";
import AuthenticationService from "@src/services/AuthenticationService";

const LogoutPage = () => {
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation("pages/sessions/logout");

  useEffect(() => {
    const doLogout = async () => {
      setW18ApiResponse(b => !b);

      try {
        await AuthenticationService.logout();
        createNotification(NotificationTypes.success, t("messages.successLogout"));
        clearSession();
        delayedRedirect(CommonRoutesStrings.homePath);
      } catch (err) {
        console.log(err);
      } finally {
        setW18ApiResponse(b => !b);
      }
    };
    
    translationsReady && doLogout();
    // eslint-disable-next-line
  }, [translationsReady]);

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <>
          <p className="text-center">{t("messages.loadingLogout")}</p>
          {w18ApiResponse && <Spinner />}
        </>
      )}
    </>    
  );
};

export default LogoutPage;