import {
  Card,
  CardBody,
  CardHeader,
  Typography
} from '@material-tailwind/react';
import React from 'react';

const TableCard = ({
  title,
  description,
  headersArray,
  dataMatrix,
  color,
  isLastColumnForActions,
  timeSlices
}) => {
  const headerStyling = "px-2 align-middle border-b border-solid "
    + "border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left "
    + `${color ? `text-${color}-500` : "text-blue-500"}`,
    lastHeaderStyling = "text-center " + headerStyling;
  const rowCellStyling = "border-b border-gray-200 align-middle font-light "
   + "text-sm whitespace-nowrap px-2 py-4 text-left";
  const rowDisabledStyling = "bg-gray-300";

  const isLastColumnAndIsForActions = index =>
    index + 1 === headersArray.length && isLastColumnForActions;

  return (
    <Card className={`container mx-auto max-w-full ${title ? "mt-8" : "border bg-gray-50"}`}>
      {description ? (
          <CardHeader color={color ?? "light-blue"} className="p-4 space-y-2">
            <h2 className="text-white text-2xl">
              {title}
            </h2>
            <h6 className="uppercase text-gray-200 text-xs font-medium">
              {description}
            </h6>
          </CardHeader>
      ) : (
        <>
          {title ? (
            <CardHeader
              color={color ?? "light-blue"}
              variant="gradient"
              className="px-8 py-6"
            >
              <Typography
                variant="h5"
                color="white"
              >{title}</Typography>
            </CardHeader>
          ) : null}
        </>
      )}
      <CardBody>
        <div className="overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                {headersArray.map((header, i) =>
                  <th
                    key={i}
                    className={isLastColumnAndIsForActions(i)
                      ? lastHeaderStyling : headerStyling}
                  >{header}</th>
                )}
              </tr>
            </thead>
            <tbody>
              {dataMatrix.map((dataRow, rowIndex) =>
                <tr key={rowIndex} className={(timeSlices && timeSlices[rowIndex].disabled_at) && rowDisabledStyling }>
                  {dataRow.map((dataCell, cellIndex) =>
                    <td key={cellIndex} className={rowCellStyling}>
                      {isLastColumnAndIsForActions(cellIndex) ? (
                        <div className={"grid grid-flow-col place-items-center "
                          + "max-w-[16rem] mx-auto"}>
                          {dataCell}
                        </div>
                      ) : dataCell}
                    </td>
                  )}
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
};
 
export default TableCard;