import React from "react";
import { Route, Switch } from "react-router";
import { CommonRoutesStrings, DefinedRoutesStrings, ProductTypesStrings } from "@src/helpers/stringHelpers";
import CreateUserPage from "@src/pages/CreateUserPage";
import EditUserPage from "@src/pages/EditUserPage";
import HomePage from "@src/pages/HomePage";
import ListUsersPage from "@src/pages/ListUsersPage";
import LogoutPage from "@src/pages/LogoutPage";
import NotFoundPage from "@src/pages/NotFoundPage";
import ValidateProfessionalsPage from "@src/pages/ValidateProfessionalsPage";
import CreateProductPage from "@src/pages/CreateProductPage";
import PrivateRoute from "@src/components/PrivateRoute";
import ListProductsPage from "@src/pages/ListProductsPage";
import ValidateThirdPartyProductsPage from "@src/pages/ValidateThirdPartyProductsPage";
import EditProductPage from "@src/pages/EditProductPage";
import ShowProductPage from "@src/pages/ShowProductPage";
import ListPeopleSegmentsPage from "@src/pages/ListPeopleSegmentsPage";
import ListPriceTemplatesPage from "@src/pages/ListPriceTemplatesPage";
import ListProductCategoriesPage from "@src/pages/ListProductCategoriesPage";
import ListServiceCategoriesPage from "@src/pages/ListServiceCategoriesPage";
import ListOrdersPage from "@src/pages/ListOrdersPage";
import ShowOrderPage from "@src/pages/ShowOrderPage";
import CreateServicePage from "@src/pages/CreateServicePage";
import ListServicesPage from "@src/pages/ListServicesPage";
import JokerPage from "@src/pages/JokerPage";
import ProfilePage from "@src/pages/ProfilePage";
import ListNewslettersPage from "@src/pages/ListNewslettersPage";
import ShowServicePage from "@src/pages/ShowServicePage";
import ListBannersPage from "@src/pages/ListBannersPage";
import ListCreditsPage from "@src/pages/ListCreditsPage";
import EditServicePage from "@src/pages/EditServicePage";
import EditBannerPage from "@src/pages/EditBannerPage";
import DisableProfessionalsPage from "@src/pages/DisableProfessionalsPage";
import ListOrderRefundsPage from "@src/pages/ListOrderRefundsPage";
import LocationsPage from "@src/pages/LocationsPage";
import ListImageBankPage from "@src/pages/ListImageBankPage";
import ListSupplementsPage from "@src/pages/ListSupplementsPage";
import DashboardPage from "@src/pages/DashboardPage";

const DefinedRoutes = () => {
  return (
    <Switch>

      <PrivateRoute exact path={CommonRoutesStrings.logoutPath}>
        <LogoutPage />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.listUsersPath}>
        <ListUsersPage />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.createUserPath}>
        <CreateUserPage />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.editUserPath}>
        <EditUserPage />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.validateProfessionalsPath}>
        <ValidateProfessionalsPage />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.disableProfessionalsPath}>
        <DisableProfessionalsPage />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.myProfilePath}>
        <ProfilePage />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.dashboardPath}>
        <DashboardPage />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.editMyProfilePath}>
        <EditUserPage editingProfile={true} />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.listProductsPath}>
        <ListProductsPage />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.createProductPath}>
        <CreateProductPage />
      </PrivateRoute>

      <PrivateRoute exact path={CommonRoutesStrings.showOwnProductPath}>
        <ShowProductPage productType={ProductTypesStrings.ownProduct} />
      </PrivateRoute>

      <PrivateRoute exact path={CommonRoutesStrings.showThirdPartyProductPath}>
        <ShowProductPage productType={ProductTypesStrings.thirdPartyProduct} />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.editOwnProductPath}>
        <EditProductPage productType={ProductTypesStrings.ownProduct} />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.editThirdPartyProductPath}>
        <EditProductPage productType={ProductTypesStrings.thirdPartyProduct} />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.validateThirdPartyProductsPath}>
        <ValidateThirdPartyProductsPage />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.createThirdPartyProductsPath}>
        <CreateProductPage productType={ProductTypesStrings.thirdPartyProduct} />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.createOwnProductPath}>
        <CreateProductPage productType={ProductTypesStrings.ownProduct} />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.listServicesPath}>
        <ListServicesPage />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.createServicePath}>
        <CreateServicePage />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.showServicePath}>
        <ShowServicePage />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.editServicePath}>
        <EditServicePage />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.jokerPath}>
        <JokerPage />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.listPeopleSegments}>
        <ListPeopleSegmentsPage />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.listPriceTemplates}>
        <ListPriceTemplatesPage />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.listProductCategories}>
        <ListProductCategoriesPage />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.listServiceCategories}>
        <ListServiceCategoriesPage />
      </PrivateRoute>

      <PrivateRoute exact path={CommonRoutesStrings.listOrdersPath}>
        <ListOrdersPage />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.showOrderPath}>
        <ShowOrderPage />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.listOrderRefundsPath}>
        <ListOrderRefundsPage />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.listNewslettersPath}>
        <ListNewslettersPage />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.listCreditsPath}>
        <ListCreditsPage />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.listBannersPath}>
        <ListBannersPage />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.editBannerPath}>
        <EditBannerPage />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.locationsPath}>
        <LocationsPage/>
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.listImageBankPath}>
        <ListImageBankPage />
      </PrivateRoute>

      <PrivateRoute exact path={DefinedRoutesStrings.listSupplementsPath}>
        <ListSupplementsPage />
      </PrivateRoute>

      <Route exact path={CommonRoutesStrings.homePath}>
        <HomePage />
      </Route>

      <Route>
        <NotFoundPage />
      </Route>

    </Switch>
  );
};

export default DefinedRoutes;