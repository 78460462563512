import Spinner from '@src/components/ui/Spinner';
import React from 'react';
import { useTranslation } from 'react-i18next';

const TicketsPage = () => {
  const { t, ready: translationsReady } = useTranslation(["pages/b2c/staticHelpPages/tickets", "components/b2c/footer"]);

  return (
    <div className="bg-white py-[67px] px-[135.9px] grid grid-cols-1 sm:text-sm md:text-base lg:text-lg xl:text-xl montserrat-medium-shark  w-full mx-auto max-w-[1440px]">
      {!translationsReady ? <Spinner /> : (
        <>
          <p className="self-start font-extrabold  sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-[#e30746]">{t("discoverMoreHere.tickets", { ns: "components/b2c/footer" })}</p>
          <p className="mt-8 whitespace-pre-line leading-relaxed">{t("intro")}</p>
          <p className="mt-6 font-extrabold sm:text-lg md:text-xl lg:text-2xl xl:text-3xl text-[#e30746]">{t("ticketTypes.intro")}</p>
          <p className="mt-4 font-extrabold sm:text-base md:text-lg lg:text-xl xl:text-2xl text-[#e30746]">{t("ticketTypes.guidedTour.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("ticketTypes.guidedTour.content")}</p>
          <p className="mt-4 font-extrabold sm:text-base md:text-lg lg:text-xl xl:text-2xl text-[#e30746]">{t("ticketTypes.exclusiveTour.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("ticketTypes.exclusiveTour.content")}</p>
          <p className="mt-4 font-extrabold sm:text-base md:text-lg lg:text-xl xl:text-2xl text-[#e30746]">{t("ticketTypes.individualTicket.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("ticketTypes.individualTicket.content")}</p>
          <p className="mt-4 font-extrabold sm:text-base md:text-lg lg:text-xl xl:text-2xl text-[#e30746]">{t("ticketTypes.touristNavigator.title")}</p>
          <p className="mt-2 whitespace-pre-line leading-relaxed">{t("ticketTypes.touristNavigator.content")}</p>
        </>
      )}
    </div>
  );
};
 
export default TicketsPage;