import { faHandHoldingUsd, faPrint, faSearchDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@src/components/ui/b2c/buttons/Button';
import CloseButton from '@src/components/ui/b2c/buttons/CloseButton';
import IconButton from '@src/components/ui/b2c/buttons/IconButton';
import CountryFlag from '@src/components/ui/CountryFlag';
import Spinner from '@src/components/ui/Spinner';
import { dateToStringDDMMYYYYHHMMSS, formatTimeMinutes, stringDateFormat } from '@src/helpers/dateHelpers';
import { formatPrice } from '@src/helpers/localeHelpers';
import { isOrderCancellable } from '@src/helpers/orderHelpers';
import { APIEndpointsStrings, B2CRoutesStrings, OrderStatusStrings } from '@src/helpers/stringHelpers';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const OrderDisplay = ({ className: additionalClasses, order, printing, cbSetOrderToCancel, cbPrintOrder, cbSetOrderToShowRefundDetails }) => {
  const [showOrderDetails, setShowOrderDetails] = useState(printing ?? false);
  const { t, ready: translationsReady } = useTranslation(["pages/orders/list", "common/orderStatuses", "common/common"]);
  const history = useHistory();
  const askForOrderRefundPath = B2CRoutesStrings.askForOrderRefundPath.replace(APIEndpointsStrings.idLocators.id, order.id);

  const getProductDateDataFromOrderProduct = orderProduct =>
    orderProduct.configurations[0].dateProductSnapshot;

  const getTimeSliceDataFromOrderProduct = orderProduct =>
    orderProduct.configurations[0].dateProductSnapshot.timeSlices[0];

  const getPriceObjectForSelectedPrice = selectedPrice =>
    selectedPrice.dateProductSnapshot.timeSlices[0].prices[0];

  return !translationsReady ? <Spinner /> : (
    <div className={`flex items-center w-full  sm:text-xs md:text-sm lg:text-base xl:text-xl montserrat-medium-shark ${additionalClasses ?? ""}`}>
      <div className={`rounded-xl flex flex-col items-center justify-between h-auto w-full shadow-2xl ${showOrderDetails ? "p-[25px]" : "px-[27px] xl:py-[15px] sm:py-[30px]"}`}>
        <div className="flex items-center justify-between w-full  ">
          <div className="flex items-center justify-evenly">
            <div className="flex items-start justify-end h-8 mt-0">
              {/* eslint-disable-next-line */}
              <img
                className="self-center h-6 w-6"
                src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bdbd04ad7f05c93f81a613/img/group-1855-2@2x.svg"
              />
              <div className="leading-8 ml-2 -mt-px tracking-normal whitespace-no-wrap min-h-[36px]">{dateToStringDDMMYYYYHHMMSS(order.createdAt)}</div>
            </div>
            <div className="xl:h-12 sm:h-16 sm:mt-5 xl:mt-0 ml-4 w-px bg-[#e30746]" />
            <div className="flex items-start justify-end h-8 ml-4 min-w-[108px]">
              {/* eslint-disable-next-line */}
              <img
                className="self-center h-3 mt-0 w-6"
                src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bdbd04ad7f05c93f81a613/img/union-5@2x.svg"
              />
              <div className="leading-8 ml-2 -mt-px tracking-normal whitespace-no-wrap">{t("b2c.numberProducts", { count: order.orderProducts.length })}</div>
            </div>
            <div className="xl:h-12 sm:h-16 sm:mt-5 xl:mt-0 ml-4 w-px bg-[#e30746]" />
            <div className="flex items-start justify-center h-8 ml-4">
              <div className="leading-8 -mt-px tracking-normal whitespace-no-wrap">{t("b2c.orderStatus", { status: t(order.status, { ns: "common/orderStatuses" }) })}</div>
            </div>
          </div>
          <div className="flex items-start h-8">
            <div className="leading-8 -mt-px text-right tracking-normal whitespace-no-wrap montserrat-semi-bold-shark-20px">{t("b2c.totalPrice", { price: formatPrice(order.totalPrice) })}</div>
            {!printing &&
              <button onClick={() => setShowOrderDetails(b => !b)} className="self-center h-2 ml-4 w-3">
                {showOrderDetails ? (
                  <>
                    {/* eslint-disable-next-line */}
                    <img
                      src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bdbd04ad7f05c93f81a613/img/vector-5@2x.svg"
                    />
                  </>
                ) : (
                  <>
                    {/* eslint-disable-next-line */}
                    <img
                      src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bdbd04ad7f05c93f81a613/img/vector-4@2x.svg"
                    />
                  </>
                )}
              </button>
            }
          </div>
        </div>
        {showOrderDetails &&
          <>
            <div className="flex flex-col self-start items-start my-10 w-full">
              {order.orderProducts.map((orderProduct, index) => {
                const date = getProductDateDataFromOrderProduct(orderProduct).date,
                  timeSlice = getTimeSliceDataFromOrderProduct(orderProduct),
                  productName = orderProduct.product.name;
                
                const totalOrderProductPrice = orderProduct.configurations.reduce((sumPrices, selectedPrice) =>
                  sumPrices + getPriceObjectForSelectedPrice(selectedPrice).price * selectedPrice.quantity
                , 0);
                
                return (
                  <div key={index} className="flex flex-col items-start w-full">
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center w-full ">
                        {/* eslint-disable-next-line */}
                        <img
                          className="h-40 mt-5 object-cover w-44"
                          src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bdbd04ad7f05c93f81a613/img/image-24@2x.png"
                        />
                        <div className="flex flex-col items-start ml-4 w-full">
                          <div className="leading-8 -mt-px w-[85%] tracking-normal whitespace-no-wrap montserrat-medium-shark-19px truncate">{productName}</div>
                          <div className="flex sm:items-start lg:items-center h-auto w-full sm:flex-col lg:flex-row">
                            <div className="flex items-center h-24 ">
                              <div className="flex flex-col items-start mt-2 w-5">
                                {/* eslint-disable-next-line */}
                                <img
                                  className="h-5 ml-0 w-5"
                                  src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bdbd04ad7f05c93f81a613/img/group-1862@2x.svg"
                                />
                                {/* eslint-disable-next-line */}
                                <img
                                  className="h-5 mt-2 ml-px w-4"
                                  src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bdbd04ad7f05c93f81a613/img/vector-6@2x.svg"
                                />
                                {/* eslint-disable-next-line */}
                                <img
                                  className="h-5 mt-2 w-5"
                                  src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bdbd04ad7f05c93f81a613/img/union-6@2x.svg"
                                />
                                {/* eslint-disable-next-line */}
                                <img
                                  className="w-5 mt-2"
                                  src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bc4675b1e3967329ef0e4a/img/group-1741@2x.svg"
                                />
                              </div>
                              <div className="flex flex-col items-start ml-1 w-20 montserrat-medium-gunsmoke-14px">
                                <div className="leading-8 -mt-px tracking-normal whitespace-no-wrap">{stringDateFormat(date)}</div>
                                <div className="leading-7 mt-px tracking-normal whitespace-no-wrap">{formatTimeMinutes(timeSlice.duration)}</div>
                                <div className="leading-7 mt-px tracking-normal whitespace-no-wrap">{timeSlice.beginsAt}</div>
                                <CountryFlag
                                  className="w-[25px] mt-[5px]"
                                  languageCode={timeSlice.language}
                                />
                              </div>
                            </div>
                            <div className="sm:w-20 sm:my-4 sm:h-px lg:h-20 lg:ml-4 lg:w-px bg-[#e30746] " />
                            <div className="flex flex-col w-auto items-start ">
                              {orderProduct.configurations.map((configuration, index) => {
                                const totalConfigurationPrice = getPriceObjectForSelectedPrice(configuration).price * configuration.quantity;

                                return (
                                  <div key={index} className={`flex sm:flex-col lg:flex-row items-start ${index > 0 ? "mt-[11px]" : ""}`}>
                                    <div className="flex items-start h-5 w-full">
                                      <div className="leading-5 ml-2 -mt-px tracking-normal whitespace-no-wrap montserrat-medium-shark-14px">{configuration.quantity}x {getPriceObjectForSelectedPrice(configuration).clientType.name}</div>
                                    </div>
                                    <div className="leading-5 ml-6 -mt-px tracking-normal whitespace-no-wrap montserrat-medium-shark-14px">{formatPrice(totalConfigurationPrice)}</div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="leading-8 mb-px tracking-normal whitespace-no-wrap montserrat-medium-red-ribbon-18px">{t("b2c.subtotalPrice", { price: formatPrice(totalOrderProductPrice) })}</div>
                        {!printing &&
                          <>
                            {/* eslint-disable-next-line */}
                            <img
                              className="h-10 ml-5 w-10"
                              src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bdbd04ad7f05c93f81a613/img/group-1863@2x.svg"
                            />
                          </>
                        }
                      </div>
                    </div>
                    {index + 1 < order.orderProducts.length &&
                      <div className="h-px mt-5 w-[974px] bg-[#848484]" />
                    }
                  </div>
                );
              })}
            </div>
            {!printing && order.orderRefund &&
              <Button
                className="bg-[#e30746] text-white font-bold place-self-center"
                onClick={() => cbSetOrderToShowRefundDetails(order)}
              >
                <FontAwesomeIcon icon={faSearchDollar} color="white" className="mr-2" />
                {t("b2c.seeRefundDetailsBtn")}
              </Button>
            }
          </>
        }
      </div>
      {!printing &&
        <>
        <div className='flex flex-col'>
          {isOrderCancellable(order) ? (
            <CloseButton
              onClick={() => cbSetOrderToCancel(order)}
              disabled={order.status === OrderStatusStrings.cancelled}
              className="mt-5 ml-7"
            />
          ) : (
            <IconButton
              className="mt-5 ml-7"
              onClick={() => history.push(askForOrderRefundPath)}
              disabled={order.status !== OrderStatusStrings.cancelled}
            >
              <FontAwesomeIcon icon={faHandHoldingUsd} color="white" />
            </IconButton>
          )}
          <IconButton
            className="mt-5 ml-7"
            onClick={() => cbPrintOrder(order)}
          >
            <FontAwesomeIcon icon={faPrint} color="white" />
          </IconButton>
        </div>
        </>
      }
    </div>
  );
}
 
export default OrderDisplay;