import { Checkbox } from "@material-tailwind/react";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { emptyArray } from "@src/helpers/arrayHelpers";
import { isPositiveFloat } from "@src/helpers/numberHelpers";
import SubmitButton from "@src/components/ui/buttons/SubmitButton";
import Button from "@src/components/ui/buttons/Button";
import Spinner from "@src/components/ui/Spinner";
import PeopleSegmentSearch from "@src/components/products/product_wizard/PeopleSegmentSearch";
import PeopleTemplatePriceSelector from "@src/components/products/product_wizard/PeopleTemplatePriceSelector";
import FormikTextInput from "@src/components/forms/FormikTextInput";
import PriceSeletionDetails from "@src/components/products/product_wizard/PriceSelectionDetails";

export const PricesSelectorInitialValue = {
  price: "",
  type_client: "",
  quota: ""
};

const PricesSelector = ({ cbSubmitPriceSelection, cbCancelPriceSelector, minQuotaForSelectedDateRanges, priceSelectionsToModify }) => {
  const editingPriceSelections = priceSelectionsToModify != null;
  const [prices, setPrices] = useState(editingPriceSelections ? priceSelectionsToModify : []);
  const [quotaLeftToAssign, setQuotaLeftToAssign] = useState(minQuotaForSelectedDateRanges);
  const [usingPriceTemplate, setUsingPriceTemplate] = useState(false);
  const { t, ready: translationsReady } = useTranslation([
    "components/products/create/prices",
    "pages/products/create",
    "common/form",
    "common/list",
    "common/common"
  ]);

  const togglePriceTemplateUsage = () => {
    setUsingPriceTemplate(!usingPriceTemplate);
    setPrices([]);
    setQuotaLeftToAssign(minQuotaForSelectedDateRanges);
  };

  const removePrice = index => {
    const priceToDelete = prices.at(index);
    setQuotaLeftToAssign(quotaLeftToAssign + priceToDelete.quota * priceToDelete.type_client.quantity);
    setPrices(prices.filter((_, i) => i !== index));
  };

  const clientTypeAlreadySelected = clientType => prices.some(p => p.type_client === clientType);

  const validatePriceSelection = values => {
    const errors = {};

    if (!values.type_client) {
      errors.type_client = "components/products/create/prices:validations.noClientType";
    } else if (clientTypeAlreadySelected(values.type_client)) {
      errors.type_client = "components/products/create/prices:validations.clientTypeAlreadySelected";
    }

    if (!values.price) {
      errors.price = "components/products/create/prices:validations.noPrice";
    } else if (!isPositiveFloat(values.price)) {
      errors.price = "components/products/create/prices:validations.priceNotIntegerGreater0";
    }

    if (values.quota) {
      if (!/^[1-9][0-9]*$/.test(values.quota)) {
        errors.quota = "components/products/create/prices:validations.quotaNotIntegerGreater0";
      } else if (values.type_client && quotaLeftToAssign - Number(values.quota) * values.type_client.quantity < 0) {
        errors.quota = "components/products/create/prices:validations.morePricesQuotasThanQuotaForSelectedHours";
      }
    }

    return errors;
  };

  const handleNewPriceSelection = async (values, actions) => {
    setPrices([...prices, {
      type_client: values.type_client, 
      price: Number(values.price),
      quota: values.quota ? Number(values.quota) : null,
    }]);

    setQuotaLeftToAssign(quotaLeftToAssign - Number(values.quota) * values.type_client.quantity);

    actions.resetForm({ values: PricesSelectorInitialValue });
  };

  const handlePriceSelectionsSubmission = () => {
    cbSubmitPriceSelection(prices);      
  };

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <div className="px-4 py-2">
          <p>{t("labels.priceSelector.description")}</p>
          <Checkbox value={usingPriceTemplate} onClick={togglePriceTemplateUsage} label={t("labels.priceSelector.usingPriceTemplate")} />
          {usingPriceTemplate ? (
            <PeopleTemplatePriceSelector
              prices={prices}
              cbSetPrices={setPrices}
              minQuotaForSelectedDateRanges={minQuotaForSelectedDateRanges}
            />
          ) : (
            <>
              {!emptyArray(prices) &&
                <PriceSeletionDetails
                  prices={prices}
                  removeCallback={priceIndex => removePrice(priceIndex)}
                />
              }
              <Formik
                initialValues={PricesSelectorInitialValue}
                validate={validatePriceSelection}
                onSubmit={handleNewPriceSelection}
              >
                {({ values }) =>
                  <Form>
                    <div className="grid grid-cols-1 mx-10 mt-2">
                      <PeopleSegmentSearch
                        label={t("labels.priceTableHeaders.clientType")}
                        placeholder={t("placeholders.selectOne", { ns: "common/form" })}
                        name="type_client"
                        loadOptionsErrorMsg={t("messages.errorRetrievingPeopleSegments", { ns: "pages/products/create" })}
                        yMargin="0"
                      />
                      <p className="text-gray-700">
                        {t("labels.priceSelector.clientTypeQuantity", { quantity: (values.type_client && values.type_client.quantity) || t("notAvailable", { ns: "common/common" }) })}
                      </p>
                      <div className="my-4">
                      <FormikTextInput
                        label={t("labels.priceSelector.priceLabel")}
                        name="price"
                      />
                      </div>
                      <div className="my-4">
                        <FormikTextInput
                          label={t("labels.priceTableHeaders.quota")}
                          name="quota"
                        />
                        <p className="text-gray-600">{t("labels.priceSelector.quotaLeftDescription", { quota: quotaLeftToAssign })}</p>
                      </div>
                      <SubmitButton
                        color="green"
                        className="place-self-center"
                        disabled={!(values.type_client && values.price)}
                      >{t("add", { ns: "common/list" })}</SubmitButton>
                    </div>
                  </Form>
                }
              </Formik>
            </>
          )}
          <div className="flex my-2">
            <Button
              color="gray"
              onClick={cbCancelPriceSelector}
              className="mr-auto"
            >{t("buttons.cancel", { ns: "common/form" })}</Button>
            <Button
              onClick={handlePriceSelectionsSubmission}
              className="ml-auto"
              disabled={emptyArray(prices)}
            >{t("buttons.submit", { ns: "common/form" })}</Button>
          </div>
        </div>
      )}
    </>
  );
};

export default PricesSelector;