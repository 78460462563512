import { Dialog } from '@material-tailwind/react';
import BasicFormTemplate, { FooterType } from '@src/components/forms/BasicFormTemplate';
import Spinner from '@src/components/ui/Spinner';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ShowRefundCommentsDialog = ({ open, handler, orderRefund }) => {
  const { t, ready: translationsReady } = useTranslation(["components/orders/orderRefunds/showRefundCommentsDialog", "common/common"]);

  return !translationsReady ? <Spinner /> : (
    <Dialog
      open={open}
      handler={handler}
    >
      <>
        {!orderRefund ? undefined : (
          <BasicFormTemplate
            className="mt-10"
            footerType={FooterType.BACK_BUTTON}
            formTitle={t("title")}
            backCallback={handler}
          >
            <p>{t("clientComments")}</p>
            <p className="border-2 p-2 rounded-md whitespace-pre-line">{orderRefund.clientRefundReason ?? t("notAvailable", { ns: "common/common" })}</p>
            <p className="mt-4">{t("operatorComments")}</p>
            <p className="border-2 p-2 rounded-md whitespace-pre-line">{orderRefund.operatorRefundReason ?? t("notAvailable", { ns: "common/common" })}</p>
          </BasicFormTemplate>
        )}
      </>
    </Dialog>
  );
};
 
export default ShowRefundCommentsDialog;