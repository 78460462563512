import React from "react";
import { useTranslation } from "react-i18next";
import { stringDateFormat } from "@src/helpers/dateHelpers";
import Spinner from "@src/components/ui/Spinner";
import { getDateRangeRegularityDescription } from "@src/components/products/product_wizard/RegularityTable";
import { listArrayElemsInString } from "@src/helpers/arrayHelpers";
import { formatHour } from "@src/helpers/localeHelpers";
import CountryFlagWithLanguageCode from "@src/components/products/product_wizard/CountryFlagWithLanguageCode";

const HourSelectionDetails = ({ dateRange }) => {
  const { t, ready: translationsReady } = useTranslation(["components/products/create/summary", "common/regularityTypes", "components/products/create/timetables", "common/weekDays", "common/common"]);
  
  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <>
          <p><u>{t("dateRange")}:</u> {stringDateFormat(dateRange.fromDate) + " - " + stringDateFormat(dateRange.toDate)}</p>
          {dateRange.regularityType != null &&
            <p><u>{t("regularityType")}:</u> {t(dateRange.regularityType, { ns: "common/regularityTypes" })}</p>
          }
          {dateRange.regularityData != null &&
            <p><u>{t("regularityData")}:</u> {getDateRangeRegularityDescription(dateRange)}</p>
          }
          {dateRange.dates != null &&
            <p><u>{t("dates")}:</u> {listArrayElemsInString(dateRange.dates)}</p>
          }
          <p><u>{t("labels.hourSelector.durationLabel", { ns: "components/products/create/timetables" })}:</u> {dateRange.duration}</p>
          <p><u>{t("labels.hourSelector.beginsAtLabel", { ns: "components/products/create/timetables" })}:</u></p>
          <div className="flex space-x-1 my-2 overflow-x-auto">
            {dateRange.times.map((beginsAt, index) => 
              <div
                key={`beginsat-badge-${index}`}
                className="rounded bg-gray-200 px-1 whitespace-nowrap"
              >{formatHour(beginsAt[0])}{beginsAt[1].map((value) => <CountryFlagWithLanguageCode language={value} />)}</div>
            )}
          </div>
          <p><u>{t("labels.hourSelector.quotaLabel", { ns: "components/products/create/timetables" })}:</u> {dateRange.quota}</p>
        </>
      )}
    </>
  );
};

export default HourSelectionDetails;