import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ValidateThirdPartyProductsTable from "@src/components/products/ValidateThirdPartyProductsTable";
import PaginateData from "@src/components/ui/PaginateData";
import { createNotification, NotificationTypes } from "@src/helpers/notificationHelpers";
import { denyThirdPartyProductRequest, getNotValidatedThirdPartyProductsRequest, validateThirdPartyProductRequest } from "@src/helpers/productHelpers";
import { CommonRoutesStrings } from "@src/helpers/stringHelpers";
import Spinner from "@src/components/ui/Spinner";
import { useTranslation } from "react-i18next";
import HistoryBackButton from "@src/components/ui/buttons/HistoryBackButton";

const ValidateThirdPartyProductsPage = () => {
  const [data, setData] = useState();
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation("pages/products/validate");
  const history = useHistory();
  const PRODUCTS_TO_VALIDATE_PER_PAGE = 5;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const getProductsResponse = await getNotValidatedThirdPartyProductsRequest();
        setData(getProductsResponse.data);
      } catch (err) {
        createNotification(NotificationTypes.error, t("messages.cannotRetrieveProducts"));
        history.push(CommonRoutesStrings.homePath);
      }
    };
    
    translationsReady && fetchProducts();
    // eslint-disable-next-line
  }, [translationsReady]);

  const removeProductFromTable = productId => {
    setData(data.filter(product => product.id !== productId));
  }

  const doValidateProduct = async (productId) => {
    setW18ApiResponse(b => !b);

    try {
      await validateThirdPartyProductRequest(productId);
      createNotification(NotificationTypes.success, t("messages.okProductValidated"));
      removeProductFromTable(productId);
    } catch (error) {
      createNotification(NotificationTypes.error, t("messages.genericRequestError"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  const doDenyProduct = async (productId) => {
    setW18ApiResponse(b => !b);

    try {
      await denyThirdPartyProductRequest(productId);
      createNotification(NotificationTypes.success, t("messages.okProductRevoked"));
      removeProductFromTable(productId);
    } catch (error) {
      createNotification(NotificationTypes.error, t("messages.genericRequestError"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  return (
    <>
      {!(translationsReady && data) ? <Spinner /> : (
        <div className="grid grid-cols-1 place-items-center">
          <HistoryBackButton />
          <PaginateData 
            data={data}
            emptyDataArrayMsg={t("messages.noProductsToValidate")}
            perPage={PRODUCTS_TO_VALIDATE_PER_PAGE}
          >
            <ValidateThirdPartyProductsTable
              validateProductCallback={doValidateProduct}
              denyProductCallback={doDenyProduct}
            />
          </PaginateData>
        </div>
      )}
      {w18ApiResponse && <Spinner />}
    </>
  );
};

export default ValidateThirdPartyProductsPage;