import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import { getCategoriesJSX } from "@src/components/products/ListAllProductsTable";
import TableCard from "@src/components/ui/TableCard";
import TickButton from "@src/components/ui/buttons/TickButton";
import TimesButton from "@src/components/ui/buttons/TimesButton";

const ValidateThirdPartyProductsTable = ({ currentPageData : productList, validateProductCallback, denyProductCallback }) => {
  const { t, ready: translationsReady } = useTranslation(["components/products/validateTable", "pages/products/validate"]);
  
  const getHeadersArray = () => Object.values(t("headers"));

  const formatDataMatrix = () => {
    let matrix = [];

    productList.forEach(product => {
      matrix.push([
        product.id,
        product.name,
        product.professional_email,
        product.comission,
        getCategoriesJSX(product.categories),
        <>
          <TickButton
            id={`validate-product-${product.id}`} 
            onClick={() => validateProductCallback(product.id)}
          />
          <TimesButton
            id={`deny-product-${product.id}`}
            onClick={() => denyProductCallback(product.id)}
          />
        </>
      ]);
    });

    return matrix;
  }
    

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <TableCard
          title={t("title", { ns: "pages/products/validate" })}
          headersArray={getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

export default ValidateThirdPartyProductsTable;