import PencilButton from "@src/components/ui/buttons/PencilButton";
import TrashButton from "@src/components/ui/buttons/TrashButton";
import TableCard from "@src/components/ui/TableCard";
import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import { formatPrice } from "@src/helpers/localeHelpers";
import { isProvider } from "@src/helpers/userHelpers";
import TickButton from "@src/components/ui/buttons/TickButton";

const ListSupplementsTable = ({
  currentPageData: supplements,
  hideProviderColumn,
  editSupplementCallback,
  deleteSupplementCallback,
  selectSupplementCallback,
}) => {
  const { t, ready: translationsReady } = useTranslation([
    "components/supplements/listTable",
    "pages/supplements/list",
    "common/list",
    "common/common",
  ]);

  const getHeadersArray = () => Object.values(t("headers")).concat(t("actionsTableHeader", { ns: "common/list" }));

  const formatDataMatrix = () =>
    supplements.map(supplement => {
      const dataRow = [
        supplement.id,
        supplement.name,
        formatPrice(supplement.price),
        supplement.maxPerProduct,
        supplement.provider?.email ?? t("notAvailable", { ns: "common/common" }),
        <>
          {editSupplementCallback && <PencilButton onClick={() => editSupplementCallback(supplement)} />}
          {deleteSupplementCallback && <TrashButton onClick={() => deleteSupplementCallback(supplement)} />}
          {selectSupplementCallback && <TickButton onClick={() => selectSupplementCallback(supplement)} />}
        </>,
      ];

      // Do not show provider email if provider
      return isProvider() || hideProviderColumn ? dataRow.filter((_, index) => index !== 4) : dataRow;
    });

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <TableCard
          title={t("title", { ns: "pages/supplements/list" })}
          headersArray={isProvider() || hideProviderColumn ? getHeadersArray().filter((_, index) => index !== 4) : getHeadersArray()}
          dataMatrix={formatDataMatrix()}
          isLastColumnForActions={true}
        />
      )}
    </>
  );
};

export default ListSupplementsTable;
