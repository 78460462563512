import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { B2CRoutesStrings, isString, UserTypesStrings } from '@src/helpers/stringHelpers';
import { useTranslation } from 'react-i18next';
import { createNotification, NotificationTypes } from '@src/helpers/notificationHelpers';
import AuthenticationService from '@src/services/AuthenticationService';
import EssentialUserDataRegistrationForm from '@src/components/users/b2c/new/EssentialUserDataRegistrationForm';
import UserRegistrationService from '@src/services/users/userRegistrationService';
import AddCompanyDataRegistrationForm from '@src/components/users/b2c/new/AddCompanyDataRegistrationForm';

import "@src/assets/styles/b2c/pages/register_user/globals.css";
import "@src/assets/styles/b2c/pages/register_user/styleguide.css";
import Spinner from '@src/components/ui/Spinner';

const RegisterUserPage = ({ defaultUserType }) => {
  const history = useHistory();
  const [w18ApiResponseUserRegistrationWithoutCompany, setW18ApiResponseUserRegistrationWithoutCompany] = useState(false);
  const [w18ApiResponseProfessionalRegistrationWithCompany, setw18ApiResponseProfessionalRegistrationWithCompany] = useState(false);
  const [w18ApiResponseEmailValidation, setW18ApiResponseEmailValidation] = useState(false);
  const [essentialUserData, setEssentialUserData] = useState();
  const [displayEssentialUserDataForm, setDisplayEssentialUserDataForm] = useState(true);
  const { t, ready: translationsReady } = useTranslation(["pages/users/create", "common/form", "common/userTypes"]);
  const registeringClient = defaultUserType === UserTypesStrings.client;

  const handleUserRegistrationWithoutCompany = async ({ email, password }) => {
    setW18ApiResponseUserRegistrationWithoutCompany(b => !b);
      
    try {
      await AuthenticationService.registerUser(defaultUserType, email, password);
      createNotification(NotificationTypes.info, t("messages.successRegisterUser"));
      history.push(B2CRoutesStrings.loginPath);
    } catch (err) {
      const errors = err?.response?.data?.errors;
      if (errors) {
        if (isString(errors)) {
          createNotification(NotificationTypes.error, errors);
        } else {
          errors.forEach(error => createNotification(NotificationTypes.error, error));
        }
      }
    } finally {
      setW18ApiResponseUserRegistrationWithoutCompany(b => !b);
    }
  };

  const handlePreviousStep = async () => {
    if (registeringClient || displayEssentialUserDataForm) {
      history.replace(B2CRoutesStrings.userTypePickerRegisterPath);
    } else {
      setDisplayEssentialUserDataForm(true);
    }
  };

  const handleEssentialUserData = async (data) => {
    if (registeringClient) {
      handleUserRegistrationWithoutCompany(data);
    } else {
      try {
        setW18ApiResponseEmailValidation(b => !b);

        await UserRegistrationService.validateEmailAlreadyRegistered(data.email);

        setEssentialUserData(data);
        setDisplayEssentialUserDataForm(false);

      } catch (err) {
        createNotification(NotificationTypes.error, t("validationErrors.emailTaken", { ns: "common/form" }));
      } finally {
        setW18ApiResponseEmailValidation(b => !b);
      }
    }
  };

  const handleCompanyDataSubmission = async (company) => {
    setw18ApiResponseProfessionalRegistrationWithCompany(b => !b);
      
    try {
      await AuthenticationService.registerProfessionalWithCompany(defaultUserType, essentialUserData.email, essentialUserData.password, company);
      createNotification(NotificationTypes.info, t("messages.successRegisterUser"));
      history.push(B2CRoutesStrings.loginPath);
    } catch (err) {
      createNotification(NotificationTypes.error, t("messages.genericRequestError"));
    } finally {
      setw18ApiResponseProfessionalRegistrationWithCompany(b => !b);
    }
  };

  const handleCompanyDataSkipped = async () =>
    handleUserRegistrationWithoutCompany(essentialUserData);

  return (
    <div className="container-center-horizontal">
      <div className="flex items-start h-[875px] w-[1440px]">
        <div className="flex justify-center relative h-full w-full bg-[url(https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62babdbc8606f7d7f2443e0a/img/image-35-4@1x.png)]">
          {!translationsReady ? <Spinner /> : (
            <>
              {displayEssentialUserDataForm ? (
                <EssentialUserDataRegistrationForm
                  initialValues={essentialUserData}
                  backCallback={handlePreviousStep}
                  nextCallback={handleEssentialUserData}
                  showLoadingSpinner={registeringClient ? w18ApiResponseUserRegistrationWithoutCompany : w18ApiResponseEmailValidation}
                  isLastStep={registeringClient}
                  title={t("titles.registerUser", { userType: t(defaultUserType, { ns: "common/userTypes" }).toLowerCase() })}
                />
              ) : (
                <AddCompanyDataRegistrationForm
                  showLoadingSpinnerCompanyDataSubmission={w18ApiResponseProfessionalRegistrationWithCompany}
                  showLoadingSpinnerSkipCompanyDataSubmission={w18ApiResponseUserRegistrationWithoutCompany}
                  backCallback={handlePreviousStep}
                  skipCompanySubmissionCallback={handleCompanyDataSkipped}
                  submitCompanyDataCallback={handleCompanyDataSubmission}
                  title={t("titles.addCompanyData")}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
 
export default RegisterUserPage;