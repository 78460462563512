import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import CreditForm from "@src/components/credits/CreditForm";
import CreditService from "@src/services/creditService";
import ErrorDisplayBox from "@src/components/ui/ErrorDisplayBox";
import { Dialog } from "@material-tailwind/react";
import {createNotification, NotificationTypes} from "@src/helpers/notificationHelpers";
import * as Yup from "yup";
import { CreditStateStrings } from "@src/helpers/stringHelpers";

export function getOptionsForCreditStateSelect() {
  const creditState = [
    CreditStateStrings.pending,
    CreditStateStrings.denied,
    CreditStateStrings.validated
  ];
  let options = [];

  creditState.forEach(value => options.push({
    value,
    label: `common/creditState:${value}`
  }));
  return options;
};    

const CreateCredit = ({open, handler: backCallback, creationEndedCallback}) => {
 
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const [errors, setErrors] = useState();
  const {t, ready: translationsReady} = useTranslation("components/credits/create");
  const CreditSchema = Yup.object().shape({});

  const submitData = async (values, _) => {

    setW18ApiResponse(b => !b);


    try {
      await CreditService.create(values);
      setErrors(); // clear errorbox if present
      createNotification(NotificationTypes.success, t("okSubmissionResponse"));
      creationEndedCallback();
    } catch (error) {
      if (error?.response?.data?.error) {
        const errorMessage = error.response.data.error;
        setErrors([errorMessage]);
      }else{
        createNotification(NotificationTypes.error, t("koSubmissionResponse"));
      }

    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <Dialog open={open} handler={backCallback}>
          
            {errors && (
              <ErrorDisplayBox
                boxDescription={t("errorBoxDescription")}
                errors={errors}
              />
            )}

            <CreditForm
              title={t("title")}
              enabled={true}
              handleSubmit={submitData}
              creditSchema={CreditSchema}
              backCallback={backCallback}
              showLoadingSpinnerSubmitButton={w18ApiResponse}
            />
       
        </Dialog>
      )}
    </>
  );
};

export default CreateCredit;