import Spinner from '@src/components/ui/Spinner';
import { formatPrice } from '@src/helpers/localeHelpers';
import ProductService from '@src/services/products/productService';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import { isThirdPartyProduct } from '@src/helpers/productHelpers';
import { APIEndpointsStrings, CommonRoutesStrings, HexStandardPinkStylingB2C } from '@src/helpers/stringHelpers';
import { formatTimeMinutes } from '@src/helpers/dateHelpers';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';

import "@src/assets/styles/b2c/components/ui/highlighted-products.css"
import CountryFlag from '@src/components/ui/CountryFlag';

const HighlightedProducts = () => {
  const [highlightedProducts, setHighlightedProducts] = useState();
  const { t, ready: translationsReady } = useTranslation(["pages/b2c/landing", "common/common"]);
  const currentRouteMatch = useRouteMatch();
  const { id: productId } = useParams();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        // if in B2C ShowProductPage, avoid showing current product, as it won't be meaningful
        const excludedProductId = currentRouteMatch.path === CommonRoutesStrings.showOwnProductPath || currentRouteMatch.path === CommonRoutesStrings.showThirdPartyProductPath
          ? productId : undefined;
        const products = await ProductService.indexHighlightedProducts(excludedProductId);
        setHighlightedProducts(products);
      } catch (_) {}
    };

    fetchProducts();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex flex-wrap justify-center mt-8 font-['Montserrat']">
      {!(highlightedProducts && translationsReady) ? <Spinner /> : highlightedProducts.map(product => {
        const showProductPath = isThirdPartyProduct(product.type) ?
          CommonRoutesStrings.showThirdPartyProductPath : CommonRoutesStrings.showOwnProductPath;

        return (
          <div
            key={product.id}
            className="bg-white rounded-[28px] shadow-2xl h-[25rem] w-[25rem] md:mx-[10px] sm:mt-[5%] p-10 flex flex-col justify-around"
          >
            <div className="flex flex-col items-start w-auto h-auto">
              {/* eslint-disable-next-line */}
              <img
                className="object-cover h-[150px] w-full rounded-xl"
                src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bab950d3ed8532d0c3cc19/img/rectangle-14@2x.png"
              />
              <div className="flex flex-col items-start mt-8 w-full ">
                <div className="max-w-full leading-[28.5px] max-h-[86px] h-[19rem] text-gray-900 text-3xl font-bold line-clamp-3">{product.name}</div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex items-start h-8 min-w-[157px]">
                {/* eslint-disable-next-line */}
                <img
                  className="self-center h-5 -ml-px w-5"
                  src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bab950d3ed8532d0c3cc19/img/group-1741@2x.svg"
                />
                <div className="flex items-start self-center">
                  {product.productCache.languages.map(language => (
                    <CountryFlag
                      key={language}
                      className="w-6 ml-2"
                      languageCode={language}
                    />
                  ))}
                </div>
              </div>
              <div className="flex items-center justify-between h-10 w-full mt-2">
                <div className="flex items-center h-8 w-full">
                  <FontAwesomeIcon icon={faClock} className="h-6 w-6" color="gray" />
                  <div className="ml-2 whitespace-no-wrap montserrat-normal-shark-18px">{formatTimeMinutes(product.productCache.duration)}</div>
                </div>
                <Link
                  className={`flex w-auto h-auto p-2 bg-[${HexStandardPinkStylingB2C}] rounded-[34px]`}
                  to={showProductPath.replace(APIEndpointsStrings.idLocators.id, product.id)}
                >
                  <FontAwesomeIcon icon={faPlus} color="white" className="mt-1" />
                  <div className="ml-3 whitespace-nowrap montserrat-semi-bold-white-16px">{t("productPriceFrom", { price: formatPrice(product.productCache.price) })}</div>
                </Link>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
 
export default HighlightedProducts;