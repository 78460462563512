import { Button } from "@material-tailwind/react";
import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@src/components/ui/Spinner";
import { useHistory } from "react-router-dom";

const HistoryBackButton = ({ color, size, onClick, ...props }) => {
  const history = useHistory();
  const { t, ready: translationsReady } = useTranslation("common/navigation");
  
  const handleBackAction = () => history.goBack();

  return (
    <>
      {!translationsReady ? <Spinner /> : (
        <Button
          color={color ?? "gray"}
          onClick={onClick ?? handleBackAction}
          size={size ?? "lg"}
          {...props}
        >{t("back")}</Button>
      )}
    </>
  );
};

export default HistoryBackButton;