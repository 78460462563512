import React, { useState } from 'react';
import { B2CRoutesStrings } from '@src/helpers/stringHelpers';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from "yup";
import { Field, Form, Formik } from 'formik';
import { useQuery } from '@src/helpers/hooks/useQuery';
import { useTranslation } from 'react-i18next';
import { createNotification, NotificationTypes } from '@src/helpers/notificationHelpers';
import Spinner from '@src/components/ui/Spinner';
import AuthenticationService from '@src/services/AuthenticationService';
import SmallSpinner from "react-spinkit";

import "@src/assets/styles/b2c/pages/edit_password/edit-password.css";
import "@src/assets/styles/b2c/pages/edit_password/globals.css";
import "@src/assets/styles/b2c/pages/edit_password/styleguide.css";

const ResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string().required("common/form:validationErrors.empty.newPassword")
    .min(6, "common/form:validationErrors.passwords.passwordMinLength"),
  passwordConfirmation: Yup.string().required("common/form:validationErrors.empty.confirmationPassword")
    .oneOf([Yup.ref("newPassword"), null], "common/form:validationErrors.passwords.notMatchingPasswords")
});

const ResetPasswordInitialValues = {
  newPassword: "",
  passwordConfirmation: "",
};

const EditPasswordPage = () => {
  const query = useQuery();
  const history = useHistory();
  const resetPasswordToken = query.get("reset_password_token");
  const [w18ApiResponse, setW18ApiResponse] = useState(false);
  const { t, ready: translationsReady } = useTranslation(["pages/users/editPassword", "common/form", "common/navigation"]);
  
  const handleSubmit = async ({ newPassword, passwordConfirmation }, _) => {
    setW18ApiResponse(b => !b);

    try {
      await AuthenticationService.editPassword(newPassword, passwordConfirmation, resetPasswordToken);
      createNotification(NotificationTypes.success, t("editPasswordAction.ok"));
      history.push(B2CRoutesStrings.loginPath);
    } catch (err) {
      createNotification(NotificationTypes.error, t("editPasswordAction.ko"));
    } finally {
      setW18ApiResponse(b => !b);
    }
  };

  return (
    <div class="container-center-horizontal">
      <div class="restablecer-contrasena-2 screen">
        <div class="overlap-group">
          {!translationsReady ? <Spinner /> : (
            <Formik
              initialValues={ResetPasswordInitialValues}
              validationSchema={ResetPasswordSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form>
                  <div class="frame-13">
                    <div class="frame-9">
                      <h1 class="restablecer-contrasea montserrat-bold-shark-36px">{t("title")}</h1>
                      <div className="grid grid-cols-1">
                        <div className={`frame-1837 ${errors.newPassword && touched.newPassword ? "border-2 border-red-500" : ""}`}>
                          <Field
                            className="nueva-contrasea montserrat-medium-gunsmoke-12px"
                            name="newPassword"
                            placeholder={t("labels.newPassword", { ns: "common/form" })}
                            type="password"
                          />
                        </div>
                      </div>
                      {errors.newPassword && touched.newPassword &&
                        <p className="mt-2 text-red-500 font-bold">{t(errors.newPassword)}</p>
                      }
                      <div className="grid grid-cols-1">
                        <div className={`frame-1838 ${errors.passwordConfirmation && touched.passwordConfirmation ? "border-2 border-red-500" : ""}`}>
                          <Field
                            className="confirma-la-contrasea montserrat-medium-gunsmoke-12px"
                            name="passwordConfirmation"
                            placeholder={t("labels.confirmPassword", { ns: "common/form" })}
                            type="password"
                          />
                        </div>
                      </div>
                      {errors.passwordConfirmation && touched.passwordConfirmation &&
                        <p className="mt-2 text-red-500 font-bold">{t(errors.passwordConfirmation)}</p>
                      }
                    </div>
                    <div class="frame-1831">
                      <Link to={B2CRoutesStrings.loginPath}>
                        <div class="frame-8">
                          {/* eslint-disable-next-line */}
                          <img
                            class="group-1835"
                            src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bac59e6ed04d3f1a08729f/img/group-1835@2x.svg"
                          />
                          <div class="volver montserrat-semi-bold-white-16px">{t("back", { ns: "common/navigation" })}</div>
                        </div>
                      </Link>
                      <button type="submit">
                        <div className="frame-8-1">
                          {w18ApiResponse ? (
                            <div className="w-[19px] h-[19px] flex">
                              <SmallSpinner name="circle" color="white" fadeIn="none" className="m-auto" />
                            </div>
                          ) : (
                            <>
                              {/* eslint-disable-next-line */}
                              <img
                                className="icon-paper_plane"
                                src="https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62babcf788aa0b91b68cf475/img/group-2@2x.svg"
                              />
                            </>
                          )}
                          <div className="enviar montserrat-semi-bold-white-16px">{t("buttons.submit", { ns: "common/form" })}</div>
                        </div>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
}
 
export default EditPasswordPage;