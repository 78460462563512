import Button from "@src/components/ui/buttons/Button";
import React from "react";
import { useTranslation } from "react-i18next";
import SmallSpinner from "react-spinkit";
import BigSpinner from "@src/components/ui/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SubmitButton = ({ children: text, className, showLoadingSpinner, icon, ...props }) => {
  const { t, ready: translationsReady } = useTranslation("common/form");

  return (
    <>
      {!translationsReady ? (
        <BigSpinner />
      ) : (
        <Button type="submit" className={className} {...props}>
          <div className="flex">
            {showLoadingSpinner ? (
              <SmallSpinner name="circle" color="white" fadeIn="none" className="mr-2" />
            ) : (
              <>{icon && <FontAwesomeIcon icon={icon} className="mr-2" />}</>
            )}
            {text ?? t("buttons.submit")}
          </div>
        </Button>
      )}
    </>
  );
};

export default SubmitButton;
