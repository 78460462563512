import { DEFINED_COMPONENTS } from "@src/pages/ShowProductPage";

export const isString = (str) => typeof str === "string" || str instanceof String;

export const CommonRoutesStrings = {
  homePath: "/",
  homePathAfterImpersonationSessionExpired: function () {
    return `${this.homePath}?impersonationExpired=1`;
  },
  loginPathAfterSessionCleared: function () {
    return `${B2CRoutesStrings.loginPath}?sessionExpired=1`;
  },
  loginPathAfterEditingCurrentUser: function () {
    return `${B2CRoutesStrings.loginPath}?currentUserEdited=1`;
  },
  loginPathAndRedirectToCheckout: function () {
    return `${B2CRoutesStrings.loginPath}?redirectToCheckout=1`;
  },
  logoutPath: "/logout",
  showThirdPartyProductPath: "/thirdPartyProducts/:id",
  showThirdPartyProductPropertiesPath: "/thirdPartyProducts/:id?show="+DEFINED_COMPONENTS.SHOW_PRODUCT_PROPERTIES,
  showOwnProductPath: "/ownProducts/:id",
  showOwnProductPropertiesPath: "/ownProducts/:id?show="+DEFINED_COMPONENTS.SHOW_PRODUCT_PROPERTIES,
  listOrdersPath: "/orders",
};

export const B2CRoutesStrings = {
  staticHelpPaths: {
    faqPath: "/help/faq",
    termsOfServicePath: "/help/termsOfService",
    gdprPath: "/help/gdpr",
    legalNoticePath: "/help/legalNotice",
    aboutUsPath: "/help/aboutUs",
    contactUsPath: "/help/contact",
    discoverMoreHerePath: "/help/discoverMoreHere",
    ticketsPath: "/help/tickets",
    becomeAPartnerPath: "/help/becomeAPartner",
  },
  loginPath: "/login",
  forgotPasswordPath: "/users/password/forgot",
  editPasswordPath: "/users/password/edit",
  userTypePickerRegisterPath: "/signup",
  registerClientPath: "/signup/clients",
  registerProviderPath: "/signup/providers",
  registerServicesProviderPath: "/signup/servicesProviders",
  confirmEmailPath: "/users/confirmation",
  listProductsSearchPath: "/products/search",
  checkoutPath: "/checkout",
  showGuestOrderPath: "/orders_guest/:id",
  showRedsysOkPath: "/redsys/pago-ok",
  showRedsysKoPath: "/redsys/pago-ko",
  askForOrderRefundPath: "/orders/:id/refund",
};

export const DefinedRoutesStrings = {
  listUsersPath: "/users",
  dashboardPath: "/dashboard",
  createUserPath: "/users/new",
  editUserPath: "/users/edit/:id",
  validateProfessionalsPath: "/users/validateProfessionals",
  disableProfessionalsPath: "/users/disableProfessionals",
  myProfilePath: "/profile",
  editMyProfilePath: "/profile/edit",
  listProductsPath: "/products",
  createProductPath: "/products/new",
  editThirdPartyProductPath: "/thirdPartyProducts/edit/:id",
  editOwnProductPath: "/ownProducts/edit/:id",
  editProductPath: "/products/edit/:id",
  validateThirdPartyProductsPath: "/products/thirdParty/validate",
  createThirdPartyProductsPath: "/products/thirdParty/new",
  createOwnProductPath: "/products/own/new",
  listServicesPath: "/services",
  showServicePath: "/services/:id",
  editServicePath: "/services/edit/:id",
  createServicePath: "/services/new",
  jokerPath: "/joker",
  listPeopleSegments: "/peopleSegments",
  listPriceTemplates: "/priceTemplates",
  listProductCategories: "/categories",
  listServiceCategories: "/serviceCategories",
  showOrderPath: "/orders/:id",
  listOrderRefundsPath: "/orderRefunds",
  listSupplementsPath: "/supplements",
  listNewslettersPath: "/newsletters",
  listBannersPath: "/banners",
  editBannerPath: "/banners/edit/:id",
  listCreditsPath: "/credits",
  locationsPath: "/locations",
  listImageBankPath: "/imageBank",
};

export const APIEndpointsStrings = {
  idLocators: {
    id: ":id",
    productId: ":product_id",
    serviceId: ":service_id",
    productDateId: ":product_date_id",
    timesliceId: ":timeslice_id",
    resourceId: ":resource_id",
    orderId: ":order_id"
  },
  usersPath: "/users",
  reviewedUsersPath: "/users?q[reviewed_users]=1&q[type_not_eq]=Guest",
  userPath: "/users/:id",
  showCurrentUserPath: "/users/current_user_logged",
  impersonateUserPath: "/users/:id/impersonate",
  stopImpersonatingUserPath: "/users/impersonate",
  disableProfessionalPath: "/users/professional/disable/:id",
  enableProfessionalPath: "/users/professional/enable/:id",
  loginPath: "/login",
  logoutPath: "/logout",
  forgotPasswordPath: "/users/password",
  registerClientPath: "/signup/client",
  registerThirdPartyProviderPath: "/signup/provider",
  registerServicesProviderPath: "/signup/services_provider",
  validateEmailAlreadyRegisteredPath: "/signup/validate_email_already_registered",
  confirmEmailPath: "/users/confirmation?confirmation_token=",
  companiesPath: "/companies",
  companyPath: "/companies/:id",
  productsPath: "/products/",
  productSearchFiltersB2C: "/filters_web/search",
  productSearchFilterLimitsB2C: "/filters_web",
  productTranslationsPath: "/products/:id/product_translations",
  productCancelPath: "/products/:id/cancel",
  createProductsWithXlsxPath: "/products/create-from-xls",
  ownProductsPath: "/own_products/",
  patchThirdPartyProduct: "/third_party_products/:id",
  ownProductPath: "/own_products/:id",
  thirdPartyProductsPath: "/third_party_products/",
  thirdPartyProductPath: "/third_party_products/:id",
  createProductConfiguration: "/products/:product_id/configurations/",
  deleteProductConfiguration: "/products/:product_id/configurations/:id",
  thirdPartyProductDatesPath: "/third_party_products/:id/date_products",
  ownProductDatesPath: "/own_products/:id/date_products",
  productDatesPath: "/products/:id/date_products",
  thirdPartyProductDatePath: "/third_party_products/:product_id/date_products/:id",
  ownProductDatePath: "/own_products/:product_id/date_products/:id",
  productDateCancelPath: "/products/:product_id/date_products/:product_date_id/cancel",
  productDatePath: "/products/:product_id/date_products/:id",
  thirdPartyProductTimeslicesPath: "/third_party_products/:product_id/date_products/:product_date_id/time_slices",
  ownProductTimeslicesPath: "/own_products/:product_id/date_products/:product_date_id/time_slices",
  productTimeSlicesPath: "/products/:product_id/date_products/:product_date_id/time_slices",
  productTimeslicePath: "/products/:product_id/date_products/:product_date_id/time_slices/:id",
  statusProductTimeslicePath: "/products/:product_id/date_products/:product_date_id/time_slices/:id/status_events",
  thirdPartyProductPricesPath:
    "/third_party_products/:product_id/date_products/:product_date_id/time_slices/:timeslice_id/prices",
  ownProductPricesPath: "/own_products/:product_id/date_products/:product_date_id/time_slices/:timeslice_id/prices",
  productPricesPath: "/products/:product_id/date_products/:product_date_id/time_slices/:timeslice_id/prices",
  thirdPartyProductPricePath:
    "/third_party_products/:product_id/date_products/:product_date_id/time_slices/:timeslice_id/prices/:id",
  ownProductPricePath: "/own_products/:product_id/date_products/:product_date_id/time_slices/:timeslice_id/prices/:id",
  productPricePath: "/products/:product_id/date_products/:product_date_id/time_slices/:timeslice_id/prices/:id",
  createProductDate: "/products/:product_id/configurations/:id/add_date",
  deleteProductDate: "/products/:product_id/configurations/:id/delete_date",
  createProductTimeslice: "/products/:product_id/time_slices/",
  updateProductTimeslice: "/products/:product_id/time_slices/:id",
  deleteProductTimeslice: "/products/:product_id/time_slices/:id",
  createProductPrice: "/products/:product_id/prices",
  updateProductPrice: "/products/:product_id/prices/:id",
  deleteProductPrice: "/products/:product_id/prices/:id",
  assignSupplementToProductPath: "/products/:id/product_supplements",
  deleteSupplementFromProductPath: "/products/:product_id/product_supplements/:id",
  searchEventsByDatePath: "/products/search/by_date",
  searchResourcesByDateCategoryPath: "/service_products/resources/index",
  addServiceToProductPath: "/own_products/:product_id/date_products/:product_date_id/time_slices/:id/add_service",
  removeServiceFromProductPath:
    "/own_products/:product_id/date_products/:product_date_id/time_slices/:id/remove_service",
  servicesPath: "/service_products/",
  servicePath: "/service_products/:id",
  createServicesWithXlsxPath: "/service_products/create-from-xls",
  serviceDatesPath: "/service_products/:id/date_products/",
  serviceDatePath: "/service_products/:service_id/date_products/:id",
  createServiceResourcesPath: "/service_products/:id/resources/create",
  serviceResourcePath: "/service_products/:service_id/date_products/:product_date_id/resources/:id",
  serviceResourceUnitsPath: "/service_products/:service_id/date_products/:product_date_id/resources/:id/units",
  serviceResourceUnitPath:
    "/service_products/:service_id/date_products/:product_date_id/resources/:resource_id/units/:id",
  disableServiceResourceUnitPath: "/service_products/:id/disable_unit",
  createPeopleSegment: "/people_segments",
  singlePeopleSegment: "/people_segments/:id",
  priceTemplatesPath: "/price_templates/",
  singlePriceTemplate: "/price_templates/:id",
  categoriesPath: "/categories/",
  categoryPath: "/categories/:id",
  serviceCategoriesPath: "/service_categories/",
  serviceCategoryPath: "/service_categories/:id",
  ordersPath: "/orders",
  orderPath: "/orders/:id",
  cancelOrderPath: "/orders/:id/cancel",
  confirmOrderPath: "/orders/:id/confirm",
  guestOrdersPath: "/orders_guest",
  guestOrderPath: "/orders_guest/:id",
  indexOrderRefundsPath: "/order_refunds/index",
  createOrderRefundPath: "/orders/:id/order_refunds",
  manageOrderRefundPath: "/orders/:order_id/order_refunds/:id/manage_refund",
  supplementsPath: "/supplements",
  supplementPath: "/supplements/:id",
  newslettersPath: "/newsletters",
  newsletterPath: "/newsletters/:id",
  newsletterSubscriptionsPath: "/subscription_newsletters",
  bannersPath: "/banners",
  bannerPath: "/banners/:id",
  creditsPath: "/credit_requests",
  creditPath: "/credit_requests/:id",
  authorizeCreditPath: "/credit_requests/:id/authorize_credit",
  creditsPathByUserId: "/credit_requests?q[request_by_id_eq]=:id",
  locationsPath: "/locations",
  locationAddPath: "/services_provider/:id/add_location",
  locationDeletePath: "/services_provider/:id/delete_location",
  locationPath: "/locations/:id",
  locationServicesProvaiderPath: "/users?q[type_eq]=ServicesProvider",
  locationServicesProvaiderIdPath: "/users?q[type_eq]=ServicesProvider&q[id_eq]=:id",
  imagesBankPath: "/bank_images",
  imageBankPath: "/bank_images/:id",
};
export const CreditStateStrings = {
  denied: "Denied",
  pending: "Pending",
  validated: "Validated",
}

export const UserTypesStrings = {
  admin: "Admin",
  operator: "Operator",
  provider: "Provider",
  servicesProvider: "ServicesProvider",
  client: "Client",
};

export const ProductTypesStrings = {
  thirdPartyProduct: "ThirdPartyProduct",
  ownProduct: "OwnProduct",
};

export const RegularityTypesStrings = {
  none: "none",
  daily: "daily",
  weekly: "weekly",
  monthly: "monthly",
  yearly: "yearly",
};

export const OrderStatusStrings = {
  paymentReceived: "Payment received",
  completed: "Completed",
  cancelled: "Cancelled",
  pendingRefund: "Pending refund",
};

export const WeekDaysStrings = {
  full: {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
  },
  simplified: {
    monday: "Mo",
    tuesday: "Tu",
    wednesday: "We",
    thursday: "Th",
    friday: "Fr",
    saturday: "Sa",
    sunday: "Su",
  },
};

export const HexStandardPinkStylingB2C = "#e30746";

export const ProfessionalValidationStatusStrings = {
  awaitingCompanyData: "AWAITING_COMPANY_DATA",
  reviewPending: "REVIEW_PENDING",
  accountRevoked: "ACCOUNT_REVOKED",
};

export const SocialNetworkLinkStrings = {
  twitter: "https://twitter.com/alhambraonline",
  youtube: "https://www.youtube.com/user/Granavisiontv",
  tripadvisor:
    "https://www.tripadvisor.es/Attraction_Review-g187441-d2175894-Reviews-Granavision-Granada_Province_of_Granada_Andalucia.html",
  pinterest: "https://www.pinterest.es/alhambraonline/",
  linkedin: "https://www.linkedin.com/company/granavision-grupo-tur-stico/",
  instagram: "https://www.instagram.com/granavision_tourism_group/",
  facebook: "https://www.facebook.com/granavision",
  whatsapp: "https://wa.me/34606715901",
  googleMaps:
    "https://www.google.com/maps/place/%E2%AD%90Alhambra+Online+(Granavisi%C3%B3n)/@37.173534,-3.5841533,17z/data=!3m1!4b1!4m5!3m4!1s0xd71fcb386b52b43:0x8ab290e0ef99d732!8m2!3d37.173534!4d-3.5841533",
};

export const LanguageStrings = {
  es: "español",
  en: "English",
};
