import React from "react";

const Button = ({ className : additionalClasses, children, ...props }) =>
  <button
    className={"rounded px-4 py-2 shadow-md hover:shadow-none disabled:shadow-none "
      + "transition-all duration-75 hover:brightness-90 disabled:cursor-default "
      + `disabled:opacity-50 disabled:filter-none ${additionalClasses ?? ""}`}
    {...props}
  >
    {children}
  </button>;

export default Button;