import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import IconButton from "@src/components/ui/buttons/IconButton";

const PencilButton = ({ targetPath, onClick, ...props }) => {
  const history = useHistory();

  return (
    <IconButton
      variant="gradient"
      onClick={targetPath ? () => history.push(targetPath) : onClick}
      {...props}
    ><FontAwesomeIcon icon={faPencilAlt} /></IconButton>
  );
};

export default PencilButton;




