import React, { useState } from "react";
import { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { getCurrentUserData } from "@src/helpers/localStorageHelpers";
import {
  createNotification,
  NotificationTypes,
} from "@src/helpers/notificationHelpers";
import { CommonRoutesStrings } from "@src/helpers/stringHelpers";
import { isServicesProvider } from "@src/helpers/userHelpers";
import Spinner from "@src/components/ui/Spinner";
import { Trans, useTranslation } from "react-i18next";
import ServiceService from "@src/services/services/serviceService";
import EditServiceMainScreen from "@src/components/products/services/edit/EditServiceMainScreen";
import EditBasicInfo from "@src/components/products/services/edit/EditBasicInfo";
import ListDates from "@src/components/products/services/edit/dates/ListDates";
import ListResources from "@src/components/products/services/edit/resources/ListResources";
import ListResourceUnits from "@src/components/products/services/edit/resource_units/ListResourceUnits";
import ListResourceUnitsTaken from "@src/components/products/services/edit/resource_units_taken/ListResourceUnitsTaken";
import {
  CardHeader
} from "@material-tailwind/react";

const ShowServicePage = () => {
  const [service, setService] = useState();
  const [error, setError] = useState(false);
  const { id } = useParams();
  const { t, ready: translationsReady } = useTranslation("pages/services/edit");
  const readOnly = true;

  const DEFINED_COMPONENTS = {
    MAIN_COMPONENT: "MAIN_COMPONENT",
    SHOW_BASIC_INFO: "SHOW_BASIC_INFO",
    LIST_DATES: "LIST_DATES",
    LIST_RESOURCES_OF_DATE: "LIST_RESOURCES_OF_DATE",
    LIST_UNIT_FROM_RESOURCE: "LIST_UNIT_FROM_RESOURCE",
    LIST_TAKEN_FROM_RESOURCE_UNIT: "LIST_TAKEN_FROM_RESOURCE_UNIT",
  };

  const [currentComponent, setCurrentComponent] = useState(
    DEFINED_COMPONENTS.MAIN_COMPONENT
  );
  const [selectedDate, setSelectedDate] = useState();
  const [selectedResource, setSelectedResource] = useState();
  const [selectedResourceUnit, setSelectedResourceUnit] = useState();

  useEffect(() => {
    const fetchService = async () => {
      if (id == null) {
        createNotification(
          NotificationTypes.error,
          t("validations.noServiceIdInPath")
        );
        setError(true);
      } else {
        try {
          const serviceData = await ServiceService.show(id);
          if (
            isServicesProvider() &&
            serviceData.professionalEmail !== getCurrentUserData().email
          ) {
            createNotification(
              NotificationTypes.error,
              t("validations.noPermissions.show")
            );
            setError(true);
          } else {
            setService(serviceData);
          }
        } catch (err) {
          if (err?.response?.data?.error === "Record not found") {
            createNotification(
              NotificationTypes.error,
              t("validations.productNotFound")
            );
            setError(true);
          }
        }
      }
    };

    translationsReady && fetchService();
    // eslint-disable-next-line
  }, [translationsReady]);

  const renderCurrentComponent = () => {
    switch (currentComponent) {
      case DEFINED_COMPONENTS.MAIN_COMPONENT:
        return (
          <EditServiceMainScreen
            readOnly={readOnly}
            editBasicInfoCallback={() =>
              changeSelectedComponent(DEFINED_COMPONENTS.SHOW_BASIC_INFO)
            }
            editConfigurationsCallback={() =>
              changeSelectedComponent(DEFINED_COMPONENTS.LIST_DATES)
            }
          />
        );
      case DEFINED_COMPONENTS.SHOW_BASIC_INFO:
        return (
          <EditBasicInfo
            serviceData={service}
            readOnly={readOnly}
            backAction={() =>
              changeSelectedComponent(DEFINED_COMPONENTS.MAIN_COMPONENT)
            }
          />
        );
      case DEFINED_COMPONENTS.LIST_DATES:
        return (
          <ListDates
            dates={service.dates}
            readOnly={readOnly}
            backCallback={() =>
              changeSelectedComponent(DEFINED_COMPONENTS.MAIN_COMPONENT)
            }
            showResourcesOfDateCallback={(date) =>
              changeSelectedComponent(
                DEFINED_COMPONENTS.LIST_RESOURCES_OF_DATE,
                date
              )
            }
          />
        );
      case DEFINED_COMPONENTS.LIST_RESOURCES_OF_DATE:
        return (
          <ListResources
            date={selectedDate}
            readOnly={readOnly}
            backCallback={() =>
              changeSelectedComponent(DEFINED_COMPONENTS.LIST_DATES)
            }
            showResourceUnitsCallback={(resourceUnit) =>
              changeSelectedComponent(
                DEFINED_COMPONENTS.LIST_UNIT_FROM_RESOURCE,
                resourceUnit
              )
            }
          />
        );
      case DEFINED_COMPONENTS.LIST_UNIT_FROM_RESOURCE:
        return (
          <ListResourceUnits
            date={selectedDate.date}
            resourceName={selectedResource.name}
            resourceUnits={selectedResource.resourceUnits}
            readOnly={readOnly}
            backCallback={() =>
              changeSelectedComponent(DEFINED_COMPONENTS.LIST_RESOURCES_OF_DATE)
            }
            showResourceUnitTakenCallback={(resourceUnit) =>
              changeSelectedComponent(
                DEFINED_COMPONENTS.LIST_TAKEN_FROM_RESOURCE_UNIT,
                resourceUnit
              )
            }
          />
        );
      case DEFINED_COMPONENTS.LIST_TAKEN_FROM_RESOURCE_UNIT:
        return (
          <ListResourceUnitsTaken
            date={selectedDate.date}
            resourceName={selectedResource.name}
            resourceUnitId={selectedResourceUnit.id}
            resourceUnitTakens={selectedResourceUnit.taken}
            readOnly={readOnly}
            backCallback={() =>
              changeSelectedComponent(
                DEFINED_COMPONENTS.LIST_UNIT_FROM_RESOURCE
              )
            }
          />
        );
      default:
        return <div>Not found :(</div>;
    }
  };

  const changeSelectedComponent = (selectedComponent, requiredData) => {
    // eslint-disable-next-line
    switch (selectedComponent) {
      case DEFINED_COMPONENTS.MAIN_COMPONENT:
        setCurrentComponent(DEFINED_COMPONENTS.MAIN_COMPONENT);
        break;
      case DEFINED_COMPONENTS.SHOW_BASIC_INFO:
        setCurrentComponent(DEFINED_COMPONENTS.SHOW_BASIC_INFO);
        break;
      case DEFINED_COMPONENTS.LIST_DATES:
        setCurrentComponent(DEFINED_COMPONENTS.LIST_DATES);
        break;
      case DEFINED_COMPONENTS.LIST_RESOURCES_OF_DATE:
        setCurrentComponent(DEFINED_COMPONENTS.LIST_RESOURCES_OF_DATE);
        if (requiredData) setSelectedDate(requiredData);
        break;
      case DEFINED_COMPONENTS.LIST_UNIT_FROM_RESOURCE:
        setCurrentComponent(DEFINED_COMPONENTS.LIST_UNIT_FROM_RESOURCE);
        if (requiredData) setSelectedResource(requiredData);
        break;
      case DEFINED_COMPONENTS.LIST_TAKEN_FROM_RESOURCE_UNIT:
        setCurrentComponent(DEFINED_COMPONENTS.LIST_TAKEN_FROM_RESOURCE_UNIT);
        setSelectedResourceUnit(requiredData);
        break;
    }
  };

  return (
    <>
      {!translationsReady ? (
        <Spinner />
      ) : (
        <>
          {error ? (
            <Redirect to={CommonRoutesStrings.homePath} />
          ) : (
            <>
              {!service ? (
                <Spinner />
              ) : (
                <div className="container mx-auto">
                  <div className="mt-10 mb-4 flex place-content-center">
                    <CardHeader
                      color="indigo"
                      variant="gradient"
                      className="px-8 py-6"
                    >
                      <h2 className="text-white text-center text-2xl">
                        {t("titles.show")}
                      </h2>
                      <h6 className="uppercase text-gray-200 text-xs font-medium">
                        <Trans
                          t={t}
                          i18nKey="serviceName"
                          values={{ name: service.name }}
                          components={{ italic: <i /> }}
                        />
                      </h6>
                    </CardHeader>
                  </div>

                  {renderCurrentComponent()}
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ShowServicePage;
